import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 40px auto;
`;

export const Title = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  margin: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    p {
      font-size: 1.3em;
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      color: ${({ theme }) => theme.font.color.primary};
      margin: 1rem;
    }
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
  }
`;

export const CardContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  > button {
    width: 265px;
    height: 36px;
    margin: 25px 0;
    align-self: center;
    border-radius: 0;
  }
`;

export const ContentTitle = styled.h2`
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  line-height: 28px;
  color: ${({ theme }) => theme.font.color.primary};
  margin: 1rem 1rem 1.5rem;
`;

export const CardContentFooter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  > button {
    width: 265px;
    height: 36px;
    margin: 25px 0;
    align-self: center;
    border-radius: 0;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 25px 0;
`;
