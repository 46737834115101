import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { getProductsForSelect as getData } from 'services/strategic-plans/actions';
import { BaseSelect } from 'components/StrategicPlans/ActionsForms/shared/styles';
import { ApiAction } from 'services/strategic-plans/actions/interfaces';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  actions: ApiAction[];
}

const ProductsSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
  placeholder,
  actions,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData().then(data => {
      const uniqueTitles = new Set<string>(); // Conjunto para rastrear títulos únicos

      const filteredOptions = data.filter(option =>
        actions.some(action =>
          action.custom_fields.products.some(product => {
            const cropTitle = product.name.toLowerCase();
            const optionTitle = option.title.toLowerCase();

            if (cropTitle === optionTitle && !uniqueTitles.has(optionTitle)) {
              uniqueTitles.add(optionTitle); // Adiciona o título ao conjunto

              return true;
            }

            return false;
          }),
        ),
      );

      setOptions(filteredOptions);
    });
  }, [actions]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <BaseSelect
      label="Produto"
      loadItems={loadItems}
      className={className}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      inputRole="primary"
    />
  );
};

export default ProductsSelect;
