import { fixedPrecisionOf } from 'util/numbers';

type Args = {
  field: any;
  totalpoints: any;
  advancepoints: any;
};
/** Calcula a porcentagem máxima considerando valores de ponto antecipados */
export function calculateMaxPorcentage({
  field,
  advancepoints,
  totalpoints,
}: Args) {
  const distribution =
    field &&
    field.filter(
      (x: any) => x.balance_unit_id === 47 || x.balance_unit_id === 59,
    )
      ? field.filter(
          (x: any) => x.balance_unit_id === 47 || x.balance_unit_id === 59,
        )
      : [];
  if (distribution[0]?.max_porcentage > 0) {
    const isRetornoFinanceiro =
      distribution[0].balance_unit_id === 47 ||
      distribution[0].balance_unit_id === 59;
    const maxPorcentage = distribution[0].max_porcentage;
    let maxAllowedValue = isRetornoFinanceiro
      ? (totalpoints + advancepoints) * (maxPorcentage / 100)
      : totalpoints * (maxPorcentage / 100);
    // Se o cálculo total for maior que o total de pontos disponível, retorna o
    // total de pontos e ignora o valor calculado
    if (maxAllowedValue > totalpoints) {
      maxAllowedValue = totalpoints;
    }
    return {
      maxPorcentage,
      maxvalue: fixedPrecisionOf(maxAllowedValue, 2),
    };
  }
  return null;
}
