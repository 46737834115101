import React, { useCallback, useState } from 'react';

import { useAuth } from 'context/AuthContext';

import {
  CAMPAIGN_STATUS,
  Campaign,
} from 'services/campaigns-manager/interfaces/Campaign';
import history from 'services/history';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from 'state/modules/campaigns-manager/selectors';
import {
  approveCampaign,
  disapproveCampaign,
  publishToggle,
} from 'services/campaigns-manager';
import { campaignToUpdateCampaignDTO } from 'services/campaigns-manager/transformers';
import { useToast } from 'context/ToastContext';
import { setErrors } from 'state/modules/campaigns-manager/actions';
import CommentModal from 'components/CampaignsManager/Modals/CommentModal';
import routeMap from 'routes/route-map';
import LegendModal from 'components/CampaignsManager/Modals/LegendModal';
import { useToastConfirm } from 'context/ToastConfirm';
import useSchema from 'util/validations/useSchema';
import schema from 'components/CampaignsManager/CampaignForm/schemaValidation';
import { UpdateCampaignDTO } from 'services/campaigns-manager/dtos';
import updateCampaign from 'services/campaigns-manager/updateCampaign';
import { useCampaignsList } from '../Context';
import { Container, Content, LegendStatus } from './styles';
import Table from './Table';
import Filters from './Filters';
import Header from './Header';

const Main: React.FC = () => {
  const { participant } = useAuth();
  const {
    campaigns,
    addHighlight,
    removeHighlight,
    isFetching,
  } = useCampaignsList();
  const dispatch = useDispatch();
  const campaign = useSelector(getCampaign);
  const { addToast } = useToast();
  const { addToastConfirm } = useToastConfirm();
  const { isValid, getErrors } = useSchema<Campaign>(schema);
  const [isCommentPopupOpen, setIsCommentPopupOpen] = useState(false);
  const [disapprovalComment, setDisapprovalComment] = useState('');
  const [disapprovalFormId, setDisapprovalFormId] = useState(0);
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[] | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openCommentPopup = (id: number) => {
    setDisapprovalFormId(id);
    setIsCommentPopupOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentPopupOpen(false);
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDisapprovalComment(event.target.value);
  };

  const handleRequestApproval = useCallback(
    async (data: Campaign) => {
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [data.id]: true,
      }));
      try {
        if (!(await isValid(data))) {
          const errors = await getErrors(data);
          dispatch(setErrors(errors));
          throw new Error('Por favor confira o preenchimento do formulário.');
        }

        const dto: UpdateCampaignDTO = campaignToUpdateCampaignDTO(data);

        await updateCampaign(dto, data.id);

        addToastConfirm({
          title: 'Campanha em Aprovação',
          subtitle:
            'Acompanhe o status da campanha na aba "Aprovações" ou clique no link "Ver aprovação", na lista de campanhas.',
          type: 'success',
        });
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      } catch (e) {
        addToast({
          title: 'Falha ao atualizar campanha. Por favor contate o suporte.',
          type: 'error',
        });
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      }
    },
    [addToast, addToastConfirm, dispatch, getErrors, isValid],
  );

  const handleApprove = useCallback(
    async (data: Campaign) => {
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [data.id]: true,
      }));
      try {
        await approveCampaign(data.id);

        addToastConfirm({
          title: 'Campanha aprovada.',
          type: 'success',
        });

        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      } catch (e) {
        addToast({
          title: 'Falha ao aprovar a campanha. Por favor contate o suporte.',
          type: 'error',
        });
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      }
    },
    [addToast, addToastConfirm],
  );

  const confirmDisapproval = useCallback(async () => {
    setLoading(prevLoadings => ({
      ...prevLoadings,
      [campaign.id]: true,
    }));

    try {
      await disapproveCampaign(disapprovalFormId, disapprovalComment);

      addToastConfirm({
        title: 'Campanha reprovada.',
        type: 'success',
      });

      setLoading(prevLoadings => ({
        ...prevLoadings,
        [campaign.id]: false,
      }));
    } catch (e) {
      addToast({
        title: 'Falha ao reprovar a campanha. Por favor contate o suporte.',
        type: 'error',
      });
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [campaign.id]: false,
      }));
    }

    closeCommentPopup();
  }, [
    addToast,
    addToastConfirm,
    campaign,
    disapprovalComment,
    disapprovalFormId,
  ]);

  const handleApprovalView = useCallback((id: number) => {
    history.push(
      `${routeMap.campaignManagement.acceleratedCampaigns}/${id}?pre_select=aprovacao`,
    );
  }, []);

  const handleDisplayHighlight = useCallback(
    async (id: number | null, campaignId: number) => {
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [campaignId]: true,
      }));

      try {
        if (!id) {
          await addHighlight(campaignId);
          addToastConfirm({
            title: 'Destaque exibido com sucesso',
            type: 'success',
          });
        } else {
          addToast({
            title: 'Falha ao exibir destaque. Por favor tente novamente',
          });
        }
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [campaignId]: false,
        }));
      } catch (e) {
        addToast({
          title: 'Falha ao exibir destaque. Por favor tente novamente',
          type: 'error',
        });
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [campaignId]: false,
        }));
      }
    },
    [addHighlight, addToast, addToastConfirm],
  );

  const handleHideHighlight = useCallback(
    async (highlightId: number | null) => {
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [campaign.id]: true,
      }));
      try {
        if (highlightId) {
          await removeHighlight(highlightId);
          addToastConfirm({
            title: 'Destaque ocultado com sucesso',
            type: 'success',
          });
        }
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [campaign.id]: false,
        }));
      } catch (e) {
        addToast({
          title: 'Falha ao ocultar destaque. Por favor tente novamente',
          type: 'error',
        });
      }
    },
    [campaign.id, removeHighlight, addToastConfirm, addToast],
  );

  const handlePublishUnpublishToggle = useCallback(
    async (data: Campaign) => {
      setLoading(prevLoadings => ({
        ...prevLoadings,
        [data.id]: true,
      }));

      try {
        await publishToggle(data.id);

        if (data.status.id === CAMPAIGN_STATUS.PUBLISHED_STATUS) {
          addToastConfirm({
            title: 'Publicação desfeita.',
            type: 'success',
          });
        } else {
          addToastConfirm({
            title: 'Campanha publicada!',
            type: 'success',
          });
        }
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      } catch (e) {
        addToast({
          title: 'Falha ao na requisição. Por favor tente novamente.',
          type: 'error',
        });
        setLoading(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      }
    },
    [addToast, addToastConfirm],
  );

  return (
    <Container>
      <Header />
      <Content>
        <Filters
          profile={participant.profile_value}
          campaign={campaigns}
          setFilteredCampaigns={setFilteredCampaigns}
        />
        <Table
          handleRequestApproval={handleRequestApproval}
          handleApprove={handleApprove}
          handlePublishUnpublishToggle={handlePublishUnpublishToggle}
          handleDisplayHighlight={handleDisplayHighlight}
          handleHideHighlight={handleHideHighlight}
          handleApprovalView={handleApprovalView}
          openCommentPopup={openCommentPopup}
          filteredCampaigns={filteredCampaigns}
          campaigns={campaigns}
          isFetching={isFetching}
          isLoading={loading}
        />
        {isCommentPopupOpen && (
          <CommentModal
            onClose={closeCommentPopup}
            onConfirm={confirmDisapproval}
            isOpen={isCommentPopupOpen}
            value={disapprovalComment}
            campaign={campaign}
            onCommentChange={handleCommentChange}
          />
        )}
        <LegendStatus type="button" onClick={openModal}>
          <span>Legenda de Status</span>
        </LegendStatus>

        {isModalOpen && (
          <LegendModal onRequestClose={() => setIsModalOpen(false)} isOpen />
        )}
      </Content>
    </Container>
  );
};

export default Main;
