import React from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';

import { Container } from './styles';

interface ThemeLoadingProps {
  size?: number;
}

const ThemeLoading: React.FC<ThemeLoadingProps> = ({ size = 42 }) => {
  const theme = useTheme();

  return (
    <Container>
      <ReactLoading
        className="_loading"
        type="bars"
        height={size}
        width={size}
        color={theme.font.color.primary}
      />
    </Container>
  );
};

export default ThemeLoading;
