import { animated } from 'react-spring';
import styled from 'styled-components';

export const SpinWrapper = styled(animated.div)`
  height: 100%;
  width: 100%;
  z-index: 90;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
`;
