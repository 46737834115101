/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AiOutlineRight } from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';

import {
  DateLimit,
  PlanFilters,
  StrategicPlan,
} from 'services/strategic-plans/interfaces';
import { getStrategicPlans } from 'services/strategic-plans/getStrategicPlans';
import Loading from 'components/shared/Table/Loading';
import { fakeFormatDollars } from 'util/points';
import { Link } from 'react-router-dom';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import { formatDate } from 'util/datetime';
import { Button } from 'components/shared';
import Filters from 'components/StrategicPlans/Filters';
import { useAuth } from 'context/AuthContext';
import getDateLimitForPlans from 'services/strategic-plans/getDateLimitForPlans';
import getStrategyPlansReport from 'services/strategic-plans/reports/getStrategyPlansReport';
import getApprovedReport from 'services/strategic-plans/reports/getApprovedReport';
import { Container, TableGrid, Head } from './styles';

type TabOption = 'para_aprovacao' | 'aprovados' | 'em_ajustes';

type Props = {
  campaignId: number;
};

export const PlanApprovalScreen = ({ campaignId }: Props) => {
  const { participant } = useAuth();
  const [tab, setTab] = useState<TabOption>('para_aprovacao');
  const [isLoading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<PlanFilters>({});
  const [plans, setPlans] = useState<StrategicPlan[]>([]);
  const [reports, setReports] = useState({
    url_approved: '',
    url_full: '',
  });
  const [dateLimit, setDateLimit] = useState<DateLimit | null>(null);

  // Função memorizada para ordenação por nome
  const sortPlansByName = useCallback((a: StrategicPlan, b: StrategicPlan): number => {
    const sortedPlansA = (a.establishmentName || '').toLowerCase();
    const sortedPlansB = (b.establishmentName || '').toLowerCase();
    return sortedPlansA.localeCompare(sortedPlansB);
  }, []);

  // Filtragem de planos
  const filteredPlans = useMemo(
    () => plans.filter(p => p.participantId !== participant.id),
    [plans, participant.id]
  );

  // Planos organizados por aba
  const tabPlans = useMemo(
    () => ({
      para_aprovacao: filteredPlans.filter(plan => plan.status === strategyPlanStatus.onApproval),
      aprovados: filteredPlans.filter(plan => plan.status === strategyPlanStatus.approved),
      em_ajustes: filteredPlans.filter(plan => plan.status === strategyPlanStatus.inAdjustments),
      em_planejamento: filteredPlans.filter(plan => plan.status === strategyPlanStatus.planning),
    }),
    [filteredPlans]
  );

  // Requisição de dados com Promise.all para melhorar performance
  useEffect(() => {
    setLoading(true);
    Promise.all([
      getStrategicPlans({ campaignId, fetch: 'to-approve-plans', filters }),
      getStrategyPlansReport(campaignId),
      getApprovedReport(),
    ])
      .then(([plansResponse, fullReport, approvedReport]) => {
        if (plansResponse.success && plansResponse.data) {
          const filteredAndSortedPlans = plansResponse.data
            .filter(plan => plan.establishmentName)
            .sort(sortPlansByName);
          setPlans(filteredAndSortedPlans);
        }
        setReports({
          url_approved: approvedReport,
          url_full: fullReport,
        });
      })
      .finally(() => setLoading(false));
  }, [campaignId, filters, sortPlansByName]);

  useEffect(() => {
    if (isLoading) return;
    getDateLimitForPlans(campaignId).then(response => setDateLimit(response));
  }, [campaignId, isLoading]);

  const handleFilterChange = useCallback(
    (planFilters: PlanFilters) => setFilters(planFilters),
    []
  );

  const getCurrentApprover = (plan: StrategicPlan) => {
    if (plan.askApprovations?.length) {
      const approver = plan.askApprovations[0];
      if (approver.participantProfile === 'CRM') {
        return 'CRM';
      }
      return (
        <div>
          <span>
            {approver.participantName} ({approver.participantProfile})
          </span>
        </div>
      );
    }
    return `---`;
  };

  const handleFilterDisplay = () => setShowFilters(!showFilters);
  const handleReportDownload = (url: string) => window.open(url);

  const approveStrategyPlan = dateLimit?.approve_strategy_plan;
  const displayNextApproverColumn = tab === 'para_aprovacao';

  return (
    <Container>
      <Head>
        <div>
          <strong>Aprovação de planos estratégicos</strong>
          <p className="info-prazo">
            <FiAlertCircle />
            Prazo para aprovação dos planos: {formatDate(approveStrategyPlan)}
          </p>
        </div>
        <div className="filters-btn--wrapper">
          <Button
            buttonRole="tertiary"
            type="button"
            disabled={reports.url_full.length <= 1}
            onClick={() => handleReportDownload(reports.url_full)}
          >
            Relatório completo
          </Button>
          {/* Caso queiram reativar o relatório de aprovados, é só descomentar esse botão.
          <Button
            buttonRole="tertiary"
            type="button"
            disabled={reports.url_approved.length <= 1}
            onClick={() => handleReportDownload(reports.url_approved)}
          >
            Relatório aprovados
          </Button> */}
        </div>
      </Head>
      <div className="tabs-wrapper">
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#000',
            overflow: 'auto',
          }}
        >
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <Tab
              label={`Para aprovação (${tabPlans.para_aprovacao.length})`}
              value="para_aprovacao"
            />
            <Tab
              label={`Aprovados (${tabPlans.aprovados.length})`}
              value="aprovados"
            />
            <Tab
              label={`Em ajustes (${tabPlans.em_ajustes.length})`}
              value="em_ajustes"
            />
            <Tab
              label={`Em Planejamento (${tabPlans.em_planejamento.length})`}
              value="em_planejamento"
            />
          </Tabs>
        </AppBar>
        <div className="filters-btn--wrapper">
          <Button
            buttonRole="primary"
            type="button"
            onClick={handleFilterDisplay}
          >
            Filtros [{showFilters ? '-' : '+'}]
          </Button>
        </div>
      </div>

      <Filters
        changeFilterHandler={handleFilterChange}
        hideStatusFilter
        hide={!showFilters}
        tabPlans={tabPlans[tab]}
      />

      <TableGrid>
        <div
          className="table table--head desktop-only"
          data-dnp={displayNextApproverColumn}
        >
          <div className="th">Cliente</div>
          <div className="th">Autor do Plano</div>
          <div className="th">Categoria</div>
          <div className="th">Gestão e Desenvolvimento (R$)</div>
          <div className="th">Budget (R$)</div>
          <div className="th">IGD (R$)</div>
          {displayNextApproverColumn && <div className="th">Aprovador</div>}
          <div className="th">Detalhes</div>
        </div>
        {!isLoading &&
          tabPlans[tab].map(plan => (
            <div
              key={plan.uuid}
              className="table table--body"
              data-dnp={displayNextApproverColumn}
            >
              <div className="row-block">
                <span className="title mobile-only">Cliente</span>
                <b>{plan.establishmentName || '---'}</b>
              </div>
              <div className="row-block">
                <span className="title mobile-only">Autor do plano</span>
                <span>
                  {plan.profile}: {plan.participantName}
                </span>
              </div>
              <div className="row-block">
                <span className="title mobile-only">Categoria</span>
                <span>{plan.category}</span>
              </div>
              <div className="row-block">
                <span className="title mobile-only">Gestão e Desenvolvimento (R$)</span>
                <span>{fakeFormatDollars(plan.totalPoints || 0)}</span>
              </div>
              <div className="row-block">
                <span className="title mobile-only">Budget(R$)</span>
                <span>{fakeFormatDollars(plan.totalBudget || 0)}</span>
              </div>
              <div className="row-block">
                <span className="title mobile-only">IGD (R$)</span>
                <span>{fakeFormatDollars(plan.totalIGD || 0)}</span>
              </div>
              {displayNextApproverColumn && (
                <div className="row-block">
                  <span className="title mobile-only">Aprovador</span>
                  <span>{getCurrentApprover(plan)}</span>
                </div>
              )}
              <div className="row-block">
                <span className="title mobile-only">Detalhes</span>
                <Link
                  className="details-link"
                  to={`/planos-estrategicos/${plan.uuid}`}
                >
                  Ver detalhes
                  <AiOutlineRight size={10} />
                </Link>
              </div>
            </div>
          ))}
        {!isLoading && !tabPlans[tab].length && (
          <div className="table--empty">
            <span>Vazio</span>
          </div>
        )}
        {isLoading && (
          <div className="table--loading">
            <Loading colSpan={5} />
          </div>
        )}
      </TableGrid>
    </Container>
  );
};
