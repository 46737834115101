import React, { useState } from 'react';
import { Button } from 'components/shared';
import { Modal, Title, Close, Container } from './style';

interface ModalChoiceTokenProps {
  isOpen: boolean;
  cpf: string;
  password: string;
  onRequestClose: () => void;
  onChooseEmail: () => void;
  onChooseSms: () => void;
  obfuscatedEmail: string;
  obfuscatedCellPhone: string;
}

const ModalChoiceToken: React.FC<ModalChoiceTokenProps> = ({
  isOpen,
  onRequestClose,
  onChooseEmail,
  onChooseSms,
  cpf,
  password,
  obfuscatedEmail,
  obfuscatedCellPhone,
}) => {
  const [loading, setLoading] = useState(false); // Controla o estado de carregamento

  const handleChooseEmail = async () => {
    setLoading(true);
    try {
      await onChooseEmail();
    } finally {
      setLoading(false);
    }
  };

  // Função para iniciar o contador regressivo
  const handleSmsClick = () => {
    onChooseSms();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Escolha o método de recebimento do token"
    >
      <Close>
        {/* eslint-disable-next-line react/button-has-type */}
        <button onClick={onRequestClose}>✕</button>
      </Close>
      <Container>
        <Title>Escolha como deseja receber</Title>
        <div>
          <>
            <Button
              onClick={handleChooseEmail}
              buttonRole="primary"
              type="button"
              disabled={loading}
              style={{ textTransform: 'uppercase' }}
            >
              Receber em {obfuscatedEmail}
            </Button>
            <Button
              onClick={handleSmsClick}
              buttonRole="secondary"
              type="button"
              style={{ textTransform: 'uppercase' }}
            >
              Receber sms em {obfuscatedCellPhone}
            </Button>
          </>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalChoiceToken;
