import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Visible, Hidden } from 'react-grid-system';
import { Hidden } from 'react-grid-system';

import { ReactSVG } from 'react-svg';
import getParticipantAccessPi from 'services/showcase/getParticipantsToAccessPI';

import deleteIcon from 'assets/images/campaigns/delete-icon.svg';
import cloudIcon from 'assets/images/weather/cloud.svg';
import cloudIconGreen from 'assets/images/weather/cloudIconGreen.svg';

import { PROFILES } from 'config/constants';
import * as vendavallStatus from 'config/constants/vendavallStatus';
import { useAuth } from 'context/AuthContext';
import {
  fetchBillingPog,
  fetchPotentializers,
  setSelectedCampaignId,
  fetchPercentageHome,
  fetchBiological,
} from 'state/modules/goals/actions';
import { fetchWeather } from 'state/modules/weather/actions';
import {
  fetchBanners,
  fetchHighlights,
  fetchShowcase,
} from 'state/modules/home/actions';

import {
  getBanners,
  getHighlights,
  getShowcaseProducts,
} from 'state/modules/home/selectors';

import { getWeather } from 'state/modules/weather/selectors';
import { fetchCurrentCampaignTerraService as getCurrentCampaignTerra } from 'services/goals';

// import { getCoinQuotations } from 'state/modules/header/selectors';
import {
  Banners,
  Title,
  Highlights,
  Performance,
  MyPoints,
  Showcase,
  BiologicalPerformance,
} from 'components/Home';
import { BiologicalProps as IBiologicalPerformance } from 'components/Home/BiologicalPerformance';
import { Props as IPerformance } from 'components/Home/Performance';
import {
  getPotentializers,
  getCurrentCampaign,
  getPercentageHome,
  getBiological,
} from 'state/modules/goals/selectors';

import history from 'services/history';

import { City } from 'services/weather/interfaces';
import {
  getCityCoordinatesByName,
  loadCities,
  removeCity,
  saveCity,
} from 'services/weather';
import CitySelect from 'components/Weather/Selects/Cities';
import WeatherWidget from 'components/Weather/Widget';
// import CoinQuotation from 'components/Header/CoinQuotation';
import { useToast } from 'context/ToastContext';
import { CityCoordinates } from 'state/modules/weather/types';

import {
  Wrapper,
  ShowCaseWrapper,
  PerformanceMyPointsWrapper,
  PerformanceWrapper,
  MyPointsWrapper,
  HomeWrapper,
  WeatherWrapper,
  WeatherTitle,
  CitySelectWrapper,
  Img,
  WeatherWidgetWrapper,
  RemoveActionWrapper,
  HighlightsTitle,
} from './styles';

interface Props {
  isFocused: boolean;
}

const DefaultHome: React.FC = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  // const coinQuotations = useSelector(getCoinQuotations);
  const { participant, simulating, isLoggedFromAdmin } = useAuth();

  const isValid =
    participant.esocial_valid &&
    participant.esocial_validation_status === 'Aprovado';

  const isTerraBrasil =
    participant.establishment.type_name?.toLowerCase() === 'terra brasil';

  const colorIcon =
    participant.establishment.type_name.toLowerCase() === 'terra brasil'
      ? cloudIconGreen
      : cloudIcon;

  const [
    banners,
    highlights,
    products,
    potentializers,
    currentCampaignJuntos,
    PercentageHome,
    biological,
  ] = [
    useSelector(getBanners),
    useSelector(getHighlights),
    useSelector(getShowcaseProducts),
    useSelector(getPotentializers),
    useSelector(getCurrentCampaign),
    useSelector(getPercentageHome),
    useSelector(getBiological),
  ];

  const weather = useSelector(getWeather);
  const [citySelect, setCitySelect] = useState<City | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [currentCampaignTerra, setCurrentCampaignTerra] = useState<{
    id: number;
    title: string;
  }>({ id: 0, title: '' });
  const [currentCampaign, setCurrentCampaign] = useState('');

  useEffect(() => {
    if (currentCampaignJuntos) {
      setCurrentCampaign(currentCampaignJuntos.title);
    }
  }, [currentCampaignJuntos]);

  useEffect(() => {
    const fetchCurrentCampaignTerra = async () => {
      const currentTerra = await getCurrentCampaignTerra();
      if (isTerraBrasil && currentTerra) {
        setCurrentCampaignTerra(currentTerra);
        dispatch(setSelectedCampaignId(currentTerra.id));
        setCurrentCampaign(currentTerra.title);
      }
    };

    fetchCurrentCampaignTerra();
  }, [dispatch, isTerraBrasil]);

  useEffect(() => {
    if (!participant.id) return;

    if (participant.status === vendavallStatus.CATALOG) {
      history.push('/catalogo-de-premios');
      return;
    }
    if (isTerraBrasil) {
      dispatch(setSelectedCampaignId(currentCampaignTerra.id));
    } else {
      dispatch(setSelectedCampaignId(currentCampaignJuntos?.id || 0));
    }

    dispatch(fetchBanners());
    dispatch(fetchHighlights());
    dispatch(fetchShowcase(participant.id));

    dispatch(fetchBillingPog());
    dispatch(fetchPercentageHome());
    dispatch(fetchPotentializers());
    dispatch(fetchBiological());

    loadCities().then(data => {
      setCities(
        data
          .filter(item => item.id)
          .map(item => ({
            id: item.id as number,
            name: item.name,
          })),
      );
    });
  }, [
    currentCampaignJuntos,
    currentCampaignTerra,
    dispatch,
    isTerraBrasil,
    participant.id,
    participant.status,
  ]);

  const handleCitySelect = useCallback(
    async (_city: City) => {
      if (cities.length >= 2) {
        addToast({ title: 'Por favor exclua uma cidade antes de continuar' });
        setTimeout(() => {
          setCitySelect(null);
        }, 1500);
        return;
      }

      setCitySelect(_city);

      setCities(oldCities => [
        ...oldCities.filter(item => item.name !== _city.name),
        _city,
      ]);

      saveCity({ name: _city.name, lat: -1, lon: -1 });

      setTimeout(() => {
        setCitySelect(null);
      }, 3000);
    },
    [cities, addToast],
  );

  useEffect(() => {
    const load = async () => {
      const citiesCoordinates = await Promise.all(
        cities.map(async city => {
          const cityCoordinates = await getCityCoordinatesByName(city.name);
          return cityCoordinates as CityCoordinates;
        }),
      );

      dispatch(fetchWeather(citiesCoordinates.filter(item => item?.name)));
    };

    load();
  }, [dispatch, cities]);

  const handleRemoveCity = useCallback((cityName: string) => {
    setCities(oldCities => {
      const internalCity = oldCities.find(item => item.name === cityName);
      if (internalCity) {
        removeCity(internalCity.id);
      }
      return oldCities.filter(item => item.name !== cityName);
    });
  }, []);

  const [showCitySelection, setShowCitySelection] = useState(false);

  const isParticipant = participant.profile === PROFILES.participant;
  const showCatalog = !!participant.establishment.team_receives_points;
  const isBiological = !!participant.establishment.biological;

  const [percentBiological, setPercentBiological] = useState<
    IBiologicalPerformance
  >({} as IBiologicalPerformance);

  const [realized, setRealized] = useState<IPerformance>({
    realized: {},
  } as IPerformance);

  const [harvestPoints, setHarvestPoints] = useState(0);

  useEffect(() => {
    getParticipantAccessPi().then(data => {
      const foundEstablishment = data.find(item => item.type === 'cnpj');
      setHarvestPoints(foundEstablishment?.points || 0);
    });
  }, []);

  useEffect(() => {
    if (!PercentageHome || !potentializers) return;
    setRealized({
      realized: {
        bilingPercent: PercentageHome?.billing.percentage,
        pogPercent: PercentageHome?.pog.percentage,
        premioPercent:
          potentializers.find(item => item.name.toLowerCase() === 'premio')
            ?.percentage || 0,
        heroPercent:
          potentializers.find(item => item.name.toLowerCase() === 'hero')
            ?.percentage || 0,
        talismanPercent:
          potentializers.find(item => item.name.toLowerCase() === 'talisman')
            ?.percentage || 0,
      },
      potentializers,
      additionalMargin: PercentageHome.margem_adicional,
    });
  }, [PercentageHome, potentializers]);

  useEffect(() => {
    if (!biological) return;
    setPercentBiological({
      invoiceBiologicalPercent: biological?.invoice.percentage,
      invoiceBiologicalGoal: biological?.invoice.goal,
      pogBiologicalPercent: biological?.pog.percentage,
      pogBiologicalGoal: biological?.pog.goal,
    });
  }, [biological]);

  return (
    <>
      <HomeWrapper>
        {/* <Visible xs sm>
          {!!coinQuotations && <CoinQuotation quotations={coinQuotations} />}
        </Visible> */}
        <Hidden xs sm>
          {!!banners && <Banners items={banners} />}
        </Hidden>
        <Wrapper>
          <PerformanceMyPointsWrapper>
            {!isParticipant && (
              <>
                <PerformanceWrapper>
                  <Title>Performance</Title>
                  {potentializers && (
                    <Performance
                      realized={realized.realized}
                      potentializers={potentializers}
                      additionalMargin={realized.additionalMargin}
                    />
                  )}
                </PerformanceWrapper>
                <MyPointsWrapper>
                  <MyPoints
                    isParticipant={isParticipant}
                    points={harvestPoints}
                  />
                </MyPointsWrapper>
              </>
            )}
          </PerformanceMyPointsWrapper>
          {isBiological && (
            <BiologicalPerformance
              invoiceBiologicalGoal={percentBiological.invoiceBiologicalGoal}
              invoiceBiologicalPercent={
                percentBiological.invoiceBiologicalPercent
              }
              pogBiologicalGoal={percentBiological.pogBiologicalGoal}
              pogBiologicalPercent={percentBiological.pogBiologicalPercent}
              currentCampaign={currentCampaign}
            />
          )}
          <HighlightsTitle>Destaques</HighlightsTitle>
          {!!highlights && <Highlights items={highlights} />}
        </Wrapper>
        {participant.establishment.type_name !== 'Terra Brasil' &&
          isParticipant &&
          showCatalog &&
          !isLoggedFromAdmin && (
            <ShowCaseWrapper style={{ opacity: isValid ? '1' : '0.5' }}>
              <Wrapper style={{ pointerEvents: isValid ? 'auto' : 'none' }}>
                <Title reverse>Vitrine de Prêmios</Title>
                <Showcase products={products} isSimulating={simulating} />
              </Wrapper>
            </ShowCaseWrapper>
          )}
        {participant.establishment.type_name !== 'Terra Brasil' &&
          isParticipant &&
          showCatalog && (
            <ShowCaseWrapper style={{ opacity: isValid ? '1' : '0.5' }}>
              <Wrapper style={{ pointerEvents: isValid ? 'auto' : 'none' }}>
                <Title reverse>Vitrine de Prêmios</Title>
                <Showcase products={products} isSimulating={simulating} />
              </Wrapper>
            </ShowCaseWrapper>
          )}
        {participant.profile !== PROFILES.focalPoint && (
          <WeatherWrapper>
            <WeatherTitle>
              Clima
              <Img
                src={colorIcon}
                alt="Configuração do Clima"
                onClick={() => setShowCitySelection(e => !e)}
              />
            </WeatherTitle>
            <CitySelectWrapper show={showCitySelection}>
              <h3>Você pode escolher até duas cidades</h3>
              <CitySelect
                value={citySelect}
                setValue={handleCitySelect}
                placeholder="Escolha uma cidade para adicionar"
              />
            </CitySelectWrapper>
            {weather.map(item => (
              <WeatherWidgetWrapper key={`weather-${item.city.name}`}>
                <WeatherWidget weather={item} />
                <RemoveActionWrapper show={showCitySelection}>
                  <ReactSVG
                    src={deleteIcon}
                    onClick={() => handleRemoveCity(item.city.name)}
                  />
                </RemoveActionWrapper>
              </WeatherWidgetWrapper>
            ))}
          </WeatherWrapper>
        )}
      </HomeWrapper>
    </>
  );
};

export default DefaultHome;
