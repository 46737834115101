/* eslint-disable @typescript-eslint/no-explicit-any */
import { pluginApi } from 'services/api';
import success from 'assets/images/accelerator-campaigns/icone-success.png';
import iconCanceled from 'assets/images/accelerator-campaigns/icone-canceled.png';
import waiting from 'assets/images/accelerator-campaigns/icone-waiting.png';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_TEXT,
  CampaignStatus,
} from './interfaces/Campaign';
import getPtStatus from './util/getPtStatusText';

export interface Response {
  status: CampaignStatus;
  count: number;
  values?: any[];
  img: string;
}

interface ApiResponse {
  under_analysis: number;
  on_approval: number;
  published: number;
  canceled: number;
  completed: number;
  new_campaign: number;
  approved_for_publish: number;
}

export default async (participantId?: number): Promise<Response[]> => {
  /* ?status[0]=published&establishments[0]=1&types[0]=1&regional[0]=Arroz&directorships[0]=Sul&participants[0]=1 */
  let extraSearch = '';
  if (participantId) {
    extraSearch = `?participants[0]=${participantId}`;
  }
  const { data } = await pluginApi.get(`fmc-campaigns/details${extraSearch}`);

  return [
    {
      status: {
        id: CAMPAIGN_STATUS.NEW_REQUEST_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.NEW_REQUEST_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.NEW_REQUEST_STATUS),
      },
      img: waiting,
      count: data.new_campaign,
      values: [],
    },
    {
      status: {
        id: CAMPAIGN_STATUS.DEVELOPMENT_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.DEVELOPMENT_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.DEVELOPMENT_STATUS),
      },
      img: waiting,
      count: data.under_analysis,
      values: [], // data.data2.under_analysis
    },
    {
      status: {
        id: CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.WAITING_FOR_APPROVAL_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS),
      },
      img: waiting,
      count: data.waiting,
      values: [],
    },
    {
      status: {
        id: CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.APPROVED_FOR_PUBLICATION_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS),
      },
      img: success,
      count: data.approved_for_publish,
      values: [],
    },
    {
      status: {
        id: CAMPAIGN_STATUS.CANCELED_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.CANCELED_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.CANCELED_STATUS),
      },
      img: iconCanceled,
      count: data.canceled,
      values: [],
    },
    {
      status: {
        id: CAMPAIGN_STATUS.FINISHED_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.FINISHED_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.FINISHED_STATUS),
      },
      img: success,
      count: data.completed,
      values: [],
    },
    {
      status: {
        id: CAMPAIGN_STATUS.PUBLISHED_STATUS,
        statusText: CAMPAIGN_STATUS_TEXT.PUBLISHED_STATUS,
        name: getPtStatus(CAMPAIGN_STATUS.PUBLISHED_STATUS),
      },
      img: success,
      count: data.completed,
      values: [],
    },
  ];
};
