import React from 'react';
import { ReactSVG } from 'react-svg';
import commentIcon from 'assets/images/campaigns/messages-icon.svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { Button } from 'components/shared';
import { Campaign } from 'services/campaigns-manager/interfaces/Campaign';
import { Close, Container, Content, Modal } from './styles';

interface DisapprovalCommentBoxProps {
  onClose: () => void;
  onConfirm: (data: Campaign) => void;
  isOpen: boolean;
  value: string;
  onCommentChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  campaign: Campaign;
  loading?: boolean;
}

const DisapprovalCommentBox: React.FC<DisapprovalCommentBoxProps> = ({
  onClose,
  onConfirm,
  value,
  isOpen,
  onCommentChange,
  campaign,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} type="primary">
      <Container>
        <Close>
          <button type="button" onClick={onClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <Content>
          <h3>
            <ReactSVG src={commentIcon} />
            Comentário
          </h3>
          <textarea
            value={value || ''}
            onChange={onCommentChange}
            rows={4}
            cols={40}
          />
          <Button
            buttonRole="primary"
            type="button"
            onClick={() => onConfirm(campaign)}
            loading={loading}
          >
            OK
          </Button>
        </Content>
      </Container>
    </Modal>
  );
};

export default DisapprovalCommentBox;
