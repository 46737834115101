import styled from 'styled-components';

export const Container = styled.div`
  span {
    color: ${({ theme }) => theme.font.color.quartenary};
  }
`;

export const Header = styled.h4`
  margin-top: 20px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Button = styled.button`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.quartenary};
  text-decoration: underline;
  border: none;
  background-color: transparent;
`;

export const List = styled.ul`
  list-style: none;
`;

export const Label = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.color.quartenary};
  position: relative;
  margin-left: 2px;

  ::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    left: -6px;
    top: 0;
    z-index: -1;
  }

  :first-child ::before {
    display: none;
  }
`;

export const Circle = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  border: 2px solid #00000096;
  background-color: #e5e1e1;
  box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.8),
    0 2px 4px rgba(0, 0, 0, 0.2);
  ::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 100%;
    left: calc(50% - 0.5px);
  }

  :last-child::after {
    display: none;
  }
`;

export const Recuse = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -10px;

  button {
    color: ${({ theme }) => theme.font.color.quartenary};
    border: none;
    background: none;
    margin-left: 3px;
    cursor: pointer;
  }
`;
