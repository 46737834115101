import { pluginApi } from 'services/api';
import { ActionStatus } from 'services/strategic-plans/actions/interfaces';
import { Option } from 'components/shared/Select';

export default async (): Promise<Option[]> => {
  try {
    const { data } = await pluginApi.get<ActionStatus[]>(
      '/status-strategy-actions',
    );
    return data.map<Option>(({ id, name }) => ({
      value: id.toString(),
      title: name,
    }));
  } catch (err) {
    console.error(err);
    return [];
  }
};
