import { AnswerData as AnswerValue } from 'state/modules/surveys/types';

export const buildRequestPayload = (answerList: AnswerValue[]) => {
  const formData = new FormData();

  Array.from(answerList).forEach((item: AnswerValue, index: number) => {
    if (item.answerValue === '') return;

    formData.append(`survey_question[${index}][value]`, item.answerValue);
    formData.append(
      `survey_question[${index}][id]`,
      item.questionId.toString(),
    );
    formData.append(
      `survey_question[${index}][answer_id]`,
      item.answerId.toString(),
    );

    if (item.topic) {
      formData.append(
        `survey_question[${index}][alternative_group]`,
        item.topic,
      );
    }
  });

  return formData;
};
