import * as yup from 'yup';
import validateCpf from 'util/validations/cpf';
import validateCnpj from 'util/validations/cnpj';

export function getValidationSchema() {
  return yup.object().shape({
    cpf_cnpj: yup
      .string()
      .required('Campo obrigatório')
      .test(
        'validate_cpfcnpj',
        'CPF ou CNPJ inválido',
        v => validateCnpj(v) || validateCpf(v),
      ),
    group_name: yup.string().required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    regulation: yup.bool().test('accepted_required', 'required', v => v),
    uf: yup
      .object({
        value: yup.string().required('Campo obrigatório'),
      })
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    member_type: yup
      .object({
        value: yup.string().required('Campo obrigatório'),
      })
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  });
}
