import React from 'react';

import {
  Container,
  Input,
  UfSelect,
  LocationHeader,
  InputWrapper,
} from './styles';
import { FormRow } from '../shared/styles';

interface ActionLocationProps {
  canEdit?: boolean;
}

const ActionLocation: React.FC<ActionLocationProps> = ({ canEdit = true }) => {
  return (
    <Container>
      <LocationHeader>Local da Ação</LocationHeader>
      <FormRow>
        <Input
          name="action_location_name"
          label="Nome do Local"
          disabled={!canEdit}
        />
        <Input
          name="action_location_city"
          label="Município"
          disabled={!canEdit}
        />
        <UfSelect
          disabled={!canEdit}
          name="action_location_state_code_select"
          label="UF"
        />
      </FormRow>
      <FormRow>
        <InputWrapper>
          <Input
            name="action_location_total_area"
            label="Área total"
            placeholder="0"
            numbersOnly
            disabled={!canEdit}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="action_location_treated_area"
            label="Área tratada"
            placeholder="0"
            numbersOnly
            disabled={!canEdit}
          />
        </InputWrapper>
      </FormRow>
    </Container>
  );
};

export default ActionLocation;
