import { pluginApi } from 'services/api';
import { EstablishmentCategory } from 'config/constants';
import { Channel } from './interfaces/channel';

interface ChannelApi extends Channel {
  client_group: string;
  regional: [];
  category: string;
}

interface ApiResponse {
  data: ChannelApi[];
}

export interface Filters {
  directorName?: string;
  regionalName?: string;
  typeName?: string;
  categoryName?: EstablishmentCategory;
}

export default async (filters?: Filters): Promise<Channel[]> => {
  let extraSearch = '';

  if (filters) {
    const { directorName, regionalName, typeName, categoryName } = filters;

    if (directorName) {
      extraSearch += `&directorships[0]=${directorName}`;
    }

    if (regionalName) {
      extraSearch += `&regional[0]=${regionalName}`;
    }

    if (typeName) {
      extraSearch += `&types[0]=${typeName}`;
    }

    if (categoryName) {
      extraSearch += `&category[0]=${categoryName}`;
    }
  }

  const {
    data: { data },
  } = await pluginApi.get<ApiResponse>(
    `cockpit/establishments?page=1&limit=500${extraSearch}`,
  );

  // console.log(data);

  return data.map(
    ({ id, client_group, cnpj, establishment_type_id, investment_group, regional, category}) => ({
      id,
      name: client_group,
      cnpj,
      establishment_type_id,
      investment_group,
      regional,
      category
    }),
  );
};
