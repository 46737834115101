import { pluginApi } from 'services/api';
import { ApiStrategicPlanDetails, Comment, Approver } from './interfaces';

export type StrategicPlanDetails = {
  id: number;
  uuid: string;
  category: string;
  campaignId: number;
  clientGroup: string;
  establishmentId: number;
  participantId: number;
  status: number;
  askApprovations: Approver[];
  approvers: Approver[];
  comments: Comment[];
  actions: Array<{
    id: number;
    uuid: string;
    budget: number;
    igd: number;
    points: number;
    stateCode: string;
    status: number;
    title: string;
    typeId: number;
    expectedDate: string;
    planId: number;
  }>;
};

export async function getStrategyPlanDetailsByUUID(
  uuid: string,
): Promise<StrategicPlanDetails | null> {
  const { data } = await pluginApi.get<ApiStrategicPlanDetails[]>(
    `/strategy-plans/${uuid}`,
  );

  if (data.length) {
    const details = data[0];

    return {
      id: details.id,
      uuid: details.uuid,
      campaignId: details.campaign_id,
      category: details.category,
      status: details.status_strategy_plan_id,
      clientGroup: details.client_group,
      participantId: details.participant_id,
      establishmentId: details.establishment_id,
      comments: details.strategy_plans_reprovers_comments.map(comment => ({
        comment: comment.comment,
        dateTime: comment.created,
        id: comment.id,
        participantId: comment.participant_id,
        participantName: comment.participant.name,
        participantProfile: comment.participant.profile,
      })),
      actions: details.strategy_actions.map(ac => ({
        budget: ac.custom_fields.budget,
        igd: ac.custom_fields.igd,
        expectedDate: ac.expected_date,
        id: ac.id || 0,
        points: ac.custom_fields.points,
        stateCode: ac.state_code || '---',
        status: ac.status_strategy_action_id || 0,
        title: ac.title,
        typeId: ac.strategy_action_type_id,
        uuid: ac.uuid || '',
        planId: ac.strategy_plan_id,
      })),
      approvers: details.strategy_plans_approvers.map(ap => ({
        dateTime: ap.modified,
        id: ap.id,
        participantName: ap.participant.name,
        participantProfile: ap.participant.profile,
        participantId: ap.participant_id,
        approvationStatus: ap.sp_ask_approvation_status_id,
      })),
      askApprovations: details.strategy_plan_ask_approvations.map(ap => ({
        dateTime: ap.modified,
        id: ap.id,
        participantName: ap.participant.name,
        participantProfile: ap.participant.profile,
        approvationStatus: ap.sp_ask_approvation_status_id,
        participantId: ap.participant_id,
      })),
    };
  }

  return null;
}
