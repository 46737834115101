import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #821c0e;
  padding: 1rem 1.5rem;
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
  position: relative;

  ._inputContainer {
    border: 1px solid #821c0e;
    height: 40px;
  }

  .date {
    height: 65px;
  }
`;
