import { Pagination } from 'config/constants/vendavallPaginationInterface';
import { pluginApi } from 'services/api';
import {
  FmcProductsApi,
  ConversionFactor,
  ProductsFilterData,
} from './interfaces';

interface Request {
  page?: number;
  limit?: number;
  category?: string;
}
export interface ApiResponse {
  data: FmcProductsApi[];
  pagination: Pagination;
}

export const getFmcProductsApi = async ({
  page,
  limit,
}: Request): Promise<ApiResponse[]> => {
  try {
    const { data } = await pluginApi.get<ApiResponse[]>(
      `fmc-products/products?order=asc&page=${page}&limit=${limit}`,
    );
    return data;
  } catch (err) {
    return [
      {
        data: [],
        pagination: {} as Pagination,
      },
    ];
  }
};

export const getFmcProductsApiCategory = async ({
  page,
  limit,
  category,
}: Request): Promise<ApiResponse[]> => {
  try {
    const { data } = await pluginApi.get<ApiResponse[]>(
      `fmc-products/products?order=asc&page=${page}&limit=${limit}&category=${category}`,
    );

    return data;
  } catch (err) {
    return [
      {
        data: [],
        pagination: {} as Pagination,
      },
    ];
  }
};

export const getConversionCategories = async (
  id?: number,
): Promise<ConversionFactor | null> => {
  const { data } = await pluginApi.get<ConversionFactor>(
    `fmc-products/factor?product_id[]=${id}`,
  );
  return data;
};

export const getProductsFilterData = async (
  name: string,
): Promise<ProductsFilterData[]> => {
  const { data } = await pluginApi.get<ProductsFilterData[]>(
    `fmc-products/filter?product_name=${name}`,
  );
  return data;
};

export async function getSimulationsSaved() {
  const { data } = await pluginApi.get(`/coin-simulator`);
  return data;
}

export async function getSimulationSaved(id: string) {
  const { data } = await pluginApi.get(`/coin-simulator/by-id?id=${id}`);
  return data;
}

export async function saveSimulationProducts(response: any) {
  const { data } = await pluginApi.post(`/coin-simulator`, response);
  return data;
}
