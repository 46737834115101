import React, { CSSProperties, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TooltipMaterial, { TooltipProps } from '@material-ui/core/Tooltip';

import { Container, ContentTooltip } from './styles';

interface Props {
  title: string | React.ReactNode;
  className?: string;
  type: 'success' | 'error' | 'info' | 'primary' | 'neutral';
  position?: Pick<TooltipProps, 'placement'>['placement'];
  customStyles?: CSSProperties;
}

const Tooltip: React.FC<Props> = ({
  title,
  children,
  className,
  type,
  position = 'bottom',
  customStyles = {},
}) => {
  const styledTheme = useContext(ThemeContext);

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor:
        type === 'primary'
          ? styledTheme.font.color.primary
          : styledTheme.tooltip[type].backgroundColor,
      fontSize: theme.typography.pxToRem(12),
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      border: 'none',
      padding: '8px',
      ...customStyles,
    },
  }))(TooltipMaterial);
  return (
    <Container className={className}>
      <HtmlTooltip
        placement={position}
        enterTouchDelay={0}
        title={<ContentTooltip type={type}>{title}</ContentTooltip>}
      >
        <span>{children}</span>
      </HtmlTooltip>
    </Container>
  );
};

export default Tooltip;
