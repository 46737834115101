import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { Product } from 'services/strategic-plans/actions/interfaces';
import { Pagination } from 'config/constants/vendavallPaginationInterface';

interface ApiResponse {
  data: Product[];
  pagination: Pagination;
}

export default async (): Promise<Option[]> => {
  const {
    data: { data },
  } = await pluginApi.get<ApiResponse>('/products?limit=500');

  return data.map<Option>(({ id, name }) => ({
    value: id.toString(),
    title: name.toUpperCase(),
  }));
};
