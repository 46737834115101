import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from 'context/AuthContext';
import history from 'services/history';
import { getCampaign } from 'services/campaigns-manager';
import { setCampaign } from 'state/modules/campaigns-manager/actions';
import { CampaignForm } from 'components/CampaignsManager';
import ReactLoading from 'react-loading';
import { Alert, Container, Content } from './styles';

interface Params {
  id: string;
  name: string;
}

const VIEWPROFILES = ['GRV', 'GC'];

const Main: React.FC = () => {
  const params = useParams<Params>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    participant: { profile_value: profile },
  } = useAuth();

  const viewProfiles = VIEWPROFILES?.includes(profile);

  useEffect(() => {
    if (!params.id) {
      setLoading(false);
      return;
    }

    const searchCampaign = async () => {
      setLoading(true);

      try {
        const data = await getCampaign(Number(params.id));

        if (viewProfiles) {
          dispatch(setCampaign(data, false));
        } else {
          dispatch(setCampaign(data));
        }
      } catch (e) {
        history.push('/');
      } finally {
        setLoading(false);
      }
    };

    searchCampaign();
  }, [params, dispatch, viewProfiles]);

  return (
    <Container>
      {loading ? (
        <>
          <Alert>
            <span>Carregando formulário</span>
            <ReactLoading
              type="bubbles"
              color="#8d8d8d"
              height={24}
              width={24}
            />
          </Alert>
        </>
      ) : (
        <Content>
          <CampaignForm />
        </Content>
      )}
    </Container>
  );
};

export default Main;
