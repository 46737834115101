import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 0;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-bottom: solid 1px #821c0e;

  h3 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
  }
`;

type Alignment = 'center' | 'flex-start' | 'flex-end';
interface FormActionsProps {
  alignment?: Alignment;
}

export const FormActions = styled.div<FormActionsProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => alignment || 'flex-start'};
  padding: 20px 0;
  width: 100%;
  margin-top: -30px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    color: #808285;
  }
`;

export const AttachmentsWrapper = styled.div`
  width: 100%auto;
`;

export const StrategyGroupName = styled.h2`
  color: #821c0e;
  font-size: 1.45em;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  margin-top: 5px;
`;
export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
