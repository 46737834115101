import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { MaritalStatus } from './interfaces';

interface Request {
  civilStatusCode: string;
}

export default async (): Promise<Option[]> => {
  const { data } = await pluginApi.get<MaritalStatus[]>(`/marital-status`);
  return data.map<Option>(item => ({
    value: item.name_marital_status,
    title: item.name_marital_status.toLowerCase(),
  }));
};
