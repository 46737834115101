import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 50px;

  strong.title {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 18px;
  }

  .points-wrapper {
    margin: 15px 0;
    padding: 20px 30px;
    background-color: #efefef;
    display: grid;
    grid-gap: 10px;

    @media screen and (max-width: 590px) {
      margin: 15px 0 0 0;
    }
  }

  .loading-wrapper {
    display: flex;
    place-content: center;
    padding: 25px 0;

    strong.empty {
      color: #000000;
      opacity: 0.6;
      justify-content: center;
    }

    &.left {
      justify-content: flex-start;
    }
  }

  .points-action {
    display: flex;
    place-content: center;
    padding: 20px 0;
    margin-bottom: 20px;

    button {
      margin: 0;
      max-width: 200px;
      height: auto;
      padding: 12px 25px;
      font-size: 14px;
      background-color: #e63027;

      &:hover,
      &:active {
        background-color: #e63027;
      }
    }
  }
`;

export const DistributedContainer = styled.div`
  margin-top: 30px;

  strong.title {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 18px;
  }
`;

export const DistributedPoints = styled.div`
  padding: 15px 0px;
  display: grid;
  gap: 15px;
  max-width: 535px;
`;
