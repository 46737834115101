import React, { useEffect, useState, useCallback } from 'react';
import getParticipant from 'services/auth/getLoggedParticipant';
import history from 'services/history';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import { useSpring } from 'react-spring';
import { Participant } from 'services/auth/interfaces/Participant';
import save from 'services/auth/editParticipant';
import indirectHasProducerLink from 'services/participants/indirectHasProducerLink';
import numbersOnly from 'util/numbersOnly';
import { INDIRECT_PROFILES as INDIRECTS, PROFILES } from 'config/constants';

import OptOut from 'components/Auth/Register/Form/OptOut';

import CpfCrossDataForm from 'components/Auth/Register/Form/CpfCrossDataForm';
import CompleteProducerRegister from 'components/Auth/Modals/CompleteProducerRegister';
import { checkPendingLead } from 'services/participants/checkLeadProducer';

import SuccessEditModal from 'components/Auth/Modals/SuccessEditRegister';
import Form from 'components/Auth/Register/Form';

import { Container, Content, contentAnimation } from './styles';

const RegisterEdit: React.FC = () => {
  const props = useSpring(contentAnimation);
  const { addToast } = useToast();
  const { refreshParticipant } = useAuth();
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [showProducerRegister, setShowProducerRegister] = useState(false);
  const [showCompleteRegister, setShowCompleteRegister] = useState(false);

  const seeOptOut = participant?.profile !== PROFILES.fmc;
  // const [leadData, setLeadData] = useState(null);

  const hasProducerLink = useCallback(async () => {
    const result = await indirectHasProducerLink();
    setShowProducerRegister(!result);
  }, []);

  useEffect(() => {
    async function load(): Promise<void> {
      try {
        const p = await getParticipant();
        setParticipant(p);

        if (INDIRECTS.includes(p.profile)) {
          await hasProducerLink();
        }
      } catch {
        history.push('/');
      }
    }

    load();
  }, [hasProducerLink]);

  const saveParticipant = useCallback(
    async (data: Participant): Promise<void> => {
      try {
        const request = {
          ...participant,
          ...data,
          area_code: numbersOnly(data.area_code),
          cell_phone: numbersOnly(data.cell_phone),
          cpf: numbersOnly(data.cpf),
          pis_nis: numbersOnly(data.pis_nis),
          regulations_accepted: [],
        } as Participant;

        await save(request);
        refreshParticipant();
        setModalOpened(true);
      } catch (e) {
        addToast({
          title:
            e.response?.data?.message ||
            'Falha na validação dos dados. Por favor entre em contato com o suporte',
          type: 'error',
        });
      }
    },
    [participant, addToast, refreshParticipant],
  );

  const handleCloseModal = useCallback(() => {
    setModalOpened(false);
    history.push('/');
  }, []);

  useEffect(() => {
    async function checkLead() {
      const response = await checkPendingLead();

      if (response) {
        setShowCompleteRegister(true);
      }
    }

    checkLead();
  }, []);

  return (
    <>
      {!!participant && (
        <Container>
          <Content style={props}>
            <Form saveParticipant={saveParticipant} editing />
            {showProducerRegister && <CpfCrossDataForm />}
            {seeOptOut && <OptOut profile="" />}
          </Content>
          <SuccessEditModal
            isOpen={modalOpened}
            onRequestClose={handleCloseModal}
          />
        </Container>
      )}

      {!!participant && (
        <CompleteProducerRegister
          isOpen={showCompleteRegister}
          onRequestClose={() => setShowCompleteRegister(false)}
        />
      )}
    </>
  );
};

export default RegisterEdit;
