import { Option } from 'components/shared/Select';
import { CampaignStatus } from '../interfaces/Campaign';

export default (data: CampaignStatus[]): Option[] => {
  const mappedData = data.map(item => ({
    title: item.name,
    value: item.id.toString(),
  }));

  mappedData.sort((a, b) => a.title.localeCompare(b.title));

  return mappedData;
};
