import { Option } from 'components/shared/Select';
import { City, UF } from '../interface';

export const cityToSelectOptions = (data: City[]): Option[] => {
  return data.map(item => ({
    title: item.nome,
    value: item.id.toString(),
  }));
};

export const ufToOption = (data: UF[]): Option[] => {
  return data.map(item => ({
    title: item.sigla,
    value: item.id.toString(),
  }));
};
