import styled from 'styled-components';
import DefaultTextArea from 'components/shared/TextArea';
import DefaultInput from 'components/shared/Input';
import DefaultDatePicker from 'components/shared/DatePicker';
import DefaultSelect from 'components/shared/Select';
import DefaultBaseSelect from 'components/shared/Select/BaseSelect';
import DefaultButton from 'components/shared/Button';

export const TextArea = styled(DefaultTextArea)`
  margin-bottom: 20px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
    margin-bottom: 8px;
  }

  textarea {
    font-size: 1em;
    // color: #2c2b2b;
  }
`;

export const Input = styled(DefaultInput)`
  margin-bottom: 20px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
    margin-bottom: 8px;
  }
`;

export const DatePicker = styled(DefaultDatePicker)`
  margin-bottom: 20px;
  max-width: 250px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
    margin-bottom: 8px;
  }
`;

export const Select = styled(DefaultSelect)`
  margin-bottom: 20px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
    margin-bottom: 8px;
  }
`;

export const BaseSelect = styled(DefaultBaseSelect)`
  margin-bottom: 20px;
  width: 95%;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
    margin-bottom: 8px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #821c0e;
  margin: 10px 0;
`;

export const Header = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #821c0e;
  margin-bottom: 20px;
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;

  ._inputContainer {
    border: 1px solid #821c0e;
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  > .delete {
    background: transparent;
    border: none;
    text-decoration: underline;
    color: #e63027;
    margin-right: 40px;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 160px;
  min-width: 75px;
  height: 40px;
  margin-top: 0;
  background-color: #e63027;
  text-transform: uppercase;
  border-radius: 5px;
`;
