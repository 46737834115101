import React, { useCallback, useState } from 'react';
import { approvePlans } from 'services/strategic-plans';

import CloseButton from 'components/shared/CloseButton';
import { useToast } from 'context/ToastContext';
import {
  Container,
  Modal,
  ButtonWrapper,
  Button,
  Header,
  Text,
} from './styles';

interface ApproveModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedUuids: string[];
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  isOpen,
  onRequestClose,
  selectedUuids,
}) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const confirmHandler = useCallback(async () => {
    try {
      setLoading(true);
      await approvePlans({ uuid: selectedUuids });

      addToast({
        title: 'Plano(s) aprovado(s) com sucesso!',
        type: 'success',
      });

      onRequestClose();

      window.location.reload();
    } catch (err) {
      addToast({
        title:
          err.response?.data?.message ||
          'Problemas ao aprovar plano(s) selecionado(s)!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, onRequestClose, selectedUuids]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={1}>
      <CloseButton onClickHandler={onRequestClose} />
      <Container>
        <Header>Tem certeza que de deseja aprovar esse plano de ação?</Header>
        <Text>
          Após a aprovação do Plano a equipe não poderá mais alterar as
          informações.
        </Text>
        <ButtonWrapper>
          <Button
            type="button"
            buttonRole="primary"
            onClick={confirmHandler}
            loading={loading}
          >
            OK
          </Button>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            Cancelar
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default ApproveModal;
