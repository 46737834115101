import React from 'react';
import { Performance as IPerformance } from 'services/cockpit/interfaces/channel';

import CircularPerformance from '../Performance/CircularPerformance';

import { Container, Content, Row, Item, Title, Details } from './styles';

interface Props {
  biological: IPerformance;
}

const Biological: React.FC<Props> = ({ biological }) => {
  const { biologicalInvoice, biologicalPog } = biological;

  if (biologicalInvoice.goal === 0 && biologicalPog.goal === 0) {
    return <></>;
  }

  return (
    <Container>
      <h3>Biológicos</h3>
      <Content>
        <Row>
          <Item>
            <Title>Faturamento</Title>
            <CircularPerformance
              color="#FF4C16"
              percent={parseFloat(biologicalInvoice.formattedPercentage)}
            />
            <Details>
              <span>Objetivo: US$ {biologicalInvoice.formattedGoal}</span>
              <span>Realizado: US$ {biologicalInvoice.formattedRealized}</span>
            </Details>
          </Item>
          <Item>
            <Title>POG</Title>
            <CircularPerformance
              color="#25CCE1"
              percent={parseFloat(biologicalPog.formattedPercentage)}
            />
            <Details>
              <span>Objetivo: US$ {biologicalPog.formattedGoal}</span>
              <span>Realizado: US$ {biologicalPog.formattedRealized}</span>
            </Details>
          </Item>
        </Row>
      </Content>
    </Container>
  );
};

export default Biological;
