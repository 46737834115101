import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Title } from 'components/Home';

import { QuotationsList } from 'components/Header/CoinQuotation/styles';

export const Wrapper = styled.div`
  padding: 1em 1.5em 2em 1.5em;
`;

export const IndicateWrapper = styled.div`
  width: 100%;
`;

export const IndicateContent = styled.div`
  padding: 0 20px 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
`;

export const ShowCaseWrapper = styled.div`
  background: linear-gradient(
    to right,
    #8f817b 0.35%,
    #7e6f68 14.4%,
    #685952 36.38%,
    #61514a 50.32%,
    #4f413b 71.07%,
    #312722 100%
  );

  @media screen and (max-width: 480px) {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.font.color.primary} 70%,
      ${({ theme }) => darken(0.05, theme.font.color.primary)}
    );
  }
`;

export const PerformanceMyPointsWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const HomeWrapper = styled.div`
  ${QuotationsList} {
    margin-top: 1em;
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 0.9em;
  }
`;

export const WeatherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 20px 0;
`;

export const WeatherTitle = styled(Title)`
  margin-left: 10px;
  > img {
    width: 25px;
    margin-left: 5px;
    cursor: pointer;
    transform: translateY(13px);
  }
`;

interface CitySelectProps {
  show: boolean;
}

export const CitySelectWrapper = styled.div<CitySelectProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 20px 10px 20px 10px;
  > h3 {
    color: ${({ theme }) => theme.font.color.quartenary};
    font-size: 16px;
    margin-bottom: 4px;
  }

  ._inputContainer {
    height: 40px;
  }
`;

export const WeatherWidgetWrapper = styled.div`
  position: relative;
  margin: 20px 10px 20px 10px;

  & + div {
    margin-top: 16px;
  }
`;

export const RemoveActionWrapper = styled.div<CitySelectProps>`
  display: none;
  position: absolute;
  top: -5px;
  left: -6px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}
`;

export const Img = styled.img`
  margin-bottom: 10px;
`;
