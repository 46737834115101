import styled from 'styled-components';
import DefaultSelect from 'components/shared/Select/BaseSelect';

export const Container = styled.div``;

export const Select = styled(DefaultSelect)`
  max-width: 300px;

  input {
    text-align: center;
    font-size: 14px;
    color: #000;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    cursor: pointer;
  }
  ._inputContainer {
    height: 40px;
    width: 150px;
    margin-left: 10px;
  }
`;
