import React, { useState, useCallback, useEffect } from 'react';

import {
  CAMPAIGN_STATUS,
  Campaign,
} from 'services/campaigns-manager/interfaces/Campaign';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from 'state/modules/campaigns-manager/selectors';
import {
  approveCampaign,
  disapproveCampaign,
} from 'services/campaigns-manager';
import { useAuth } from 'context/AuthContext';
import { format, parseISO } from 'date-fns';
import { setErrors } from 'state/modules/campaigns-manager/actions';
import {
  CreateNewCampaignDTO,
  UpdateCampaignDTO,
} from 'services/campaigns-manager/dtos';

import {
  campaignToCreateNewCampaignDTO,
  campaignToUpdateCampaignDTO,
} from 'services/campaigns-manager/transformers';
import { useToast } from 'context/ToastContext';

import useSchema from 'util/validations/useSchema';

import { FaCheck, FaTimes } from 'react-icons/fa';
import { useToastConfirm } from 'context/ToastConfirm';
import deleteCampaign from 'services/campaigns-manager/deleteCampaign';
import ReactLoading from 'react-loading';
import saveCampaign from 'services/campaigns-manager/saveCampaign';
import saveDraft from 'services/campaigns-manager/saveDraft';
import { useParams } from 'react-router-dom';
import updateCampaign from 'services/campaigns-manager/updateCampaign';
import FormTabs, { Tab } from './Tabs';
import schema from './schemaValidation';
import CustomerGroup from './Forms/CustomerGroup';
import InfoAndRules from './Forms/InfoAndRules';
import ButtonsFooter from '../ButtonsFooter';
import Awards from './Forms/Awards';
import Approval from './Forms/Approval';
import CommentModal from '../Modals/CommentModal';

import {
  Container,
  Header,
  CampaignStatus,
  ApprovalBox,
  HelpButton,
} from './styles';
import HelpModal from '../Modals/HelpModal';
import SendCampaign from '../Modals/SendCampaignModal';
import DiscardModal from '../Modals/DiscardMordal';
import RequestApproval from '../Modals/RequestApprovalModal';
import ChangeMechanicsModal from '../Modals/ChangeMechanicsModal';

const approversProfile = ['CRM', 'GRM'];

const tabOrder: Tab[] = [
  'CUSTOMER_GROUP',
  'INFO_AND_RULES',
  'AWARDS',
  'APPROVAL',
];
interface TypeCampaignProps {
  name: string;
}

const Form: React.FC = () => {
  const {
    participant,
    participant: { profile_value: profile },
  } = useAuth();
  const campaign = useSelector(getCampaign);
  const typeCampaign = useParams<TypeCampaignProps>();
  const { addToast } = useToast();
  const { isValid, getErrors } = useSchema<any>(schema);
  const dispatch = useDispatch();
  const { addToastConfirm } = useToastConfirm();
  const [helpModal, setHelpModal] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [isOpenSendCampaingn, setIsOpenSendCampaingn] = useState(false);
  const [isOpenModalForApprovation, setIsOpenModalForApprovation] = useState(
    false,
  );
  const [isCommentPopupOpen, setIsCommentPopupOpen] = useState(false);
  const [disapprovalComment, setDisapprovalComment] = useState('');
  const [activeTab, setActiveTab] = useState<Tab>('CUSTOMER_GROUP');
  const [showPreviousButton, setShowPreviousButton] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState<boolean>(true);
  const [showLastTab, setShowLastTab] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [disapproveLoading, setDisapproveLoading] = useState(false);
  const [openChangeMechanicsModal, setOpenChangeMechanicsModal] = useState(
    false,
  );

  const isWaitingApproval =
    campaign.status.id === CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS;
  const isApproversProfile = approversProfile.includes(profile);

  const currentDate = new Date();
  const formattedDate = format(currentDate, 'dd/MM/yyyy');
  let createdDateFormatted = null;
  if (campaign?.createdAt) {
    const createdDate = parseISO(campaign.createdAt);
    createdDateFormatted = format(createdDate, 'dd/MM/yyyy');
  }

  const handleSendButton = useCallback(() => {
    if (campaign.id === 0) {
      setIsOpenSendCampaingn(true);
    } else {
      setIsOpenModalForApprovation(true);
    }
  }, [campaign.id]);

  const openCommentPopup = () => {
    setIsCommentPopupOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentPopupOpen(false);
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDisapprovalComment(event.target.value);
  };

  const handleNextClick = useCallback(() => {
    const currentIndex = tabOrder.indexOf(activeTab);
    if (currentIndex !== -1 && currentIndex < tabOrder.length - 1) {
      const nextTab = tabOrder[currentIndex + 1];
      setActiveTab(nextTab);
    }
  }, [activeTab]);

  const handlePreviousClick = useCallback(() => {
    const currentIndex = tabOrder.indexOf(activeTab);
    if (currentIndex !== -1 && currentIndex > 0) {
      const previousTab = tabOrder[currentIndex - 1];
      setActiveTab(previousTab);
    }
  }, [activeTab]);

  const hasNextTab = useCallback((): boolean => {
    const currentIndex = tabOrder.indexOf(activeTab);
    return (
      currentIndex !== -1 &&
      ((currentIndex < tabOrder.length - 1 && showLastTab) ||
        currentIndex < tabOrder.length - 2)
    );
  }, [activeTab, showLastTab]);

  const hasPreviousTab = useCallback((): boolean => {
    const currentIndex = tabOrder.indexOf(activeTab);
    return currentIndex !== -1 && currentIndex > 0;
  }, [activeTab]);

  const handleSendCampaign = useCallback(
    async (data: Campaign) => {
      setSendLoading(true);
      try {
        if (!(await isValid(data))) {
          const errors = await getErrors(data);
          dispatch(setErrors(errors));
          throw new Error(
            'Por favor confira o preenchimento do formulário nas abas anteriores',
          );
        }
        const dto: CreateNewCampaignDTO = campaignToCreateNewCampaignDTO(data);

        await saveCampaign(dto);

        if (participant.profile_value === 'GRM') {
          addToastConfirm({
            title: 'Campanha enviada para aprovação.',
            subtitle:
              'Acompanhe o status da campanha na aba "Aprovações" ou clique no link "Ver aprovação", na lista de campanhas.',
            type: 'success',
          });
        } else {
          addToastConfirm({
            title: 'Campanha enviada para análise.',
            type: 'success',
          });
        }

        setSendLoading(false);
      } catch (err) {
        addToast({
          title: err.message || 'Erro ao salvar campanha. Contate o suporte.',
          type: 'error',
        });

        setSendLoading(false);
      }
    },
    [addToast, addToastConfirm, dispatch, getErrors, isValid, participant],
  );

  const requestApproval = useCallback(async () => {
    setSaveLoading(true);

    if (!campaign.id) {
      addToast({
        title: 'Erro ao salvar campanha. Contate o suporte.',
        type: 'error',
      });
    }

    try {
      if (!(await isValid(campaign))) {
        const errors = await getErrors(campaign);
        dispatch(setErrors(errors));
        throw new Error(
          'Por favor confira o preenchimento do formulário nas abas anteriores',
        );
      }
      const dto: UpdateCampaignDTO = campaignToUpdateCampaignDTO(campaign);
      await updateCampaign(dto, campaign.id);

      if (participant.profile_value === 'GRM') {
        addToastConfirm({
          title: 'Campanha enviada para aprovação.',
          subtitle:
            'Acompanhe o status da campanha na aba "Aprovações" ou clique no link "Ver aprovação", na lista de campanhas.',
          type: 'success',
        });
      } else {
        addToastConfirm({
          title: 'Campanha enviada para análise.',
          type: 'success',
        });
      }

      setSaveLoading(false);
    } catch (err) {
      addToast({
        title: err.message || 'Erro ao salvar campanha. Contate o suporte.',
        type: 'error',
      });
      setSaveLoading(false);
    }
  }, [
    addToast,
    addToastConfirm,
    campaign,
    dispatch,
    getErrors,
    isValid,
    participant.profile_value,
  ]);

  const handleSaveDraft = useCallback(
    async (data: Campaign) => {
      setSaveDraftLoading(true);
      try {
        if (campaign.id !== 0) {
          const updatedto: UpdateCampaignDTO = campaignToUpdateCampaignDTO(
            campaign,
          );
          await saveDraft(updatedto, campaign.id);
        } else {
          const dto: CreateNewCampaignDTO = campaignToCreateNewCampaignDTO(
            data,
          );
          await saveDraft(dto);
        }

        addToastConfirm({
          title: 'Rascunho salvo com sucesso.',
        });

        setSaveDraftLoading(false);
      } catch (e) {
        addToast({
          title:
            e.message ||
            'Falha ao salvar rascunho. Por favor contate o suporte.',
          type: 'error',
        });
        setSaveDraftLoading(false);
      }
    },
    [addToast, addToastConfirm, campaign],
  );

  const approve = useCallback(async () => {
    setApproveLoading(true);
    if (!campaign || !campaign.id) return;
    try {
      await approveCampaign(campaign.id);
      addToastConfirm({
        title: 'Campanha aprovada.',
        type: 'error',
      });
      setApproveLoading(false);
    } catch (e) {
      addToast({
        title:
          e.message ||
          'Falha ao aprovar a campanha. Por favor contate o suporte.',
        type: 'error',
      });
      setApproveLoading(false);
    }
  }, [addToast, campaign, addToastConfirm]);

  const confirmDisapproval = useCallback(async () => {
    if (!campaign || !campaign.id) return;
    setDisapproveLoading(true);

    try {
      await disapproveCampaign(campaign.id, disapprovalComment);

      addToastConfirm({
        title: 'Campanha reprovada.',
        type: 'success',
      });

      setDisapproveLoading(false);
    } catch (e) {
      addToast({
        title:
          e.message ||
          'Falha ao reprovar a campanha. Por favor contate o suporte.',
        type: 'error',
      });
      setDisapproveLoading(false);
    }

    closeCommentPopup();
  }, [addToast, campaign, disapprovalComment, addToastConfirm]);

  const confirmDiscard = useCallback(
    async (data: Campaign) => {
      setDiscardLoading(true);
      if (!data.id || data.id === 0) {
        window.location.href = '/campanhas-aceleradoras';
      }

      try {
        await deleteCampaign(data.id);
        addToastConfirm({
          title: 'Campanha descartada.',
          type: 'success',
        });
        setDiscardLoading(false);
      } catch (e) {
        addToast({
          title:
            e.message ||
            'Falha ao descartar a campanha. Por favor contate o suporte.',
          type: 'error',
        });
        setDiscardLoading(false);
      }
    },
    [addToast, addToastConfirm],
  );

  useEffect(() => {
    setShowPreviousButton(hasPreviousTab());
    setShowNextButton(hasNextTab());
  }, [activeTab, hasNextTab, hasPreviousTab]);

  useEffect(() => {
    if (isApproversProfile && isWaitingApproval) {
      setShowLastTab(true);
    }
  }, [isApproversProfile, isWaitingApproval]);
  const [isCreator, setIsCreator] = useState(true);

  useEffect(() => {
    const checkCreator = campaign.participant_id === participant.id;
    setIsCreator(checkCreator);
  }, [campaign.participant_id, participant.id]);

  const redirectWithClear = () => {
    window.location.href = '/campanhas-aceleradoras';
  };

  const viewChangeMechanics =
    campaign.status.id === CAMPAIGN_STATUS.NEW_REQUEST_STATUS ||
    campaign.id === 0;

  return (
    <Container>
      {isCommentPopupOpen && (
        <CommentModal
          onClose={closeCommentPopup}
          onConfirm={confirmDisapproval}
          isOpen={isCommentPopupOpen}
          value={disapprovalComment}
          campaign={campaign}
          onCommentChange={handleCommentChange}
        />
      )}
      {discardModalOpen && (
        <DiscardModal
          onClose={() => setDiscardModalOpen(false)}
          onConfirm={() => confirmDiscard(campaign)}
          isOpen={discardModalOpen}
        />
      )}
      {isOpenModalForApprovation && (
        <RequestApproval
          onClose={() => setIsOpenModalForApprovation(false)}
          onConfirm={() => requestApproval()}
          isOpen={isOpenModalForApprovation}
        />
      )}
      {isOpenSendCampaingn && (
        <SendCampaign
          onClose={() => setIsOpenSendCampaingn(false)}
          onConfirm={() => handleSendCampaign(campaign)}
          isOpen={isOpenSendCampaingn}
        />
      )}
      {openChangeMechanicsModal && (
        <ChangeMechanicsModal
          onClose={() => setOpenChangeMechanicsModal(false)}
          isOpen={openChangeMechanicsModal}
          campaign={campaign}
        />
      )}
      <Header>
        <button type="button" onClick={redirectWithClear}>
          VOLTAR PARA TODAS AS CAMPANHAS
        </button>
        {viewChangeMechanics && (
          <button
            type="button"
            onClick={() => setOpenChangeMechanicsModal(true)}
          >
            ALTERAR
          </button>
        )}
      </Header>
      <br />

      <CampaignStatus>
        <h3>
          Campanha do tipo: {campaign.mechanic?.name || typeCampaign.name}
        </h3>
        <p>
          Solicitada por {campaign.participantName || participant.name}, em{' '}
          {createdDateFormatted ?? formattedDate}
        </p>
        {campaign.status.id === CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS && (
          <p>Aguardando aprovação do {campaign.approver_in_turn}</p>
        )}
      </CampaignStatus>
      <br />
      <div className="contents">
        <strong className="status">{`Status: ${campaign.status.name}`}</strong>
        <HelpButton onClick={() => setHelpModal(true)} toggle={helpModal}>
          ?
        </HelpButton>
      </div>
      {profile.toLowerCase() === 'crm' &&
        campaign.status.id === CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS && (
          <ApprovalBox>
            <span>Esta campanha está aguardando a sua aprovação.</span>
            <button type="button" className="approve" onClick={approve}>
              {approveLoading ? (
                <ReactLoading
                  type="bars"
                  color="#8d8d8d"
                  height={10}
                  width={10}
                />
              ) : (
                <>
                  <FaCheck className="icon icon-check" />
                  Aprovar
                </>
              )}
            </button>
            <button
              type="button"
              className="disapprove"
              onClick={openCommentPopup}
            >
              {disapproveLoading ? (
                <ReactLoading
                  type="bars"
                  color="#8d8d8d"
                  height={10}
                  width={10}
                />
              ) : (
                <>
                  <FaTimes className="icon icon-times" />
                  Reprovar
                </>
              )}
            </button>
          </ApprovalBox>
        )}
      <FormTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        statusCampaign={campaign.status}
        isApproveProfile={isApproversProfile}
        approvers={campaign.approvers ? campaign.approvers : []}
      />

      <CustomerGroup
        myProfile={participant.profile_value}
        actived={activeTab === 'CUSTOMER_GROUP'}
      />
      <InfoAndRules
        actived={activeTab === 'INFO_AND_RULES'}
        campaignType={campaign.mechanic?.name || typeCampaign.name}
        isCheckedRegulation={campaign.checkRegulation}
      />
      <Awards actived={activeTab === 'AWARDS'} />
      <Approval
        approvers={campaign.approvers ? campaign.approvers : []}
        actived={activeTab === 'APPROVAL'}
      />
      <div className="contents-buttons">
        {showPreviousButton && (
          <button type="button" onClick={handlePreviousClick}>
            Anterior
          </button>
        )}
        {showNextButton && (
          <button type="button" onClick={handleNextClick}>
            Próximo
          </button>
        )}
      </div>
      {(campaign.status.id !== CAMPAIGN_STATUS.NEW_REQUEST_STATUS ||
        campaign.status.id !== CAMPAIGN_STATUS.DEVELOPMENT_STATUS) && (
        <ButtonsFooter
          sendButton={handleSendButton}
          saveButton={handleSendButton}
          discardButton={() => setDiscardModalOpen(true)}
          saveDraftButton={() => handleSaveDraft(campaign)}
          isCreator={isCreator}
          sendLoading={sendLoading}
          saveDraftLoading={saveDraftLoading}
          saveLoading={saveLoading}
          discardLoading={discardLoading}
          profile={profile}
          statusCampaign={campaign.status.id}
          showSendButton={activeTab === 'AWARDS'}
        />
      )}
      {helpModal && (
        <HelpModal
          isOpen={helpModal}
          onRequestClose={() => setHelpModal(false)}
        />
      )}
    </Container>
  );
};

export default Form;
