import { pluginApi } from "services/api";

interface ImportProductSegmentsResponse {
  download_url: string;
}

export const getImportProductSegments = async (importId?: string): Promise<ImportProductSegmentsResponse | null> => {
  try {
    const { data } = await pluginApi.get<ImportProductSegmentsResponse>(`/reports/dictionary-product-segments`, {
      params: {
        import: importId,
      },
    });
    return data;
  } catch (error) {
    console.error("Erro ao obter link de download:", error);
    return null;
  }
};