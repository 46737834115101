import styled from 'styled-components';

export const BoxBackground = styled.div`
  margin-left: 1rem;
  background-color: #d9d9d9;
  padding: 2rem;
`;

export const TitleSection = styled.h1`
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  color: #000;
  font-size: 1.1em;
  margin-bottom: 0.5em;
  font-weight: 100;
`;
