import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 5px;
  position: relative;
  cursor: pointer;

  .tooltip {
    position: relative;
  }

  &:hover {
    img {
      opacity: 0.6;
    }
  }

  img {
    max-height: 20px;
    transition: opacity 250ms ease-in-out;
    z-index: 1;
  }

  b {
    position: absolute;
    display: flex;
    top: -5px;
    right: -5px;
    justify-content: center;
    align-items: center;
    padding: 2px;
    background-color: #e63027;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    z-index: 2;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;
