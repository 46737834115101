import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 500px;
    width: 100%;
    height: 100%;
    max-height: 320px;
    background: #e63027;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > h3 {
    font-size: 30px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.tertiary};
  }
  > h4 {
    font-size: 19px;
    color: ${({ theme }) => theme.font.color.tertiary};
  }

  > button {
    width: 181px;
    height: 48px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 18px;
  }
`;
