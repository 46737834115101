import styled from 'styled-components';

import { Input as DefaultInput } from '../shared/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px #821c0e;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
  position: relative;

  ._inputContainer {
    border: 1px solid #821c0e;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Input = styled(DefaultInput)`
  margin-bottom: 0;
  margin-right: 10px;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  span {
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #821c0e;
  margin-bottom: 8px;
`;

export const Obs = styled.span`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  color: ${({ theme }) => theme.font.color.quartenary};
  size: 0.7em;
`;
