import React, { useState, useCallback } from 'react';
import { InvoiceData } from 'services/nf/interfaces';

import NoResults from 'components/shared/NoResults';
import Details from 'pages/Receipts/Details';
import ConsultInvoiceModal from 'pages/Receipts/ConsultInvoiceModal';
import Loading from 'components/shared/ThemeLoading';

import { formatPoints } from 'util/points';
import useWindowSize from 'hooks/use-window-dimensions';
import {
  ListTable,
  ListTableContainer,
  IconEye,
  IconList,
  ContentMobile,
} from './styles';

interface ListDetailsProps {
  items: InvoiceData[];
  loading?: boolean;
}

const ListDetails: React.FC<ListDetailsProps> = ({ items, loading }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [consultIsOpen, setConsultIsOpen] = useState(false);
  const { width } = useWindowSize();

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(
    null,
  );
  const [selectedAccesseKey, setSelectedAccessKey] = useState('');
  const [selectedOrigin, setSelectedOrigin] = useState('');

  const handleDetails = useCallback((invoice: InvoiceData) => {
    setIsDetailOpen(true);
    setSelectedInvoiceId(invoice.id);
  }, []);

  const closeModalHandler = useCallback(() => {
    setIsDetailOpen(false);
    setSelectedInvoiceId(null);
  }, []);

  const closeConsultHandler = useCallback(() => {
    setConsultIsOpen(false);
    setSelectedAccessKey('');
    setSelectedOrigin('');
  }, []);

  const linkHandler = (url: string) => {
    const linkClick = document.createElement('a');
    linkClick.href = url;
    linkClick.target = '_blank';
    linkClick.rel = 'noopener noreferrer';
    document.body.appendChild(linkClick);
    linkClick.click();
    document.body.removeChild(linkClick);
  };

  const handleInvoice = (invoice: InvoiceData) => {
    if (invoice.fromApi) {
      setConsultIsOpen(true);
      setSelectedAccessKey(invoice.accessKey);
      setSelectedOrigin(invoice.origin);
      return;
    }

    linkHandler(invoice.url);
  };

  return (
    <ListTableContainer>
      {loading && <Loading />}
      {!loading && items.length === 0 && <NoResults />}
      {!loading && items.length > 0 && width && width > 768 ? (
        <ListTable>
          <thead>
            <tr>
              <th>Nota</th>
              <th>Detalhes</th>
              <th>Status</th>
              <th>Canal onde comprou</th>
              <th>Valor de produtos FMC</th>
              <th>FMC Coins</th>
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>
                  <IconList onClick={() => handleInvoice(item)} />
                </td>
                <td>
                  {[1, 2].includes(item.status) && (
                    <IconEye onClick={() => handleDetails(item)} />
                  )}
                </td>
                <td> {item.statusText} </td>
                <td>
                  {' '}
                  {item.status === 1 ? item.establishmentClientGroup : '-'}{' '}
                </td>
                <td>
                  {' '}
                  {item.status === 1 ? `R$ ${formatPoints(item.total)}` : '-'}
                </td>
                <td>
                  {item.status === 1 ? formatPoints(item.totalFmcCoins) : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      ) : (
        <ContentMobile>
          {items.map(item => (
            <div key={item.id} className="card-items">
              <div className="card-header">
                <p>
                  <h4>Nota</h4>
                  <IconList onClick={() => handleInvoice(item)} />
                </p>
                <p>
                  <h4 className="details">Detalhes</h4>
                  {[1, 2].includes(item.status) && (
                    <IconEye
                      onClick={() => handleDetails(item)}
                      className="details"
                    />
                  )}
                </p>
              </div>
              <p>
                {' '}
                <span>Status</span>: {item.statusText}{' '}
              </p>
              <p>
                {' '}
                <span>Canal onde comprou</span>:{' '}
                {item.status === 1 ? item.establishmentClientGroup : '-'}{' '}
              </p>
              <p>
                <span>Valor de produtos FMC</span>:{' '}
                {item.status === 1 ? `R$ ${formatPoints(item.total)}` : '-'}
              </p>
              <p>
                <span>FMC Coins</span>:{' '}
                {item.status === 1 ? formatPoints(item.totalFmcCoins) : '-'}
              </p>
            </div>
          ))}
        </ContentMobile>
      )}
      <Details
        modalOpen={isDetailOpen}
        invoiceId={selectedInvoiceId ?? 0}
        closeModalHandler={closeModalHandler}
      />
      <ConsultInvoiceModal
        modalOpen={consultIsOpen}
        invoiceAccessKey={selectedAccesseKey}
        selectedOrigin={selectedOrigin}
        closeModalHandler={closeConsultHandler}
      />
    </ListTableContainer>
  );
};

export default ListDetails;
