import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { BaseSelect } from 'components/StrategicPlans/ActionsForms/shared/styles';
import { StrategicPlan } from 'services/strategic-plans/interfaces';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  plans: StrategicPlan[];
}

const ChannelSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
  plans,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const uniqueTitles = new Set<string>(); // Conjunto para rastrear títulos únicos
    const newOptions = plans
      .map<Option>(({ establishmentId, establishmentName }) => ({
        title: establishmentName,
        value: establishmentId.toString(),
      }))
      .filter(option => {
        if (!uniqueTitles.has(option.title)) {
          uniqueTitles.add(option.title); // Adiciona o título ao conjunto

          return true;
        }

        return false;
      });

    newOptions.sort((a, b) => a.title.localeCompare(b.title)); // Ordenação alfabética

    setOptions(newOptions);
  }, [plans]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <BaseSelect
      label="Selecione um cliente"
      value={value}
      setValue={setValue}
      loadItems={loadItems}
      className={className}
      inputRole="primary"
    />
  );
};

export default ChannelSelect;
