import React, { useEffect, useState } from 'react';
import ThemeLoading from 'components/shared/ThemeLoading';
import { Alert } from 'components/shared';
import { PointCard } from 'components/PointManagement/point-card';
import { Point } from 'services/point-management/dtos/points-distribution';
import { getPointsDistributionToApprove } from 'services/point-management/points-distribuition-to-approve';
import { uuid } from 'uuidv4';
import { usePoints } from '../../use-points';
import { Container, Wrapper } from './styles';

export const ChannelTab: React.FC = () => {
  const {
    points,
    isFetching,
    isFetched,
    fetchError,
    selectedCampaign,
    selectedEstablishment,
  } = usePoints();

  const pointsArrayLength = points && Object.values(points).length === 1;
  const [valuesPointsToApprove, setValuesPointsToApprove] = useState();

  useEffect(() => {
    const getPoints = async () => {
      if (selectedEstablishment) {
        const dados = await getPointsDistributionToApprove({
          establishmentId:
            selectedEstablishment && Number(selectedEstablishment?.value),
          campaignId: selectedCampaign,
        });
        setValuesPointsToApprove(dados);
      }
    };

    getPoints();
  }, [selectedCampaign, selectedEstablishment]);

  return (
    <Wrapper>
      <div>
        {!pointsArrayLength && (
          <strong className="title">
            Você deve distribuir o valor total de pontos:
          </strong>
        )}
        <Container>
          {fetchError && (
            <div className="error">
              <Alert variation="error">
                <span>Ocorreu um erro ao carregar seus pontos.</span>
              </Alert>
            </div>
          )}
          {isFetching && (
            <div className="loading-wrapper">
              <ThemeLoading size={24} />
            </div>
          )}
          {isFetched && pointsArrayLength && (
            <div className="loading-wrapper">
              <strong className="empty">
                Este canal não possui pontos para serem distribuídos
              </strong>
            </div>
          )}
          {isFetched && !pointsArrayLength && (
            <div className="cards-wrapper">
              {points.waiting_distribution &&
                Object.values(points.waiting_distribution)
                  .flat()
                  .map((point: Point) => (
                    <PointCard
                      key={uuid()}
                      pointsDistribution={point}
                      pointsToApprove={valuesPointsToApprove}
                    />
                  ))}
              {points.waiting_approved &&
                Object.values(points.waiting_approved)
                  .flat()
                  .map((point: Point) => (
                    <PointCard
                      key={uuid()}
                      pointsDistribution={point}
                      pointsToApprove={valuesPointsToApprove}
                    />
                  ))}
              {points.approved &&
                Object.values(points.approved).map((point: Point) => (
                  <PointCard
                    key={uuid()}
                    pointsDistribution={point}
                    pointsToApprove={valuesPointsToApprove}
                  />
                ))}
              {points.published &&
                Object.values(points.published).map((point: Point) => (
                  <PointCard
                    key={uuid()}
                    pointsDistribution={point}
                    pointsToApprove={valuesPointsToApprove}
                  />
                ))}
            </div>
          )}
        </Container>
      </div>
    </Wrapper>
  );
};
