import React, { useState } from 'react';
import { Buffer } from 'buffer';
import { useToast } from 'context/ToastContext';
import { useForm, FormContext } from 'react-hook-form';
import { Input, Button } from 'components/shared';
import * as Yup from 'yup';
import validateCpf from 'util/validations/cpf';
import { CatalogStatusError } from 'services/auth/errors';
import { validateRecoverPasswordToken } from 'services/auth/signIn';
import  ChooseResetPassword from 'components/Auth/ForgotPassword/ChooseResetPassword';
import ModalTokenRecoverPassword from 'components/Auth/ForgotPassword/ModalTokenRecoverPassword';
import ChangePasswordBySmsModal from 'components/Auth/ForgotPassword/ChangePasswordBySmsModal';
import { previousRecoverPasswordParticipant, recoverPasswordSmsToken } from 'services/auth/signIn';

import { Container, Title, DefaultModal } from './styles';

interface ModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  onSuccessSendEmail(): void;
  sendEmail(cpf: string): Promise<void>;
}

interface FormData {
  cpf: string;
}

interface ObfuscatedContacts {
  obfuscatedEmail: string;
  obfuscatedCellPhone: string;
}

const RequestResetPasswordModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  onSuccessSendEmail,
  sendEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [chooseResetPassword, setChooseResetPassword] = useState(false);//Modal para escolher a forma de alterar a senha
  const [obfuscatedContacts, setObfuscatedContacts] = useState<ObfuscatedContacts>({} as ObfuscatedContacts);
  const [cpf, setCpf] = useState('');
  const [isModalTokenOpen, setIsModalTokenOpen] = useState(false);
  const [changePasswordModalOpened, setChangePasswordModalOpened] = useState(false);
  const [userToken, setUserToken] = useState('');

  const schemaValidation = Yup.object().shape({
    cpf: Yup.string()
      .required('CPF é obrigatório')
      .test('valid-cpf', 'CPF inválido', validateCpf),
  });

  const methods = useForm<FormData>({
    validationSchema: schemaValidation,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async ({ cpf }) => {
    setLoading(true);
    try {
            const contacts = await previousRecoverPasswordParticipant({
              cpf: Buffer.from(cpf).toString('base64'),
            });
            
            setCpf(cpf);
            setObfuscatedContacts(contacts);
            setChooseResetPassword(true);
            onRequestClose();
          } catch (error) {
            if (error instanceof Error) {
              addToast({
                title:
                  error.message ||
                  'Falha ao enviar CPF. Por favor tente novamente',
                type: 'error',
              });
            } else {
              addToast({
                title: 'Falha ao enviar CPF. Por favor tente novamente',
                type: 'error',
              });
            }
          }
    setLoading(false);
  });

  const sendEmailHandler = async (cpf: string) => {
    try {
      await sendEmail(cpf);
      setChooseResetPassword(false);
      onSuccessSendEmail();
      setIsModalTokenOpen(false)
    } catch (error) {
      if (error instanceof Error) {
        addToast({
          title: error.message || 'Falha ao enviar E-mail. Por favor tente novamente',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Falha ao enviar E-mail. Por favor tente novamente',
          type: 'error',
        });
      }
    }
  };

  const sendSmsHandler = async (cpf: string) => {
    console.log("Clicou no enviar receber SMS" );

    try {
      const response = await recoverPasswordSmsToken({
        cpf: Buffer.from(cpf).toString('base64'),
      })

      if (response.success) {
        setChooseResetPassword(false);
        setIsModalTokenOpen(true); // Abre o modal
      } 
    } catch (error) {
      if (error instanceof Error) {
        addToast({
          title:
            error.message ||
            'Falha ao enviar CPF. Por favor tente novamente',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Falha ao enviar CPF. Por favor tente novamente',
          type: 'error',
        });
      }
    }
  };

  const handleTokenSubmit = async (token: string) => {
    try {
      const response = await validateRecoverPasswordToken({
        cpf: Buffer.from(cpf).toString('base64'),
        tokenSms: token,
      });

      if (response) {
        setUserToken(token)
        setIsModalTokenOpen(false);
        setChangePasswordModalOpened(true);
      }
    } catch (error) {
      if (error instanceof CatalogStatusError) {
        window.open(error.message, '_self');
        return;
      }
      addToast({
        title: 'Erro ao enviar o token',
        type: 'error',
      });
    }
  };


  return (
    <>
    {/* Esse modal envia o cpf */}
    <DefaultModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <Title>Esqueci minha senha</Title>
        <FormContext {...methods}>
          <form onSubmit={onSubmit}>
            <Input
              name="cpf"
              label="CPF"
              numbersOnly
              pattern="XXX.XXX.XXX-XX"
            />
            <Button type="submit" buttonRole="primary" loading={loading}>
              Enviar
            </Button>
          </form>
        </FormContext>
      </Container>
    </DefaultModal>
    {/* Esse modal abre as opções para recuperar senha */}
    {chooseResetPassword && obfuscatedContacts && (
      <ChooseResetPassword
        isOpen={chooseResetPassword}
        cpf={cpf}
        onRequestClose={() => setChooseResetPassword(false)}
        onChooseEmail={() => sendEmailHandler(cpf)}
        onChooseSms={() => sendSmsHandler(cpf)}
        obfuscatedEmail={obfuscatedContacts.obfuscatedEmail}
        obfuscatedCellPhone={obfuscatedContacts.obfuscatedCellPhone}
        />
    )}
    {/* Este modal é o que abre para enviar o token */}
        <ModalTokenRecoverPassword
          isOpenOne={isModalTokenOpen}
          onRequestClose={() => setIsModalTokenOpen(false)}
          onSubmit={handleTokenSubmit}
          selectedMethod="sms"
          onChooseSms={() => sendSmsHandler(cpf)}
          onChooseEmail={() => sendEmailHandler(cpf)}
          obfuscatedCellPhone={obfuscatedContacts.obfuscatedCellPhone}
        />
        <ChangePasswordBySmsModal
            isOpen={changePasswordModalOpened}
            onRequestClose={() => {
              setChangePasswordModalOpened(false);
            }}
            token={userToken}
        />
    </>
    
  );
};

export default RequestResetPasswordModal;