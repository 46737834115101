import { pluginApi } from 'services/api';

export interface ParticipantData {
  id: number;
  name: string;
  campaign_id: number;
  noteCount: number;
  totalValue: string;
}

export default async (): Promise<ParticipantData[]> => {
  const { data } = await pluginApi.get(
    '/participants/establishments/getNameCLienteCanal',
  );
  return data;
};
