import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Title } from 'components/Home';

import { QuotationsList } from 'components/Header/CoinQuotation/styles';

export const Wrapper = styled.div`
  padding: 1em 1.5em 2em 1.5em;

  @media screen and (max-width: 410px) {
    overflow-y: auto;
  }
`;

export const ShowCaseWrapper = styled.div`
  background-color: ${({ theme }) => theme.font.color.primary};

  @media screen and (max-width: 480px) {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.font.color.primary} 70%,
      ${({ theme }) => darken(0.05, theme.font.color.primary)}
    );
  }
`;

export const PerformanceWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 428px) {
    flex-wrap: wrap;
  }
`;

export const MyPointsWrapper = styled.div`
  width: 25%;
  margin-left: 1em;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PerformanceMyPointsWrapper = styled.div`
  display: flex;
  align-items: end;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 428px) {
    flex-wrap: wrap;
  }
`;

export const HomeWrapper = styled.div`
  ${QuotationsList} {
    margin-top: 1em;
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 0.9em;
  }
`;

export const Tampao = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background: black;
  opacity: 0.98;

  position: fixed;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

export const WeatherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 20px 0;
`;

export const WeatherTitle = styled(Title)`
  margin-left: 25px;
  > img {
    width: 25px;
    margin-left: 5px;
    cursor: pointer;
    transform: translateY(13px);
  }
`;

interface CitySelectProps {
  show: boolean;
}

export const CitySelectWrapper = styled.div<CitySelectProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 20px 10px 20px 10px;
  > h3 {
    color: ${({ theme }) => theme.font.color.quartenary};
    font-size: 16px;
    margin-bottom: 4px;
  }

  ._inputContainer {
    height: 40px;
  }
`;

export const WeatherWidgetWrapper = styled.div`
  position: relative;
  margin: 20px 10px 20px 10px;

  & + div {
    margin-top: 16px;
  }
`;

export const RemoveActionWrapper = styled.div<CitySelectProps>`
  display: none;
  position: absolute;
  top: -5px;
  left: -6px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}
`;

export const Img = styled.img`
  margin-bottom: 10px;
`;

export const HighlightsTitle = styled.h1`
  font-size: 1.3em;
  margin: 0.5em 0;
  color: ${({ theme }) => theme.font.color.primary};
`;
