/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';
import { AnswerData } from 'state/modules/surveys/types';

import {
  Container,
  CheckBoxContent,
  InputGroup,
  InputItem,
  InputLabel,
  InputCheckbox,
  LabelWrapper,
  InputText,
} from './styles';

interface Props {
  question: string;
  answers: Answer[];
  id: number;
  canEdit: boolean;
}

const InputCheckBox: React.FC<Props> = ({ question, answers, id, canEdit }) => {
  const dispatch = useDispatch();
  const inputCheckBoxRef = useRef<HTMLInputElement>(null);
  const inputFreeAnswerRef = useRef<HTMLInputElement>(null);

  const itemChecked =
    !!inputCheckBoxRef.current && inputCheckBoxRef.current.checked;

  const changeAnswerHandler = useCallback(
    (
      answerValue: string,
      questionId: number,
      answerId: number,
      deleteOperation: boolean,
    ) => {
      const payload: AnswerData = {
        answerValue,
        questionId,
        answerId,
        deleteOperation,
      };

      // if (freeAnswer) payload = { ...payload, multipleAnswers: 'unique' };

      dispatch(setValueAnswer(payload));
    },
    [dispatch],
  );

  const clearFreeAnswerInput = useCallback(() => {
    if (inputFreeAnswerRef && inputFreeAnswerRef.current)
      inputFreeAnswerRef.current.value = '';
  }, [inputFreeAnswerRef]);

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch);
    }
  }, [answers, canEdit, dispatch, id]);

  return (
    <Container>
      <CheckBoxContent>
        <p>{question}</p>
        <InputGroup>
          {answers.map(
            ({
              id: answerId,
              survey_question_id,
              answer,
              survey_participant_answers,
              free_answer,
            }) => (
              <InputItem key={`${answerId}-${survey_question_id}`}>
                <InputLabel htmlFor={answer} key={answerId}>
                  <InputCheckbox
                    ref={inputCheckBoxRef}
                    type="checkbox"
                    id={answer}
                    value={answer}
                    name={`${survey_question_id}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = inputFreeAnswerRef.current
                        ? inputFreeAnswerRef.current.value
                        : '';

                      changeAnswerHandler(
                        !free_answer ? e.target.value : value,
                        id,
                        answerId,
                        !e.target.checked,
                      );

                      if (free_answer && !e.target.checked)
                        clearFreeAnswerInput();
                      if (free_answer && e.target.checked)
                        inputFreeAnswerRef.current &&
                          inputFreeAnswerRef.current.focus();
                    }}
                    defaultChecked={survey_participant_answers.some(
                      item => item.survey_question_answer_id === answerId,
                    )}
                    disabled={!canEdit}
                  />
                </InputLabel>

                <LabelWrapper>
                  <span>{answer}</span>

                  {free_answer && (
                    <InputText
                      ref={inputFreeAnswerRef}
                      type="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        changeAnswerHandler(
                          e.target.value,
                          id,
                          answerId,
                          !itemChecked,
                        )
                      }
                      defaultValue={
                        survey_participant_answers.length > 0
                          ? survey_participant_answers[0].answer
                          : ''
                      }
                      disabled={!itemChecked}
                    />
                  )}
                </LabelWrapper>
              </InputItem>
            ),
          )}
        </InputGroup>
      </CheckBoxContent>
    </Container>
  );
};

export default InputCheckBox;
