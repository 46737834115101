import { Product } from '../interfaces/api-interface';

const productIdsToMock = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const notParticipateProductIds = [6, 7, 11, 15];

const products: Product[] = [
  {
    id: 1,
    name: 'Hero',
    segment_id: 4,
    segment: 'Inseticida',
    biological: false,
    is_enhancer: true,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 1333000,
    revenues_realized_in_dollar: 666000,
    revenues_goal_in_kilos_by_liter: 1333000,
    revenues_realized_in_kilos_by_liter: 666000,
    pog_goal_in_dollar: 1333000,
    pog_realized_in_dollar: 666000,
    pog_goal_in_kilos_by_liter: 1333000,
    pog_realized_in_kilos_by_liter: 666000,
    stock_in_kg_per_liter: 90000,
    stock_in_dolar: 1000000,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.5,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.25,
    additional_margin_percentage_biological: 7,
  },
  {
    id: 2,
    name: 'Premio',
    segment_id: 4,
    segment: 'Inseticida',
    biological: false,
    is_enhancer: true,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 1333000,
    revenues_realized_in_dollar: 666000,
    revenues_goal_in_kilos_by_liter: 1333000,
    revenues_realized_in_kilos_by_liter: 666000,
    pog_goal_in_dollar: 1333000,
    pog_realized_in_dollar: 666000,
    pog_goal_in_kilos_by_liter: 1333000,
    pog_realized_in_kilos_by_liter: 666000,
    stock_in_kg_per_liter: 90000,
    stock_in_dolar: 1000000,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.5,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.25,
    additional_margin_percentage_biological: 7,
  },
  {
    id: 3,
    name: 'Talisman',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: true,
    biological: false,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 1333000,
    revenues_realized_in_dollar: 666000,
    revenues_goal_in_kilos_by_liter: 1333000,
    revenues_realized_in_kilos_by_liter: 666000,
    pog_goal_in_dollar: 1333000,
    pog_realized_in_dollar: 666000,
    pog_goal_in_kilos_by_liter: 1333000,
    pog_realized_in_kilos_by_liter: 666000,
    stock_in_kg_per_liter: 90000,
    stock_in_dolar: 1000000,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.5,
    additional_margin_percentage_to_pay: 0.25,
    additional_margin_percentage_biological: 7,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
  },
  ...productIdsToMock.map(id => ({
    id,
    name: `Produto ${id}`,
    segment_id: id % 2 === 0 ? 4 : 5,
    segment: id % 2 === 0 ? 'Inseticida' : 'Fungicida',
    is_enhancer: false,
    is_terra_brasil: false,
    biological: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: !notParticipateProductIds.includes(id),
    revenues_goal_in_dollar: 1333000,
    revenues_realized_in_dollar: 666000,
    revenues_goal_in_kilos_by_liter: 1333000,
    revenues_realized_in_kilos_by_liter: 666000,
    pog_goal_in_dollar: 1333000,
    pog_realized_in_dollar: 666000,
    pog_goal_in_kilos_by_liter: 1333000,
    pog_realized_in_kilos_by_liter: 666000,
    stock_in_kg_per_liter: 1333000,
    stock_in_dolar: 666000,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.5,
    additional_margin_percentage_to_pay: 0.25,
    additional_margin_percentage_biological: 7,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
  })),
];

export const newMock: Product[] = [
  {
    id: 1,
    name: 'Ally',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: false,
    is_terra_brasil: false,
    biological: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 0,
    revenues_realized_in_dollar: 4323.33,
    revenues_goal_in_kilos_by_liter: 0,
    revenues_realized_in_kilos_by_liter: 14.4,
    pog_goal_in_dollar: 0,
    pog_realized_in_dollar: 712,
    pog_goal_in_kilos_by_liter: 0,
    pog_realized_in_kilos_by_liter: 2,
    stock_in_kg_per_liter: 28,
    stock_in_dolar: 9483.49,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.01,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 2,
    name: 'AURORA 400 EC',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: false,
    is_terra_brasil: false,
    biological: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 98000,
    revenues_realized_in_dollar: 180887.84,
    revenues_goal_in_kilos_by_liter: 1000,
    revenues_realized_in_kilos_by_liter: 1825,
    pog_goal_in_dollar: 98000,
    pog_realized_in_dollar: 122612.41,
    pog_goal_in_kilos_by_liter: 1000,
    pog_realized_in_kilos_by_liter: 1230,
    stock_in_kg_per_liter: 314.25,
    stock_in_dolar: 37744.53,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.01,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.02,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 3,
    name: 'AUTHORITY',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: false,
    biological: false,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 307500,
    revenues_realized_in_dollar: 316498.94,
    revenues_goal_in_kilos_by_liter: 15000,
    revenues_realized_in_kilos_by_liter: 17480,
    pog_goal_in_dollar: 307500,
    pog_realized_in_dollar: 56762.61,
    pog_goal_in_kilos_by_liter: 15000,
    pog_realized_in_kilos_by_liter: 3100,
    stock_in_kg_per_liter: 6850,
    stock_in_dolar: 140894.62,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.0025,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 4,
    name: 'AVATAR',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: false,
    is_terra_brasil: false,
    biological: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 147000,
    revenues_realized_in_dollar: 342786.66,
    revenues_goal_in_kilos_by_liter: 3000,
    revenues_realized_in_kilos_by_liter: 7600,
    pog_goal_in_dollar: 147000,
    pog_realized_in_dollar: 69680.8,
    pog_goal_in_kilos_by_liter: 3000,
    pog_realized_in_kilos_by_liter: 1420,
    stock_in_kg_per_liter: 5050,
    stock_in_dolar: 258743.86,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.005,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 5,
    name: 'HERO',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: true,
    biological: false,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 3120000,
    revenues_realized_in_dollar: 2320019.63,
    revenues_goal_in_kilos_by_liter: 80000,
    revenues_realized_in_kilos_by_liter: 68220,
    pog_goal_in_dollar: 2730000,
    pog_realized_in_dollar: 339388.48,
    pog_goal_in_kilos_by_liter: 70000,
    pog_realized_in_kilos_by_liter: 10315,
    stock_in_kg_per_liter: 1165,
    stock_in_dolar: 46771.49,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.01,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.02,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 6,
    name: 'PREMIO',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: true,
    biological: false,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 3250000,
    revenues_realized_in_dollar: 2071482,
    revenues_goal_in_kilos_by_liter: 25000,
    revenues_realized_in_kilos_by_liter: 18692,
    pog_goal_in_dollar: 2990000,
    pog_realized_in_dollar: 614348.64,
    pog_goal_in_kilos_by_liter: 23000,
    pog_realized_in_kilos_by_liter: 5305,
    stock_in_kg_per_liter: 2749,
    stock_in_dolar: 359478.83,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.01,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.02,
    additional_margin_percentage_biological: 7,
  },

  {
    id: 7,
    name: 'TALISMAN',
    segment_id: 4,
    segment: 'Inseticida',
    is_enhancer: true,
    biological: false,
    is_terra_brasil: false,
    enhancer_segment: '',
    crop_enhancer: true,
    is_a_participating_product: true,
    revenues_goal_in_dollar: 1232500,
    revenues_realized_in_dollar: 925239.95,
    revenues_goal_in_kilos_by_liter: 72500,
    revenues_realized_in_kilos_by_liter: 60760,
    pog_goal_in_dollar: 1105000,
    pog_realized_in_dollar: 128393.66,
    pog_goal_in_kilos_by_liter: 65000,
    pog_realized_in_kilos_by_liter: 7960,
    stock_in_kg_per_liter: 13985,
    stock_in_dolar: 246355.64,
    rebate_percentage_to_pay: 3.5,
    rebate_percentage_to_pay_biological: 3.5,
    extra_percentage_to_pay_per_enhancer_product: 0.01,
    seller_value_in_real_to_pay: 18.0,
    seller_value_in_real_to_pay_biological: 18.0,
    additional_margin_percentage_to_pay: 0.02,
    additional_margin_percentage_biological: 7,
  },
];

export default products;
