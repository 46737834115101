import React from 'react';

import CloseButton from 'components/shared/CloseButton';
import approvedIcon from 'assets/images/campaigns/ico_aprovada.svg';
import waitingIcon from 'assets/images/campaigns/ico_aguardando.svg';
import canceledIcon from 'assets/images/campaigns/ico_cancelada.svg';
import {
  Container,
  Modal,
  Button,
  Title,
  Content,
  ModalContainer,
  Icon,
  BoldText,
} from './styles';

interface LegendModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}
const LegendModal: React.FC<LegendModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={3}>
      <ModalContainer>
        <Container>
          <CloseButton onClickHandler={onRequestClose} />
          <Title>Status da Solicitação</Title>
          <Content>
            <Icon src={waitingIcon} />
            <BoldText>Nova Solicitação:</BoldText>&nbsp;criação da campanha em
            andamento, rascunho.
          </Content>
          <Content>
            <Icon src={waitingIcon} />
            <BoldText>Em aprovação:</BoldText>&nbsp;a campanha foi enviada para
            aprovação.
          </Content>
          <Content>
            <Icon src={waitingIcon} />
            <BoldText>Em análise/desenvolvimento:</BoldText>&nbsp; ocorreu um
            erro na solicitação ou a solicitação foi reprovada.
          </Content>
          <Content>
            <Icon src={approvedIcon} />
            <BoldText>Aprovada:</BoldText>&nbsp;campanha aprovada/pronta para
            ser publicada.
          </Content>
          <Content>
            <Icon src={approvedIcon} />
            <BoldText>Publicada:</BoldText>&nbsp;a campanha foi publicada e pode
            ser visualizada pelo canal.
          </Content>
          <Content>
            <Icon src={approvedIcon} />
            <BoldText>Finalizada:</BoldText>&nbsp;a campanha foi encerrada.
          </Content>
          <Content>
            <Icon src={canceledIcon} />
            <BoldText>Cancelada:</BoldText>&nbsp;a campanha foi cancelada.
          </Content>
        </Container>
        <Button type="button" buttonRole="primary" onClick={onRequestClose}>
          FECHAR
        </Button>
      </ModalContainer>
    </Modal>
  );
};
export default LegendModal;
