import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from 'context/AuthContext';

type Props = {
  children: React.ReactNode;
};

const IdleTimer: React.FC<Props> = ({ children }) => {
  const { signOut, signed } = useAuth();

  // ! TODO: Adicionar esse tempo em uma variável de ambiente
  const TIMEOUT = 30 * 60 * 1000; // 30 minutes

  const onIdle = () => {
    if (signed) {
      signOut();
    }
  };

  useIdleTimer({ onIdle, timeout: TIMEOUT });

  return <>{children}</>;
};

export default IdleTimer;
