import { pluginApi } from 'services/api';

interface Request {
  comment: string;
  uuid: string[];
}

export default async (data: Request): Promise<void> => {
  await pluginApi.post('/strategy-plans/reprove', data);
};
