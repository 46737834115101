import { Pagination } from 'config/constants/vendavallPaginationInterface';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFmcProductsCheck,
  setFmcProductsValue,
} from 'state/modules/fmc-coins-simulator/actions';
import { FmcProducts } from 'state/modules/fmc-coins-simulator/interfaces';
import { getFmcProducts } from 'state/modules/fmc-coins-simulator/selectors';
import { FmcProductsCheck } from 'state/modules/fmc-coins-simulator/types';
import { formatPoints } from 'util/points';
import { Link, useParams } from 'react-router-dom';
import routeMap from 'routes/route-map';
import useDimensions from 'hooks/use-window-dimensions';
import checkedIcon from 'assets/images/fmc-coins-simulator/checkedIcon.svg';
import { getSimulationSaved } from 'services/fmc-coins-simulator';
import Input from '../Calculator/CustomInput';
import {
  Card,
  List,
  Title,
  Button,
  SeeMoreButton,
  Checkbox,
  Contents,
  ResultContents,
} from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  categoryInterface?: string | undefined;
  categoryComponent: 'complementary' | 'innovation' | 'strategic';
  inputRole?: string;
  loadState?: boolean;
  defaultVal?: any;
  defaultFmcCoins?: any;
  products: FmcProducts[] | undefined;
  valueToConversion?: number;
  productsPagination?: Pagination;
  onSumValuesTotal?: (valueTotal: number, fmcCoins: number) => void;
  onLoadNextPage?: (
    category: string | undefined,
    productPagination: Pagination,
  ) => void | undefined;
}

export const CardsSaved: React.FC<Props> = ({
  categoryInterface,
  categoryComponent,
  type,
  products,
  productsPagination,
  onSumValuesTotal,
  onLoadNextPage,
  defaultVal,
  defaultFmcCoins,
}) => {
  const dispatch = useDispatch();
  const productSelector = useSelector(getFmcProducts);
  const { width } = useDimensions();

  const { id } = useParams<any>();

  const [product, setProduct] = useState<any>();

  useEffect(() => {
    const getSimulation = async () => {
      const response = await getSimulationSaved(id);
      setProduct(response.products);
    };

    getSimulation();

    // eslint-disable-next-line
  }, []);

  const changeProductsHandler = useCallback(
    (productId: number, checked: boolean) => {
      const payload: FmcProductsCheck = {
        productId,
        checked,
      };
      dispatch(setFmcProductsCheck(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    const value = productSelector
      .filter(
        (item: any) =>
          product && product.find((value: any) => value.product === item.id),
      )
      .map(item => item.valueToConversion)
      .reduce((total, currentValue) => total + currentValue, 0);

    const fmcCoins = productSelector
      .filter(
        (item: any) =>
          product && product.find((value: any) => value.product === item.id),
      )
      .map(item => item.fmcCoins)
      .reduce((total, currentValue) => total + currentValue, 0);

    if (onSumValuesTotal) {
      if (value !== 0 || fmcCoins !== 0) onSumValuesTotal(value, fmcCoins);
    }
  }, [productSelector, onSumValuesTotal, product]);

  const handleLoad = useCallback(
    async (category: string | undefined, productPagination: Pagination) => {
      if (onLoadNextPage) {
        onLoadNextPage(category, productPagination);
      }
    },

    [onLoadNextPage],
  );
  const changeMoneyFormat = useCallback(
    (productId: number, value: number) => {
      const payload = { productId, value };
      dispatch(setFmcProductsValue(payload));
    },
    [dispatch],
  );

  return (
    <>
      <Card>
        {products !== undefined && products.length > 0 ? (
          <>
            <Title inputRole={categoryComponent}>{categoryInterface}</Title>
            {products.map(fmcProduct => (
              <List key={fmcProduct.id}>
                <Contents inputRole={categoryComponent}>
                  <img src={fmcProduct.picture} alt={fmcProduct.name} />
                  {type === 'checkbox' ? (
                    <Checkbox inputRole={categoryComponent}>
                      <input
                        type={type}
                        value={fmcProduct.id}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          changeProductsHandler(
                            fmcProduct.id,
                            event.target.checked,
                          );
                        }}
                      />
                      <span>
                        <img src={checkedIcon} alt="Ícone checkbox" />
                      </span>
                    </Checkbox>
                  ) : (
                    <>
                      <div>
                        <span>Valor (R$)</span>
                        <Input
                          type="money"
                          defaultValue={
                            defaultVal || fmcProduct.valueToConversion
                          }
                          onChange={value => {
                            changeMoneyFormat(fmcProduct.id, value);
                          }}
                        />
                      </div>

                      {type === 'result' ? (
                        <ResultContents>
                          <span>FMC Coins</span>
                          <span className="value">
                            {formatPoints(
                              defaultFmcCoins || fmcProduct.fmcCoins,
                            )}
                          </span>
                        </ResultContents>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Contents>
              </List>
            ))}
            {type === 'money' && width > 500 && (
              <SeeMoreButton>
                <Link
                  to={`${routeMap.fmcCoinsSimulator.selector}`}
                  className="btnBack"
                >
                  <Button
                    onClick={() => localStorage.removeItem('productsChecked')}
                    inputRole={categoryComponent}
                    type="button"
                  />
                </Link>
                <span>Voltar para seleção</span>
              </SeeMoreButton>
            )}
            {productsPagination &&
              productsPagination.current_page !==
                productsPagination.last_page && (
                <SeeMoreButton>
                  <Button
                    inputRole={categoryComponent}
                    type="button"
                    onClick={() => {
                      handleLoad(categoryInterface, productsPagination);
                    }}
                  >
                    <span>+</span>
                  </Button>
                  <span>Veja mais</span>
                </SeeMoreButton>
              )}
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};
export default CardsSaved;
