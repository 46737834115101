import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import getInvoiceStatusSummary from 'services/nf/getInvoiceStatusSummary';

import { InvoiceStatusSummary } from 'services/nf/interfaces';
import HelpIcon from 'components/ExtractProducer/AddNF/Help/HelpIcon';
import coins1 from 'assets/images/fmcProdutor/NF/coins-desktop.svg';
import coins from 'assets/images/fmcProdutor/NF/coins-desktop--2.svg';
import coins2 from 'assets/images/fmcProdutor/NF/coins-desktop--3.svg';

import computer from 'assets/images/fmcProdutor/NF/computador-desktop.png';
import Upload from './Upload';

import {
  Container,
  Content,
  Title,
  RightSideBox,
  AdjustStatusTable,
  ButtonDownload,
} from './styles';

interface Props {
  layout?: string;
}

const AddNF: React.FC<Props> = Props => {
  const history = useHistory();
  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatusSummary>({
    underAnalysis: 0,
    released: 0,
    disqualified: 0,
    awaitingApproval: 0,
  });

  const updateStatus = useCallback(async () => {
    const status = await getInvoiceStatusSummary(0);
    setInvoiceStatus(status);
  }, []);

  const refreshPage = useCallback(() => {
    history.go(0);
  }, [history]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  return (
    <Container>
      <div>
        <img src={coins1} alt="" className="coinsBanner1" />
      </div>
      <div>
        <img src={coins} alt="coins" className="coinsBanner" />
      </div>
      <div className="backgroundComputer" />
      <div className="background3" />
      <div className="background1" />
      <div className="background2" />
      <div className="background4" />
      <img src={computer} className="imgComputer" alt="Computador" />

      {Props.layout !== 'secondary' && (
        <Content>
          <Title>
            Cadastre suas notas fiscais <br /> e garanta seus FMC Coins!
          </Title>
          <div>
            <RightSideBox className="right-side">
              <div className="right-side--box">
                <AdjustStatusTable status={invoiceStatus} display="1" />

                <ButtonDownload onUpdate={() => updateStatus()} />
              </div>
            </RightSideBox>
          </div>
        </Content>
      )}
      {Props.layout === 'secondary' && (
        <Content secondary>
          <Title>
            Cadastre suas notas fiscais <br /> e garanta seus FMC Coins!
          </Title>
          <Upload onUpdate={() => refreshPage()} />
        </Content>
      )}
      <div>
        <img src={coins2} alt="" className="coinsBanner2" />
      </div>
      <HelpIcon initialPosition="right-top" />
    </Container>
  );
};

export default AddNF;
