import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import signInService, { singnInWithProfile } from 'services/auth/signIn';
import isTokenValid from 'services/auth/isTokenValid';
import changeLoginService from 'services/auth/changeLoginProfile';
import isThereAnyRegulationToAccept from 'services/register/regulation/isThereAnyRegulationToAccept';
import { getTokenSimulate } from 'services/participant-simulation';
import { fetchMenu } from 'state/modules/header/actions';
import getLoggedParticipant from 'services/auth/getLoggedParticipant';
import { Participant } from 'services/auth/interfaces/Participant';
import { setToken, setApiMode } from 'services/api';
import history from 'services/history';
import routeMap from 'routes/route-map';
import ExpiredPassword from 'components/Auth/Modals/ExpiratedPassword';
import { getMaintenance } from 'services/blockAccess';
import { useToast } from './ToastContext';
interface Credentials {
  cpf: string;
  password: string;
  tokenMfa: string;
}
interface CredentialsToken {
  token: string;
  isSSOToken?: boolean;
}
interface ChangeLoginRequest {
  establishmentId: number;
  roleId: number;
}
interface CredentialsProfile {
  cpf: string;
  password: string;
  establishment_id: number;
  role_id: number;
}
type CredentialsType = Credentials | CredentialsToken | CredentialsProfile;
type CredentialsTypeReturn =
  | 'credentials'
  | 'credentialsToken'
  | 'credentialsProfile';
interface AuthContextState {
  participant: Participant;
  signed: boolean;
  loading: boolean | undefined;
  isPasswordExpired: boolean;
  isProfileRegisterComplete: boolean;
  shouldShowRegulationsModal: boolean;
  simulating: boolean;
  isLoggedFromAdmin: boolean;
  refreshParticipant(): void;
  signIn(credentials: CredentialsType): Promise<void>;
  signOut(): void;
  simulate(participantId: number): Promise<void>;
  changeProfile(data: ChangeLoginRequest): Promise<void>;
}
const AuthContext = createContext<AuthContextState>({} as AuthContextState);
export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [simulating, setSimulating] = useState(false);
  const [apiToken, setApiToken] = useState<string>('');
  const [participant, setParticipant] = useState<Participant>(
    {} as Participant,
  );
  const [shouldShowRegulationsModal, setShouldShowRegulationsModal] = useState(
    false,
  );
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const { addToast } = useToast();
  const refreshParticipant = useCallback(async () => {
    setLoading(true);
    const [data, isThereRegulationsToAccept] = await Promise.all([
      getLoggedParticipant(),
      isThereAnyRegulationToAccept(),
    ]);
    const typeCredential = localStorage.getItem('@typeCredential');
    if (typeCredential !== 'credentialsToken') {
      setShouldShowRegulationsModal(isThereRegulationsToAccept);
    }
    setParticipant(data);
    setLoading(false);
  }, []);
  useEffect(() => {
    const token = localStorage.getItem('@Vendavall:token');
    if (token) {
      setApiToken(token);
    }
  }, []);
  const signInWithCredentials = useCallback(
    async (credentials: Credentials): Promise<string> => {
      const { token } = await signInService(credentials);
      return token;
    },
    [],
  );
  const signInWithCredentialsToken = useCallback(
    async (credentials: CredentialsToken): Promise<string> => {
      if (credentials.isSSOToken) return credentials.token;
      const { token } = await signInService(credentials.token);
      return token;
    },
    [],
  );
  const sigInWithCredentialsProfile = useCallback(
    async (credentials: CredentialsProfile): Promise<string> => {
      const { token } = await singnInWithProfile(credentials);
      return token;
    },
    [],
  );
  const isCredentialsOrCredentialsToken = useCallback(
    (data: CredentialsType): CredentialsTypeReturn => {
      if ((data as CredentialsProfile).establishment_id)
        return 'credentialsProfile';
      if ((data as Credentials).cpf) return 'credentials';
      return 'credentialsToken';
    },
    [],
  );
  const signIn = useCallback(
    async (data: CredentialsType) => {
      let token = '';
      const credentialsType = isCredentialsOrCredentialsToken(data);
      if (credentialsType === 'credentials') {
        token = await signInWithCredentials(data as Credentials);
        localStorage.setItem('@typeCredential', 'credentials');
      }
      if (credentialsType === 'credentialsToken') {
        token = await signInWithCredentialsToken(data as CredentialsToken);
        localStorage.setItem('@typeCredential', 'credentialsToken');
      }
      if (credentialsType === 'credentialsProfile') {
        token = await sigInWithCredentialsProfile(data as CredentialsProfile);
        localStorage.setItem('@typeCredential', 'credentialsProfile');
      }
      localStorage.setItem('@Vendavall:token', token);
      setToken(token);
      setApiToken(token);
    },
    [
      isCredentialsOrCredentialsToken,
      signInWithCredentials,
      signInWithCredentialsToken,
      sigInWithCredentialsProfile,
    ],
  );
  const signOut = useCallback(() => {
    if (simulating) {
      setApiMode('write');
      const token = localStorage.getItem('@Vendavall:token');
      if (token) {
        setApiToken(token);
        setTimeout(() => {
          setSimulating(false);
          history.push(routeMap.participantSimulation);
        }, 600);
      }
      return;
    }
    localStorage.removeItem('@Vendavall:token');
    localStorage.removeItem('@typeCredential');
    setToken('');
    setApiToken('');
  }, [simulating]);
  const simulate = useCallback(async (participantId: number): Promise<void> => {
    const token = await getTokenSimulate(participantId);
    setSimulating(true);
    setApiMode('readonly');
    setApiToken(token);
    setTimeout(() => {
      history.push('/home');
    }, 700);
  }, []);
  const getCredentialsType = () => {
    const ctype = localStorage.getItem('@typeCredential');
    return ctype;
  };
  useEffect(() => {
    setLoading(true);
    if (!apiToken) {
      setLoading(false);
      return;
    }
    setToken(apiToken);
    setTimeout(() => {
      isTokenValid()
        .then(isValid => {
          if (!isValid) {
            signOut();
            addToast({
              title: 'Sua Sessão expirou, por favor refaça seu login',
              type: 'error',
            });
            return;
          }
          refreshParticipant();
          dispatch(fetchMenu());
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300);
  }, [addToast, signOut, apiToken, refreshParticipant, dispatch]);
  const changeProfile = useCallback(
    async ({ establishmentId, roleId }: ChangeLoginRequest): Promise<void> => {
      const { token } = await changeLoginService({ establishmentId, roleId });
      localStorage.setItem('@Vendavall:token', token);
      setToken(token);
      setApiToken(token);
    },
    [],
  );
  useEffect(() => {
    getMaintenance().then(
      (response: { data: React.SetStateAction<number> }) => {
        if (response.data === 1) {
          signOut();
        }
      },
    );
  }, [signOut]);
  const isParticipantPasswordExpired =
    participant.role && participant.role.expiratedPassword;
  const saveUrl = window.location.pathname;
  const isSigned = !!apiToken;
  /** Indica se o usuário foi logado na plataforma utilizando
   * o login direto do painel de administração. */
  const isLoggedFromAdmin = getCredentialsType() === 'credentialsToken';
  localStorage.setItem('saveUrl', saveUrl);
  return (
    <AuthContext.Provider
      value={{
        participant,
        signed: isSigned,
        isPasswordExpired: participant.role?.expiratedPassword,
        isProfileRegisterComplete:
          participant.role?.complete_registration?.complete,
        refreshParticipant,
        signIn,
        signOut,
        shouldShowRegulationsModal,
        simulating,
        simulate,
        loading,
        changeProfile,
        isLoggedFromAdmin,
      }}
    >
      {children}
      {!loading && !simulating && isParticipantPasswordExpired && isSigned && (
        <ExpiredPassword
          expiredMessage={participant.role.expiratedPasswordMsg || ''}
        />
      )}
    </AuthContext.Provider>
  );
};
export const useAuth = (): AuthContextState => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
