import styled from 'styled-components';

import * as InputStyles from 'components/shared/Input/styles';
import * as PasswordInputStyles from 'components/shared/PasswordInput/styles';

export const Container = styled.div``;

export const Form = styled.form`
  button {
    height: 44px;
  }

  button.btn--outline {
    background: none;
    border: 1px solid #ffff;
    padding: 0;
    height: 40px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const FormInput = styled.div`
  margin-bottom: 10px;

  /** Override input styles */
  ${InputStyles.InputContainer} {
    height: 44px;
  }

  /** Override password input styles */
  ${PasswordInputStyles.InputContainer} {
    height: 44px;
  }
`;

export const FormLostPassword = styled.div`
  text-align: center;
  margin-top: 15px;

  button {
    all: unset;
    background-color: none;
    color: #ffff;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    border-bottom: 1px solid #fff;
    cursor: pointer;
    margin-bottom: 15px;
  }
`;
