import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 420px) {
    margin: 0 20px 0 20px;
  }
`;

export const TableContent = styled.table`
  border-spacing: 0 4px;

  thead {
    background: transparent;
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    height: 50px;
  }

  tbody {
    background: #efefef;
    color: #000;
  }

  th {
    text-align: center;
    width: 200px;

    @media (max-width: 420px) {
      padding: 0 15px;
    }
  }

  td {
    text-align: center;
    font-size: 0.75em;
    height: 50px;

    @media (max-width: 420px) {
      padding: 0 15px;
    }
  }
`;

export const DateUpload = styled.p`
  margin-top: 40px;
  color: rgb(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: bold;

  span {
    font-size: 13px;
    font-weight: bold;
  }
`;
