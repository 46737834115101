import React from 'react';

import ThemeLoading from 'components/shared/ThemeLoading';
import { Container, Link } from './styles';
import { ProductType } from '..';

type ReportType = 'producer' | 'indirect' | 'biologic';
export interface Report {
  title: string;
  url: string;
  type: ReportType;
}

interface Props {
  data: Report[];
  loading?: boolean;
  type: ProductType;
}

const Report: React.FC<Props> = ({ data, loading, type }) => {
  const indirectsReports = data
    ? data.filter(item => item.type === 'indirect')
    : [];
  const producersReports = data
    ? data.filter(item => item.type === 'producer')
    : [];
  const biologicalReports = data
    ? data.filter(item => item.type === 'biologic')
    : [];

  const isTerraBrasil = type === 'terrabrasil';

  return (
    <Container>
      {loading && <ThemeLoading size={32} />}

      {indirectsReports.length > 0 && !loading && (
        <>
          <h3>{isTerraBrasil ? 'Terra Brasil' : 'Juntos Indiretos'}</h3>
          {indirectsReports.map(({ title, url }) => (
            <li key={`report-${title}`}>
              <span>{title}</span>
              <Link href={url} target="_blank">
                Download
              </Link>
            </li>
          ))}
        </>
      )}

      {producersReports.length > 0 && !loading && (
        <>
          <h3>Juntos Produtor</h3>
          {producersReports.map(({ title, url }) => (
            <li key={`report-${title}`}>
              <span>{title}</span>
              <Link href={url} target="_blank">
                Download
              </Link>
            </li>
          ))}
        </>
      )}

      {!isTerraBrasil && biologicalReports.length > 0 && !loading && (
        <>
          <h3>Biológicos</h3>
          {biologicalReports.map(({ title, url }) => (
            <li key={`report-${title}`}>
              <span>{title}</span>
              <Link href={url} target="_blank">
                Download
              </Link>
            </li>
          ))}
        </>
      )}
    </Container>
  );
};

export default Report;
