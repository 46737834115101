import { MemberGroup } from 'services/auth/interfaces/Participant';
import { pluginApi } from 'services/api';

interface Response {
  only_farm: boolean;
}

export async function addFarm(request: MemberGroup) {
  const { data } = await pluginApi.post('/participants/fazendas/addFarm', {
    ...request,
    only_farm: false,
  });

  return data;
}

export async function editFarm(request: MemberGroup) {
  const { data } = await pluginApi.post('/participants/fazendas/editFarm', request);

  return data;
}

export async function deleteFarm(request: MemberGroup) {
  await pluginApi.post('/participants/fazendas/deleteFarm', {
    id: request.id,
  });
}
