import React from 'react';

import { Link } from './styles';

export interface Props {
  url?: string;
}

const DownloadList: React.FC<Props> = ({ url }) => {
  return (
    <Link href={url} target="_blank">
      Baixar lista
    </Link>
  );
};
export default DownloadList;
