import routeMap from 'routes/route-map';
import { RouletteExtract } from 'pages/RouletteExtract';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.rouletteExtract,
    component: RouletteExtract,
    accessPage: 'Extrato de Giros',
  },
];

export default routes;
