import axios from 'axios';

type Response = {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
};

export const fetchCep = async (cep: string) => {
  const sanitized = cep.replace('[^0-9]', '');
  const response = await axios.get<Response>(
    `https://brasilapi.com.br/api/cep/v2/${sanitized}`,
  );

  return response.data;
};
