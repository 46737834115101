import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Regulation,
  RegulationPolicy,
} from 'services/register/regulation/interfaces/IRegulation';
import parser from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import getUrlToForceDownload from 'services/storage/getUrlToForceDownload';

import pdfIcon from 'assets/images/pdf.svg';
import printIcon from 'assets/images/print.svg';
import {
  Container,
  RegulationContent,
  Button,
  BoxActions,
  PrintRef,
  BoxAccept,
  BoxInput,
  BoxText,
} from './styles';

interface Props {
  onAccept(): Promise<void> | void;
  regulation: Regulation | null;
  profile: string | undefined;
  onSetPolicy?: (value: string) => void;
  regulationPolicy?: RegulationPolicy | undefined;
}

const producerPrivacyPolicy = getUrlToForceDownload({
  filename: 'Portal_Juntos_FMC-POLITICA_DE_PRIVACIDADE.pdf',
  url:
    'https://storage.vendavall.com.br/photos/1622492212.60b54434f2f525.21535283.pdf',
});
const producerTermsOfUse = getUrlToForceDownload({
  filename: 'Portal_Juntos_FMC-TERMO_DE_USO.pdf',
  url:
    'https://storage.vendavall.com.br/photos/1622492254.60b5445e8d30e9.15840996.pdf',
});

const DataRegulation: React.FC<Props> = ({
  onAccept,
  regulation,
  profile,
  onSetPolicy,
  regulationPolicy,
}) => {
  const [loading, setLoading] = useState(false);
  const [canAccept, setCanAccept] = useState(false);
  const [policyExposed, setPolicyExposed] = useState(false);
  const [regulationPolicyValid, setRegulationPolicyValid] = useState(false);
  const [profileValidate, setPofileValidate] = useState(false);

  const buttonRole = 'primary';
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    profile !== 'PRODUTOR' ? setPolicyExposed(true) : setPolicyExposed(false);

    if (!regulationPolicy?.message) {
      setRegulationPolicyValid(true);
    }

    if (regulationPolicy?.message && regulationPolicy.status) {
      setRegulationPolicyValid(true);
    }

    if (regulationPolicy?.message && !regulationPolicy.status) {
      setRegulationPolicyValid(false);
      setPolicyExposed(true);
    }
  }, [profile, regulationPolicy]);

  useEffect(() => {
    if (
      profile === 'PRODUTOR' ||
      profile === 'FOCALPOINT' ||
      profile === 'PARTICIPANTE'
    ) {
      setPofileValidate(true);
    }
  }, [profile]);
  const handleAcceptClick = useCallback(async () => {
    setLoading(true);
    try {
      await onAccept();
    } catch {}

    setLoading(false);
  }, [onAccept]);

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (value) {
      setPolicyExposed(true);
      if (onSetPolicy) onSetPolicy(value);
    }
  };

  const handlePdfDownload = useCallback(async () => {
    if (!regulation) return;
    const url = 'https://s3.amazonaws.com/fmc-juntos/photos/1706719914.65ba7aaacd7543.11833696.pdf';

    const linkClick = document.createElement('a');
    linkClick.href = url;
    linkClick.download = 'Termo_de_segurança_de_dados.pdf';
    document.body.appendChild(linkClick);
    linkClick.target = '_blank';
    linkClick.click();
    document.body.removeChild(linkClick);
  }, [regulation]);

  return (
    <Container>
      <RegulationContent type="primary">
        {/* <h3>{regulation?.name}</h3> */}
        <div>
          <PrintRef ref={printRef}>
            {parser(regulation?.content || '')}
          </PrintRef>
        </div>
      </RegulationContent>
      <BoxActions>
        <button type="submit" onClick={handlePdfDownload}>
          <img src={pdfIcon} alt="Ícone botão salvar PDF" />
          Download (PDF)
        </button>
        <ReactToPrint
          trigger={() => {
            return (
              <button type="button">
                <img src={printIcon} alt="Ícone botão imprimir" />
                Imprimir
              </button>
            );
          }}
          content={() => printRef.current}
        />
      </BoxActions>

      <BoxAccept>
        <span>
          {profile === 'PRODUTOR' && (
            <div>
              “Li e aceito os <a href={producerTermsOfUse}>Termos de Uso</a> e{' '}
              <a href={producerPrivacyPolicy}>Política de Privacidade</a>
              ”, tenho compreensão e estou de acordo com esses Termos, bem como
              concordo e autorizo para a finalidade a que se destina, sem
              reservas.
            </div>
          )}
          {profile !== 'PRODUTOR' && (
            <div>
              “Li e aceito os{' '}
              <a href="https://juntosfmc-adm.vendavall.com.br/download?name=Portal_Juntos_FMC-TERMO_DE_USO.pdf&url=https://storage.juntosfmc.com.br/avatar/1597870012.5f3d8fbc16bc67.86487857.pdf">
                Termos de Uso
              </a>{' '}
              e{' '}
              <a href="https://juntosfmc-adm.vendavall.com.br/download?name=Portal_Juntos_FMC-POL%C3%8DTICA_DE_PRIVACIDADE.pdf&url=https://storage.juntosfmc.com.br/avatar/1598359154.5f450672673807.86869023.pdf">
                Política de Privacidade
              </a>
              ”, tenho compreensão e estou de acordo com esses Termos, bem como
              concordo e autorizo para a finalidade a que se destina, sem
              reservas.
            </div>
          )}
        </span>
      </BoxAccept>
      <BoxInput>
        <input
          type="checkbox"
          name="accept"
          checked={canAccept}
          onChange={() => {
            setCanAccept(e => !e);
          }}
        />
        <span>Estou de acordo</span>
      </BoxInput>

      {profileValidate && regulationPolicyValid && (
        <>
          <BoxAccept>
            <select
              name="policyExposed"
              id="policyExposed"
              onChange={selectChange}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
              <option defaultValue="" disabled selected>
                ---
              </option>
            </select>
            <p>Você se considera uma pessoa politicamente exposta?</p>
          </BoxAccept>
          <BoxText>
            <p>
              São consideradas pessoas politicamente expostas: o agente público
              ou político eleito, candidato a qualquer cargo político ou de
              agente político, bem como seus parentes até segundo grau ou
              pessoas com relacionamento próximo, durante o desempenho ou que
              tenham desempenhado tal função nos últimos 5 anos.
            </p>
          </BoxText>
          <BoxText>
            <p>
              Por agente público entende-se a pessoa física ou jurídica que
              presta qualquer serviço ao Estado ou às pessoas jurídicas da
              administração pública indireta, por exemplo, servidores públicos,
              agentes políticos, militares ou ainda particulares em colaboração
              com o poder público, ou aqueles equiparados ao agente público no
              exercício de cargo ou função.
            </p>
          </BoxText>
        </>
      )}

      <Button
        type="button"
        buttonRole={buttonRole}
        onClick={handleAcceptClick}
        loading={loading}
        disabled={!canAccept || !policyExposed}
      >
        Quero me cadastrar
      </Button>
      {/* <span>
        O botão "Aceito Participar" será habilitado após a leitura do
        regulamento na integra.
      </span> */}
    </Container>
  );
};

export default DataRegulation;
