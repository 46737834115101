import styled from 'styled-components';

export const Container = styled.div`
  .title {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 18px;
  }

  .wrapper {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .loading-wrapper {
    display: flex;
    place-content: center;
    padding: 25px 0;
    min-height: 150px;

    strong {
      color: #000;
      opacity: 0.5;
    }
  }

  .cards-wrapper {
    padding: 0 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 25px;
  }
`;

export const HeadWrapper = styled.div`
  padding: 50px 0;
  background-image: url('/images/dist-banner.jpeg');
  background-repeat: no-repeat;
  background-size: cover;

  .wrapper > strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 24px;
  }

  .fields {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 20px;
    margin-top: 20px;

    @media screen and (max-width: 590px) {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  }
`;

export const TabsWrapper = styled.div`
  margin: 25px 0;

  > strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 18px;
  }
`;

export const TabContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  margin-top: 20px;

  @media screen and (max-width: 825px) {
    padding: 20px 15px;
  }

  @media screen and (max-width: 590px) {
    padding: 15px 15px;
    flex-wrap: wrap;
  }

  .tab {
    all: unset;
    width: 100%;
    padding: 16px 10px;
    background-color: #efefef;
    text-align: center;
    user-select: none;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 150ms ease-in-out, background 150ms ease-in-out;

    strong {
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: #707070;
      font-size: 16px;

      @media screen and (max-width: 520px) {
        font-size: 14px;
      }
    }

    &[data-selected='true']:not(&.status--waiting, &.status--approved, &.status--reproved) {
      background-color: ${({ theme }) => theme.font.color.primary};

      strong {
        color: #fff;
      }
    }

    &[data-selected='true']:is(&.status--waiting, &.status--approved, &.status--reproved) {
      opacity: 1;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }

    &.status {
      &--waiting {
        opacity: 0.5;
        background-color: #821c0e;

        svg,
        strong {
          color: #fff;
        }
      }
      &--approved {
        opacity: 0.5;
        background-color: #9bffd1;

        svg,
        strong {
          color: #437e63;
        }
      }
      &--reproved {
        opacity: 0.5;
        background-color: #ffc1be;

        svg,
        strong {
          color: #e4001b;
        }
      }
    }
  }
`;
