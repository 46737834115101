import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import BaseSelect from 'components/shared/Select/BaseSelect';
import { fetchCampaignsService } from 'services/goals';

interface Props {
  className?: string;
  label?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  placeholder?: string;
  options?: Option[];
}

const SafraSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
  label,
  placeholder,
  options: propOptions,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchHarvests = async () => {
      const result = (await fetchCampaignsService()) ?? [];
      const slicedResult = result.slice(0, 4);
      setOptions(
        slicedResult.map<Option>(({ id, title, Grupo }) => ({
          ...result,
          title,
          value: id.toString(),
          isTerraBrasil: Grupo,
        })),
      );
    };
    if (!propOptions) {
      fetchHarvests();
    } else {
      setOptions(propOptions);
    }
  }, [propOptions]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <BaseSelect
      label={label}
      loadItems={loadItems}
      className={className}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      inputRole="secondary"
    />
  );
};

export default SafraSelect;
