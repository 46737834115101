import { pluginApi } from 'services/api';
import { fakeFormatDollars } from 'util/points';
import { Points } from './interfaces/channel';

export interface ApiResponse {
  pointsAvaibleChannel: {
    prizeCatalog: number;
    generationDemand: number;
    developmentActions: number;
    businessGeneration: number;
    igdPointsAvailable: number;
  };
  programPoints: {
    generationDemand: number;
    sellerDistributedPoints: number;
    financialReturn: number;
    rebate: number;
    additionalMargin: number;
    igdBalance: number;
    biological: number;
    totalPoinsChannel: number;
  };
}

const mock = {
  pointsAvaibleChannel: {
    prizeCatalog: 0,
    generationDemand: 0,
    developmentActions: 0,
    businessGeneration: 0,
    igdPointsAvailable: 0,
  },
  programPoints: {
    sellerDistributedPoints: 0,
    financialReturn: 0,
    rebate: 0,
    generationDemand: 0,
    additionalMargin: 0,
    igdBalance: 0,
    biological: 0,
    totalPoinsChannel: 0,
  },
};

const fake = false;

const transformer = (data: ApiResponse): Points => {
  return {
    prizeCatalog: data.pointsAvaibleChannel.prizeCatalog || 0,
    generationDemand: data.pointsAvaibleChannel.generationDemand || 0,
    developmentActions: data.pointsAvaibleChannel.developmentActions || 0,
    businessGeneration: data.pointsAvaibleChannel.businessGeneration || 0,
    sellerDistributedPoints: data.programPoints.sellerDistributedPoints || 0,
    financialReturn: data.programPoints.financialReturn || 0,
    rebate: data.programPoints.rebate || 0,
    additionalMargin: data.programPoints.additionalMargin || 0,
    igdBalance: data.programPoints.igdBalance || 0,
    biological: data.programPoints.biological || 0,
    totalPoinsChannel: data.programPoints.totalPoinsChannel || 0,
    igdPointsAvailable: data.pointsAvaibleChannel.igdPointsAvailable || 0,
    formattedChannelGenerationDemand: fakeFormatDollars(
      data.pointsAvaibleChannel.generationDemand || 0,
    ),
    formattedPrizeCatalog: fakeFormatDollars(
      data.pointsAvaibleChannel.prizeCatalog || 0,
    ),
    formattedDevelopmentActions: fakeFormatDollars(
      data.pointsAvaibleChannel.developmentActions || 0,
    ),
    formattedSellerDistributedPoints: fakeFormatDollars(
      data.programPoints.sellerDistributedPoints || 0,
    ),
    formattedFinancialReturn: fakeFormatDollars(
      data.programPoints.financialReturn || 0,
    ),
    formattedRebate: fakeFormatDollars(data.programPoints.rebate || 0),
    formattedAdditionalMargin: fakeFormatDollars(
      data.programPoints.additionalMargin || 0,
    ),
    formattedProgramGenerationDemand: fakeFormatDollars(
      data.programPoints.generationDemand || 0,
    ),
    formattedBiological: fakeFormatDollars(data.programPoints.biological || 0),
    formattedTotalPoinsChannel: fakeFormatDollars(
      data.programPoints.totalPoinsChannel || 0,
    ),
    formattedBusinessGeneration: fakeFormatDollars(
      data.pointsAvaibleChannel.businessGeneration || 0,
    ),
    formattedIgdBalance: fakeFormatDollars(data.programPoints.igdBalance || 0),
    formattedIgdPointsAvailable: fakeFormatDollars(
      data.pointsAvaibleChannel.igdPointsAvailable || 0,
    ),
  };
};

export default async (
  establishmentId: number,
  campaignId: number,
): Promise<Points> => {
  if (fake) {
    return transformer(mock);
  }

  const { data } = await pluginApi.get<ApiResponse>(
    `cockpit/points-statistics?establishment_id=${establishmentId}&campaign_id=${campaignId}`,
  );
  return transformer(data);
};
