import { pluginApi } from 'services/api';

type Params = {
  establishmentId: number;
  campaignId: number;
};

interface ResponsePointDistributions {
  distribution: {
    approval_status: number;
    approval_status_name: string;
    balance_unit_id: number;
    balance_unit_name: string;
    establishment_id: number;
    id: number;
    max_porcentage: number;
    undistributed_point_id: number;
    value: number;
  };
}

export async function getTeamPointsDistribution({
  establishmentId,
  campaignId,
}: Params): Promise<ResponsePointDistributions> {
  const { data } = await pluginApi.get(
    `/points-distribution/team-points-distribution?establishment_id=${establishmentId}&campaign_id=${campaignId}`,
  );
  return data;
}
