import { pluginApi } from 'services/api';

interface Response {
  produtor: boolean;
}

export default async (): Promise<boolean> => {
  try {
    const {
      data: { produtor },
    } = await pluginApi.get<Response>('/participants/produtor/status');
    return produtor;
  } catch (error) {
    return false;
  }
};
