import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';

import { AdditionalMargin, Potentializer } from 'state/modules/goals/types';
import { useAuth } from 'context/AuthContext';
import newTab from 'assets/images/new-tab.png';
import CircularProgress from './CircularProgress';

import {
  Wrapper,
  PerformanceWrapper,
  ProgressWrapper,
  IndividualProgressWrapper,
  Label,
  CompletePerformanceWrapper,
  List,
  Separator,
  Item,
  CustomSlider,
  MobileItemContainer,
  Goals,
} from './styles';

export interface Props {
  realized: {
    bilingPercent: number;
    pogPercent: number;
    // pogVolumePercent: number;
    premioPercent: number;
    heroPercent: number;
    talismanPercent: number;
  };
  potentializers: Potentializer[];
  additionalMargin: AdditionalMargin;
}

const Performance: React.FC<Props> = ({
  realized: { bilingPercent, pogPercent },
  potentializers,
  additionalMargin,
}) => {
  const { participant } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const dinamicTitle =
    participant.establishment.type_name === 'Terra Brasil'
      ? 'POG Fornecedor'
      : 'POG';

  const orderProducts = potentializers.sort((a, b) => a.position - b.position);

  const shouldDisplayAdditionalMargin = additionalMargin
    ? Object.values(additionalMargin).some(value => value > 0)
    : false;

  const shouldDisplayAll = additionalMargin
    ? Object.values(additionalMargin).some(value => value > 0) ||
      potentializers.length > 0
    : false;

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow:
      participant.establishment.type_name === 'Terra Brasil' ? 3 : 4,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isTerraBrasil = participant.establishment.type_name !== 'Terra Brasil';

  return (
    <Wrapper isTerraBrasil={isTerraBrasil}>
      <>
        {isMobile ? (
          <>
            <Goals>
              <IndividualProgressWrapper noData={!shouldDisplayAll}>
                <Label>FATURAMENTO</Label>
                <CircularProgress
                  color="#FF4C16"
                  percent={bilingPercent || 0}
                />
              </IndividualProgressWrapper>
              <IndividualProgressWrapper noData={!shouldDisplayAll}>
                <Label>{dinamicTitle}</Label>
                <CircularProgress color="#25CCE1" percent={pogPercent || 0} />
              </IndividualProgressWrapper>
              {shouldDisplayAdditionalMargin && (
                <IndividualProgressWrapper className="add-margim">
                  <Label>MARGEM ADICIONAL</Label>

                  <CircularProgress
                    color="#47C246"
                    percent={additionalMargin.porcentagem || 0}
                  />
                </IndividualProgressWrapper>
              )}
            </Goals>
            <MobileItemContainer>
              {orderProducts &&
                potentializers &&
                orderProducts.map(potentializer => (
                  <IndividualProgressWrapper key={potentializer.position}>
                    <Label>{potentializer.name}</Label>
                    <CircularProgress
                      color={potentializer.color}
                      percent={potentializer.percentage || 0}
                    />
                  </IndividualProgressWrapper>
                ))}
            </MobileItemContainer>
          </>
        ) : (
          <>
            <PerformanceWrapper noData={!isMobile}>
              <IndividualProgressWrapper noData={!shouldDisplayAll}>
                <Label>FATURAMENTO</Label>
                <CircularProgress
                  color="#FF4C16"
                  percent={bilingPercent || 0}
                />
              </IndividualProgressWrapper>
              <IndividualProgressWrapper noData={!shouldDisplayAll}>
                <Label>{dinamicTitle}</Label>
                <CircularProgress color="#25CCE1" percent={pogPercent || 0} />
              </IndividualProgressWrapper>
              {shouldDisplayAdditionalMargin && (
                <IndividualProgressWrapper className="add-margim">
                  <Label>MARGEM ADICIONAL</Label>
                  <CircularProgress
                    color="#47C246"
                    percent={additionalMargin.porcentagem || 0}
                  />
                </IndividualProgressWrapper>
              )}
              {potentializers.length > 0 ? (
                <>
                  <Separator />
                  <CustomSlider {...settings}>
                    {orderProducts.map(potentializer => (
                      <Item key={potentializer.position}>
                        <ProgressWrapper>
                          <List>
                            <IndividualProgressWrapper>
                              <Label $isSpecial={potentializer.name === 'Inseticidas by Rynaxypyr'}>
                                 {potentializer.name}
                              </Label>
                              <CircularProgress
                                color={potentializer.color}
                                percent={potentializer.percentage || 0}
                              />
                            </IndividualProgressWrapper>
                          </List>
                        </ProgressWrapper>
                      </Item>
                    ))}
                  </CustomSlider>
                </>
              ) : (
                <CompletePerformanceWrapper>
                  <Link
                    to={routeMap.goal}
                    style={{
                      width: '420px',
                      height: '40px',
                      display: 'flex', // Usa flexbox para organizar o conteúdo
                      justifyContent: 'center', // Centraliza o texto horizontalmente
                      alignItems: 'center', // Centraliza o texto verticalmente
                      whiteSpace: 'nowrap', // Evita que o texto quebre em várias linhas
                      textAlign: 'center', // Alinha o texto ao centro
                      marginLeft: '400px', // Ajuste extra de espaçamento à esquerda para o conteúdo
                    }}
                  >
                    CONFIRA SEU DESEMPENHO COMPLETO
                    <img
                      src={newTab}
                      alt="new-tab"
                      style={{
                        display: 'flex', // Usa flexbox para alinhar o conteúdo
                        justifyContent: 'center', // Centraliza horizontalmente
                        alignItems: 'center', // Centraliza verticalmente
                        height: '100%', // Preenche o espaço do slider
                      }}
                    />
                  </Link>
                </CompletePerformanceWrapper>
              )}
            </PerformanceWrapper>
            {/* Exibe o CompletePerformanceWrapper abaixo do slider se houver potentializers */}
            {potentializers.length > 0 && (
              <CompletePerformanceWrapper>
                <Link
                  to={routeMap.goal}
                  style={{
                    width: '320px',
                    height: '40px',
                    display: 'block',
                  }}
                >
                  CONFIRA SEU DESEMPENHO COMPLETO
                  <img
                    src={newTab}
                    alt="new-tab"
                    style={{
                      position: 'relative',
                      left: '0.2rem',
                      top: '0.4rem',
                    }}
                  />
                </Link>
              </CompletePerformanceWrapper>
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};
export default Performance;
