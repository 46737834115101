import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';

import Select, { Option } from 'components/shared/Select';
import { usePrevious } from 'hooks/use-previus';
import { EstablishmentTypes } from 'config/constants';
import {
  getEstablishmentList,
  Establishment,
} from './services/getEstablishmentList';

import { Container } from './styles';

type Props = {
  onEstablishmentChange: (establishment: {
    id: string;
    name: string;
    type: EstablishmentTypes;
  }) => void;
};

type FormData = {
  establishment: Option;
};

export const EstablishmentSelect = ({ onEstablishmentChange }: Props) => {
  const [establishments, setEstablishments] = useState<Establishment[]>([]);
  const [showError, setShowError] = useState(false);
  const methods = useForm<FormData>();

  const selectedEstablishment = methods.watch(['establishment']);
  const previusSelectedEstablishment = usePrevious<FormData>(
    selectedEstablishment,
  );

  const options = establishments.map(establishment => ({
    title: establishment.client_group,
    value: String(establishment.id),
  }));

  options.sort((a, b) => a.title.localeCompare(b.title));

  useEffect(() => {
    getEstablishmentList()
      .then(ests => {
        setEstablishments(ests);
      })
      .catch(() => setShowError(true));
  }, []);

  useEffect(() => {
    if (
      selectedEstablishment.establishment &&
      previusSelectedEstablishment?.establishment?.value !==
        selectedEstablishment.establishment?.value
    ) {
      const establishment = establishments.find(
        est => String(est.id) === selectedEstablishment.establishment.value,
      );

      if (establishment) {
        onEstablishmentChange({
          id: String(establishment.id),
          name: establishment.client_group,
          type: establishment.type.name,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEstablishment]);

  return (
    <FormContext {...methods}>
      <Container>
        <Select
          inputRole="primary"
          className="establishment-select"
          name="establishment"
          placeholder="Selecione um canal"
          loadItems={() => options}
          disabled={showError}
        />
        {showError && (
          <span className="error">Falha ao carregar os estabelecimentos</span>
        )}
      </Container>
    </FormContext>
  );
};
