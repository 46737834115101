import { pluginApi } from 'services/api';
import { Campaign, StatusText } from './interfaces/Campaign';
import { CampaignApi } from './interfaces/CampaignApi';
import { campaignApiToCampaign } from './transformers';

interface ApiResponse {
  data: CampaignApi[];
  details: any;
}

interface Response {
  data: Campaign[];
  details: any;
}

export interface FilterOptions {
  directorId?: string;
  regionalId?: string;
  customerId?: number;
  status?: StatusText;
  mechanicId?: number;
  campaignId?: number;
}

export default async (filters?: FilterOptions): Promise<Response> => {
  let extraSearch = '';
  if (filters) {
    const {
      status,
      customerId,
      directorId,
      mechanicId,
      regionalId,
      campaignId,
    } = filters;
    if (mechanicId) {
      extraSearch += `?types[0]=${mechanicId}`;
    }
    if (customerId) {
      extraSearch += `?establishments[0]=${customerId}`;
    }
    if (directorId) {
      extraSearch += `?directorships[0]=${directorId}`;
    }
    if (status) {
      extraSearch += `?status[0]=${status}`;
    }
    if (campaignId) {
      extraSearch += `?campaign_id[0]=${campaignId}`;
    }
    if (regionalId) {
      extraSearch += `?regional[0]=${regionalId}`;
    }
  }

  const {
    data: { data, details },
  } = await pluginApi.get<ApiResponse>(`fmc-campaigns${extraSearch}`);
  return {
    data: data.map(item => campaignApiToCampaign(item)),
    details,
  };
};
