import styled from 'styled-components';

export const MiniBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 1030px;
  justify-content: space-around;

  .total-fmc-coins {
    background-color: #3b302a;
  }

  .total-value {
    background-color: #65554d;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    height: 83px;

    > span {
      width: 267px;
      height: 20px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      margin-top: 5px;

      & + span {
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: start;

  > div {
    opacity: 1;
    transition: opacity 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: 1000px) {
    display: flex;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 380px);

  .container-buttons {
    display: flex;
    align-items: center;
    width: 75%;
    margin-top: 2rem;
  }

  .content-simulation {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    span {
      color: #000;
    }
  }

  .buttons-desktop {
    display: flex;
    margin-left: auto;
  }

  .btn-save,
  .btn-back {
    width: auto;
    height: 45px;
    margin-left: 10px;
    color: #fff;
    &:first-child {
      width: 200px;
      background-color: #db0320;
    }
    &:last-child {
      width: 160px;
      background-color: ${({ theme }) => theme.font.color.primary};
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 265px;
    max-width: 360px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 3em;
    background-color: ${({ theme }) => theme.font.color.primary};
    font-style: normal;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;

    @media screen and (max-width: 720px) {
      font-size: 20px;
      margin-top: auto;
    }
  }

  .mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 350px;
    width: 100%;

    > div {
      width: 100%;
      max-width: 170px;
    }

    @media screen and (min-width: 1000px) {
      display: none;
    }
  }
`;

export const ContentMobile = styled.div`
  display: flex;

  .btn-save-mobile,
  .btn-back-mobile {
    height: 45px;
    background: #db041b;
    color: #fff;
    &:last-child {
      margin-left: 10px;
    }
    &:last-child {
      background-color: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  margin-top: 4em;

  > span {
    font-size: 14px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-left: 1px;

    & + span {
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      margin-left: 36px;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 0 20px;
    font-size: 16px;
  }
`;

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  margin-bottom: 3px;

  @media screen and (max-width: 720px) {
    margin-bottom: 5px;
  }
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1030px;
  height: 1px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 720px) {
    width: 100%;
    max-width: 350px;
  }
`;

export const CatalogDesktop = styled.div`
  margin-top: 13%;
  box-shadow: 2px 10px 5px #888888; /* Adjusted the box-shadow value */
  border-radius: 10px 10px 0px 10px;
  padding: 25px 105px;
  width: auto;
  background: #f1f1ef;
  display: flex;
  align-items: center;

  h4 {
    position: relative;
    top: -0.5rem;
    color: #3b302a;
    font-size: 1.1rem;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  button {
    width: auto;
    height: 45px;
    position: relative;
    top: -1rem;
    left: 2rem;
    background: #db0320;
    color: #fff;
  }
`;

export const CatalogMobile = styled.div`
  margin-top: 13%;
  box-shadow: 2px 10px 5px #888888; /* Adjusted the box-shadow value */
  gap: 20px;
  border-radius: 10px 10px 0px 10px;
  width: 90%;
  background: #f1f1ef;
  display: flex;
  align-items: center;
  flex-direction: column;

  h4 {
    position: relative;
    top: 0.5rem;
    color: #3b302a;
    text-align: center;
    font-size: 0.8rem;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  button {
    width: auto;
    height: 30px;
    position: relative;
    top: -1rem;
    background: #db0320;
    color: #fff;
  }
`;
