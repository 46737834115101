import { pluginApi } from 'services/api';

export interface ClientGroup {
  id: string;
  name: string;
}
interface EstablishmentsApiItem {
  id: number;
  grupo_de_cliente: string;
}

interface EstablishmentsApi {
  establishments: EstablishmentsApiItem[];
}

const transformToClientGroups = (
  establishments: EstablishmentsApiItem[],
): ClientGroup[] => {
  return establishments.map(item => ({
    id: item.id.toString(),
    name: item.grupo_de_cliente,
  }));
};

export const getClientGroups = async (): Promise<ClientGroup[]> => {
  const endpoint =
    '/participants/establishments/get-by-participant-id-and-role';
  const {
    data: { establishments },
  } = await pluginApi.get<EstablishmentsApi>(endpoint);

  return transformToClientGroups(establishments);
};
