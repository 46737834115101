import routeMap from 'routes/route-map';
import Auth from 'pages/Auth';
import Autologin from 'pages/Auth/Autologin';
import Sso from 'pages/Auth/Sso';
import RegisterEdit from 'pages/Auth/RegisterEdit';
import Regulation from 'pages/Auth/Regulation';
import ReportIncome from 'pages/ReportIncome';
import { PrimeiroAcesso } from 'pages/primeiro-acesso';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: '/',
    component: Auth,
    accessPage: 'Página de login',
    isPublic: true,
  },
  {
    path: '/autologin',
    component: Autologin,
    accessPage: 'Página de login automático',
    special: true,
  },
  {
    path: '/auto-login',
    component: Autologin,
    accessPage: 'Página de login automático',
    special: true,
  },
  {
    path: '/login/auto-login',
    component: Autologin,
    accessPage: 'Página de login automático',
    special: true,
  },
  {
    path: '/login/autologin',
    component: Autologin,
    accessPage: 'Página de login automático',
    special: true,
  },
  {
    path: '/sso',
    component: Sso,
    accessPage: 'Página de login automático via SSO',
    special: true,
  },
  {
    path: routeMap.primeiroAcesso,
    component: PrimeiroAcesso,
    accessPage: 'Página de primeiro acesso (nova)',
    isPublic: true,
  },
  {
    path: '/recover',
    component: Auth,
    accessPage: 'Página de recuperação de senha',
    isPublic: true,
  },
  {
    path: routeMap.profile,
    component: RegisterEdit,
    accessPage: 'Página de edição de cadastro',
  },
  {
    path: routeMap.regulation,
    component: Regulation,
    accessPage: 'Página de regulamentos',
  },
  {
    path: routeMap.reportIncome,
    component: ReportIncome,
    accessPage: 'Informe de Rendimentos',
  },
];

export default routes;
