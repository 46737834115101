import React, { createContext, useState, useEffect, useContext } from 'react';
import { FetchParticipantsRawData } from 'services/point-management/team-awards';
import { fetchTeam } from './services';

type TeamData = {
  participants: FetchParticipantsRawData | null;
  totalParticipants: number;
};

type Context = {
  team: TeamData;
  isFetching: boolean;
  isFetched: boolean;
  fetchError: boolean;
  campaignId: number;
  establishmentId: number;
};

type Props = {
  establishmentId: number;
  campaignId: number;
};

const TeamContext = createContext<Context>({} as Context);

export const TeamProvider: React.FC<Props> = ({
  children,
  establishmentId,
  campaignId,
}) => {
  const [fetchState, setFetchState] = useState<'loading' | 'error' | 'success'>(
    'loading',
  );

  const [team, setTeam] = useState<TeamData>({
    totalParticipants: 0,
    participants: null,
  });

  useEffect(() => {
    setFetchState('loading');
    fetchTeam({
      establishmentId,
    })
      .then((resp: any) => {
        setTeam(resp);
        setFetchState('success');
      })
      .catch(() => setFetchState('error'));
  }, [establishmentId]);

  return (
    <TeamContext.Provider
      value={{
        campaignId,
        establishmentId,
        team,
        isFetching: fetchState === 'loading',
        isFetched: fetchState === 'success',
        fetchError: fetchState === 'error',
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => useContext(TeamContext);
