import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  margin-top: 5em;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  width: 100%;
  max-width: 920px;
  justify-content: flex-end;
  padding: 0 5px;
`;

interface ListProps {
  isSelected: boolean;
}

export const List = styled.button<ListProps>`
  position: relative;
  background-color: #efefef;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  border-radius: 12px 12px 0 0;
  color: ${({ theme }) => theme.font.color.primary};
  min-width: 140px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

  &:nth-child(n + 2) {
    margin-right: -12px;
    padding: 10px 4px 10px 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.font.color.primary};
    color: ${({ theme }) => theme.font.color.tertiary};
    font-weight: bold;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.font.color.primary};
      color: ${({ theme }) => theme.font.color.tertiary};
      font-weight: bold;
    `}
`;
