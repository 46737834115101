import styled from 'styled-components';

export const Container = styled.div`
  font-size: 1.3em;
  margin: 2rem;

  h3 {
    margin-bottom: 2rem;
  }

  p {
    width: 80%;
  }

  @media (max-width: 600px) {
    margin: 2rem;
  }
`;
