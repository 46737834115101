import React, { useEffect, useState } from 'react';

import { Modal, Content } from './styles';

interface Props {
  isOpen: boolean;
  signOut(): void;
}

const Success: React.FC<Props> = ({ isOpen, signOut }) => {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (!success) {
      setTimeout(() => {
        signOut();
      }, 3000);
    } else {
      setSuccess(true);
    }
  }, [signOut, success]);

  return (
    <Modal isOpen={isOpen} type="primary">
      <Content>
        <h3>Obrigado por sua resposta.</h3>
        <h4>O seu cadastro será cancelado do nosso sistema.</h4>
      </Content>
    </Modal>
  );
};

export default Success;
