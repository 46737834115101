import React from 'react';

import { Product } from 'services/strategic-plans/actions/interfaces';
import { Container } from './styles';
import Item from './Item';

interface SelectedProductsListProps {
  selectedProducts: Product[];
  removeClickHandler: (id: number) => void;
  canEdit?: boolean;
}

const SelectedProductsList: React.FC<SelectedProductsListProps> = ({
  selectedProducts,
  removeClickHandler,
  canEdit,
}) => {
  return (
    <Container>
      {selectedProducts?.map(product => (
        <Item
          key={product.id}
          product={product}
          removeClickHandler={removeClickHandler}
          canEdit={canEdit}
        />
      ))}
    </Container>
  );
};

export default SelectedProductsList;
