/* eslint-disable react/static-property-placement */

export interface PointDistributions {
  approval_status: number;
  approval_status_name: string;
  campaign_id: number;
  balance_unit_id: number;
  balance_unit_name: string;
  establishment_id: number;
  id: number;
  max_porcentage: number;
  undistributed_point_id: number;
  value: number;
}

export type Participants = {
  id: number;
  value: number;
};

export interface PointDistributionTeam {
  undistributed_point_id: number;
  value_total: number;
  campaign_id: number;
  point_type_id: number;
  establishment_id: number;
  participants: Participants[];
}

export interface PointDistributed extends Point {
  profile: string;
  participant_id: number;
}

export type Point = PointDistributions & {
  approval_status: number;
  approval_status_name: string;
  demand_generation_points: string;
  distributions: PointDistributions[];
  name_point: string;
  total: number;
};

export type PointsStatus =
  | 'approved'
  | 'waiting_approved'
  | 'waiting_distribution'
  | 'disapproved'
  | 'published';

export type PointDistributorData = {
  [status in PointsStatus]: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    [undistributed_point_id: string]: Point;
  };
};

export enum ApprovalStatus {
  AGUARDANDO_PUBLICACAO = 1,
  AGUARDANDO_APROVACAO = 2,
  APROVADO = 3,
  REPROVADO = 4,
  PUBLICADO = 5,
}
