import { Product, Indicator, IndicatorType } from '../interfaces';
import {
  calculateSimulatedRevenues,
  calculateSimulatedProduct,
  calculateSimulatedPog,
  calculateSimulatedRevenuesBiological,
  calculateSimulatedPogBiological,
  calculateSimulatedProductTerraBrasil,
} from './calculate-simulation';

const enhancerProductsIndicatorTypesTerraBrasil = [
  IndicatorType.aurora,
  IndicatorType.hero,
  IndicatorType.talisman,
  IndicatorType.altacor,
  IndicatorType.boralFull,
  IndicatorType.quartzo,
  IndicatorType.stone,
  IndicatorType.veriMark,
  // IndicatorType.reator,
  // IndicatorType.pogVolume,
  IndicatorType.premioStar,
];

const enhancerProductsIndicatorTypes = [
  IndicatorType.fungicidas,
  IndicatorType.herbicidas,
  IndicatorType.inseticidas,
  IndicatorType.inseticidasByRynaxypyr,
];

export default (products: Product[], indicators: Indicator[]): Indicator[] => {
  return indicators.map(
    (item): Indicator => {
      const indicator = { ...item };
      if (indicator.type === IndicatorType.revenues) {
        const revenuesSimulationData = calculateSimulatedRevenues({
          products,
          indicator,
        });

        indicator.simulationData = revenuesSimulationData;
      }

      if (indicator.type === IndicatorType.pog) {
        const pogSimulationData = calculateSimulatedPog({
          products,
          indicator,
        });

        indicator.simulationData = pogSimulationData;
      }

      if (enhancerProductsIndicatorTypes.includes(indicator.type)) {
        const productSimulationData = calculateSimulatedProduct({
          products,
          indicator,
        });

        indicator.simulationData = productSimulationData;
      }

      if (enhancerProductsIndicatorTypesTerraBrasil.includes(indicator.type)) {
        const productSimulationData = calculateSimulatedProductTerraBrasil({
          products,
          indicator,
        });

        indicator.simulationData = productSimulationData;
      }

      if (indicator.type === IndicatorType.revenuesBiological) {
        const revenuesSimulationData = calculateSimulatedRevenuesBiological({
          products,
          indicator,
        });

        indicator.simulationData = revenuesSimulationData;
      }

      if (indicator.type === IndicatorType.pogBiological) {
        const pogSimulationData = calculateSimulatedPogBiological({
          products,
          indicator,
        });

        indicator.simulationData = pogSimulationData;
      }

      return indicator;
    },
  );
};
