import {
  ActionCreator,
  ActionCreatorPayload,
  ActionCreatorFailure,
} from '@types';
import { Pagination } from 'config/constants/vendavallPaginationInterface';
import { FmcCoinsSimulatorState, FmcProducts } from './interfaces';
import { FmcProductsCheck, FmcProductsValue } from './types';

import * as constants from './constants';

export const fetchFmcProducts = (
  data: Pagination,
): ActionCreatorPayload<
  typeof constants.FETCH_FMC_PRODUCTS_ACTION,
  Pagination
> =>
  <const>{
    type: constants.FETCH_FMC_PRODUCTS_ACTION,
    payload: data,
  };

export const fetchFmcProductsSuccess = (
  data: FmcProducts[],
): ActionCreatorPayload<
  typeof constants.FETCH_FMC_PRODUCTS_SUCCESS,
  FmcProducts[]
> =>
  <const>{
    type: constants.FETCH_FMC_PRODUCTS_SUCCESS,
    payload: data,
  };

export const fetchFmcProductsFailure = (
  error: string,
): ActionCreatorFailure<typeof constants.FETCH_FMC_PRODUCTS_FAILURE> =>
  <const>{
    type: constants.FETCH_FMC_PRODUCTS_FAILURE,
    payload: {
      error,
    },
  };

export const setFmcProductsCheck = (
  data: FmcProductsCheck,
): ActionCreatorPayload<
  typeof constants.SET_FMC_PRODUCT_CHECK,
  FmcProductsCheck
> =>
  <const>{
    type: constants.SET_FMC_PRODUCT_CHECK,
    payload: data,
  };

export const setFmcProductsValue = (
  data: FmcProductsValue,
): ActionCreatorPayload<
  typeof constants.SET_FMC_PRODUCT_VALUE,
  FmcProductsValue
> =>
  <const>{
    type: constants.SET_FMC_PRODUCT_VALUE,
    payload: data,
  };

export const setValueToConversion = (
  value: number,
): ActionCreatorPayload<typeof constants.SET_VALUE_TO_CONVERSION, number> =>
  <const>{
    type: constants.SET_VALUE_TO_CONVERSION,
    payload: value,
  };

export const setFmcProductsValues = (
  data: FmcProducts[],
): ActionCreatorPayload<
  typeof constants.SET_FMC_PRODUCTS_VALUES,
  FmcProducts[]
> => <const>{ type: constants.SET_FMC_PRODUCTS_VALUES, payload: data };

export const fetchFmcLoadState = (
  data: FmcCoinsSimulatorState,
): ActionCreatorPayload<
  typeof constants.FETCH_FMC_LOAD_STATE,
  FmcCoinsSimulatorState
> =>
  <const>{
    type: constants.FETCH_FMC_LOAD_STATE,
    payload: data,
  };

export const fetchFmcLoadStateSuccess = (
  data: FmcCoinsSimulatorState,
): ActionCreatorPayload<
  typeof constants.FETCH_FMC_LOAD_STATE_SUCCESS,
  FmcCoinsSimulatorState
> =>
  <const>{
    type: constants.FETCH_FMC_LOAD_STATE_SUCCESS,
    payload: data,
  };

export const resetFmc = (): ActionCreator<typeof constants.RESET_FMC> =>
  <const>{
    type: constants.RESET_FMC,
  };

export type FmcCoinsSimulatorActions = ReturnType<
  | typeof fetchFmcProducts
  | typeof fetchFmcProductsFailure
  | typeof fetchFmcProductsSuccess
  | typeof setFmcProductsCheck
  | typeof setFmcProductsValues
  | typeof setFmcProductsValue
  | typeof fetchFmcLoadStateSuccess
  | typeof setValueToConversion
  | typeof resetFmc
>;
