import { pluginApi } from 'services/api';
import { Regulation, RegulationResponse } from './interfaces/IRegulation';

interface ApiResponse {
  regulations: RegulationResponse[];
}

export default async (): Promise<Omit<Regulation, 'content'>[]> => {
  const [
    {
      data: { regulations: accepted },
    },
    {
      data: { regulations: notAccepted },
    },
  ] = await Promise.all([
    pluginApi.get<ApiResponse>('participants/regulations'),
    pluginApi.get<ApiResponse>('participants/regulations/not-accepted'),
  ]);

  const notAcceptedSorted = notAccepted.sort((a: RegulationResponse) => {
    if (a.type.includes('term')) {
      return -1;
    }
    if (a.type === 'regulation_of_campaign') {
      return -1;
    }
    return 1;
  });

  return accepted
    .map(item => ({ ...item, accepted: true }))
    .concat(notAcceptedSorted.map(item => ({ ...item, accepted: false })));
};
