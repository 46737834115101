import { passwordValidationSchema } from 'components/shared/PasswordHelp/validation';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  nickname: yup.string().required('Campo obrigatório'),
  email: yup
    .string()
    .email('Informe um e-mail válido.')
    .required('Campo obrigatório'),
  cpf: yup.string().required('Campo obrigatório'),
  regulation: yup
    .bool()
    .test(
      'accepted_required',
      '* Aceite de termos e regulamentos obrigatório!',
      v => v,
    ),
  avatar: yup.string(),
  ddd: yup.string().required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  policy_expose: yup
    .string()
    .required('* Informe se você é ou não uma pessoa politicamente exposta')
    .oneOf(
      ['0', '1'],
      '* Informe se você é ou não uma pessoa politicamente exposta',
    ),
  password: passwordValidationSchema,
  confirm_password: yup
    .string()
    .required('Campo obrigatório')
    .oneOf(
      [yup.ref('password')],
      'Confirmação de senha precisa ser igual a senha',
    ),
});
