import React, { useCallback, useEffect, useState } from 'react';
import { Option } from 'components/shared/Select';
import { ParticipantChannelsData } from 'services/participant-channels/interface';

import { Select } from '../styles';

interface LocationSelectProps {
  className?: string;
  ufValue: Option | null;
  cityValue: Option | null;
  setUfValue(value: Option | null): void;
  setCityValue(value: Option | null): void;
  channelsData: ParticipantChannelsData[];
  ufOptions: Option[];
  cityOptions: any;
}

const LocationSelect: React.FC<LocationSelectProps> = ({
  className,
  ufValue,
  cityValue,
  setUfValue,
  setCityValue,
  channelsData,
  ufOptions,
}) => {
  const [cityOptions, setCityOptions] = useState<Option[]>([]);

  const filterCityList = useCallback(
    (selectedUf: Option | null) => {
      if (selectedUf) {
        const filteredCities = channelsData
          .filter(data => data.uf === selectedUf.title)
          .map(data => ({
            value: data.city,
            title: data.city,
          }));

        const uniqueCities = Array.from(new Set(filteredCities.map(city => city.title)))
          .map(city => ({
            value: city,
            title: city,
          }));

        const sortedCityOptions = uniqueCities.sort((a, b) =>
          a.title.localeCompare(b.title),
        );

        setCityOptions(sortedCityOptions);
      } else {
        setCityOptions([]);
      }
    },
    [channelsData],
  );

  const handleUfChange = useCallback(
    async (value: Option | null) => {
      setUfValue(value);
      setCityValue(null);
      filterCityList(value);
    },
    [setUfValue, filterCityList, setCityValue],
  );

  const handleCityChange = useCallback(
    async (value: Option | null) => {
      setCityValue(value);
    },
    [setCityValue],
  );

  useEffect(() => {
    if (ufValue) {
      filterCityList(ufValue);
    }
  }, [ufValue, filterCityList]);

  return (
    <>
      <Select
        label="UF"
        loadItems={() => ufOptions}
        className={className}
        value={ufValue}
        setValue={handleUfChange}
        placeholder="Selecione"
        inputRole="secondary"
      />

      <Select
        label="Município"
        loadItems={() => cityOptions}
        className={className}
        value={cityValue}
        setValue={handleCityChange}
        placeholder="Selecione"
        inputRole="secondary"
      />
    </>
  );
};

export default LocationSelect;