import styled from 'styled-components';

export const Container = styled.div`
  background-color: #efefef;
  border-radius: 5px;
  padding: 16px 32px;
  min-width: 450px;

  strong {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }

  ._inputContainer {
    margin-top: 8px;
  }

  @media screen and (max-width: 750px) {
    min-width: unset;
    width: 100%;

    input {
      width: 100% !important;
    }
  }
`;
