import styled, { css } from 'styled-components';

import { AlertVariation } from '.';

export const Container = styled.div<{ type: AlertVariation }>`
  padding: 15px 25px;

  ${p =>
    p.type === 'error' &&
    css`
      border: 1px solid rgba(230, 49, 39, 0.5);
      background-color: rgba(230, 49, 39, 0.1);
      color: #e63027;
      font-size: 14px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    `}

  ${p =>
    p.type === 'info' &&
    css`
      border: 1px solid rgba(2, 145, 240, 0.5);
      background-color: rgba(2, 145, 240, 0.1);
      color: #0291f0;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      font-size: 14px;
    `}
`;
