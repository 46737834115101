import { pluginApi } from "services/api";

interface ImportDownloadResponse {
  download_url: string;
}

export const getImportDownload = async (importId: string, identifier: number): Promise<ImportDownloadResponse | null> => {
  try {
    const { data } = await pluginApi.get<ImportDownloadResponse>(`/reports/dictionary-imports`, {
      params: {
        import: importId, // Passando o importId como parâmetro de consulta
        identifier: identifier, // Passando o identifier como parâmetro de consulta
      },
    });
    return data;
  } catch (error) {
    console.error("Erro ao obter link de download:", error);
    return null;
  }
};