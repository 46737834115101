import React from 'react';

import { Product } from 'state/modules/points-simulator/interfaces';
import { DataValueDTO } from 'state/modules/points-simulator/types';
import Tr from './Tr';
import { Container, TitleHerbicidas, TitleInseticidas, TitleInseticidasByRynaXypyr, TitleFungicidas } from './styles';

interface HeaderProps {
  checkTerraBrasil?: Boolean | any;
  tabSelected: string;
  products: Product[];
  setUnitValueInDollar(data: DataValueDTO): void;
  setRevenuesInKilosPerLiter(data: DataValueDTO): void;
  setPogInKilosPerLiter(data: DataValueDTO): void;
  onCheckUncheckProductHandle({
    id,
    checked,
  }: {
    id: number;
    checked: boolean;
  }): void;
  onCheckUncheckProductHandleEnhancer({
    id,
    enhancersChecked,
  }: {
    id: number;
    enhancersChecked: boolean;
  }): void;
  channelId: string;
}

const Header: React.FC<HeaderProps> = ({
  checkTerraBrasil,
  products,
  setUnitValueInDollar,
  setRevenuesInKilosPerLiter,
  setPogInKilosPerLiter,
  onCheckUncheckProductHandle,
  onCheckUncheckProductHandleEnhancer,
  channelId,
  tabSelected
}) => {
  const sortedProducts = products.slice().sort((a, b) => {
    // Primeiro, ordena pelos produtos participantes
    // if (a.isParticipatingProduct && !b.isParticipatingProduct) {
    //   return -1;
    // }
    // if (!a.isParticipatingProduct && b.isParticipatingProduct) {
    //   return 1;
    // }

    // Se ambos têm o mesmo valor de isParticipatingProduct, ordena alfabeticamente
    return a.name.localeCompare(b.name); // Troque 'name' pela propriedade correta
  });

  return (
    <>
    {
      tabSelected === 'Segmentos potencializadores' && !checkTerraBrasil ? 
      <Container>
        <TitleHerbicidas>Herbicidas</TitleHerbicidas>
        {
          sortedProducts.map(
            product => (
              product.enhancerSegment === 'Herbicidas' &&
              <Tr
                checkTerraBrasil={checkTerraBrasil}
                tabSelected={tabSelected}
                key={product.id}
                product={product}
                setUnitValueInDollar={setUnitValueInDollar}
                setRevenuesInKilosPerLiter={setRevenuesInKilosPerLiter}
                setPogInKilosPerLiter={setPogInKilosPerLiter}
                onCheckUncheckProductHandle={onCheckUncheckProductHandle}
                onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
                channelId={channelId}
              />
            )
          )
        }
        <TitleInseticidas>Inseticidas</TitleInseticidas>
        {
          sortedProducts.map(
            product => (
              product.enhancerSegment === 'Inseticidas' &&
              <Tr
                checkTerraBrasil={checkTerraBrasil}
                tabSelected={tabSelected}
                key={product.id}
                product={product}
                setUnitValueInDollar={setUnitValueInDollar}
                setRevenuesInKilosPerLiter={setRevenuesInKilosPerLiter}
                setPogInKilosPerLiter={setPogInKilosPerLiter}
                onCheckUncheckProductHandle={onCheckUncheckProductHandle}
                onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
                channelId={channelId}
              />
            )
          )
        }
        <TitleInseticidasByRynaXypyr>Inseticidas by Rynaxypyr</TitleInseticidasByRynaXypyr>
        {
          sortedProducts.map(
            product => (
              product.enhancerSegment === 'Inseticidas by Rynaxypyr' &&
              <Tr
                checkTerraBrasil={checkTerraBrasil}
                tabSelected={tabSelected}
                key={product.id}
                product={product}
                setUnitValueInDollar={setUnitValueInDollar}
                setRevenuesInKilosPerLiter={setRevenuesInKilosPerLiter}
                setPogInKilosPerLiter={setPogInKilosPerLiter}
                onCheckUncheckProductHandle={onCheckUncheckProductHandle}
                onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
                channelId={channelId}
              />
            )
          )
        }
        <TitleFungicidas>Fungicidas</TitleFungicidas>
        {
          sortedProducts.map(
            product => (
              product.enhancerSegment === 'Fungicidas' &&
              <Tr
                checkTerraBrasil={checkTerraBrasil}
                tabSelected={tabSelected}
                key={product.id}
                product={product}
                setUnitValueInDollar={setUnitValueInDollar}
                setRevenuesInKilosPerLiter={setRevenuesInKilosPerLiter}
                setPogInKilosPerLiter={setPogInKilosPerLiter}
                onCheckUncheckProductHandle={onCheckUncheckProductHandle}
                onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
                channelId={channelId}
              />
            )
          )
        }
      </Container> 
      : 
      <Container>
        {sortedProducts.map(product => (
          <Tr
            checkTerraBrasil={checkTerraBrasil}
            tabSelected={tabSelected}
            key={product.id}
            product={product}
            setUnitValueInDollar={setUnitValueInDollar}
            setRevenuesInKilosPerLiter={setRevenuesInKilosPerLiter}
            setPogInKilosPerLiter={setPogInKilosPerLiter}
            onCheckUncheckProductHandle={onCheckUncheckProductHandle}
            onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
            channelId={channelId}
          />
        ))}
      </Container>
    }
    </>
  );
};

export default Header;
