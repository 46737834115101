import React from 'react';

import logoImg from 'assets/images/logo.png';
import { Header as DefaultHeader } from '../styles';

const Header: React.FC = () => {
  return (
    <DefaultHeader>
      <img src={logoImg} alt="Logo" />
    </DefaultHeader>
  );
};

export default Header;
