import React from 'react';
import routeMap from 'routes/route-map';
import { useDispatch } from 'react-redux';
import history from 'services/history';
import { setSubject, setCategory } from 'state/modules/contact/actions';
import CloseButton from 'components/shared/CloseButton';
import { Container, Modal, Button, Title } from './styles';

interface HelpModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onRequestClose }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setSubject({ title: 'Campanhas Aceleradoras', id: '20' }));
    dispatch(setCategory({ name: 'Dúvidas', id: '60' }));
    history.push(routeMap.contact);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={3}>
      <Container>
        <CloseButton onClickHandler={onRequestClose} />
        <Title>Precisa de ajuda?</Title>
        <span>
          Tem dúvidas ou precisa de ajuda do nosso time de estratégia para criar
          uma campanha? Abra um chamado pelo nosso canal de atendimento e
          descreva em detalhes do que precisa. A nossa equipe entrará em contato
          com você!
        </span>
        <Button type="button" buttonRole="primary" onClick={handleClick}>
          Fale Conosco
        </Button>
      </Container>
    </Modal>
  );
};

export default HelpModal;
