import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 25px 0;

  .roles {
    grid-column: 1 / 3;
  }
`;
