export default {
  complementary: {
    color: '#dd8813',
  },
  innovation: {
    color: '#ebdb1e',
  },
  strategic: {
    color: '#78ce58',
  },
};
