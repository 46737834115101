import { DefaultTheme } from 'styled-components';
import contact from './modules/contact/terra-brasil';
import indication from './modules/indication/terra-brasil';
import howParticipate from './modules/how-participate/terra-brasil';
import layout from './modules/layout/default';
import link from './modules/link/default';
import modal from './modules/modal/default';
import regulation from './modules/regulation/default';
import tabMenu from './modules/tab-menu/default';
import tooltip from './modules/tooltip/default';

import accordion from './modules/accordion/terra-brasil';
import button from './modules/button/terra-brasil';
import font from './modules/font/terra-brasil';
import footer from './modules/footer/terra-brasil';
import input from './modules/input/terra-brasil';
import menu from './modules/menu/terra-brasil';
import table from './modules/table/terra-brasil';
import fmcProducts from './modules/fmc-products/default';

const Theme: DefaultTheme = {
  accordion,
  button,
  indication,
  contact,
  font,
  footer,
  howParticipate,
  input,
  layout,
  link,
  menu,
  modal,
  regulation,
  table,
  tabMenu,
  tooltip,
  fmcProducts,
};

export default Theme;
