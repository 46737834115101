import {
  PointsSimulatorState,
  IndicatorType,
} from 'state/modules/points-simulator/interfaces';
import { EstablishmentTypes } from 'config/constants';
import head from './head';
import logo from './logo';
import header from './header';
import results from './results';
import resultsBiological from './results-biological';
import separator from './separator';
import indicatorsTemplate from './indicators';
import indicatorsBiological from './indicators-biological';
import dollarBase from './dollar-base';
import whiteSpace from './white-space';
import calculatorBase from './calculator-base';

export const generateHtml = (pointsSimulatorState: PointsSimulatorState) => {
  const {
    channel,
    configuration,
    indicators: indicatorsState,
    award,
    dollarBaseValue,
    products,
  } = pointsSimulatorState;
  const { partialDate } = configuration;
  const { partialDateInvoicing } = configuration;
  const { partialDatePog } = configuration;
  const { pogRealizedNetPercentage } = configuration;

  const revenues = indicatorsState.find(
    item => item.type === IndicatorType.revenues,
  );
  const pog = indicatorsState.find(item => item.type === IndicatorType.pog);

  // Valor biológicos
  const revenuesBiological = indicatorsState.find(
    item => item.type === IndicatorType.revenuesBiological,
  );
  const pogBiological = indicatorsState.find(
    item => item.type === IndicatorType.pogBiological,
  );

  // Valor total da venda
  // Soma de todos os produtos
  const totalPog =
    (pog?.simulationData.totalSimulated || 0) +
    (pogBiological?.simulationData.totalSimulated || 0);
  const totalRevenues =
    (revenues?.simulationData.totalSimulated || 0) +
    (revenuesBiological?.simulationData.totalSimulated || 0);

  // Tipos de produtos para renderizar nos cards de indicadores do pdf
  const indicatorType =
    channel?.type === EstablishmentTypes.TerraBrasil
    ? [
        IndicatorType.altacor,
        IndicatorType.boralFull,
        IndicatorType.quartzo,
        IndicatorType.stone,
        IndicatorType.premioStar,
        IndicatorType.veriMark,
      ]
    : [
        IndicatorType.hero, 
        IndicatorType.aurora, 
        IndicatorType.talisman,
        IndicatorType.fungicidas,
        IndicatorType.herbicidas,
        IndicatorType.inseticidas,
        IndicatorType.inseticidasByRynaxypyr
      ];

  const indicators = indicatorsState.filter(indicator =>
    indicatorType.includes(indicator.type),
  );

  if (!channel) return '';
  if (!partialDate) return '';
  if (!partialDateInvoicing) return '';
  if (!partialDatePog) return '';
  if (!revenues) return '';
  if (!pog) return '';
  if (!revenuesBiological) return '';
  if (!pogBiological) return '';

  /**
   * Caso o canal seja biológico,
   * imprime no PDF os resultados da simulação para biológicos
   */
  const renderBiologicalResults = () => {
    if (!channel.isBiological) return '';

    return `
      ${resultsBiological({
        award,
        configuration
      })}

      ${separator}

      ${indicatorsBiological({
        revenues: revenuesBiological,
        pog: pogBiological,
      })}
    `;
  };

  return `
    <!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

    ${head}

    <body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #fcfcfc">
    ${logo}

    ${header({ channel, partialDate, partialDateInvoicing, partialDatePog })}

    ${results({
      award,
      totalPog,
      totalRevenues,
      channelType: channel.type,
      channel,
    })}

    ${separator}

    ${indicatorsTemplate({
      revenues,
      products: indicators,
      pogBiological,
      revenuesBiological,
      pog,
    })}

    ${whiteSpace(35)}

    ${renderBiologicalResults()}

    ${dollarBase({
      dollarValue: dollarBaseValue,
      itemsCount: products.filter(prod => prod.checked).length,
      groos: pogRealizedNetPercentage,
    })}

    ${separator}

    ${whiteSpace(20)}

    ${calculatorBase({ products })}

    ${whiteSpace(20)}

    </body>

    </html>
  `;
};
