/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import getCampaigns from 'services/producer-extract/getCampaigns';
import { ExtractCampaign } from 'services/producer-extract/interfaces';

import { getParticipantsToAccessPI } from 'services/showcase';
import { formatDate } from 'util/datetime';
import { useAuth } from 'context/AuthContext';

import RescuePoints from 'components/FcmCoinsExtract/RescuePoints';
import orderBy from 'lodash.orderby';

import { Button } from 'components/shared';
import DatePicker from 'components/shared/DatePicker';

import { formatPointsExtract } from 'util/points';

import { pluginApi, setApiMode } from 'services/api';
import useDimensions from 'hooks/use-window-dimensions';
import { parseISO } from 'date-fns';
import {
  Container,
  Content,
  ListTableContainer,
  PageTitle,
  StatusContainer,
  StatusContent,
  StatusItem,
  StatusBox,
  Banner,
  TotalCoins,
  ListTable,
  ContentMobile,
} from './styles';

interface ResponseExtract {
  datahora: Date | null;
  descricao: string | null;
  credito: number | null;
  debito: number | null;
  saldo: number | null;
  dataExpiracao: Date | null;
}

export const ExtractUserPoints = () => {
  const location = useLocation();
  const [points, setPoints] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [researched, setResearched] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { width } = useDimensions();
  const { participant, simulating } = useAuth();

  const idCampaignMyPoints = 348;

  const [infoExtract, setInfoExtract] = useState([]);

  const [initialDate, setInitialDate] = useState<Date>(
    new Date('2020-01-01T00:00:00-03:00'),
  );
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  const [valueFmcCoins, setValueFmcCoins] = useState(0);

  const [campaigns, setCampaigns] = useState<ExtractCampaign[]>([]);
  const isTerraBrasil =
    participant.establishment.type_name.toLowerCase() === 'terra brasil';

  const getCampaign = campaigns
    .map(infoCampaign => infoCampaign.title)
    .slice(1)[0];
  const [select, setSelect] = useState(getCampaign);

  const [piAccess, setPiAccess] = useState('');
  const [
    currentCampaign,
    setCurrentCampaign,
  ] = useState<ExtractCampaign | null>(null);

  const getInfoCampaign = campaigns
    .map(infoCampaign => infoCampaign)
    .filter(x => x.title === select);

  // Formatadores de data
  const onEndDateChangeHandler = useCallback((value: Date): void => {
    setFinalDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);
  const onBeginDateChangeHandler = useCallback((value: Date): void => {
    setInitialDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const result = await getCampaigns();
      const ordered = orderBy(result, ['title'], ['desc']);

      if (isTerraBrasil) {
        setCampaigns(result.filter(item => item.GrupoID === 6));
      } else {
        setCampaigns(
          ordered.filter(item => item.id !== 2 && item.GrupoID !== 6),
        );
        setCurrentCampaign(ordered[0]);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    getParticipantsToAccessPI().then(data => {
      setPiAccess(data.find(item => item.type === 'cpf')?.urlPi || '');
      setPoints(data.find(item => item.type === 'cpf')?.points || 0);
    });
  }, [location]);

  useEffect(() => {
    const getAllFmcCoins = async () => {
      try {
        const { data } = await pluginApi.get(
          `/participants/extrato-fmc-coins/get-all-points?campaign_id=${
            getInfoCampaign[0]?.id || (currentCampaign && currentCampaign.id)
          }&id_participnat=${participant.id}
          &establishment_type_id=${participant.establishment.type_id}`,
        );
        setValueFmcCoins(data.pointsTotal);
      } catch (err) {}
    };
    getAllFmcCoins();
  }, [select, currentCampaign && currentCampaign.id]);

  const getInfoExtract = async () => {
    try {
      setLoading(true);
      setApiMode('write');
      const { data } = await pluginApi.get('premio-ideall/points', {
        params: {
          start_date: formatDate(initialDate, 'yyyy-MM-dd'),
          final_date: formatDate(finalDate, 'yyyy-MM-dd'),
          campaign_id_pi:
            participant.establishment.classificacao === 'Terra Brasil'
              ? idCampaignMyPoints
              : null,
        },
      });
      setResearched(true);
      setLoading(false);
      if (data != null) {
        setInfoExtract(data.data);
      }
    } catch (err) {
      setError(true);
    }
  };

  const getExtractValues =
    researched && !loading && infoExtract.length > 1 && width && width > 768;

  const pointsFormatted = `${
    valueFmcCoins && formatPointsExtract(valueFmcCoins)
  } pontos`;

  return (
    <Container>
      <Banner>
        <PageTitle>Saldo Disponível Para Resgate</PageTitle>
        <StatusContainer>
          <StatusContent>
            <StatusItem>
              <StatusBox className="first-box">
                <RescuePoints
                  points={points}
                  piAccess={piAccess}
                  isSimulating={simulating}
                  isValid={participant.esocial_valid}
                />
              </StatusBox>
            </StatusItem>
            <StatusItem>
              <StatusBox>
                <TotalCoins>
                  <h3>Recebidos por Safra:</h3>
                  <p>Valor Bruto</p>
                  <select
                    value={select}
                    onChange={e => setSelect(e.target.value)}
                  >
                    {campaigns.map(selectCampaign => (
                      <option
                        key={selectCampaign.title}
                        value={selectCampaign.title}
                      >
                        {!isTerraBrasil
                          ? `Safra ${selectCampaign.title}`
                          : `Ano ${selectCampaign.title}`}
                      </option>
                    ))}
                  </select>

                  <h4>{pointsFormatted || '000.000,00 pontos'}</h4>
                </TotalCoins>
              </StatusBox>
            </StatusItem>
          </StatusContent>
        </StatusContainer>
      </Banner>
      <Content>
        <div className="title-extract">
          <h3>Extrato do Catálogo de Prêmios</h3>
        </div>
        <div className="parent-date">
          <div className="content-date">
            <div className="align-datepickers">
              <div className="first-datepicker">
                <DatePicker
                  label="Data inicial"
                  value={initialDate}
                  onChange={onBeginDateChangeHandler}
                />
              </div>
              <div className="content-date">
                <div className="second-datepicker">
                  <DatePicker
                    label="Data final"
                    value={finalDate}
                    onChange={onEndDateChangeHandler}
                    className="datepickerfinal"
                  />
                </div>
              </div>
            </div>
            <Button
              className="datepickerfinal btn"
              buttonRole="primary"
              type="button"
              disabled={!initialDate || !finalDate}
              onClick={getInfoExtract}
            >
              Consultar
            </Button>
          </div>
        </div>
        <div>
          <ListTableContainer>
            <div className={researched ? 'searched' : ''}>
              {error && <h3>Nenhum resultado encontrado</h3>}
              {researched && infoExtract.length < 2 && (
                <h3>Nenhum resultado encontrado</h3>
              )}
              {getExtractValues ? (
                <ListTable>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Descrição</th>
                      <th>Pontos</th>
                      <th>Débito</th>
                      <th>Saldo</th>
                      <th>Vencimento</th>
                    </tr>
                  </thead>

                  <tbody>
                    {infoExtract &&
                      infoExtract.slice(1).map((info: ResponseExtract) => (
                        <tr>
                          <td>
                            {(info.datahora &&
                              formatDate(info.datahora, 'dd/MM/yyyy')) ||
                              '-'}
                          </td>

                          <td>{info.descricao || '-'}</td>

                          <td>
                            {(info.credito &&
                              formatPointsExtract(info.credito)) ||
                              '-'}
                          </td>

                          <td>
                            {(info.debito &&
                              formatPointsExtract(info.debito)) ||
                              '-'}
                          </td>

                          <td>
                            {(info.saldo && formatPointsExtract(info.saldo)) ||
                              '-'}
                          </td>
                          <td>
                            {(info.dataExpiracao &&
                              formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                              '-'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </ListTable>
              ) : (
                <ContentMobile>
                  {infoExtract &&
                    infoExtract.slice(1).map((info: ResponseExtract) => (
                      <div className="card-items">
                        <p>
                          {' '}
                          <span>Data</span>:{' '}
                          {(info.datahora &&
                            formatDate(info.datahora, 'dd/MM/yyyy')) ||
                            '-'}
                        </p>
                        <p>
                          {' '}
                          <span>Descrição</span>: {info.descricao || '-'}
                        </p>
                        <p>
                          {' '}
                          <span>Pontos</span>: {info.credito || '-'}
                        </p>
                        <p>
                          {' '}
                          <span>Débito </span>:{info.debito || '-'}
                        </p>
                        <p>
                          <span>Saldo</span>: {info.saldo || '-'}
                        </p>
                        <p>
                          <span>Vencimento</span>:{' '}
                          {(info.dataExpiracao &&
                            formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                            '-'}
                        </p>
                      </div>
                    ))}
                </ContentMobile>
              )}
            </div>
          </ListTableContainer>
        </div>
      </Content>
    </Container>
  );
};
