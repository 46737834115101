export const ADD_GOAL = '@fmc/campaigns/ADD_GOAL';
export const REMOVE_GOAL = '@fmc/campaigns/REMOVE_GOAL';
export const SET_FIELD_VALUE = '@fmc/campaigns/SET_FIELD_VALUE';
export const SET_PRIZE_NAME = '@fmc/campaigns/SET_PRIZE_NAME';
export const SET_PRIZE_DESCRIPTION = '@fmc/campaigns/SET_PRIZE_DESCRIPTION';
export const SET_POINTS_VALUE = '@fmc/campaigns/SET_POINTS_VALUE';
export const ADD_AUDIENCE = '@fmc/campaigns/ADD_AUDIENCE';
export const REMOVE_AUDIENCE = '@fmc/campaigns/REMOVE_AUDIENCE';
export const SET_START_DATE = '@fmc/campaigns/SET_START_DATE';
export const SET_END_DATE = '@fmc/campaigns/SET_END_DATE';
export const SET_MECHANIC = '@fmc/campaigns/SET_MECHANIC';
export const SET_CULTURE = '@fmc/campaigns/SET_CULTURE';
export const ADD_CULTURE = '@fmc/campaigns/ADD_CULTURE';
export const REMOVE_CULTURE = '@fmc/campaigns/REMOVE_CULTURE';
export const SET_CAMPAIGN = '@fmc/campaigns/SET_CAMPAIGN';
export const RESET = '@fmc/campaigns/RESET';
export const SET_ERRORS = '@fmc/campaigns/SET_ERRORS';
export const SET_SEND_MAIL = '@fmc/campaigns/SET_SEND_MAIL';
export const CAMPAIGN_APPROVED = '@fmc/campaigns/CAMPAIGN_APPROVED';
export const CAMPAIGN_DISAPPROVED = '@fmc/campaigns/CAMPAIGN_DISAPPROVED';
export const UPDATE_STATUS = '@fmc/campaigns/UPDATE_STATUS';
export const SET_CHECK_REGULATION = '@fmc/campaigns/SET_CHECK_REGULATION';
export const SEND_COMMUNICATION_KIT = '@fmc/campaigns/SEND_COMMUNICATION_KIT';
