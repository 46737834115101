import React, { useCallback, useEffect } from 'react';
import getUfList from 'services/address/getUfList';

import { Option } from 'components/shared/Select';
import { useFormContext } from 'react-hook-form';
import { Select } from './styles';

interface Props {
  className?: string;
  setValue(value: Option | null): void;
  inputRole: 'primary' | 'secondary';
  disabled?: boolean;
}

const name = 'uf_naturalness_select';

const UfNaturalnessSelect: React.FC<Props> = ({
  setValue,
  inputRole = 'primary',
  disabled = false,
}) => {
  const { watch } = useFormContext();

  useEffect(() => {
    const option: Option = watch(name);
    setValue(option);
  }, [watch, setValue]);

  const loadItems = useCallback(() => {
    return getUfList().map<Option>(item => ({
      title: item.stateCode,
      value: item.stateCode,
    }));
  }, []);

  return (
    <Select
      name={name}
      label="UF da Naturalidade"
      loadItems={loadItems}
      inputRole={inputRole}
      disabled={disabled}
    />
  );
};

export default UfNaturalnessSelect;
