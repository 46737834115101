import styled from 'styled-components';
import DefaultSelect from 'components/shared/Select';

export const Select = styled(DefaultSelect)`
  margin-top: 15px;
  max-width: 499px;

  ._inputContainer {
    height: 40px;
  }
`;
