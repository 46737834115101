import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
// import { getNaturalnessListTransformer } from './transformers/transformers';
import { EducationLevel } from './interfaces';

interface Request {
  codeGrau: string;
}

export default async (): Promise<Option[]> => {
  const { data } = await pluginApi.get<EducationLevel[]>(`/schooling`);
  return data.map<Option>(item => ({
    value: item.name_schooling,
    title: item.name_schooling.toLowerCase(),
  }));
};
