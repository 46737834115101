import { Answer as AnswerData } from 'components/Surveys/types';
import { Dispatch } from 'redux';
import { setValueAnswer } from 'state/modules/surveys/actions';

export interface Options {
  topic?: string;
  multipleAnswers?: 'unique' | 'many' | 'none';
}

const defaultOptions: Options = { multipleAnswers: 'none' };

export default (
  answers: AnswerData[],
  id: number,
  dispatch: Dispatch,
  options: Options = defaultOptions,
): void => {
  answers.forEach(({ survey_participant_answers }) => {
    survey_participant_answers.forEach(item => {
      dispatch(
        setValueAnswer({
          answerValue: item.answer,
          questionId: id,
          answerId: item.survey_question_answer_id,
          topic: item.alternative_group,
          ...options,
        }),
      );
    });
  });
};
