import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinha à esquerda */
  margin: 10px 0;
  width: 100%;
  max-width: 300px; /* Definir um tamanho máximo */
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

export const StyledOption = styled.option`
  font-size: 16px;
  color: #333;
`;

export const SubTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 18px;
  color: ${({ theme }) => theme.font.color.primary};
`;