import React, { useCallback } from 'react';

import { Attachment } from 'services/strategic-plans/actions/interfaces';
import { MdClose } from 'react-icons/md';
import { formatDate } from 'util/datetime';
import getUrlToForceDownload from 'services/storage/getUrlToForceDownload';
import { Container, List, ListItem, Name, Date } from './styles';

interface AttachmentsListProps {
  attachments: Attachment[];
  removeClickHandler: (itemId: string) => void;
  canEdit?: boolean;
}

const AttachmentsList: React.FC<AttachmentsListProps> = ({
  attachments,
  removeClickHandler,
  canEdit = true,
}) => {
  const downloadClickHandler = useCallback(
    (url: string, filename: string): void => {
      const pathUrl = getUrlToForceDownload({
        url,
        filename,
      });
      const link = document.createElement('a');
      link.href = pathUrl;
      link.click();
    },
    [],
  );

  return (
    <Container>
      <List>
        {attachments.map(({ id, name, uploadDate, url }) => (
          <li key={id}>
            <ListItem>
              <Name onClick={() => downloadClickHandler(url, name)}>
                {name}
              </Name>
              <Date>Carregado em {formatDate(uploadDate)}</Date>

              {canEdit && (
                <MdClose size={20} onClick={() => removeClickHandler(id)} />
              )}
            </ListItem>
          </li>
        ))}
      </List>
    </Container>
  );
};

export default AttachmentsList;
