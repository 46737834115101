/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
} from 'react-icons/ai';

import ThemeLoading from 'components/shared/ThemeLoading';
import { Alert } from 'components/shared';
import { SafraSelect } from 'components/PointManagement/SafraSelect';
import { EstablishmentSelection } from 'components/PointManagement/EstablishmentSelection';
import { PointCard } from 'components/PointManagement/point-card';
import { getPointsDistributionToApprove } from 'services/point-management/points-distribuition-to-approve';
import { uuid } from 'uuidv4';
import { PointCardSecondPhase } from 'components/PointManagement/point-card/point-card-second-phase';
import { getPointsAlreadyDistributed } from 'services/point-management/points-already-distributed';
import { PointCardSecondPhaseRepprove } from 'components/PointManagement/point-card/point-card-second-phase-reprove';
import { Container, HeadWrapper, TabsWrapper, TabContainer } from './styles';
import { usePoints } from '../use-points';

export const ApprovementPage = () => {
  const [tab, setTab] = useState<'waiting' | 'approved' | 'reproved'>(
    'waiting',
  );

  const [valuesPointsToApprove, setValuesPointsToApprove] = useState();
  const [valuesPointsToRepprove, setValuesPointsToRepprove] = useState();
  const [valueCampaign, setValueCampaign] = useState(0);
  const [filter, setFilter] = useState(2);

  const [pointsDistributed, setPointsDistributed] = useState<any>();

  const { isFetching, fetchError, points } = usePoints();
  const { selectedEstablishment, selectedCampaign } = usePoints();

  useEffect(() => {
    const getPoints = async () => {
      setValueCampaign(selectedCampaign);
      if (selectedEstablishment) {
        const dados = await getPointsDistributionToApprove({
          establishmentId:
            selectedEstablishment && Number(selectedEstablishment?.value),
          campaignId: selectedCampaign,
        });
        setValuesPointsToApprove(dados.distribution);
        setValuesPointsToRepprove(dados.disapproved);
      }
    };

    getPoints();
  }, [selectedCampaign, selectedEstablishment]);

  useEffect(() => {
    const establishmentId =
      selectedEstablishment && selectedEstablishment.value !== null
        ? Number(selectedEstablishment.value)
        : 0;

    const pointsAlreadyDistributed = async () => {
      const response = await getPointsAlreadyDistributed({
        establishmentId,
        campaignId: selectedCampaign,
      });

      setPointsDistributed(response);
    };

    pointsAlreadyDistributed();
  }, [selectedCampaign, selectedEstablishment]);

  const pointsAl =
    pointsDistributed &&
    pointsDistributed.points.filter(
      (p: any) => p.status === 5 || p.status === 3,
    );

  return (
    <Container>
      <HeadWrapper>
        <div className="wrapper">
          <strong>Aprovação de distribuição de pontos</strong>

          <div className="fields">
            <SafraSelect />

            <EstablishmentSelection showSelection valueCampaign={valueCampaign} filter={filter} />
          </div>
        </div>
      </HeadWrapper>
      <div className="wrapper">
        <TabsWrapper>
          <TabContainer>
            <button
              type="button"
              className="tab status--waiting"
              data-selected={tab === 'waiting'}
              onClick={
                () => {
                  setTab('waiting');
                  setFilter(2); 
                }
              }
            >
              <AiOutlineClockCircle />
              <strong>Aguardando aprovação</strong>
            </button>
            <button
              type="button"
              className="tab status--approved"
              data-selected={tab === 'approved'}
              onClick={
                () => {
                  setTab('approved');
                  setFilter(3);
                }
              }
            >
              <AiOutlineCheckCircle />
              <strong>Aprovados</strong>
            </button>
            <button
              type="button"
              className="tab status--reproved"
              data-selected={tab === 'reproved'}
              onClick={
                () => {
                  setTab('reproved');
                  setFilter(4);
                }
              }
            >
              <AiOutlineCloseCircle />
              <strong>Reprovados</strong>
            </button>
          </TabContainer>
        </TabsWrapper>

        {isFetching && (
          <div className="loading-wrapper">
            <ThemeLoading size={24} />
          </div>
        )}

        <div className="cards-wrapper">
          {fetchError && (
            <Alert variation="error">
              Ocorreu um problema ao tentar carregar as informações.
            </Alert>
          )}

          {/** Aguardando aprovação */}
          {tab === 'waiting' &&
            points.waiting_approved &&
            Object.values(points.waiting_approved)
              .flat()
              .map((point: any) => (
                <PointCard
                  key={uuid()}
                  pointsDistribution={point}
                  pointsToApprove={false}
                  hideDistributed
                />
              ))}

          {tab === 'waiting' &&
            valuesPointsToApprove &&
            Object.values(valuesPointsToApprove)
              .flat()
              .map((point: any) => (
                <PointCard
                  key={uuid()}
                  pointsDistribution={point}
                  pointsToApprove
                  hideDistributed
                />
              ))}

          {tab === 'approved' &&
            points.published &&
            Object.values(points.published)
              .flat()
              .map((point: any) => (
                <PointCard
                  key={uuid()}
                  pointsDistribution={point}
                  pointsToApprove={false}
                />
              ))}

          {tab === 'approved' &&
            points.approved &&
            Object.values(points.approved)
              .flat()
              .map((point: any) => (
                <PointCard
                  key={uuid()}
                  pointsDistribution={point}
                  pointsToApprove={false}
                />
              ))}

          {tab === 'approved' &&
            pointsAl &&
            Object.values(pointsAl)
              .flat()
              .map((point: any) => (
                <PointCardSecondPhase
                  key={uuid()}
                  pointsAlready={point}
                  tab={tab}
                />
              ))}

          {tab === 'reproved' &&
            points.disapproved &&
            Object.values(points.disapproved)
              ?.flat()
              ?.map((point: any) => (
                <PointCard
                  tab={tab}
                  key={uuid()}
                  pointsDistribution={point}
                  pointsToApprove={false}
                  hideDistributed
                />
              ))}

          {tab === 'reproved' &&
            valuesPointsToRepprove &&
            Object.values(valuesPointsToRepprove)
              ?.flat()
              ?.map((point: any) => (
                <PointCardSecondPhaseRepprove
                  key={uuid()}
                  pointRepproved={point}
                  tab={tab}
                />
              ))}
        </div>
      </div>
    </Container>
  );
};
