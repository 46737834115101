import React, { useCallback, useState, useEffect } from 'react';
import routesMap from 'routes/route-map';
import history from 'services/history';
import getCampaigns, { Campaign } from 'services/campaigns/getCampaigns';
import NoResults from 'components/shared/NoResults';

import { Button } from 'components/shared';
import { CAMPAIGN_STATUS } from 'services/campaigns-manager/interfaces/Campaign';
import ReactLoading from 'react-loading';
import { Container, Content, CampaignBox, Header, Alert } from './styles';

const List: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getCampaigns().then(data => setCampaigns(data));
  }, []);

  const handleButtonClick = useCallback((id: number) => {
    history.push(`${routesMap.campaign}/${id}`);
  }, []);

  const availableCampaigns = campaigns.filter(
    c => c.status === CAMPAIGN_STATUS.PUBLISHED_STATUS,
  );

  const finishedCampaigns = campaigns.filter(
    c => c.status === CAMPAIGN_STATUS.FINISHED_STATUS,
  );

  useEffect(() => {
    if (campaigns.length === 0) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [campaigns.length]);

  return (
    <Container>
      <Header>
        <h1>Campanhas Aceleradoras</h1>
      </Header>
      {isLoading ? (
        <Alert>
          <ReactLoading type="bars" color="#8d8d8d" height={24} width={24} />
        </Alert>
      ) : (
        <>
          {availableCampaigns.length > 0 && (
            <>
              <h2>Campanhas disponíveis</h2>
              <Content>
                {availableCampaigns.map(item => (
                  <div>
                    <CampaignBox key={`campaign-${item.id}`}>
                      <img src={item.imageUrl} alt={item.title} />
                      <div className="campaign-details">
                        <div className="contents-details">
                          <h3>{item.title}</h3>
                          <span>{`Início: ${item.startDate} Término: ${item.endDate}`}</span>
                        </div>
                        <Button
                          buttonRole="primary"
                          type="button"
                          onClick={() => handleButtonClick(item.id)}
                        >
                          Saiba mais
                        </Button>
                      </div>
                    </CampaignBox>
                  </div>
                ))}
              </Content>
            </>
          )}
          {finishedCampaigns.length > 0 && (
            <>
              <h2>Campanhas Finalizadas</h2>
              <Content>
                {finishedCampaigns.map(item => (
                  <div>
                    <CampaignBox key={`campaign-${item.id}`}>
                      <img src={item.imageUrl} alt={item.title} />
                      <div className="campaign-details">
                        <div className="contents-details">
                          <h3>{item.title}</h3>
                          <span>{`Início: ${item.startDate} Término: ${item.endDate}`}</span>
                        </div>
                        <Button
                          buttonRole="primary"
                          type="button"
                          onClick={() => handleButtonClick(item.id)}
                        >
                          Saiba mais
                        </Button>
                      </div>
                    </CampaignBox>
                  </div>
                ))}
              </Content>
            </>
          )}
          {campaigns.length === 0 && (
            <div style={{ marginTop: '15px' }}>
              <NoResults text="Nenhuma campanha encontrada." />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default List;
