import styled from 'styled-components';

export const Title = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 24px;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  margin-bottom: 10px;

  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

export const Container = styled.div`
  width: 100%;

  > span {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    color: ${({ theme }) => theme.font.color.primary};
  }

  @media (max-width: 420px) {
    margin: 0 20px 0 20px;
  }
`;

export const TableContent = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 10px;
  width: 100%;

  thead {
    text-align: left;
    background: ${({ theme }) => theme.font.color.primary};
    color: #fff;
  }

  tbody {
    background: #efefef;
    color: #000;
  }

  th {
    height: 29px;
    padding: 0 25px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};

    @media (max-width: 420px) {
      padding: 0 15px;
    }
  }

  td {
    height: 29px;
    padding: 0 25px;

    @media (max-width: 420px) {
      padding: 0 15px;
    }
  }
`;
