import React from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import quadroIndiretos from 'assets/images/simulation/quadroIndiretos.png';
import quadroTerraBrasil from 'assets/images/simulation/quadroTerraBrasil.png';

import { Modal, Container, Close, StyledText, ColoredSpan } from './styles';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  isTerraBrasil: boolean;
}

const HelpPointsSimulator: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  isTerraBrasil,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={10}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>

      <Container>
        <StyledText>
          <h4>
            É preciso <ColoredSpan>atingir no mínimo 80% </ColoredSpan>das metas
            de Faturamento e POG para receber o rebate. Abaixo desse valor, não
            será realizado o rebate. <br /> <br />O ganho de 100% do rebate só
            será possível se o estabelecimento alcançar ou superar esse valor em
            todos os indicadores de meta. <br /> <br />
            Confira abaixo os ganhos em rebate de acordo com a meta atingida.
          </h4>
        </StyledText>
        {isTerraBrasil ? (
          <img src={quadroTerraBrasil} alt="" />
        ) : (
          <img src={quadroIndiretos} alt="" />
        )}
      </Container>
    </Modal>
  );
};
export default HelpPointsSimulator;
