import routeMap from 'routes/route-map';

import IndirectsBlockAccess from 'pages/BlockAccess/TampaoSafra';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.blockAccess.tampaoSafra,
    component: IndirectsBlockAccess,
    accessPage: 'Juntos Indiretos em Manutenção',
    isPublic: true,
  },
];

export default routes;
