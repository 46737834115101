/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getFmcProducts,
  getValueToConversion,
} from 'state/modules/fmc-coins-simulator/selectors';
import useDimensions from 'hooks/use-window-dimensions';
import routeMap from 'routes/route-map';
import {
  CardsContainer,
  Container,
  Content,
  PageTitle,
  Separator,
} from './styles';
import CardsSaved from 'components/FmcCoinsSimulator/CardsSaved';
import { getSimulationSaved } from 'services/fmc-coins-simulator';

type Params = {
  id: string;
};

const CalculatorSaved: React.FC = () => {
  const { id } = useParams<Params>();
  const productsSelector = useSelector(getFmcProducts);
  const valueToConversion = useSelector(getValueToConversion);
  const { width } = useDimensions();

  const [productStrategic, setProductStrategic] = useState<any | undefined>([]);
  const [productComplementary, setProductComplementary] = useState<
    any | undefined
  >([]);
  const [productInnovation, setProductInnovation] = useState<any | undefined>(
    [],
  );
  const [product, setProduct] = useState<any>();

  const quantityItemsAdded = useMemo(
    () => productsSelector?.filter(item => item.checked).length || 0,
    [productsSelector],
  );

  useEffect(() => {
    const getSimulation = async () => {
      const response = await getSimulationSaved(id);
      setProduct(response);
    };

    getSimulation();
  }, [id]);

  const getProductMock = product && product.products;

  useEffect(() => {
    const productsStrategic = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'estratégico' &&
        getProductMock &&
        getProductMock.find((prod: any) => prod.product === product.id),
    );
    const productsComplementary = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'complementar' &&
        getProductMock &&
        getProductMock.find((prod: any) => prod.product === product.id),
    );
    const productsInnovation = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'inovação' &&
        getProductMock &&
        getProductMock.find((prod: any) => prod.product === product.id),
    );
    const productFilteredById = productsSelector.filter(
      product =>
        getProductMock &&
        getProductMock.find((prod: any) => prod.product === product.id),
    );

    const sortedProductsStrategic = productsStrategic.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsInnovation = productsInnovation.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsComplementary = productsComplementary.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    localStorage.setItem(
      'calculate-products',
      JSON.stringify(productFilteredById),
    );

    setProductStrategic(sortedProductsStrategic);
    setProductComplementary(sortedProductsComplementary);
    setProductInnovation(sortedProductsInnovation);
  }, [productsSelector, getProductMock]);

  return (
    <Container id="calculator">
      <Content>
        <PageTitle>Simulador de FMC Coins</PageTitle>
        <span>Produtos adicionados ({quantityItemsAdded || 4})</span>
      </Content>

      {width <= 500 && (
        <Link to={`${routeMap.fmcCoinsSimulator.result}`} className="bntMobile">
          Calcular
        </Link>
      )}
      <Separator />
      {width <= 500 && (
        <span>Preencha os valores e clique em &quot;Calcular&quot;.</span>
      )}
      <CardsContainer>
        <CardsSaved
          products={productInnovation}
          type="result"
          categoryInterface="Inovação"
          categoryComponent="innovation"
          valueToConversion={valueToConversion}
        />
        <CardsSaved
          products={productStrategic}
          type="result"
          categoryInterface="Estratégico"
          categoryComponent="strategic"
          valueToConversion={valueToConversion}
        />
        <CardsSaved
          products={productComplementary}
          type="result"
          categoryInterface="Complementar"
          categoryComponent="complementary"
          valueToConversion={valueToConversion}
        />
      </CardsContainer>
      <Link to={`${routeMap.fmcCoinsSimulator.result}/${id}`} className="btn">
        Calcular
      </Link>
      {width <= 500 && (
        <Link
          to={`${routeMap.fmcCoinsSimulator.selector}`}
          className="bntMobile"
        >
          Adicionar produtos
        </Link>
      )}
    </Container>
  );
};

export default CalculatorSaved;
