import * as Yup from 'yup';
import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacteres,
  hasUpperCase,
} from 'util/validations/string';

export const passwordValidationSchema = Yup.string()
  .required('Campo obrigatório')
  .min(10, 'Mínimo de 10 caracteres')
  .test(
    'lower-case',
    'Deve conter pelo menos uma letra minúscula',
    hasLowerCase,
  )
  .test(
    'upper-case',
    'Deve conter pelo menos uma letra maiúscula',
    hasUpperCase,
  )
  .test('lower-case', 'Deve conter pelo menos um número', hasNumber)
  .test(
    'has-special-characteres',
    'Deve conter pelo menos um desses caracteres (!, $, #, %, @, &)',
    hasSpecialCharacteres,
  );
