import React, { useCallback, useState } from 'react';
import { animated, useSpring, config } from 'react-spring';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';

import { useRoulette } from 'components/Roulette/hooks/use-roulette';
import { useRouletteDraw } from 'components/Roulette/hooks/use-draw';
import { RouletteStatus } from 'components/Roulette/types';
import useDimensions from 'hooks/use-window-dimensions';

import RouletteArco from '../../assets/arco_luzes.png';
import RouletteArrow from '../../assets/seta.png';
import RouletteButton from '../../assets/button.png';
import RouletteBase from '../../assets/base.png';

import { WheelButton, WheelContainer, WheelWrapper, Wrapper } from './styles';

import { Spinner } from './Spinner';
import { Overlay } from './Spinner/Overlay';

export const Wheel: React.FC = () => {
  const { status, setStatus, spins, rouletteData } = useRoulette();

  const [isOpen, setIsOpen] = useState(spins > 0);

  const { isPreparing, spin, award } = useRouletteDraw();
  const { width } = useDimensions();
  const isMobile = width <= 630 || window.innerWidth <= 630;

  const textFadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 600,
  });

  const transitionDesktop = useSpring({
    width: isOpen ? '100%' : '0%',
    opacity: isOpen ? 1 : 0,
    marginRight: isOpen ? '-100px' : '0px',
    config: config.default,
  });

  const transitionMobile = useSpring({
    height: isOpen ? '100%' : '150px',
    opacity: isOpen ? 1 : 0,
    marginBottom: isOpen ? '-150px' : '-200px',
    config: config.default,
  });

  const mobileTransitionPadding = useSpring({
    paddingBottom: isOpen && isMobile ? '300px' : '0px',
    config: config.default,
  });

  const handleSpinButtonClick = () => {
    // Spin roulette
    if (isPreparing) return;
    if (spins <= 0) return;

    const canSpin = [RouletteStatus.READY, RouletteStatus.DRAWED].includes(
      status,
    );

    if (!canSpin) return;

    setIsOpen(false);
    spin({
      onSpinError: () => setIsOpen(true),
    });
  };

  const handleSpinEnd = useCallback(() => {
    setStatus(RouletteStatus.DRAWED);
    setIsOpen(true);
  }, [setStatus]);

  const handleSpinStart = useCallback(() => {
    setIsOpen(false);
  }, []);

  const isSpinWin =
    status === RouletteStatus.DRAWED && award && award.value >= 0;
  const isSpinLose =
    status === RouletteStatus.DRAWED && award && award.value < 0;
  const isTryAgain =
    RouletteStatus.DRAWED &&
    award &&
    award.title.toLocaleLowerCase().includes('tente novamente');

  return (
    <Wrapper>
      <WheelWrapper>
        <animated.div
          style={isMobile ? transitionMobile : transitionDesktop}
          className="wheel-details__container"
        >
          <animated.div
            style={mobileTransitionPadding}
            className="wheel-details__desc"
          >
            <animated.div style={textFadeIn}>
              {[
                RouletteStatus.DRAWING,
                RouletteStatus.LOADING,
                RouletteStatus.NO_SPINS,
                RouletteStatus.READY,
              ].includes(status) && (
                <>
                  <h1>Gire agora a roleta!</h1>
                  <p>{rouletteData?.messages.main}</p>
                </>
              )}

              {isSpinWin && (
                <>
                  <h1>Parabéns! Você ganhou {award?.title}.</h1>
                  <p>{rouletteData?.messages.win}</p>
                  <Link to="/extrato-roleta-premiada" className="extract-link">
                    Acesse agora!
                  </Link>
                </>
              )}

              {isSpinLose && !isTryAgain && (
                <>
                  <h1>Não foi dessa vez!</h1>
                  <p>{rouletteData?.messages.lose}</p>
                  <Link to="/extrato-roleta-premiada" className="extract-link">
                    Acesse agora!
                  </Link>
                </>
              )}

              {!isSpinLose && isTryAgain && (
                <>
                  <h1>Tente novamente!</h1>
                  <p>{rouletteData?.messages.try_again}</p>
                </>
              )}
            </animated.div>
          </animated.div>
        </animated.div>
        <div>
          <WheelContainer>
            <img src={RouletteArco} alt="Wheel border" className="arco" />
            <img src={RouletteArrow} alt="Arrow" className="wheel-indicator" />

            <WheelButton
              role="button"
              onClick={handleSpinButtonClick}
              loading={isPreparing}
              disabled={
                isPreparing || status === RouletteStatus.DRAWING || spins <= 0
              }
            >
              {isPreparing && (
                <ReactLoading
                  type="bars"
                  className="loader"
                  color="#a3231c"
                  height={24}
                  width={24}
                />
              )}

              <img src={RouletteButton} alt="Button" />
              {!isPreparing && (
                <div className="btn-label">
                  <span>Girar</span>
                  <small>
                    <strong>{spins}</strong> giro(s) restante(s)
                  </small>
                </div>
              )}
            </WheelButton>

            <Spinner
              onSpinEnd={handleSpinEnd}
              onSpinStart={handleSpinStart}
              spinToAward={award}
            />
            <Overlay />

            <img src={RouletteBase} alt="FMC" className="wheel-base" />
          </WheelContainer>
        </div>
      </WheelWrapper>
    </Wrapper>
  );
};
