import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { useToast } from 'context/ToastContext';


import {
  Modal,
  Close,
  Container,
  Title,
  Input,
  DoneButtonContainer,
  SubTitle,
  TokenSendingNumber,
} from './styles';
import {
  MessageCount,
  MessageResend,
  ResendToken,
} from 'components/Auth/ForgotPassword/ChooseResetPassword/style';
import { Button } from 'components/shared';

interface Props {
  isOpenOne: boolean;
  onRequestClose: () => void;
  onSubmit: (token: string) => void;
  selectedMethod: string;
  onChooseSms: () => void;
  onChooseEmail: () => void;
  obfuscatedCellPhone: string;
}

const ModalTokenRecoverPassword: React.FC<Props> = ({
  isOpenOne,
  onRequestClose,
  onSubmit,
  selectedMethod,
  onChooseEmail,
  onChooseSms,
  obfuscatedCellPhone,
}) => {
  const [userToken, setUserToken] = useState('');
  const { addToast } = useToast();
  const [timeLeft, setTimeLeft] = useState(60); // Tempo restante em segundos
  const [isCounting, setIsCounting] = useState(true); // Controla se o contador está ativo
  const [loading, setLoading] = useState(false);

   const handleTokenChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
     setUserToken(e.target.value);
   }, []);


  const handleSubmit = useCallback(() => {
    onSubmit(userToken);
  }, [userToken, onSubmit]);

  useEffect(() => {
    let timer: number;
    if (isCounting && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearTimeout(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    if (isOpenOne) {
      setTimeLeft(60);
      setIsCounting(true); // Reinicia o contador
    }
  }, [isOpenOne]);

  const handleChooseEmail = async () => {
    try {
      setLoading(true);
      await onChooseEmail();

      isOpenOne = false
      setIsCounting(false);

    } catch (error) {
      if (error instanceof Error) {
        addToast({
          title: error.message,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSmsClick = async () => {
    try {
      setLoading(true);
      await onChooseSms();
      setTimeLeft(60);
      setIsCounting(true);
      isOpenOne = false
    } catch (error) {
      if (error instanceof Error) {
        addToast({
          title: error.message,
          type: 'error',
        });
    }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpenOne} onRequestClose={onRequestClose} zIndex={10}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Container>
        <Title>TOKEN ENVIADO!</Title>
        {selectedMethod === 'sms' && (
          <>
            <SubTitle>Código de segurança enviado para o número:</SubTitle>
            <TokenSendingNumber>{obfuscatedCellPhone}</TokenSendingNumber>
          </>
        )}
        <Input
          type="text"
          value={userToken}
          onChange={handleTokenChange}
          placeholder="Digite o token aqui"
        />
        <DoneButtonContainer>
          <Button
            buttonRole="primary"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            ENVIAR TOKEN
          </Button>
        </DoneButtonContainer>
      </Container>
      {
        selectedMethod === 'sms' ? (
          <>
            <MessageCount>
              Caso não receba o SMS em {timeLeft}s, solicite novamente por e-mail.
            </MessageCount>
          </>
        ) : (
          <>
            <MessageCount>
              Caso não receba em {timeLeft}s, solicite novamente.
            </MessageCount>
          </>
        )
      }
      <MessageResend>
        <ResendToken>
          <button
            onClick={handleChooseEmail}
            type="button"
            disabled={isCounting}
            style={{ cursor: isCounting ? 'not-allowed' : 'pointer' }}
          >
            Receber por e-mail
          </button>
        </ResendToken>
        <span>ou</span>
        <ResendToken>
          <button
            onClick={handleSmsClick}
            type="button"
            disabled={isCounting}
            style={{ cursor: isCounting ? 'not-allowed' : 'pointer' }}
          >
            Reenviar SMS
          </button>
        </ResendToken>
      </MessageResend>
    </Modal>
  );
};

export default ModalTokenRecoverPassword;
