import styled from 'styled-components';

import DefaultGraduationLevelSelect from 'components/Auth/Register/Form/Selects/GraduationLevelSelect';
import DefaultMaritalSelect from 'components/Auth/Register/Form/Selects/MaritalStatusSelect';
import DefaultUfSelect from 'components/Auth/Register/Form/Selects/UfsSelect';
import DefaultInput from 'components/shared/Input';
import DefaultButton from 'components/shared/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(DefaultInput)`
  margin-top: 15px;
  max-width: 499px;

  ._inputContainer {
    height: 40px;
  }
`;

export const GraduationLevelSelect = styled(DefaultGraduationLevelSelect)`
  margin-top: 15px;
  max-width: 499px;

  ._inputContainer {
    height: 40px;
  }
`;

export const MaritalSelect = styled(DefaultMaritalSelect)`
  margin-top: 15px;
  max-width: 499px;

  ._inputContainer {
    height: 40px;
  }
`;

export const UfSelect = styled(DefaultUfSelect)`
  margin-top: 15px;
  max-width: 160px;

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 36px;
  }

  ._inputContainer {
    height: 40px;
  }
`;

export const Button = styled(DefaultButton)`
  background: #e63027;
  color: #fff;
  max-width: 300px;

  &:hover {
    background: #e63027;
    filter: grayscale(20%);
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 10px;
  width: 100%;
`;
