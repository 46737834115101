/* eslint-disable react/forbid-prop-types */
import * as Yup from 'yup';
import validateCpf from 'util/validations/cpf';
import validMobilePhone from 'util/validations/mobilePhone';
import {
  hasLowerCase,
  hasNumber,
  hasUpperCase,
  hasSpecialCharacteres,
} from 'util/validations/string';
import { PROFILES } from 'config/constants';
import { validatePis } from 'util/validations/pis';

const mandatoryMessage = 'Campo obrigatório';

const personalDataFormSchema = Yup.object().shape({
  nick_name: Yup.string().required(mandatoryMessage),
  name: Yup.string().required(mandatoryMessage),
  email: Yup.string().required(mandatoryMessage).email('E-mail inválido'),
  cpf: Yup.string()
    .required(mandatoryMessage)
    .test('valid-cpf', 'CPF inválido', validateCpf),
  area_code: Yup.string().required(mandatoryMessage),
  cell_phone: Yup.string()
    .required(mandatoryMessage)
    .test('valid-mobile', 'Número inválido', validMobilePhone),
  formatted_birth_date: Yup.date()
    .transform((t, v) => {
      const newValue = v.split('/');
      return new Date(`${newValue[1]}/${newValue[0]}/${newValue[2]}`);
    })
    .typeError('Data inválida'),
});

const extraProducerFields = Yup.object().shape({
  get_to_know_select: Yup.object({
    value: Yup.string(),
  }),
  gender_select: Yup.object({
    value: Yup.string().required(mandatoryMessage),
  })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
  medium_select: Yup.object({
    value: Yup.string().required(mandatoryMessage),
  })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
  other_channels: Yup.string()
    .ensure()
    .when('medium_select.value', {
      is: 'OUTROS',
      then: Yup.string().required(
        `Ao selecionar "OUTROS", é necessário informar um canal.`,
      ),
    }),
  public_place_select: Yup.object()
    .shape({
      value: Yup.string().required(mandatoryMessage),
    })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
  address: Yup.object().shape({
    zip_code: Yup.string().required(mandatoryMessage),
    street: Yup.string().required(mandatoryMessage),
    number: Yup.string()
      .required(mandatoryMessage)
      .max(7, 'máximo de 7 caracteres'),
    complement: Yup.string(),
    district: Yup.string().required(mandatoryMessage),
    city: Yup.string().required(mandatoryMessage),
  }),
  state_code_select: Yup.object()
    .shape({
      value: Yup.string().required(mandatoryMessage),
    })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
  formatted_birth_date: Yup.date()
    .transform((t, v) => {
      const newValue = v.split('/');
      return new Date(`${newValue[1]}/${newValue[0]}/${newValue[2]}`);
    })
    .typeError('Data inválida')
    .test('age', 'Idade mínima: 18 anos', birthdate => {
      const cutoff = new Date();
      cutoff.setFullYear(cutoff.getFullYear() - 18);
      return birthdate <= cutoff;
    }),
});

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.lazy<string>(v =>
    v !== ''
      ? Yup.string()
          .min(10, 'Mínimo de 10 caracteres')
          .test(
            'lower-case',
            'Deve conter pelo menos uma letra minúscula',
            hasLowerCase,
          )
          .test(
            'upper-case',
            'Deve conter pelo menos uma letra maiúscula',
            hasUpperCase,
          )
          .test('lower-case', 'Deve conter pelo menos um número', hasNumber)
          .test(
            'has-special-characteres',
            'Deve conter pelo menos um desses caracteres (!, $, #, %, @, &)',
            hasSpecialCharacteres,
          )
      : Yup.string(),
  ),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password')],
    'Confirmação de senha precisa ser igual a senha',
  ),
});

const editParticipantValidations = Yup.object().shape({
  pis_nis: Yup.string().test('valid-pis', 'PIS inválido', validatePis),
  public_place_select: Yup.object()
    .shape({
      value: Yup.string().required(mandatoryMessage),
    })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
  address: Yup.object().shape({
    zip_code: Yup.string().required(mandatoryMessage),
    street: Yup.string().required(mandatoryMessage),
    number: Yup.string()
      .required(mandatoryMessage)
      .max(7, 'máximo de 7 caracteres'),
    complement: Yup.string(),
    district: Yup.string().required(mandatoryMessage),
    city: Yup.string().required(mandatoryMessage),
  }),
  state_code_select: Yup.object()
    .shape({
      value: Yup.string().required(mandatoryMessage),
    })
    .typeError(mandatoryMessage)
    .required(mandatoryMessage),
});

export function getValidationSchema(profile: string, editing = false) {
  const isProducer = profile === PROFILES.producer;
  let schema = Yup.object();

  schema = schema.concat(personalDataFormSchema);

  /** Caso for um usuário produtor, inclui a validação dos campos extras
   * que apenas os produtores possuem. */
  if (isProducer) {
    schema = schema.concat(extraProducerFields);
  } else {
    schema = schema.concat(passwordValidationSchema);
  }

  if (
    (editing && profile === PROFILES.participant) ||
    (editing && profile === PROFILES.focalPoint)
  ) {
    schema = schema.concat(editParticipantValidations);
  }

  return schema;
}
