import { pluginApi } from 'services/api';

interface ApiResponse {
  balances:
    | {
        value: number;
      }
    | string;
}

async function getNewPoints(
  cpfCnpj: string,
  campaignId: number,
): Promise<number> {
  const {
    data: { balances },
  } = await pluginApi.get<ApiResponse>(
    `premio-ideall/getAvailableBalance?cpf=${cpfCnpj}&campaignId=${campaignId}`,
  );

  if (typeof balances === 'string') {
    // Solução temporaria !!!!!!
    // Bypass para o rate-limiter do endpoint
    if (balances.includes('Você deve aguardar')) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(getNewPoints(cpfCnpj, campaignId));
        }, 1000);
      });
    }
    return 0;
  }

  return balances.value || 0;
}

export default getNewPoints;
