import React, { useEffect, useState } from 'react';
import ParticipantsFinder from 'components/PointManagement/ParticipantsFinder';
import SubsidiarySelect from 'components/PointManagement/SubsidiarySelect';
import fetchSubsidiariesService from 'services/establishment/getSubsidiaryList';
import RolesList from 'components/PointManagement/RolesList';
import {
  Subsidiary,
  Role,
} from 'state/modules/point-management/team-awards/types';
import { getProtectedRoles } from 'services/role/protectedRoles';

import { Container } from './styles';

type Props = {
  isVisible: boolean;
  establishmentId: number;
  selectedSubsidiaries: string[];
  selectedRoles: string[];
  onRoleSelect: (value?: string) => void;
  onSubsidiarySelect: (value: string) => void;
  onParticipantSearch: (term: string) => void;
};

/** TODO: Melhorar gerenciamento de estado dos filtros */
export const Filter: React.FC<Props> = ({
  establishmentId,
  isVisible,
  onRoleSelect,
  onSubsidiarySelect,
  onParticipantSearch,
  selectedRoles,
  selectedSubsidiaries,
}) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);

  useEffect(() => {
    async function fetchRoles() {
      const resp = await getProtectedRoles();
      setRoles(resp);
    }

    async function fetchSubsidiaries() {
      const resp = await fetchSubsidiariesService(establishmentId);
      setSubsidiaries(resp);
    }

    fetchSubsidiaries();
    fetchRoles();
  }, [establishmentId]);

  return (
    <Container style={{ display: isVisible ? 'grid' : 'none' }}>
      <SubsidiarySelect
        onSelect={onSubsidiarySelect}
        selectedSubsidiaries={selectedSubsidiaries}
        subsidiaries={subsidiaries}
      />
      <ParticipantsFinder onChange={onParticipantSearch} />
      <div className="roles">
        <RolesList
          roles={roles}
          selectedRoles={selectedRoles}
          onSelect={onRoleSelect}
          isFetchingRoles={false}
        />
      </div>
    </Container>
  );
};
