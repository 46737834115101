import styled from 'styled-components';
import Slider from 'react-slick';
import { Dot } from '../../../components/Home/Performance/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 47px;

  > h3 {
    color: #34ffff;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 21px;
    margin-top: 18px;
    margin-bottom: 10px;
  }

  ._extra-margin {
    margin-top: 39px;
  }

  @media screen and (max-width: 720px) {
    margin-top: 16px;

    > h3 {
      margin-left: 10px;
      margin-bottom: 5px;
    }

    ._extra-margin {
      margin-top: 16px;
    }
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-gap: 60px;
  grid-template-columns: 320px 320px;
  margin: 12px 0 20px 0;
  width: 100%;

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 6px;
  }
`;

export const List = styled.ul`
  display: grid;
  flex-direction: column;
  margin: 0 5px;
  width: 30px;
`;

export const Label = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em 0 0.7em 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  > span {
    font-size: 9px;
    transform: translateY(1px);
  }
`;

export const IndividualProgressWrapper = styled.div`
  margin: 1em;
  width: 160px;

  @media screen and (max-width: 730px) {
    margin: 0;
  }
`;

export const IndividualWatchProduct = styled.div`
  margin: 2em;
  width: 110px;

  @media screen and (max-width: 730px) {
    margin: 0;
  }
`;
export const GoalText = styled.p`
  color: #000000;
  font-size: 11px;
  text-align: center;
  margin: 0.5em 0 0.7em 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

export const AllBox = styled.div`
  background-color: #fff;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);
  height: auto;
  width: 105%;
`;

export const BoxForAllWatches = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  @media screen and (max-width: 730px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const WatchBox = styled.div`
  margin: 1em;
  width: 160px;
`;

export const WatchBoxCockpit = styled.div`
  margin: 2em;
  width: 110px;
`;

export const Separator = styled.hr`
  width: 2px;
  height: 160px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  margin-left: 0.7em;

  @media screen and (max-width: 730px) {
    width: 80%;
    height: 2px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    margin-left: 20px;
  }
`;

export const CustomSlider = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: green !important;
  }

  .slick-prev,
  .slick-next {
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 15px;
  }
  .slick-prev {
    z-index: 99999; !important;
  }

  width: 800px;
  img {
    width: 100%;
  }

  .slick-dots {
    bottom: 10px;

    .slick-active {
      ${Dot} {
        &:before {
          background-color: #fff;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
