import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'context/AuthContext';
import { Container, Navigation, NavigationItem } from './styles';

export type Tab =
  | 'PERSONAL_DATA'
  | 'FARM_DATA'
  | 'HARVEST_DATA'
  | 'SECURITY_DATA';

interface Props {
  activeTab: Tab;
  setActiveTab(tab: Tab): void;
  completeRegister?: boolean;
}

const Header: React.FC<Props> = ({
  activeTab,
  completeRegister,
  setActiveTab,
}) => {
  const { search } = useLocation();
  const { participant } = useAuth();

  useEffect(() => {
    // Pré seleciona uma tab
    const params = new URLSearchParams(search);
    const preSelect = params.get('pre_select');

    if (preSelect) {
      switch (preSelect) {
        case 'dados_de_cultivo':
          setActiveTab('HARVEST_DATA');
          break;
        case 'dados_fazenda':
          setActiveTab('FARM_DATA');
          break;
        default:
          break;
      }
    }
  }, [search, setActiveTab]);

  useEffect(() => {
    // Pré seleciona uma tab
    const params = new URLSearchParams(search);
    const preSelect = params.get('pre_select');

    if (preSelect) {
      switch (preSelect) {
        case 'dados_de_cultivo':
          setActiveTab('HARVEST_DATA');
          break;
        case 'dados_fazenda':
          setActiveTab('FARM_DATA');
          break;
        default:
          break;
      }
    }
  }, [search, setActiveTab]);

  const isFarmRegisterComplete = !participant.role.complete_registration
    ?.missing?.grupo_fazenda;
  const isCultivoRegisterComplete = !participant.role.complete_registration
    ?.missing?.cultivo;

  return (
    <Container>
      <Navigation>
        <NavigationItem
          onClick={() => setActiveTab('PERSONAL_DATA')}
          className={activeTab === 'PERSONAL_DATA' ? 'isCurrent' : ''}
          haserrors={0}
        >
          Pessoa física
        </NavigationItem>
        <NavigationItem
          onClick={() => setActiveTab('FARM_DATA')}
          className={activeTab === 'FARM_DATA' ? 'isCurrent' : ''}
          haserrors={isFarmRegisterComplete ? 0 : 1}
        >
          Dados da minha fazenda e/ou CNPJ*
        </NavigationItem>
        <NavigationItem
          onClick={() => setActiveTab('HARVEST_DATA')}
          className={activeTab === 'HARVEST_DATA' ? 'isCurrent' : ''}
          haserrors={isCultivoRegisterComplete ? 0 : 1}
        >
          Dados da área de cultivo
        </NavigationItem>
        {!completeRegister && (
          <NavigationItem
            onClick={() => setActiveTab('SECURITY_DATA')}
            className={activeTab === 'SECURITY_DATA' ? 'isCurrent' : ''}
            haserrors={0}
          >
            Segurança
          </NavigationItem>
        )}
      </Navigation>
    </Container>
  );
};

export default Header;
