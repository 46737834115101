/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactSVG } from 'react-svg';
import CloseIcon from 'assets/images/training/close-icon.svg';
import { Profile } from 'services/auth/getAvailableProfiles';
import { hideProfileChange } from 'state/modules/header/actions';
import { getAvailableProfiles } from 'state/modules/header/selectors';
import { useAuth } from 'context/AuthContext';
import history from 'services/history';
import {
  Container,
  Modal,
  Button,
  Title,
  Actions,
  Close,
  ButtonProdutor,
} from './styles';
// release
interface ChangeProfileProps {
  isOpen: boolean;
}

const ChangeProfile: React.FC<ChangeProfileProps> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { changeProfile } = useAuth();
  const availableProfiles = useSelector(getAvailableProfiles);

  const isResale =
    !!availableProfiles.indirect &&
    availableProfiles.indirect?.establishmentType === 'Revenda';

  const onClickHandler = useCallback(
    async (profile: Profile | null) => {
      if (!profile) return;

      const { establishmentId, roleId, loggedIn } = profile;

      if (loggedIn) {
        dispatch(hideProfileChange());
        return;
      }

      try {
        await changeProfile({
          establishmentId,
          roleId,
        });

        dispatch(hideProfileChange());
        history.push('/');
      } catch (err) {}
    },
    [changeProfile, dispatch],
  );

  const closeRequestHandler = useCallback(() => dispatch(hideProfileChange()), [
    dispatch,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeRequestHandler} zIndex={10}>
      <Close>
        <button type="button" onClick={closeRequestHandler}>
          <ReactSVG src={CloseIcon} />
        </button>
      </Close>
      <Container>
        <Title>
          Acessar qual perfil? <br />
        </Title>
        <Actions>
          <Button
            type="button"
            buttonRole="tertiary"
            onClick={() => onClickHandler(availableProfiles.indirect)}
            isResale={isResale}
          >
            ACESSAR MEU PERFIL <br /> NO JUNTOS{' '}
            {isResale ? 'REVENDA' : 'COOPERATIVA'}
          </Button>
          <ButtonProdutor
            type="button"
            buttonRole="secondary"
            onClick={() => onClickHandler(availableProfiles.producer)}
          >
            ACESSAR MEU PERFIL <br /> NO JUNTOS PRODUTOR
          </ButtonProdutor>
        </Actions>
      </Container>
    </Modal>
  );
};

export default ChangeProfile;
