import React, { useEffect, useState } from 'react';
import { ApprovationStatus } from 'services/point-management/dtos/point';

import { formatPoints } from 'util/points';
import { StatusIcon } from 'components/PointManagement/point-card/status-icon';
import { FaDownload } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { downloadExcel } from 'util/download-excel';
import { Container, Details } from './styles';

type Props = {
  distributedPoint: any;
  pointsAl: any;
};

export const DistributedPoint: React.FC<Props> = ({
  distributedPoint,
  pointsAl,
}) => {
  const [participantExcel, setParticipantExcel] = useState<any>([]);
  useEffect(() => {
    const allParticipants: any[] = [];

    pointsAl &&
      pointsAl.participants &&
      pointsAl.participants.forEach(
        (obj: any) => obj && allParticipants.push(obj),
      );

    setParticipantExcel(allParticipants);
  }, [pointsAl]);

  return (
    <Container>
      <strong>{formatPoints(Number(distributedPoint.value), 2)}</strong>
      <Details>
        <button type="button" onClick={() => downloadExcel(participantExcel)}>
          <FaDownload /> <strong>Download</strong>
        </button>

        <StatusIcon
          key={uuid()}
          status={
            distributedPoint.status === 5 || distributedPoint.status === 3
              ? ApprovationStatus.APROVADO
              : ApprovationStatus.AGUARDANDO_DISTRIBUICAO
          }
          statusText={
            distributedPoint.status === 5 || distributedPoint.status === 3
              ? 'Aprovado'
              : 'Aguardando aprovação'
          }
        />
      </Details>
    </Container>
  );
};
