import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { ApiAction } from 'services/strategic-plans/actions/interfaces';
import {
  getDevelopmentProductActionsByPlanId,
  getRelationshipActionsByPlanId,
  getStrategyActionTypes,
  getActionStatusForSelect,
} from 'services/strategic-plans/actions';

import { StrategicPlan } from 'components/StrategicPlans/types';
import { Option } from 'components/shared/Select';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import {
  StrategicPlan as IStrategicPlan,
  PlanFilters,
} from 'services/strategic-plans/interfaces';
import { getStrategicPlans } from 'services/strategic-plans/getStrategicPlans';

export interface StrategicPlansContextState {
  selectedStrategicPlan: StrategicPlan;
  setSelectedStrategicPlan: (value: StrategicPlan) => void;
  strategicPlans: IStrategicPlan[];
  setStrategicPlans: (value: IStrategicPlan[]) => void;
  productActions: ApiAction[];
  relationshipActions: ApiAction[];
  actionStatuses: Option[];
  actionTypes: Option[];
  filters: PlanFilters;
  setFilters: (value: PlanFilters) => void;
}

const StrategicPlansContext = createContext<StrategicPlansContextState>(
  {} as StrategicPlansContextState,
);

export const StrategicPlansProvider: React.FC = ({ children }) => {
  const [strategicPlans, setStrategicPlans] = useState<IStrategicPlan[]>([]);
  const [filters, setFilters] = useState<PlanFilters>({} as PlanFilters);
  const [selectedStrategicPlan, setSelectedStrategicPlan] = useState<
    StrategicPlan
  >({
    id: 0,
    uuid: '',
    category: '',
    clientGroup: '',
    status: strategyPlanStatus.planning,
    comments: [],
    campaignId: null,
    actions: [],
  });

  const [productActions, setProductActions] = useState<ApiAction[]>([]);
  const [relationshipActions, setRelationshipActions] = useState<ApiAction[]>(
    [],
  );
  const [actionStatuses, setActionStatuses] = useState<Option[]>([]);
  const [actionTypes, setActionTypes] = useState<Option[]>([]);

  const fetchProductActions = useCallback(async (strategicPlanId: number) => {
    const data = await getDevelopmentProductActionsByPlanId(strategicPlanId);
    setProductActions(data);
  }, []);

  const fetchRelationshipActions = useCallback(
    async (strategicPlanId: number) => {
      const data = await getRelationshipActionsByPlanId(strategicPlanId);
      setRelationshipActions(data);
    },
    [],
  );

  const fetchActionTypes = useCallback(async () => {
    const data = await getStrategyActionTypes();

    setActionTypes(data);
  }, []);

  const fetchActionStatuses = useCallback(async () => {
    const data = await getActionStatusForSelect();
    setActionStatuses(data);
  }, []);

  const refreshProductActions = useCallback(async () => {
    if (selectedStrategicPlan.id) {
      fetchProductActions(selectedStrategicPlan.id);
    }
  }, [fetchProductActions, selectedStrategicPlan.id]);

  const refreshRelationshipActions = useCallback(async () => {
    if (selectedStrategicPlan.id) {
      fetchRelationshipActions(selectedStrategicPlan.id);
    }
  }, [fetchRelationshipActions, selectedStrategicPlan.id]);

  const fetchStrategicPlansApi = useCallback(async () => {
    if (!selectedStrategicPlan.campaignId) return;
    try {
      const data = await getStrategicPlans({
        campaignId: selectedStrategicPlan.campaignId,
        fetch: 'created-plans',
        filters,
      });

      if (data.data) setStrategicPlans(data.data);
    } catch (err) {
      setStrategicPlans([]);
    }
  }, [filters, selectedStrategicPlan.campaignId]);

  useEffect(() => {
    refreshProductActions();
    refreshRelationshipActions();
  }, [refreshProductActions, refreshRelationshipActions]);

  useEffect(() => {
    fetchActionTypes();
    fetchActionStatuses();
    fetchStrategicPlansApi();
  }, [fetchStrategicPlansApi, fetchActionTypes, fetchActionStatuses]);

  return (
    <StrategicPlansContext.Provider
      value={{
        selectedStrategicPlan,
        setSelectedStrategicPlan,
        strategicPlans,
        setStrategicPlans,
        productActions,
        relationshipActions,
        actionStatuses,
        actionTypes,
        filters,
        setFilters,
      }}
    >
      {children}
    </StrategicPlansContext.Provider>
  );
};

export const useStrategicPlansContext = (): StrategicPlansContextState => {
  const context = useContext(StrategicPlansContext);

  if (!context) {
    throw new Error(
      'useHavestTermsContext must be used within a StrategicPlansContext',
    );
  }

  return context;
};
