/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import {
  Container,
  RadioContent,
  RadioContentGroup,
  AnswerLabel,
  GridRow,
} from './styles';
import RadioList from './RadioList';

interface ButtonsSquareNumberProps {
  question: string;
  answers: AnswerData[];
  id: number;
  topics: string[];
  canEdit: boolean;
}

const ButtonsSquareNumber: React.FC<ButtonsSquareNumberProps> = ({
  question,
  answers,
  id,
  topics,
  canEdit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'many' });
    }
  }, [answers, canEdit, dispatch, id]);

  const changeHandler = useCallback(
    (answerValue: string, answerId: number, topic: string): void => {
      dispatch(
        setValueAnswer({
          answerValue,
          questionId: id,
          answerId,
          topic,
        }),
      );
    },
    [dispatch, id],
  );

  return (
    <Container>
      <RadioContent>
        <p>{question}</p>
        <RadioContentGroup>
          <GridRow>
            <AnswerLabel>{'      '}</AnswerLabel>
            {topics.map(item => (
              <span key={item} style={{ textAlign: 'center' }}>
                {item}
              </span>
            ))}
          </GridRow>
          {answers.map(answer => (
            <GridRow key={answer.id}>
              <AnswerLabel>{answer.answer}</AnswerLabel>
              <RadioList
                answer={answer}
                topics={topics}
                onChangeHandler={(value: string, topic: string) => {
                  changeHandler(value, answer.id, topic);
                }}
                canEdit={canEdit}
              />
            </GridRow>
          ))}
        </RadioContentGroup>
      </RadioContent>
    </Container>
  );
};

export default ButtonsSquareNumber;
