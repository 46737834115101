import { pluginApi } from 'services/api';

interface Response {
  download_url: string;
}

const getChannelCampaignPerformanceLink = async (
  campaingId: number,
): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `reports/campaign-performance?campaign_id=${campaingId}`,
  );

  return data.download_url
    ? `${data.download_url}&campaign_id=${campaingId}`
    : '';
};

const getProductsPerformanceLink = async (
  campaingId: number,
): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `reports/products-performance?campaign_id=${campaingId}`,
  );

  return data.download_url
    ? `${data.download_url}&campaign_id=${campaingId}`
    : '';
};

const getAccessLogLink = async (campaingId: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/access?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getParticipantsLogLink = async (campaingId: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/team?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getInvoicesReportLink = async (campaingId: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/invoices?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getProducersRescuesReportLink = async (
  campaingId: number,
): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/rescues-producers?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getIndirectsRescuesReportLink = async (
  campaingId: number,
): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/rescues-indirects?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getReportRegisterOff = async (campaingId: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/report-register-off?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

const getBiologicalReport = async (campaingId: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/biological?campaign_id=${campaingId}`,
  );

  return data.download_url || '';
};

export {
  getChannelCampaignPerformanceLink,
  getProductsPerformanceLink,
  getAccessLogLink,
  getParticipantsLogLink,
  getInvoicesReportLink,
  getProducersRescuesReportLink,
  getIndirectsRescuesReportLink,
  getReportRegisterOff,
  getBiologicalReport,
};
