import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #821c0e;
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
  position: relative;

  ._inputContainer {
    max-width: 300px;
    border: 1px solid #821c0e;
  }

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
  }
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

export const SelectedCultivationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

export const SelectedProductsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #821c0e;
`;
