import styled from "styled-components";

// Container geral da página
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
`;

// Título da página
export const Title = styled.h1`
  font-size: 16px;
  color: #333;
  margin-Top: 20px;
  margin-bottom: 10px;
`;

export const Item = styled.div`
  background-color: #E0ECF8;
  color: "#ffcc33";
  width: 70%;
  padding: 10px;
  display: flex; /* Adicionado para usar flexbox */
  align-items: center; /* Alinha verticalmente os itens */
  justify-content: space-between;
`

export const DownloadLink = styled.a`
  font-size: 16px;
  text-decoration: none;
  margin-left: auto; /* Garante que o link vá para a direita */
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// Input de upload estilizado
export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between; // Deixa os itens nas extremidades
    gap: 15px; // Espaçamento entre os dois itens
  }

  label {
    font-size: 16px;
    color: #333;
  }

  input {
    display: none; // Esconde o input padrão
  }

  .upload-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      padding: 10px 20px;
      background-color: #ff9900;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #cc7a00;
      }
    }
  }

  .submit-button {
    margin-top: 10px;
    align-self: flex-start;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

// Tabela de histórico
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;

  thead {
    background-color:rgb(62, 66, 70);
    color: white;

    th {
      padding: 10px;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      padding: 10px;
      color: #333;
    }
  }
`;

// Badge de status
export const StatusBadge = styled.span<{ status: string }>`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;

  background-color: ${({ status }) =>
    status === "success"
      ? "#28a745"
      : status === "error"
      ? "#dc3545"
      : "#ffc107"};
`;

export const PaginationContainer = styled.div`
  display: flex;
  color: #ccc;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const PaginationButton = styled.button`
  margin: 0 10px;
  padding: 8px 10px;
  border: none;
  background-color:rgb(228, 8, 8);
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;