import React from 'react';
import { ParticipantChannelsData } from 'services/participant-channels/interface';
import { Container, Item, List, Separator } from './styles';

interface Props {
  channelsList: ParticipantChannelsData[];
}

const ListParticipantChannels: React.FC<Props> = ({ channelsList }) => {
  return (
    <Container>
      {channelsList.map(
        ({
          id,
          channel_name: channelName,
          city,
          uf,
          address,
          contact,
          client_group: clientGroup,
          cnpj,
          address_number: addressNumber,
          complement,
          zip_code: cep,
          integrator,
        }) => (
          <List key={id}>
            <Item>
              <strong>Grupo de Cliente:</strong> {clientGroup}
            </Item>
            <Item>
              <strong>Razão Social:</strong> {channelName}
            </Item>
            <Item>
              <strong>CNPJ:</strong> {cnpj}
            </Item>
            <Item>
              <strong>Município:</strong> {city}
            </Item>
            <Item>
              <strong>UF:</strong> {uf}
            </Item>
            <Item>
              <strong>Endereço:</strong> {address}, Nº {addressNumber},{' '}
              {complement && `COMPLEMENTO: ${complement},`} CEP: {cep}
            </Item>
            <Item>
              <strong>Contato:</strong> {contact}
            </Item>
            <Item>
              <strong>Envio Automático de NF:</strong> {integrator ? 'Sim' : 'Não'}
            </Item>
            <Separator />
          </List>
        ),
      )}
    </Container>
  );
};

export default ListParticipantChannels;
