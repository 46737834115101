import routeMap from 'routes/route-map';
import CompleteIndirectRegistration from 'pages/CompleteIndirectRegistration';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.completeIndirectRegistration,
    component: CompleteIndirectRegistration,
    accessPage: 'Completar Cadastro de Indiretos',
  },
];

export default routes;
