import React, { useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { BaseSelect } from 'components/StrategicPlans/ActionsForms/shared/styles';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import { getStatusText } from 'components/StrategicPlans/utils';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  setValue(value: Option | null): void;
  value: Option | null;
}

const StatusSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
  placeholder,
}) => {
  const loadItems = useCallback(() => {
    return [
      strategyPlanStatus.approved,
      strategyPlanStatus.inAdjustments,
      strategyPlanStatus.onApproval,
      strategyPlanStatus.outOfTime,
      strategyPlanStatus.planning,
    ].map(status => ({
      title: getStatusText(status),
      value: `${status}`,
    })) as Option[];
  }, []);

  return (
    <BaseSelect
      label="Status"
      loadItems={loadItems}
      className={className}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      inputRole="primary"
    />
  );
};

export default StatusSelect;
