import styled from 'styled-components';

export const Card = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  padding-top: 18px;
  .btnBack {
    text-decoration: none;
  }
`;

interface CategoriesColor {
  inputRole: 'complementary' | 'innovation' | 'strategic';
}

export const Title = styled.h3<CategoriesColor>`
  height: 21px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme, inputRole }) => theme.fmcProducts[inputRole].color};
`;

export const SeeMoreButton = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  cursor: pointer;
  border-radius: 12px;
  span {
    width: 224px;
    height: 20px;
    font-size: 10px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Button = styled.button<CategoriesColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: none;
  background-color: ${({ theme, inputRole }) =>
    theme.fmcProducts[inputRole].color};

  span {
    font-size: 26px;
    line-height: 18px;
    font-weight: bold;
    border-radius: 12px;
    color: #ffffff;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
`;

export const Contents = styled.label<CategoriesColor>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 36px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid
    ${({ theme, inputRole }) => theme.fmcProducts[inputRole].color};
  background-color: #ffffff;

  > img {
    width: 66px;
    height: 20px;
    z-index: 2;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 3px;
    margin-bottom: 3px;

    > input {
      width: 80px;
      height: 18px;
      border-radius: 2px;
      background-color: #ffffff;
      border: 0.5px solid #808285;
      font-size: 12px;
      padding: 3px;
    }

    > span {
      font-size: 6px;
      color: ${({ theme }) => theme.font.color.primary};
      margin-bottom: 1px;
    }
  }
`;

export const ResultContents = styled.div`
  padding-left: 10px;
  .value {
    font-size: 12px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Checkbox = styled.span<CategoriesColor>`
  display: flex;
  align-items: center;

  > span {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 1px solid
      ${({ theme, inputRole }) => theme.fmcProducts[inputRole].color};
    position: relative;
    margin-right: 5px;

    > img {
      display: none;
    }
  }

  > input {
    appearance: none;
  }

  > input:checked + span {
    > img {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-top: -8px;
      margin-left: -5px;
    }
  }
`;
