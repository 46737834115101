import styled from 'styled-components';

export const Container = styled.ul`
  list-style-type: none;
  margin-top: 5px;

  .slider {
    display: flex;
    gap: 8px;
    height: fit-content;
  }

  span {
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
  }

  button {
    background: none;
    border: none;
    opacity: 0.6;
    color: ${({ theme }) => theme.font.color.secondary};
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    font-size: 0.625rem;
    display: flex;
    align-items: center;
    gap: 3px;

    &:hover {
      opacity: 1;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;
