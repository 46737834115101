import { pluginApi } from 'services/api';
import { AxiosError } from 'axios';


interface ApiResponse {
  success_count: number;
  errors: string[];
  success: boolean;
  processedRows: number;
  message: string;
}

interface Response {
  success_count: number;
  success: boolean;
  processedRows: number;
  message: string;
  errors: string[];
}


export default async (file: File, id: number, id_import: string): Promise<Response> => {
  try {
    console.log("Arquivo recebido:", file);
    console.log("ID associado:", id);

    // Criar o FormData e adicionar o arquivo e o ID
    const formData = new FormData();
    formData.append("file", file); // Adiciona o arquivo
    formData.append("id", id.toString()); // Adiciona o identificador
    formData.append("id_import", id_import.toString()); // Adiciona o identificador

    // Enviar o arquivo e o ID para o backend
    const { data } = await pluginApi.post<ApiResponse>(
      "dictionary/upload-dictionary",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

        // Verificar se a resposta indica sucesso
    if (data.success) {
      return {
        success: data.success,
        success_count: data.processedRows,
        processedRows: data.processedRows,
        message: data.message,
        errors: [],
      };
    }

    // Caso contrário, tratar como erro
    return {
      success: false,
      success_count: 0,
      processedRows: 0,
      message: data.message,
      errors: [data.message],
    };
  } catch (e) {
    // Captura de erros
    const axiosError = e as AxiosError<{ message: string }>;

    // Verificar se há uma mensagem de erro específica no response
    const errorMessage =
      axiosError.response?.data?.message ||
      "Erro não identificado, por favor entre em contato com o suporte";

    return {
      success: false,
      success_count: 0,
      processedRows: 0,
      message: errorMessage,
      errors: [errorMessage],
    };
  }
};