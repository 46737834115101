import styled, { keyframes } from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { FaCheck } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
  }
`;

interface CheckIconProps {
  selected: boolean;
}

export const Container = styled.div<CheckIconProps>`
  animation: ${fadeIn} 250ms ease-out;
  background-color: #fff;
  padding: 6px 15px;
  display: grid;
  grid-template-columns: max-content minmax(140px, max-content) max-content;
  align-items: center;
  gap: 25px;
  color: ${({ theme }) => theme.font.color.primary};

  strong,
  span {
    color: ${({ selected }) => (selected ? '#ccc' : '')};
  }

  @media screen and (max-width: 520px) {
    grid-template-columns: max-content 1fr max-content;
  }
`;

export const CheckboxContainer = styled.div`
  position: relative;
`;

export const StyledCheckbox = styled(Checkbox)<CheckIconProps>`
  display: block; // Talvez você possa remover esta linha ou ajustar conforme necessário
  position: absolute;
  z-index: 1000;
  top: -0.9em;
  right: -1em;
`;

export const CheckIcon = styled(FaCheck)<CheckIconProps>`
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.font.color.primary};
  visibility: ${({ selected }) => (selected ? 'visible' : 'visible')};
  display: ${({ selected }) => (selected ? 'hidden' : 'block')};
  top: -12px;
  left: -5px;
`;
