import routeMap from "routes/route-map";
import TeamRegistration from "pages/DataDictionary/TeamRegistration";
import BranchInlusion from "pages/DataDictionary/BranchInclusion";
import ChannelHierarchy from "pages/DataDictionary/ChannelHierarchy";
import FocalPointChange from "pages/DataDictionary/FocalPointChange";
import ImportGoals from "pages/DataDictionary/ImportGoals";
import ImportGoalsProducts from "pages/DataDictionary/ImportGoalsProducts";
import { RouteModule } from "./route-module";

const dataDictionaryRoutes: RouteModule[] = [
  {
    path: routeMap.dataDictionary.teamRegistration,
    component: TeamRegistration,
    accessPage: 'Cadastro do Time FMC',
    isPrivate: true,
  },
  {
    path: routeMap.dataDictionary.channelHierarchy,
    component: ChannelHierarchy,
    accessPage: 'Alteração de Hierarquia de Canais',
    isPrivate: true,
  },
  {
    path: routeMap.dataDictionary.branchInclusion,
    component: BranchInlusion,
    accessPage: 'Inclusão de Filial',
    isPrivate: true,
  },
  {
    path: routeMap.dataDictionary.focalPointChange,
    component: FocalPointChange,
    accessPage: 'Alteração de Focal Point',
    isPrivate: true,
  },
  {
    path: routeMap.dataDictionary.importGoals,
    component: ImportGoals,
    accessPage: 'Importação de metas',
    isPrivate: true,
  },
  {
    path: routeMap.dataDictionary.importGoalsProducts,
    component: ImportGoalsProducts,
    accessPage: 'Importação de metas por produtos',
    isPrivate: true,
  },
];

export default dataDictionaryRoutes