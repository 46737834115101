import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { StrategyActionType } from 'services/strategic-plans/actions/interfaces';

export default async () => {
  const { data } = await pluginApi.get<StrategyActionType[]>(
    '/strategy-action-types/product-development',
  );

  return data.map<Option>(({ id, name }) => ({
    value: id.toString(),
    title: name,
  }));
};
