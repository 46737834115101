/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import React, { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uploadFileToStorage from 'services/storage/sendFile';
import {
  getCampaign,
  getErrors,
} from 'state/modules/campaigns-manager/selectors';
import { useToast } from 'context/ToastContext';
import {
  sendCommunicationKit,
  setCheckRegulation,
  setEndDate,
  setFieldValue,
  setStartDate,
} from 'state/modules/campaigns-manager/actions';

import {
  Container,
  Input,
  TextArea,
  Box,
  DatePicker,
  Checkboxes,
  DownloadButton,
} from './styles';

interface Props {
  actived: boolean;
  campaignType: string;
  isCheckedRegulation: boolean;
}

const InfoAndRules: React.FC<Props> = ({
  actived,
  campaignType,
  isCheckedRegulation,
}) => {
  const dispatch = useDispatch();
  const campaign = useSelector(getCampaign);
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const { addToast } = useToast();
  const [validUploadArchive, setValidUploadArchive] = useState(false);
  const [loading, setLoading] = useState(false);

  const errors = useSelector(getErrors);
  const customTypeCampaign =
    campaignType && campaignType.toLowerCase() === 'personalizada';

  const onClickHandler = useCallback(() => {
    fileRef.current?.click();
  }, []);

  const handleAttachFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoading(true);
        if (e && e.target && e.target.files && e.target.files.length > 0) {
          const { url, extension, originalName } = await uploadFileToStorage(
            e.target.files[0],
            'kit_comunicacao',
          );

          const validArchive = ['zip'].includes(extension);

          if (validArchive) {
            dispatch(sendCommunicationKit(url));
            setSelectedFileName(originalName);
            setValidUploadArchive(false);
          } else {
            addToast({
              title: 'Arquivo com formato inválido!',
              type: 'error',
            });
          }
        }
      } catch (err) {
        addToast({
          title: err.response?.data?.message || 'Problemas ao adicionar anexo!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, dispatch],
  );

  return (
    <Container style={{ display: actived ? 'block' : 'none' }}>
      <h4>Período</h4>
      <span>Selecione a data de início e fim da sua campanha</span>
      <Box>
        <div>
          <span>Início</span>
          <DatePicker
            value={campaign.startDate}
            onChange={e => dispatch(setStartDate(e))}
            inputRole="secondary"
            error={errors.startDate}
          />
        </div>
        <div>
          <span>Fim</span>
          <DatePicker
            value={campaign.endDate}
            onChange={e => dispatch(setEndDate(e))}
            inputRole="secondary"
            error={errors.endDate}
          />
        </div>
      </Box>
      <h4>Título da Campanha</h4>
      <span>Digite abaixo um título para sua campanha</span>
      <Input
        type="text"
        inputRole="secondary"
        value={campaign.title}
        error={errors.title}
        onChange={e =>
          dispatch(setFieldValue({ fieldName: 'title', value: e.target.value }))
        }
      />
      <h4>Descrição</h4>
      <span>Este é o texto exibido aos participantes da campanha</span>
      <TextArea
        value={campaign.description}
        onChange={e =>
          dispatch(
            setFieldValue({
              fieldName: 'description',
              value: e.target.value,
            }),
          )
        }
        inputRole="secondary"
        error={errors.description}
      />
      {customTypeCampaign && (
        <>
          <h4>Precisa de aceite de regulamento?</h4>
          <span>
            Caso a campanha tenha que ter o aceite do regulamento do Grupo de
            Cliente
          </span>
          <Checkboxes>
            <div>
              <input
                name="check_regulation"
                type="checkbox"
                onChange={() => dispatch(setCheckRegulation(true))}
                checked={isCheckedRegulation}
              />
              <span>Sim</span>
            </div>
            <div>
              <input
                name="check_regulation"
                type="checkbox"
                onChange={() => dispatch(setCheckRegulation(false))}
                checked={!isCheckedRegulation}
              />
              <span>Não</span>
            </div>
          </Checkboxes>
        </>
      )}
      <h4>Objetivo</h4>
      <span>Descreva qual a finalidade que esta campanha deseja alcançar</span>
      <TextArea
        value={campaign.goal}
        onChange={e =>
          dispatch(
            setFieldValue({
              fieldName: 'goal',
              value: e.target.value,
            }),
          )
        }
        inputRole="secondary"
        error={errors.description}
      />
      <h4>Resumo</h4>
      <span>
        Faça um resumo de tudo que o seu cliente precisa saber sobre esta
        campanha
      </span>
      <TextArea
        value={campaign.summary}
        onChange={e =>
          dispatch(
            setFieldValue({
              fieldName: 'summary',
              value: e.target.value,
            }),
          )
        }
        inputRole="secondary"
        error={errors.description}
      />
      <h4>Regras de participação</h4>
      <span>Descreva com clareza o critério para premiação</span>
      <TextArea
        value={campaign.rules_participation}
        onChange={e =>
          dispatch(
            setFieldValue({
              fieldName: 'rules_participation',
              value: e.target.value,
            }),
          )
        }
        inputRole="secondary"
        error={errors.description}
      />
      <h4>Observações</h4>
      <TextArea
        value={campaign.observation}
        onChange={e =>
          dispatch(
            setFieldValue({
              fieldName: 'observation',
              value: e.target.value,
            }),
          )
        }
        inputRole="secondary"
        error={errors.description}
      />
      {customTypeCampaign && (
        <div className="custom-file-input">
          <DownloadButton
            disabled={validUploadArchive}
            buttonRole="primary"
            type="button"
            onClick={onClickHandler}
            loading={loading}
          >
            Carregar arquivo
            <input
              ref={fileRef}
              type="file"
              id="communication_kit"
              name="communication_kit"
              accept=".zip"
              onChange={handleAttachFile}
              style={{ display: 'none' }}
            />
          </DownloadButton>
          {selectedFileName && <p>{selectedFileName}</p>}
        </div>
      )}
    </Container>
  );
};

export default InfoAndRules;
