export default `
<!--[if IE]><div class="ie-container"><![endif]-->
<!--[if mso]><div class="mso-container"><![endif]-->
<table
  class="nl-container"
  style="border-collapse: collapse; table-layout: fixed; border-spacing: 0; mso-table-lspace: 0pt; mso-table-rspace: 0pt; vertical-align: top; min-width: 320px; margin: 0 auto; background-color: #fcfcfc; width: 100%;"
  cellpadding="0"
  cellspacing="0"
>
  <tbody>
    <tr style="vertical-align: top;">
      <td style="word-break: break-word; border-collapse: collapse !important; vertical-align: top;">
        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #fcfcfc;"><![endif]-->

        <div class="u-row-container" style="padding: 0px; background-color: transparent;">
          <div style="margin: 0 auto; min-width: 320px; max-width: 760px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; background-color: transparent;" class="u-row">
            <div style="border-collapse: collapse; display: table; width: 100%; background-color: transparent;">
              <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:760px;"><tr style="background-color: transparent;"><![endif]-->

              <!--[if (mso)|(IE)]><td align="center" width="760" style="width: 760px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
              <div class="u-col u-col-100" style="max-width: 320px; min-width: 760px; display: table-cell; vertical-align: top;">
                <div style="width: 100% !important;">
                  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-right: 0px solid transparent; border-bottom: 0px solid transparent;"><!--<![endif]-->
                    <table id="u_content_text_1" class="u_content_text" style="font-family: arial, helvetica, sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                      <tbody>
                        <tr>
                          <td style="overflow-wrap: break-word; word-break: break-word; padding: 10px 10px 6px; font-family: arial, helvetica, sans-serif;" align="left">
                            <div class="v-text-align" style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                              <p style="font-size: 14px; line-height: 140%;">
                                <span style="font-size: 16px; line-height: 22.4px;">
                                  <strong><span style="line-height: 22.4px; font-size: 16px;">Produtos Biológicos</span></strong>
                                </span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!--[if (!mso)&(!IE)]><!-->
                  </div>
                  <!--<![endif]-->
                </div>
              </div>
              <!--[if (mso)|(IE)]></td><![endif]-->
              <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
            </div>
          </div>
        </div>

        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
      </td>
    </tr>
  </tbody>
</table>
<!--[if (mso)|(IE)]></div><![endif]-->
`;
