import { pluginApi } from 'services/api';
import { ApproverProfile, RTC, KAM, DM, RTCPOG } from 'config/constants';

interface DefaultResponse {
  client_code: string;
  client_group: string;
  classificacao: string;
  establishment_type: string;
  billing_goal: number;
  billing_result: number;
  billing_percentage: number;
  pog_goal: number;
  pog_result: number;
  pog_percentage: number;
  premio_goal: number;
  premio_result: number;
  premio_percentage: number;
  aurora_goal: number;
  aurora_result: number;
  aurora_percentage: number;
  hero_goal: number;
  hero_result: number;
  hero_percentage: number;
  quartzo_goal: number;
  quartzo_result: number;
  quartzo_percentage: number;
  reator_goal: number;
  reator_percentage: number;
  reator_result: number;
  altacor_goal: number;
  altacor_result: number;
  altacor_percentage: number;
  boral_goal: number;
  boral_percentage: number;
  boral_result: number;
  talisman_goal: number;
  talisman_result: number;
  talisman_percentage: number;
  premio_star_goal: number;
  premio_star_result: number;
  premio_star_percentage: number;
  stone_goal: number;
  stone_result: number;
  stone_percentage: number;
  verimark_goal: number;
  verimark_result: number;
  verimark_percentage: number;
  biological_pog_goal: number;
  biological_pog_percentage: number;
  biological_pog_realized: number;
  biological_invoice_goal: number;
  biological_invoice_percentage: number;
  biological_invoice_realized: number;
  fungicidas_goal: number;
  fungicidas_percentage: number;
  fungicidas_result: number;
  herbicidas_goal: number;
  herbicidas_percentage: number;
  herbicidas_result: number;
  inseticidas_by_ryna_goal: number;
  inseticidas_by_ryna_percentage: number;
  inseticidas_by_ryna_result: number;
  inseticidas_goal: number;
  inseticidas_percentage: number;
  inseticidas_result: number;
  check_biologic: {
    biological: boolean;
    campaign: string;
    id_establishment: string;
    type: string;
  };
  products: [
    {
      id: number;
      value: number;
      owner_ref: string;
      category: string;
      period: string;
      generated_at: string;
      pcp: {
        color: string;
        position: number;
      };
      balance_unit: {
        id: number;
        name: string;
        description: string;
      };
      progress: {
        value: number;
        percentage: number;
      };
    },
  ];
}

interface CustomResponse extends Omit<DefaultResponse, 'client_group'> {
  rtc_name: string;
}

export interface Data extends Omit<DefaultResponse, 'client_group'> {
  name: string;
}

const orderByName = (data: Data[]): Data[] => {
  return data.sort((a, b) => (a.name > b.name ? 1 : -1));
};

const getChartsData = async (
  profile: ApproverProfile,
  campaingId?: number,
): Promise<Data[]> => {
  const campaignParam = campaingId ? `?campaign_id=${campaingId}` : '';

  if (
    profile === RTC ||
    profile === KAM ||
    profile === DM ||
    profile === RTCPOG
  ) {
    const {
      data: { data },
    } = await pluginApi.get<{ data: DefaultResponse[] }>(
      `dashboards/performance${campaignParam}`,
    );
    return orderByName(
      data.map(item => ({ ...item, name: item.client_group })),
    );
  }

  const {
    data: { data },
  } = await pluginApi.get<{ data: CustomResponse[] }>(
    `dashboards/team-performance${campaignParam}`,
  );
  return orderByName(data.map(item => ({ ...item, name: item.rtc_name })));
};

export { getChartsData };
