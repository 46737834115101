import { pluginApi } from 'services/api';
import { ExtractApi, Extract as IExtract } from './interfaces';
import transformer from './transformers/apiToDisplay';

export async function getExtractByEstablishment(
  establishmentId: string,
): Promise<IExtract> {
  const { data } = await pluginApi.get<ExtractApi>(
    `statement/establishment-extract/${establishmentId}`,
  );

  return transformer(data);
}
