import { useToast } from 'context/ToastContext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserRoulettes } from 'state/modules/home/selectors';
import {
  AwardResponseRouletteData,
  getRouletteAwards,
} from '../services/getRouletteAwards';
import { Award, RouletteStatus } from '../types';
import { getUserRoulette } from '../utils/get-user-roulette';

type Props = {
  children: React.ReactNode;
};

type IRouletteContext = {
  status: RouletteStatus;
  setStatus: (status: RouletteStatus) => void;
  spins: number;
  rouletteId: number | null;
  awards: Award[];
  rouletteData: AwardResponseRouletteData | null;
};

const RouletteContext = React.createContext<IRouletteContext>(
  {} as IRouletteContext,
);

export const RouletteProvider = ({ children }: Props) => {
  const [status, setStatus] = useState<RouletteStatus>(RouletteStatus.LOADING);
  const [awards, setAwards] = useState<Award[]>();
  const [rouletteId, setRouletteId] = useState<number | null>(null);
  const [
    rouletteData,
    setRouletteData,
  ] = useState<AwardResponseRouletteData | null>(null);

  const rouletteStore = useSelector(getUserRoulettes);
  const awardsLoaded = useRef(false);
  const { addToast } = useToast();

  const leftSpins = useMemo(() => {
    const roulette = getUserRoulette(rouletteStore?.roulettes || []);

    if (!roulette) return 0;

    return roulette.user_spins;
  }, [rouletteStore]);

  useEffect(() => {
    /** Fetch roulette awards */
    async function fetchAwards(id: number, spins: number) {
      setStatus(RouletteStatus.LOADING);

      try {
        const { awards: responseAwards, data } = await getRouletteAwards({
          roulette_id: id,
        });

        setAwards(responseAwards);
        setRouletteData(data);
        setStatus(spins > 0 ? RouletteStatus.READY : RouletteStatus.NO_SPINS);
      } catch (error) {
        addToast({
          title:
            'Ocorreu um erro inesperado ao tentar carregar os prêmios, tente novamente',
          type: 'error',
        });
      } finally {
        setRouletteId(id);
      }
    }

    if (
      rouletteStore?.isOpen &&
      rouletteStore.roulettes &&
      !awardsLoaded.current
    ) {
      /** Fetch awards only if user open roulette  */
      const { roulettes } = rouletteStore;
      const roulette = getUserRoulette(roulettes);

      if (roulette) {
        fetchAwards(roulette.id, roulette.user_spins);
        awardsLoaded.current = true;
      } else setStatus(RouletteStatus.NO_SPINS);
    }

    if (!rouletteStore?.isOpen) {
      // Set to false to fetch awards on user re-open roulette modal
      awardsLoaded.current = false;
    }
  }, [rouletteStore, addToast]);

  return (
    <RouletteContext.Provider
      value={{
        status,
        rouletteId,
        awards: awards || [],
        spins: leftSpins,
        setStatus,
        rouletteData,
      }}
    >
      {children}
    </RouletteContext.Provider>
  );
};

export const useRoulette = () => useContext(RouletteContext);
