import styled from 'styled-components';

export const ListTable = styled.table`
  width: 100%;
  font-size: 14px;
  text-align: left;
  border-collapse: collapse;
  margin-top: 16px;
  background: rgba(239, 239, 239, 255);

  th {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 14px;
    text-align: left;
    padding: 7px 10px;
  }
  td {
    padding: 7px 10px;
    border-top: 3px solid #efefef;
  }
`;

export const ListTableContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
`;

export const IconEye = styled.a`
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='eye' class='svg-inline--fa fa-eye fa-w-18' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='currentColor' d='M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z'%3E%3C/path%3E%3C/svg%3E");
`;

export const IconList = styled.a`
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='list-alt' class='svg-inline--fa fa-list-alt fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z'%3E%3C/path%3E%3C/svg%3E");
`;

export const ContentMobile = styled.div`
  .card-items {
    background: #fff;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .card-header {
    display: flex;
  }

  h4,
  a {
    margin-bottom: 0.5rem;
  }

  .details {
    margin-left: 1.5rem;
  }

  span {
    font-weight: bold;
  }
`;
