import React, {useState, useCallback} from 'react';
import { useForm, FormContext } from 'react-hook-form';

import PasswordHelp from 'components/shared/PasswordHelp';
import { updatePassword } from 'services/auth/password/updatePassword';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import { passwordValidationSchema } from '../PersonalDataForm/validation';
import { Title, PasswordInput, ButtonContainer, Button } from './styles';

interface Props {
  inputRole: 'primary' | 'secondary';
  actived: boolean;
}

type FormData = {
  password: string;
  password_confirmation: string;
};

const SecurityDataForm: React.FC<Props> = ({ inputRole, actived }) => {
  const { participant, refreshParticipant } = useAuth();
  const { addToast } = useToast();
  const [valueInput, setvalueInput] = useState<string>('');
  const [variavel, setVariavel] = useState<boolean>(false);
  const formMethods = useForm<FormData>({
    validationSchema: passwordValidationSchema,
  });
  const {
    handleSubmit: submit,
    formState: { isSubmitting },
  } = formMethods;

  const handleSubmit = submit(async data => {
    const pwdResponse = await updatePassword({
      participantId: participant.id,
      password: data.password,
      confirmPassword: data.password_confirmation,
    });

    if (!pwdResponse.success) {
      addToast({
        title: pwdResponse.error || '',
        type: 'error',
      });
      return;
    }

    addToast({
      title: 'Senha atualizada com sucesso!',
      type: 'success',
    });

    refreshParticipant();
  });

  const getValueInput = useCallback(
        (value) => {
          setvalueInput(value.value);
        }, []
      );

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: actived ? 'block' : 'none' }}>
          <Title>Definir senha</Title>
          <PasswordInput
            name="password"
            label="Senha"
            inputRole={inputRole}
            onChange={e => getValueInput(e.target)}
            onFocus={() => setVariavel(true)}
            onBlur={() => setVariavel(false)}
          />
           {
              variavel &&
              <PasswordHelp checkedText={valueInput} />
           }
          <PasswordInput
            name="password_confirmation"
            label="Confirmar Senha"
            inputRole={inputRole}
          />
          <ButtonContainer>
            <Button
              type="submit"
              buttonRole="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </ButtonContainer>
        </div>
      </form>
    </FormContext>
  );
};

export default SecurityDataForm;
