import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { Establishment as IEstablishment } from 'services/auth/interfaces/Participant';
import { IProfile } from 'config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { showProfileChange } from 'state/modules/header/actions';
import { getIsChangeProfileAvailable } from 'state/modules/header/selectors';

import {
  DropdownList,
  ParticipantMenuList,
  ProfileInfo,
  Establishment,
} from './Dropdown.styles';

interface DropdownProps {
  establishment: IEstablishment | null;
  profile: IProfile | null;
  signOut(): void;
  simulating: boolean;
}
const Dropdown: React.FC<DropdownProps> = ({
  establishment,
  profile,
  signOut,
  simulating,
}) => {
  const [isProducer] = useState(() => profile === 'PRODUTOR');
  const dispatch = useDispatch();
  const isChangeProfileAvailable = useSelector(getIsChangeProfileAvailable);

  const changeProfileHandler = useCallback(
    () => dispatch(showProfileChange()),
    [dispatch],
  );

  return (
    <DropdownList>
      {!!establishment && !isProducer && (
        <ProfileInfo>
          <Establishment>{establishment.client_group}</Establishment>
          {establishment.rtc_name && <span>RTC: {establishment.rtc_name}</span>}
        </ProfileInfo>
      )}
      <ParticipantMenuList notshowseparator={!establishment || isProducer}>
        <li>
          {simulating ? (
            <span>Meu perfil</span>
          ) : (
            <Link to={routeMap.profile}>Meu perfil</Link>
          )}
        </li>
        {isChangeProfileAvailable && (
          <li>
            {simulating ? (
              <span>Trocar perfil</span>
            ) : (
              <a href="#perfil" onClick={changeProfileHandler}>
                Trocar perfil
              </a>
            )}
          </li>
        )}
        <li>
          <a href="#sair" onClick={signOut}>
            Sair
          </a>
        </li>
      </ParticipantMenuList>
    </DropdownList>
  );
};

export default Dropdown;
