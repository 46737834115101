import { pluginApi } from 'services/api';
import { Audience, EstablishmentApi } from './interfaces/Campaign';

interface ApiResponse {
  data: EstablishmentApi[];
}

export default async (): Promise<Audience[]> => {
  const {
    data: { data },
  } = await pluginApi.get<ApiResponse>(
    'fmc-campaigns/establishments?limit=200',
  );
  return data.map(item => ({
    customer: {
      id: item.id,
      name: item.client_group,
      cnpj: item.cnpj,
      type: item.type.name,
    },
    balance: item.balance || 0,
  }));
};
