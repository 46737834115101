import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import getFaqList from 'services/faq/getFaqList';
import { FaqResponse } from 'services/faq/interfaces';
import FaqList from 'components/Contact/Faq';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { 
  Container, 
  Content, 
  SearchContainer, 
  SearchInput, 
  SearchButton,
  ClearButton, 
  PaginationContainer, 
  PaginationButton,
  Loading } from './styles';

const Faq: React.FC = () => {
  const [faqResponse, setFaqResponse] = useState<FaqResponse>({
    data: [],
    pagination: {
      page_total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0,
    },
  });


const [filter, setFilter] = useState<string>('');
const [page, setPage] = useState<number>(1);
const [searchTerm, setSearchTerm] = useState<string>('');
const [loading, setLoading] = useState<boolean>(false); 
const limit = 10;


useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
      try {
        const data = await getFaqList(filter, page, limit);
        setFaqResponse(data);
      } catch (error) {
        console.error('Erro ao buscar FAQ:', error);
      }
    setLoading(false);
  };

    fetchData();
}, [filter, page]);

const handleSearchClick = () => {
  setPage(1);
  setFilter(searchTerm);
};

const handleClearSearch = () => {
  setSearchTerm('');
  setFilter(''); // Dispara a requisição com busca vazia
  setPage(1);
};

const handleNextPage = () => {
  if (page < faqResponse.pagination.last_page) {
    setPage(page + 1);
  }
};

const handlePrevPage = () => {
  if (page > 1) {
    setPage(page - 1);
  }
};

return (
  <Container>
    <Content>
      <h3>FAQ</h3>
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <ClearButton onClick={handleClearSearch}>
              <FaTimes size={14} />
            </ClearButton>
          )}
          <SearchButton onClick={handleSearchClick}>
            <FaSearch size={16} />
          </SearchButton>
        </SearchContainer>

          {loading && (
            <Loading>
              <ReactLoading type="bars" height={24} width={24} color="#808285" />
            </Loading>
          )}

          {loading && <p style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>Carregando...</p>}
          
          {!loading && faqResponse.data.length === 0 && (
            <p style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>
              Nenhum resultado encontrado para "{filter}"
            </p>
          )}

        {!loading && faqResponse.data.length > 0 && <FaqList data={faqResponse.data} />}

        {!loading && faqResponse.data.length > 0 && (
          <PaginationContainer>
            <PaginationButton onClick={handlePrevPage} disabled={page === 1}>
              Anterior
            </PaginationButton>
            <span>
              Página {faqResponse.pagination.current_page} de {faqResponse.pagination.last_page}
            </span>
            <PaginationButton onClick={handleNextPage} disabled={page === faqResponse.pagination.last_page}>
              Próxima
            </PaginationButton>
          </PaginationContainer>
        )}
      </Content>
    </Container>
  );
};

export default Faq;
