import { StoreState } from 'state/root-reducer';
import { FmcCoinsSimulatorState, FmcProducts } from './interfaces';

export const getFmcProductsState = (
  state: StoreState,
): FmcCoinsSimulatorState => {
  return state.fmcCoinsSimulator;
};

export const getFmcProducts = (state: StoreState): FmcProducts[] => {
  return state.fmcCoinsSimulator.products;
};

export const getValueToConversion = (state: StoreState): number =>
  state.fmcCoinsSimulator.valueToConversion;
