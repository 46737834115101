import { pluginApi } from 'services/api';

interface ApiResponse {
  download_url: string;
}

export default async (fmcCampaignId: number): Promise<string> => {
  const { data } = await pluginApi.get<ApiResponse>(
    `reports/fmc-campaign-participants?fmc_campaign_id=${fmcCampaignId}`,
  );

  return data.download_url;
};
