import { combineReducers } from 'redux';

import pointManagement, {
  PointManagementState,
} from './modules/point-management/reducer';
import header, { HeaderState } from './modules/header/reducer';
import home, { HomeState } from './modules/home/reducer';
import campaignsManager, {
  CampaignsManagerState,
} from './modules/campaigns-manager/reducer';
import goals, { GoalsState } from './modules/goals/reducer';
import pointsSimulator from './modules/points-simulator/reducer';
import { PointsSimulatorState } from './modules/points-simulator/interfaces';
import surveys, { SurveyState } from './modules/surveys/reducer';
import weather, { WeatherState } from './modules/weather/reducer';
import { FmcCoinsSimulatorState } from './modules/fmc-coins-simulator/interfaces';
import fmcCoinsSimulator from './modules/fmc-coins-simulator/reducer';
import contact, { ContactState } from './modules/contact/reducer';

export type StoreState = {
  pointManagement: PointManagementState;
  campaignsManager: CampaignsManagerState;
  header: HeaderState;
  home: HomeState;
  goals: GoalsState;
  pointsSimulator: PointsSimulatorState;
  surveys: SurveyState;
  weather: WeatherState;
  fmcCoinsSimulator: FmcCoinsSimulatorState;
  contact: ContactState;
};

export default combineReducers<StoreState>({
  pointManagement,
  campaignsManager,
  header,
  home,
  goals,
  pointsSimulator,
  surveys,
  weather,
  fmcCoinsSimulator,
  contact,
});
