import React from 'react';

import { PointsProvider } from 'pages/PointManagement/use-points';
import { PointDistributionPage } from './page';

export const DistributionPage = () => (
  <PointsProvider>
    <PointDistributionPage />
  </PointsProvider>
);
