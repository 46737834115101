import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  border-bottom: 1px solid #821c0e;
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1100px;
  flex-direction: column;
  padding: 40px 60px;

  @media screen and (max-width: 500px) {
    padding: 40px 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  span {
    color: #707070;
  }
`;

export const HeaderTitle = styled.h1`
  color: #821c0e;
  font-size: 2em;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;
