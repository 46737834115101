import styled from 'styled-components';
import chevron from 'assets/images/extract/chevron.svg';

export const Container = styled.div`
  background: #efefef;
  padding: 10px;
  margin-bottom: 15px;

  button {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
    height: 42px;
    max-width: 156px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  color: ${({ theme }) => theme.font.color};
  strong {
    display: block;
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }
  .divider {
    flex: 1;
    border-bottom: 1px solid #444;
  }
  .text-right {
    text-align: right;
  }
  .chevron {
    background: url(${chevron}) no-repeat center center;
    width: 40px;
    height: 40px;
    &.open {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: 495px) {
    strong {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  margin-right: 40px;
  margin-top: 20px;

  .content-row {
    margin-bottom: 10px;
  }

  /* Estilo para garantir que tudo fique em uma linha só */
  .row-description {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    flex-wrap: nowrap; /* Não permite quebra de linha */
    white-space: nowrap; /* Evita quebra de texto */
    overflow: hidden; /* Esconde o overflow de textos longos */

    div.value-to-distribute {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto; /* Ajusta a largura do conteúdo para caber ao lado direito */

      p {
        min-width: 90px;
        text-align: right;
        margin-left: auto;
      }

      a {
        color: #7a7a7a;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
        margin-left: auto; /* Move o link distribuir para a direita */
        text-align: right;
        white-space: nowrap; /* Evita quebra de linha no link */
      }

      @media screen and (max-width: 700px) {
        p {
          min-width: unset;
        }

        a {
          margin-right: 10px;
        }
      }
    }
  }

  /* Estilos para os valores distribuídos */
  .distributed {
    color: red; /* Cor vermelha para valores distribuídos */
  }

  /* Estilo para valores negativos */
  .negative {
    color: red; /* Exibe valores negativos em vermelho */
  }

  .row-header {
    background: #fff;
    color: ${({ theme }) => theme.font.color.primary};
    display: flex;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    justify-content: space-between;
    padding: 10px;
  }

  .row-details {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    color: ${({ theme }) => theme.font.color.quartenary};
  }

  .special {
    opacity: 50%;
  }
`;
