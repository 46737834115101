import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container, AnswerLabel } from './styles';

interface LinearScaleProps {
  question: string;
  answers: AnswerData[];
  id: number;
  scale_type?: number;
  canEdit: boolean;
}

const LinearScale: React.FC<LinearScaleProps> = ({
  question,
  answers,
  id,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const [currentAnswer, setCurrentAnswer] = useState(0);

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'unique' });
    }
  }, [answers, canEdit, dispatch, id]);

  useEffect(() => {
    answers.forEach(answer => {
      if (answer.survey_participant_answers.length > 0) {
        setCurrentAnswer(Number(answer.survey_participant_answers[0].answer));
      }
    });
  }, [answers]);

  return (
    <Container>
      <p>{question}</p>
      <div>
        {answers.map((answer, index) => (
          <Box key={answer.id} component="div" mb={3} borderColor="transparent">
            <AnswerLabel>{answer.answer}</AnswerLabel>
            <Rating
              name={answer.id.toString()}
              max={1}
              size="large"
              onClick={() => {
                setCurrentAnswer(index + 1);
                dispatch(
                  setValueAnswer({
                    answerValue: `${index + 1}`,
                    questionId: Number(id),
                    answerId: Number(answer.id),
                    multipleAnswers: 'unique',
                  }),
                );
              }}
              value={index < currentAnswer ? 1 : null}
              disabled={!canEdit}
            />
          </Box>
        ))}
      </div>
    </Container>
  );
};

export default LinearScale;
