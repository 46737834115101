import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px 0;

  strong {
    font-size: 18px;
    color: #000;
  }

  .filters-btn--wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      height: auto;
      max-width: fit-content;
      font-size: 14px;
      padding: 8px 35px;
      margin: 0;
    }
  }

  .creation--select {
    margin-top: 25px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  button {
    max-width: 170px;
  }

  .info-prazo {
    display: flex;
    align-items: center;
    margin: 15px 0;
    opacity: 0.6;
    font-size: 0.875rem;
    gap: 5px;
    color: #000;
  }

  .table--plans {
    margin-top: 25px;

    .details-link {
      color: ${({ theme }) => theme.font.color.primary};
    }

    tbody tr {
      td {
        padding: 10px 10px 10px 20px;
        height: 60px;
      }

      td:first-child {
        border-left: 2px solid transparent;
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;

        b {
          white-space: nowrap;
        }

        @media screen and (max-width: 690px) {
          max-width: unset;
          overflow: unset;
          text-overflow: unset;

          b {
            white-space: unset;
          }
        }
      }

      &.status--approved > td {
        border-color: #07d100;
      }

      &.status--adjustments > td {
        border-color: #666666;
      }

      &.status--onapproval > td {
        border-color: #fcb603;
      }

      &.status--planning > td {
        border-color: #0c7ded;
      }

      &.status--outoftime > td {
        border-color: #d60622;
      }

      button {
        height: auto;
        width: unset;
        min-width: 120px;
        margin: 0;
        font-size: 0.875em !important;
        padding: 8px 10px;
      }
    }
  }
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 16px;
  color: #686565;
`;
