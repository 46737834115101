/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { ReactSVG } from 'react-svg';

// import goalReachedIcon from 'assets/images/active-bell.svg';
import goalNotReachedIcon from 'assets/images/inactive-bell.svg';
import { useAuth } from 'context/AuthContext';
import { DM } from 'config/constants';

import { Container, Subtitle, Grid, Row } from './styles';

export interface Item {
  description: string;
  goal: number;
  reached: number;
}

interface Props {
  items: Item[];
  isEngagement?: boolean;
}

const BellsCard: React.FC<Props> = ({ items, isEngagement }) => {
  const {
    participant: { profile_value },
  } = useAuth();

  const isDM = profile_value === DM;

  return (
    <Container>
      <Subtitle>{isDM || isEngagement ? 'Total' : 'Realizado/Total'}</Subtitle>
      <Grid>
        {isDM
          ? items
              .filter(item => {
                return item.description !== 'Top 10 clientes dos canais';
              })
              .map(item => {
                return (
                  <Row reached={false} key={item.description}>
                    <ReactSVG src={goalNotReachedIcon} />
                    <span>{item.description}</span>
                    <strong>
                      {0}{' '}
                      {!isDM && !isEngagement && (
                        <>
                          <b>/</b>
                          <span>{item.goal}</span>
                        </>
                      )}
                    </strong>
                  </Row>
                );
              })
          : items.map(item => {
              return (
                <Row reached={false} key={item.description}>
                  <ReactSVG src={goalNotReachedIcon} />
                  <span>{item.description}</span>
                  <strong>
                    {0}{' '}
                    {!isDM && !isEngagement && (
                      <>
                        <b>/</b>
                        <span>{item.goal}</span>
                      </>
                    )}
                  </strong>
                </Row>
              );
            })}
      </Grid>
    </Container>
  );
};

export default BellsCard;
