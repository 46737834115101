import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 10px 0 50px 0;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  flex-direction: column;
  text-align: justify;
`;

export const Content = styled.div`
  width: 100%;
  text-align: justify;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 16px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 16px;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 360px;
  height: 40px;
  position: relative;
  margin-top: 30px;
  text-transform: uppercase;
  margin: 30px auto;
`;
