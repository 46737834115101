import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import { useHistory } from 'react-router-dom';
import { Establishment } from 'services/auth/signIn';
import { CatalogStatusError, TwoLoginException } from 'services/auth/errors';
import ProfileAuth from '../ProfileAuth';
import {
  Modal,
  Close,
  Container,
  Title,
  Input,
  DoneButtonContainer,
  SubTitle,
  TokenSendingNumber,
} from './styles';
import {
  MessageCount,
  MessageResend,
  ResendToken,
} from '../ModalChoiceToken/style';
import { Button } from '../../../../../components/shared';

interface Props {
  isOpenOne: boolean;
  onRequestClose: () => void;
  onSubmit: (token: string) => void;
  selectedMethod: string;
  onChooseSms: () => void;
  onChooseEmail: () => void;
  obfuscatedCellPhone: string;
}

interface SignInFormData {
  cpf: string;
  password: string;
  token?: string;
}

const ModalToken: React.FC<Props> = ({
  isOpenOne,
  onRequestClose,
  onSubmit,
  selectedMethod,
  onChooseEmail,
  onChooseSms,
  obfuscatedCellPhone,
}) => {
  const { signIn } = useAuth();
  const [userToken, setUserToken] = useState('');
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [profiles, setProfiles] = useState<Establishment[]>([]);
  const [credentials, setCredentials] = useState<SignInFormData>(
    {} as SignInFormData,
  );
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(60); // Tempo restante em segundos
  const [isCounting, setIsCounting] = useState(true); // Controla se o contador está ativo
  const [loading, setLoading] = useState(false);

  const handleTokenChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUserToken(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const cpf = localStorage.getItem('identifier');
      const password = localStorage.getItem('password');
      const nextPage = localStorage.getItem('saveUrl') || '/';

      if (!userToken) {
        throw new Error('Token não informado.');
      }

      if (!cpf || !password) {
        throw new Error('CPF ou senha não encontrados.');
      }

      await signIn({
        cpf,
        password,
        tokenMfa: userToken,
      });

      localStorage.setItem('authToken', userToken);
      setCredentials({ cpf, password });
      onSubmit(userToken);
      setUserToken('');
      onRequestClose();
      history.push(nextPage);
    } catch (e) {
      if (e instanceof CatalogStatusError) {
        window.open(e.message, '_self');
        return;
      }

      if (
        e instanceof TwoLoginException ||
        String(e).includes('TwologinError')
      ) {
        const profilesValue = e.getProfiles();
        setProfiles(profilesValue);
        setIsOpen(true);
        return;
      }

      const errorMessage =
        e.response?.data?.message || 'Erro ao enviar o token';

      if (errorMessage.match(/Revenda/gi)) {
        history.push('/indiretos', 'resale');
        return;
      }

      if (errorMessage.match(/FMC/gi)) {
        history.push('/indiretos', 'produtor');
        return;
      }

      if (errorMessage.search(/Cooperativa/gi) >= 0) {
        history.push('/indiretos', 'cooperative');
        return;
      }

      addToast({
        title: errorMessage,
        type: 'error',
      });
    } finally {
      setUserToken('');
    }
  }, [userToken, addToast, onRequestClose, onSubmit, history, signIn]);

  useEffect(() => {
    let timer: number;
    if (isCounting && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearTimeout(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    if (isOpenOne) {
      setTimeLeft(60);
      setIsCounting(true); // Reinicia o contador
    }
  }, [isOpenOne]);

  const handleChooseEmail = async () => {
    try {
      setLoading(true);
      await onChooseEmail();
      setTimeLeft(60);
      setIsCounting(true);
    } catch (e) {
      addToast({
        title: e.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSmsClick = async () => {
    try {
      setLoading(true);
      await onChooseSms();
      setTimeLeft(60);
      setIsCounting(true);
    } catch (e) {
      addToast({
        title: e.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpenOne} onRequestClose={onRequestClose} zIndex={10}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Container>
        <Title>TOKEN ENVIADO!</Title>
        {selectedMethod === 'sms' && (
          <>
            <SubTitle>Código de segurança enviado para o número:</SubTitle>
            <TokenSendingNumber>{obfuscatedCellPhone}</TokenSendingNumber>
          </>
        )}
        <Input
          type="text"
          value={userToken}
          onChange={handleTokenChange}
          placeholder="Digite o token aqui"
        />
        <DoneButtonContainer>
          <Button
            buttonRole="primary"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          >
            ENVIAR TOKEN
          </Button>
        </DoneButtonContainer>
      </Container>
      {
        selectedMethod === 'sms' ? (
          <>
            <MessageCount>
              Caso não receba o SMS em {timeLeft}s, solicite novamente por e-mail.
            </MessageCount>
          </>
        ) : (
          <>
            <MessageCount>
              Caso não receba em {timeLeft}s, solicite novamente.
            </MessageCount>
          </>
        )
      }
      <MessageResend>
        <ResendToken>
          <button
            onClick={handleChooseEmail}
            type="button"
            disabled={isCounting}
            style={{ cursor: isCounting ? 'not-allowed' : 'pointer' }}
          >
            Receber por e-mail
          </button>
        </ResendToken>
        <span>ou</span>
        <ResendToken>
          <button
            onClick={handleSmsClick}
            type="button"
            disabled={isCounting}
            style={{ cursor: isCounting ? 'not-allowed' : 'pointer' }}
          >
            Reenviar SMS
          </button>
        </ResendToken>
      </MessageResend>
      <ProfileAuth
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        profiles={profiles}
        credentials={credentials}
      />
    </Modal>
  );
};

export default ModalToken;
