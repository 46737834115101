import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { TypeHiring } from 'services/strategic-plans/actions/interfaces';

export default async (): Promise<Option[]> => {
  const { data } = await pluginApi.get<TypeHiring[]>('/strategy-action-hiring');

  return data.map<Option>(({ id, name }) => ({
    value: id.toString(),
    title: name,
  }));
};
