import { pluginApi } from 'services/api';

interface Request {
  establishmentId: number;
  roleId: number;
}

interface ChangeLoginResponse {
  token: string;
}

export default async ({
  establishmentId,
  roleId,
}: Request): Promise<ChangeLoginResponse> => {
  const { data } = await pluginApi.post<ChangeLoginResponse>(
    '/participants/login/change',
    {
      establishment_id: establishmentId,
      role_id: roleId,
    },
  );

  return data;
};
