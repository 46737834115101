import { pluginApi } from 'services/api';

export interface Request {
  code_schooling: string;
  place_of_birth: string;
  nationality: string;
  marital_status_code: string;
  rg: string;
  rg_emitter: string;
  rg_emitter_uf: string;
  pis_nis: string;
}

export default async (data: Request): Promise<void> => {
  await pluginApi.post('/participants/participant/activate', data);
};
