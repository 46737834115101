import styled from 'styled-components';
import BaseTextArea from 'components/shared/TextArea';
import DefaultButton from 'components/shared/Button';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-height: 120px;
  margin-top: 10px;
`;

export const Form = styled.form`
  //width: 70%;
`;

export const TextArea = styled(BaseTextArea)`
  margin-bottom: 10px;
  width: 100%;
  ._inputContainer {
    width: 100%;
    height: 80px;
  }

  span {
    margin-bottom: 10px;
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const TextAreaWrapper = styled.div`
  width: 70%;
`;

export const ActionWrapper = styled.div`
  width: 30%;
  padding: 1rem;
`;

export const Button = styled(DefaultButton)`
  max-width: 200px;
  min-width: 75px;
  height: 40px;
  margin-top: 0;
  background-color: #e63027;
  text-transform: uppercase;
  border-radius: 5px;
`;
