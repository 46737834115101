import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import { Button as DefaultButton } from 'components/shared';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 623px;
    width: 100%;
    height: 100%;
    max-height: 210px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px 32px 10px 32px;

  h5 {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 1.5em;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  width: 100%;
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  padding: 5px;
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: black;
    }
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 200px;
  width: 100%;

  @media (max-width: 500px) {
    margin: 10px;
  }
`;
