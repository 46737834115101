import { pluginApi } from 'services/api';

interface Role {
  id: number;
  name: string;
}

interface ApiResponse {
  roles: Role[];
}

interface Option {
  value: string;
  title: string;
}

const getProtectedRoles = async (
  excludedRoles: string[] = [],
): Promise<Role[]> => {
  try {
    const {
      data: { roles },
    } = await pluginApi.get<ApiResponse>(`participants/roles`);

    if (excludedRoles.length > 0 && roles) {
      return roles.filter(role => !excludedRoles.includes(role.name));
    }

    return roles || [];
  } catch (e) {
    return [];
  }
};

const getProtecedRolesForSelect = async (
  excludedRoles: string[] = [],
): Promise<Option[]> => {
  const result = await getProtectedRoles(excludedRoles);
  // const roles = result.filter(role => !excludedRoles.includes(role.name));

  return result.map(item => ({
    value: item.id.toString(),
    title: item.name,
  }));
};

export { getProtecedRolesForSelect, getProtectedRoles };
