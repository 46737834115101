import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { HectaresSelect } from './styles';

interface Props {
  name: string;
  title: string;
  extraInfo?: string;
  disabled?: boolean;
}

const CustomInput: React.FC<Props> = ({
  name,
  title,
  extraInfo,
  disabled = false,
}) => {
  const { setValue, watch, register } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(name, e.target.value);
  };

  return (
    <>
      <HectaresSelect 
        id="opcao" 
        name={name}
        onChange={handleChange}
        value={watch('harvest.hectares') || ''}
      >
        <option value="" disabled selected>Selecione</option>
        <option value="1-50">1 - 50</option>
        <option value="51-100">51 - 100</option>
        <option value="101-250">101 - 250</option>
        <option value="251-500">251 - 500</option>
        <option value="501-1000">501 - 1000</option>
        <option value="1001-2000">1001 - 2000</option>
        <option value="2001-5000">2001 - 5000</option>
        <option value="5001-10000">5001 - 10000</option>
        <option value="10001-20000">10001 - 20000</option>
        <option value="acima de 20000">Acima de 20000</option>
      </HectaresSelect>
      <p>Exemplo de preenchimento</p>
      <p>10.000m² = 1 Hectare</p>
      <p>55.450m² = 5,645 Hectares</p>     
    </>
  );
};

export default CustomInput;
