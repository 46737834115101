import { pluginApi } from 'services/api';
import { fakeFormatDollars } from 'util/points';
import { Performance } from './interfaces/channel';

export interface ApiResponse {
  revenues: {
    goal: number;
    result: number;
    percentage: number;
  };
  pog: {
    goal: number;
    result: number;
    percentage: number;
  };
  devolution_below_5_percent: boolean;
  punctuality: string;
  focus_product: {
    pcp: {
      position: number;
      color: string;
    };
    name: string;
    goal: number;
    result: number;
    percentage: number;
  }[];
  biologicalInvoice: {
    goal: number;
    percentage: number;
    realized: number;
  };
  biologicalPog: {
    goal: number;
    percentage: number;
    realized: number;
  };
  margem_adicional?: {
    goal: number;
    result: number;
    percentage: number;
  };
  last_update: string;
  last_update_invoicing: string;
  last_update_pog: string;
}

export interface Filters {
  directorName?: string;
  regionalName?: string;
}

type FilterArgs = {
  establishmentId?: number;
  campaignId?: number;
  investmentGroups?: string;
};

const transformer = (data: ApiResponse): Performance => {
  return {
    revenues: {
      ...data.revenues,
      goal: (data.revenues.goal || 0) * 100,
      result: (data.revenues.result || 0) * 100,
      percentage: (data.revenues.percentage || 0) * 100,
      formattedGoal: fakeFormatDollars(data.revenues.goal, 0, 0),
      formattedResult: fakeFormatDollars(data.revenues.result, 0, 0),
      formattedPercentage: fakeFormatDollars(
        data.revenues.percentage || 0,
        0,
        0,
      ),
    },
    pog: {
      ...data.pog,
      goal: (data.pog.goal || 0) * 100,
      result: (data.pog.result || 0) * 100,
      percentage: (data.pog.percentage || 0) * 100,
      formattedGoal: fakeFormatDollars(data.pog.goal, 0, 0),
      formattedResult: fakeFormatDollars(data.pog.result, 0, 0),
      formattedPercentage: fakeFormatDollars(data.pog.percentage || 0, 0, 0),
    },
    devolutionBelow5Percent: data.devolution_below_5_percent,
    punctuality: data.punctuality,
    focusProduct: data.focus_product
      .map(item => ({
        ...item,
        goal: (item.goal || 0) * 100,
        result: (item.result || 0) * 100,
        percentage: (item.percentage || 0) * 100,
        formattedGoal: fakeFormatDollars(item.goal, 0, 0),
        formattedResult: fakeFormatDollars(item.result, 0, 0),
        formattedPercentage: fakeFormatDollars(item.percentage || 0, 0, 0),
        color: item.pcp.color || '#47C246',
        order: item.pcp.position,
      }))
      .sort((i, j) => (i.order > j.order ? 1 : -1)),
    biologicalInvoice: {
      goal: (data.biologicalInvoice.goal || 0) * 100,
      realized: (data.biologicalInvoice.realized || 0) * 100,
      percentage: (data.biologicalInvoice.percentage || 0) * 100,
      formattedGoal: fakeFormatDollars(data.biologicalInvoice.goal, 0, 0),
      formattedRealized: fakeFormatDollars(
        data.biologicalInvoice.realized,
        0,
        0,
      ),
      formattedPercentage: fakeFormatDollars(
        (data.biologicalInvoice.percentage || 0) * 100,
        0,
        0,
      ),
    },
    biologicalPog: {
      goal: (data.biologicalPog.goal || 0) * 100,
      realized: (data.biologicalPog.realized || 0) * 100,
      percentage: (data.biologicalPog.percentage || 0) * 100,
      formattedGoal: fakeFormatDollars(data.biologicalPog.goal, 0, 0),
      formattedRealized: fakeFormatDollars(data.biologicalPog.realized, 0, 0),
      formattedPercentage: fakeFormatDollars(
        (data.biologicalPog.percentage || 0) * 100,
        0,
        0,
      ),
    },
    additionalMargin: data.margem_adicional
      ? {
          goal: data.margem_adicional.goal || 0,
          result: data.margem_adicional.result || 0,
          percentage: data.margem_adicional.percentage || 0,
          formattedGoal: fakeFormatDollars(
            data.margem_adicional.goal || 0,
            0,
            0,
          ),
          formattedResult: fakeFormatDollars(
            data.margem_adicional.result || 0,
            0,
            0,
          ),
        }
      : null,
    last_update: data.last_update,
    last_update_invoicing: data.last_update_invoicing,
    last_update_pog: data.last_update_pog,
  };
};

export default async ({
  establishmentId,
  campaignId,
  investmentGroups,
}: FilterArgs): Promise<Performance> => {
  const { data } = await pluginApi.get<ApiResponse>(
    `cockpit/performance-statistics`,
    {
      params: {
        establishment_id: establishmentId,
        campaign_id: campaignId,
        investment_group: investmentGroups,
      },
    },
  );

  return transformer(data);
};
