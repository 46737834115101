/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToast } from 'context/ToastContext';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserRouletteSpins } from 'state/modules/home/actions';

import { makeSpin } from '../services/makeSpin';
import { Award, RouletteStatus } from '../types';
import { useRoulette } from './use-roulette';

type SpinFnProps = {
  onSpinSuccess?: () => void | any;
  onSpinError?: () => void | any;
};

export function useRouletteDraw() {
  const [loading, setLoading] = useState(false);
  const [spinAwardResult, setSpinAwardResult] = useState<Award | null>(null);

  const { setStatus, status, awards, rouletteId } = useRoulette();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const reset = () => {
    // Reset reward
    setSpinAwardResult(null);
    setStatus(RouletteStatus.READY);
  };

  const spin = async (cbs?: SpinFnProps) => {
    setLoading(true);

    /** Delay de 600ms na ação de girar a roleta para evitar
     * bug flick da animação de "encurtar" o card informativo.
     */
    const delayToRollWheel = 600;

    setTimeout(async () => {
      try {
        if (!rouletteId) {
          throw new Error('Roulette not found');
        }

        if (status === RouletteStatus.DRAWED) {
          reset();
        }

        /** Faz a requisição no back-end, que retorna o resultado
         * da ação (prêmio)
         */
        const spinResult = await makeSpin({
          rouletteId,
          awards,
        });

        if (!spinResult.award) {
          throw new Error('Failed to fetch reward');
        }

        setSpinAwardResult(spinResult.award);
        setStatus(RouletteStatus.DRAWING);

        dispatch(updateUserRouletteSpins(rouletteId, spinResult.left_spins));

        setLoading(false);

        cbs?.onSpinSuccess?.();
      } catch (error) {
        setLoading(false);
        let message =
          'Ocorreu um erro ao tentar girar a roleta, tente novamente.';

        if ((error as any).response?.data?.message) {
          message = (error as any).response?.data?.message;
        }

        addToast({
          title: message,
          type: 'error',
        });

        cbs?.onSpinError?.();
      }
    }, delayToRollWheel);
  };

  return {
    spin,
    reset,
    isPreparing: loading,
    award: spinAwardResult,
  };
}
