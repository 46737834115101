import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { dismissHasRegisterToComplete } from 'state/modules/home/actions';
import history from 'services/history';
import routeMap from 'routes/route-map';

import { Modal, Container, BtnContainer, Close, Button } from './style';

interface Props {
  isOpen: boolean;
}

const CompleteRegister: React.FC<Props> = ({ isOpen }) => {
  const dispatch = useDispatch();

  const onRequestCloseHandler = useCallback(
    () => dispatch(dismissHasRegisterToComplete()),
    [dispatch],
  );

  const onConfirmHandler = useCallback(() => {
    history.push(routeMap.completeIndirectRegistration);
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestCloseHandler}>
      <Close>
        <button type="button" onClick={onRequestCloseHandler}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Container>
        <h5>Deseja ativar seu novo perfil no juntos ?</h5>
        <BtnContainer>
          <Button type="button" buttonRole="primary" onClick={onConfirmHandler}>
            SIM
          </Button>
          <Button
            type="button"
            buttonRole="primary"
            onClick={onRequestCloseHandler}
          >
            MAIS TARDE
          </Button>
        </BtnContainer>
      </Container>
    </Modal>
  );
};

export default CompleteRegister;
