import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import YourOpinion from 'components/Surveys/Cards/YourOpinion';
import Answered from 'components/Surveys/Cards/AnsweredSurveys';
import ImageSearch from 'assets/images/cards/search.svg';
import { fetchSurveys, clearAnswers } from 'state/modules/surveys/actions';

import {
  Container,
  CardHeader,
  CardContentHeader,
  CardContentFooter,
  Title,
  ContentTitle,
  Separator,
} from './styles';

const ProducerResearch: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSurveys());
    dispatch(clearAnswers());
  }, [dispatch]);

  return (
    <Container>
      <Title>Pesquisas</Title>
      <CardHeader>
        <div>
          <p>
            Ao conhecer a sua opinião sobre diferentes assuntos, queremos
            fortalecer o nosso relacionamento, aprimorar o programa e entregar a
            você sempre as melhores soluções. Participe!
          </p>
        </div>
        <img src={ImageSearch} alt="Search" />
      </CardHeader>
      <CardContentHeader>
        <ContentTitle>Sua opinião vale muito</ContentTitle>
        <YourOpinion />
      </CardContentHeader>
      <Separator />
      <CardContentFooter>
        <ContentTitle>Pesquisas Respondidas/Encerradas</ContentTitle>
        <Answered />
      </CardContentFooter>
    </Container>
  );
};

export default ProducerResearch;
