/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useState } from 'react';

import { fetchCampaignsService } from 'services/goals';
import { Campaign } from 'state/modules/goals/types';
import getData from 'services/cockpit/getGeneralStatistics';
import getPogRevenues from 'services/cockpit/getPogRevenues';
import { useAuth } from 'context/AuthContext';
import { fakeFormatDollars } from 'util/points';
import Filters, { Filters as IFilters } from './Filters';
import {
  Container,
  CardContainer,
  AllBox,
  BoxForAllWatches,
  GoalText,
  IndividualWatchProduct,
  Label,
  List,
  Separator,
  WatchBoxCockpit,
  CustomSlider,
} from './styles';
import { useCockpitContext } from '../CockpitContext';
import CircularProgress from '../../../components/Home/FmcTeam/Performance/CircularProgress';

import { StatisticsWithProduct } from '../../../services/cockpit/interfaces/generalStatisticsWithProducts';
import { Product } from '../../../state/modules/home/types';

const approversProfile = ['ADM', 'CRM', 'MKT', 'DN', 'GC', 'DM'];
const segments = [
  'HERBICIDAS',
  'INSETICIDAS',
  'INSETICIDAS BY RYNAXYPYR',
  'FUNGICIDAS',
];

const GeneralView: React.FC = () => {
  const [juntosCampaign, setJuntosCampaigns] = useState<Campaign[]>();
  const [filters, setFilters] = useState<IFilters>({});
  const [terraCampaign, setTerraCampaign] = useState<Campaign[]>();
  const { participant } = useAuth();
  const { terraChannels, indirectChannels } = useCockpitContext();
  const [
    revenuesPogAndProducts,
    setRevenuesPogAndProducts,
  ] = useState<StatisticsWithProduct | null>(null);
  const [pogRevenues, setPogRevenues] = useState<StatisticsWithProduct | null>(
    null,
  );
  const settingsTerraBrasil = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const settingsIndiretos = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const isApproversProfile = approversProfile.includes(
    participant.profile_value,
  );
  const [showUsOrL, setShowUsOrL] = useState('US$');

  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await fetchCampaignsService();
      const campaignTerra2022 = '2022';

      const isTerraBrasil = data?.filter(
        c => c.isTerraBrasil && c.title !== campaignTerra2022,
      );

      const isIndirects = data?.filter(c => !c.isTerraBrasil);
      setJuntosCampaigns(isIndirects);
      setTerraCampaign(isTerraBrasil);
    };
    fetchCampaign();
  }, []);

  useEffect(() => {
    async function fetchStatistics() {
      if (filters) {
        (participant.role.id === 6 || isApproversProfile) &&
        filters?.campaignId &&
        filters.isTerraBrasil === 'true'
          ? getPogRevenues(filters?.campaignId).then(data =>
              setPogRevenues(data),
            )
          : getData(filters).then(data => setRevenuesPogAndProducts(data));
      }
    }
    fetchStatistics();
  }, [filters, participant, isApproversProfile]);

  const onFilterJuntos = useCallback(async (_filters: IFilters): Promise<
    void
  > => {
    setShowUsOrL(_filters.campaignId === 11 ? 'US$' : 'L');
    setFilters(_filters);
  }, []);

  const onFilterTerra = useCallback(async (_filters: IFilters): Promise<
    void
  > => {
    setFilters(_filters);
  }, []);

  const arrayProd = revenuesPogAndProducts?.focus_product
    ? Object.values(revenuesPogAndProducts?.focus_product)
    : [];

  const orderProducts = arrayProd
    ? arrayProd.sort(
        (a: Product, b: Product) => a.pcp.position - b.pcp.position,
      )
    : null;

  const arrayProdTerraBrasil = pogRevenues?.focus_product
    ? Object.values(pogRevenues?.focus_product)
    : [];

  const orderProductsTerraBrasil = arrayProdTerraBrasil
    ? arrayProdTerraBrasil.sort(
        (a: Product, b: Product) => a.pcp.position - b.pcp.position,
      )
    : null;

  return (
    <>
      <Container>
        {indirectChannels.length > 0 && (
          <>
            <h3>Juntos FMC Indiretos</h3>

            <CardContainer>
              <Filters
                onFilter={onFilterJuntos}
                juntosCampaign={juntosCampaign}
              />
            </CardContainer>

            <AllBox>
              <BoxForAllWatches>
                <WatchBoxCockpit>
                  <Label>FATURAMENTO</Label>
                  <GoalText>
                    Objetivo US${' '}
                    {revenuesPogAndProducts?.revenues.formattedGoal}
                  </GoalText>
                  <GoalText>
                    Realizado US${' '}
                    {revenuesPogAndProducts?.revenues.formattedResult}
                  </GoalText>
                  <CircularProgress
                    color="#FF4C16"
                    percent={revenuesPogAndProducts?.revenues.percentage || 0}
                  />
                </WatchBoxCockpit>

                <WatchBoxCockpit>
                  <Label>POG</Label>
                  <GoalText>
                    Objetivo US$ {revenuesPogAndProducts?.pog.formattedGoal}
                  </GoalText>
                  <GoalText>
                    Realizado US$ {revenuesPogAndProducts?.pog.formattedResult}
                  </GoalText>
                  <CircularProgress
                    color="#25CCE1"
                    percent={revenuesPogAndProducts?.pog.percentage || 0}
                  />
                </WatchBoxCockpit>

                <Separator />

                <CustomSlider {...settingsIndiretos}>
                  {orderProducts &&
                    orderProducts.length > 0 &&
                    orderProducts.map(product => (
                      <WatchBoxCockpit key={product.pcp.position}>
                        <List>
                          <IndividualWatchProduct>
                            <Label>
                              {!segments.includes(product.name.toUpperCase())
                                ? `${product.name} ®`
                                : product.name}
                            </Label>
                            <GoalText>
                              Objetivo{' '}
                              {showUsOrL !== 'L'
                                ? `US$ ${fakeFormatDollars(product.goal, 0, 0)}`
                                : `${fakeFormatDollars(product.goal, 0, 0)} L`}
                            </GoalText>
                            <GoalText>
                              Realizado{' '}
                              {showUsOrL !== 'L'
                                ? `US$ ${fakeFormatDollars(
                                    product.result,
                                    0,
                                    0,
                                  )}`
                                : `${fakeFormatDollars(
                                    product.result,
                                    0,
                                    0,
                                  )} L`}
                            </GoalText>
                            <CircularProgress
                              color={product.pcp.color}
                              percent={product.percentage || 0}
                            />
                          </IndividualWatchProduct>
                        </List>
                      </WatchBoxCockpit>
                    ))}
                </CustomSlider>
              </BoxForAllWatches>
            </AllBox>
          </>
        )}
        {/* FIM RELÓGIOS DOS PRODUTOS DOS INDIRETOS */}

        {terraChannels.length > 0 && (
          <>
            <h3>Terra Brasil</h3>
            <CardContainer>
              <Filters onFilter={onFilterTerra} terraCampaign={terraCampaign} />
            </CardContainer>

            <AllBox>
              <BoxForAllWatches>
                <WatchBoxCockpit>
                  <Label>FATURAMENTO</Label>
                  <GoalText>
                    Objetivo US$ {pogRevenues?.revenues.formattedGoal}
                  </GoalText>
                  <GoalText>
                    Realizado US$ {pogRevenues?.revenues.formattedResult}
                  </GoalText>
                  <CircularProgress
                    color="#FF4C16"
                    percent={pogRevenues?.revenues.percentage || 0}
                  />
                </WatchBoxCockpit>
                <WatchBoxCockpit>
                  <Label>POG Fornecedor</Label>
                  <GoalText>
                    Objetivo US$ {pogRevenues?.pog.formattedGoal}
                  </GoalText>
                  <GoalText>
                    Realizado US$ {pogRevenues?.pog.formattedResult}
                  </GoalText>
                  <CircularProgress
                    color="#25CCE1"
                    percent={pogRevenues?.pog.percentage || 0}
                  />
                </WatchBoxCockpit>

                <Separator />

                <CustomSlider {...settingsTerraBrasil}>
                  {orderProductsTerraBrasil &&
                    orderProductsTerraBrasil.length > 0 &&
                    orderProductsTerraBrasil.map(product => (
                      <WatchBoxCockpit>
                        <List key={product.pcp.position}>
                          <IndividualWatchProduct>
                            <Label>
                              {!segments.includes(product.name.toUpperCase())
                                ? `${product.name} ®`
                                : product.name}
                            </Label>
                            <GoalText>
                              Objetivo {fakeFormatDollars(product.goal, 0, 0)} L
                            </GoalText>
                            <GoalText>
                              Realizado{' '}
                              {fakeFormatDollars(product.result, 0, 0)} L
                            </GoalText>
                            <CircularProgress
                              color={product.pcp.color}
                              percent={product.percentage || 0}
                            />
                          </IndividualWatchProduct>
                        </List>
                      </WatchBoxCockpit>
                    ))}
                </CustomSlider>
              </BoxForAllWatches>
            </AllBox>
          </>
        )}
      </Container>
    </>
  );
};

export default GeneralView;
