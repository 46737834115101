import styled, { css } from 'styled-components';

export const BoxTitle = styled.div`
  background-color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  width: max-content;
  height: 45px;
  padding: 0 0.5em;
  font-size: 0.9em;
`;

export const ProgressTitleV = styled.div<{ color: any; isWide: boolean }>`
  background-color: ${({ color }) => color};
  color: ${({ color }) => (color === '#C9ED00' ? '#000' : '#fff')};
  width: ${({ isWide }) => (isWide ? '920px' : '300px')};
  padding: 0.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-family: 'Helvetica-Neue-Bold';
  font-weight: 700;
  justify-content: center;
`;

export const GoalTextStyled = styled.p<{ isWide: boolean }>`
  position: relative;
  top: 1.6rem;
  color: #000;
  font-size: 0.7rem;
  text-align: center;
  font-weight: 400;
  @media (min-width: 1100px) {
    left: -5rem !important;
  }
  left: 0rem !important;
  margin: 1rem;
  text-align: ${({ isWide }) => (isWide ? 'left' : 'center')};
  width: ${({ isWide }) => (isWide ? '460px' : 'auto')};
`;

export const Body = styled.div`
  padding: 2em;
`;

interface WrapperProps {
  reverse: boolean;
}
export const WrapperBox = styled.div<WrapperProps>`
  margin-bottom: 2em;

  ${({ reverse }) =>
    reverse
      ? css`
          ${BoxTitle} {
            background-color: #b4bfc5;
          }

          ${Body} {
            border: 1.2px solid #b4bfc5;
          }
        `
      : css`
          ${BoxTitle} {
            background-color: ${({ theme }) => theme.font.color.primary};
          }

          ${Body} {
            border: 1.2px solid ${({ theme }) => theme.font.color.primary};
          }
        `}
`;

export const Goals = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-bottom: 3px solid #e2e2e2;
`;

export const WrapperValues = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  margin: 0.5em;
  flex: 0 0 calc(33.33% - 1em);

  .last-segment {
    color: #000;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 5px;
  max-height: 70px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 670px;
  position: relative;
  right: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProgressTitle = styled.div`
  color: #000000;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-align: center;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  > span {
    font-size: 11px;
    transform: translateY(-1px);
    text-align: center;
  }
`;

export const GoalTextSecondary = styled.p`
  color: #000000;
  font-size: 0.5em;
  text-align: center;
`;

export const GoalText = styled.p`
  color: #000000;
  font-size: 0.7em;
  text-align: center;
`;

export const CircularSectionItem = styled.div`
  width: 142px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  position: relative;
  left: -1rem;

  > img {
    width: 32px;
    height: 32px;
  }

  .uncheckIcon {
    width: 40px;
    height: 40px;
  }
`;

export const TableStyle = styled.table`
  color: black;
  margin: 10px auto;
  max-width: 427px;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-left: 0.5rem;
  }

  tbody:nth-child(even) {
    background-color: #fff;
  }
  tbody:nth-child(odd) {
    background-color: #efeeee;
  }

  @media (max-width: 768px) {
    .table-1 {
      width: 67%;
      border-collapse: collapse;
      margin: 0 auto;
      text-align: center;
    }

    .table-2 {
      width: 75%;
      border-collapse: collapse;
      margin: 0 auto;
      text-align: center;
    }

    .table-3 {
      width: 69%;
      border-collapse: collapse;
      margin: 0 auto;
      text-align: center;
    }

    .table-4 {
      width: 80%;
      border-collapse: collapse;
      margin: 0 auto;
      text-align: center;
    }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 16px;
  font-family: 'Helvetica-Neue-Bold';
`;

export const TableCell = styled.td`
  font-size: 12px;
  padding: 8px 20px;
  text-align: center;

  > span {
    width: 120px;
    display: inline-block;
  }
`;

export const Title = styled.h5`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 16px;
  color: #000;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0 5px 0;

  > span {
    font-size: 12px;
    transform: translateY(-3px);
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 14px;
    text-align: center;
    color: #000;
  }
`;


export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(180px, 1fr));
  grid-gap: 4.4rem;

  & + div {
    margin: 10px -40px 0px -40px;
  }

  @media screen and (max-width: 768px) {
    background-color: #fff;
    grid-template-columns: 0;
    justify-content: center;
  }
`;



export const LastUpdate = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  color: #000;
  font-size: 0.8em;
  text-align: start;
  margin: 45px 45px 0;
  width: 72%;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    margin: 30px 10px 0;
  }
`;
