import { combineReducers } from 'redux';

import common, { CommonState } from './common/reducer';

export type PointManagementState = {
  common: CommonState;
};

export default combineReducers<PointManagementState>({
  common,
});
