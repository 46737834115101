import { Reducer } from 'redux';
import { FetchState } from '@types';
import { emptyFetchState, fetchingState, fetchErrorState } from 'state/utils';
import {
  SET_VALUE_ANSWERS,
  CLEAN_VALUE_ANSWERS,
  FETCH_SURVEYS_ACTION,
  FETCH_SURVEYS_FAILURE,
  FETCH_SURVEYS_SUCCESS,
} from './constants';
import { AnswerData, Surveys } from './types';
import { SurveyActions } from './actions';
import { getAnswersValues } from './utils';

export interface SurveyState {
  answers: AnswerData[];
  surveys: Surveys;
  fetchingSurveys: FetchState;
}

export const initialState: SurveyState = {
  answers: [],
  fetchingSurveys: emptyFetchState,
  surveys: {
    available: [],
    performed: [],
  },
};

const surveysReducer: Reducer<SurveyState, SurveyActions> = (
  state = initialState,
  action: SurveyActions,
) => {
  switch (action.type) {
    case SET_VALUE_ANSWERS:
      return {
        ...state,
        answers: getAnswersValues([...state.answers], action.payload),
      };

    case FETCH_SURVEYS_ACTION:
      return { ...state, fetchingSurveys: fetchingState };

    case FETCH_SURVEYS_FAILURE:
      return { ...state, fetchingSurveys: fetchErrorState(action) };

    case FETCH_SURVEYS_SUCCESS:
      return {
        ...state,
        fetchingSurveys: emptyFetchState,
        surveys: {
          available: [...action.payload.surveys.available],
          performed: [...action.payload.surveys.performed],
        },
      };

    case CLEAN_VALUE_ANSWERS:
      return initialState;

    default:
      return state;
  }
};

export default surveysReducer;
