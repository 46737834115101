import React, { useState } from 'react';
import { Channel } from 'state/modules/points-simulator/interfaces';
import HelpPointsSimulator from '../Modal';

import { Container, Content } from './styles';

type Position = 'left-bottom' | 'right-bottom' | 'left-top' | 'right-top';

interface HelpIconProps {
  initialPosition: Position;
  channel: Channel | null;
}

const style = {
  'left-bottom': { left: 60, bottom: 50 },
  'right-bottom': { right: 60, bottom: 50 },
  'left-top': { left: 60, top: 50 },
  'right-top': { right: 60, top: 50 },
};

const HelpIcon: React.FC<HelpIconProps> = ({ initialPosition, channel }) => {
  const defaultPosition = style[initialPosition];
  const [modalOpened, setModalOpened] = useState(false);
  const isTerraBrasil = channel?.type.toLowerCase() === 'terra brasil';

  return (
    <>
      <Container style={defaultPosition}>
        <div>
          <Content onClick={() => setModalOpened(true)}>
            <span>?</span>
          </Content>
        </div>
      </Container>
      <HelpPointsSimulator
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
        isTerraBrasil={isTerraBrasil}
      />
    </>
  );
};
export default HelpIcon;
