import React, { useEffect, useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';

import {
  StrategicPlanDetails,
  getStrategyPlanDetailsByUUID,
} from 'services/strategic-plans/getStrategyPlanDetailsByUUID';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import {
  getActionTypeName,
  getStatusText,
} from 'components/StrategicPlans/utils';
import { useAuth } from 'context/AuthContext';
import { Table } from 'components/shared';
import { canApprovePlans } from 'pages/StrategicPlans/rules';
import { fakeFormatDollars } from 'util/points';
import { formatDate } from 'util/datetime';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import StatusSelect from 'components/StrategicPlans/ActionsList/StatusSelect';
import {
  StrategicPlansProvider,
  useStrategicPlansContext,
} from 'pages/StrategicPlans/StrategicPlansView/Context';
import { Container } from './styles';
import { ApproverActions } from './actions/approver-actions';
import { CreatorActions } from './actions/creator-actions';

const getPlanIdFromPathName = (pathname: string): string => {
  const planId = pathname.replace(/\/$/, '').split('/').slice(-1).pop();
  return planId || '';
};

interface Params {
  uuid: string;
  id: number;
}

export const PlanDetailsScreen = () => (
  <StrategicPlansProvider>
    <PlanDetails />
  </StrategicPlansProvider>
);

export const PlanDetails = () => {
  const [details, setDetails] = useState<StrategicPlanDetails | null>(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation<Params>();
  const history = useHistory();
  const { participant } = useAuth();
  const { addToast } = useToast();
  const { actionStatuses } = useStrategicPlansContext();

  const planId = getPlanIdFromPathName(location.pathname);

  useEffect(() => {
    if (planId && planId.length) {
      getStrategyPlanDetailsByUUID(planId)
        .then(plan => {
          setDetails(plan);
        })
        .catch(() =>
          addToast({
            title: 'Falha ao obter detalhes do plano',
            type: 'error',
          }),
        )
        .finally(() => setLoading(false));
    }
  }, [planId, addToast]);

  const canApprovePlan = canApprovePlans(
    participant.id,
    participant.profile_value,
    details?.askApprovations || [],
  );
  const canCreateActions = details?.participantId === participant.id;

  return (
    <Container>
      <div className="header">
        <div className="header--title">
          <button
            type="button"
            onClick={() => history.push('/planos-estrategicos')}
          >
            <BsChevronLeft /> Voltar
          </button>
          <strong>Detalhes do plano estratégico</strong>
        </div>
        <div className="header--details">
          <div className="header--details_title">
            {details?.clientGroup && details?.category && (
              <strong>
                {details?.clientGroup || '---'} ({details?.category || '---'})
              </strong>
            )}
            <span>Status: {getStatusText(details?.status || 0)}</span>
          </div>
          <div className="header--details_actions">
            {!loading && details && !canCreateActions && (
              <ApproverActions
                planUUID={details.uuid}
                comments={details.comments}
                status={details.status}
                canApproveOrReprove={canApprovePlan}
              />
            )}

            {!loading && details && canCreateActions && (
              <CreatorActions
                planID={details.id}
                planUUID={details.uuid}
                comments={details.comments}
                canRequestApproval={
                  details.actions.length > 0 &&
                  ![
                    strategyPlanStatus.onApproval,
                    strategyPlanStatus.approved,
                    strategyPlanStatus.outOfTime,
                  ].includes(details.status)
                }
                canCreateActions={[
                  strategyPlanStatus.inAdjustments,
                  strategyPlanStatus.planning,
                ].includes(details.status)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <Table
          className="table--info"
          tableRole="secondary"
          isFetching={loading}
          noResultText="Nenhuma ação cadastrada."
          data={details?.actions || []}
          headers={[
            {
              column: 'Tipo',
              dataValue: 'typeId',
              fn: value => <strong>{getActionTypeName(Number(value))}</strong>,
            },
            {
              column: 'Título',
              dataValue: 'uuid',
              fn: value => {
                const action = details?.actions.find(ac => ac.uuid === value);

                return (
                  <div className="table--info_data">
                    <strong>{action?.title}</strong>
                    <span>Previsão: {formatDate(action?.expectedDate)}</span>
                  </div>
                );
              },
            },
            {
              column: 'Valor',
              dataValue: 'uuid',
              fn: value => {
                const action = details?.actions.find(ac => ac.uuid === value);

                return (
                  <div className="table--info_data">
                    <strong>
                      Gestão e Desenvolvimento (R$):{' '}
                      {fakeFormatDollars(action?.points || 0)}
                    </strong>
                    <strong>
                      Budget (R$): {fakeFormatDollars(action?.budget || 0)}
                    </strong>
                    <strong>
                      IGD (R$):{' '}
                      {fakeFormatDollars(action?.igd || 0)}
                    </strong>
                  </div>
                );
              },
            },
            {
              column: 'Status',
              dataValue: 'uuid',
              fn: value => {
                const action = details?.actions.find(ac => ac.uuid === value);

                return (
                  action && (
                    <div>
                      <StatusSelect
                        statuses={actionStatuses}
                        uuid={action.uuid}
                        defaultStatus={action.status}
                      />
                    </div>
                  )
                );
              },
            },
            {
              column: '',
              dataValue: 'uuid',
              fn: value => (
                <Link to={`/planos-estrategicos/${value}/acao`}>
                  Ver detalhes
                </Link>
              ),
            },
          ]}
        />
      </div>
    </Container>
  );
};
