export const downloadExcel = (data: any) => {
  const flattenedData = data.reduce(
    (acc: any, val: any) => acc.concat(val),
    [],
  );

  const content: any = [
    ['Nome', 'Cargo', 'Pontos'],
    ...flattenedData.map((item: any) => [item.name, item.role, item.value]),
  ];

  const csvContent = content
    .map((row: any) =>
      row.map((value: any) => encodeURIComponent(value)).join(';'),
    )
    .join('\n');

  const csvDataUri = `data:text/csv;charset=utf-8,%EF%BB%BF${csvContent}`;

  const link = document.createElement('a');
  link.setAttribute('href', csvDataUri);
  link.setAttribute('download', 'dados-participants-approvals.csv');
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadExcelInBatch = (data: any) => {
  const content: any = [
    ['Filial', 'Participant_Id', 'Nome', 'Cargo', 'Pontos'],
    ...data.map((item: any) => [
      item.subsidiary || '',
      item.id,
      item.name,
      item.role,
    ]),
  ];

  const csvContent = content
    .map((row: any) =>
      row.map((value: any) => encodeURIComponent(value)).join(';'),
    )
    .join('\n');

  const csvDataUri = `data:text/csv;charset=utf-8,%EF%BB%BF${csvContent}`;

  const link = document.createElement('a');
  link.setAttribute('href', csvDataUri);
  link.setAttribute('download', 'dados-participants.csv');
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
