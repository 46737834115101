import React from 'react';
import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import { FaFileInvoice as InvoiceIcon } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ToolTip from 'components/shared/Tooltip';

import {
  Content,
  PageTitle,
  ModalContainer,
  CloseModalOverlay,
  CloseModal,
  BottomText,
  DetailsSection,
  CopyContainer,
  DetailsText,
  DetailsHeader,
  InvoiceLink,
} from './styles';

interface ConsultInvoiceModalProps {
  invoiceAccessKey: string;
  modalOpen: boolean;
  closeModalHandler: () => void;
  selectedOrigin: string;
}

const URL_RECEITA =
  'https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=';

const ConsultInvoiceModal: React.FC<ConsultInvoiceModalProps> = ({
  invoiceAccessKey,
  modalOpen,
  closeModalHandler,
  selectedOrigin
}) => {
  return (
    <ModalContainer modalOpen={modalOpen}>
      <CloseModalOverlay onClick={() => closeModalHandler()} />
      <Content>
        <PageTitle>
          Detalhes da Nota
          <CloseModal onClick={() => closeModalHandler()}>
            <ReactSVG src={closeIcon} />
          </CloseModal>
        </PageTitle>
        <DetailsSection fullWidth>
          <DetailsHeader>
            <InvoiceIcon size={48} />
            <DetailsText>
              Para obter os dados da sua Nota Fiscal, copie o código abaixo e
              acesse o Portal NF-e{' '}
              <InvoiceLink href={URL_RECEITA} target="_blank" rel="noreferrer">
                clicando aqui.
              </InvoiceLink>
            </DetailsText>
          </DetailsHeader>

          <CopyToClipboard text={invoiceAccessKey}>
            <CopyContainer>
              <p>Chave de acesso: {invoiceAccessKey}</p>
              <ToolTip
                title="Clique aqui para copiar a chave de acesso"
                type="primary"
              >
                <CopyIcon size={32} />
              </ToolTip>
            </CopyContainer>
          </CopyToClipboard>
        </DetailsSection>

        {selectedOrigin === "Integrator" && (
          <h4 style={{ textAlign: 'center', marginTop: '1em' }}>Nota carregada pelo Envio Automático de Notas.</h4>
        )}

        <BottomText>
          Dúvidas sobre o preenchimento desta nota?&nbsp;
          <Link to={routeMap.contact}>Clique aqui</Link>
        </BottomText>
      </Content>
    </ModalContainer>
  );
};

export default ConsultInvoiceModal;
