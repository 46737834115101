/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { formatPoints } from 'util/points';
import { downloadExcel } from 'util/download-excel';
import { ApprovationStatus } from 'services/point-management/dtos/point';
import { StatusIcon } from 'components/PointManagement/point-card/status-icon';
import { Container } from './styles';
import { Participant } from './types/PointCard';
import { CommentModelTeam } from './comment-model-team';

type Props = {
  pointRepproved?: any;
  tab?: string;
};

export const PointCardSecondPhaseRepprove: React.FC<Props> = ({
  pointRepproved,
  tab,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [participantExcel, setParticipantExcel] = useState<any>();
  const [isOpenComments, setOpenComments] = useState(false);

  useEffect(() => {
    const allParticipants: Participant[] = [];

    pointRepproved &&
      pointRepproved.participants &&
      pointRepproved.participants.forEach(
        (obj: Participant | null) => obj && allParticipants.push(obj),
      );

    setParticipantExcel(allParticipants);
  }, [pointRepproved, pointRepproved.participants]);

  return (
    <Container status={4}>
      <div className="point-wrapper">
        <div className="point-name">
          <strong>{pointRepproved.name_point}</strong>
        </div>
        <div className="point-divider" />
        <div className="point-value">
          <strong>{formatPoints(pointRepproved.total)} pontos</strong>
        </div>
        <div className="point-action">
          <CommentModelTeam
            isOpen={isOpenComments}
            comments={pointRepproved.comments}
            setOpenComments={setOpenComments}
          />

          <button
            type="button"
            className="details"
            onClick={() => setOpenComments(!isOpenComments)}
          >
            Comentários
          </button>

          <button
            type="button"
            className="details-action"
            onClick={() => setOpen(!isOpen)}
          >
            Detalhes
          </button>

          <StatusIcon
            status={ApprovationStatus.REPROVADO}
            statusText="Reprovado"
          />
        </div>
      </div>
      {isOpen && (
        <div className="open--content">
          <div className="report--link">
            <a href="#" onClick={() => downloadExcel(participantExcel)}>
              Fazer o download do relatório para ver os detalhes da distribuição
            </a>
          </div>
        </div>
      )}
    </Container>
  );
};
