import { QuestionsData } from 'components/Surveys/types';
import { AnswerData as AnswerValue } from 'state/modules/surveys/types';

interface ValidationResponse {
  valid: boolean;
  questions?: string[];
}

export default (
  answers: AnswerValue[],
  questions: QuestionsData[],
): ValidationResponse => {
  const requiredQuestionIds = questions.filter(item => item.is_required);

  const answeredQuestionIds = answers
    .filter(item => item.answerValue)
    .map(item => item.questionId);

  if (requiredQuestionIds.length === 0) return { valid: true };

  const result = [] as QuestionsData[];

  requiredQuestionIds.forEach(item => {
    if (!answeredQuestionIds.includes(item.id)) {
      result.push(item);
    }
  });

  return {
    valid: result.length === 0,
    questions: result.map(item => item.question),
  };
};
