import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Harvest } from 'services/auth/interfaces/Participant';
import { useToast } from 'context/ToastContext';
import { pluginApi } from 'services/api';
import { useAuth } from 'context/AuthContext';
import { Alert } from 'components/shared';
// import CustomInput from './CustomInput';
import CustomInputCheck from './CustomInputCheck';
import CustomInputHectares from './CustomInputHectares';
// import { formatValue } from 'util/points';
import {
  // Obs,
  // ButtonContainer,
  Button,
  // Separator,
  // TotalHectares,

  NewObs,
  BoxText,
  BoxHectares,

  BoxOptions,
  Container,
  BoxInputOptions,
  // InputOtions,
  // InputText,
  // OptionsTitle,
  BoxButton

} from './styles';
// import { getValidationSchema } from './validation';

const harvestInputList = [
  { key: 'harvest.algodao', title: 'Algodão' },
  { key: 'harvest.arroz_irrigado', title: 'Arroz Irrigado' },
  { key: 'harvest.batata', title: 'Batata' },
  { key: 'harvest.cafe', title: 'Cafe' },
  { key: 'harvest.cana', title: 'Cana' },
  { key: 'harvest.cenoura', title: 'Cenoura' },
  { key: 'harvest.cevada', title: 'Cevada' },
  { key: 'harvest.citrus', title: 'Citrus' },
  { key: 'harvest.feijao', title: 'Feijão' },
  { key: 'harvest.mandioca', title: 'Mandioca' },
  { key: 'harvest.melao', title: 'Melão' },
  { key: 'harvest.milho', title: 'Milho' },
  { key: 'harvest.soja', title: 'Soja' },
  { key: 'harvest.tabaco', title: 'Tabaco' },
  { key: 'harvest.tomate', title: 'Tomate' },
  { key: 'harvest.trigo', title: 'Trigo' },
  { key: 'harvest.uva', title: 'Uva' },
];

interface Props {
  handleActionPageButton(): void;
  actived: boolean;
  completeRegister?: boolean;
}

type FormData = {
  harvest: Harvest;
};

const HarvestDataForm: React.FC<Props> = ({ actived, completeRegister }) => {
  const { addToast } = useToast();
  const { participant, refreshParticipant } = useAuth();


  const formMethods = useForm<FormData>({
    //validationSchema: getValidationSchema(),
    defaultValues: {
      harvest: participant.harvest,
    },
  });
  
  const {
    handleSubmit: submit,
    formState: { isSubmitting },
  } = formMethods;


  const handleSubmit = submit(async data => {    

    data.harvest.hectares === undefined &&( data.harvest.hectares = "");
    data.harvest.outras_quais === undefined &&( data.harvest.outras_quais = "");

    const isHectaresFilled =
      data.harvest.hectares !== '0' && data.harvest.hectares.length > 0;

    const isCultivoFilled =
      Object.keys(data.harvest).filter(field => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (data.harvest as any)[`${field}`];

        if (field === 'hectares') return false;

        return value !== '0' && value.length > 0;
      }).length > 0;

    if (isHectaresFilled && !isCultivoFilled) {
      addToast({
        title: 'Você deve preencher ao menos um campo de cultivo.',
        type: 'error',
      });
      return;
    }

    if (isCultivoFilled && !isHectaresFilled) {
      addToast({
        title: 'Você deve preencher o campo de hectares.',
        type: 'error',
      });
      return;
    }

    if(data.harvest.outras === "1" && data.harvest.outras_quais.length === 0){
      addToast({
        title: 'Obrigatório prencher o campo de outras!',
        type: 'error',
      });
      return;
    }

    try {
      await pluginApi.post('/participants/Cultivo', data.harvest);

      addToast({
        title: 'Dados de cultivo salvos com sucesso.',
        type: 'success',
      });

      refreshParticipant();
    } catch (error) {
      addToast({
        title:
          (error as any).response?.data?.message ||
          'Falha ao editar a fazenda. Por favor entre em contato com o suporte',
        type: 'error',
      });
    }
  });
  const isCultivoRegisterComplete = !participant.role.complete_registration
    ?.missing?.cultivo;  

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: actived ? 'block' : 'none' }}>
          {!isCultivoRegisterComplete && (
            <div style={{ marginBottom: '20px' }}>
              <Alert variation="error">
                Você precisa cadastrar os dados da área de cultivo para
                completar seu cadastro!
              </Alert>
            </div>
          )}
          


          <NewObs>
            <BoxText>
              <h3>Por favor, preencha os dados correspondentes à área total de cultivo:</h3>
            </BoxText>
            <BoxHectares>
              <CustomInputHectares name="harvest.hectares" title="Hectares"/>              
            </BoxHectares>
          </NewObs>

          <BoxOptions>
            
            <h3>Marque abaixo so seus principais cultivos:</h3>
            
            <Container>
              {
                harvestInputList.map(
                  item => (
                    <>
                      <BoxInputOptions active={false}>
                        <CustomInputCheck key={item.key} title={item.title} name={item.key}/>
                      </BoxInputOptions>
                    </>
                  )
                )
              }
              <BoxInputOptions active={true} style={{ height: '40px' }}>                
                <CustomInputCheck 
                  name="harvest.outras"
                  title="Outras"
                  extraInfo="harvest.outras_quais"
                />
              </BoxInputOptions>
            </Container> 

            <BoxButton>            
              <Button
                  type="submit"
                  buttonRole="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                Enviar
              </Button>
            </BoxButton>

          </BoxOptions>


          {/* <Obs>Por favor, preencha os dados da área total de plantio</Obs>
          <CustomInput name="harvest.hectares" title="Área total de cultivo" />
          <TotalHectares>
            <p>Exemplo de preenchimento</p>
            <p>10.000m² = 1 Hectare</p>
            <p>55.450m² = 5,645 Hectares</p>
          </TotalHectares>

          <Separator />
          <Obs>Preencha os dados das áreas dos seus principais cultivos</Obs>
          {harvestInputList.map(item => (
            <CustomInput key={item.key} name={item.key} title={item.title} />
          ))}

          <CustomInput
            name="harvest.outras"
            title="Outras"
            extraInfo="harvest.outras_quais"
          />
          {!completeRegister && (
            <ButtonContainer>
              <Button
                type="submit"
                buttonRole="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Salvar
              </Button>
            </ButtonContainer>
          )} */}



        </div>
      </form>
    </FormContext>
  );
};

export default HarvestDataForm;
