import styled from 'styled-components';
import banner from 'assets/images/accelerator-campaigns/banner-new-campaingn.png';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 95px);
  background: #fff;
`;

export const Header = styled.div`
  background-image: url(${banner});
  background-position: 10% 0, center;
  background-size: cover;
  display: flex;
  height: auto;
  min-height: 250px;
  width: 100%;

  .contents-header {
    width: 100%;
    margin-top: 4em;
    margin-left: 200px;
  }

  h1 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 1em;
  }

  button {
    color: ${({ theme }) => theme.font.color.primary};
    background: none;
    text-transform: uppercase;
    text-decoration: underline;
    border: none;
    font-size: 14px;
    margin-top: 1em;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1100px;
  flex-direction: column;
  background: #fff;
  padding: 40px 60px;

  h3 {
    color: ${({ theme }) => theme.font.color.primary};
    margin: 1rem 0rem;
    font-size: 22px;
    font-family: 'Helvetica-Neue-Bold';
  }
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.font.color.primary};
  height: 100px;
  border-radius: 7px;
  margin-bottom: 20px;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.font.color.primary};

    h1 {
      font-size: 18px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: ${({ theme }) => theme.font.color.primary};
      position: relative;
      top: 1rem;
      left: 2rem;
    }

    p {
      margin-top: 10px;
      color: #000000;
      position: relative;
      top: 1rem;
      left: 2rem;
      width: 75%;
    }
  }

  button {
    max-height: 37px;
    max-width: fit-content;
    font-size: 14px;
    padding: 8px 35px;
    margin: 20px 10px;
  }

  @media screen and (max-width: 920px) {
    p {
      font-size: 11px;
    }
  }
`;

export const CardImage = styled.div`
  width: 190px !important;
  height: 98px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.font.color.primary};

  img {
    width: 46.51px;
    height: 47.49px;
    display: block;
    margin: 1.6rem auto;
  }
`;
