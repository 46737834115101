import { pluginApi } from 'services/api';
import { SurveyApi } from './interfaces';

interface Response {
  available_surveys: SurveyApi[];
  performed_surveys: SurveyApi[];
}

export default async (): Promise<Response> => {
  const { data } = await pluginApi.get<Response>('/participants/surveys');
  return data;
};
