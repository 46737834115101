import { Statement, StatementPoints } from 'services/extract/interfaces';

export interface GroupedPoints {
  statementId: number;
  title: string;
  total: number;
  total_resume: number;
  points: StatementPoints[];
  showDistributeButton: boolean;
  order?: number;
  className?: string;
}

/**
 * Agrupa os pontos pelo nome e soma o total
 * @param extractStatement
 * @returns Pontos agrupados por nome e total somado
 */
export function groupPointsByUnitName(
  extractStatement: Statement,
): GroupedPoints[] {
  const groupedPoints: GroupedPoints[] = [];

  extractStatement.points?.forEach(statementPoint => {
    // Mostrar botão de distribuir apenas para registros da tabela "undistributed_points"
    const showDistributeButton = statementPoint.type === 'undistributed_points';

    const isDistributed =
      statementPoint.type === 'undistributed_points' &&
      !!statementPoint.distributed?.length;

      // Calcular a soma dos pontos distribuídos
      const distributedTotal = statementPoint.distributed
      ? statementPoint.distributed.reduce((sum, distributedPoint) => sum + distributedPoint.value, 0)
      : 0;

      // Subtrair os pontos distribuídos do total para obter o total_resume
      const totalResume = statementPoint.value - distributedTotal;

    const skipTotalSum = ['premiação de vendedores'].includes(
      statementPoint.balanceUnit.name,
    );

    const alreadyAddedIndex = groupedPoints.findIndex(
      point => point.title === statementPoint.balanceUnit.name,
    );

    if (alreadyAddedIndex !== -1) {
      const groupedPoint = groupedPoints[alreadyAddedIndex];

      const updatedGrupedPoint: GroupedPoints = {
        ...groupedPoint,
        total:
          skipTotalSum && isDistributed
            ? groupedPoint.total
            : groupedPoint.total + statementPoint.value,
        points: [...groupedPoint.points, statementPoint],
        total_resume: groupedPoint.total_resume + totalResume,
      };

      groupedPoints[alreadyAddedIndex] = updatedGrupedPoint;
    } else {
      const groupedPoint: GroupedPoints = {
        statementId: statementPoint.id,
        title: statementPoint.balanceUnit.name,
        total: statementPoint.value,
        total_resume: totalResume,
        points: [statementPoint],
        showDistributeButton,
      };

      groupedPoints.push(groupedPoint);
    }
  });

  return groupedPoints;
}
