import styled from 'styled-components';

interface ColorMap {
  [key: string]: string;
}
const colors: ColorMap = {
  Cooperativa: '#1e3c47',
  Revenda: '#1e3c47',
  Produtor: '#1e3c47',
};

interface ContainerProps {
  type: string;
}

export const Container = styled.div<ContainerProps>`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${({ type }) => colors[type]};
`;
