import { pluginApi } from 'services/api';
import { Award } from '../types';

type Request = {
  awards: Award[];
  rouletteId: number;
};

type Response = {
  award_id: string;
  giros_restantes: number;
};

export async function makeSpin({ awards, rouletteId }: Request) {
  const response = await pluginApi.post<Response>('/roulettes/spin', {
    roulette_id: rouletteId,
  });

  const resultAward = awards.find(
    award => award.id === Number(response.data.award_id),
  );

  return {
    award: resultAward,
    left_spins: response.data?.giros_restantes,
  };
}
