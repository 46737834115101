import React from 'react';

import CloseButton from 'components/shared/CloseButton';
import { Container, Modal, ButtonWrapper, Button, Header } from './styles';

interface DeleteModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={1}>
      <CloseButton onClickHandler={onRequestClose} />
      <Container>
        <Header>Tem certeza que deseja remover esta Ação?</Header>
        <ButtonWrapper>
          <Button type="button" buttonRole="primary" onClick={onConfirm}>
            Sim
          </Button>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            Não
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default DeleteModal;
