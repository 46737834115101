import React, { useState, useCallback, useRef, useEffect } from 'react';

import { formatDate } from 'util/datetime';
import { useFormContext } from 'react-hook-form';
import { toDate } from 'date-fns';
import { Container } from './styles';
import { Input, TextArea, DatePicker } from '../shared/styles';

interface CommonProps {
  canEdit?: boolean;
}

const Common: React.FC<CommonProps> = ({ canEdit }) => {
  const { errors, register, setValue, watch } = useFormContext();
  const [expectedDate, setExpectedDate] = useState<Date | null>(null);

  const hiddenRef = useRef<HTMLInputElement>();

  const defaultExpectedDate = watch('expected_date');

  const onExpectedDateChangeHandler = useCallback(
    (value: Date): void => {
      if (!canEdit) return;

      setExpectedDate(toDate(value));
      if (value) {
        const data = formatDate(value, 'yyyy-MM-dd');
        setValue('expected_date', data);
      }
    },
    [setValue, canEdit],
  );

  useEffect(() => {
    if (defaultExpectedDate) {
      const data = new Date(
        formatDate(defaultExpectedDate, 'yyyy-MM-dd hh:mm:ss'),
      );
      setExpectedDate(data);
    }
  }, [defaultExpectedDate]);

  return (
    <Container>
      <DatePicker
        label="Previsão *"
        value={expectedDate}
        onChange={onExpectedDateChangeHandler}
        name="teste"
        error={errors.expected_date?.message ?? false}
        disabled={!canEdit}
      />
      <input
        type="hidden"
        disabled={!canEdit}
        name="expected_date"
        ref={(e: HTMLInputElement) => {
          hiddenRef.current = e;
          register(e);
        }}
      />
      <Input name="title" label="Título da Ação *" disabled={!canEdit} />
      <TextArea name="description" label="Descrição *" disabled={!canEdit} />
    </Container>
  );
};

export default Common;
