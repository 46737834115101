import { DM, RTC, RTCPOG, GC, CRM, GRM, GRV, KAM } from 'config/constants';
import { Approver } from 'services/strategic-plans/interfaces';

export function canViewApprovePlanList(profile: string) {
  const allowedToApprove = [DM, GC, CRM, GRM, GRV, KAM];

  return allowedToApprove.includes(profile.toUpperCase());
}

export function canApprovePlans(
  participantId: number,
  profile: string,
  askApprovations: Approver[],
) {
  const canViewPlanList = canViewApprovePlanList(profile);

  if (canViewPlanList) {
    const notAvailableStatus = 1;
    const userCanApprovePlan = askApprovations.find(
      ask =>
        ask.participantId === participantId &&
        ask.approvationStatus === notAvailableStatus,
    );
    const isCRM = profile === CRM;

    return !!userCanApprovePlan || isCRM;
  }

  return false;
}

export function canCreatePlans(profile: string) {
  const allowedToCreate = [DM, RTC, RTCPOG];

  return allowedToCreate.includes(profile.toUpperCase());
}
