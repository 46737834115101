import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;
export const Modal = styled(DefaultModal)`
  @media screen and (max-width: 720px) {
    ._modalContainer {
      width: 98%;
      padding-right: 0;
    }
  }
`;
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #000000;
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 20px; /* Espaço abaixo do título */
  @media (min-width: 768px) {
    font-size: 1.1em;
  }
`;

export const DoneButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza o botão horizontalmente */
  width: 100%;
  max-width: 300px; /* Largura máxima do contêiner do botão */
  button {
    background-color: #e63027;
    height:20px;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    transition: background-color 0.3s;
    &:hover {
      background-color: #c3201f;
    }
  }
`;
export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  padding: 5px;
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: black;
    }
  }
`;
export const Message = styled.p`
  margin-top: 15px; /* Espaço entre os botões e a mensagem */
  font-size: 14px;
  color: ${({ theme }) => theme.font.color.secondary}; /* Cor da mensagem */
`;

export const MessageCount = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #000;
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 0.9em;
    font-weight: 900;
  }
`;

export const MessageResend = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #000;
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 0.9em;
  }

  span {
    margin: 0 10px; /* Margem horizontal para o texto "ou" */
  }
`;

export const ResendToken = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #000;
  font-size: 0.9em;

  button {
    all: unset;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #000;
    font-size: 1.1em;
    margin-bottom: 20px;
    text-decoration: underline;

    @media (min-width: 768px) {
      font-size: 1.1em;
    }
  }
`;