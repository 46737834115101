import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { Municipality } from './interfaces';

interface Request {
  idState: string;
}

export default async ({ idState }: Request): Promise<Option[]> => {
  const { data } = await pluginApi.post<Municipality[]>(
    `/municipalities-states`,
    {
      id_estado: idState,
    },
  );
  return data.map<Option>(item => ({
    value: item.name_city,
    title: item.name_city,
  }));
};
