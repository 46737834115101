/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container, TypeRadioContent, TypeRadioContentGroup } from './styles';

interface InputGridCheckBoxProps {
  question: string;
  answers: AnswerData[];
  id: number;
  canEdit: boolean;
}

const InputGridCheckBox: React.FC<InputGridCheckBoxProps> = ({
  question,
  answers,
  id,
  canEdit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'unique' });
    }
  }, [answers, canEdit, dispatch, id]);

  return (
    <Container>
      <TypeRadioContent>
        <p>{question}</p>
        <TypeRadioContentGroup>
          {answers.map(answer => (
            <div key={answer.id}>
              <span>{answer.answer}</span>
              <label>
                <input
                  type="radio"
                  id={answer.answer}
                  value={answer.answer}
                  name={`${answer.survey_question_id}`}
                  onChange={e => {
                    dispatch(
                      setValueAnswer({
                        answerValue: e.target.value,
                        questionId: Number(id),
                        answerId: Number(answer.id),
                      }),
                    );
                  }}
                  disabled={!canEdit}
                  defaultChecked={answer.survey_participant_answers.some(
                    item => item.survey_question_answer_id === answer.id,
                  )}
                />
              </label>
            </div>
          ))}
        </TypeRadioContentGroup>
      </TypeRadioContent>
    </Container>
  );
};

export default InputGridCheckBox;
