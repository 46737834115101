import React, { useCallback } from 'react';
import Select, { SelectProps } from 'components/shared/Select';
import getData from 'services/address/getUfList';
import transformer from 'services/address/transformers/ufToSelectOptions';

interface Props extends Omit<SelectProps, 'loadItems'> {
  className?: string;
  label?: string;
  placeholder?: string;
  inputRole?: 'primary' | 'secondary';
  name: string;
  disabled?: boolean;
}

const UfsSelect: React.FC<Props> = ({
  name,
  className,
  label,
  placeholder,
  inputRole = 'primary',
  disabled = false,
  ...rest
}) => {
  const loadItems = useCallback(() => {
    return transformer(getData());
  }, []);

  return (
    <Select
      {...rest}
      name={name}
      label={label}
      loadItems={loadItems}
      className={className}
      placeholder={placeholder}
      inputRole={inputRole}
      disabled={disabled}
    />
  );
};

export default UfsSelect;
