import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation: ${fadeIn} 250ms ease-out;
  background-color: #efefef;
  padding: 12px;
  display: grid;
  grid-template-columns: 45px 1fr;
  align-items: center;
  gap: 15px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px #00000029;

  .alert--error {
    padding: 10px 0;
  }
`;

export const ParticipantInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  .name-and-selection {
    display: grid;
    grid-template-columns: 170px 42px;
    align-items: center;

    /** Checkbox */
    .MuiFormControlLabel-root {
      margin: 0;
      transform: translateX(10px);
    }
  }

  strong {
    font-size: 14px;
    width: 100%;
    color: ${({ theme }) => theme.font.color.primary};
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.3);
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    padding: 6px 5px;
    text-align: center;
  }
`;

export const Photo = styled.img`
  width: 45px;
  height: 45px;
  object-fit: cover;
  background-color: gray;
  border-radius: 999px;
`;
