import React, { useEffect, useState, useCallback } from 'react';
import Select, { Option } from 'components/shared/Select';

import getData from 'services/register/getSchoolingForSelect';

interface Props {
  name: string;
  label?: string;
  className?: string;
  inputRole?: 'primary' | 'secondary';
  disabled?: boolean;
}

const SchoolingSelect: React.FC<Props> = ({
  name,
  className,
  label,
  inputRole = 'primary',
  disabled = false,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData().then(data => setOptions(data));
  }, []);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <Select
      name={name}
      label={label}
      loadItems={loadItems}
      className={className}
      inputRole={inputRole}
      disabled={disabled}
    />
  );
};

export default SchoolingSelect;
