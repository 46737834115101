import { pluginApi } from 'services/api';

interface ApiResponse {
  url: string;
}

export default async (
  establishmentId: number,
  campaignId: number,
  campaignGroup: string,
): Promise<string> => {
  const {
    data: { url },
  } = await pluginApi.get<ApiResponse>(
    `premio-ideall/login-url?establishment_id=${establishmentId}&campaign_id=${campaignId}&establishment_type=${campaignGroup}`,
  );
  return url;
};
