import React from 'react';

import { MdSentimentDissatisfied as SadIcon } from 'react-icons/md';

import { Container } from './styles';

const EmptyList: React.FC = () => {
  return (
    <Container>
      <SadIcon size={24} />
      <h3>Sem resultados</h3>
    </Container>
  );
};

export default EmptyList;
