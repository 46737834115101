import React, { useCallback, useEffect, useState } from 'react';
import routeMap from 'routes/route-map';
import { useHistory } from 'react-router-dom';

import { usePoints } from 'pages/PointManagement/use-points';
import { Alert } from 'components/shared';
import ThemeLoading from 'components/shared/ThemeLoading';
import { getPointsAlreadyDistributed } from 'services/point-management/points-already-distributed';
import { uuid } from 'uuidv4';
import { TeamPoint } from './team-point';
import { Container, DistributedContainer } from './styles';
import { Point } from '../../../../services/point-management/dtos/points-distribution';
import { getTeamPointsDistribution } from '../../../../services/point-management/team-points-distributions';
import { DistributedPoint } from './distributed-point';

export const TeamPointsTab: React.FC = () => {
  const [selectedPointId, setSelectedPointId] = useState(0);

  const [pointsTeam, setPointsTeam] = useState<object>([]);

  const [pointsDistributed, setPointsDistributed] = useState<any>();

  const {
    fetchError,
    isFetching,
    isFetched,
    selectedEstablishment,
    selectedCampaign,
    points,
  } = usePoints();
  const history = useHistory();

  const pointsArrayLength = points && Object.values(points).length === 0;

  const fetchData = useCallback(async () => {
    const establishmentId =
      selectedEstablishment && selectedEstablishment.value !== null
        ? Number(selectedEstablishment.value)
        : 0;

    const response = await getTeamPointsDistribution({
      establishmentId,
      campaignId: selectedCampaign,
    });

    if (response) {
      setPointsTeam(response.distribution);
    }
  }, [selectedCampaign, selectedEstablishment]);

  useEffect(() => {
    fetchData();
  }, [selectedEstablishment, fetchData]);

  const getResume =
    pointsTeam && Object.values(pointsTeam).filter(y => y.resume);

  const handleDistribution = () => {
    if (!getResume.length) {
      const selectedPoint = Object.values(pointsTeam).find(
        point => point.undistributed_point_id === selectedPointId,
      );
      history.replace(routeMap.pointTeamDistribution, selectedPoint);
    } else {
      const selectedPoint = Object.values(pointsTeam).find(
        point =>
          point.undistributed_point_id === getResume[0].undistributed_point_id,
      );
      history.replace(routeMap.pointTeamDistribution, selectedPoint);
    }
  };

  useEffect(() => {
    const establishmentId =
      selectedEstablishment && selectedEstablishment.value !== null
        ? Number(selectedEstablishment.value)
        : 0;

    const pointsAlreadyDistributed = async () => {
      const response = await getPointsAlreadyDistributed({
        establishmentId,
        campaignId: selectedCampaign,
      });

      setPointsDistributed(response);
    };

    pointsAlreadyDistributed();
  }, [selectedCampaign, selectedEstablishment]);

  const pointsAl =
    pointsDistributed &&
    pointsDistributed.points &&
    pointsDistributed.points.flat().map((point: any) => point);

  return (
    <Container>
      <strong className="title">
        Selecione o ponto para carregar o saldo e fazer a distribuição:
      </strong>
      {fetchError && (
        <div className="error">
          <Alert variation="error">
            <span>Ocorreu um erro ao carregar seus pontos!</span>
          </Alert>
        </div>
      )}
      {isFetching && (
        <div className="loading-wrapper">
          <ThemeLoading size={24} />
        </div>
      )}
      {isFetched && pointsArrayLength && (
        <div className="loading-wrapper">
          <strong className="empty">
            Não há nenhum ponto para ser distribuído
          </strong>
        </div>
      )}

      {isFetched && !pointsArrayLength && (
        <>
          <div className="points-wrapper">
            {points &&
              Object.values(pointsTeam)
                .flat()
                .map((point: Point) => (
                  <TeamPoint
                    getSketch={
                      getResume && getResume[0] && getResume[0].resume.sketch
                    }
                    key={uuid()}
                    handleDistribution={handleDistribution}
                    selectedPointId={selectedPointId}
                    onSelect={setSelectedPointId}
                    pointsDistribution={points.disapproved}
                    selectedId={
                      !getResume.length
                        ? selectedPointId
                        : getResume[0].undistributed_point_id
                    }
                    point={point}
                  />
                ))}
          </div>
        </>
      )}

      <hr />

      <DistributedContainer>
        <strong className="title">Pontos já distribuídos</strong>
        {isFetched && !pointsDistributed ? (
          <div className="loading-wrapper left">
            <strong className="empty">Não há nenhum ponto distribuído</strong>
          </div>
        ) : (
          pointsDistributed &&
          Object.values(pointsAl)
            ?.flat()
            ?.map(point => (
              <DistributedPoint
                key={Math.random()}
                distributedPoint={point}
                pointsAl={point}
              />
            ))
        )}
      </DistributedContainer>
    </Container>
  );
};
