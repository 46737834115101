import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 500px;
    width: 100%;
    height: 100%;
    max-height: 320px;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: #fff;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  > h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.font.color.primary};
    text-align: center;
    padding: 0 20px;
    font-family: 'Helvetica-Neue-Bold';
    margin-top: 32px;
  }

  > h2 {
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.primary};
    text-align: center;
    padding: 0 20px;
    font-family: 'Helvetica-Neue-Bold';
    margin-top: 10px;
  }

  > button {
    width: 181px;
    height: 35px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 14px;
    transition: auto;
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: #000;
    }
  }
`;
