import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 750px;
    width: 100%;
    max-height: 750px;
  }
`;

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  > h4 {
    font-size: 21px;
    margin-left: 3em;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }

  > button {
    width: 232px;
    height: 48px;
    text-transform: uppercase;
    align-self: center;
    margin-bottom: 25px;
    border-radius: 5px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
  }

  .download-button {
    color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 12px;
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 15px;
    margin-right: 300px;
    width: 300px;

    svg {
      margin-right: 8px;
      width: 27px;
      height: 30px;
      path {
        fill: ${({ theme }) => theme.font.color.primary};
      }
    }
  }
`;

export const Content = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > span {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: ${({ theme }) => theme.font.color.secondary};
    margin-top: 10px;

    & + span {
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: ${({ theme }) => theme.font.color.primary};
      margin-top: 24px;
    }
  }
  @media screen and (max-width: 720px) {
    padding-left: 5px;
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button {
    border: none;
    background: transparent;
  }
`;

export const RegulationBox = styled.div`
  width: 100%;
  max-width: 600px;
  border: 1px solid ${({ theme }) => theme.font.color.quartenary};
  height: 40vh;
  color: ${({ theme }) => theme.font.color.quartenary};
  font-size: 14px;
  margin-top: 10px;
  overflow-y: auto;
`;
