// juntosfmc-adm.vendavall.com.br/admin/menus/site-app
const ALL_PRIVATE_ROUTES = [
  '/bibliotecas-juntos',
  '/campanhas',
  '/como-participar',
  '/indicacao-de-participantes',
  '/indicar-produtor',
  '/novidades',
  '/como-participar',
  '/regulamentos-e-termos',
  '/myextract',
  '/aprovacao-de-produtores',
  '/extrato-notas',
  '/extrato-sinos',
  '/produtor-extrato',
  '/cockpit',
  '/power-bi',
  '/relatorios',
  '/como-participar',
  '/planos-estrategicos',
  '/performance',
  '/pontos-canal',
  '/distribuicao-de-pontos',
  '/treinamentos',
  '/pesquisas',
  '/solucoes',
  '/catalogo-de-premios',
  '/gerenciamento-de-campanhas',
  '/visao-do-participante',
  '/simulador',
];

export const canAccess = (pathname: string, userRoutes: string[]) => {
  const isVendavallRoute = ALL_PRIVATE_ROUTES.find(
    vroute => vroute.startsWith(pathname) || pathname.startsWith(vroute),
  );
  const isAllowed = userRoutes.find(
    uroute => uroute.startsWith(pathname) || pathname.startsWith(uroute),
  );

  if (isVendavallRoute && isAllowed) return true;

  if (isVendavallRoute && !isAllowed) return false;

  if (!isVendavallRoute) return true;

  return true;
};
