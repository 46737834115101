import styled from 'styled-components';

export const Main = styled.main`
  padding: 0 25px;

  ._inputContainer {
    height: 42px;
    width: 40%;
  }

  .campaign-list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
`;

export const ContentSelect = styled.div`
  display: flex;
  align-items: center;

  > span:first-child {
    margin: 1rem;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
