const ufs = [
  { ibgeCode: 12, stateCode: 'AC', stateName: 'Acre' },
  { ibgeCode: 27, stateCode: 'AL', stateName: 'Alagoas' },
  { ibgeCode: 16, stateCode: 'AP', stateName: 'Amapá' },
  { ibgeCode: 13, stateCode: 'AM', stateName: 'Amazonas' },
  { ibgeCode: 29, stateCode: 'BA', stateName: 'Bahia' },
  { ibgeCode: 23, stateCode: 'CE', stateName: 'Ceará' },
  { ibgeCode: 53, stateCode: 'DF', stateName: 'Distrito Federal' },
  { ibgeCode: 32, stateCode: 'ES', stateName: 'Espírito Santo' },
  { ibgeCode: 52, stateCode: 'GO', stateName: 'Goiás' },
  { ibgeCode: 21, stateCode: 'MA', stateName: 'Maranhão' },
  { ibgeCode: 51, stateCode: 'MT', stateName: 'Mato Grosso' },
  { ibgeCode: 50, stateCode: 'MS', stateName: 'Mato Grosso do Sul' },
  { ibgeCode: 31, stateCode: 'MG', stateName: 'Minas Gerais' },
  { ibgeCode: 15, stateCode: 'PA', stateName: 'Pará' },
  { ibgeCode: 25, stateCode: 'PB', stateName: 'Paraíba' },
  { ibgeCode: 41, stateCode: 'PR', stateName: 'Paraná' },
  { ibgeCode: 26, stateCode: 'PE', stateName: 'Pernambuco' },
  { ibgeCode: 22, stateCode: 'PI', stateName: 'Piauí' },
  { ibgeCode: 33, stateCode: 'RJ', stateName: 'Rio de Janeiro' },
  { ibgeCode: 24, stateCode: 'RN', stateName: 'Rio Grande do Norte' },
  { ibgeCode: 43, stateCode: 'RS', stateName: 'Rio Grande do Sul' },
  { ibgeCode: 11, stateCode: 'RO', stateName: 'Rondônia' },
  { ibgeCode: 14, stateCode: 'RR', stateName: 'Roraima' },
  { ibgeCode: 42, stateCode: 'SC', stateName: 'Santa Catarina' },
  { ibgeCode: 35, stateCode: 'SP', stateName: 'São Paulo' },
  { ibgeCode: 28, stateCode: 'SE', stateName: 'Sergipe' },
  { ibgeCode: 17, stateCode: 'TO', stateName: 'Tocantins' },
];

export interface State {
  ibgeCode: number;
  stateCode: string;
  stateName: string;
}

export default (): State[] => ufs;
