import React from 'react';
import routeMap from 'routes/route-map';
import { useLocation, Redirect } from 'react-router-dom';
import { TeamProvider } from './use-team';
import { TeamDistributionPage } from './page';
import { TeamDistributionProvider } from './use-distribution';
import { PointDistributions } from '../../../services/point-management/dtos/points-distribution';

export const TeamDistribution: React.FC = () => {
  const { state } = useLocation<PointDistributions>();

  if (!state) {
    return <Redirect to={routeMap.pointDistribution} />;
  }

  return (
    <TeamProvider
      campaignId={state.campaign_id}
      establishmentId={state.establishment_id}
    >
      <TeamDistributionProvider point={state}>
        <TeamDistributionPage />
      </TeamDistributionProvider>
    </TeamProvider>
  );
};
