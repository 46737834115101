import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import DefaultButton from 'components/shared/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2c2b2b;
  height: 100%;

  span {
    margin: 25px;
    font-size: 15px;
    text-align: center;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 21px;
  margin-top: 35px;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 630px;
    width: 100%;
    min-height: 250px;
    max-height: 100vh !important;
    overflow-y: unset;
    padding: 0 30px;
  }
`;

export const Button = styled(DefaultButton)`
  width: 175px;
  height: 38px;
  background-color: ${({ theme }) => theme.footer.background};
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 40px;
`;
