import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Buffer } from 'buffer';

import TwoLoginModal from 'components/Auth/Modals/TwoLoginModal';
import { Establishment } from 'services/auth/signIn';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';

type Credentials = {
  cpf: string;
  password: string;
  tokenMfa?: string;
};

type Props = {
  isOpen: boolean;
  profiles: Establishment[];
  onClose: () => void;
  credentials: Credentials;
};

const ProfileAuth = ({ isOpen, onClose, profiles, credentials }: Props) => {
  const nextPage = localStorage.saveUrl;
  const history = useHistory();
  const { addToast } = useToast();
  const { signIn } = useAuth();
  const [userToken] = useState('');

  const handleProfileLogin = useCallback(
    async (establishment: Establishment) => {
      const cpf = localStorage.getItem('identifier');
      const password = localStorage.getItem('password');
      const { establishment: est, role } = establishment;

      if (!cpf || !password) {
        addToast({
          title: 'Credenciais inválidas',
          type: 'error',
        });
        return;
      }

      try {
        const encodedCpf = !est ? Buffer.from(cpf).toString('base64') : cpf;
        const encodedPassword = !est
          ? Buffer.from(password).toString('base64')
          : password;

        await signIn({
          cpf: encodedCpf,
          password: encodedPassword,
          establishment_id: est.id,
          role_id: role.id,
          tokenMfa: userToken,
        });

        localStorage.setItem('authToken', userToken);
        history.push(nextPage);
      } catch (e) {
        const message: string =
          e?.response?.data?.message || 'Falha ao fazer login';

        if (message.match(/Revenda/gi)) {
          history.push('/indiretos', 'resale');
          return;
        }
        if (message.match(/FMC/gi)) {
          history.push('/indiretos', 'produtor');
          return;
        }
        if (message.search(/Cooperativa/gi) >= 0) {
          history.push('/indiretos', 'cooperative');
          return;
        }

        addToast({
          title: message,
          type: 'error',
        });
      }
    },
    [addToast, nextPage, history, signIn, userToken],
  );

  return (
    <TwoLoginModal
      isOpen={isOpen}
      onRequestClose={onClose}
      establishments={profiles}
      selectProfileHandler={handleProfileLogin}
    />
  );
};

export default ProfileAuth;
