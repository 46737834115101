import { pluginApi } from 'services/api';
import { Channel } from './interfaces';
import { EstablishmentApi } from './interfaces/Campaign';

interface ApiResponse {
  data: EstablishmentApi[];
}

export default async (): Promise<Channel[]> => {
  const {
    data: { data },
  } = await pluginApi.get<ApiResponse>(
    'fmc-campaigns/establishments?limit=200',
  );

  return data;
};
