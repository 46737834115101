/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { GC, GRV } from 'config/constants';
import { CAMPAIGN_STATUS } from 'services/campaigns-manager/interfaces/Campaign';
// import { useSelector } from 'react-redux';
// import { getCampaign } from 'state/modules/campaigns-manager/selectors';
// import { useToast } from 'context/ToastContext';
import { Container, Separator, Button, Main } from './styles';

interface Props {
  sendButton(): void;
  saveDraftButton(): void;
  saveButton(): void;
  discardButton(): void;
  sendLoading: boolean;
  saveDraftLoading: boolean;
  saveLoading: boolean;
  discardLoading: boolean;
  children?: React.ReactNode;
  isCreator: boolean;
  profile: string;
  statusCampaign: number;
  showSendButton: boolean;
}

const ButtonsFooter: React.FC<Props> = ({
  sendButton,
  saveDraftButton,
  saveButton,
  discardButton,
  sendLoading,
  saveDraftLoading,
  saveLoading,
  discardLoading,
  isCreator,
  profile,
  statusCampaign,
  showSendButton,
}) => {
  const location = useLocation();
  const justView = profile === GC || profile === GRV;
  const newCampaign = location.pathname.includes('nova-campanha');
  // const campaign = useSelector(getCampaign);
  // const { addToast } = useToast();

  // const handleSave = useCallback(() => {
  //   if (campaign.startDate && campaign.endDate) {
  //     const startDate = new Date(campaign.startDate);
  //     const endDate = new Date(campaign.endDate);

  //     if (startDate.getTime() > endDate.getTime()) {
  //       addToast({
  //         title:
  //           'A data de início da sua campanha não pode ser maior que a data final',
  //         type: 'error',
  //       });
  //       return;
  //     }

  //     if (startDate.getTime() < Date.now()) {
  //       addToast({
  //         title:
  //           'A data de início da sua campanha não pode ser menor que a data atual',
  //         type: 'error',
  //       });
  //     }
  //   }
  // }, [addToast, campaign.startDate, campaign.endDate]);

  return (
    <Main>
      <Separator />
      {(!justView && statusCampaign === CAMPAIGN_STATUS.NEW_REQUEST_STATUS) ||
      statusCampaign === CAMPAIGN_STATUS.DEVELOPMENT_STATUS ||
      (newCampaign && isCreator) ? (
        <Container>
          {!newCampaign && (
            <button
              className="simple-button"
              type="button"
              onClick={discardButton}
            >
              {discardLoading ? (
                <ReactLoading
                  type="bars"
                  color="#8d8d8d"
                  height={10}
                  width={10}
                />
              ) : (
                'Descartar'
              )}
            </button>
          )}
          <Button
            buttonRole="primary"
            type="button"
            onClick={saveDraftButton}
            loading={saveDraftLoading}
          >
            Salvar Rascunho
          </Button>
          {showSendButton && (
            <Button
              buttonRole="primary"
              type="button"
              onClick={sendButton}
              loading={sendLoading}
            >
              Enviar Solicitação
            </Button>
          )}
        </Container>
      ) : (
        <>
          {statusCampaign === CAMPAIGN_STATUS.UNDER_ANALYSIS_STATUS &&
            !isCreator &&
            !justView && (
              <div>
                <Button
                  buttonRole="primary"
                  type="button"
                  className="btn-primary"
                  onClick={saveButton}
                  loading={saveLoading}
                >
                  Salvar
                </Button>
              </div>
            )}
        </>
      )}
    </Main>
  );
};

export default ButtonsFooter;
