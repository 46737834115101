import { ActionTypeName } from 'config/constants';

import { strategyPlanStatus } from '../constants';

const getActionTypeName = (id?: number): string => {
  const identifierMap: { [key: number]: string } = {
    1: ActionTypeName.fieldDay,
    2: ActionTypeName.training,
    3: ActionTypeName.speeches,
    4: ActionTypeName.otherDevelopmentProducts,
    5: ActionTypeName.events,
    7: ActionTypeName.leadershipVisits,
    8: ActionTypeName.regionInfluencers,
    9: ActionTypeName.otherRelationshipActions,
    10: ActionTypeName.topFarmers,
    11: ActionTypeName.strategicVision,
  };

  return identifierMap[id ?? 0] || 'other';
};

const getTypeHiringName = (id?: number): string => {
  const typeHiring: { [key: number]: string } = {
    1: 'Compartilhado com o canal',
    2: 'Via Fmc',
  };

  return typeHiring[id ?? 0] || '';
};

const getStatusText = (status: number): string => {
  switch (status) {
    case strategyPlanStatus.planning:
      return 'Em Planejamento';
    case strategyPlanStatus.onApproval:
      return 'Em Aprovação';
    case strategyPlanStatus.inAdjustments:
      return 'Em Ajustes';
    case strategyPlanStatus.approved:
      return 'Aprovado';
    case strategyPlanStatus.outOfTime:
      return 'Fora do Prazo';

    default:
      return '';
  }
};

export { getStatusText, getActionTypeName, getTypeHiringName };
