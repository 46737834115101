import React from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';

import { Establishment } from 'services/auth/signIn';
import {
  Modal,
  Container,
  BtnContainer,
  Close,
  Button,
  ButtonProdutor,
  Title,
} from './style';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  establishments: Establishment[];
  selectProfileHandler(estableshiment: Establishment): void;
}

const TwoLoginModal: React.FC<Props> = ({
  establishments,
  isOpen,
  onRequestClose,
  selectProfileHandler,
}) => {
  const [producer, indirect] = establishments;

  const isResale = indirect?.establishment?.type?.name === 'Revenda';

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Container>
        <Title> Acessar qual perfil?</Title>
        <BtnContainer>
          <Button
            type="button"
            buttonRole="tertiary"
            onClick={() => selectProfileHandler(indirect)}
            isResale={isResale}
          >
            ACESSAR MEU PERFIL NO JUNTOS {isResale ? 'REVENDA' : 'COOPERATIVA'}
          </Button>
          <ButtonProdutor
            type="button"
            buttonRole="secondary"
            onClick={() => selectProfileHandler(producer)}
          >
            ACESSAR MEU PERFIL NO JUNTOS PRODUTOR
          </ButtonProdutor>
        </BtnContainer>
      </Container>
    </Modal>
  );
};

export default TwoLoginModal;
