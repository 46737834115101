import React, { useMemo } from 'react';
import { IconBaseProps } from 'react-icons';
import SelectBase from '../../shared/Select/BaseSelect'; // Importe o BaseSelect existente, se necessário

export interface Option {
  value: string;
  title: string;
  label?: string;
  Grupo?: string;
  regional?: any;
  category?: any;
}

export interface CustomSelectProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: string;
  className?: string;
  loadItems(search: string): Option[] | Promise<Option[]>;
  inputRole?: 'primary' | 'secondary';
  placeholder?: string;
  disabled?: boolean;
  value: Option | null; // Garantir que value seja Option | null
  onChange?: (selectedOption: Option | null) => void; // Propriedade onChange personalizada
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  icon: Icon,
  className,
  label,
  loadItems,
  inputRole = 'primary',
  placeholder = '',
  disabled = false,
  value,
  onChange, // Propriedade onChange
}) => {
 // const [error, setError] = useState('');

  return useMemo(
    () => (
      <SelectBase
        icon={Icon}
        label={label}
        className={className}
        inputRole={inputRole}
        placeholder={placeholder}
        disabled={disabled}
       // error={error}
        value={value}
        setValue={(newValue: Option | null) => {
          if (onChange) {
            onChange(newValue); // Chama a função onChange, se fornecida
          }
        }}
        loadItems={loadItems}
      />
    ),
    [
    //  name,
      Icon,
      label,
      className,
      inputRole,
      placeholder,
      disabled,
     // error,
      value,
      loadItems,
      onChange, // Incluído no array de dependências
    ],
  );
};

export default CustomSelect;