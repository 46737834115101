import styled, { css } from 'styled-components';
import { ReactSVG as DefaultReactSVG } from 'react-svg';
import DefaultChannelSelect from '../CustomSelects/Channels';
import DefaultProductTypeSelect from '../CustomSelects/ProductTypes';

export const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin: 20px;
  }

  @media screen and (max-width: 850px) {
    margin: 20px;
  }

  @media screen and (max-width: 425px) {
    margin: 20px;
  }
`;

export const FirstBox = styled.div`
  display: flex;
  width: 100%;

  > h3 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 24px;
    color: ${({ theme }) => theme.font.color.primary};
    width: 100%;
    max-width: 439px;
    padding-top: 23px;

    @media screen and (max-width: 425px) {
      margin: 0 0 0 15px;
    }
  }

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 40px;

    > span {
      font-size: 16px;
      color: ${({ theme }) => theme.font.color.primary};

      @media screen and (max-width: 425px) {
        font-size: 14px;
        margin-bottom: -10px;
      }
    }

    @media screen and (max-width: 425px) {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      margin: 0 0 80px 20px;
    }
  }

  @media screen and (max-width: 425px) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    /* margin: 40px; */
  }
`;

export const ReactSVG = styled(DefaultReactSVG)`
  cursor: pointer;

  @media screen and (max-width: 425px) {
    display: none;
    /* margin-left: 8px; */
  }
`;

export const SecondBox = styled.div`
  align-items: center;
  background-color: #ccc;
  border-radius: 5px;
  display: flex;
  margin: 40px 0 28px 0;
  padding: 10px;
  width: 25%;

  span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 16px;
    margin-left: 10px;
  }

  img {
    margin-left: 15px;
  }

  @media screen and (max-width: 425px) {
    margin: 65px 0 0 16px;
    width: 60%;

    span {
      font-size: 14px;
    }

    img {
      margin-left: 10px;
    }
  }
`;

export const ThirdBox = styled.div`
  display: flex;
  margin-top: 24px;

  @media screen and (max-width: 425px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const CustomText = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.font.color.primary};
  margin-right: 12px;

  @media screen and (max-width: 425px) {
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const ChannelSelect = styled(DefaultChannelSelect)`
  max-width: 248px;
  height: 40px;
  margin-right: 34px;

  ._inputContainer {
    max-width: 267px;
    height: 40px;

    @media screen and (max-width: 425px) {
      margin-left: -12px;
    }
  }
`;

export const ProductTypeSelect = styled(DefaultProductTypeSelect)`
  max-width: 202px;
  margin-left: 93px;
  height: 40px;

  ._inputContainer {
    max-width: 202px;
    height: 40px;
  }

  @media screen and (max-width: 425px) {
    margin-left: 18px;
    margin-top: -10px;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  max-width: 466px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  background: #efefef;

  @media screen and (max-width: 425px) {
    margin-top: 42px;
  }
`;

interface ItemProps {
  selected: boolean;
}

export const Item = styled.div<ItemProps>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    font-size: 14px;
    text-align: center;
    color: ${({ theme }) => theme.font.color.primary};
  }

  &:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
  }

  &:nth-child(3) {
    border-radius: 0px 5px 5px 0px;
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      background: ${theme.font.color.primary};

      > span {
        color: #fff;
      }
    `}

  @media screen and (max-width: 425px) {
    padding: 5px;

    > span {
      font-size: 12px;
    }
  }
`;

export const SimulationsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;

  @media screen and (max-width: 425px) {
    justify-content: flex-start;
  }
`;

export const CategoryContainer = styled.div`
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.font.color.quartenary};

  > span {
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.quartenary};
    text-transform: uppercase;
    font-weight: 600;
  }

  /* @media screen and (max-width: 425px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 40px;
    max-width: 466px;
    height: 40px;
  } */
`;
