import {
  Channel as ChannelApi,
  Product as ProductApi,
  Indicators as IndicatorsApi,
  Configuration as ConfigurationApi,
} from 'services/points-simulator/interfaces/api-interface';
import getPercentage from './services/get-goal-realized-percentage';
import {
  Channel,
  Product,
  Indicator,
  IndicatorType,
  UnitType,
  Configuration,
} from './interfaces';

const channelApiToChannel = (channel: ChannelApi): Channel => ({
  id: channel.id,
  category: channel.category,
  code: channel.client_code,
  groupName: channel.client_group,
  name: channel.name,
  type: channel.type,
  isBiological: channel.biological,
  hasAdditionalMargin: channel.additional_margin,
});

const productsApiToProducts = (products: ProductApi[]): Product[] =>
  products.map(product => ({
    id: product.id,
    checked: false,
    name: product.name,
    isEnhancer: product.is_enhancer,
    isParticipatingProduct: product.is_a_participating_product,
    isBiological: product.biological,
    isTerraBrasil: product.is_terra_brasil,
    enhancerSegment: product.enhancer_segment,
    cropEnhancer: product.crop_enhancer,
    type: {
      id: product.segment_id,
      name: product.segment,
    },
    revenues: {
      goalInDollar: product.revenues_goal_in_dollar,
      realizedInDollar: product.revenues_realized_in_dollar,
      goalInKilosByLiter: product.revenues_goal_in_kilos_by_liter,
      realizedInKilosByLiter: product.revenues_realized_in_kilos_by_liter,
    },
    pog: {
      goalInDollar: product.pog_goal_in_dollar,
      realizedInDollar: product.pog_realized_in_dollar,
      goalInKilosByLiter: product.pog_goal_in_kilos_by_liter,
      realizedInKilosByLiter: product.pog_realized_in_kilos_by_liter,
    },
    stock: {
      inKilosPerLiter: product.stock_in_kg_per_liter,
      inDollar: product.stock_in_dolar,
    },
    simulationData: {
      unitValueInDollar: 0,
      revenuesInKilosPerLiter: 0,
      revenuesInDollar: 0,
      pogInKilosPerLiter: 0,
      pogInDollar: 0,
      pogUnitValueInDollar: 0,
      pogRealizedNetInDollarSimulated: 0,
      pogRealizedNetInRealSimulated: 0,
      pogRealizedNetInDollarTotal: 0,
      pogRealizedNetInRealTotal: 0,
    },
    simulationPoints: {
      rebateReachedInRealSimulated: 0,
      rebateReachedInRealSimulatedButUsedToGetTotal: 0,
      rebateReachedInRealRealized: 0,
      rebateReachedInRealTotal: 0,
      sellerReachedInRealSimulated: 0,
      sellerReachedInRealSimulatedButUsedToGetTotal: 0,
      sellerReachedInRealRealized: 0,
      sellerReachedInRealTotal: 0,
      additionalMarginSimulated: 0,
      additionalMarginRealized: 0,
      additionalMarginTotal: 0,
      sellerBiologicalReachedInRealRealized: 0,
      sellerBiologicalReachedInRealSimulated: 0,
      sellerBiologicalReachedInRealSimulatedButUsedToGetTotal: 0,
      sellerBiologicalReachedInRealTotal: 0,
      rebateBiologicalReachedInRealRealized: 0,
      rebateBiologicalReachedInRealSimulated: 0,
      rebateBiologicalReachedInRealSimulatedButUsedToGetTotal: 0,
      rebateBiologicalReachedInRealTotal: 0,
    },
    awardsParamsToPay: {
      rebatePercentage: product.rebate_percentage_to_pay * 100,
      rebatePercentageBiological:
        product.rebate_percentage_to_pay_biological * 100,
      sellerValueInReal: product.seller_value_in_real_to_pay,
      sellerValueInRealBiological:
        product.seller_value_in_real_to_pay_biological,
      additionalMarginPercentage:
        product.additional_margin_percentage_to_pay * 100,
      additionalMarginPercentageBiological:
        product.additional_margin_percentage_biological,
    },
    extraPercentageToPayByEnhancerProduct:
      product.extra_percentage_to_pay_per_enhancer_product * 100,
  }));

const indicatorsApiToIndicators = (indicators: IndicatorsApi): Indicator[] => {
  const result: Indicator[] = [];

  result.push({
    title: 'Faturamento',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.revenues,
    unitType: UnitType.dollar,
    lastRealized: indicators.revenues_last_realized_in_dollar,
    currentRealized: indicators.revenues_current_realized_in_dollar,
    isRegisteredProduct: false,
    currentGoal: indicators.revenues_current_goal_in_dollar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.revenues_current_goal_in_dollar,
      indicators.revenues_current_realized_in_dollar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.revenues_current_goal_in_dollar,
        indicators.revenues_current_realized_in_dollar,
      ),
      totalRealized: indicators.revenues_current_realized_in_dollar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'POG',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.pog,
    unitType: UnitType.dollar,
    lastRealized: indicators.pog_last_realized_in_dollar,
    currentRealized: indicators.pog_current_realized_in_dollar,
    isRegisteredProduct: false,
    currentGoal: indicators.pog_current_goal_in_dollar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.pog_current_goal_in_dollar,
      indicators.pog_current_realized_in_dollar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.pog_current_goal_in_dollar,
        indicators.pog_current_realized_in_dollar,
      ),
      totalRealized: indicators.pog_current_realized_in_dollar,
      totalSimulated: 0,
    },
  });

  // result.push({
  //   title: 'Aurora',
  //   isBiological: false,
  //   isTerraBrasil: false,
  //   type: IndicatorType.aurora,
  //   unitType: UnitType.liter,
  //   lastRealized: indicators.aurora_last_realized_in_Liter,
  //   currentRealized: indicators.aurora_current_realized_in_Liter,
  //   isRegisteredProduct: true,
  //   currentGoal: indicators.aurora_current_goal_in_Liter,
  //   currentYear: indicators.current_campaign,
  //   lastYear: indicators.last_campaign,
  //   percentageRealized: getPercentage(
  //     indicators.aurora_current_goal_in_Liter,
  //     indicators.aurora_current_realized_in_Liter,
  //   ),
  //   simulationData: {
  //     totalPercentageRealized: getPercentage(
  //       indicators.aurora_current_goal_in_Liter,
  //       indicators.aurora_current_realized_in_Liter,
  //     ),
  //     totalRealized: indicators.aurora_current_realized_in_Liter,
  //     totalSimulated: 0,
  //   },
  // });

  // result.push({
  //   title: 'Hero',
  //   isBiological: false,
  //   isTerraBrasil: false,
  //   type: IndicatorType.hero,
  //   unitType: UnitType.liter,
  //   lastRealized: indicators.hero_last_realized_in_Liter,
  //   currentRealized: indicators.hero_current_realized_in_Liter,
  //   isRegisteredProduct: true,
  //   currentGoal: indicators.hero_current_goal_in_Liter,
  //   currentYear: indicators.current_campaign,
  //   lastYear: indicators.last_campaign,
  //   percentageRealized: getPercentage(
  //     indicators.hero_current_goal_in_Liter,
  //     indicators.hero_current_realized_in_Liter,
  //   ),
  //   simulationData: {
  //     totalPercentageRealized: getPercentage(
  //       indicators.hero_current_goal_in_Liter,
  //       indicators.hero_current_realized_in_Liter,
  //     ),
  //     totalRealized: indicators.hero_current_realized_in_Liter,
  //     totalSimulated: 0,
  //   },
  // });

  // result.push({
  //   title: 'Talisman',
  //   isBiological: false,
  //   isTerraBrasil: false,
  //   type: IndicatorType.talisman,
  //   unitType: UnitType.liter,
  //   lastRealized: indicators.talisman_last_realized_in_Liter,
  //   currentRealized: indicators.talisman_current_realized_in_Liter,
  //   isRegisteredProduct: true,
  //   currentGoal: indicators.talisman_current_goal_in_Liter,
  //   currentYear: indicators.current_campaign,
  //   lastYear: indicators.last_campaign,
  //   percentageRealized: getPercentage(
  //     indicators.talisman_current_goal_in_Liter,
  //     indicators.talisman_current_realized_in_Liter,
  //   ),
  //   simulationData: {
  //     totalPercentageRealized: getPercentage(
  //       indicators.talisman_current_goal_in_Liter,
  //       indicators.talisman_current_realized_in_Liter,
  //     ),
  //     totalRealized: indicators.talisman_current_realized_in_Liter,
  //     totalSimulated: 0,
  //   },
  // });

  result.push({
    title: 'FUNGICIDAS',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.fungicidas,
    unitType: UnitType.liter,
    lastRealized: indicators.fungicidas_last_realized_in_dollar,
    currentRealized: indicators.fungicidas_pog_realized_total_dolar,
    isRegisteredProduct: true,
    currentGoal: indicators.fungicidas_pog_meta_total_dolar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.fungicidas_pog_meta_total_dolar,
      indicators.fungicidas_pog_realized_total_dolar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.fungicidas_pog_meta_total_dolar,
        indicators.fungicidas_pog_realized_total_dolar,
      ),
      totalRealized: indicators.fungicidas_pog_realized_total_dolar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'HERBICIDAS',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.herbicidas,
    unitType: UnitType.liter,
    lastRealized: indicators.herbicidas_last_realized_in_dollar,
    currentRealized: indicators.herbicidas_pog_realized_total_dolar,
    isRegisteredProduct: true,
    currentGoal: indicators.herbicidas_pog_meta_total_dolar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.herbicidas_pog_meta_total_dolar,
      indicators.herbicidas_pog_realized_total_dolar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.herbicidas_pog_meta_total_dolar,
        indicators.herbicidas_pog_realized_total_dolar,
      ),
      totalRealized: indicators.herbicidas_pog_realized_total_dolar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'INSETICIDAS',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.inseticidas,
    unitType: UnitType.liter,
    lastRealized: indicators.inseticidas_last_realized_in_dollar,
    currentRealized: indicators.inseticidas_pog_realized_total_dolar,
    isRegisteredProduct: true,
    currentGoal: indicators.inseticidas_pog_meta_total_dolar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.inseticidas_pog_meta_total_dolar,
      indicators.inseticidas_pog_realized_total_dolar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.inseticidas_pog_meta_total_dolar,
        indicators.inseticidas_pog_realized_total_dolar,
      ),
      totalRealized: indicators.inseticidas_pog_realized_total_dolar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'INSETICIDAS BY RYNAXYPYR',
    isBiological: false,
    isTerraBrasil: false,
    type: IndicatorType.inseticidasByRynaxypyr,
    unitType: UnitType.liter,
    lastRealized: indicators.inseticidas_by_rynaxypyr_last_realized_in_dollar,
    currentRealized:
      indicators.inseticidas_by_rynaxypyr_pog_realized_total_dolar,
    isRegisteredProduct: true,
    currentGoal: indicators.inseticidas_by_rynaxypyr_pog_meta_total_dolar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.inseticidas_by_rynaxypyr_pog_meta_total_dolar,
      indicators.inseticidas_by_rynaxypyr_pog_realized_total_dolar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.inseticidas_by_rynaxypyr_pog_meta_total_dolar,
        indicators.inseticidas_by_rynaxypyr_pog_realized_total_dolar,
      ),
      totalRealized:
        indicators.inseticidas_by_rynaxypyr_pog_realized_total_dolar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Faturamento Biológico',
    isBiological: true,
    isTerraBrasil: false,
    type: IndicatorType.revenuesBiological,
    unitType: UnitType.dollar,
    lastRealized: indicators.revenues_biological_last_realized_in_dollar,
    currentRealized: indicators.revenues_biological_current_realized_in_dollar,
    isRegisteredProduct: true,
    currentGoal: indicators.revenues_biological_current_goal_in_dollar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.revenues_biological_current_goal_in_dollar,
      indicators.revenues_biological_current_realized_in_dollar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.revenues_biological_current_goal_in_dollar,
        indicators.revenues_biological_current_realized_in_dollar,
      ),
      totalRealized: indicators.revenues_biological_current_goal_in_dollar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Pog Biológico',
    isBiological: true,
    isTerraBrasil: false,
    type: IndicatorType.pogBiological,
    unitType: UnitType.dollar,
    lastRealized: indicators.pog_biological_last_realized_in_dollar,
    currentRealized: indicators.pog_biological_current_realized_in_dollar,
    isRegisteredProduct: true,
    currentGoal: indicators.pog_biological_current_goal_in_dollar,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.pog_biological_current_goal_in_dollar,
      indicators.pog_biological_current_realized_in_dollar,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.pog_biological_current_goal_in_dollar,
        indicators.pog_biological_current_realized_in_dollar,
      ),
      totalRealized: indicators.pog_biological_current_realized_in_dollar,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Verimark',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.veriMark,
    unitType: UnitType.liter,
    lastRealized: indicators.verimark_last_realized_in_Liter || 0,
    currentRealized: indicators.verimark_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.verimark_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.verimark_current_goal_in_Liter || 0,
      indicators.verimark_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.verimark_current_goal_in_Liter || 0,
        indicators.verimark_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.verimark_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Stone',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.stone,
    unitType: UnitType.liter,
    lastRealized: indicators.stone_last_realized_in_Liter || 0,
    currentRealized: indicators.stone_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.stone_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.stone_current_goal_in_Liter || 0,
      indicators.stone_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.stone_current_goal_in_Liter || 0,
        indicators.stone_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.stone_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Prêmio Star',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.premioStar,
    unitType: UnitType.liter,
    lastRealized: indicators.premio_star_last_realized_in_Liter || 0,
    currentRealized: indicators.premio_star_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.premio_star_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.premio_star_current_goal_in_Liter || 0,

      indicators.premio_star_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.premio_star_current_goal_in_Liter || 0,

        indicators.premio_star_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.premio_star_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Altacor',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.altacor,
    unitType: UnitType.liter,
    lastRealized: indicators.altacor_last_realized_in_Liter || 0,
    currentRealized: indicators.altacor_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.altacor_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.altacor_current_goal_in_Liter || 0,
      indicators.altacor_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.altacor_current_goal_in_Liter || 0,
        indicators.altacor_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.altacor_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Boral Full',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.boralFull,
    unitType: UnitType.liter,
    lastRealized: indicators.boralfull_last_realized_in_Liter || 0,
    currentRealized: indicators.boralfull_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.boralfull_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.boralfull_current_goal_in_Liter || 0,
      indicators.boralfull_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.boralfull_current_goal_in_Liter || 0,
        indicators.boralfull_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.boralfull_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  result.push({
    title: 'Quartzo',
    isBiological: false,
    isTerraBrasil: true,
    type: IndicatorType.quartzo,
    unitType: UnitType.liter,
    lastRealized: indicators.quartzo_last_realized_in_Liter || 0,
    currentRealized: indicators.quartzo_current_realized_in_Liter || 0,
    isRegisteredProduct: true,
    currentGoal: indicators.quartzo_current_goal_in_Liter || 0,
    currentYear: indicators.current_campaign,
    lastYear: indicators.last_campaign,
    percentageRealized: getPercentage(
      indicators.quartzo_current_goal_in_Liter || 0,
      indicators.quartzo_current_realized_in_Liter || 0,
    ),
    simulationData: {
      totalPercentageRealized: getPercentage(
        indicators.quartzo_current_goal_in_Liter || 0,
        indicators.quartzo_current_realized_in_Liter || 0,
      ),
      totalRealized: indicators.quartzo_current_realized_in_Liter || 0,
      totalSimulated: 0,
    },
  });

  return result;
};

const configurationApiToConfiguration = (
  configuration: ConfigurationApi,
): Configuration => {
  return {
    partialDate: configuration.partial_date,
    partialDateInvoicing: configuration.partial_date_invoicing,
    partialDatePog: configuration.partial_date_pog,
    percent_to_pay_rebate: configuration.percent_to_pay.percent_to_pay_rebate.map(
      item => ({
        balance_type_name: item.balance_type_name,
        value: item.value,
        pay_percent: item.pay_percent,
      }),
    ),
    percent_to_pay_seller: configuration.percent_to_pay.percent_to_pay_seller.map(
      item => ({
        balance_type_name: item.balance_type_name,
        value: item.value,
        pay_percent: item.pay_percent,
      }),
    ),
    percent_to_pay_biological: configuration.percent_to_pay.percent_to_pay_biological.map(
      item => ({
        balance_type_name: item.balance_type_name,
        value: item.value,
        pay_percent: item.pay_percent,
      }),
    ),

    pogRealizedNetPercentage:
      100 - configuration.pog_realized_net_percentage * 100,
    minimumRebatePercentageToMakePoints:
      configuration.minimum_rebate_percentage_to_participate * 100,
    minimumSellerPercentageToMakePoints:
      configuration.minimum_seller_percentage_to_participate * 100,
    minimumBiologicalPercentageToMakePoints:
      configuration.minimum_biological_percentage_to_participate * 100,
    biologicalSimulationValue: configuration.biological_simulation_value,
      finalCalculation: configuration.final_calculation,
  };
};

export {
  channelApiToChannel,
  productsApiToProducts,
  indicatorsApiToIndicators,
  configurationApiToConfiguration,
};
