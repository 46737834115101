import React, { useCallback } from 'react';
import routeMap from 'routes/route-map';
import history from 'services/history';

import { Modal, Container, BtnContainer, Button } from './style';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
}

const CompleteProducerRegister: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const onClickHandler = useCallback(() => {
    history.push(routeMap.completeProducerRegistration);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} type="primary">
        <Container>
          <h3>
            Verificamos que você precisa completar seu cadastro no JUNTOS
            Produtores. <br /> <br />
            Deseja completar agora?
          </h3>
        </Container>
        <BtnContainer>
          <Button type="button" buttonRole="primary" onClick={onClickHandler}>
            Sim
          </Button>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            Não
          </Button>
        </BtnContainer>
      </Modal>
    </>
  );
};

export default CompleteProducerRegister;
