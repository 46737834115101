import React, { useCallback, useMemo } from 'react';

import { Comment } from 'services/strategic-plans/interfaces';
import CloseButton from 'components/shared/CloseButton';
import { FaRegCommentDots as CommentIcon } from 'react-icons/fa';
import CommentForm from './Form';
import CommentList from './Comments';

import {
  Container,
  CustomModal as Modal,
  Header,
  CommentsListWrapper,
  CommentWrapper,
} from './styles';

interface NegotiationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedUuids?: string[];
  onSuccess?: () => void;
  comments?: Comment[];
  onlyShow?: boolean;
}

const NegotiationModal: React.FC<NegotiationModalProps> = ({
  isOpen,
  onRequestClose,
  selectedUuids = [],
  onSuccess,
  comments = [],
  onlyShow,
}) => {
  const onSuccessHandler = useCallback(() => {
    if (onSuccess) onSuccess();

    onRequestClose();
  }, [onRequestClose, onSuccess]);

  const validComments = useMemo(() => comments.filter(item => item.comment), [
    comments,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <CloseButton onClickHandler={onRequestClose} />
      <Container>
        <Header>
          <CommentIcon size={42} />
          <h3>Solicitação de Ajustes</h3>
        </Header>
        <CommentsListWrapper>
          <p>Comentário:</p>
          <CommentList comments={validComments} />
        </CommentsListWrapper>
        <CommentWrapper>
          {!onlyShow && (
            <CommentForm
              onConfirmComment={onSuccessHandler}
              selectedUuids={selectedUuids}
            />
          )}
        </CommentWrapper>
      </Container>
    </Modal>
  );
};

export default NegotiationModal;
