import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

// Initialize Google Tag Manager
if (gtmId) {
  TagManager.initialize({ gtmId });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
