import React, { useState, useCallback, useEffect } from 'react';
import { Option } from 'components/shared/Select';
import { getStrategyActionTypes } from 'services/strategic-plans/actions';
import { BaseSelect } from './styles';

interface ActionTypesProps {
  className?: string;
  label?: string;
  placeholder?: string;
  inputRole: 'primary' | 'secondary';
  name: string;
  disabled: boolean;
  setValue(value: Option | null): void;
  value: Option | null;
  defaultValue?: string;
}

const ActionTypes: React.FC<ActionTypesProps> = ({
  label,
  className,
  placeholder,
  inputRole,
  name,
  disabled,
  value,
  setValue,
  defaultValue,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const fetchActionTypes = async () => {
      const data = await getStrategyActionTypes();
      const searchActionTypes = data.map(typeName => ({
        value: typeName.value,
        title: typeName.title,
      }));
      setOptions(searchActionTypes);
    };

    fetchActionTypes();
  }, []);

  useEffect(() => {
    if (!defaultValue) {
      setValue(value);
    }
  }, [defaultValue, setValue, value]);

  return (
    <BaseSelect
      name={name}
      label={label}
      loadItems={loadItems}
      className={className}
      inputRole={inputRole}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      setValue={setValue}
    />
  );
};

export default ActionTypes;
