import { pluginApi } from 'services/api';

interface Response {
  download_url: string;
}

export default async (): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    '/reports/strategy-plans-to-approve',
  );

  return data.download_url;
};
