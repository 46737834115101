import React from 'react';

import { formatPoints } from 'util/points';
import {
  Product,
  Performance as IPerformance,
  AdditionalMargin,
} from 'state/modules/home/types';
import { ALLOWED_ASK_APPROVAL_PROFILES as ALLOWED_PROFILES } from 'config/constants';
import { useAuth } from 'context/AuthContext';
import Title from 'components/Home/Title';
import CircularProgress from './CircularProgress';
import {
  IndividualProgressWrapper,
  Label,
  GoalText,
  List,
  AllBox,
  BoxForAllWatches,
  WatchBox,
  Separator,
  LinkBox,
} from './styles';

interface Props {
  realized: IPerformance;
  products: Product[];
  additionalMargin: AdditionalMargin;
}

const Performance: React.FC<Props> = ({
  realized,
  products,
  additionalMargin,
}) => {
  const {
    participant: { profile_value },
  } = useAuth();

  const { revenues: _revenues, pog: _pog } = realized;
  const revenues = {
    goal: _revenues?.goal ? formatPoints(_revenues.goal) : null,
    reached: _revenues?.reached ? formatPoints(_revenues.reached) : null,
    percentage: _revenues?.percentage ? _revenues.percentage : 0,
  };

  const pog = {
    goal: _pog?.goal ? formatPoints(_pog.goal) : null,
    reached: _pog?.reached ? formatPoints(_pog.reached) : null,
    percentage: _pog?.percentage ? _pog.percentage : 0,
  };
  const orderProducts = products
    ? products.sort((a, b) => a.pcp.position - b.pcp.position)
    : null;

  return (
    <>
      <Title>Performance</Title>

      <AllBox>
        <BoxForAllWatches>
          <WatchBox>
            <Label>FATURAMENTO</Label>
            <GoalText>Objetivo US$ {revenues.goal}</GoalText>
            <GoalText>Realizado US$ {revenues.reached}</GoalText>
            <CircularProgress
              color="#FF4C16"
              percent={revenues.percentage || 0}
            />
          </WatchBox>

          <WatchBox>
            <Label>POG</Label>
            <GoalText>Objetivo US$ {pog.goal}</GoalText>
            <GoalText>Realizado US$ {pog.reached}</GoalText>
            <CircularProgress color="#25CCE1" percent={pog.percentage || 0} />
          </WatchBox>

          {products && products.length > 0 && <Separator />}

          {ALLOWED_PROFILES.includes(profile_value) &&
            orderProducts &&
            orderProducts.length > 0 &&
            orderProducts.map(product => (
              <WatchBox>
                <List key={product.pcp.position}>
                  <IndividualProgressWrapper>
                    <Label>{product.name}</Label>
                    <GoalText>
                      Objetivo US$ {formatPoints(product.goal)}
                    </GoalText>
                    <GoalText>
                      Realizado US$ {formatPoints(product.result)}
                    </GoalText>
                    <CircularProgress
                      color={product.pcp.color}
                      percent={product.percentage || 0}
                    />
                  </IndividualProgressWrapper>
                </List>
              </WatchBox>
            ))}
        </BoxForAllWatches>
        <LinkBox>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a className="link-cockipt" href="/cockpit">
              ACESSE O COOCKPIT
            </a>
          </div>
        </LinkBox>
      </AllBox>
    </>
  );
};

export default Performance;
