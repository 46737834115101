import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from 'context/AuthContext';
import {
  KAM,
  RTC,
  GRV,
  GRM,
  DM,
  RTCPOG,
  EstablishmentTypes,
} from 'config/constants';

import {
  getChannelCampaignPerformanceLink,
  getProductsPerformanceLink,
  getParticipantsLogLink,
  getInvoicesReportLink,
  getProducersRescuesReportLink,
  getIndirectsRescuesReportLink,
  getBiologicalReport,
} from 'services/dashboard/reports';

import CampaignsList, {
  CampaignsListHandle,
} from 'components/Dashboards/CampaignsList';
import getEstablishments, {
  Establishment,
} from 'services/auth/getEstablishments';
import Reports, { Report as IReport } from './Reports';
import Charts from './Charts';

import {
  Container,
  Content,
  TabWrapper,
  TabsList,
  Tab,
  PanelContent,
  Title,
  PanelIndication,
} from './styles';

type Tab = 'reports' | 'charts';

const RolesToShowTabs = [KAM, RTC, RTCPOG, GRV, GRM, DM];

export type ProductType = 'terrabrasil' | 'indiretos';

const Report: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<IReport[]>([]);
  const [tabSelected, setTabSelected] = useState<Tab>('charts');
  const [showTabs, setShowTabs] = useState(false);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);
  const [type, setType] = useState<ProductType>('indiretos');
  const [selectedCampaignId, setSelectedCampaignId] = useState(
    type === 'terrabrasil' ? 10 : 11,// CORREÇÂO PARA ACERTAR A SAFRA NA MASTER
  );

  const { participant } = useAuth();
  const campaignListRef = useRef<CampaignsListHandle>(null);

  useEffect(() => {
    if (RolesToShowTabs.includes(participant.profile_value)) {
      setShowTabs(true);
      return;
    }
    setTabSelected('reports');
  }, [participant]);

  useEffect(() => {
    const loadLinks = async () => {
      setLoading(true);

      const [
        channelCampaignPerformance,
        productPerformanceLink,
        participantsLink,
        invoicesLink,
        producersRescuesReportLink,
        indirectsRescuesReportLink,
        biologicalReportLink,
      ] = await Promise.all([
        getChannelCampaignPerformanceLink(selectedCampaignId),
        getProductsPerformanceLink(selectedCampaignId),
        getParticipantsLogLink(selectedCampaignId),
        getInvoicesReportLink(selectedCampaignId),
        getProducersRescuesReportLink(selectedCampaignId),
        getIndirectsRescuesReportLink(selectedCampaignId),
        getBiologicalReport(selectedCampaignId),
      ]);

      setReports([
        {
          title: 'Performance Programa',
          url: channelCampaignPerformance,
          type: 'indirect',
        },
        {
          title: 'Performance Produtos',
          url: productPerformanceLink,
          type: 'indirect',
        },
        {
          title: 'Relatório de Participantes e Saldo',
          url: participantsLink,
          type: 'indirect',
        },
        {
          title: 'Relatório de Notas Fiscais - Produtores',
          url: invoicesLink,
          type: 'producer',
        },
        {
          title: 'Relatório de Resgates - Produtores',
          url: producersRescuesReportLink,
          type: 'producer',
        },
        {
          title: 'Relatório de Resgates',
          url: indirectsRescuesReportLink,
          type: 'indirect',
        },
        {
          title: 'Performance Produtos',
          url: biologicalReportLink,
          type: 'biologic',
        },
      ]);

      setLoading(false);
    };

    loadLinks();
  }, [selectedCampaignId]);

  useEffect(() => {
    async function fetchEstablishments() {
      const data = await getEstablishments();
      if (data && data.length) setEstablishments(data);
    }

    fetchEstablishments();
  }, []);

  useEffect(() => {
    if (!establishments.some(e => e.type.name === EstablishmentTypes.Resale || e.type.name === EstablishmentTypes.Cooperative)) {
      setType('terrabrasil');
      setSelectedCampaignId(10); // Default para Terra Brasil
    } else {
      setType('indiretos');
      setSelectedCampaignId(11); // Default para Indiretos
    }
  }, [establishments]);

  const handleCampaignSelect = useCallback(
    (campaign: number) => {
      type === 'terrabrasil'
        ? setSelectedCampaignId(campaign === 0 ? 10 : campaign)
        : setSelectedCampaignId(campaign === 0 ? 11 : campaign);
    },
    [type],
  );

  const handleTypeChange = (tp: ProductType) => setType(tp);

  useEffect(() => {
    campaignListRef.current?.changeSelectedCampaignToFirst();
  }, [type]);

  return (
    participant && (
      <Container>
        <>
          <PanelContent>
            <Title>Relatórios</Title>
            <PanelIndication>
              {establishments.some(e => e.type.name === EstablishmentTypes.TerraBrasil) && (
                <button
                  type="button"
                  className={type === 'terrabrasil' ? '_selected' : ''}
                  onClick={() => handleTypeChange('terrabrasil')}
                >
                  TERRA BRASIL
                </button>
              )}
              {establishments.some(
                e =>
                  e.type.name === EstablishmentTypes.Resale ||
                  e.type.name === EstablishmentTypes.Cooperative,
              ) && (
                <button
                  type="button"
                  className={type === 'indiretos' ? '_selected' : ''}
                  onClick={() => handleTypeChange('indiretos')}
                >
                  JUNTOS INDIRETOS
                </button>
              )}
            </PanelIndication>
          </PanelContent>
          <CampaignsList
            ref={campaignListRef}
            selectedCampaignId={selectedCampaignId}
            selectCampaign={handleCampaignSelect}
            campaignType={type}
          />
          <Content>
            <TabWrapper>
              {showTabs && (
                <TabsList>
                  <Tab
                    onClick={() => setTabSelected('charts')}
                    active={tabSelected === 'charts'}
                  >
                    <span>Gráficos</span>
                  </Tab>
                  <Tab
                    onClick={() => setTabSelected('reports')}
                    active={tabSelected === 'reports'}
                  >
                    <span>Relatórios</span>
                  </Tab>
                </TabsList>
              )}
              {tabSelected === 'reports' ? (
                <Reports data={reports} loading={loading} type={type} />
              ) : (
                <Charts campaignId={selectedCampaignId} productType={type} />
              )}
            </TabWrapper>
          </Content>
        </>
      </Container>
    )
  );
};

export default Report;
