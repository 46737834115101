import React from 'react';

import { Biological as BiologicalType } from 'state/modules/goals/types';

import { fakeFormatDollars } from 'util/points';
import CircularProgress from '../../Home/Performance/CircularProgress';

import RealizedProgress from '../RealizedProgress';
import {
  BoxTitle,
  WrapperValues,
  Item,
  ProgressTitle,
  GoalText,
  CircularSectionItem,
} from './styles';

export interface BiologicalProps {
  biological: BiologicalType | null;
}

const BiologicalPerformance: React.FC<BiologicalProps> = ({ biological }) => {
  if (biological && biological.invoice.goal <= 0) {
    return <></>;
  }
  return (
    <>
      <BoxTitle>BIOLÓGICOS - FATURAMENTO E POG</BoxTitle>

      {biological && (
        <WrapperValues>
          <Item>
            <ProgressTitle>FATURAMENTO</ProgressTitle>
            <GoalText>
              Objetivo US$ {fakeFormatDollars(biological.invoice.goal, 0, 0)}
            </GoalText>
            <CircularSectionItem>
              <CircularProgress
                color="#FF4C16"
                percent={biological.invoice.percentage}
              >
                <RealizedProgress
                  percent={biological.invoice.percentage}
                  realized={`US$ ${fakeFormatDollars(
                    biological.invoice.realized,
                    0,
                    0,
                  )}`}
                />
              </CircularProgress>
            </CircularSectionItem>
          </Item>
          <Item>
            <ProgressTitle>POG</ProgressTitle>
            <GoalText>
              Objetivo US$ {fakeFormatDollars(biological.pog.goal, 0, 0)}
            </GoalText>
            <CircularSectionItem>
              <CircularProgress
                color="#25CCE1"
                percent={biological.pog.percentage}
              >
                <RealizedProgress
                  percent={biological.pog.percentage}
                  realized={`US$ ${fakeFormatDollars(
                    biological.pog.realized,
                    0,
                    0,
                  )}`}
                />
              </CircularProgress>
            </CircularSectionItem>
          </Item>
        </WrapperValues>
      )}
    </>
  );
};

export default BiologicalPerformance;
