import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 0 15px 0;
`;

export const CircularSectionItem = styled.div`
  width: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;

  > img {
    width: 40px;
    height: 40px;
    margin-bottom: 100px;
  }

  .devolutionCheck {
    width: 40px;
    height: 40px;
    margin-top: 36px;
  }

  .pontualIcon {
    width: 55px;
    height: 55px;
    margin-top: 4em;
  }

  .pontualCheck {
    width: 40px;
    height: 40px;
    margin-top: 4.5em;
  }
`;

export const PercentCircularCenter = styled.div`
  position: absolute;
  background-color: #eef1f2;
  width: 69%;
  height: 69%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 100%;
  box-shadow: 2px 3px 6px rgb(0 0 0 / 51%);
  color: #000;

  > div {
    text-align: center;
  }

  span {
    display: block;
    text-align: center;
    font-size: 28px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #000;
  }
`;
