import React from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import stepOne from 'assets/images/fmcProdutor/NF/etapa1.png';
import stepTwo from 'assets/images/fmcProdutor/NF/etapa2.png';
import stepTree from 'assets/images/fmcProdutor/NF/etapa3.png';
import arrowNF from 'assets/images/fmcProdutor/NF/arrow.png';
import arrow2NF from 'assets/images/fmcProdutor/NF/arrow_2.png';
import coins1 from 'assets/images/fmcProdutor/NF/coins_modal_3.png';
import coins2 from 'assets/images/fmcProdutor/NF/coins_modal2.png';

import {
  Modal,
  Container,
  Title,
  Close,
  Actions,
  ButtonContainer,
  Button,
} from './styles';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
}

const HelpAddNF: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={10}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>

      <Container>
        <div>
          <img src={coins1} alt="coins" className="coinsModal1" />
        </div>
        <div>
          <img src={coins2} alt="coins" className="coinsModal2" />
        </div>
        <Title>Como enviar sua nota fiscal e ganhar FMC Coins?</Title>
        <Actions>
          <img src={stepOne} alt="Step 1" className="ImgStep1" />
          <img src={arrowNF} className="arrow" alt="Arrow" />
          <img src={stepTwo} alt="Step 2" />
          <img src={arrow2NF} className="arrow" alt="Arrow" />
          <img src={stepTree} alt="Step 3" />
        </Actions>
        <Actions>
          <span>
            Depois de comprar produtos participantes nas lojas vinculadas ao
            programa, acesse o site.
          </span>
          <span>
            Ao acessar, envie sua nota (formato JPG, PNG, PDF ou chave de acesso
            de 44 caracteres.
          </span>
          <span>
            Aguarde o prazo de validação <br /> (30 dias) e pronto!
          </span>
        </Actions>

        <ButtonContainer>
          <Button type="submit" buttonRole="primary" onClick={onRequestClose}>
            OK
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default HelpAddNF;
