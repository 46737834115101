import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const getValue = <T>(search: string, param: string) =>
  new URLSearchParams(search).get(param) as T | null;

const useSearchParam = <T>(param: string): T | null => {
  const location = useLocation();
  const [value, setValue] = useState<T | null>(() =>
    getValue(location.search, param),
  );

  useEffect(() => {
    const onChange = () => {
      setValue(getValue(location.search, param));
    };

    window.addEventListener('popstate', onChange);
    window.addEventListener('pushstate', onChange);
    window.addEventListener('replacestate', onChange);

    return () => {
      window.removeEventListener('popstate', onChange);
      window.removeEventListener('pushstate', onChange);
      window.removeEventListener('replacestate', onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
};

export default useSearchParam;
