import { Reducer } from 'redux';

import { Subject } from 'services/contact/connected/privateSubjects';
import { Category } from 'services/contact/connected/privateCategories';
import { SET_CATEGORY, SET_SUBJECT, RESET } from './constants';
import { ContactActions } from './actions';

export type ContactState = {
  category: Category | null;
  subject: Subject | null;
};

export const initialState: ContactState = {
  category: null,
  subject: null,
};

const ContactReducer: Reducer<ContactState, ContactActions> = (
  state = initialState,
  action: ContactActions,
): ContactState => {
  if (
    action.type !== SET_SUBJECT &&
    action.type !== SET_CATEGORY &&
    action.type !== RESET
  ) {
    return state;
  }

  switch (action.type) {
    case SET_SUBJECT:
      return {
        ...state,
        subject: { title: action.payload.title, id: action.payload.id },
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: { name: action.payload.name, id: action.payload.id },
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export default ContactReducer;
