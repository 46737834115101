import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import { Button as DefaultButton } from 'components/shared';

const cooperativeColor = '#3B5309';
const resaleColor = '#254155';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 620px;
    width: 100%;
    height: 100%;
    max-height: 210px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px 32px 10px 32px;
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 1.6em;
  text-align: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  position: relative;
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  padding: 5px;
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: black;
    }
  }
`;

const CustomButton = styled(DefaultButton)`
  max-width: 260px;
  border-radius: 8px;
  text-transform: uppercase;
`;
interface ButtonProps {
  isResale?: boolean;
}

export const Button = styled(CustomButton)<ButtonProps>`
  background-color: ${({ isResale }) =>
    isResale ? resaleColor : cooperativeColor};

  &:hover {
    background-color: ${({ isResale }) =>
      isResale ? resaleColor : cooperativeColor};
  }
`;

export const ButtonProdutor = styled(CustomButton)`
  background-color: #65554d;

  &:hover {
    background-color: #65554d;
  }
`;
