/* eslint-disable no-undef */
import {
  Product,
  Indicator,
  IndicatorType,
  Configuration,
  ValuesConfig,
} from '../interfaces';

interface DefaultProps {
  indicators: Indicator[];
  configuration: Configuration;
}
interface CustomProps extends DefaultProps {
  products: Product[];
  dollarBase: number;
}

interface CheckIndicatorsToPayExtra {
  shouldPayExtraForHero: boolean;
  shouldPayExtraForTalisman: boolean;
  shouldPayExtraForAurora: boolean;
  shouldPayExtraForAltacor: boolean;
  shouldPayExtraForVeriMark: boolean;
  shouldPayExtraForStone: boolean;
  shouldPayExtraForBoralFull: boolean;
  shouldPayExtraForQuartzo: boolean;
  shouldPayExtraForFungicidas: boolean;
  shouldPayExtraForHerbicidas: boolean;
  shouldPayExtraForInseticidas: boolean;
  shouldPayExtraForInseticidasByRynaxypyr: boolean;
  shouldPayExtraForPremioStar: boolean;
}

const getIndicatorsToPayExtra = (
  indicators: Indicator[],
  type: 'simulated' | 'total',
  check?: boolean,
): CheckIndicatorsToPayExtra => {
  const heroIndicator = indicators.find(
    item => item.type === IndicatorType.hero,
  );
  const talismanIndicator = indicators.find(
    item => item.type === IndicatorType.talisman,
  );
  const auroraIndicator = indicators.find(
    item => item.type === IndicatorType.aurora,
  );
  const altacorIndicator = indicators.find(
    item => item.type === IndicatorType.altacor,
  );
  const premioStarIndicator = indicators.find(
    item => item.type === IndicatorType.premioStar,
  );

  const veriMarkIndicator = indicators.find(
    item => item.type === IndicatorType.veriMark,
  );
  const stoneIndicator = indicators.find(
    item => item.type === IndicatorType.stone,
  );
  const boralFullIndicator = indicators.find(
    item => item.type === IndicatorType.boralFull,
  );
  const quartzoIndicator = indicators.find(
    item => item.type === IndicatorType.quartzo,
  );
  const fungicidasIndicator = indicators.find(
    item => item.type === IndicatorType.fungicidas,
  );
  const herbicidasIndicator = indicators.find(
    item => item.type === IndicatorType.herbicidas,
  );
  const inseticidasIndicator = indicators.find(
    item => item.type === IndicatorType.inseticidas,
  );
  const inseticidasByRynaxypyrIndicator = indicators.find(
    item => item.type === IndicatorType.inseticidasByRynaxypyr,
  );
  // const reatorIndicator = indicators.find(
  //   item => item.type === IndicatorType.reator,
  // );

  const pogIndicator = indicators.find(item => item.type === IndicatorType.pog);
  const revenuesIndicator = indicators.find(
    item => item.type === IndicatorType.revenues,
  );

  // const pogVolumeIndicator = indicators.find(
  //   item => item.type === IndicatorType.pogVolume,
  // );
  const shouldPayExtra =
    (pogIndicator?.simulationData.totalPercentageRealized || 0) >= 80 &&
    (revenuesIndicator?.simulationData.totalPercentageRealized || 0) >= 80; 

  const shouldPayExtraForHero =
    (((shouldPayExtra &&
      heroIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (heroIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForTalisman =
    (((shouldPayExtra &&
      talismanIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (talismanIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForAurora =
    (((shouldPayExtra &&
      auroraIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (auroraIndicator?.currentGoal || 0) > 0;

  /** Produtos terra brasil */
  const shouldPayExtraForAltacor =
    (((shouldPayExtra &&
      altacorIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (altacorIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForPremioStar =
    (((shouldPayExtra &&
      premioStarIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (premioStarIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForVeriMark =
    (((shouldPayExtra &&
      veriMarkIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (veriMarkIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForStone =
    (((shouldPayExtra &&
      stoneIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (stoneIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForBoralFull =
    (((shouldPayExtra &&
      boralFullIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (boralFullIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForQuartzo =
    (((shouldPayExtra &&
      quartzoIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 100 ||
      type === 'simulated') &&
    (quartzoIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForFungicidas =
    (type === 'simulated') &&
    (fungicidasIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForHerbicidas =
    (type === 'simulated') &&
    (herbicidasIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForInseticidas =
    (type === 'simulated') &&
    (inseticidasIndicator?.currentGoal || 0) > 0;

  const shouldPayExtraForInseticidasByRynaxypyr =
    (type === 'simulated') &&
    (inseticidasByRynaxypyrIndicator?.currentGoal || 0) > 0;

  // const shouldPayExtraForReator =
  //   (((shouldPayExtra &&
  //     reatorIndicator?.simulationData.totalPercentageRealized) ||
  //     0) >= 100 ||
  //     type === 'simulated') &&
  //   (reatorIndicator?.currentGoal || 0) > 0;

  // const shouldPayExtraForPogVolume =
  //   (((shouldPayExtra &&
  //     pogVolumeIndicator?.simulationData.totalPercentageRealized) ||
  //     0) >= 100 ||
  //     type === 'simulated') &&
  //   (pogVolumeIndicator?.currentGoal || 0) > 0;

  return {
    shouldPayExtraForHero,
    shouldPayExtraForTalisman,
    shouldPayExtraForAurora,
    shouldPayExtraForAltacor,
    shouldPayExtraForVeriMark,
    shouldPayExtraForStone,
    shouldPayExtraForBoralFull,
    shouldPayExtraForQuartzo,
    shouldPayExtraForFungicidas,
    shouldPayExtraForHerbicidas,
    shouldPayExtraForInseticidas,
    shouldPayExtraForInseticidasByRynaxypyr,
    shouldPayExtraForPremioStar,
    // shouldPayExtraForReator,
    // shouldPayExtraForPogVolume,
  };
};
interface CheckIndicatorsToPayExtraNew {
  shouldPayExtraForFungicidasNew: any;
  shouldPayExtraForHerbicidasNew: any;
  shouldPayExtraForInseticidasNew: any;
  shouldPayExtraForInseticidasByRynaxypyrNew: any;
}

const getIndicatorsToPayExtraNew = (
  indicators: Indicator[],
  type: 'total',
): CheckIndicatorsToPayExtraNew => {
  const fungicidasIndicator = indicators.find(
    item => item.type === IndicatorType.fungicidas,
  );
  const herbicidasIndicator = indicators.find(
    item => item.type === IndicatorType.herbicidas,
  );
  const inseticidasIndicator = indicators.find(
    item => item.type === IndicatorType.inseticidas,
  );
  const inseticidasByRynaxypyrIndicator = indicators.find(
    item => item.type === IndicatorType.inseticidasByRynaxypyr,
  );

  const pogIndicator = indicators.find(item => item.type === IndicatorType.pog);
  const revenuesIndicator = indicators.find(
    item => item.type === IndicatorType.revenues,
  );

  const shouldPayExtra =
    (pogIndicator?.simulationData.totalPercentageRealized || 0) >= 80 &&
    (revenuesIndicator?.simulationData.totalPercentageRealized || 0) >= 80; 

  const shouldPayExtraForFungicidasNew =
    (((shouldPayExtra &&
      fungicidasIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 80 &&
      type === 'total') &&
    (fungicidasIndicator?.currentGoal || 0) > 0 ? 
    (
      getPercentRibet(fungicidasIndicator?.simulationData.totalPercentageRealized || 0)
    ) : (
      0
    );

  const shouldPayExtraForHerbicidasNew =
    (((shouldPayExtra &&
      herbicidasIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 80 &&
      type === 'total') &&
    (herbicidasIndicator?.currentGoal || 0) > 0 ? 
    (
      getPercentRibet(herbicidasIndicator?.simulationData.totalPercentageRealized || 0)
    ) : (
      0
    );

  const shouldPayExtraForInseticidasNew =
    (((shouldPayExtra &&
      inseticidasIndicator?.simulationData.totalPercentageRealized) ||
      0) >= 80 &&
      type === 'total') &&
    (inseticidasIndicator?.currentGoal || 0) > 0 ? 
    (
      getPercentRibet(inseticidasIndicator?.simulationData.totalPercentageRealized || 0)
    ) : (
      0
    );

  const shouldPayExtraForInseticidasByRynaxypyrNew =
    (((shouldPayExtra &&
      inseticidasByRynaxypyrIndicator?.simulationData
        .totalPercentageRealized) ||
      0) >= 80 &&
      type === 'total') &&
    (inseticidasByRynaxypyrIndicator?.currentGoal || 0) > 0 ? 
    (
      getPercentRibet(inseticidasByRynaxypyrIndicator?.simulationData.totalPercentageRealized || 0)
    ) : (
      0
    );

  return {
    shouldPayExtraForFungicidasNew,
    shouldPayExtraForHerbicidasNew,
    shouldPayExtraForInseticidasNew,
    shouldPayExtraForInseticidasByRynaxypyrNew,
  };
};

const getHowManyEnhancerProductsReachedTheGoals = (
  indicators: Indicator[],
  type: 'simulated' | 'total',
): number => {
  const {
    shouldPayExtraForHero,
    shouldPayExtraForAurora,
    shouldPayExtraForTalisman,
    shouldPayExtraForAltacor,
    shouldPayExtraForVeriMark,
    shouldPayExtraForStone,
    shouldPayExtraForBoralFull,
    shouldPayExtraForQuartzo,
    shouldPayExtraForPremioStar,
    shouldPayExtraForFungicidas,
    shouldPayExtraForHerbicidas,
    shouldPayExtraForInseticidas,
    shouldPayExtraForInseticidasByRynaxypyr,
    // shouldPayExtraForReator,
    // shouldPayExtraForPogVolume,
  } = getIndicatorsToPayExtra(indicators, type);

  const indicatorsToPayExtraResult = [
    shouldPayExtraForHero,
    shouldPayExtraForAurora,
    shouldPayExtraForTalisman,
    shouldPayExtraForAltacor,
    shouldPayExtraForVeriMark,
    shouldPayExtraForStone,
    shouldPayExtraForBoralFull,
    shouldPayExtraForQuartzo,
    shouldPayExtraForPremioStar,
    shouldPayExtraForFungicidas,
    shouldPayExtraForHerbicidas,
    shouldPayExtraForInseticidas,
    shouldPayExtraForInseticidasByRynaxypyr,
    // shouldPayExtraForReator,
    // shouldPayExtraForPogVolume,
  ].filter(value => value).length;

  return indicatorsToPayExtraResult;
};
const getHowManyEnhancerProductsReachedTheGoalsNewPorcentage = (
  indicators: Indicator[],
  type: 'total'
) => {
  const {
    shouldPayExtraForFungicidasNew,
    shouldPayExtraForHerbicidasNew,
    shouldPayExtraForInseticidasNew,
    shouldPayExtraForInseticidasByRynaxypyrNew
  } = getIndicatorsToPayExtraNew(indicators, type);

  const indicatorsToPayExtraResult = [
    shouldPayExtraForFungicidasNew,
    shouldPayExtraForHerbicidasNew,
    shouldPayExtraForInseticidasNew,
    shouldPayExtraForInseticidasByRynaxypyrNew
  ];

  let sumPercenteg = 0;

  indicatorsToPayExtraResult.forEach(
    item => {
      sumPercenteg += item;
    }
  );

  return sumPercenteg;
};

export const getPercentRibet = (percentage: number) => {
  if(percentage < 80)  return 0;
  if(percentage >= 80 && percentage < 85)  return 0.5;
  if(percentage >= 85 && percentage < 90)  return 0.6;
  if(percentage >= 90 && percentage < 95)  return 0.7;
  if(percentage >= 95 && percentage < 100)  return 0.8;
  if(percentage >= 100)  return 1;
};


export const getTrigger = (
  percentTopay: ValuesConfig[],
  pogPercentageRealized: number,
  revenuesPercentageRealized: number,
) => {
  let resultPog;

  if (revenuesPercentageRealized) {
    resultPog = percentTopay.find(
      item =>
        pogPercentageRealized >= item.value * 100 &&
        revenuesPercentageRealized >= item.value * 100,
    );
  }

  if (resultPog) {
    const resultPogFinal = resultPog;

    return resultPogFinal;
  }
  const responseAvoid: ValuesConfig | undefined = {
    balance_type_name: '',
    value: 0,
    pay_percent: 0,
  };
  const resultPogFinal = responseAvoid;

  return resultPogFinal;
};

export const getFactorConversion = (
  configuration: Configuration,
  pogPercentageRealized: number,
  revenuesPercentageRealized: number,
  paymentType: 'seller' | 'rebate' | 'biological',
) => {
  const { percent_to_pay_rebate } = configuration;

  if (paymentType === 'seller') {
    const resultTrigger = getTrigger(
      percent_to_pay_rebate,
      pogPercentageRealized,
      revenuesPercentageRealized,
    );

    return resultTrigger;
  }

  if (paymentType === 'rebate') {
    const resultTrigger = getTrigger(
      percent_to_pay_rebate,
      pogPercentageRealized,
      revenuesPercentageRealized,
    );

    return resultTrigger;
  }

  if (paymentType === 'biological') {
    const resultTrigger = getTrigger(
      percent_to_pay_rebate,
      pogPercentageRealized,
      revenuesPercentageRealized,
    );

    return resultTrigger;
  }
};

export const getIndicatorPercentageRealizedFromIndicators = (
  indicators: Indicator[],
  indicatorType: IndicatorType,
): number => {
  return (
    indicators.find(item => item.type === indicatorType)?.simulationData
      .totalPercentageRealized || 0
  );
};

type MinimumPercentage = {
  minimumValue: number;
  payPercent: number;
};

// const getMinimumPercentageToMakePoints = (data: MinimumPercentage[]) => {
//   return data[data.length - 1].minimumValue;
// };

const getRebateFinalMultiplierValue = ({
  configuration,
  indicators,
}: DefaultProps): number => {
  const pogPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pog,
  );
  const revenuesPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenues,
  );

  const resultFactor = getFactorConversion(
    configuration,
    pogPercentageRealized,
    revenuesPercentageRealized,
    'rebate',
  );

  if (resultFactor && resultFactor.value >= 0.8) {
    return 1;
  }

  return 0;
};

const getSellerFinalMultiplierValue = ({
  configuration,
  indicators,
}: DefaultProps): number => {
  const pogPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pog,
  );
  const revenuesPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenues,
  );

  const resultFactor = getFactorConversion(
    configuration,
    pogPercentageRealized,
    revenuesPercentageRealized,
    'seller',
  );

  if (resultFactor && resultFactor.value >= 0.8) {
    return 1;
  }

  return 0;
};

export const getBiologicalFinalMultiplierValue = ({
  configuration,
  indicators,
}: DefaultProps): number => {
  const pogBiologicalPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pogBiological,
  );
  const revenuesBiologicalPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenuesBiological,
  );

  const pogPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pog,
  );
  const revenuesPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenues,
  );

  const resultFactorJuntosFmc = getFactorConversion(
    configuration,
    pogPercentageRealized,
    revenuesPercentageRealized,
    'seller',
  );

  const resultFactorBiological = getFactorConversion(
    configuration,
    pogBiologicalPercentageRealized,
    revenuesBiologicalPercentageRealized,
    'biological',
  );

  if (resultFactorJuntosFmc && resultFactorJuntosFmc.value >= 0.8) return 1;
  if (resultFactorBiological && resultFactorBiological.value >= 0.8) return 1;

  return 0;
};

interface PercentageValueToApplyProps extends DefaultProps {
  resultType: 'simulated' | 'total';
  paymentType: 'seller' | 'rebate' | 'biological';
}

const getPercentageValueToApplyInDecimal = ({
  configuration,
  indicators,
  resultType,
  paymentType,
}: PercentageValueToApplyProps) => {
  const pogPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pog,
  );
  const revenuesPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenues,
  );

  const pogBiologicalPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.pogBiological,
  );

  const revenuesBiologicalPercentageRealized = getIndicatorPercentageRealizedFromIndicators(
    indicators,
    IndicatorType.revenuesBiological,
  );

  const resultSeller = getFactorConversion(
    configuration,
    pogPercentageRealized,
    revenuesPercentageRealized,
    'seller',
  );

  const resultRebate = getFactorConversion(
    configuration,
    pogPercentageRealized,
    revenuesPercentageRealized,
    'rebate',
  );

  const resultBiological = getFactorConversion(
    configuration,
    pogBiologicalPercentageRealized,
    revenuesBiologicalPercentageRealized,
    'biological',
  );

  if (resultType === 'simulated') {
    return 1;
  }

  if (paymentType === 'seller') {
    if (resultSeller) {
      return resultSeller.pay_percent;
    }
  }

  if (paymentType === 'rebate') {
    if (resultRebate) {
      return resultRebate.pay_percent;
    }
  }

  if (paymentType === 'biological') {
    if (resultBiological) {
      return resultBiological.pay_percent;
    }
  }
  return 0;
};

interface RebateReachedValuesProps {
  product: Product;
  numberOfTimesExtraShouldBePaidSimulated: number;
  numberOfTimesExtraShouldBePaidAccumalated: number;
  numberOfTimesExtraShouldBePaidAccumalatedPorcentage: number;
  percentageValueToApplyInDecimalInTotalAmount: number;
  percentageValueToApplyInDecimalInSimulatedAmount: number;
  finalMultiplierValue: number;
}

const getRebateReachedValuesInReal = ({
  product,
  finalMultiplierValue,
  numberOfTimesExtraShouldBePaidSimulated,
  numberOfTimesExtraShouldBePaidAccumalated,
  numberOfTimesExtraShouldBePaidAccumalatedPorcentage,
  percentageValueToApplyInDecimalInSimulatedAmount,
  percentageValueToApplyInDecimalInTotalAmount,
}: RebateReachedValuesProps) => {
  const extraPercentageToPaySimulated =
    product.extraPercentageToPayByEnhancerProduct *
    numberOfTimesExtraShouldBePaidSimulated;
    const extraPercentageToPayAccumulated = 
    product.extraPercentageToPayByEnhancerProduct * 
    (
      numberOfTimesExtraShouldBePaidAccumalated > 0 ? 
      numberOfTimesExtraShouldBePaidAccumalated : numberOfTimesExtraShouldBePaidAccumalatedPorcentage
    );

  const rebatePercentageToPayInTotalAmount =
    product.awardsParamsToPay.rebatePercentage *
    percentageValueToApplyInDecimalInTotalAmount;

  const rebatePercentageToPayInSimulatedAmount =
    product.awardsParamsToPay.rebatePercentage *
    percentageValueToApplyInDecimalInSimulatedAmount;

  const percentageTotalToPayInTotalAmount =
    rebatePercentageToPayInTotalAmount + extraPercentageToPayAccumulated;
  const percentageTotalToPayInSimulatedAmount =
    rebatePercentageToPayInSimulatedAmount + extraPercentageToPaySimulated;

  const rebateReachedInRealSimulated =
    (product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInSimulatedAmount) /
    100;

  const rebateReachedInRealSimulatedButUsedToGetTotal =
    ((product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInTotalAmount) /
      100) *
    finalMultiplierValue;

  const rebateReachedInRealRealized =
    ((product.simulationData.pogRealizedNetInRealTotal *
      percentageTotalToPayInTotalAmount) /
      100) *
    finalMultiplierValue;

  const rebateReachedInRealTotal =
    ((product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInTotalAmount) /
      100 +
      (product.simulationData.pogRealizedNetInRealTotal *
        percentageTotalToPayInTotalAmount) /
        100) *
    finalMultiplierValue;

  const rebatePercentageBiologicalToPayInTotalAmount =
    product.awardsParamsToPay.rebatePercentageBiological *
    percentageValueToApplyInDecimalInTotalAmount;

  const rebatePercentageBiologicalToPayInSimulatedAmount =
    product.awardsParamsToPay.rebatePercentageBiological *
    percentageValueToApplyInDecimalInSimulatedAmount;

  const percentageTotalToPayInTotalAmountBiological =
    rebatePercentageBiologicalToPayInTotalAmount +
    extraPercentageToPayAccumulated;
  const percentageTotalToPayInSimulatedAmountBiological =
    rebatePercentageBiologicalToPayInSimulatedAmount +
    extraPercentageToPaySimulated;

  const rebateBiologicalReachedInRealSimulated =
    (product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInSimulatedAmountBiological) /
    100;

  const rebateBiologicalReachedInRealSimulatedButUsedToGetTotal =
    ((product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInTotalAmountBiological) /
      100) *
    finalMultiplierValue;

  const rebateBiologicalReachedInRealRealized =
    ((product.simulationData.pogRealizedNetInRealTotal *
      percentageTotalToPayInTotalAmountBiological) /
      100) *
    finalMultiplierValue;

  const rebateBiologicalReachedInRealTotal =
    ((product.simulationData.pogRealizedNetInRealSimulated *
      percentageTotalToPayInTotalAmount) /
      100 +
      (product.simulationData.pogRealizedNetInRealTotal *
        percentageTotalToPayInTotalAmount) /
        100) *
    finalMultiplierValue;

  return {
    rebateReachedInRealSimulated,
    rebateReachedInRealRealized,
    rebateReachedInRealTotal,
    rebateReachedInRealSimulatedButUsedToGetTotal,
    rebateBiologicalReachedInRealRealized,
    rebateBiologicalReachedInRealSimulated,
    rebateBiologicalReachedInRealSimulatedButUsedToGetTotal,
    rebateBiologicalReachedInRealTotal,
  };
};

interface SellerReachedValuesProps {
  product: Product;
  finalMultiplierValue: number;
  percentageValueToApplyInDecimalInTotalAmount: number;
  percentageValueToApplyInDecimalInSimulatedAmount: number;
}
const getSellerReachedValuesInReal = ({
  product,
  finalMultiplierValue,
  percentageValueToApplyInDecimalInTotalAmount,
  percentageValueToApplyInDecimalInSimulatedAmount,
}: SellerReachedValuesProps) => {
  const sellerReachedInRealSimulated =
    product.simulationData.pogInKilosPerLiter *
    (product.awardsParamsToPay.sellerValueInReal *
      percentageValueToApplyInDecimalInSimulatedAmount);

  const sellerReachedInRealSimulatedButUsedToGetTotal =
    product.simulationData.pogInKilosPerLiter *
    (product.awardsParamsToPay.sellerValueInReal *
      percentageValueToApplyInDecimalInTotalAmount);

  const sellerReachedInRealRealized =
    product.pog.realizedInKilosByLiter *
    (product.awardsParamsToPay.sellerValueInReal *
      percentageValueToApplyInDecimalInTotalAmount) *
    finalMultiplierValue;

  const sellerReachedInRealTotal =
    (product.simulationData.pogInKilosPerLiter *
      (product.awardsParamsToPay.sellerValueInReal *
        percentageValueToApplyInDecimalInTotalAmount) +
      product.pog.realizedInKilosByLiter *
        (product.awardsParamsToPay.sellerValueInReal *
          percentageValueToApplyInDecimalInTotalAmount)) *
    finalMultiplierValue;

  const sellerBiologicalReachedInRealSimulated =
    product.simulationData.pogInKilosPerLiter *
    (product.awardsParamsToPay.sellerValueInRealBiological *
      percentageValueToApplyInDecimalInSimulatedAmount);
  const sellerBiologicalReachedInRealSimulatedButUsedToGetTotal =
    product.simulationData.pogInKilosPerLiter *
    (product.awardsParamsToPay.sellerValueInRealBiological *
      percentageValueToApplyInDecimalInTotalAmount);

  const sellerBiologicalReachedInRealRealized =
    product.pog.realizedInKilosByLiter *
    (product.awardsParamsToPay.sellerValueInRealBiological *
      percentageValueToApplyInDecimalInTotalAmount) *
    finalMultiplierValue;

  const sellerBiologicalReachedInRealTotal =
    (product.simulationData.pogInKilosPerLiter *
      (product.awardsParamsToPay.sellerValueInReal *
        percentageValueToApplyInDecimalInTotalAmount) +
      product.pog.realizedInKilosByLiter *
        (product.awardsParamsToPay.sellerValueInReal *
          percentageValueToApplyInDecimalInTotalAmount)) *
    finalMultiplierValue;

  return {
    sellerReachedInRealSimulated,
    sellerReachedInRealSimulatedButUsedToGetTotal,
    sellerReachedInRealRealized,
    sellerReachedInRealTotal,
    sellerBiologicalReachedInRealSimulated,
    sellerBiologicalReachedInRealSimulatedButUsedToGetTotal,
    sellerBiologicalReachedInRealRealized,
    sellerBiologicalReachedInRealTotal,
  };
};

export default ({
  products,
  indicators,
  configuration,
  dollarBase,
}: CustomProps): Product[] => {
  const numberOfTimesExtraShouldBePaidSimulated = getHowManyEnhancerProductsReachedTheGoals(
    indicators,
    'simulated',
  );
  const numberOfTimesExtraShouldBePaidAccumalated = getHowManyEnhancerProductsReachedTheGoals(
    indicators,
    'total',
  );

  const numberOfTimesExtraShouldBePaidAccumalatedPorcentage = getHowManyEnhancerProductsReachedTheGoalsNewPorcentage(
    indicators,
    'total'
  );

  const rebateFinalMultiplierValue = getRebateFinalMultiplierValue({
    configuration,
    indicators,
  });

  const sellerFinalMultiplierValue = getSellerFinalMultiplierValue({
    configuration,
    indicators,
  });

  const biologicalFinalMultiplierValue = getBiologicalFinalMultiplierValue({
    configuration,
    indicators,
  });

  const rebatePercentageValueToApplyInDecimalInTotalAmount = getPercentageValueToApplyInDecimal(
    {
      configuration,
      indicators,
      resultType: 'total',
      paymentType: 'rebate',
    },
  );

  const sellerPercentageValueToApplyInDecimalInTotalAmount = getPercentageValueToApplyInDecimal(
    {
      configuration,
      indicators,
      resultType: 'total',
      paymentType: 'seller',
    },
  );

  const percentageValueToApplyInDecimalInSimulatedAmount = getPercentageValueToApplyInDecimal(
    {
      configuration,
      indicators,
      resultType: 'simulated',
      paymentType: 'rebate',
    },
  );

  const biologicalPercentageValueToApplyInDecimalInTotalAmount = getPercentageValueToApplyInDecimal(
    {
      configuration,
      indicators,
      resultType: 'total',
      paymentType: 'biological',
    },
  );

  // MAYCONN

  return products.map(product => {
    const {
      rebateReachedInRealSimulated,
      rebateReachedInRealSimulatedButUsedToGetTotal,
      rebateReachedInRealRealized,
      rebateReachedInRealTotal,
    } = getRebateReachedValuesInReal({
      product,
      numberOfTimesExtraShouldBePaidSimulated,
      numberOfTimesExtraShouldBePaidAccumalated,
      numberOfTimesExtraShouldBePaidAccumalatedPorcentage,
      percentageValueToApplyInDecimalInSimulatedAmount,
      percentageValueToApplyInDecimalInTotalAmount: rebatePercentageValueToApplyInDecimalInTotalAmount,
      finalMultiplierValue: rebateFinalMultiplierValue,
    });

    const {
      sellerReachedInRealSimulated,
      sellerReachedInRealSimulatedButUsedToGetTotal,
      sellerReachedInRealRealized,
      sellerReachedInRealTotal,
    } = getSellerReachedValuesInReal({
      product,
      percentageValueToApplyInDecimalInSimulatedAmount,
      percentageValueToApplyInDecimalInTotalAmount: sellerPercentageValueToApplyInDecimalInTotalAmount,
      finalMultiplierValue: sellerFinalMultiplierValue,
    });

    const {
      sellerBiologicalReachedInRealSimulated,
      sellerBiologicalReachedInRealSimulatedButUsedToGetTotal,
      sellerBiologicalReachedInRealRealized,
      sellerBiologicalReachedInRealTotal,
    } = getSellerReachedValuesInReal({
      product,
      percentageValueToApplyInDecimalInSimulatedAmount,
      percentageValueToApplyInDecimalInTotalAmount: biologicalPercentageValueToApplyInDecimalInTotalAmount,
      finalMultiplierValue: biologicalFinalMultiplierValue,
    });

    const {
      rebateBiologicalReachedInRealRealized,
      rebateBiologicalReachedInRealSimulated,
      rebateBiologicalReachedInRealSimulatedButUsedToGetTotal,
      rebateBiologicalReachedInRealTotal,
    } = getRebateReachedValuesInReal({
      product,
      numberOfTimesExtraShouldBePaidSimulated,
      numberOfTimesExtraShouldBePaidAccumalated,
      numberOfTimesExtraShouldBePaidAccumalatedPorcentage,
      percentageValueToApplyInDecimalInSimulatedAmount,
      percentageValueToApplyInDecimalInTotalAmount: biologicalPercentageValueToApplyInDecimalInTotalAmount,
      finalMultiplierValue: biologicalFinalMultiplierValue,
    });

    const additionalMarginSimulated =
      ((product.simulationData.revenuesInDollar *
        product.awardsParamsToPay.additionalMarginPercentage) /
        100) *
      dollarBase;

    const additionalMarginRealized =
      ((product.revenues.realizedInDollar *
        product.awardsParamsToPay.additionalMarginPercentage) /
        100) *
      dollarBase;

    const additionalMarginTotal =
      additionalMarginRealized + additionalMarginSimulated;

    const response = {
      ...product,
      simulationPoints: {
        rebateReachedInRealSimulated,
        rebateReachedInRealSimulatedButUsedToGetTotal,
        rebateReachedInRealRealized,
        rebateReachedInRealTotal,
        sellerReachedInRealSimulated,
        sellerReachedInRealSimulatedButUsedToGetTotal,
        sellerReachedInRealRealized,
        sellerReachedInRealTotal,
        additionalMarginSimulated,
        additionalMarginRealized,
        additionalMarginTotal,
        sellerBiologicalReachedInRealRealized,
        sellerBiologicalReachedInRealSimulated,
        sellerBiologicalReachedInRealSimulatedButUsedToGetTotal,
        sellerBiologicalReachedInRealTotal,
        rebateBiologicalReachedInRealRealized,
        rebateBiologicalReachedInRealSimulated,
        rebateBiologicalReachedInRealSimulatedButUsedToGetTotal,
        rebateBiologicalReachedInRealTotal,
      },
    };

    return response;
  });
};
