import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import detail from 'assets/images/mini-banner-detail.svg';

import { Container, Cover, Title } from './styles';
import {useAuth} from "../../../context/AuthContext";

const Bainer: React.FC = () => {
  const { participant } = useAuth();
  const initialUrl = process.env.REACT_APP_HOST + '?code=';
  const [value] = useState(initialUrl + participant.indicator_code);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Container>
      <Cover>
        <Title>Indique um Produtor</Title>
        <div className="content-bainer">
          <main>
            <div className="indicator-code">
              <h1>Compartilhe seu link de Indicação</h1>
              <div className="indicator-code-content">
                <div>
                  <h2>{value}</h2>
                  <span></span>
                </div>
                <CopyToClipboard text={value} onCopy={() => handleCopy()}>
                  <button type="button">Copiar</button>
                </CopyToClipboard>
                {copied ? <span>Copiado.</span> : null}
              </div>
            </div>
            {/* eslint-disable-next-line  */}
            <img src={detail} alt="Detalhe da image" />
          </main>
        </div>
      </Cover>
    </Container>
  );
};

export default Bainer;
