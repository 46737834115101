import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container } from './styles';

interface DropDownListProps {
  question: string;
  answers: AnswerData[];
  id: number;
  alreadyAnwered: boolean;
  canEdit: boolean;
}

const DropDownList: React.FC<DropDownListProps> = ({
  question,
  answers,
  id,
  canEdit,
  alreadyAnwered,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'unique' });
    }
  }, [answers, canEdit, dispatch, id]);

  return (
    <Container>
      <p>{question}</p>
      <select
        name="droplist"
        onChange={e => {
          const test = e.target.options;
          const answerId = test[test.selectedIndex].id;

          dispatch(
            setValueAnswer({
              answerValue: e.target.value,
              questionId: Number(id),
              answerId: Number(answerId),
              multipleAnswers: 'unique',
            }),
          );
        }}
        disabled={!canEdit}
      >
        {!alreadyAnwered && <option value="">Selecione uma opção</option>}
        {answers.map(item => (
          <option
            key={item.id}
            value={item.answer}
            id={String(item.id)}
            selected={item.survey_participant_answers.some(
              a => a.survey_question_answer_id === item.id,
            )}
          >
            {item.answer}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default DropDownList;
