import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div``;

export const SpinContainer = styled.div`
  width: 100%;

  .arco {
    height: 380px;
    width: 380px;
    z-index: 3;
  }

  .seta {
    max-width: 60px;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    z-index: 3;
  }

  .button {
    max-width: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto auto;
    z-index: 3;
  }
`;

export const Overlay = styled(animated.div)`
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  overflow-y: auto;

  .close-icon {
    position: absolute;
    height: 22px;
    width: 22px;
    top: 40px;
    right: 50px;
    cursor: pointer;
    transition: opacity 250ms ease-in-out;

    path {
      stroke: #fff;
    }

    &:hover {
      opacity: 0.6;
    }
  }
`;
