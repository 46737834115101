import React from 'react';

import { Container } from './styles';

export type AlertVariation = 'error' | 'info';

type Props = {
  variation: AlertVariation;
  children: React.ReactNode;
  style?: any;
};

export const Alert: React.FC<Props> = ({ variation, children, style }) => {
  return (
    <Container style={style} type={variation}>
      {children}
    </Container>
  );
};
