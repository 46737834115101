import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  color: ${({ theme }) => theme.font.color.quartenary};

  h3 {
    margin-left: 1rem;
  }
`;
