import { EstablishmentTypes } from 'config/constants';

/**
 * Retorna o título das TABs de acordo com o tipo
 * de estabelecimento.
 */
export function parseResumeCampaignTitle(
  establishmentType: EstablishmentTypes,
  title: string,
) {
  const isTotal = title.toUpperCase() === 'TOTAL';

  if (isTotal) return 'Total';

  if (establishmentType === EstablishmentTypes.TerraBrasil)
    return `Ano ${title}`;

  return `Safra ${title}`;
}
