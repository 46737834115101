/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useAuth } from 'context/AuthContext';
import tampao from 'assets/images/tampao-vs.gif';
import { Container } from './styles';

const gifs = {
  cooperative:
    'https://storage.vendavall.com.br/photos/1655924411.62b366bb513d80.41762520.gif',

  resale:
    'https://storage.vendavall.com.br/photos/1655924345.62b3667997d778.67029922.gif',

  producer:
    'https://storage.juntosfmc.com.br/photos/1689977650.64bb033224e123.83918954.gif',
};

const IndirectType = {
  cooperative: 'Cooperativa',
  resale: 'Revenda',
  producer: 'Produtor',
  terra: 'Terra Brasil',
};

const TampaoSafra: React.FC = () => {
  const [type, setType] = useState(IndirectType.cooperative);
  const [url, setUrl] = useState('');
  const { participant, signOut } = useAuth();

  useEffect(() => {
    const establishmentType = participant.establishment.type_name;

    switch (establishmentType) {
      case IndirectType.cooperative:
        setType(IndirectType.cooperative);
        setUrl(gifs.cooperative);
        break;
      case IndirectType.resale:
        setType(IndirectType.resale);
        setUrl(gifs.resale);
        break;
      case IndirectType.terra:
        setType(IndirectType.cooperative);
        setUrl(gifs.cooperative);
        break;
      default:
        setType(IndirectType.producer);
        setUrl(gifs.producer);
    }
  }, [participant.establishment.type_name]);

  useEffect(() => {
    setTimeout(() => {
      signOut();
    }, 8000);
  }, [signOut]);

  return (
    <Container type={type}>
      <img src={tampao} alt="Imagem para o tampão" />
    </Container>
  );
};

export default TampaoSafra;
