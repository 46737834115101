import React, { useState, useEffect } from 'react';
import { formatDate } from 'util/datetime';
import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';

import { getInvoiceDetails } from 'services/nf';
import { InvoiceDetails } from 'services/nf/interfaces';

import {
  Content,
  PageTitle,
  ModalContainer,
  CloseModalOverlay,
  CloseModal,
  LinkBottom,
} from './styles';
import Released from './Released';
import Discredited from './Discredited';

const RELEASED = 1;
const DISCREDITED = 2;

interface DetailsProps {
  invoiceId: number;
  modalOpen: boolean;
  closeModalHandler: () => void;
}

const Details: React.FC<DetailsProps> = ({
  invoiceId,
  modalOpen,
  closeModalHandler,
}) => {
  const [details, setDetails] = useState<InvoiceDetails | null>(null);

  useEffect(() => {
    setDetails(null);
    const fetchDetails = async () => {
      const result = await getInvoiceDetails(invoiceId);
      setDetails(result);
    };

    if (invoiceId && invoiceId !== 0) {
      fetchDetails();
    }
  }, [invoiceId]);

  if (!details) return <div />;

  return (
    <ModalContainer modalOpen={modalOpen}>
      <CloseModalOverlay onClick={() => closeModalHandler()} />
      <Content>
        <PageTitle>
          Nota enviada em: {formatDate(details.sendDate)}
          <CloseModal onClick={() => closeModalHandler()}>
            <ReactSVG src={closeIcon} />
          </CloseModal>
        </PageTitle>

        {details.status === RELEASED && <Released details={details} />}
        {details.status === DISCREDITED && <Discredited details={details} />}

        <LinkBottom>
          Dúvidas sobre o preenchimento desta nota?&nbsp;
          <Link to={routeMap.contact}>Clique aqui</Link>
        </LinkBottom>
      </Content>
    </ModalContainer>
  );
};

export default Details;
