import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import ThemeLoading from 'components/shared/ThemeLoading';

import { MenuItem } from 'state/modules/header/types';
import { getHeader } from 'state/modules/header/selectors';
import { getMenuByUrl, getAllowedRoutes } from 'state/modules/header/utils';
import routeMap from 'routes/route-map';
import { useAuth } from '../AuthContext';
import { canAccess } from './utils/route-privacy';

type MenuContextType = {
  isFetching: boolean;
  selectedMenu: MenuItem | null;
  menus: Array<MenuItem> | null;
};

const MenuContext = createContext({} as MenuContextType);

export const MenuProvider: React.FC = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState<MenuItem | null>(null);
  const { simulating, isLoggedFromAdmin } = useAuth();

  const {
    menu,
    fetchMenu: { isFetching },
  } = useSelector(getHeader);

  const location = useLocation();
  const history = useHistory();
  const { signed: isSigned, participant } = useAuth();

  useEffect(
    function onRouteChange() {
      if (menu && menu.length && isSigned) {
        const accessedPath = location.pathname;
        const accessedMenu = getMenuByUrl(accessedPath, menu);
        const allowedRoutes = getAllowedRoutes(menu);
        const hasAccessOnRoute = canAccess(accessedPath, allowedRoutes || []);

        if (
          isLoggedFromAdmin &&
          accessedPath === routeMap.participantSimulation
        ) {
          return () => {
            setSelectedMenu(accessedMenu || null);
          };
        }

        if (!hasAccessOnRoute) {
          // User does not have access to this menu or route does not exists
          history.push('/home');
        } else {
          return () => {
            setSelectedMenu(accessedMenu || null);
          };
        }
      }

      return () => {
        setSelectedMenu(null);
      };
    },
    [
      history,
      menu,
      location.pathname,
      isSigned,
      participant,
      simulating,
      isLoggedFromAdmin,
    ],
  );

  return (
    <MenuContext.Provider
      value={{
        isFetching,
        menus: menu,
        selectedMenu,
      }}
    >
      {!isFetching ? (
        children
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <ThemeLoading size={32} />
        </div>
      )}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
