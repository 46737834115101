import styled from 'styled-components'; 
 

export const Container = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 48px;
  min-height: calc(100vh - 384px);
  color: ${({ theme }) => theme.font.color.secondary};
  background: #fff;
`;
 
 
 



