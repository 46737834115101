import styled, { css } from 'styled-components';
import { Button as DefaultButton } from 'components/shared';
import bannerVerde from 'assets/images/producer-indication/banner-verde.png';
import bannerVerdeMobile from 'assets/images/producer-indication/banner-verde-mobile.png';
import bannerAzul from 'assets/images/producer-indication/banner-azul.png';
import bannerAzulMobile from 'assets/images/producer-indication/banner-azul-mobile.png';
import bannerMarron from 'assets/images/producer-indication/banner-marron.png';
import bannerMarronMobile from 'assets/images/producer-indication/banner-marron-mobile.png';
import bannerCinza from 'assets/images/producer-indication/banner-cinza.png';
import bannerCinzaMobile from 'assets/images/producer-indication/banner-cinza-mobile.png';

interface ColorProps {
  colorType: string;
}

interface TextProps {
  diminacType: string;
}

export const Container = styled.div<ColorProps>`
    background-repeat: no-repeat;
    background-position: center;
    height: 463px;
    ${props =>
      props.colorType === 'verde' &&
      css`
        background-image: url(${bannerVerde});
      `}
    ${props =>
      props.colorType === 'azul' &&
      css`
        background-image: url(${bannerAzul});
      `}
    ${props =>
      props.colorType === 'marron' &&
      css`
        background-image: url(${bannerMarron});
      `}
    ${props =>
      props.colorType === 'cinza' &&
      css`
        background-image: url(${bannerCinza});
      `}

      @media (max-width: 420px) {
        ${props =>
          props.colorType === 'verde' &&
          css`
            background-image: url(${bannerVerdeMobile});
            background-position: center;
          `}
         ${props =>
           props.colorType === 'azul' &&
           css`
             background-image: url(${bannerAzulMobile});
             background-position: center;
           `}
          ${props =>
            props.colorType === 'marron' &&
            css`
              background-image: url(${bannerMarronMobile});
              background-position: center;
            `}
          ${props =>
            props.colorType === 'cinza' &&
            css`
              background-image: url(${bannerCinzaMobile});
              background-position: center;
            `}
        }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 18px;
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 10px;

    @media (max-width: 420px) {
      font-size: 14px;
    }
  }

  @media (max-width: 420px) {
    margin: 15px;
    margin-bottom: 0;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 24px;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  margin-bottom: 10px;

  @media (max-width: 420px) {
    margin-bottom: 0;
    font-size: 20px;
  }
`;

export const CardBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 150px;

  @media screen and (max-width: 420px) {
    margin: 0;
    flex-direction: column;
  }
`;

export const CodeCard = styled.div<ColorProps>`
  height: 160px;
  background: #ffff;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 18px;
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 10px;

    @media (max-width: 420px) {
      font-size: 16px;
    }
  }

  > span {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    color: ${({ theme }) => theme.font.color.primary};
    align-items: center;
  }

  @media (max-width: 420px) {
    height: 145px;
    margin: 28px;
    margin-top: 35px;
  }
`;

interface ImgProps {
  src: string;
}

export const Img = styled.img<ImgProps>`
  margin-bottom: 15px;
`;

export const InfoCard = styled.div`
  color: #ffff;

  > h3 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 10px;

    @media (max-width: 420px) {
      font-size: 16px;
    }
  }
  > div {
    display: flex;
    margin: 10px;
  }

  > div p {
    max-width: 283px;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 16px;
    font-style: italic;
    margin-left: 8px;

    @media (max-width: 420px) {
      font-size: 14px;
    }
  }

  @media (max-width: 420px) {
    color: #000000;
    margin-left: 8px;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 320px;
  width: 100%;
  height: 45px;
  border-radius: 0;

  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const TextType = styled.p<TextProps>`
  ${props =>
    props.diminacType === 'cooperados' &&
    css`
      width: 283px;
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      color: ${({ theme }) => theme.font.color.primary};
      font-size: 16px;
      font-style: italic;
      font-weight: 18px;
      margin-left: 8px;
    `}
  ${props =>
    props.diminacType === 'clientes' &&
    css`
      width: 283px;
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      color: ${({ theme }) => theme.font.color.primary};
      font-size: 16px;
      font-style: italic;
      font-weight: 18px;
      margin-left: 8px;
    `}
`;
