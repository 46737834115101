import * as Yup from 'yup';

const validateEmptyText = (value: string) => {
  return value.trim() !== '';
};

const mandatoryMessage = 'Campo obrigatório';
const inValidTextMessage = 'Digite um texto válido';

export default (): Yup.ObjectSchema<object> => {
  return Yup.object().shape({
    place_of_birth: Yup.string()
      // .required(mandatoryMessage)
      .test('valid-text', inValidTextMessage, validateEmptyText),
    nationality: Yup.string()
      // .required(mandatoryMessage)
      .test('valid-text', inValidTextMessage, validateEmptyText),
    // pis_nis: Yup.string()
    //   .required(mandatoryMessage)
    //   .test('valid-text', inValidTextMessage, validateEmptyText),
    rg: Yup.string()
      // .required(mandatoryMessage)
      .test('valid-text', inValidTextMessage, validateEmptyText),
    // education_level_select: Yup.object()
    //   .shape({
    //     value: Yup.string().required(mandatoryMessage),
    //   })
    //   .typeError(mandatoryMessage)
    //   .required(mandatoryMessage),
    marital_status_code_select: Yup.object()
      .shape({
        value: Yup.string().required(mandatoryMessage),
      })
      .typeError(mandatoryMessage),
    // .required(mandatoryMessage),
    rg_emitter_uf_select: Yup.object()
      .shape({
        value: Yup.string().required(mandatoryMessage),
      })
      .typeError(mandatoryMessage),
    // .required(mandatoryMessage),
  });
};
