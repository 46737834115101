// import React from 'react';

import {
  Campaign,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_TEXT,
} from './interfaces/Campaign';
import getPtStatus from './util/getPtStatusText';

export default (): Campaign => {
  return {
    id: 0,
    participant_id: 0,
    participantName: '',
    title: '',
    description: '',
    checkRegulation: true,
    stock: '',
    mechanic: {
      id: 0,
      campaignListImage: '',
      created: '',
      description: '',
      emailImage: '',
      homeImage: '',
      internalImage: '',
      materialLink: '',
      name: '',
    },
    culture: [],
    audience: [],
    startDate: null,
    endDate: null,
    prize: {
      description: '',
      name: '',
    },
    affordPoints: 0,
    complementaryAffordPoints: 0,
    complementaryLocalBudget: 0,
    complementaryCrmBudget: 0,
    expectedSellIn: 0,
    expectedSellOut: 0,
    goal: '',
    campaign_id: 14,
    rules_participation: '',
    summary: '',
    goals: [],
    observation: '',
    status: {
      id: CAMPAIGN_STATUS.NEW_REQUEST_STATUS,
      name: getPtStatus(CAMPAIGN_STATUS.NEW_REQUEST_STATUS),
      statusText: CAMPAIGN_STATUS_TEXT.NEW_REQUEST_STATUS,
    },
    createdAt: null,
    comments: [],
    highlight: { status: false, id: null },
    sendEmail: false,
    profileTurnToApprove: [],
    communicationKit: '',
  };
};
