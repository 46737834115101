import React from 'react';

import CompleteRegistration from 'components/CompleteRegister/ProducerCompleteRegister';
import { Container } from './styles';

const CompleteProducerRegistration: React.FC = () => {
  return (
    <Container>
      <CompleteRegistration />
    </Container>
  );
};

export default CompleteProducerRegistration;
