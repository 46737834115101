/* eslint-disable no-restricted-properties */
import React, { useEffect, useRef } from 'react';
import { useSpring } from 'react-spring';

import { Award as IAward, RouletteStatus } from 'components/Roulette/types';
import { useRoulette } from 'components/Roulette/hooks/use-roulette';
import { Award } from './Award';
import { Slice } from './Slice';

import { SpinWrapper } from './styles';

type Props = {
  onSpinEnd: (award: IAward) => void;
  onSpinStart: () => void;
  spinToAward: IAward | null;
};

export const Spinner: React.FC<Props> = ({
  onSpinEnd,
  onSpinStart,
  spinToAward,
}) => {
  const { status, awards } = useRoulette();
  const isSpinning = useRef(false);
  const lastDeg = useRef(0);
  const currentDeg = useRef(0);

  const [animateProps, animationApi] = useSpring(() => ({
    transform: 'rotate(0deg)',
    config: {
      easing: (x: number) =>
        // https://easings.net/#easeInOutQuart
        x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
      duration: 8000, // 8s
    },
  }));

  useEffect(() => {
    if (isSpinning.current) return;

    if (spinToAward) {
      /** Número total de voltas */
      const totalWheelRolls = 10 * 360;

      /** Rotação atual da roleta (valor inicial: 0) */
      const currentWheelDeg = currentDeg.current;

      /** Rotação alvo */
      const targetRotation = spinToAward.slice_data.deg;

      /**  Última rotação alvo (valor inicial: 0) */
      const lastTargetRotation = lastDeg.current;

      /** Calcula o total de voltas e a posição exata onde deve parar */
      const degsToRotate =
        currentWheelDeg +
        (360 - lastTargetRotation) +
        targetRotation +
        totalWheelRolls;

      animationApi({
        transform: `rotate(${degsToRotate}deg)`,
        onStart: () => {
          isSpinning.current = true;
          onSpinStart();
        },
        onRest: () => {
          /** On animation finish */

          onSpinEnd(spinToAward);

          lastDeg.current = spinToAward.slice_data.deg;
          isSpinning.current = false;
          currentDeg.current = degsToRotate;
        },
      });
    }
  }, [animationApi, spinToAward, onSpinEnd, onSpinStart]);

  const isDrawed = status === RouletteStatus.DRAWED;

  return (
    <SpinWrapper style={animateProps}>
      <Slice
        index={1}
        isWinner={isDrawed && spinToAward?.slice_data.index === 2}
      />
      <Slice
        index={2}
        isWinner={isDrawed && spinToAward?.slice_data.index === 3}
      />
      <Slice
        index={3}
        isWinner={isDrawed && spinToAward?.slice_data.index === 4}
      />
      <Slice
        index={4}
        isWinner={isDrawed && spinToAward?.slice_data.index === 5}
      />
      <Slice
        index={5}
        isWinner={isDrawed && spinToAward?.slice_data.index === 6}
      />
      <Slice
        index={6}
        isWinner={isDrawed && spinToAward?.slice_data.index === 1}
      />

      {!!awards.length && (
        <>
          <Award
            index={1}
            award={awards[0]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 1}
          />
          <Award
            index={2}
            award={awards[1]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 2}
          />
          <Award
            index={3}
            award={awards[2]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 3}
          />
          <Award
            index={4}
            award={awards[3]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 4}
          />
          <Award
            index={5}
            award={awards[4]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 5}
          />
          <Award
            index={6}
            award={awards[5]}
            isWinner={isDrawed && spinToAward?.slice_data.index === 6}
          />
        </>
      )}
    </SpinWrapper>
  );
};
