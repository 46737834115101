import styled from 'styled-components';
import DefaultInput from 'components/shared/Input/BaseInput';
import DefaultTextArea from 'components/shared/TextArea/BaseTextArea';
import DefaultDatePicker from 'components/shared/DatePicker';
import { Button as DefaultButton } from 'components/shared';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  h4 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 20px;
  }

  span {
    color: ${({ theme }) => theme.font.color.quartenary};
    font-size: 14px;
    margin-bottom: 10px;
  }

  .custom-file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
    color: #000;
    border-radius: 4px;
  }

  .custom-file-input input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }

  .custom-file-input label {
    position: relative;
    display: inline-block;
    background: ${({ theme }) => theme.font.color.primary};
    color: #fff;
    padding: 6px 12px;
    cursor: pointer;
  }

  .custom-file-input:hover label {
    background: ${({ theme }) => theme.font.color.primary};
  }
`;
export const DatePicker = styled(DefaultDatePicker)`
  margin-bottom: 20px;
  max-width: 250px;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 8px;
  }
`;

export const Input = styled(DefaultInput)`
  ._inputContainer {
    width: 100%;
    margin-top: 10px;
    max-width: 900px;
    height: 44px;
    margin-bottom: 20px;
  }
`;

export const TextArea = styled(DefaultTextArea)`
  margin-top: 10px;
  ._inputContainer {
    width: 100%;
    max-width: 900px;
    height: 95px;
    margin-bottom: 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  > div {
    width: 194px;
    & + div {
      margin-left: 15px;
    }
  }
  ._inputContainer {
    width: 194px;
    height: 44px;
  }
`;

export const Checkboxes = styled.div`
  display: flex;

  div {
    margin: 10px 5px;
  }

  div span {
    margin-left: 5px;
  }
`;

export const DownloadButton = styled(DefaultButton)`
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 25px;
  max-width: 260px;
  align-self: center;
  height: 44px;
  background-color: ${({ theme }) => theme.font.color.primary};
  button:hover {
    background-color: ${({ theme }) => theme.font.color.primary};
    filter: grayscale(50%);
    transition: 0.5s;
  }

  @media (max-width: 768px) {
    max-width: 180px;
    font-size: 12px;
  }
`;
