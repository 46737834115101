import React, { createContext, useState, useEffect, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { uuid } from 'uuidv4';

type ContextType = {
  showToast: (params: Params) => void;
};

type Props = {
  children: React.ReactNode;
};

type ToastType = 'success' | 'info' | 'error' | 'warning';

type Params = {
  message: string;
  type: ToastType;
};

type Toast = {
  id: string;
  type: ToastType;
  message: string;
};

const SimpleToastContext = createContext<ContextType>({} as ContextType);

export const SimpleToastProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [currentToast, setCurrentToast] = useState<Toast | null>(null);

  const showToast = useCallback(({ message, type }: Params) => {
    setToasts(prev => [
      ...prev,
      {
        id: uuid(),
        message,
        type,
      },
    ]);
  }, []);

  useEffect(() => {
    // Ref: https://v4.mui.com/components/snackbars/#consecutive-snackbars

    if (toasts.length) {
      // Set a new snack when we don't have an active one
      setCurrentToast({ ...toasts[0] });
      setToasts(prev => prev.slice(1));
      setIsOpen(true);
    } else if (toasts.length && isOpen) {
      // Close an active snack when a new one is added
      setIsOpen(false);
    }
  }, [toasts, isOpen]);

  const handleExit = () => {
    setCurrentToast(null);
  };

  return (
    <SimpleToastContext.Provider
      value={{
        showToast,
      }}
    >
      <Snackbar
        key={currentToast ? currentToast.id : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setIsOpen(false)}
        onExited={handleExit}
      >
        <MuiAlert
          onClose={() => setIsOpen(false)}
          severity={currentToast?.type}
        >
          {currentToast?.message}
        </MuiAlert>
      </Snackbar>
      {children}
    </SimpleToastContext.Provider>
  );
};

export const useSimpleToast = () => React.useContext(SimpleToastContext);
