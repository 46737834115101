import { pluginApi } from 'services/api';
import { Option } from 'components/shared/Select';
import { PlanAuthorsAndApprovers } from 'services/strategic-plans/actions/interfaces';

export default async (): Promise<Option[]> => {
  const { data } = await pluginApi.get<PlanAuthorsAndApprovers[]>(
    '/strategy-plans/get-plan-author',
  );
  
  return data.map<Option>(({ id, name }) => ({
    value: id.toString(),
    title: name,
  }));
};