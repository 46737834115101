import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from 'context/AuthContext';
import { IProfile, PROFILES } from 'config/constants';
import { getAvailableProfiles } from 'state/modules/header/selectors';

import OptOutModal from 'components/Auth/Modals/OptOutModal';

import { Separator, Content, Container, Button, Title } from './styles';

interface Props {
  profile: IProfile;
}

const OptOut: React.FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { participant, signOut } = useAuth();
  const availableProfiles = useSelector(getAvailableProfiles);

  const getProgram = useCallback((): string => {
    if (participant.profile === PROFILES.producer) {
      return 'JUNTOS Produtor';
    }
    if (availableProfiles.indirect?.establishmentType === 'Revenda') {
      return 'JUNTOS Revenda';
    }
    if (availableProfiles.indirect?.establishmentType === 'Cooperativa') {
      return 'JUNTOS Cooperativa';
    }
    if (availableProfiles.indirect?.establishmentType === 'Terra Brasil') {
      return 'JUNTOS Terra Brasil';
    }
    return '';
  }, [availableProfiles.indirect, participant.profile]);

  const wichProgram = getProgram();

  return (
    <>
      <Separator />
      <Container>
        <Title>Desejo sair do {wichProgram}</Title>

        <Content>
          <span>
            Clicando no botão abaixo você não poderá mais acessar o Programa{' '}
            {wichProgram}. Seus dados cadastrados nesta campanha serão
            inativados e seus pontos cancelados.
          </span>
          <Button
            type="button"
            buttonRole="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Não quero mais participar
          </Button>
          {isModalOpen ? (
            <OptOutModal
              isOpen
              onRequestClose={() => setIsModalOpen(false)}
              signOut={signOut}
            />
          ) : null}
        </Content>
      </Container>
    </>
  );
};

export default OptOut;
