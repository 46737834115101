import React, { useCallback } from 'react';
import { Button } from 'components/shared';
import { Option } from 'components/shared/Select';
import { getClientGroups } from 'services/strategic-plans/getClientGroups';
import { Container, Content } from './styles';
import ClientGroupSelect from './select';

interface Props {
  clientGroupSelected: Option | null;
  setClientGroupSelected(value: Option | null): void;
  handleCreatePlan(): void;
  onBlur(): void;
  onFocus(): void;
}

const ClientSelector: React.FC<Props> = ({
  clientGroupSelected,
  setClientGroupSelected,
  handleCreatePlan,
  onBlur,
  onFocus,
}) => {
  const loadItems = useCallback(async () => {
    const clientGroups = await getClientGroups();
    return clientGroups.map(item => ({
      title: item.name,
      value: item.id.toString(),
    }));
  }, []);

  return (
    <Container>
      <Content onBlur={onBlur} onFocus={onFocus}>
        <ClientGroupSelect
          value={clientGroupSelected}
          className="select-client"
          loadItems={loadItems}
          setValue={currentValue => setClientGroupSelected(currentValue)}
        />
        <Button
          type="button"
          buttonRole="primary"
          onClick={handleCreatePlan}
          disabled={clientGroupSelected === null}
        >
          Criar Plano
        </Button>
      </Content>
    </Container>
  );
};

export default ClientSelector;
