import { parseISO } from 'date-fns';

import {
  ExtractApi,
  Extract as IExtract,
  Resume,
} from 'services/extract/interfaces';

export default (data: ExtractApi): IExtract => {
  return {
    balance: data.balance,
    resume: Array.isArray(data.resume)
      ? data.resume.map(
          resume =>
            ({
              campaing: resume.campaing.all
                ? { id: 0, title: 'Total' }
                : resume.campaing,
              points: resume.points,
              total: resume.total,
              total_resume: resume.total_resume
            } as Resume),
        )
      : [
          {
            campaing: data.resume.campaing.all
              ? { id: 0, title: 'Total' }
              : data.resume.campaing,
            total: data.resume.total,
            total_resume: data.resume.total_resume,
            points: data.resume.points,
          },
        ],
    statement: data.statement?.map(statement => ({
      campaign: {
        id: statement.campaign?.id,
        title: statement.campaign?.title,
        total: statement?.campaign?.total,
        total_resume: statement?.campaign?.total  ?? 0, 
      },
      points: statement?.points?.map(point => ({
        id: point.id,
        value: point.value,
        description: point.description,
        dateRef: point.date_ref,
        type: point.type,
        originType: point.origin_type,
        originId: point.origin_id,
        created: parseISO(point.created),
        balanceUnit: {
          id: point.balance_unit.id,
          name: point.balance_unit.name,
          description: point.balance_unit.description,
        },
        balanceStatus: {
          id: point.balance_status.id,
          name: point.balance_status.name,
        },
        campaign: {
          id: point.campaign.id,
          title: point.campaign.title,
        },
        distributed: point.distributed?.map(distributedItem => ({
          value: distributedItem.value,
          balanceUnitId: distributedItem.balance_unit_id,
          balanceUnitName: distributedItem.balance_unit_name,
          created: parseISO(distributedItem.created),
        })),
      })),
    })),
  };
};
