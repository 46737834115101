import { pluginApi } from 'services/api';
interface ApiResponse {
  establishments:
    {
      title: string;
      type: string;
      value: number;
    };
}
export default async (campaignId: number, filter: number): Promise<any> => {
  const { data } = await pluginApi.get(
    `participants/establishments/getEstablishmentWithFilter?filter=${filter}&campaing=${campaignId}`,
  );
  return data;
};