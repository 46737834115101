import React, { useEffect, useState } from 'react';

import CircularProgress from 'components/Home/Performance/CircularProgress';
import { Potentializer } from 'state/modules/goals/types';
import useDimensions from 'hooks/use-window-dimensions';
import { pluginApi } from 'services/api';
import { formatPoints } from 'util/points';
import { useAuth } from 'context/AuthContext';
import Box from '../Box';
import {
  WrapperValues,
  Item,
  ProgressTitleV,
  CircularSectionItem,
  Content,
  TableStyle,
  TableRow,
  TableHeader,
  GoalTextStyled,
  TableCell,
  ProgressTitle,
  GoalText,
  WrapperValuesOld,
} from '../Box/styles';
import RealizedProgress from '../RealizedProgress';

interface PotentializersProps {
  potentializers: Potentializer[] | null;
}
const Potentializers: React.FC<PotentializersProps> = ({ potentializers }) => {
  const orderProducts = potentializers?.sort((a, b) => a.position - b.position);

  const { participant } = useAuth();

  const { width } = useDimensions();
  const isWide = width > 768;

  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await pluginApi('/goals/product-segment');
        setProducts(data);
      } catch (error) {
        console.error('Erro ao obter os dados:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box title="POTENCIALIZADORES">
      {!!orderProducts &&
      orderProducts &&
      participant.establishment.classificacao !== 'Terra Brasil' &&
      orderProducts.length === 4 ? (
        <WrapperValues>
          <Item key={orderProducts[0]?.name}>
            <>
              <ProgressTitleV color={orderProducts[0]?.color} isWide={isWide}>
                <strong>{orderProducts[0]?.name}</strong>
              </ProgressTitleV>

              <GoalTextStyled isWide={isWide}>
                Objetivo US$ {orderProducts[0]?.goal}
              </GoalTextStyled>
            </>
            <Content>
              <CircularSectionItem>
                <CircularProgress
                  color={orderProducts[0]?.color}
                  percent={orderProducts[0]?.percentage}
                >
                  <RealizedProgress
                    percent={orderProducts[0]?.percentage}
                    realized={`US$ ${orderProducts[0]?.realized} `}
                  />
                </CircularProgress>
              </CircularSectionItem>

              <TableStyle>
                <table className="table-1">
                  {products &&
                    products.Herbicidas &&
                    products.Herbicidas.map((product: any) => (
                      <tbody key={product.id}>
                        <TableRow>
                          <TableHeader>
                            {product.name}
                            <span>®</span>
                          </TableHeader>
                          <TableCell>
                            Objetivo: <br /> US${' '}
                            {formatPoints(product.meta, 0, 0)}
                          </TableCell>
                          <TableCell>
                            Realizado <br /> US${' '}
                            {formatPoints(product.result, 0, 0) || 0}
                          </TableCell>
                        </TableRow>
                      </tbody>
                    ))}
                </table>
              </TableStyle>
            </Content>
          </Item>

          <Item key={orderProducts[1]?.name}>
            <ProgressTitleV color={orderProducts[1]?.color} isWide={isWide}>
              <strong>{orderProducts[1]?.name}</strong>
            </ProgressTitleV>

            <GoalTextStyled isWide={isWide}>
              Objetivo US$ {orderProducts[1]?.goal}
            </GoalTextStyled>

            <Content>
              <CircularSectionItem>
                <CircularProgress
                  color={orderProducts[1]?.color}
                  percent={orderProducts[1]?.percentage}
                >
                  <RealizedProgress
                    percent={orderProducts[1]?.percentage}
                    realized={`US$ ${orderProducts[1]?.realized}`}
                  />
                </CircularProgress>
              </CircularSectionItem>

              <TableStyle>
                <table className="table-2">
                  {products &&
                    products.Inseticidas &&
                    products.Inseticidas.map((product: any) => (
                      <tbody key={product.id}>
                        <TableRow>
                          <TableHeader>
                            {product.name}
                            <span>®</span>
                          </TableHeader>
                          <TableCell>
                            Objetivo: <br /> US${' '}
                            {formatPoints(product.meta, 0, 0)}
                          </TableCell>
                          <TableCell>
                            Realizado <br /> US${' '}
                            {formatPoints(product.result, 0, 0) || 0}
                          </TableCell>
                        </TableRow>
                      </tbody>
                    ))}
                </table>
              </TableStyle>
            </Content>
          </Item>

          <Item key={orderProducts[2]?.name}>
            <ProgressTitleV color={orderProducts[2]?.color} isWide={isWide}>
              <strong>{orderProducts[2]?.name}</strong>
            </ProgressTitleV>

            <GoalTextStyled isWide={isWide}>
              Objetivo US$ {orderProducts[2]?.goal}
            </GoalTextStyled>

            <Content>
              <CircularSectionItem>
                <CircularProgress
                  color={orderProducts[2]?.color}
                  percent={orderProducts[2]?.percentage}
                >
                  <RealizedProgress
                    percent={orderProducts[2]?.percentage}
                    realized={`US$ ${orderProducts[2]?.realized}`}
                  />
                </CircularProgress>
              </CircularSectionItem>

              <TableStyle>
                <table className="table-3">
                  {products &&
                    products['Inseticidas by Rynaxypyr'] &&
                    products['Inseticidas by Rynaxypyr'].map((product: any) => (
                      <tbody key={product.id}>
                        <TableRow>
                          <TableHeader>
                            {product.name}
                            <span>®</span>
                          </TableHeader>
                          <TableCell>
                            Objetivo: <br /> US${' '}
                            {formatPoints(product.meta, 0, 0)}
                          </TableCell>
                          <TableCell>
                            Realizado <br /> US${' '}
                            {formatPoints(product.result, 0, 0) || 0}
                          </TableCell>
                        </TableRow>
                      </tbody>
                    ))}
                </table>
              </TableStyle>
            </Content>
          </Item>
          <Item key={orderProducts[3]?.name}>
            <ProgressTitleV color={orderProducts[3]?.color} isWide={isWide}>
              <strong className="last-segment">{orderProducts[3]?.name}</strong>
            </ProgressTitleV>

            <GoalTextStyled isWide={isWide}>
              Objetivo US$ {orderProducts[3]?.goal}
            </GoalTextStyled>

            <Content>
              <CircularSectionItem>
                <CircularProgress
                  color={orderProducts[3]?.color}
                  percent={orderProducts[3]?.percentage}
                >
                  <RealizedProgress
                    percent={orderProducts[3]?.percentage}
                    realized={`US$ ${orderProducts[3]?.realized}`}
                  />
                </CircularProgress>
              </CircularSectionItem>
              <TableStyle>
                <table className="table-4">
                  {products &&
                    products.Fungicidas &&
                    products.Fungicidas.map((product: any) => (
                      <tbody key={product.id}>
                        <TableRow>
                          <TableHeader>
                            {product.name}
                            <span>®</span>
                          </TableHeader>
                          <TableCell>
                            Objetivo: <br /> US${' '}
                            {formatPoints(product.meta, 0, 0)}
                          </TableCell>
                          <TableCell>
                            Realizado <br /> US${' '}
                            {formatPoints(product.result, 0, 0) || 0}
                          </TableCell>
                        </TableRow>
                      </tbody>
                    ))}
                </table>
              </TableStyle>
            </Content>
          </Item>
        </WrapperValues>
      ) : (
        <WrapperValuesOld>
          {!!orderProducts &&
            orderProducts.map(potentializer => (
              <Item key={potentializer.name}>
                <ProgressTitle>
                  {potentializer.name}
                  <span>®</span>
                </ProgressTitle>
                <GoalText>Objetivo {potentializer.goal} L</GoalText>
                <CircularSectionItem>
                  <CircularProgress
                    color={potentializer.color}
                    percent={potentializer.percentage}
                  >
                    <RealizedProgress
                      percent={potentializer.percentage}
                      realized={`${potentializer.realized} L`}
                    />
                  </CircularProgress>
                </CircularSectionItem>
              </Item>
            ))}
        </WrapperValuesOld>
      )}
    </Box>
  );
};

export default Potentializers;
