import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ChannelSelect from 'components/Auth/Register/Form/Selects/ChannelSelect';
import ComoFicouConhecendoSelect from 'components/Auth/Register/Form/Selects/ComoFicouConhecendoSelect';
import GenderSelect from 'components/Auth/Register/Form/Selects/GenderSelect';
import PublicPlaceSelect from 'components/Auth/Register/Form/Selects/PublicPlaceSelect';
import UfsSelect from 'components/Auth/Register/Form/Selects/UfsSelect';
import { Alert, Avatar, Button, Input, PasswordInput } from 'components/shared';
import { CepData, CepInput } from 'components/shared/CepInput';
import PasswordHelp from 'components/shared/PasswordHelp';
import { PROFILES } from 'config/constants';
import { useToast } from 'context/ToastContext';
import { PrimeiroAcessoDTO } from 'pages/primeiro-acesso';
import { Regulation } from 'services/auth/interfaces/Participant';
import getAllDataRegulations from 'services/register/regulation/getAllDataRegulations';
import saveParticipant from 'services/register/saveParticipant';
import { Form, FormField, FormFieldGroup } from '../styles';
import { validationSchema } from './validation';

type FormData = {
  name: string;
  nickname: string;
  email: string;
  cpf: string;
  state_registration: string;
  regulation: boolean;
  avatar: string;
  ddd: string;
  phone: string;
  gender: string;
  medium_select: string;
  get_know: string;
  birth_date: string;
  zip_code: string;
  public_place: string;
  numero: string;
  rua: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: string;
  policy_expose: string;
  password: string;
  confirm_password: string;
};

type Props = {
  initialData: PrimeiroAcessoDTO;
};

export const ProdutorRegisterForm: React.FC<Props> = ({ initialData }) => {
  const formMethods = useForm<FormData>({
    validationSchema,
    defaultValues: {
      avatar: initialData.participant?.picture,
      email: initialData.participant?.email,
      name: initialData.participant?.name,
      nickname: initialData.participant?.nick_name,
      state_registration: initialData.participant?.state_registration || '',
      cpf: initialData.cpf || initialData.participant?.cpf,
      ddd: initialData.participant?.area_code,
      phone: initialData.participant?.cell_phone,
      bairro: initialData.participant?.address.district || '',
      complemento: initialData.participant?.address.complement || '',
      municipio: initialData.participant?.address.city || '',
      zip_code: initialData.participant?.address.zip_code || '',
      uf: initialData.participant?.address.state_code || '',
      rua: initialData.participant?.address.street || '',
      numero: initialData.participant?.address.number || '',
      public_place: initialData.participant?.address.public_place || '',
      regulation: false,
    },
  });
  const {
    handleSubmit,
    control,
    errors,
    setValue,
    formState: { isSubmitting },
  } = formMethods;

  const { addToast } = useToast();
  const history = useHistory();

  const onFormSubmit = handleSubmit(async (data: FormData) => {
    try {
      const regulation = await getAllDataRegulations(PROFILES.producer);
      const regulationAcceptPayload = regulation.map(
        reg =>
          ({
            version: String(reg.version),
            regulation_id: String(reg.id),
            policyExposed: data.policy_expose === '1' ? 'yes' : 'no',
          } as Regulation),
      );

      await saveParticipant({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...initialData.participant!,
        profile: PROFILES.producer,
        name: data.name,
        nick_name: data.nickname,
        cpf: data.cpf,
        state_registration: data.state_registration,
        email: data.email,
        area_code: data.ddd,
        cell_phone: data.phone,
        password: data.password,
        password_confirmation: data.confirm_password,
        picture: data.avatar,
        address: {
          city: data.municipio,
          complement: data.complemento,
          district: data.bairro,
          public_place: data.public_place,
          state_code: data.uf,
          street: data.rua,
          zip_code: data.zip_code,
          number: data.numero,
        },
        birth_date: data.birth_date,
        gender: data.gender,
        get_to_know: data.get_know,
        medium: data.medium_select,
        regulations_accepted: regulationAcceptPayload,
      });

      addToast({
        title:
          'Cadastro finalizado com sucesso, faça seu login para acessar a plataforma.',
        type: 'success',
      });

      setTimeout(() => {
        history.push('/', null);
      }, 3000);
    } catch (error) {
      addToast({
        type: 'error',
        title:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error as any).response?.data?.message ||
          'Ocorreu um erro ao tentar finalizar seu cadastro.',
      });
    }
  });

  const onCepFill = (data: CepData) => {
    setValue('rua', data.street);
    setValue('bairro', data.bairro);
    setValue('municipio', data.city);
    setValue('uf', data.state);
  };

  useEffect(() => {
    /** Scrolla para o topo da página caso o usuário não tenha aceito os regulamentos */
    if (errors.regulation || errors.policy_expose) {
      const rootElement = document.getElementById('root');

      rootElement?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errors]);

  return (
    <FormContext {...formMethods}>
      <Form onSubmit={onFormSubmit}>
        {(errors.regulation || errors.policy_expose) && (
          <Alert variation="error">
            {errors.regulation?.message && (
              <div>{errors.regulation.message}</div>
            )}
            {errors.policy_expose?.message && (
              <div>{errors.policy_expose.message}</div>
            )}
          </Alert>
        )}
        <FormField className="checkbox">
          <Controller as={Checkbox} name="regulation" control={control} />
          <a
            href="/regulamentos?role=producer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Eu concordo com os{' '}
            <span className="underline">Regulamentos e Termos</span> do Programa
            JUNTOS FMC.
          </a>
          <br />
        </FormField>

        <FormField className="policy-exposed-alert">
          <span>Você se considera uma pessoa politicamente exposta?</span>
          <p>
            São consideradas pessoas politicamente expostas: o agente público ou
            político eleito, candidato a qualquer cargo político ou de agente
            político, bem como seus parentes até segundo grau ou pessoas com
            relacionamento próximo, durante o desempenho ou que tenham
            desempenhado tal função nos últimos 5 anos. Por agente público
            entende-se a pessoa física ou jurídica que presta qualquer serviço
            ao estado ou às pessoas jurídicas da administração pública indireta,
            por exemplo, servidores públicos, agentes políticos, militares ou
            ainda particulares em colaboração com o poder público, ou aqueles
            equiparados ao agente público no exercício de cargo ou função.
          </p>

          <Controller
            name="policy_expose"
            as={props => (
              <RadioGroup {...props}>
                <FormFieldGroup>
                  <div className="policy_expose--option">
                    <Radio value="1" />
                    <span>Sim</span>
                  </div>
                  <div className="policy_expose--option">
                    <Radio value="0" />
                    <span>Não</span>
                  </div>
                </FormFieldGroup>
              </RadioGroup>
            )}
          />
        </FormField>
        <div className="divider" />
        <div className="form--fields">
          <FormField>
            <Avatar name="avatar" />
          </FormField>
          <FormField>
            <Input name="name" label="Nome completo*" inputRole="secondary" />
          </FormField>
          <FormField>
            <Input
              name="nickname"
              label="Como gostaria de ser chamado(a)*"
              inputRole="secondary"
            />
          </FormField>
          <FormField>
            <Input name="email" label="Email*" inputRole="secondary" />
          </FormField>
          <FormField>
            <Input
              name="cpf"
              label="CPF*"
              inputRole="secondary"
              pattern="XXX.XXX.XXX-XX"
              numbersOnly
            />
          </FormField>
          <FormField>
            <Input
              name="state_registration"
              label="Inscrição Estadual"
              inputRole="secondary"
              pattern="XX.XXX.XXXX-X"
              numbersOnly
            />
          </FormField>
          <FormFieldGroup>
            <div style={{ maxWidth: '90px' }}>
              <Input
                name="ddd"
                numbersOnly
                pattern="(XX)"
                label="DDD*"
                inputRole="secondary"
              />
            </div>
            <Input
              name="phone"
              numbersOnly
              label="Celular*"
              pattern="X XXXX-XXXX"
              inputRole="secondary"
            />
          </FormFieldGroup>
          <FormField>
            <GenderSelect name="gender" forceStringAsValue />
          </FormField>
          <FormField>
            <ChannelSelect
              name="medium_select"
              label="Em qual estabelecimento você compra os produtos FMC?*"
              inputRole="primary"
              placeholder="Selecione"
              forceStringAsValue
            />
          </FormField>
          <FormField>
            <ComoFicouConhecendoSelect name="get_know" forceStringAsValue />
          </FormField>
          <FormField>
            <Input
              name="birth_date"
              label="Data de nascimento* (idade mínima: 18 anos)"
              inputRole="primary"
              placeholder="00/00/0000"
              pattern="XX/XX/XXXX"
            />
          </FormField>
          <FormField>
            <CepInput
              name="zip_code"
              label="CEP*"
              placeholder="00000-000"
              inputRole="primary"
              onCepDataFetch={onCepFill}
            />
          </FormField>
          <FormField>
            <PublicPlaceSelect name="public_place" forceStringAsValue />
          </FormField>
          <FormField>
            <Input name="rua" label="Endereço*" inputRole="primary" />
          </FormField>
          <FormField>
            <Input
              name="numero"
              label="Número* (Caso seu endereço não possua número, preencher como: S/N)"
              inputRole="primary"
              maxLength={7}
            />
          </FormField>
          <FormField>
            <Input name="complemento" label="Complemento" inputRole="primary" />
          </FormField>
          <FormField>
            <Input name="bairro" label="Bairro*" inputRole="primary" />
          </FormField>
          <FormField>
            <Input name="municipio" label="Município*" inputRole="primary" />
          </FormField>
          <FormField>
            <UfsSelect
              name="uf"
              inputRole="primary"
              label="Estado*"
              forceStringAsValue
            />
          </FormField>
        </div>

        <div className="divider" />
        <h1>Segurança</h1>

        <div className="form--fields">
          <FormField>
            <PasswordInput
              name="password"
              help={PasswordHelp}
              label="Senha"
              inputRole="secondary"
            />
          </FormField>
          <FormField>
            <PasswordInput
              name="confirm_password"
              label="Confirmar senha"
              inputRole="secondary"
            />
          </FormField>
        </div>

        <div className="form--action">
          <Button
            buttonRole="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            CONFIRMAR
          </Button>
        </div>
      </Form>
    </FormContext>
  );
};
