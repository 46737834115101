import styled from 'styled-components';

import * as InputStyles from 'components/shared/Input/styles';

export const Container = styled.div``;

export const Form = styled.form`
  button {
    height: 44px;
  }
`;

export const FormInput = styled.div`
  margin-bottom: 10px;

  /** Override input styles */
  ${InputStyles.InputContainer} {
    height: 44px;
  }
`;

export const Title = styled.div`
  height: 44px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  padding: 20px 0 60px 0;
  margin: auto;

  @media (min-width: 768px) {
    width: 743px;
  }

  h3 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 24px;
  }

  p {
    margin-top: 18px;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  button {
    width: auto;
    height: 40px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: ${({ theme }) => theme.font.color.tertiary};
    }
  }
`;
