import routeMap from 'routes/route-map';
import ProducersWhoBuy from 'pages/ProducersWhoBuy';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.producersWhoBuy,
    component: ProducersWhoBuy,
    accessPage: 'Produtores que compram',
  },
];

export default routes;
