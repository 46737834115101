/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { GRM, RTC, KAM } from 'config/constants';
import { Option } from 'components/shared/Select';
import { Campaign } from 'services/cockpit/interfaces/campaign';
import {
  Mechanic,
  ApproverProfile,
  Campaign as Campaigns,
} from 'services/campaigns-manager/interfaces/Campaign';
import getAllCampaigns, {
  FilterOptions,
} from 'services/campaigns-manager/getAllCampaigns';
import { Channel } from 'services/campaigns-manager/interfaces';
import closeIcon from 'assets/images/training/close-icon.svg';
import DirectorsSelect from 'components/CampaignsManager/CampaignForm/Selects/Directors';
import RegionalSelect from 'components/CampaignsManager/CampaignForm/Selects/Regional';
import CampaignStatusSelect from 'components/CampaignsManager/CampaignForm/Selects/CampaignStatus';
import MechanicsSelect from 'components/CampaignsManager/CampaignForm/Selects/Mechanics';
import ChannelsSelect from 'components/CampaignsManager/CampaignForm/Selects/Channels';
import SafraSelect from 'components/CampaignsManager/CampaignForm/Selects/Safra';
import getCampaigns from 'services/cockpit/getCampaign';
import getRegionals from 'services/cockpit/getRegional';
import getDirectors from 'services/cockpit/getDirectors';

import ReactLoading from 'react-loading';
import { Container, Filter, Button } from './styles';

interface Props {
  profile: ApproverProfile;
  campaign: Campaigns[];
  setFilteredCampaigns(data: Campaigns[] | null): void;
}

const Filters: React.FC<Props> = ({
  profile,
  campaign,
  setFilteredCampaigns,
}) => {
  const [channelSelected, setChannelSelected] = useState<Channel | null>(null);
  const [directorSelected, setDirectorSelected] = useState<Option | null>(null);
  const [regionalSelected, setRegionalSelected] = useState<Option | null>(null);
  const [mechanicSelected, setMechanicSelected] = useState<Mechanic | null>(
    null,
  );
  const [statusSelected, setStatusSelected] = useState<Option | null>(null);
  const [safraSelected, setSafraSelected] = useState<Option | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const profilesToNotShowDirectors: ApproverProfile[] = [GRM, RTC, KAM];
  const profilesToNotShowRegional: ApproverProfile[] = [RTC, KAM];
  const [moreFilters, setMoreFilters] = useState(false);
  const [filters, setFilters] = useState<FilterOptions | undefined>(undefined);
  const [showRegionalSelect, setShowRegionalSelect] = useState(true);
  const [showDirectorSelect, setShowDirectorSelect] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await getCampaigns();

      setCampaigns(data);
    };
    fetchCampaign();
  }, []);

  useEffect(() => {
    getRegionals().then(data => {
      setShowRegionalSelect(data.length > 1);
      if (data.length === 1) {
        setRegionalSelected({
          value: data[0].id.toString(),
          title: data[0].name,
        });
      }
    });

    getDirectors().then(data => {
      setShowDirectorSelect(data.length > 1);
      if (data.length === 1) {
        setDirectorSelected({
          value: data[0].id,
          title: data[0].name,
        });
      }
    });
  }, []);

  useEffect(() => {
    const customerId = channelSelected?.id || undefined;
    const mechanicId = mechanicSelected?.id || undefined;
    const directorId = directorSelected?.value || undefined;
    const regionalId = regionalSelected?.value || undefined;
    const campaignId = Number(safraSelected?.value) || undefined;
    const status = statusSelected?.value || undefined;

    if (channelSelected) {
      setFilters({ customerId });
    }
    if (mechanicSelected) {
      setFilters({ mechanicId });
    }
    if (directorSelected) {
      setFilters({ directorId });
    }
    if (regionalSelected) {
      setFilters({ regionalId });
    }
    if (safraSelected) {
      setFilters({ campaignId });
    }
    if (statusSelected) {
      setFilters({ status });
    }
  }, [
    channelSelected,
    mechanicSelected,
    directorSelected,
    regionalSelected,
    safraSelected,
    statusSelected,
  ]);

  const handleFilterClick = useCallback(async () => {
    setLoading(true);

    if (filters) {
      await getAllCampaigns(filters).then(({ data }) => {
        setFilteredCampaigns(data);
      });
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    const filterCampaigns = () => {
      let filtered = campaign;

      if (statusSelected) {
        filtered = filtered.filter(
          c => c.status.id === Number(statusSelected.value),
        );

        setFilteredCampaigns(filtered);
      } else {
        setFilteredCampaigns(null);
      }
    };

    filterCampaigns();
  }, [statusSelected, campaigns]);

  const handleClickClear = () => {
    setMechanicSelected(null);
    setRegionalSelected(null);
    setDirectorSelected(null);
    setChannelSelected(null);
    setSafraSelected(null);
    setFilteredCampaigns(null);
    setStatusSelected(null);
  };

  return (
    <>
      <Container>
        <div className="header">
          <h4>Minhas campanhas</h4>
          {!moreFilters ? (
            <Filter onClick={() => setMoreFilters(true)}>
              <span>Mais filtros</span>
            </Filter>
          ) : (
            <Filter
              onClick={() => {
                setMoreFilters(false);
                setFilteredCampaigns(null);
              }}
            >
              <ReactSVG src={closeIcon} size={20} />
            </Filter>
          )}
        </div>
        <div className="contents-status">
          <span>Status</span>
          <CampaignStatusSelect
            setValue={value => setStatusSelected(value)}
            value={statusSelected}
            placeholder="Status"
          />
        </div>
        {moreFilters && (
          <>
            <div className="contents">
              <SafraSelect
                setValue={value => setSafraSelected(value)}
                value={safraSelected}
                placeholder="Safra"
                options={campaigns.map(c => ({
                  title: c.label,
                  value: String(c.id),
                  Grupo: String(c.isTerraBrasil),
                }))}
              />
              <MechanicsSelect
                setValue={value => setMechanicSelected(value)}
                value={mechanicSelected}
                placeholder="Mecânica"
              />
              {profilesToNotShowDirectors.indexOf(profile) === -1 && (
                <>
                  {showDirectorSelect ? (
                    <DirectorsSelect
                      setValue={value => setDirectorSelected(value)}
                      value={directorSelected}
                      placeholder="Diretoria"
                    />
                  ) : (
                    <span>{directorSelected?.title}</span>
                  )}
                </>
              )}
              {profilesToNotShowRegional.indexOf(profile) === -1 && (
                <>
                  {showRegionalSelect ? (
                    <RegionalSelect
                      setValue={value => setRegionalSelected(value)}
                      value={regionalSelected}
                      placeholder="Regional"
                    />
                  ) : (
                    <span>{regionalSelected?.title}</span>
                  )}
                </>
              )}
              <ChannelsSelect
                setValue={value => setChannelSelected(value)}
                value={channelSelected}
                placeholder="Estabelecimento"
              />
              <div className="contents-buttons">
                <button
                  type="button"
                  className="btn"
                  onClick={handleClickClear}
                >
                  Limpar
                </button>
                <Button
                  type="button"
                  onClick={handleFilterClick}
                  buttonRole="primary"
                >
                  {loading ? (
                    <ReactLoading
                      type="bars"
                      color="#fff"
                      height={10}
                      width={10}
                    />
                  ) : (
                    'Filtrar'
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Filters;
