import { pluginApi } from 'services/api';
import { InvestmentGroup, InvestmentGroupApi } from './interfaces/channel';
import toInvestimentGroups from './transformers/investmentGroups';

export default async (): Promise<InvestmentGroup[]> => {
  const { data } = await pluginApi.get<InvestmentGroupApi[]>(
    '/cockpit/establishmentsInvestmentGroup',
  );
  return toInvestimentGroups(data);
};
