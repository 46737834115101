import { EstablishmentTypes } from 'config/constants';
import {
  DistributionPoint,
  // DistributionPointProps,
} from './distribution-point';
import { PointType } from './types/point-type';
import { PointCategory } from './types/point-category';

export type PointComment = {
  authorId: number;
  author: string;
  author_profile: string;
  comment: string;
};

/* eslint-disable react/static-property-placement */
export type PointProps = {
  id: number;
  name: string;
  type_id: PointType;
  category: PointCategory;
  totalpoints: number;
  campaignId: number;
  savedDistribution: {
    jsonPayload?: string;
    date?: Date;
  };
  establishment: {
    id: number;
    name: string;
    type: string;
  };
  approvation: {
    id: number | null;
    status: string | null;
    /** Comentários de reprovação */
    comments: Array<PointComment>;
    approver?: {
      name: string;
      profile: string;
      is_approved: boolean;
    } | null;
  };
};

export enum ApprovationStatus {
  AGUARDANDO_DISTRIBUICAO = 1,
  AGUARDANDO_APROVACAO = 2,
  APROVADO = 3,
  REPROVADO = 4,
  PUBLICADO = 5,
}

export class Point {
  private props: PointProps;

  private distribution: DistributionPoint[] = [];

  constructor(props: PointProps) {
    this.props = props;
  }

  public getData(): PointProps {
    return this.props;
  }

  // public setDistribution(data: DistributionPointProps[]) {
  //   this.distribution = data.map(d => new DistributionPoint(d));
  // }

  public getDistribution() {
    return this.distribution;
  }

  public isTerraBrasil() {
    return this.props.establishment.type === EstablishmentTypes.TerraBrasil;
  }

  public getCategory() {
    return this.props.category;
  }

  public getCampaignId() {
    return this.props.campaignId;
  }

  public getTotalPoints() {
    return this.props.totalpoints;
  }

  /** Indica se esse ponto pode ser dividido e distribuído
   * na tela de pontos do canal/equipe. */
  public canDistribute() {
    return ![
      ApprovationStatus.AGUARDANDO_APROVACAO,
      ApprovationStatus.APROVADO,
      ApprovationStatus.PUBLICADO,
      ApprovationStatus.REPROVADO,
    ].includes(this.props.approvation.id || 0);
  }

  /** O usuário pode "re-distribuir" os pontos que foram
   * reprovados e enviá-los novamente para análise. */
  public canRedistribute() {
    return (
      this.props.category === PointCategory.CANAL &&
      this.props.approvation.id === ApprovationStatus.REPROVADO
    );
  }

  public getTeamSavedDistribution() {
    return this.props.savedDistribution;
  }

  public setSavedDistribution(payload: string) {
    this.props.savedDistribution = {
      date: new Date(),
      jsonPayload: payload,
    };
  }
}
