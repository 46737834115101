import { pluginApi } from 'services/api';
import { InvoiceDataApi, InvoiceData } from 'services/nf/interfaces';
import { transformInvoiceFromApi } from './transformers';

export default async (campaignId: number): Promise<InvoiceData[]> => {
  const { data } = await pluginApi.get<InvoiceDataApi>(
    `participants/upload-nota?campaign_id=${campaignId}`,
  );

  return transformInvoiceFromApi(data);
};
