import React from "react";
import { StyledTable } from "./styles";

interface DataTableProps {
  data: Array<{
    fileName: string;
    status: string;
    date: string;
    user: string;
    info: React.ReactNode; 
    download: string;
  }>;
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Arquivo</th>
          <th>Status</th>
          <th>Data</th>
          <th>Usuário</th>
          <th>Info</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.fileName}</td>
            <td>
              <span
                style={{
                  color:
                    row.status === "success"
                      ? "green"
                      : row.status === "error"
                      ? "red"
                      : "orange",
                }}
              >
                {row.status === "success"
                  ? "Sucesso"
                  : row.status === "error"
                  ? "Erro"
                  : "Processando"}
              </span>
            </td>
            <td>{row.date}</td>
            <td>{row.user}</td>
            <td>{row.info}</td>
            <td>{row.download}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default DataTable;