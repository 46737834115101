import React from "react";

interface ModalProps {
  message: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ message, onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        fontSize: "16px",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#E6311E",
        padding: "20px",
        border: "2px solid #FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <p>{message}</p>
      <button
        onClick={onClose}
        style={{
          background: "none",
          border: "none",
          color: "#007BFF",
          fontSize: "16px",
          textDecoration: "underline",
          cursor: "pointer",
          marginTop: "10px",
        }}
>
  Fechar
</button>
    </div>
  );
};

export default Modal;