import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;

  @media (max-width: 820px) {
    padding: 0 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    > h4 {
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: ${({ theme }) => theme.font.color.primary};
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
    margin-top: 8px;
  }

  .contents-status {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > span:first-child {
      margin: 1rem;
      font-size: 14px;
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }
  }

  .contents {
    padding: 10px 80px;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-bottom: 20px;

    @media (max-width: 820px) {
      padding: 5px 10px;
    }

    .contents-buttons {
      display: flex;
      justify-content: space-around;
      width: 80%;
      margin-right: 15em;
      margin-bottom: 15px;

      .btn {
        text-decoration: underline;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        color: ${({ theme }) => theme.font.color.primary};
      }

      @media (max-width: 820px) {
        margin: 5px;
      }
    }
  }
`;

export const Button = styled(DefaultButton)`
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  border-radius: 7px;
`;

export const Separator = styled.div`
  width: calc(100% + 90px);
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-45px);
  margin-bottom: 2px;
  margin-top: 3px;
`;

export const Filter = styled.button`
  text-decoration: underline;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 14px;
  border: none;
  height: 31px;
  text-transform: capitalize;
  margin-top: 30px;
  background-color: transparent;

  svg {
    color: ${({ theme }) => theme.font.color.primary};
    margin: -4px 10px;
  }
`;
