/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCampaign } from 'state/modules/campaigns-manager/selectors';
import CommentModal from 'components/CampaignsManager/Modals/CommentModal';
import RegulationModal from 'components/CampaignsManager/Modals/RegulationModal';
import { formatDate } from 'util/datetime';
import { ApproverApi } from 'services/campaigns-manager/interfaces/CampaignApi';
import getStatusText from 'components/CampaignsManager/utils';
import {
  Container,
  Header,
  Button,
  Circle,
  Label,
  List,
  Recuse,
} from './styles';

interface Props {
  actived: boolean;
  approvers: ApproverApi[];
}

const Approval: React.FC<Props> = ({ actived, approvers }) => {
  const campaign = useSelector(getCampaign);
  const [openRegulationModal, setOpenRegulationModal] = useState(false);
  const [isCommentPopupOpen, setIsCommentPopupOpen] = useState(false);

  const order = approvers.map(approver => approver.id).sort((a, b) => a - b);

  const closeModal = () => {
    setOpenRegulationModal(false);
  };

  const closeCommentModal = () => {
    setIsCommentPopupOpen(false);
  };

  return (
    <Container style={{ display: actived ? 'block' : 'none' }}>
      {openRegulationModal && (
        <RegulationModal
          isOpen={openRegulationModal}
          onRequestClose={closeModal}
          campaign={campaign}
        />
      )}
      <span>
        <Button type="button" onClick={() => setOpenRegulationModal(true)}>
          Clique aqui
        </Button>{' '}
        para visualizar o regulamento da campanha.
      </span>

      <Header>Fluxo de aprovação</Header>
      <List>
        {order.map(id => {
          const approver = approvers.find(a => a.id === id);

          if (!approver) return [];

          if (approver.type === 'request') {
            return (
              <div key={approver.id}>
                <Label>
                  <Circle className="circle" />
                  Campanha solicitada por {approver.name} no dia{' '}
                  {formatDate(approver.created)}
                </Label>
                <Label>
                  <Circle className="circle" />
                  Status alterado para:{' '}
                  {`"${getStatusText(approver.status_campaign)}"`}
                </Label>
              </div>
            );
          }

          if (approver.type === 'approve') {
            return (
              <div key={approver.id}>
                <Label>
                  <Circle className="circle" />
                  Campanha aceita por {approver.name} no dia{' '}
                  {formatDate(approver.created)}
                </Label>
                <Label>
                  <Circle className="circle" />
                  Status alterado para:{' '}
                  {`"${getStatusText(approver.status_campaign)}"`}
                </Label>
              </div>
            );
          }

          if (approver.type === 'disapprove') {
            return (
              <div key={approver.id}>
                <Recuse>
                  <Label>
                    <Circle className="circle" />
                    Recusada por {approver.name} em{' '}
                    {new Date(approver.created).toLocaleString('pt-BR', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    })}
                    , às{' '}
                    {new Date(approver.created).toLocaleString('pt-BR', {
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: false,
                    })}
                    .
                  </Label>
                  <button
                    type="button"
                    onClick={() => setIsCommentPopupOpen(true)}
                  >
                    Ver comentários.
                  </button>
                  {isCommentPopupOpen && (
                    <CommentModal
                      onClose={closeCommentModal}
                      onConfirm={closeCommentModal}
                      isOpen={isCommentPopupOpen}
                      value={approver.comment || ''}
                      campaign={campaign}
                    />
                  )}
                </Recuse>
                <Label>
                  <Circle className="circle" />
                  Status alterado para:{' '}
                  {`"${getStatusText(approver.status_campaign)}"`}
                </Label>
              </div>
            );
          }

          return [];
        })}
      </List>
    </Container>
  );
};

export default Approval;
