/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { Button, useSimpleToast } from 'components/shared';
import { formatNumber, formatPoints, formatPointsInput } from 'util/points';
import { ApprovalStatus } from 'services/point-management/dtos/points-distribution';
import { saveDistribution } from 'services/point-management/save-distribution';
import { useAuth } from 'context/AuthContext';
import { usePoints } from 'pages/PointManagement/use-points';
import NumberFormat from 'react-number-format';
import routeMap from 'routes/route-map';
import { useHistory } from 'react-router-dom';
import { downloadExcel } from 'util/download-excel';
import { ApprovationActions } from './approvation';
import { StatusIcon } from './status-icon';
import { Container } from './styles';
import { calculateMaxPorcentage } from './calculate-max-porcentage';

import { CommentModel } from './comment-model';
import {
  Distribution,
  DistributionPayload,
  Participant,
  Props,
  getDistributionValues,
} from './types/PointCard';

export const PointCard: React.FC<Props> = ({
  pointsDistribution,
  pointsToApprove,
  hideDistributed,
  tab,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenComments, setOpenComments] = useState(false);
  const [inputValues, setInputValues] = useState<any>({});
  const [action, setAction] = useState<'is_approving' | null>(null);
  const [participantExcel, setParticipantExcel] = useState<Participant[]>([]);

  const [idMinimumBusiness, setIdMinimumBusiness] = useState<
    undefined | number
  >();
  const [idTeamAwards, setIdTeamAwards] = useState<undefined | number>();

  const [minimumBusiness, setMinimumBusiness] = useState<any>();
  const [teamAwardsValue, setTeamAwardsValue] = useState<any>();

  const formMethods = useForm<FormData>();
  const { handleSubmit } = formMethods;

  const { showToast } = useSimpleToast();
  const history = useHistory();
  const { selectedEstablishment, refresh, points } = usePoints();
  const { participant } = useAuth();

  const maxPorcentage: any = calculateMaxPorcentage({
    field: pointsDistribution.distributions,
    advancepoints: parseFloat(pointsDistribution.demand_generation_points),
    totalpoints: pointsDistribution.total,
  });

  const sumOfFilledValues: number =
    Object.values(inputValues).length > 0
      ? Object.values(inputValues).reduce(
          (a: number, b: unknown) =>
            a + (typeof b === 'string' ? parseInt(b, 10) : 0),
          0,
        )
      : 0;

  const handleFormSubmit = handleSubmit(async () => {
    setAction('is_approving');

    const payload: DistributionPayload = {
      undistributed_point_id: pointsDistribution.undistributed_point_id,
      establishment_id: selectedEstablishment
        ? Number(selectedEstablishment.value)
        : undefined,
      participant_id: participant.id,
      profile: participant.profile_value || participant.profile,
      distributions: pointsDistribution.distributions.map(
        (participantInfo: getDistributionValues) => ({
          id: participantInfo.id,
          value: parseFloat(inputValues[participantInfo.id]) / 100 || 0,
          balance_unit_id: participantInfo.balance_unit_id,
        }),
      ),
    };

    try {
      await saveDistribution(payload);

      pointsDistribution.approval_status = 2;

      showToast({
        type: 'success',
        message: 'Distribuição enviada para aprovação.',
      });

      history.push(routeMap.pointDistribution);
      refresh();
    } catch (error) {
      showToast({
        type: 'error',
        message: `${error.message}`,
      });

      history.push(routeMap.pointDistribution);
      refresh();
    }
  });

  function canDistribute(status: number): boolean {
    return ![
      ApprovalStatus.AGUARDANDO_APROVACAO,
      ApprovalStatus.APROVADO,
      ApprovalStatus.PUBLICADO,
      ApprovalStatus.REPROVADO,
    ].includes(status || 0);
  }

  const formattedPoints = parseFloat(pointsDistribution.total);

  const isCrmOrGrm =
    participant.profile_value === 'CRM' || participant.profile_value === 'GRM';

  const handleInputChange = (id: number | string, value: any) => {
    const updatedInputValues = {
      ...inputValues,
      [id]: value || 0,
    };

    const updatedTotalValue: any = Object.values(updatedInputValues).reduce(
      (acc: any, curr: any) => acc + (parseFloat(curr) || 0),
      0,
    );

    if (updatedTotalValue / 100 > formattedPoints) {
      const previousInputValues = {
        ...inputValues,
        [id]: inputValues[id] || 0,
      };
      setInputValues(previousInputValues);
    } else {
      setInputValues(updatedInputValues);
    }
  };

  const isDisapproval =
    pointsDistribution?.approvals?.disapprovals?.is_disapproval;

  useEffect(() => {
    const allParticipants: Participant[] = [];

    pointsToApprove &&
      pointsDistribution.participants &&
      pointsDistribution.participants.forEach((obj: Participant | null) => {
        if (obj) {
          allParticipants.push(obj);
        }
      });

    setParticipantExcel(allParticipants);
  }, [pointsDistribution.participants, pointsToApprove]);

  useEffect(() => {
    if (pointsDistribution.distributions) {
      pointsDistribution.distributions.forEach((distribution: Distribution) => {
        if (distribution.balance_unit_id === 54) {
          setMinimumBusiness(distribution.minimum_business_generation_value);
          setIdMinimumBusiness(distribution.id);
        } else if (distribution.balance_unit_id === 59) {
          setIdTeamAwards(distribution.id);
          setTeamAwardsValue(
            parseFloat(distribution.minimum_team_awards_value),
          );
        }
      });
    }
  }, [
    participant.establishment.type_name,
    participant.profile_value,
    pointsDistribution.distributions,
  ]);

  const sumOfFields =
    minimumBusiness && teamAwardsValue && minimumBusiness + teamAwardsValue;

  const difference = +(pointsDistribution.total - sumOfFields).toFixed(2);

  const valueInputMinimumBusiness =
    idMinimumBusiness !== undefined ? +inputValues[idMinimumBusiness] / 100 : 0;

  const valueReceivedMinimumBusiness =
    minimumBusiness && +minimumBusiness.toFixed(2);

  const valueInputTeamAwards =
    idTeamAwards !== undefined ? +inputValues[idTeamAwards] / 100 : 0;
  const valueReceivedTeamAwards =
    teamAwardsValue && +teamAwardsValue.toFixed(2);

  const isTerraBrasil =
    participant.establishment.type_name === 'Terra Brasil'
      ? valueInputMinimumBusiness < valueReceivedMinimumBusiness ||
        valueInputTeamAwards < valueReceivedTeamAwards ||
        sumOfFilledValues / 100 !== formattedPoints
      : sumOfFilledValues / 100 !== formattedPoints;

  const readOnlyInput =
    isCrmOrGrm ||
    pointsDistribution.approval_status === 2 ||
    pointsDistribution.approval_status === 3;

  return (
    <>
      {pointsToApprove && isCrmOrGrm ? (
        <Container status={pointsDistribution.approval_status || 0}>
          <div className="point-wrapper">
            <div className="point-name">
              <strong>{pointsDistribution.name_point}</strong>
            </div>
            <div className="point-divider" />
            <div className="point-value">
              <strong>{formatPoints(pointsDistribution.total)} pontos</strong>
            </div>
            <div className="point-action">
              {hideDistributed ? (
                <button
                  type="button"
                  className="details-action"
                  onClick={() => setOpen(!isOpen)}
                >
                  Detalhes
                </button>
              ) : (
                <Button
                  type="button"
                  buttonRole="primary"
                  onClick={() => setOpen(!isOpen)}
                >
                  Distribuir
                </Button>
              )}

              <StatusIcon
                status={pointsDistribution.approval_status || 0}
                statusText={pointsDistribution.approval_status_name || ''}
              />
            </div>
          </div>
          {isOpen && (
            <div className="open--content">
              <div className="report--link">
                <a href="#" onClick={() => downloadExcel(participantExcel)}>
                  Fazer o download do relatório para ver os detalhes da
                  distribuição
                </a>
              </div>

              {isCrmOrGrm ? (
                <ApprovationActions
                  name={pointsDistribution.approvals.stages_distribution}
                  point={pointsDistribution}
                  pointsToApprove={pointsToApprove}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </Container>
      ) : (
        <Container status={pointsDistribution.approval_status || 0}>
          <div className="point-wrapper">
            <div className="point-name">
              <strong>{pointsDistribution.name_point}</strong>
            </div>
            <div className="point-divider" />
            <div className="point-value">
              <strong>{formatPoints(pointsDistribution.total)} pontos</strong>
            </div>
            <div className="point-action">
              {canDistribute(pointsDistribution.approval_status) && (
                <div className="container-buttons">
                  {isDisapproval && (
                    <button
                      type="button"
                      className="details"
                      onClick={() => setOpenComments(!isOpenComments)}
                    >
                      Comentários
                    </button>
                  )}

                  <Button
                    type="button"
                    buttonRole="primary"
                    onClick={() => setOpen(!isOpen)}
                  >
                    {hideDistributed ? 'Detalhes' : 'Distribuir'}
                  </Button>
                </div>
              )}

              <CommentModel
                isOpen={isOpenComments}
                pointsDistribution={pointsDistribution}
                setOpenComments={setOpenComments}
              />

              {!canDistribute(pointsDistribution.approval_status) &&
                tab === 'reproved' && (
                  <>
                    <button
                      type="button"
                      className="details"
                      onClick={() => setOpenComments(!isOpenComments)}
                    >
                      Comentários
                    </button>
                  </>
                )}

              {!canDistribute(pointsDistribution.approval_status) && (
                <>
                  <button
                    type="button"
                    className="details-action"
                    onClick={() => setOpen(!isOpen)}
                  >
                    Detalhes
                  </button>
                </>
              )}

              <StatusIcon
                status={pointsDistribution.approval_status || 0}
                statusText={pointsDistribution.approval_status_name || ''}
              />
            </div>
          </div>
          {isOpen && (
            <div className="open--content">
              <div className="report--link" />

              <FormContext {...formMethods}>
                <form onSubmit={handleFormSubmit} className="field-wrapper">
                  <div className="field">
                    <div className="field--title">
                      {pointsDistribution &&
                        pointsDistribution.distributions.map(
                          (f: any, index: number) => (
                            <div key={index}>
                              <strong>{f.balance_unit_name}</strong>
                              <br />
                              {pointsDistribution.demand_generation_points >=
                                0 && (
                                <>
                                  {f.balance_unit_id === 43 && (
                                    <span>
                                      Valor antecipado:{' '}
                                      {formatNumber(
                                        pointsDistribution.demand_generation_points,
                                      )}{' '}
                                      pontos
                                    </span>
                                  )}
                                </>
                              )}

                              {f.balance_unit_id === 47 &&
                               f.max_porcentage > 0 && (
                                  <span>
                                    Máximo: {f.max_porcentage}%
                                    <br />
                                    {maxPorcentage?.maxvalue === undefined
                                      ? 0
                                      : formatNumber(
                                          maxPorcentage?.maxvalue,
                                        )}{' '}
                                    pontos
                                  </span>
                                )}

                              {f.balance_unit_id === 59 &&
                               f.max_porcentage > 0 && (
                                  <span>
                                    Máximo: {f.max_porcentage}%
                                    <br />
                                    {maxPorcentage?.maxvalue === undefined
                                      ? 0
                                      : formatNumber(
                                          maxPorcentage?.maxvalue,
                                        )}{' '}
                                    pontos
                                  </span>
                                )}

                              {f.balance_unit_id === 58 && (
                                  <span>
                                    Máximo: {f.max_porcentage}%
                                    <br />
                                    {formatNumber(
                                      difference && !isNaN(difference)
                                        ? difference
                                        : (pointsDistribution.total * f.max_porcentage) / 100
                                    )}{' '}
                                    pontos
                                  </span>
                                )}

                              {f.minimum_business_generation_value &&
                                f.minimum_business_generation_value >= 0 &&
                                (
                                  <div>
                                    <span>
                                      Mínimo: {f.minimum_business_generation}%
                                    </span>{' '}
                                    <br />
                                    <span>
                                      {formatNumber(
                                        f.minimum_business_generation_value,
                                      )}{' '}
                                      pontos
                                    </span>
                                  </div>
                                )}

                              {f.minimum_team_awards_value > 0 && (
                                  <div>
                                    <span>
                                      Mínimo: {f.minimum_team_awards}%
                                    </span>
                                    <br />
                                    <span>
                                      {formatNumber(
                                        f.minimum_team_awards_value
                                        )}{' '}
                                      pontos
                                    </span>
                                  </div>
                                )}

                              <br />
                              <div className="field--input">
                                {participant.establishment.type_name ===
                                'Terra Brasil' ? (
                                  <NumberFormat
                                    thousandSeparator
                                    decimalSeparator="."
                                    placeholder="0.00"
                                    readOnly={readOnlyInput}
                                    allowNegative={false}
                                    format={formatPointsInput}
                                    type="text"
                                    value={
                                      inputValues[f.id] !== undefined
                                        ? inputValues[f.id]
                                        : formatPoints(f.value) || 0
                                    }
                                    onValueChange={({ value }) =>
                                      handleInputChange(f.id, value)
                                    }
                                    isAllowed={({ floatValue }) => {
                                      if (floatValue === undefined) {
                                        return true;
                                      }

                                      if (
                                        pointsDistribution.name_point ===
                                        'Ponto Extra'
                                      ) {
                                        return (
                                          floatValue / 100 <= formattedPoints
                                        );
                                      }

                                      const valueInPoints = floatValue / 100;

                                      if (f.balance_unit_id === 54) {
                                        if (difference) {
                                          return (
                                            valueInPoints <=
                                            pointsDistribution.total -
                                              valueReceivedMinimumBusiness
                                          );
                                        }
                                      }

                                      if (f.balance_unit_id === 59) {
                                        return (
                                          valueInPoints <=
                                          pointsDistribution.total -
                                            valueReceivedTeamAwards
                                        );
                                      }

                                      if (f.balance_unit_id === 58) {
                                        if (difference) {
                                          return (
                                            valueInPoints <= difference + 0.01
                                          );
                                        }
                                        return (
                                          valueInPoints <
                                          valueReceivedMinimumBusiness + 0.01
                                        );
                                      }

                                      if (maxPorcentage?.maxvalue) {
                                        if (
                                          floatValue === undefined ||
                                          floatValue / 100 <=
                                            maxPorcentage?.maxvalue
                                        ) {
                                          return true;
                                        }
                                      } else if (
                                        floatValue === undefined ||
                                        floatValue / 100 <= formattedPoints
                                      ) {
                                        return true;
                                      }

                                      const isBusinessOrTeamAwards =
                                        f.balance_unit_id === 43 ||
                                        f.balance_unit_id === 47 ||
                                        f.balance_unit_id === 54 ||
                                        (f.balance_unit_id === 59 &&
                                          participant.establishment
                                            .type_name === 'Terra Brasil');

                                      if (maxPorcentage?.maxvalue) {
                                        if (isBusinessOrTeamAwards) {
                                          return true;
                                        }
                                        return (
                                          floatValue !== undefined &&
                                          floatValue / 100 <=
                                            maxPorcentage.maxvalue
                                        );
                                      }
                                      if (isBusinessOrTeamAwards) {
                                        return (
                                          (floatValue !== undefined &&
                                            floatValue >=
                                              f.minimum_business_generation_value) ||
                                          floatValue >=
                                            f.minimum_team_awards_value
                                        );
                                      }
                                      return (
                                        floatValue !== undefined &&
                                        floatValue / 100 <= formattedPoints
                                      );
                                    }}
                                  />
                                ) : (
                                  <NumberFormat
                                    thousandSeparator
                                    decimalSeparator="."
                                    placeholder="0.00"
                                    allowNegative={false}
                                    format={formatPointsInput}
                                    type="text"
                                    readOnly={readOnlyInput}
                                    value={
                                      inputValues[f.id] !== undefined
                                        ? inputValues[f.id]
                                        : formatPoints(f.value)
                                    }
                                    onValueChange={({ value }) =>
                                      handleInputChange(f.id, value)
                                    }
                                    isAllowed={({ floatValue }) => {
                                      if (maxPorcentage?.maxvalue) {
                                        if (
                                          floatValue === undefined ||
                                          floatValue / 100 <=
                                            maxPorcentage?.maxvalue ||
                                          f.balance_unit_id !== 47
                                        ) {
                                          return true;
                                        }
                                      } else if (
                                        floatValue === undefined ||
                                        floatValue / 100 <= formattedPoints
                                      ) {
                                        return true;
                                      }

                                      return false;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ),
                        )}
                    </div>
                  </div>

                  <div className="fill-grid-space" />
                  {canDistribute(pointsDistribution.approval_status) &&
                    !isCrmOrGrm && (
                      <div className="form--actions">
                        <Button
                          buttonRole="primary"
                          type="submit"
                          className="save"
                          title={
                            sumOfFilledValues > formattedPoints
                              ? 'Você precisa distribuir todos os seus pontos.'
                              : ''
                          }
                          disabled={isTerraBrasil}
                          loading={action === 'is_approving'}
                        >
                          Salvar
                        </Button>

                        <Button
                          buttonRole="quaternary"
                          type="button"
                          className="cancel"
                          onClick={() => setOpen(false)}
                        >
                          Cancelar
                        </Button>
                      </div>
                    )}
                </form>
              </FormContext>

              {isCrmOrGrm ? (
                <ApprovationActions
                  name={pointsDistribution.approvals.stages_distribution}
                  point={pointsDistribution}
                  pointsToApprove={pointsToApprove}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </Container>
      )}
    </>
  );
};
