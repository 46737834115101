import { pluginApi } from 'services/api';
import { DateLimit } from './interfaces';

export default async (campaignId: number): Promise<DateLimit> => {
  const { data } = await pluginApi.get(
    `/strategy-plan-date-limit/get-dates-campaing?campaign_id=${campaignId}`,
  );

  return data;
};
