import { formatPointsExtract } from 'util/points';
import { Participant } from '../interfaces';

export interface Response {
  id: number;
  imageUrl: string;
  name: string;
  points: string;
  urlAccess: string;
  type: 'cpf' | 'cnpj';
}

export default (participant: Participant): Response => {
  return {
    id: participant.id,
    imageUrl: participant.imageUrl,
    name: participant.name,
    points: formatPointsExtract(participant.points || 0),
    urlAccess: participant.urlPi,
    type: participant.type,
  };
};
