import axios from 'axios';
import { pluginApi } from 'services/api';
import { ParticipantChannelsData } from './interface';

export const getListParticipantChannels = async (
  uf?: string | null,
  city?: string | null,
  channel_code?: string | null,
  integrador?: boolean | null,
): Promise<ParticipantChannelsData[]> => {
  const { data } = await pluginApi.get('/list-participant-channels', {
    params: {
      uf,
      city,
      channel_code,
      integrador
    },
  });
  return data;
};

export const getUfList = async () => {
  try {
    const response = await axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
    );
    return response.data;
  } catch (error) {
    throw new Error('Erro ao buscar as UFs');
  }
};

export const getCityByUf = async (ufSigla: string) => {
  try {
    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSigla}/municipios`,
    );
    return response.data;
  } catch (error) {
    throw new Error('Erro ao buscar os municípios');
  }
};
