/* eslint-disable */
import React, { useState } from 'react';
import {
  CAMPAIGN_STATUS,
  Campaign,
} from 'services/campaigns-manager/interfaces/Campaign';
import { Alert, Main } from './styles';
import { Accordion } from './Accordion';
import ReactLoading from 'react-loading';

interface Props {
  campaigns: Campaign[];
  isFetching: boolean;
  isLoading: { [key: number]: boolean };
  handleRequestApproval(data: Campaign): void;
  handleApprove(data: Campaign): void;
  handleApprovalView(id: number): void;
  handlePublishUnpublishToggle(data: Campaign): void;
  handleDisplayHighlight(highlightId: number | null, campaignId: number): void;
  handleHideHighlight(highlightId: number | null): void;
  openCommentPopup(data: number): void;
  filteredCampaigns: Campaign[] | null;
}

const Table: React.FC<Props> = ({
  handleRequestApproval,
  handleApprove,
  handleApprovalView,
  handlePublishUnpublishToggle,
  handleDisplayHighlight,
  handleHideHighlight,
  openCommentPopup,
  filteredCampaigns,
  campaigns,
  isFetching,
  isLoading,
}) => {
  const accordionConfig = [
    {
      title: 'Novas solicitações',
      icon: 'clock',
      status: [CAMPAIGN_STATUS.NEW_REQUEST_STATUS],
    },
    {
      title: 'Em análise/desenvolvimento',
      icon: 'clock',
      status: [
        CAMPAIGN_STATUS.DEVELOPMENT_STATUS,
        CAMPAIGN_STATUS.UNDER_ANALYSIS_STATUS,
      ],
      extraProps: { handleRequestApproval, handleApprovalView },
    },
    {
      title: 'Em aprovação',
      icon: 'clock',
      status: [CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS],
      extraProps: { handleApprove, openCommentPopup, handleApprovalView },
    },
    {
      title: 'Aprovada para publicação',
      icon: 'check',
      status: [CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS],
      extraProps: {
        handleDisplayHighlight,
        handleHideHighlight,
        handlePublishUnpublishToggle,
      },
    },
    {
      title: 'Publicada',
      icon: 'check',
      status: [CAMPAIGN_STATUS.PUBLISHED_STATUS],
      extraProps: { handleHideHighlight, handlePublishUnpublishToggle },
    },
    {
      title: 'Finalizada',
      icon: 'check',
      status: [CAMPAIGN_STATUS.FINISHED_STATUS],
      extraProps: { handleHideHighlight },
    },
    {
      title: 'Cancelada',
      icon: 'block',
      status: [CAMPAIGN_STATUS.CANCELED_STATUS],
    },
  ];

  const renderAccordion = (
    title: string,
    icon: string,
    displayStatus: number[],
    extraProps: any = {},
  ) => {
    const filteredCampaignsForStatus = filteredCampaigns
      ? filteredCampaigns.filter(c => displayStatus.includes(c.status.id))
      : campaigns;

    return (
      <>
        {!isFetching && (
          <Accordion
            title={title}
            icon={icon}
            displayStatus={displayStatus}
            campaigns={filteredCampaignsForStatus}
            isLoading={isLoading}
            {...extraProps}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isFetching && (
        <Alert>
          <ReactLoading type="bars" color="#8d8d8d" height={24} width={24} />
        </Alert>
      )}
      <Main>
        {filteredCampaigns
          ? accordionConfig.map(
              ({ title, icon, status, extraProps }) =>
                filteredCampaigns.some(st => status.includes(st.status.id)) && (
                  <React.Fragment key={status.join('-')}>
                    {renderAccordion(title, icon, status, extraProps)}
                  </React.Fragment>
                ),
            )
          : accordionConfig.map(({ title, icon, status, extraProps }) => (
              <React.Fragment key={status.join('-')}>
                {renderAccordion(title, icon, status, extraProps)}
              </React.Fragment>
            ))}
      </Main>
    </>
  );
};

export default Table;
