import React from 'react';

import { Campaign } from 'services/campaigns/getCampaigns';
import { Banner, Container } from './styles';

interface Props {
  data: Campaign;
}

const capitalizeTitle = (text: string) => {
  return text.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
};

const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const Header: React.FC<Props> = ({ data }) => {
  console.log(data.imageUrl);

  return (
    <>
      <Banner>
        <img src={data.imageUrl} alt="" />
      </Banner>
      <Container>
        <h1>{capitalizeTitle(data.title)}</h1>
        <span>
          Período da campanha de {data.startDate} até {data.endDate}
        </span>
        <p>{capitalizeText(data.description)}</p>
      </Container>
    </>
  );
};

export default Header;
