import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/images/training/close-icon.svg';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';

import { PROFILES } from 'config/constants';
import { SendParticipantExclusion } from 'services/auth/optout/sendParticipantExclusion';
import { getAvailableProfiles } from 'state/modules/header/selectors';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import schema from './validation';
import Success from './Success';

import {
  Close,
  Container,
  Modal,
  Title,
  FormOptOut,
  ReasonsSelect,
  TextArea,
  Actions,
  Button,
} from './styles';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  signOut(): void;
}

interface FormData {
  reason: string;
  improvementComment: string;
}

const OptOutModal: React.FC<Props> = ({ isOpen, onRequestClose, signOut }) => {
  const { participant } = useAuth();
  const [loading, setLoading] = useState(false);

  const availableProfiles = useSelector(getAvailableProfiles);

  const getProgram = useCallback((): string => {
    if (participant.profile === PROFILES.producer) {
      return 'JUNTOS Produtor';
    }
    if (availableProfiles.indirect?.establishmentType === 'Revenda') {
      return 'JUNTOS Revenda';
    }
    if (availableProfiles.indirect?.establishmentType === 'Cooperativa') {
      return 'JUNTOS Cooperativa';
    }
    return '';
  }, [availableProfiles.indirect, participant.profile]);

  const wichProgram = getProgram();

  const participantProfile = participant.establishment.id;

  const { addToast } = useToast();
  const [success, setSuccess] = useState(false);

  const methods = useForm<FormData>({
    validationSchema: schema,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
    defaultValues: {
      reason: '',
      improvementComment: '',
    },
  });

  const handleClick = useCallback(async () => {
    setSuccess(false);
  }, []);

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const createOptOut = {
      reason: data.reason,
      establishment_id: participantProfile,
      improvement_comment: data.improvementComment,
    };
    try {
      await SendParticipantExclusion.sendExcludeParticipant(createOptOut);

      if (!success) {
        setSuccess(true);
      }
    } catch (e) {
      setSuccess(false);
      addToast({
        title: 'Não foi possível completar. Tente novamente.',
        type: 'error',
      });
    }
    setLoading(false);
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={10}>
      <Close>
        <button type="button" onClick={onRequestClose}>
          <ReactSVG src={CloseIcon} />
        </button>
      </Close>
      <Container>
        <Title>
          Você está cancelando a sua inscrição no programa {wichProgram}.
        </Title>

        <span>
          Clicando no botão SIM, DESEJO SAIR, você não poderá mais acessar o
          programa {wichProgram}. Seus dados cadastrais nesta campanha serão
          inativados e seus pontos serão cancelados.
        </span>
        <FormContext {...methods}>
          <FormOptOut onSubmit={onSubmit}>
            <ReasonsSelect name="reason" />
            <TextArea
              name="improvementComment"
              label="Caso tenha comentário, crítica e/ou sugestão, utilize o espaço abaixo."
              inputRole="secondary"
            />
            <Actions>
              <Button
                type="submit"
                buttonRole="primary"
                onClick={handleClick}
                loading={loading}
              >
                Sim, desejo sair
              </Button>

              <Button
                type="button"
                buttonRole="primary"
                onClick={onRequestClose}
              >
                Continuar cadastrado
              </Button>
            </Actions>
          </FormOptOut>
        </FormContext>
      </Container>
      {success && <Success isOpen signOut={signOut} />}
    </Modal>
  );
};

export default OptOutModal;
