import styled from 'styled-components';

export const Container = styled.button`
  font-size: 12px;
  border: none;
  color: ${({ theme }) => theme.font.color.primary};
  background: none;
  transition: color 0.2s;
  text-decoration: none;
  border-bottom: 1.5px solid ${({ theme }) => theme.font.color.primary};
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  width: 110px;
  border-bottom: 1.5px solid ${({ theme }) => theme.font.color.primary};
  margin-left: 25.885px;
`;
