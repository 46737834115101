import React, { useEffect, useState } from 'react';

import listImage from 'assets/images/points-simulator/list.svg';
import { Option } from 'components/shared/Select';
import { Channel } from 'state/modules/points-simulator/interfaces';

import checkedIcon from 'assets/images/points-simulator/checkedIcon.svg';
import { useSelector } from 'react-redux';
import { getChannel } from 'state/modules/points-simulator/selectors';
import {
  Container,
  FirstBox,
  SecondBox,
  Tabs,
  Item,
  ChannelSelect,
  ProductTypeSelect,
  CustomText,
  ReactSVG,
  SimulationsContainer,
  CategoryContainer,
  ThirdBox,
} from './styles';

export enum Tab {
  enhancerSegmentTab = 'Segmentos potencializadores',
  participateProductsTab = 'Produtos',
  participateBiologicosTab = 'Produtos biológicos',
}

interface Props {
  tabSelected: Tab;
  setTabSelected(tab: Tab): void;
  setProductTypeIdSelected(productTypeId: number | undefined): void;
  /* setChannelIdSelected(channelId: number): void; */
  handleLoadSimulationClick(): void;
  channelSelectedOption: Option | null;
  channelSelectedObject: Channel | null;
  setChannelSelected(option: Option | null): void;
}

const Header: React.FC<Props> = ({
  tabSelected,
  setTabSelected,
  setProductTypeIdSelected,
  // setChannelIdSelected,
  handleLoadSimulationClick,
  channelSelectedOption,
  channelSelectedObject,
  setChannelSelected,
}) => {
  const [productTypeSelected, setProductTypeSelected] = useState<Option | null>(
    null,
  );

  useEffect(() => {
    const productTypeId = productTypeSelected
      ? parseInt(productTypeSelected.value, 0)
      : undefined;
    setProductTypeIdSelected(productTypeId);
  }, [productTypeSelected, setProductTypeIdSelected]);

  const channel = useSelector(getChannel);
  const isBiological = channel?.isBiological;
  return (
    <Container>
      <FirstBox>
        <h3>Simulador</h3>
        <div>
          <CustomText>Simulando em</CustomText>
          <ChannelSelect
            value={channelSelectedOption}
            setValue={setChannelSelected}
          />
          <CustomText>Categoria</CustomText>
          <CategoryContainer>
            <span>{channelSelectedObject?.category || ''}</span>
          </CategoryContainer>
        </div>
      </FirstBox>
      {isBiological && (
        <SecondBox>
          <span>Participa de Biológicos:</span>
          <img src={checkedIcon} alt="ícone checkbox" />
        </SecondBox>
      )}
      <ThirdBox>
        <Tabs>
          <Item
            onClick={() => setTabSelected(Tab.enhancerSegmentTab)}
            selected={tabSelected === Tab.enhancerSegmentTab}
          >
            <span>Potencializadores</span>
          </Item>
          {isBiological && (
            <Item
              onClick={() => setTabSelected(Tab.participateBiologicosTab)}
              selected={tabSelected === Tab.participateBiologicosTab}
            >
              <span>Produtos biológicos</span>
            </Item>
          )}
          <Item
            onClick={() => setTabSelected(Tab.participateProductsTab)}
            selected={tabSelected === Tab.participateProductsTab}
          >
            <span>Produtos</span>
          </Item>
        </Tabs>
        <SimulationsContainer>
          <CustomText>Minhas simulações</CustomText>
          <ReactSVG src={listImage} onClick={handleLoadSimulationClick} />
        </SimulationsContainer>
        <ProductTypeSelect
          value={productTypeSelected}
          setValue={setProductTypeSelected}
          placeholder="Segmento de Produto"
        />
      </ThirdBox>
    </Container>
  );
};

export default Header;
