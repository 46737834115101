import { pluginApi } from "services/api";

interface ImportChannelHierarchyResponse {
  download_url: string;
}

export const getImportChannelHierarchy = async (importId?: string): Promise<ImportChannelHierarchyResponse | null> => {
  try {
    const { data } = await pluginApi.get<ImportChannelHierarchyResponse>(`/reports/dictionary-channel-hierarchy`, {
      params: {
        import: importId,
      },
    });
    return data;
  } catch (error) {
    console.error("Erro ao obter link de download:", error);
    return null;
  }
};