/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { Button } from 'components/shared';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import getCertificate from 'services/training/getCertificate';
import getUrlToForceDownload from 'services/storage/getUrlToForceDownload';
import { Modal, Container, Content, Close } from './styles';

interface Props {
  id: number;
  isOpen: boolean;
  onRequestClose(): void;
}

const PopupSuccess: React.FC<Props> = ({ id, isOpen, onRequestClose }) => {
  const handleClick = useCallback(async () => {
    const { hasCertificate, url } = await getCertificate(id);
    if (hasCertificate) {
      const linkClick = document.createElement('a');
      const downloadUrl = getUrlToForceDownload({
        url,
        filename: 'Certificado.pdf',
      });
      linkClick.href = downloadUrl;
      document.body.appendChild(linkClick);
      linkClick.click();
      document.body.removeChild(linkClick);
    }
  }, [id]);
  return (
    <Container>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} type="primary">
        <Close>
          <button type="button" onClick={onRequestClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <Content>
          <h3>Parabéns!</h3>
          <h4>Treinamento concluído com sucesso</h4>
          <div>
            <Button type="button" buttonRole="primary" onClick={handleClick}>
              Baixar certificado
            </Button>
          </div>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            Ok
          </Button>
        </Content>
      </Modal>
    </Container>
  );
};

export default PopupSuccess;
