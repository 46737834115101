import React, { useState, useEffect, useCallback } from 'react';
import { EstablishmentCategory } from 'config/constants';
import { Option } from 'components/shared/Select';
import Select from 'components/shared/Select/BaseSelect';
import getData from 'services/cockpit/getChannels';
import transformer from 'services/cockpit/transformers/channelsToSelectOptions';

interface Props {
  className?: string;
  inputRole?: 'primary' | 'secondary';
  disabled?: boolean;
  label?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  placeholder?: string;
  directorName?: string;
  regionalName?: string;
  typeName?: string;
  categoryName?: EstablishmentCategory;
}

const CategorySelect: React.FC<Props> = ({
  className,
  inputRole = 'primary',
  disabled = false,
  label,
  setValue,
  value,
  placeholder,
  directorName,
  regionalName,
  typeName,
  categoryName,
}) => {

  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData({ directorName, regionalName, typeName, categoryName })
      .then(data =>
        setOptions(
          transformer(data).sort((a, b) => a.title.localeCompare(b.title)),
        ),
      )
      .catch(() => setOptions([]));
  }, [directorName, regionalName, typeName, categoryName]);

  let arrayNewOptions: any[] = [];
  options.map( 
    i => {
      return arrayNewOptions.push(i.category);
    }
  );

  const uniqueArray = arrayNewOptions.filter((value, index) => arrayNewOptions.indexOf(value) === index);

  const loadItems = useCallback(() => {
    const finalArray = uniqueArray.sort(
      (a, b) => a.localeCompare(b)
    ).map(
      uniqueArray => (
        {
          title: uniqueArray,
          value: uniqueArray,
        }
      )
    );
    
    return finalArray;
  }, [uniqueArray]);

  return (
    <Select
      label={label}
      loadItems={loadItems}
      className={className}
      inputRole={inputRole}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      setValue={setValue}
    />
  );
};

export default CategorySelect;
