import {
  Strategy,
  Engagement,
  Bell,
  Ranking,
  Performance,
} from 'state/modules/home/types';

export const strategies: Strategy[] = [
  {
    description: 'Visão estratégica',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Desenvolvimento de produtos',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Ações de relacionamento',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Top 10 clientes dos canais',
    goal: 0,
    reached: 0,
  },
];

export const engagements: Engagement[] = [
  {
    description: 'Habilidade',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Liderança',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Inovação',
    goal: 0,
    reached: 0,
  },
  {
    description: 'Campanhas aceleradoras',
    goal: 0,
    reached: 0,
  },
];

export const bells: Bell[] = [
  {
    description: 'Gestão',
    quantity: 0,
  },

  {
    description: 'Estratégia',
    quantity: 0,
  },

  {
    description: 'Engajamento',
    quantity: 0,
  },

  {
    description: 'Extra',
    quantity: 0,
  },
];

export const ranking: Ranking = {
  name: 'Diretoria Sul',
  position: 60,
};

export const performance: Performance = {
  revenues: {
    goal: 100000,
    reached: 25000,
    percentage: 25,
  },
  pog: {
    goal: 100000,
    reached: 50000,
    percentage: 50,
  },
  individualPog: {
    goal: 100000,
    reached: 100000,
    percentage: 100,
  },
  focus_product: [
    {
      goal: 0,
      name: 'Talisman',
      percentage: 0,
      result: 676546,
      pcp: {
        color: '#aafefe',
        position: 1,
      },
    },
  ],
  margem_adicional: {
    goal: 0,
    percentage: 0,
    reached: 0,
  },
};
