import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { getSpinsExtract } from 'services/roulette-extract/getSpinsExtract';
import { SpinExtract } from 'services/roulette-extract/types';
import { formatPoints } from 'util/points';

import { Container, Box, Alert } from './styles';

enum ScreenState {
  LOADING,
  READY,
  FETCH_ERROR,
  NO_DATA,
}

export const Extract: React.FC = () => {
  const [screenState, setScreenState] = useState<ScreenState>(
    ScreenState.LOADING,
  );
  const [extractData, setExtractData] = useState<SpinExtract>();
  const [shouldShowNumerosDaSorte, setShouldShowNumerosDaSorte] = useState(false);

  const fetchExtractData = async () => {
    if (screenState !== ScreenState.LOADING)
      setScreenState(ScreenState.LOADING);

    try {
      const data = await getSpinsExtract();

      setExtractData(data);

      if (!data.luckynumbers.length && !data.user_spins.length) {
        setScreenState(ScreenState.NO_DATA);
      } else {
        setScreenState(ScreenState.READY);
      }

      if(data.is_lucky_number){
        setShouldShowNumerosDaSorte(true);
      }

    } catch (error) {
      setScreenState(ScreenState.FETCH_ERROR);
    }
  };

  useEffect(() => {
    fetchExtractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = screenState === ScreenState.LOADING;
  const isErrored = screenState === ScreenState.FETCH_ERROR;
  const isReady = screenState === ScreenState.READY;
  const emptyData = screenState === ScreenState.NO_DATA;


  return (
    <Container>
      <Box>
        <h2>Meus giros</h2>

        {isLoading && (
          <Alert>
            <ReactLoading type="bars" color="#fff" height={25} width={25} />
          </Alert>
        )}

        {isErrored && (
          <Alert>
            <span>Não foi possível carregar seu extrato de giros.</span>
            <button type="button" onClick={fetchExtractData}>
              Tentar novamente
            </button>
          </Alert>
        )}

        {emptyData && (
          <Alert>
            <span>Você não possui nenhum giro.</span>
          </Alert>
        )}

        {isReady && extractData && (
          <table>
            <thead>
              <tr>
                <th>Roleta</th>
                <th>Pontos Extra</th>
                <th>Data de giro</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {extractData.user_spins.map(spin => (
                <tr key={spin.id}>
                  <td>{spin.title}</td>
                  <td>{formatPoints(Number(spin.coins_earned))}</td>
                  <td>{spin.date}</td>
                  <td>{spin.status}</td>
                </tr>
              ))}

              <tr>
                <td>
                  Total: <b>{formatPoints(extractData.total_coins)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Box>
      {shouldShowNumerosDaSorte && (
        <Box>
          <h2>Números da sorte</h2>

          {isLoading && (
            <Alert>
              <ReactLoading type="bars" color="#fff" height={25} width={25} />
            </Alert>
          )}

          {isErrored && (
            <Alert>
              <span>Você não possui nenhum número da sorte.</span>
            </Alert>
          )}

          {extractData && isReady && (
            <table>
              <thead>
                <tr>
                  <th>Número</th>
                  <th>Data de giro</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {extractData.luckynumbers.map(ln => (
                  <tr key={ln.id}>
                    <td>{ln.number}</td>
                    <td>{ln.spin_date}</td>
                    <td>{ln.status}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={3}>
                    {/* <b>Sorteio previsto para: {extractData.data_sorteio}</b> */}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Box>
      )}
    </Container>
  );
};
