import { Product } from 'state/modules/points-simulator/interfaces';
import enhancerProducts from './enhancer-products';
import otherProducts from './other-products';
import biologicalProducts from './biological-products';

interface Props {
  products: Product[];
}

export default (props: Props) => {
  const { products } = props;

  const enhancerProductsData = products.filter(
    item => item.checked && item.isEnhancer,
  );
  const otherProductsData = products.filter(
    item => item.checked && !item.isEnhancer && !item.isBiological,
  );

  const biologicalProductsData = products.filter(
    item => item.checked && !item.isEnhancer && item.isBiological,
  );

  return `
    ${
      enhancerProductsData.length > 0
        ? enhancerProducts({ products: enhancerProductsData })
        : ''
    }
    ${
      otherProductsData.length > 0
        ? otherProducts({
            enhancerProducts: enhancerProductsData,
            otherProducts: otherProductsData,
          })
        : ''
    }
    ${
      biologicalProductsData.length > 0
        ? biologicalProducts({
            enhancerProducts: enhancerProductsData,
            otherProducts: otherProductsData,
            biologicalProducts: biologicalProductsData,
          })
        : ''
    }
  `;
};
