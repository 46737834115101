import { Card } from 'components/PointsSimulator/Result/Body';
import { Indicator } from 'state/modules/points-simulator/interfaces';
import { fakeFormatDollars, formatLiter } from 'util/points';

const formatValue = (value: number, isTerraBrasil: boolean): string => {
  if (!isTerraBrasil) {
    return `US$ ${fakeFormatDollars(value, 0, 0)}`;
  }
  return `${formatLiter(value, 3)} L`;
};

const indicatorsToCards = (data: Indicator[]): Card[] => {
  return data.map(
    (indicator): Card => ({
      title: indicator.title,
      isBiological: indicator.isBiological,
      isTerraBrasil: indicator.isTerraBrasil,
      isRegisteredProduct: indicator.isRegisteredProduct,
      type: indicator.type,
      description: `Realizado ${indicator.lastYear} - ${formatValue(
        indicator.lastRealized,
        indicator.isTerraBrasil,
      )}`,
      tableData: [
        {
          title: `Meta ${indicator.currentYear}`,
          value: formatValue(indicator.currentGoal, indicator.isTerraBrasil),
        },
        {
          title: `Realizado ${indicator.currentYear}`,
          value: `${formatValue(
            indicator.simulationData.totalRealized,
            indicator.isTerraBrasil,
          )}`,
        },
      ],
      percentageCompleted: Number(indicator.percentageRealized.toFixed(2)),
      percentageSimulated: Number(
        indicator.simulationData.totalPercentageRealized.toFixed(2),
      ),
    }),
  );
};

export { indicatorsToCards };
