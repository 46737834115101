import { pluginApi } from 'services/api';

type Data = {
  participantId: number;
  password: string;
  confirmPassword: string;
};

type Response = {
  success: boolean;
  error?: string;
};

export const updatePassword = async ({
  participantId,
  password,
  confirmPassword,
}: Data): Promise<Response> => {
  try {
    await pluginApi.post(
      'participants/ChangePassword/changeExpiratedPassword',
      {
        participant_id: participantId,
        password,
        password_confirmation: confirmPassword,
      },
    );

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error:
        (error as any).response?.data?.message ||
        'Falha ao atualizar sua senha.',
    };
  }
};
