import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import BaseSelect from 'components/shared/Select/BaseSelect';
import getEstablishmentsFilters from 'services/auth/getEstablishmentsFilters';
import { useAuth } from 'context/AuthContext';
import { fetchEstablishments } from 'state/modules/point-management/common/actions';
import { usePoints } from 'pages/PointManagement/use-points';
import { Container } from './styles';

type Props = {
  showSelection: boolean;
  valueCampaign?: number | any;
  filter?: number | any;
};

export const EstablishmentSelection: React.FC<Props> = ({ showSelection, valueCampaign, filter }) => {

  const { participant } = useAuth();
  const [establishments, setEstablishments] = useState([]);
  const { selectEstablishment, selectedEstablishment, validatingEstablishmentEmpty } = usePoints();
  const dispatch = useDispatch();

  // Efeito para carregar os estabelecimentos quando a campanha mudar
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;        
        if(participant.profile !== "FOCALPOINT") {
          data = await getEstablishmentsFilters(valueCampaign , filter);
        }else{
          data = {
            establishments: [
              {
                value: participant.establishment.id,
                type: participant.establishment.client_group,
                title: participant.establishment.classificacao          
              }
            ]
          }
        }

        if (!selectedEstablishment && data.establishments.length > 0) {
          selectEstablishment(data.establishments[0]);
        }else{
          validatingEstablishmentEmpty();
        } 

        setEstablishments(data.establishments);
      } catch (error) {
        console.error("Erro na consulta dos canais:", error);
      }
    };
  
    fetchData();
  }, [valueCampaign, selectedEstablishment, selectEstablishment, filter, validatingEstablishmentEmpty, participant]);

  useEffect(() => {
    dispatch(fetchEstablishments());
  }, [dispatch]);
  
  return showSelection ? (
    <Container>
      <strong>Canal:</strong>
      <BaseSelect
        setValue={selectEstablishment}
        value={selectedEstablishment}
        disabled={establishments?.length === 1}
        loadItems={() => establishments || []}
        inputRole="primary"/>
    </Container>
  ) : null;
};
