import styled from 'styled-components';
import DefaultInput from 'components/shared/Input/BaseInput';
import DefaultTextArea from 'components/shared/TextArea/BaseTextArea';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  h4 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 20px;
  }

  > span {
    color: ${({ theme }) => theme.font.color.quartenary};
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const InputText = styled(DefaultInput)`
  ._inputContainer {
    width: 100%;
    margin-top: 10px;
    max-width: 900px;
    height: 44px;
    margin-bottom: 20px;
  }
`;

export const TextArea = styled(DefaultTextArea)`
  margin-top: 10px;
  ._inputContainer {
    width: 100%;
    max-width: 900px;
    height: 95px;
    margin-bottom: 20px;
  }
`;

export const Box = styled.div`
  width: 100%;
  max-width: 409px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  > div {
    width: 24px;
  }

  > span {
    padding: 0;
  }

  > h6 {
    margin-left: 10px;
    flex: 0.6;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
  }

  > input[type='text'] {
    width: 194px;
    height: 36px;
    padding: 0 16px;
    margin-top: 0;
    border: 1px solid ${({ theme }) => theme.font.color.quartenary};
  }
`;

export const Input = styled(DefaultInput)`
  margin-top: 10px;
  ._inputContainer {
    width: 194px;
    height: 36px;
  }
`;
