import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Option } from 'components/shared/Select';

import { Product } from 'state/modules/points-simulator/interfaces';
import {
  getProducts,
  getDollarBaseValue,
  getMode,
  getChannel,
  getConfiguration
} from 'state/modules/points-simulator/selectors';
import * as actions from 'state/modules/points-simulator/actions';
import { getCoinQuotations } from 'state/modules/header/selectors';
import {
  loadSimulations,
  deleteSimulation,
} from 'services/points-simulator/index';

import LoadSimulationModal, {
  TableData,
} from 'components/PointsSimulator/Commom/Modals/LoadSimulation';
import Header, { Tab } from 'components/PointsSimulator/Calculator/Header';
import Table from 'components/PointsSimulator/Calculator/Table';
import Footer from 'components/PointsSimulator/Calculator/Footer';

import { fixedPrecisionOf } from 'util/numbers';
import { DataValueDTO, Mode } from 'state/modules/points-simulator/types';
import { formatDate } from 'util/datetime';
import { EstablishmentTypes } from 'config/constants';
import {
  Container,
  Content,
  CustomTableBox,
  Box,
  WarningBox,
  DollarBox,
} from './styles';

interface Filter {
  productTypeId?: number;
  isEnhancer: boolean;
}

interface FilterCrop {
  productTypeId?: number;
  cropEnhancer: boolean;
}

interface FilterBiological {
  productTypeId?: number;
  isBiological: boolean;
}
const PointsSimulator: React.FC = () => {
  const [isLoadSimulatioModalOpened, setIsLoadSimulatioModalOpened] = useState(
    false,
  );
  const [checkTerraBrasil, setCheckTerraBrasil] = useState<Boolean>(false);
  const [savedSimulations, setSavedSimulations] = useState<TableData[]>([]);
  const coinsQuotation = useSelector(getCoinQuotations);
  const products = useSelector(getProducts);
  const channel = useSelector(getChannel);
  const dollarBaseValue = useSelector(getDollarBaseValue);
  const mode = useSelector(getMode);
  const configuration = useSelector(getConfiguration);
  const [productsTableData, setProductsTableData] = useState<Product[]>([]);
  const [tabSelected, setTabSelected] = useState<Tab>(Tab.enhancerSegmentTab);
  const [filter, setFilter] = useState<Filter>({ isEnhancer: true });
  const [filterBiological, setFilterBiological] = useState<FilterBiological>({
    isBiological: false,
  });
  const [filterCrop, setFilterCrop] = useState<FilterCrop>({ cropEnhancer: true});
  const [channelSelected, setChannelSelected] = useState<Option | null>(null);
  const [shouldUpdateChannel, setShouldUpdateChannel] = useState(true);
  const [groos, setGroos] = useState(configuration?.pogRealizedNetPercentage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadSimulatioModalOpened) return;
    loadSimulations().then(data =>
      setSavedSimulations(
        data.map(simulation => ({
          id: simulation.id,
          clientGroup: simulation.client_group,
          simulationDate: formatDate(simulation.created),
          simulationName: simulation.name,
          jsonStateInString: simulation.content,
          channelId: simulation.establishment_id,
        })),
      ),
    );
  }, [isLoadSimulatioModalOpened]);
  useEffect(() => {
    const dollarValue = fixedPrecisionOf(
      coinsQuotation?.find(
        item => item.name === 'Dólar Americano/Real Brasileiro',
      )?.value || 0,
      2,
    );
    dispatch(actions.setDollarBaseValue(dollarValue));
  }, [dispatch, coinsQuotation]);
 
  useEffect(() => {
    setFilter(oldFilter => ({
      ...oldFilter,
      isEnhancer: tabSelected === Tab.enhancerSegmentTab,
    }));

    setFilterCrop(
      oldFilter => ({
        ...oldFilter,
        cropEnhancer: tabSelected === Tab.enhancerSegmentTab
      })
    );

    setFilterBiological(oldFilterBiological => ({
      ...oldFilterBiological,
      isBiological: tabSelected === Tab.participateBiologicosTab,
    }));
  }, [tabSelected]);

  useEffect(() => {
    if (!products) return;

    const isChannelTerraBrasil =
      channel?.type === EstablishmentTypes.TerraBrasil;

      setCheckTerraBrasil(isChannelTerraBrasil);

    const productsBiological = products.filter(
      item => item.isBiological && filterBiological.isBiological,
    );

    let productsEnhancer;
    (
      isChannelTerraBrasil ? (
        productsEnhancer = products.filter(
          item =>
            item.isEnhancer &&
            filter.isEnhancer &&
            /** Caso o canal seja do tipo Terra Brasil, ele irá filtrar os produtos
             * potencializadores utilizando a flag para verificar apenas os produtos
             * que são Terra Brasil. */
            (isChannelTerraBrasil ? item.isTerraBrasil : !item.isTerraBrasil)
            ,
        )
      ) :
      (
        productsEnhancer = products.filter(
          item =>
            item.cropEnhancer &&
            (
              item.enhancerSegment === 'Inseticidas by Rynaxypyr' ||
              item.enhancerSegment === 'Herbicidas' ||
              item.enhancerSegment === 'Fungicidas' ||
              item.enhancerSegment === 'Inseticidas'
            ) &&
            (
              item.name !== 'INSETICIDAS BY RYNAXYPYR' &&
              item.name !== 'HERBICIDAS' &&
              item.name !== 'FUNGICIDAS' &&
              item.name !== 'INSETICIDAS'
            )
        )        
      )
    )


    const enhancerForProductsTab = products.filter(
      item =>
        (
          item.name !== 'INSETICIDAS BY RYNAXYPYR' &&
          item.name !== 'HERBICIDAS' &&
          item.name !== 'FUNGICIDAS' &&
          item.name !== 'INSETICIDAS'
        ) &&
        (isChannelTerraBrasil ? item.cropEnhancer : !item.cropEnhancer) &&
        (isChannelTerraBrasil ? !item.isTerraBrasil : item.isTerraBrasil),
    );


    const productNotCategory = products.filter(item => 
      !item.cropEnhancer && !item.isBiological && !item.isEnhancer
    );
    
    const newProdutos = products.filter(item => 
      !item.cropEnhancer && item.isEnhancer && !item.cropEnhancer && !item.isTerraBrasil
    );

    const allForProductsTabFilter = productNotCategory.concat(enhancerForProductsTab).concat(newProdutos);
    let allForProductsTab = [];
    
    if(filter.productTypeId){
      allForProductsTab = allForProductsTabFilter.filter(i => i.type.id === filter.productTypeId);
    }else{
      allForProductsTab = allForProductsTabFilter;
    }

    if (filter.isEnhancer && filterCrop.cropEnhancer) {
      setProductsTableData(productsEnhancer);
    }

    if (filterBiological.isBiological) {
      setProductsTableData(productsBiological);
    }

    if (!filter.isEnhancer && !filterBiological.isBiological) {
      setProductsTableData(allForProductsTab);
    }

  }, [filter, filterCrop, products, filterBiological, channel]);

  const handleRevenuesValueChange = useCallback(
    (data: DataValueDTO): void => {
      dispatch(actions.setRevenuesValueInKilosPerLiter(data));
    },
    [dispatch],
  );

  const handleUnitValueChange = useCallback(
    (data: DataValueDTO): void => {
      dispatch(actions.setUnitValueInDollar(data));
    },
    [dispatch],
  );

  const handlePogKilosByLiterValueChange = useCallback(
    (data: DataValueDTO): void => {
      dispatch(actions.setPogValueInKilosPerLiter(data));
    },
    [dispatch],
  );

  const onCheckUncheckProductHandle = useCallback(
    ({ id, checked }: { id: number; checked: boolean }) => {
      dispatch(actions.setProductCheck({ checked, productId: id }));
    },
    [dispatch],
  );

  const onCheckUncheckProductHandleEnhancer = useCallback(
    ({ id, enhancersChecked }: { id: number; enhancersChecked: boolean }) => {
      dispatch(actions.setProductCheckEnhancer({ enhancersChecked, productId: id }));
    },
    [dispatch],
  );

  const handleCalculate = useCallback(() => {
    dispatch(actions.fetchCalculate());
    dispatch(actions.setMode(Mode.result));
  }, [dispatch]);

  const handleReCalculate = useCallback(() => {
    dispatch(actions.setMode(Mode.calculator));
  }, [dispatch]);

  const handleProductTypeSelect = useCallback(
    (productTypeId: number | undefined): void => {
      setFilter(oldFilter => ({ ...oldFilter, productTypeId }));
    },
    [],
  );

  const handleChannelSelect = useCallback(
    (channelSelectId: number): void => {
      dispatch(actions.fetchChannel(channelSelectId));
      dispatch(actions.setMode(Mode.calculator));
    },
    [dispatch],
  );

  const onLoadState = useCallback(
    (
      jsonStateInString: string,
      channelSelectId: number,
      clientGroup: string,
    ) => {
      setShouldUpdateChannel(false);
      setChannelSelected({
        value: channelSelectId.toString(),
        title: clientGroup,
      });

      dispatch(actions.fetchLoadState(JSON.parse(jsonStateInString)));

      setTimeout(() => {
        setShouldUpdateChannel(true);
      }, 3500);
    },
    [dispatch],
  );

  const onDeleteSimulation = useCallback(async (simulationId: number) => {
    deleteSimulation(simulationId);
  }, []);

  const quantityItemsAdded = useMemo(
    () => products?.filter(item => item.checked).length || 0,
    [products],
  );

  const changeDollarValue = useCallback(
    (dollarValue: number) => {
      dispatch(actions.setDollarBaseValue(dollarValue));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!channelSelected) return;
    if (shouldUpdateChannel) {
      const channelSelectedId = parseInt(channelSelected.value, 0);
      if (channel?.id !== channelSelectedId) {
        handleChannelSelect(channelSelectedId);
      }
    }
  }, [channelSelected, handleChannelSelect, shouldUpdateChannel, channel]);

  useEffect(() => {
      setGroos(configuration?.pogRealizedNetPercentage);
  }, [configuration]); 

  return (
    <Container id="calculator">
      <Content>
        <Header
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          setProductTypeIdSelected={handleProductTypeSelect}
          handleLoadSimulationClick={() => setIsLoadSimulatioModalOpened(true)}
          channelSelectedOption={channelSelected}
          channelSelectedObject={channel}
          setChannelSelected={setChannelSelected}
        />
        <WarningBox>
          Atenção: O preço unitário simulado deve ser o preço Gross negociado
          com o canal.
        </WarningBox>
        {channel && (
          <>
            <CustomTableBox>
              <Box>
                <Table
                  checkTerraBrasil={checkTerraBrasil}
                  products={productsTableData}
                  setRevenuesInKilosPerLiter={handleRevenuesValueChange}
                  setUnitValueInDollar={handleUnitValueChange}
                  setPogInKilosPerLiter={handlePogKilosByLiterValueChange}
                  tabSelected={tabSelected}
                  onCheckUncheckProductHandle={onCheckUncheckProductHandle}
                  onCheckUncheckProductHandleEnhancer={onCheckUncheckProductHandleEnhancer}
                  channelId={channelSelected?.value || '0'}
                />
              </Box>
            </CustomTableBox>
            <DollarBox>
              <Footer
                GroosNet={groos}
                changeDollarBaseValue={changeDollarValue}
                dollarBaseValue={dollarBaseValue}
                handleButtonAction={
                  mode === Mode.calculator ? handleCalculate : handleReCalculate
                }
                buttonActionText={
                  mode === Mode.calculator ? 'Calcular' : 'Recalcular'
                }
                quantityItemsAdded={quantityItemsAdded}
              />
            </DollarBox>
          </>
        )}
      </Content>
      <LoadSimulationModal
        isOpen={isLoadSimulatioModalOpened}
        onRequestClose={() => setIsLoadSimulatioModalOpened(false)}
        tableData={savedSimulations}
        onLoadState={onLoadState}
        onDeleteSimulation={onDeleteSimulation}
      />
    </Container>
  );
};

export default PointsSimulator;
