import React, { useState, useEffect } from 'react';

import { InvoiceDetails } from 'services/nf/interfaces';
import { formatPoints } from 'util/points';
import { formatDate } from 'util/datetime';

import ItemList from './products-list';

import {
  DetailsSection,
  DetailsBlock,
  SummarySection,
  SummaryItem,
} from '../styles';

interface Props {
  details: InvoiceDetails;
}

const Released: React.FC<Props> = ({ details }) => {
  const [totalProdutosFmc, setTotalProdutosFmc] = useState<number>(0);
  const [totalFmcCoins, setTotalFmcCoins] = useState<number>(0);

  useEffect(() => {
    const sumTotal = details.items.reduce(
      (accumulated, current) => accumulated + current.totalValue,
      0,
    );
    const sumCoins = details.items.reduce(
      (accumulated, current) => accumulated + current.fmcCoins,
      0,
    );

    setTotalProdutosFmc(sumTotal);
    setTotalFmcCoins(sumCoins);
  }, [details.items]);

  return (
    <>
      <DetailsSection>
        <DetailsBlock>
          <h3>Dados da Nota</h3>
          <table>
            <tbody>
              {details.origin === "Integrator" && (
                <tr>
                  <td>
                    <strong>Enviada por: </strong>
                  </td>
                  <td>Envio Automático de NF</td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>Canal: </strong>
                </td>
                <td> {details.channel} </td>
              </tr>
              <tr>
                <td>
                  <strong>CNPJ:</strong>
                </td>
                <td> {details.cnpj} </td>
              </tr>
              <tr>
                <td>
                  <strong>Data da compra: </strong>
                </td>
                <td> {formatDate(details.purchaseDate)} </td>
              </tr>
              <tr>
                <td>
                  <strong> Número da nota: </strong>
                </td>
                <td> {details.invoiceNumber} </td>
              </tr>
            </tbody>
          </table>
        </DetailsBlock>
        <DetailsBlock>
          <h3>Dados da compra</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>CPF/CNPJ:</strong>
                </td>
                <td> {details.cpf} </td>
              </tr>
              <tr>
                <td>
                  <strong>Valor total: </strong>
                </td>
                <td> R$ {details.itemsTotal} </td>
              </tr>
            </tbody>
          </table>
        </DetailsBlock>
      </DetailsSection>
      <SummarySection>
        <SummaryItem>
          Total em Produtos FMC: R$ {formatPoints(totalProdutosFmc)}
        </SummaryItem>
        <SummaryItem>FMC Coins: {formatPoints(totalFmcCoins)}</SummaryItem>
      </SummarySection>
      <ItemList items={details.items} />
    </>
  );
};

export default Released;
