import { StoreState } from 'state/root-reducer';
import {
  Campaign,
  BillingPog,
  Product,
  Potentializer,
  Infos,
  Biological,
} from './types';

export const getCampaigns = (state: StoreState): Campaign[] | null =>
  state.goals.campaigns;

export const getBillingPog = (state: StoreState): BillingPog | null =>
  state.goals.billingPog;

export const getBiological = (state: StoreState): Biological | null =>
  state.goals.biological;
export const getPotentializers = (state: StoreState): Potentializer[] | null =>
  state.goals.potentializers;

export const getInfos = (state: StoreState): Infos | null => state.goals.infos;

export const getTopPurchasingProducts = (state: StoreState): Product[] | null =>
  state.goals.topPurchasingProducts;

export const getTopSellingProducts = (state: StoreState): Product[] | null =>
  state.goals.topSellingProducts;

export const getSelectedCampaignId = (state: StoreState): number | null =>
  state.goals.selectedCampaignId;

export const getCurrentCampaign = (state: StoreState): Campaign | null =>
  state.goals.currentCampaign;

export const getPercentageHome = (state: StoreState): BillingPog | null =>
  state.goals.billingPog;
