import React, { useEffect, useState, useCallback } from 'react';

import { ReactSVG } from 'react-svg';

import { useDispatch, useSelector } from 'react-redux';
// import { Visible, Hidden } from 'react-grid-system';
import { Hidden } from 'react-grid-system';

import cloudIcon from 'assets/images/weather/cloud.svg';
import deleteIcon from 'assets/images/campaigns/delete-icon.svg';

import { useAuth } from 'context/AuthContext';
import {
  fetchBanners,
  fetchHighlights,
  fetchShowcase,
} from 'state/modules/home/actions';

import { fetchWeather } from 'state/modules/weather/actions';

import {
  getBanners,
  getHighlights,
  getShowcaseProducts,
  getHasRegisterToComplete,
} from 'state/modules/home/selectors';

import { getWeather } from 'state/modules/weather/selectors';

// import { getCoinQuotations } from 'state/modules/header/selectors';
import { Banners, Title, Highlights, Showcase, AddNF } from 'components/Home';

// import CoinQuotation from 'components/Header/CoinQuotation';

import IndicateProducer from 'components/Home/IndicateProducer';
import SearchChannels from 'components/Home/SearchChannels';
import CompleteRegister from 'components/Auth/Modals/CompleteRegister';
import CultivationArea from 'components/Auth/Modals/CultivationArea';
import { City } from 'services/weather/interfaces';
import {
  getCityCoordinatesByName,
  loadCities,
  removeCity,
  saveCity,
} from 'services/weather';
import CitySelect from 'components/Weather/Selects/Cities';
import WeatherWidget from 'components/Weather/Widget';
import { useToast } from 'context/ToastContext';
import { CityCoordinates } from 'state/modules/weather/types';

import {
  Wrapper,
  ShowCaseWrapper,
  HomeWrapper,
  IndicateContent,
  IndicateWrapper,
  WeatherWrapper,
  WeatherTitle,
  CitySelectWrapper,
  Img,
  WeatherWidgetWrapper,
  RemoveActionWrapper,
} from './styles';

const FmcProdutorHome: React.FC = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  // const coinQuotations = useSelector(getCoinQuotations);
  const { participant, simulating, isLoggedFromAdmin } = useAuth();

  const [banners, highlights, products, hasRegisterToComplete] = [
    useSelector(getBanners),
    useSelector(getHighlights),
    useSelector(getShowcaseProducts),
    useSelector(getHasRegisterToComplete),
  ];
  const weather = useSelector(getWeather);
  const [citySelect, setCitySelect] = useState<City | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (participant.role.complete_registration.missing) {
      const { cultivo } = participant.role.complete_registration.missing;
      setShowPopup(cultivo);
    }
  }, [participant]);

  useEffect(() => {
    if (!participant.id) return;

    dispatch(fetchBanners());
    dispatch(fetchHighlights());
    dispatch(fetchShowcase(participant.id));
    loadCities().then(data => {
      setCities(
        data
          .filter(item => item.id)
          .map(item => ({
            id: item.id as number,
            name: item.name,
          })),
      );
    });
  }, [dispatch, participant.id]);

  const handleCitySelect = useCallback(
    async (_city: City) => {
      if (cities.length >= 2) {
        addToast({ title: 'Por favor exclua uma cidade antes de continuar' });
        setTimeout(() => {
          setCitySelect(null);
        }, 1500);
        return;
      }

      setCitySelect(_city);

      setCities(oldCities => [
        ...oldCities.filter(item => item.name !== _city.name),
        _city,
      ]);

      saveCity({ name: _city.name, lat: -1, lon: -1 });

      setTimeout(() => {
        setCitySelect(null);
      }, 3000);
    },
    [cities, addToast],
  );

  useEffect(() => {
    const load = async () => {
      const citiesCoordinates = await Promise.all(
        cities.map(async city => {
          const cityCoordinates = await getCityCoordinatesByName(city.name);
          return cityCoordinates as CityCoordinates;
        }),
      );

      dispatch(fetchWeather(citiesCoordinates.filter(item => item?.name)));
    };

    load();
  }, [dispatch, cities]);

  useEffect(() => {
    const ignoredFields: { [key: string]: boolean } = {
      id: true,
      participant_id: true,
      created: true,
      modified: true,
      outras_quais: true,
    };

    const hasValueGreaterThanZero = Object.entries(participant.harvest).some(
      ([key, value]) =>
        !ignoredFields[key] && value !== null && parseFloat(value) > 0,
    );

    setShowPopup(!hasValueGreaterThanZero);
  }, [participant.harvest]);

  const handleRemoveCity = useCallback((cityName: string) => {
    setCities(oldCities => {
      const internalCity = oldCities.find(item => item.name === cityName);
      if (internalCity) {
        removeCity(internalCity.id);
      }
      return oldCities.filter(item => item.name !== cityName);
    });
  }, []);

  const [showCitySelection, setShowCitySelection] = useState(false);

  return (
    <HomeWrapper>
      {showPopup && <CultivationArea />}
      {/* <Visible xs sm>
        {!!coinQuotations && <CoinQuotation quotations={coinQuotations} />}
      </Visible> */}
      <Hidden xs sm>
        {!!banners && <Banners items={banners} />}
      </Hidden>
      <Wrapper>
        <AddNF />
      </Wrapper>
      <Wrapper>
        <Title>Destaques</Title>
        {!!highlights && <Highlights items={highlights} />}
      </Wrapper>
      <IndicateWrapper>
        <IndicateContent>
          <IndicateProducer />
          <SearchChannels />
        </IndicateContent>
      </IndicateWrapper>
      {!isLoggedFromAdmin && (
        <ShowCaseWrapper>
          <Wrapper>
            <Title reverse>Vitrine de Prêmios </Title>
            <Showcase
              products={products}
              isSimulating={simulating}
              isProducerProfile
            />
          </Wrapper>
        </ShowCaseWrapper>
      )}
      <ShowCaseWrapper>
        <Wrapper>
          <Title reverse>Vitrine de Prêmios </Title>
          <Showcase
            products={products}
            isSimulating={simulating}
            isProducerProfile
          />
        </Wrapper>
      </ShowCaseWrapper>
      {hasRegisterToComplete && (
        <CompleteRegister isOpen={hasRegisterToComplete} />
      )}

      <WeatherWrapper>
        <WeatherTitle>
          Clima
          <Img
            src={cloudIcon}
            alt="Configuração do Clima"
            onClick={() => setShowCitySelection(e => !e)}
          />
        </WeatherTitle>
        <CitySelectWrapper show={showCitySelection}>
          <h3>Você pode escolher até duas cidades</h3>
          <CitySelect
            value={citySelect}
            setValue={handleCitySelect}
            placeholder="Escolha uma cidade para adicionar"
          />
        </CitySelectWrapper>
        {weather.map(item => (
          <WeatherWidgetWrapper key={`weather-${item.city.name}`}>
            <WeatherWidget weather={item} />
            <RemoveActionWrapper show={showCitySelection}>
              <ReactSVG
                src={deleteIcon}
                onClick={() => handleRemoveCity(item.city.name)}
              />
            </RemoveActionWrapper>
          </WeatherWidgetWrapper>
        ))}
      </WeatherWrapper>
    </HomeWrapper>
  );
};

export default FmcProdutorHome;
