import styled from 'styled-components';
import DefaultCultureSelect from './select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CultureSelect = styled(DefaultCultureSelect)`
  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
  }
  margin-bottom: 7px;
`;

export const SelectedCulture = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 730px;

  ._cultureName {
    display: flex;
    width: 100%;
    max-width: 130px;
    margin-right: 15px;
    height: 44px;
    border: 1px solid ${({ theme }) => theme.font.color.quartenary};
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    svg {
      cursor: pointer;
      max-width: 13px;
    }

    h3 {
      flex: 1;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      font-size: 16px;
      color: ${({ theme }) => theme.font.color.quartenary};
    }
  }
`;
