import styled from 'styled-components';

interface ContainerProps {
  hide?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;

  ._inputContainer {
    height: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 15px 20px;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  width: 100%;
  max-width: 350px;
`;
