import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { getProductsForSelect as getData } from 'services/strategic-plans/actions';
import { BaseSelect } from '../../shared/styles';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  inputRole: 'primary' | 'secondary';
  setValue(value: Option | null): void;
  value: Option | null;
  error?: string;
  disabled?: boolean;
}

const ProductsSelect: React.FC<Props> = ({
  className,
  label,
  placeholder,
  inputRole,
  setValue,
  value,
  error,
  disabled,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData().then(data => {
      const todosItem = data.find(
        item => item.title?.toLowerCase() === 'todos',
      ) as Option;
      const otherItems = data.filter(
        item => item.title?.toLowerCase() !== 'todos',
      );

      /* const sortedOtherItems = otherItems.sort((a, b) =>
        a.title.localeCompare(b.title),
      ); */

      //const finalOptions = [todosItem, ...sortedOtherItems].map(item => ({
      const finalOptions = [todosItem, ...otherItems].map(item => ({
        ...item,
        title: item.title.toUpperCase(),
      }));

      setOptions(finalOptions);
    });
  }, []);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <BaseSelect
      label={label}
      loadItems={loadItems}
      className={className}
      placeholder={placeholder}
      inputRole={inputRole}
      value={value}
      setValue={setValue}
      error={error}
      disabled={disabled}
    />
  );
};

export default ProductsSelect;
