import { StoreState } from 'state/root-reducer';

export const getAnswers = (state: StoreState) => state.surveys.answers;

export const getAvailableSurveys = (state: StoreState) =>
  state.surveys.surveys.available;

export const getPerformedSurveys = (state: StoreState) =>
  state.surveys.surveys.performed;

export const getFetchingSurveys = (state: StoreState) =>
  state.surveys.fetchingSurveys;
