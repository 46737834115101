import React, { useEffect, useState, useCallback } from 'react';
import ReactLoading from 'react-loading';

import {
  fetchCurrentCampaignService as getCurrentCampaign,
  fetchCurrentCampaignTerraService as getCurrentCampaignTerra,
} from 'services/goals';
import getAllRegulations from 'services/register/regulation/getAllRegulations';
import acceptRegulation from 'services/register/regulation/acceptRegulation';
import {
  Regulation,
  RegulationType,
} from 'services/register/regulation/interfaces/IRegulation';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import { REGULATIONS_TYPE } from 'config/constants';
import CamppaignSelect from './CampaignSelect';
import RegulationBox from './RegulationBox';

import './Toggle.css';
import { pluginApi } from 'services/api';
import { useSimpleToast } from 'components/shared';
import ConfirmationModal from './ConfirmationModal';
import history from 'services/history';

import {
  Container,
  Content,
  Title,
  SubTitle,
  Accordion,
  LinksRegulation,
  Loading,
} from './styles';

const TITLES = {
  [REGULATIONS_TYPE.dataTerm]: 'Termos de Proteção de Dados',
  [REGULATIONS_TYPE.regulationOfCampaign]: 'Regulamento do Programa Juntos',
  [REGULATIONS_TYPE.safraTerm]: 'Termos de Safras',
  [REGULATIONS_TYPE.safraTerm]: 'Regulamentos ',
  [REGULATIONS_TYPE.promotionRegulation]: 'Promoções ',
};

const AllRegulations: React.FC = () => {
  const { participant, refreshParticipant } = useAuth();
  const { addToast } = useToast();

  const [dataRegulations, setDataRegulations] = useState<
    Omit<Regulation, 'content'>[]
  >([]);
  const [campaignRegulations, setCampaingRegulations] = useState<
    Omit<Regulation, 'content'>[]
  >([]);
  const [safraRegulations, setSafraRegulations] = useState<
    Omit<Regulation, 'content'>[]
  >([]);
  const [promotionRegulations, setPromotionRegulations] = useState<
    Omit<Regulation, 'content'>[]
  >([]);

  const [isTerraBrasil, setTerraBrasil] = useState(false);
  const [loading, setLoading] = useState(false);

  const [acceptedIds, setAcceptedIds] = useState<number[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetchAllRegulations = async () => {
      await getAllRegulations().then(
        (regulations: Omit<Regulation, 'content'>[]) => {
          const findMostRecent = (
            regulationsArray: Omit<Regulation, 'content'>[],
          ): Omit<Regulation, 'content'>[] => {
            const groupedByName: {
              [key: string]: Omit<Regulation, 'content'>[];
            } = regulationsArray.reduce((acc, regulation) => {
              const regulationName = regulation.name.toLowerCase();

              if (!acc[regulationName]) {
                acc[regulationName] = [];
              }
              acc[regulationName].push(regulation);
              return acc;
            }, {} as { [key: string]: Omit<Regulation, 'content'>[] });

            const mostRecentItems = Object.values(groupedByName).map(
              (group: Omit<Regulation, 'content'>[]) => {
                return group.reduce((mostRecent, regulation) => {
                  return new Date(regulation.modified) >
                    new Date(mostRecent.modified)
                    ? regulation
                    : mostRecent;
                }, group[0]);
              },
            );

            return mostRecentItems;
          };

          setDataRegulations(
            findMostRecent(
              regulations.filter(
                regulation =>
                  regulation.type === 'data_term' ||
                  regulation.name.toLowerCase().includes('proteção de dados'),
              ),
            ),
          );

          setCampaingRegulations(
            findMostRecent(
              regulations.filter(
                regulation =>
                  regulation.type === 'regulation_of_campaign' &&
                  !regulation.name.toLowerCase().includes('proteção de dados'),
              ),
            ),
          );

          setSafraRegulations(
            findMostRecent(
              regulations.filter(
                regulation => regulation.type === 'safra_term',
              ),
            ),
          );

          setPromotionRegulations(
            findMostRecent(
              regulations.filter(
                regulation => regulation.type === 'promotion_regulation',
              ),
            ),
          );

          setAcceptedIds(
            regulations
              .filter(regulation => regulation.accepted)
              .map(regulation => regulation.id),
          );

          setTerraBrasil(
            regulations?.some(regulation =>
              regulation.regulation_accepted_logs?.some(
                t => t.type.toLowerCase() === 'terra brasil',
              ),
            ),
          );
        },
      );

      setLoading(false);
    };

    fetchAllRegulations();
  }, [participant.profile]);

  useEffect(() => {
    if (!isTerraBrasil) {
      const fetchCurrentCampaign = async () => {
        const currentCampaign = await getCurrentCampaign();
        if (currentCampaign) {
          setSelectedCampaignId(currentCampaign.id);
        } else {
          setSelectedCampaignId(0);
        }
      };
      fetchCurrentCampaign();
    }
  }, [isTerraBrasil]);

  useEffect(() => {
    if (isTerraBrasil) {
      const fetchCurrentCampaignTerra = async () => {
        const currentCampaignTerra = await getCurrentCampaignTerra();
        if (currentCampaignTerra) {
          setSelectedCampaignId(currentCampaignTerra.id);
        } else {
          setSelectedCampaignId(0);
        }
      };
      fetchCurrentCampaignTerra();
    }
  }, [isTerraBrasil]);

  const handleAcceptRegulation = useCallback(
    async (id: number, version: number, title: string) => {
      try {
        await acceptRegulation(id, version);
        addToast({
          title: `Você aceitou o regulamento ${title}`,
          type: 'success',
        });
        if (
          acceptedIds.length > 0 &&
          safraRegulations.length +
            campaignRegulations.length +
            dataRegulations.length ===
            acceptedIds.length + 1
        ) {
          addToast({
            title: 'Você aceitou todos os regulamentos',
            type: 'success',
          });
          refreshParticipant();
          return;
        }
        setAcceptedIds([...acceptedIds, id]);
      } catch {
        addToast({
          title: `Falha ao aceitar o regulamento ${title}`,
          type: 'error',
        });
      }
    },
    [
      addToast,
      acceptedIds,
      safraRegulations,
      campaignRegulations,
      dataRegulations,
      refreshParticipant,
    ],
  );

  const handleOpenRegulation = useCallback(
    async (regulationId: number) => {
      return (
        <RegulationBox
          regulationId={regulationId}
          acceptedIds={acceptedIds}
          handleAcceptRegulation={handleAcceptRegulation}
        />
      );
    },
    [acceptedIds, handleAcceptRegulation],
  );

  const printRegulation = useCallback(
    (
      regulations: Omit<Regulation, 'content'>[],
      type: RegulationType,
      campaignId?: number,
    ) => {
      const filteredRegulations = campaignId
        ? regulations?.filter(regulation =>
            regulation.regulation_accepted_logs?.find(
              campaign => campaign.campaign_id === campaignId,
            ),
          )
        : regulations;

      return (
        filteredRegulations.length > 0 && (
          <>
            <SubTitle>{TITLES[type]}</SubTitle>
            {filteredRegulations.map(regulation => (
              <Accordion
                // Alguns usuários têm vários aceites do mesmos termos isso gera uma duplicidade de key e gera o erro de renderização
                // Foi preciso então remover essa linha para evitar mas erros de renderização
                // key={`accordion-ragulation-${regulation.id}`}
                title={regulation.name}
                loadChildren={() => handleOpenRegulation(regulation.id)}
                type="secondary"
              />
            ))}
          </>
        )
      );
    },
    [handleOpenRegulation],
  );

  const [toggleChecked, setToggleChecked] = useState(participant.automatic_nf);

  useEffect(() => {
    setToggleChecked(participant.automatic_nf);
  }, [participant.automatic_nf]);
  const { showToast } = useSimpleToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenActive, setIsModalOpenActive] = useState(false);
  const [pendingState, setPendingState] = useState<boolean | null>(null);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const newCheckedState = !toggleChecked;
  
    if (!newCheckedState) {
      setPendingState(newCheckedState);
      setIsModalOpen(true);
      return;
    } else {
      if (!participant.role.complete_registration.complete) {
        setIsModalOpenActive(true);
      }
      updateToggleState(newCheckedState);
    }
  
    refreshParticipant();
  };

  const updateToggleState = async (newCheckedState: boolean) => {
    setToggleChecked(newCheckedState);
  
    try {
      await pluginApi.post('/participants/edit/updateParticipantAutomaticNf', {
        automatic_nf: newCheckedState,
      });
      showToast({
        message: 'Status atualizado com sucesso.',
        type: 'success',
      });
  
      // Atualiza os dados do participant após sucesso
      refreshParticipant();
    } catch (error) {
      showToast({
        message: 'Erro ao atualizar o status.',
        type: 'error',
      });
    }
  };
  

  const handleConfirm = () => {
    if (pendingState !== null) {
      updateToggleState(pendingState);
      setPendingState(null);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setPendingState(null);
    setIsModalOpen(false);
  };

  const navigateToProfile = () => {
    const { role } = participant;

    if (
      role?.complete_registration?.missing &&
      !role.complete_registration.missing.cultivo
    ) {
      history.push('/editar-perfil?pre_select=dados_de_cultivo');
    } else if (
      role?.complete_registration?.missing &&
      !role.complete_registration.missing.grupo_fazenda
    ) {
      history.push('/editar-perfil?pre_select=dados_fazenda');
    } else {
      history.push('/editar-perfil');
    }
  };

  const handleCancelActive = () => {
    setIsModalOpen(false);
  };

  

  return (
    <Container type="primary">
      <Content>
        <Title>Regulamentos e Termos</Title>
        {loading ? (
          <Loading>
            <ReactLoading type="bars" height={24} width={24} color="#808285" />
          </Loading>
        ) : (
          <>
            <CamppaignSelect
              selectedCampaignId={selectedCampaignId}
              selectCampaign={setSelectedCampaignId}
              isTerraBrasil={isTerraBrasil}
            />
            {campaignRegulations.length > 0 &&
              printRegulation(
                campaignRegulations,
                'regulation_of_campaign',
                selectedCampaignId,
              )}
            {safraRegulations.length > 0 &&
              printRegulation(
                safraRegulations,
                'safra_term',
                selectedCampaignId,
              )}
            {dataRegulations.length > 0 &&
              printRegulation(dataRegulations, 'data_term')}

            {promotionRegulations.length > 0 &&
              printRegulation(promotionRegulations, 'promotion_regulation')}

            {participant.profile === 'PRODUTOR' && (
              <LinksRegulation>
                <div>
                  <a
                    href="https://storage.juntosfmc.com.br/regulations/1606905851.5fc76ffb6629d2.17594420.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Uso
                  </a>
                  <a
                    href="https://adm.juntosfmc.com.br/download?name=Portal_Juntos_FMC-TERMO_DE_USO.pdf&url=https://storage.juntosfmc.com.br/regulations/1606905851.5fc76ffb6629d2.17594420.pdf"
                    className="icon-download"
                    download
                  >
                    Download
                  </a>
                </div>
                <div>
                  <a
                    href="https://storage.juntosfmc.com.br/regulations/1607130761.5fcade89ae73b4.58389042.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Privacidade
                  </a>
                  <a
                    href="https://adm.juntosfmc.com.br/download?name=Portal_Juntos_FMC-POLITICA_DE_PRIVACIDADE.pdf&url=https://storage.juntosfmc.com.br/regulations/1607130761.5fcade89ae73b4.58389042.pdf"
                    className="icon-download"
                    download
                  >
                    Download
                  </a>
                </div>
              </LinksRegulation>
            )}

            {participant.profile === 'PRODUTOR' && (
              <>
                <SubTitle>Envio Automático NF</SubTitle>
                <div style={{ position: 'relative', minHeight: '60px' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      zIndex: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ marginTop: '5px', marginRight: '5px' }}>
                      Status: {toggleChecked ? 'Ativado' : 'Desativado'}
                    </span>
                    <label className="switch-automatic-nf">
                      <input
                        type="checkbox"
                        checked={toggleChecked}
                        onChange={handleToggleChange}
                      />
                      <span className="slider-automatic-nf round"></span>
                    </label>
                  </div>
                  <Accordion
                    title="Veja mais"
                    loadChildren={() => handleOpenRegulation(401)}
                  />
                </div>
                <ConfirmationModal
                  isOpen={isModalOpen}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  title="Atenção!"
                  confirmAndCancelButton={true}
                  message="Ao desativar o Envio Automático de NF, você terá que enviar as suas notas manualmente pela plataforma. Tem certeza que deseja continuar?"
                />
                <ConfirmationModal
                  isOpen={isModalOpenActive}
                  onConfirm={navigateToProfile}
                  onCancel={handleCancelActive}
                  title="Complete seus dados"
                  message={
                    <>
                      É necessário que você preencha as informações abaixo para autorizar o cadastro de NFs:
                      <br /><br />
                      {!participant.role.complete_registration.complete && participant.role.complete_registration.missing.grupo_fazenda && (
                        <><br />Passo 2: Grupo familiar/societário e/ou CNPJ</>
                      )}
                      {!participant.role.complete_registration.complete && participant.role.complete_registration.missing.cultivo && (
                        <><br />Passo 3: Dados de hectares</>
                      )}
                    </>
                  }
                  confirmAndCancelButton={false}
                />
              </>
            )}

            {participant.profile !== 'PRODUTOR' && (
              <LinksRegulation>
                <div>
                  <a
                    href="https://storage.juntosfmc.com.br/avatar/1597870012.5f3d8fbc16bc67.86487857.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Uso
                  </a>
                  <a
                    href="https://adm.juntosfmc.com.br/download?name=Portal_Juntos_FMC-TERMO_DE_USO.pdf&url=https://storage.juntosfmc.com.br/avatar/1597870012.5f3d8fbc16bc67.86487857.pdf"
                    className="icon-download"
                    download
                  >
                    Download
                  </a>
                </div>
                <div>
                  <a
                    href="https://storage.juntosfmc.com.br/avatar/1598359154.5f450672673807.86869023.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Política de Privacidade
                  </a>
                  <a
                    href="https://adm.juntosfmc.com.br/download?name=Portal_Juntos_FMC-POL%C3%8DTICA_DE_PRIVACIDADE.pdf&url=https://storage.juntosfmc.com.br/avatar/1598359154.5f450672673807.86869023.pdf"
                    className="icon-download"
                    download
                  >
                    Download
                  </a>
                </div>
              </LinksRegulation>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default AllRegulations;
