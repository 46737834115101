import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Buffer } from 'buffer';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { Input, PasswordInput, Button } from 'components/shared';
import validateCpf from 'util/validations/cpf';
import { useToast } from 'context/ToastContext';
import { CatalogStatusError, TwoLoginException } from 'services/auth/errors';
import {
  Establishment,
  MfaToken,
  MfaTokenSms,
  previousSignInParticipantData,
} from 'services/auth/signIn';
import { Container, Form, FormInput, FormLostPassword } from './styles';
import RecoveryPassword from './RecoveryPassword';
import ProfileAuth from './ProfileAuth';
import ModalToken from './ModalToken';
import ModalChoiceToken from './ModalChoiceToken';

interface SignInFormData {
  cpf: string;
  password: string;
  token?: string;
}

interface ObfuscatedContacts {
  obfuscatedEmail: string;
  obfuscatedCellPhone: string;
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [recoveryPassModalOpen, setRecoveryPassModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profiles, setProfiles] = useState<Establishment[]>([]);
  const [myPopupOpen, setMyPopupOpen] = useState(false);
  const [modalChoiceOpen, setModalChoiceOpen] = useState(false);
  const [credentials, setCredentials] = useState<SignInFormData>(
    {} as SignInFormData,
  );
  const [obfuscatedContacts, setObfuscatedContacts] = useState<
    ObfuscatedContacts
  >({} as ObfuscatedContacts);
  const { addToast } = useToast();
  const { state: defaultCpf } = useLocation<string>();
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedMethod, setSelectedMethod] = useState('');

  const schema = Yup.object().shape({
    cpf: Yup.string()
      .required('CPF é obrigatório')
      .test('valid-cpf', 'CPF inválido', validateCpf),
    password: Yup.string().required('Senha é obrigatória'),
  });

  const methods = useForm<SignInFormData>({
    validationSchema: schema,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
    defaultValues: {
      cpf: defaultCpf || '',
    },
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data: SignInFormData) => {
    setLoading(true);

    try {
      const { cpf, password } = data;
      setCredentials({ cpf, password });

      const contacts = await previousSignInParticipantData({
        cpf: Buffer.from(cpf).toString('base64'),
        password: Buffer.from(password).toString('base64'),
      });

      setObfuscatedContacts(contacts);

      setModalChoiceOpen(true);
    } catch (e) {
      const message: string =
       e.message|| 'Falha ao fazer login';

      if (
        e instanceof TwoLoginException ||
        String(e).includes('TwologinError')
      ) {
        const profilesValue = e.getProfiles();
        setCredentials({ ...data });
        setIsOpen(true);
        setProfiles(profilesValue);
        setLoading(false);
        return;
      }

      if (e instanceof CatalogStatusError) {
        window.open(e.message, '_self');
      }

      if (message.match(/Revenda/gi)) {
        history.push('/indiretos', { state: 'resale' });
        return;
      }
      if (message.match(/FMC/gi)) {
        history.push('/indiretos', { state: 'produtor' });
        return;
      }
      if (message.search(/Cooperativa/gi) >= 0) {
        history.push('/indiretos', { state: 'cooperative' });
        return;
      }

      addToast({
        title: message,
        type: 'error',
      });

      setMyPopupOpen(false);
      setLoading(false);
    }
  };

  const handleSingleSignOn = () => {
    const base = process.env.REACT_APP_API_HOST;
    window.location.href = `${base}/juntos-fmc/api/v1/sso`;
  };

  const handleRecoveryPassClick = () => setRecoveryPassModalOpen(true);
  const handleRecoveryPassClose = () => setRecoveryPassModalOpen(false);

  useEffect(() => {
    if (containerRef.current) {
      const input: HTMLInputElement | null = containerRef.current.querySelector(
        'input[name=cpf]',
      );
      if (input) {
        input.scrollIntoView({ behavior: 'smooth' });
        input.focus();
      }
    }
  }, [containerRef]);

  const handleSignInClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setMyPopupOpen(false);
    }, 2000);
  };

  const handleTokenSubmit = async (token: string) => {
    setValue('token', token);
    try {
      await handleSubmit(onSubmit)();
    } catch (e) {
      console.log('Erro ao submeter o token');
    }
    setMyPopupOpen(false);
  };

  const handleChooseEmail = async () => {
    setLoading(true);
    try {
      await MfaToken({
        cpf: Buffer.from(credentials.cpf).toString('base64'),
        password: Buffer.from(credentials.password).toString('base64'),
        method: 'email',
      });

      setModalChoiceOpen(false);
      setSelectedMethod('email');
      setMyPopupOpen(true);
    } catch (e) {
      addToast({
        title: e.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChooseSms = async () => {
    setLoading(true);
    try {
      await MfaTokenSms({
        cpf: Buffer.from(credentials.cpf).toString('base64'),
        password: Buffer.from(credentials.password).toString('base64'),
        method: 'sms',
      });

      setModalChoiceOpen(false);
      setSelectedMethod('sms');
      setMyPopupOpen(true);
    } catch (e) {
      addToast({
        title: e.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container ref={containerRef}>
        <FormContext {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormInput>
              <Input
                placeholder="Digite seu CPF"
                name="cpf"
                inputRole="terciary"
                role="presentation"
                numbersOnly
                pattern="XXX.XXX.XXX-XX"
              />
            </FormInput>
            <FormInput>
              <PasswordInput
                placeholder="Digite sua senha"
                inputRole="terciary"
                name="password"
                role="presentation"
              />
            </FormInput>
            <Button
              buttonRole="primary"
              type="submit"
              onClick={handleSignInClick}
              loading={loading}
            >
              ENTRAR
            </Button>
            <FormLostPassword>
              <button type="button" onClick={handleRecoveryPassClick}>
                Esqueci minha senha
              </button>
            </FormLostPassword>
            <Button
              buttonRole="primary"
              type="button"
              className="btn--outline"
              onClick={handleSingleSignOn}
            >
              SINGLE SIGN ON FMC
            </Button>
          </Form>
        </FormContext>
      </Container>
      <ProfileAuth
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        profiles={profiles}
        credentials={credentials}
      />
      <ModalChoiceToken
        isOpen={modalChoiceOpen}
        onRequestClose={() => setModalChoiceOpen(false)}
        onChooseEmail={handleChooseEmail}
        onChooseSms={handleChooseSms}
        cpf={credentials.cpf}
        password={credentials.password}
        obfuscatedEmail={obfuscatedContacts.obfuscatedEmail}
        obfuscatedCellPhone={obfuscatedContacts.obfuscatedCellPhone}
      />
      <ModalToken
        isOpenOne={myPopupOpen}
        onRequestClose={() => setMyPopupOpen(false)}
        onSubmit={handleTokenSubmit}
        selectedMethod={selectedMethod}
        onChooseEmail={handleChooseEmail}
        onChooseSms={handleChooseSms}
        obfuscatedCellPhone={obfuscatedContacts.obfuscatedCellPhone}
      />
      <RecoveryPassword
        isOpen={recoveryPassModalOpen}
        onClose={handleRecoveryPassClose}
      />
    </>
  );
};

export default SignIn;
