import React from 'react';

import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { InvoiceStatusSummary } from 'services/nf/interfaces';
import { Container } from './styles';

interface StatusTableProps {
  status: InvoiceStatusSummary;
  display: string;
}

const StatusTable: React.FC<StatusTableProps> = ({ status, display }) => {
  const { awaitingApproval, disqualified, underAnalysis, released } = status;

  return (
    <Container display={display}>
      <Link to={routeMap.receipts}>
        <ul>
          <li>
            <p>Em análise</p>
            <span />
            <p>{underAnalysis + awaitingApproval}</p>
          </li>
          <li>
            <p>Liberadas</p>
            <span />
            <p>{released} </p>
          </li>
          <li>
            <p>Descredenciadas</p>
            <span />
            <p>{disqualified} </p>
          </li>
        </ul>
      </Link>
    </Container>
  );
};

export default StatusTable;
