import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentBox = styled.div`
  /* display: flex;
  flex-direction: column; */
  padding: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  width: 100%;
  height: 100%;

  > h3 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 8px;
  }

  @media screen and (max-width: 720px) {
    margin-top: 15px;
    width: 100%;

    > h3 {
      align-self: center;
    }
  }
`;
