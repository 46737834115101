import { EstablishmentCategory, EstablishmentTypes } from 'config/constants';
import { FetchState } from '@types';
import { Mode } from './types';

export interface SimulationData {
  unitValueInDollar: number;
  revenuesInKilosPerLiter: number;
  revenuesInDollar: number;
  pogInKilosPerLiter: number;
  pogUnitValueInDollar: number;
  pogInDollar: number;
  pogRealizedNetInDollarSimulated: number;
  pogRealizedNetInRealSimulated: number;

  // MAYCONN
  pogRealizedNetInDollarTotal: number;
  pogRealizedNetInRealTotal: number;
}

export interface Stock {
  inKilosPerLiter: number;
  inDollar: number;
}

export interface PointsData {
  rebateReachedInRealSimulated: number;
  rebateReachedInRealSimulatedButUsedToGetTotal: number;
  rebateReachedInRealRealized: number;
  rebateReachedInRealTotal: number;
  sellerReachedInRealSimulated: number;
  sellerReachedInRealSimulatedButUsedToGetTotal: number;
  sellerReachedInRealRealized: number;
  sellerReachedInRealTotal: number;
  additionalMarginSimulated: number;
  additionalMarginRealized: number;
  additionalMarginTotal: number;
  sellerBiologicalReachedInRealRealized: number;
  sellerBiologicalReachedInRealSimulated: number;
  sellerBiologicalReachedInRealSimulatedButUsedToGetTotal: number;
  sellerBiologicalReachedInRealTotal: number;
  rebateBiologicalReachedInRealRealized: number;
  rebateBiologicalReachedInRealSimulated: number;
  rebateBiologicalReachedInRealSimulatedButUsedToGetTotal: number;
  rebateBiologicalReachedInRealTotal: number;
}
export interface Product {
  id: number;
  checked: boolean;
  name: string;
  isEnhancer: boolean;
  isTerraBrasil: boolean;
  isBiological: boolean;
  isParticipatingProduct: boolean;
  cropEnhancer: boolean;
  enhancerSegment: String;
  type: {
    id: number;
    name: string;
  };
  revenues: {
    goalInDollar: number;
    realizedInDollar: number;
    goalInKilosByLiter: number;
    realizedInKilosByLiter: number;
  };
  pog: {
    goalInDollar: number;
    realizedInDollar: number;
    goalInKilosByLiter: number;
    realizedInKilosByLiter: number;
  };
  stock: Stock;
  simulationData: SimulationData;
  simulationPoints: PointsData;
  awardsParamsToPay: {
    rebatePercentage: number;
    rebatePercentageBiological: number;
    sellerValueInReal: number;
    sellerValueInRealBiological: number;
    additionalMarginPercentage: number;
    additionalMarginPercentageBiological: number;
  };
  extraPercentageToPayByEnhancerProduct: number;
}

export interface Channel {
  id: number;
  name: string;
  groupName: string;
  code: string;
  type: EstablishmentTypes;
  category: EstablishmentCategory;
  isBiological: boolean;
  hasAdditionalMargin: boolean;
}

export enum IndicatorType {
  revenues = 'Faturamento',
  pog = 'Pog',
  aurora = 'Aurora 400 EC',
  hero = 'Hero',
  talisman = 'Talisman',
  altacor = 'Altacor',
  boralFull = 'Boral Full',
  quartzo = 'Quartzo',
  revenuesBiological = 'Faturamento Biológico',
  pogBiological = 'Pog Biológico',
  veriMark = 'VeriMark',
  stone = 'Stone',
  premioStar = 'Premio Star',
  fungicidas = 'FUNGICIDAS',
  herbicidas = 'HERBICIDAS',
  inseticidas = 'INSETICIDAS',
  inseticidasByRynaxypyr = 'INSETICIDAS BY RYNAXYPYR',
}

export enum UnitType {
  dollar = 'Dolar',
  liter = 'Litro',
  kilo = 'Kilo',
}

export interface Indicator {
  title: string;
  type: IndicatorType;
  unitType: UnitType;
  isRegisteredProduct: boolean;
  lastRealized: number;
  currentGoal: number;
  currentRealized: number;
  percentageRealized: number;
  currentYear: string;
  lastYear: string;
  simulationData: {
    totalRealized: number;
    totalPercentageRealized: number;
    totalSimulated: number;
  };
  isBiological: boolean;
  isTerraBrasil: boolean;
}

export interface Award {
  simulatedRebate: number;
  realizedRebate: number;
  totalRebate: number;
  simulatedSeller: number;
  realizedSeller: number;
  totalSeller: number;
  simulatedAdditionalMargin: number;
  realizedAdditionalMargin: number;
  totalAdditionalMargin: number;
  simulatedRebateBiological: number;
  realizedRebateBiological: number;
  totalRebateBiological: number;
  simulatedSellerBiological: number;
  realizedSellerBiological: number;
  totalSellerBiological: number;
}
export interface ValuesConfig {
  balance_type_name: string;
  value: number;
  pay_percent: number;
}

export interface Configuration {
  partialDate?: Date;
  partialDateInvoicing?: Date;
  partialDatePog?: Date;
  percent_to_pay_rebate: ValuesConfig[];
  percent_to_pay_seller: ValuesConfig[];
  percent_to_pay_biological: ValuesConfig[];
  pogRealizedNetPercentage: number;
  minimumRebatePercentageToMakePoints: number;
  minimumSellerPercentageToMakePoints: number;
  minimumBiologicalPercentageToMakePoints: number;
  biologicalSimulationValue: number;
  finalCalculation: number;
}

export type PointsSimulatorState = {
  mode: Mode;
  fetchChannel: FetchState;
  channel: Channel | null;
  fetchProducts: FetchState;
  products: Product[];
  dollarBaseValue: number;
  fetchCalculate: FetchState;
  fetchIndicators: FetchState;
  indicators: Indicator[];
  award: Award;
  fetchConfiguration: FetchState;
  configuration: Configuration;
};
