import routeMap from 'routes/route-map';
import FmcCoinsSimulator from 'pages/FmcCoinsSimulator/';
import Calculator from 'pages/FmcCoinsSimulator/Calculator';
import Resultado from 'pages/FmcCoinsSimulator/Result';
import ResultSaved from 'pages/FmcCoinsSimulator/ResultSaved';
import CalculatorSaved from 'pages/FmcCoinsSimulator/CalculatorSaved';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: `${routeMap.fmcCoinsSimulator.selector}`,
    component: FmcCoinsSimulator,
    accessPage: 'Simulador de FMC Coins',
  },
  {
    path: `${routeMap.fmcCoinsSimulator.calculator}`,
    component: Calculator,
    accessPage: 'Simulador de FMC Coins',
  },
  {
    path: `${routeMap.fmcCoinsSimulator.result}`,
    component: Resultado,
    accessPage: 'Resultado',
  },
  {
    path: `${routeMap.fmcCoinsSimulator.resultSaved}/:id`,
    component: ResultSaved,
    accessPage: 'Resultado Salvo',
  },

  {
    path: `${routeMap.fmcCoinsSimulator.calculatorSaved}/:id`,
    component: CalculatorSaved,
    accessPage: 'Simulador de FMC Coins',
  },
];

export default routes;
