import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm, FormContext } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import { useLocation, useHistory } from 'react-router-dom';

import closeIcon from 'assets/images/training/close-icon.svg';
import { Input, Button } from 'components/shared';
import DefaultModal from 'components/shared/Modal';
import { useToast } from 'context/ToastContext';
import validateCpf from 'util/validations/cpf';

import {
  getParticipantByCpf,
  getParticipantByUpn,
} from 'services/register/getParticipantData';

import ModalPreCadastro from 'components/Contact/Disconnected/ModalPreCadastro';
import { PrimeiroAcessoDTO } from 'pages/primeiro-acesso';
import {
  Container,
  Form,
  FormInput,
  Title,
  ContainerModal,
  Close,
} from './styles';

interface SignUpFormData {
  cpf_or_name: string;
}

enum ParticipantType {
  CPF,
  FMC_NAME,
}

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentCPF, setCurrentCPF] = useState('');
  const [showFormModal, setShowFormModal] = useState(false);
  const [participantType, setParticipantType] = useState<ParticipantType>(
    ParticipantType.FMC_NAME,
  );

  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();

  const INDICATOR_CODE = location.search.replace('?code=', '');

  const schema = Yup.object().shape({
    cpf_or_name: Yup.string().required('Campo obrigatório'),
  });

  const methods = useForm<SignUpFormData>({
    validationSchema: schema,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async ({ cpf_or_name }) => {
    setLoading(true);

    try {
      const participant =
        participantType === ParticipantType.CPF
          ? await getParticipantByCpf(cpf_or_name)
          : await getParticipantByUpn(cpf_or_name);
      const initialData: PrimeiroAcessoDTO = {
        participant,
      };

      history.push('/primeiro-acesso', initialData);
    } catch (e) {
      setLoading(false);
      if (e.response?.data?.message === 'CPF não encontrado') {
        if (validateCpf(cpf_or_name)) {
          setShowModal(true);
          setCurrentCPF(cpf_or_name);
        } else {
          addToast({
            title: 'CPF inválido',
            type: 'error',
          });
        }
      } else {
        addToast({
          title: e.response?.data?.message || 'Falha ao checar CPF',
          type: 'error',
        });
      }
    }
  });

  const handleOpenPreCadastro = () => {
    setShowModal(false);

    const initialData: PrimeiroAcessoDTO = {
      cpf: currentCPF,
    };

    INDICATOR_CODE.length > 1
      ? history.push(`/primeiro-acesso?code=${INDICATOR_CODE}`, initialData)
      : history.push('/primeiro-acesso', initialData);
  };

  const handleHybridInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;

    if (!value || value.length < 2) {
      setParticipantType(ParticipantType.FMC_NAME);
      return;
    }

    const isCPF = /^[\d.-]+$/.test(value);

    if (isCPF && participantType !== ParticipantType.CPF) {
      setParticipantType(ParticipantType.CPF);
    }
  };

  return (
    <Container>
      <FormContext {...methods}>
        <DefaultModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          type="secondary"
        >
          <ContainerModal>
            <Close>
              <button type="button" onClick={() => setShowModal(false)}>
                <ReactSVG src={closeIcon} />
              </button>
            </Close>
            <h3> Bem-vindo(a)! </h3>
            <p>
              Se você é colaborador(a) de uma Cooperativa ou Revenda parceira do
              JUNTOS, solicite o acesso ao responsável do seu estabelecimento.
            </p>
            <p>
              Se você é Produtor(a) que compra produtos FMC nos estabelecimentos
              parceiros do JUNTOS, clique no botão abaixo.
            </p>
            <Button
              type="submit"
              buttonRole="quaternary"
              onClick={handleOpenPreCadastro}
            >
              SOU PRODUTOR
            </Button>
          </ContainerModal>
        </DefaultModal>

        <Form onSubmit={onSubmit}>
          <Title>
            <span>É NOVO POR AQUI?</span>
          </Title>
          <FormInput>
            <Input
              placeholder="CPF ou usuário FMC"
              name="cpf_or_name"
              inputRole="terciary"
              role="presentation"
              onChange={handleHybridInputChange}
              numbersOnly={participantType === ParticipantType.CPF}
              pattern={
                participantType === ParticipantType.CPF ? 'XXX.XXX.XXX-XX' : '*'
              }
            />
          </FormInput>
          <Button buttonRole="primary" type="submit" loading={loading}>
            CADASTRE-SE
          </Button>
        </Form>

        <ModalPreCadastro
          isOpen={showFormModal}
          onRequestClose={() => setShowFormModal(false)}
        />
      </FormContext>
    </Container>
  );
};

export default SignUp;
