import React from 'react';

import { Button } from 'components/shared';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';

import { Modal, Container, Content, Close } from './styles';

interface RequiredQuestionsModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  text: string | Node;
  className?: string;
  questions: string[];
}

const RequiredQuestionsModal: React.FC<RequiredQuestionsModalProps> = ({
  isOpen,
  onRequestClose,
  text,
  className,
  questions,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type="primary"
      className={className}
      zIndex={100}
    >
      <Container>
        <Close>
          <button type="button" onClick={onRequestClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <Content>
          <h4>{text}</h4>
          <div>
            <p>{`${questions.join(',')}.`}</p>
            {/* <ul>
              {questions.map(item => (
                <li>{item}</li>
              ))}
              </ul> */}
          </div>
          <Button
            type="button"
            buttonRole="quaternary"
            onClick={onRequestClose}
          >
            Ok
          </Button>
        </Content>
      </Container>
    </Modal>
  );
};

export default RequiredQuestionsModal;
