import {
  ActionCreatorPayload,
  ActionCreator,
  ActionCreatorFailure,
} from '@types';
import { AnswerData, Surveys } from './types';
import { SurveyState } from './reducer';

import {
  SET_VALUE_ANSWERS,
  CLEAN_VALUE_ANSWERS,
  FETCH_SURVEYS_ACTION,
  FETCH_SURVEYS_FAILURE,
  FETCH_SURVEYS_SUCCESS,
} from './constants';

export const setValueAnswer = (
  data: AnswerData,
): ActionCreatorPayload<typeof SET_VALUE_ANSWERS, AnswerData> =>
  <const>{
    type: SET_VALUE_ANSWERS,
    payload: data,
  };

export const fetchSurveys = (): ActionCreator<typeof FETCH_SURVEYS_ACTION> =>
  <const>{
    type: FETCH_SURVEYS_ACTION,
  };

export const fetchSurveysSuccess = (
  surveys: Surveys,
): ActionCreatorPayload<
  typeof FETCH_SURVEYS_SUCCESS,
  Pick<SurveyState, 'surveys'>
> =>
  <const>{
    type: FETCH_SURVEYS_SUCCESS,
    payload: { surveys },
  };

export const fetchSurveysFailure = (
  error: string,
): ActionCreatorFailure<typeof FETCH_SURVEYS_FAILURE> =>
  <const>{
    type: FETCH_SURVEYS_FAILURE,
    payload: {
      error,
    },
  };

export const clearAnswers = (): ActionCreator<typeof CLEAN_VALUE_ANSWERS> =>
  <const>{
    type: CLEAN_VALUE_ANSWERS,
  };

export type SurveyActions = ReturnType<
  | typeof setValueAnswer
  | typeof fetchSurveys
  | typeof fetchSurveysSuccess
  | typeof fetchSurveysFailure
  | typeof clearAnswers
>;
