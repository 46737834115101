import styled from 'styled-components';

import { FONTS } from 'styles/font/globals';

export const Container = styled.div`
  width: 100%;

  h1 {
    font-size: 24px;
    color: #3b302a;
  }
`;

export const GridCheckBoxContent = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  overflow-x: auto;

  p {
    font-size: 14px;
    font-family: ${FONTS.condensed}, sans-serif;
    color: #000;
    margin-bottom: 12px;
  }
`;

export const CheckBoxContentGroup = styled.div`
  width: 70%;
  margin-top: 25px;
  color: #3b302a;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 720px) {
    width: 100%;
    min-width: 560px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const GridRow = styled.div`
  padding: 10px 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  column-gap: 5px;
`;

export const AnswerLabel = styled.span`
  min-width: 75px;
`;
