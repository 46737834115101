import React, { useEffect, useState } from 'react';
import { fetchCampaignsService } from 'services/goals';
import { Campaign } from 'state/modules/goals/types';
import { SelectWrapper, StyledSelect, SubTitle } from './styles';

interface CampaignsListProps {
  selectedCampaignId?: number;
  selectCampaign: (campaignId: number) => void;
  isTerraBrasil: boolean;
}

const Campaigns: React.FC<CampaignsListProps> = ({
  selectedCampaignId,
  isTerraBrasil = true,
  selectCampaign,
}) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const data = await fetchCampaignsService();
      setCampaigns(data || []);
    };
    fetchCampaigns();
  }, []);

  const filteredSafra = campaigns.filter(
    c => c.title !== '19/20' && !c.isTerraBrasil,
  );
  const campaignsTerra = campaigns.filter(
    c => c.title !== '2022' && c.isTerraBrasil,
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    selectCampaign(selectedId);
  };

  const availableCampaigns = isTerraBrasil ? campaignsTerra : filteredSafra;

  return (
    <SelectWrapper>
      <SubTitle>{isTerraBrasil ? 'Ano' : 'Safra'}</SubTitle> 
      <StyledSelect value={selectedCampaignId || ''} onChange={handleChange}>
        {availableCampaigns.map(campaign => (
          <option key={campaign.id} value={campaign.id}>
            {campaign.title}
          </option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
};

export default Campaigns;