import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 0 150px;

  > h2 {
    font-size: 18px;
    margin-bottom: 5px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }

  @media (max-width: 780px) {
    padding: 0 50px;
  }
`;

export const ListContent = styled.table`
  width: 100%;
  list-style: none;
  border-collapse: collapse;

  th {
    width: 500px;
    margin-right: 30px;
    background: ${({ theme }) => theme.font.color.primary};
    height: 15px;
    padding: 8px;
    color: #fff;
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  tr.last-row {
    td {
      border-bottom: none;
    }
  }

  td {
    color: #000;
    margin-left: 30px;
    background-color: #efefef;
    padding: 8px;
    border-bottom: 4px solid #fff;
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
  }

  .count {
    text-decoration: underline;
    color: #3a3f46;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-left: 30px;
  }
`;

export const CampaignsList = styled.ul`
  list-style-type: none;
  display: flex;

  li {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em 0.5em;
    cursor: pointer;
    transition: color 150ms ease;

    button {
      background: none;
      border: none;
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.medium};
      font-size: 1em;
      &.selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &:hover {
      color: #999999;
    }
  }
`;
