export default {
  primary: {
    fontColor: '#312e38',
    closeIconColor: '#d7d8da',
    openIconColor: '#004E27',
    backgroundOpenIconColor: '#d7d8da',
    backgroundCloseIconColor: '#004E27',
    borderColor: '#312e38',
  },
  secondary: {
    fontColor: '#808285',
    closeIconColor: '#fff',
    openIconColor: '#004E27',
    backgroundOpenIconColor: '#D0CBC9',
    backgroundCloseIconColor: '#004E27',
    borderColor: '#312e38',
  },
};
