/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ReactLoading from 'react-loading';

import { EstablishmentTypes, PROFILES } from 'config/constants';
import { useAuth } from 'context/AuthContext';
import { formatPointsExtract } from 'util/points';
import { Extract as IExtract } from 'services/extract/interfaces';
import { getExtractByEstablishment } from 'services/extract/getExtractByEstablishment';
import { getExtractUserEstablishment } from 'services/extract/getExtractUserEstablishment';

import { Tooltip } from 'components/shared';
import ExtractHeader from 'components/Extract/ExtractHeader';
import ExtractDetails from 'components/Extract/ExtractDetails';
import { EstablishmentSelect } from './EstablishmentSelect';
import {
  HeaderContainer,
  Container,
  ContentWrapper,
  HeaderRescueBoxContainer,
  HeaderRescueBox,
} from '../styles';

enum ScreenState {
  LOADING,
  READY,
  ERROR,
}

export const ExtractChannelPoints = () => {
  const [screenState, setScreenState] = useState<ScreenState>(
    ScreenState.LOADING,
  );
  const [selectedEstablishmentId, setSelectedEstablishmentId] = useState<
    string
  >('');
  const [selectedEstablishmentType, setSelectedEstablishmentType] = useState<
    EstablishmentTypes
  >();
  const [extractData, setExtractData] = useState<IExtract>();

  const { participant } = useAuth();

  const isFmcTeam = participant.profile === PROFILES.fmc;
  const isFocalPoint = participant.profile === PROFILES.focalPoint;
  const isParticipant = participant.profile === 'PARTICIPANTE';

  const handleEstablishmentChange = ({
    id,
    type,
  }: {
    id: string;
    type: EstablishmentTypes;
  }) => {
    setSelectedEstablishmentId(id);
    setSelectedEstablishmentType(type);
  };

  useEffect(
    function fetchExtractDetails() {
      async function fetch() {
        setScreenState(ScreenState.LOADING);
        setExtractData(undefined);

        const hasEstablishmentSelected = !!selectedEstablishmentId;

        if (isFmcTeam) {
          if (hasEstablishmentSelected) {
            try {
              const extractResponse = await getExtractByEstablishment(
                selectedEstablishmentId,
              );

              setExtractData(extractResponse);
              setScreenState(ScreenState.READY);
            } catch (error) {
              console.error(error);

              setScreenState(ScreenState.ERROR);
            }
          } else {
            setScreenState(ScreenState.READY);
          }
        } else {
          try {
            const extractResponse = await getExtractUserEstablishment();

            setExtractData(extractResponse);
            setScreenState(ScreenState.READY);
            setSelectedEstablishmentType(participant.establishment.type_name);
            setSelectedEstablishmentId(participant.establishment.id.toString());
          } catch (error) {
            console.error(error);

            setScreenState(ScreenState.ERROR);
          }
        }
      }

      fetch();
    },
    [isFmcTeam, selectedEstablishmentId, participant],
  );

  const tooltipUserType =
    (isFocalPoint || isParticipant || isFmcTeam) &&
    selectedEstablishmentType === EstablishmentTypes.TerraBrasil
      ? 'KAM'
      : 'RTC';
  const TooltipText = () => (
    <p>
      Recurso para {tooltipUserType} e cliente fomentarem juntos o
      desenvolvimento dos negócios.
    </p>
  );

  const TooltipTextIGD = () => (
    <p>
      Recurso para o {tooltipUserType} fomentar o desenvolvimento do canal.
    </p>
  );

  const isLoading = screenState === ScreenState.LOADING;
  const hasError = screenState === ScreenState.ERROR;

  return (
    <Container>
      <HeaderContainer>
        <ContentWrapper>
          {isFmcTeam && (
            <EstablishmentSelect
              onEstablishmentChange={handleEstablishmentChange}
            />
          )}

          <h2>Saldo Disponível Para Resgate</h2>
          <div>
            {extractData && extractData.balance[1]?.value === 0 ? (
              <HeaderRescueBoxContainer>
                <HeaderRescueBox
                  key={(extractData && extractData.balance[0]?.title) || ''}
                >
                  <Tooltip
                    title={<TooltipText />}
                    type="neutral"
                    className="tooltip"
                    position="top"
                  >
                    <AiOutlineInfoCircle />
                  </Tooltip>

                  <p>{(extractData && extractData.balance[0].title) || ''}</p>

                  <span>
                    {(extractData &&
                      formatPointsExtract(extractData.balance[0].value)) ||
                      ''}{' '}
                    pontos
                  </span>
                </HeaderRescueBox>
                {
                  extractData.balance.map(
                    item => 
                      item.title === 'IGD' && (
                        <HeaderRescueBox key={item.title}>
                          <Tooltip
                            title={<TooltipTextIGD />}
                            type="neutral"
                            className="tooltip"
                            position="top"
                          >
                            <AiOutlineInfoCircle />
                          </Tooltip>
                          <p>{item.title}</p>
                          <span>
                            {formatPointsExtract(item.value)} pontos
                          </span>
                        </HeaderRescueBox>
                      )
                  )
                }  
              </HeaderRescueBoxContainer>
            ) : (
              <HeaderRescueBoxContainer>
                {extractData?.balance.map(balance => (
                  <HeaderRescueBox key={balance.title}>
                    <Tooltip
                      title={<TooltipText />}
                      type="neutral"
                      className="tooltip"
                      position="top"
                    >
                      <AiOutlineInfoCircle />
                    </Tooltip>

                    <p>{balance.title}</p>

                    <span>{formatPointsExtract(balance.value)} pontos</span>
                  </HeaderRescueBox>
                ))}
              </HeaderRescueBoxContainer>
            )}
          </div>
        </ContentWrapper>
      </HeaderContainer>

      <ContentWrapper>
        <div className="extract-body">
          {isLoading && (
            <div className="empty-container">
              <ReactLoading
                className="_loading"
                type="bars"
                height={32}
                width={32}
              />
            </div>
          )}

          {hasError && (
            <div className="empty-container">
              <span className="error">
                Ocorreu um erro ao carregar as informações
              </span>
            </div>
          )}

          {!extractData && !isLoading && (
            <div className="empty-container">
              <span>Selecione um canal para visualizar as informações</span>
            </div>
          )}

          {extractData &&
            selectedEstablishmentType &&
            !!extractData.resume.length && (
              <ExtractHeader
                extract={extractData}
                establishmentType={selectedEstablishmentType}
              />
            )}

          {extractData && selectedEstablishmentType && (
            <ExtractDetails
              details={extractData}
              establishmentType={selectedEstablishmentType}
              establishmentId={selectedEstablishmentId}
            />
          )}
        </div>
      </ContentWrapper>
    </Container>
  );
};
