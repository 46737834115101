import React, { useCallback, useEffect, useMemo, useState } from 'react';

import parser from 'html-react-parser';
import { fetchCampaignsService } from 'services/goals';
import { Campaign } from 'state/modules/goals/types';
import { getChartsData, Data as IData } from 'services/dashboard/charts';
import {
  getCharts,
  getClients,
  Charts as ICharts,
  Client as IClient,
  ChartName,
  ChartCategory,
} from 'services/dashboard/charts/transformers';

import { useAuth } from 'context/AuthContext';
import NoResults from 'components/shared/NoResults';
import ThemeLoading from 'components/shared/ThemeLoading';
import getChart from './getChart';
import Filters from './Filters';
import { Container, Box } from './styles';
import { ProductType } from '..';

type Arr = [string, ChartCategory, () => JSX.Element];

interface ChartsProps {
  campaignId?: number;
  productType: ProductType;
}

const Charts: React.FC<ChartsProps> = ({ campaignId, productType }) => {
  const [loading, setLoading] = useState(true);
  const [already, setAlready] = useState(false);
  const [chartsData, setChartsData] = useState<IData[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [filteredClients, setFilteredClients] = useState<IClient[]>([]);
  const [charts, setCharts] = useState<ICharts | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const { participant } = useAuth();

  const isTerraBrasil = campaigns
    .filter(i => i.isTerraBrasil === true)
    .map(i => {
      return i.id;
    });

  if(campaignId === undefined || campaignId <= 0){
      campaignId = participant.establishment.type_name === 'Terra Brasil' ? 10 : 11;
  }
  

  const campaignIdForComparison = campaignId ?? 0;

  const isTerraBrasilCampaign = isTerraBrasil.includes(Number(campaignId));

  const isBiological = chartsData.filter(
    i => i.check_biologic?.biological === true,
  );

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaign = await fetchCampaignsService();
      setCampaigns(campaign || []);
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (!participant) return;

    setLoading(true);
    getChartsData(participant.profile_value, campaignId)
      .then(data => setChartsData(data))
      .finally(() => setLoading(false));
  }, [campaignId, participant]);

  useEffect(() => {
    if (chartsData.length === 0) {
      setClients([]);
      setCharts(null);
      return;
    }

    const filter = filteredClients.length > 0 ? filteredClients : undefined;

    setClients(getClients(chartsData, isTerraBrasilCampaign));
    setCharts(getCharts(chartsData, isTerraBrasilCampaign, filter));
  }, [chartsData, filteredClients, isTerraBrasilCampaign]);

  const drawChart = useMemo(() => {
    setAlready(false);
    const arr: Arr[] = [];
    if (!charts) return arr;
    
    const checkBiological = clients.filter(c => c.biological === true);
    const biologicalNames = checkBiological.map(channel => channel.name);

    const havePremioValue = charts.premioRealized.dataset.some(
      i => i.data[0] !== undefined && i.data[0].toString() !== '0',
    );

    const haveAuroraValue = charts.auroraRealized.dataset.some(
      i => i.data[0] !== undefined && i.data[0].toString() !== '0',
    );

    const labels =
      filteredClients.length > 0
        ? clients
            .filter(item => !!filteredClients.find(i => i.name === item.name))
            .map(item => item.name)
        : clients.map(item => item.name);

    if (isTerraBrasilCampaign) {
      charts.biologicalPogRealized.title = 'POG Fornecedor (MM US$)';
      charts.pogRealized.title = 'POG Fornecedor (MM US$)';
    }

    let nameProdutsFifteen = [
      'Faturamento (MM US$)',
      'POG Fornecedor (MM US$)',
      'QUARTZO<span>®</span> (L)',
      'ALTACOR<span>®</span> (L)',
      'BORAL FULL<span>®</span> (L)',
      'REATOR 360 CS<span>®</span> (L)'
    ];
    
    let nameProdutsSeventeen = [
      'Faturamento (MM US$)',
      'POG Fornecedor (MM US$)',
      'QUARTZO<span>®</span> (L)',
      'ALTACOR<span>®</span> (L)',
      'STONE<span>®</span> (L)',
      'VERIMARK<span>®</span> (L)',
      'PREMIO STAR<span>®</span> (L)',
      'BORAL FULL<span>®</span> (L)'
    ];

    Object.keys(charts).forEach(item => {
      const { dataset, title, divideValueBy, category } = charts[
        item as ChartName
      ];

      if (item === 'premioRealized' && !havePremioValue) {
        return;
      }

      if (item === 'auroraRealized' && !haveAuroraValue) {
        return;
      }

      const check = dataset[0].data.some(value => value !== 0);

    if ((campaignId === 7 && nameProdutsFifteen.includes(title) && check) ||
    (campaignId === 10 && nameProdutsSeventeen.includes(title) && check) ||
    (campaignId !== 10 && campaignId !== 7 && check)) {
        arr.push([
          title,
          category,
          () =>
            getChart(
              {
                labels:
                  category === 'biological'
                  ? biologicalNames
                    : labels,
                datasets: dataset.map(_item => {
                  const filteredData = _item.data.filter((value, index) => {
                    if (category === 'biological') {
                      return biologicalNames.includes(clients[index].name) && value >= 0;
                    }
                    return value >= 0;
                  });
  
                  return {
                    data: filteredData,
                    backgroundColor: _item.backgroundColor,
                    borderColor: _item.borderColor,
                    borderWidth: _item.borderWidth,
                    label: _item.label,
                    visible: _item.visible,
                  };
                }),
                showLabel: true,
                divideValueBy,
              }
            ),
        ]);
      }
    });

    setAlready(true);

    return arr;
  }, [charts, clients, filteredClients, isTerraBrasilCampaign, campaignId]);

  const onFilter = useCallback((data: IClient[]): void => {
    setFilteredClients(data);
  }, []);

  const hasChartsData = !!chartsData.length;

  return (
    <Container>
      {loading && <ThemeLoading size={32} />}

      {!loading && !hasChartsData && <NoResults />}

      {!loading && hasChartsData && already && (
        <>
          <Filters clients={clients} onFilter={onFilter} />

          {
          drawChart
            .filter(([_, category]) => category === 'all')
            .map(([title, _, item]) => (
              <Box key={title}>
                <h3>{parser(title || '')}</h3>
                {item()}
              </Box>
            ))}

          {productType !== 'terrabrasil' &&
          campaignIdForComparison < 11 && 
            drawChart
              .filter(([_, category]) => category === 'normal')
              .map(([title, _, item]) => (
                <Box key={title}>
                  <h3>{parser(title || '')}</h3>
                  {item()}
                </Box>
              ))}
          
          {productType !== 'terrabrasil' &&
          campaignIdForComparison >= 11 &&
            drawChart
              .filter(([_, category]) => category === 'NewNormal')
              .map(([title, _, item]) => (
                <Box key={title}>
                  <h3>{parser(title || '')}</h3>
                  {item()}
                </Box>
              ))}

          {productType === 'terrabrasil' &&
          campaignIdForComparison < 11 && (
            <>
              <h3>Terra Brasil</h3>

              {drawChart
                .filter(([_, category]) => category === 'terraBrasil')
                .map(([title, _, item]) => (
                  <Box key={title}>
                    <h3>{parser(title || '')}</h3>
                    {item()}
                  </Box>
                ))}
            </>
          )}

          {isBiological.length > 0 && (
            <>
              <h3>Biológicos</h3>

              {drawChart
               .filter(([_, category]) => category === 'biological')
               .map(([title, _, item]) => {
                 return (
                   <Box key={title}>
                     <h3>{parser(title || '')}</h3>
                     {item()}
                   </Box>
                 );
               })}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Charts;
