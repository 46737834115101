import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`;

export const TitleHerbicidas = styled.div`
  width: 100%;
  color: #fff;
  background-color: #3ba13b;
  padding: 5px 0px;
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
`;

export const TitleInseticidas = styled.div`
  width: 100%;
  color: #fff;
  background-color: #913944;
  padding: 5px 0px;
  text-align: center;
  font-weight: 700;
`;

export const TitleInseticidasByRynaXypyr = styled.div`
  width: 100%;
  color: #fff;
  background-color: #838bc5;
  padding: 5px 0px;
  text-align: center;
  font-weight: 700;
`;

export const TitleFungicidas = styled.div`
  width: 100%;
  color: #000;
  background-color: #c8ed06;
  padding: 5px 0px;
  text-align: center;
  font-weight: 700;
`;