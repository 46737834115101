import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserRoulettes } from 'state/modules/home/actions';

import { Modal } from './components/Modal';
import { RouletteProvider } from './hooks/use-roulette';

const Roulette: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserRoulettes());
  }, [dispatch]);

  return (
    <RouletteProvider>
      <Modal />
    </RouletteProvider>
  );
};

export default Roulette;
