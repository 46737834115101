import React, { useCallback, useRef, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Option } from 'components/shared/Select';

import { useToast } from 'context/ToastContext';

import openTicket from 'services/contact/disconnected/openTicket';

import schemaValidation from './schemaValidation';

import {
  Container,
  Title,
  BoxPhone,
  Input,
  SubjectSelect,
  TextArea,
  Button,
  DefaultModal,
  Label,
} from './styles';
import uploadFileToStorage from '../../../../services/storage/sendFile';

interface ModalProps {
  isOpen: boolean;
  onRequestClose(): void;
}

interface ContactFormData {
  name: string;
  cpf: string;
  email: string;
  dddMobile: string;
  mobile: string;
  subject: Option | null;
  message: string;
  fileUrl: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [attachingFile, setAttachingFile] = useState(false);

  const methods = useForm<ContactFormData>({
    validationSchema: schemaValidation,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
    defaultValues: {
      subject: null,
    },
  });

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    try {
      const { message } = await openTicket({
        ...data,
        subjectId: parseInt(data.subject?.value || '0', 0),
        fileUrl,
      });
      addToast({ title: message });
      onRequestClose();
    } catch (e) {
      addToast({
        title:
          e.response?.data?.message ||
          'Falha ao abrir chamado. Por favor tente novamente',
        type: 'error',
      });
    }
    setLoading(false);
  });

  const inputRole = 'secondary';

  const handleAttachFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e && e.target && e.target.files && e.target.files.length > 0) {
        const isFileTooLarge = e.target.files[0].size / 1024 > 4096;
        if (isFileTooLarge) {
          addToast({
            title: 'Arquivo excede o tamanho máximo de 4mb!',
            type: 'error',
          });
          return;
        }

        setAttachingFile(true);
        const { url } = await uploadFileToStorage(e.target.files[0], 'avatar');
        setFileUrl(url);
        setAttachingFile(false);
      }
    },
    [addToast],
  );

  return (
    <DefaultModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type="primary"
    >
      <Container>
        <Title>Fale conosco</Title>
        <Label>Sua mensagem será respondida em até 48 horas úteis</Label>
        <FormContext {...methods}>
          <form onSubmit={onSubmit}>
            <Input name="name" label="Nome" inputRole={inputRole} />
            <Input
              name="cpf"
              label="CPF"
              numbersOnly
              pattern="XXX.XXX.XXX-XX"
              inputRole={inputRole}
            />
            <Input name="email" label="E-mail" inputRole={inputRole} />
            <BoxPhone>
              <Input
                name="dddMobile"
                label="Celular"
                numbersOnly
                pattern="(XX)"
                inputRole={inputRole}
              />
              <Input
                name="mobile"
                numbersOnly
                pattern="X XXXX-XXXX"
                inputRole={inputRole}
              />
            </BoxPhone>
            <SubjectSelect name="subject" inputRole={inputRole} />
            <TextArea
              name="message"
              label="Mensagem"
              inputRole={inputRole}
              maxLength={350}
            />
            <label htmlFor="fileId">
              <input
                type="file"
                id="fileId"
                accept="image/*, .pdf, .xlsx"
                style={{ display: 'none' }}
                onChange={handleAttachFile}
                ref={inputFileRef}
              />
              <button
                type="button"
                className="fileButton"
                onClick={() => {
                  inputFileRef.current?.click();
                }}
              >
                {fileUrl !== '' ? (
                  <>Arquivo anexado</>
                ) : (
                  <>{attachingFile ? 'Carregando ... ' : 'Anexar arquivo'}</>
                )}
              </button>
              <p>Formatos de arquivos válidos: jpg/jpeg, png, pdf, xlsx*</p>
            </label>

            <Button type="submit" buttonRole="primary" loading={loading}>
              Enviar
            </Button>
          </form>
        </FormContext>
      </Container>
    </DefaultModal>
  );
};

export default Modal;
