import React, { useState, useEffect, useCallback } from 'react';

import { useForm, FormContext } from 'react-hook-form';

import { Option } from 'components/shared/Select';
import RolesSelect from 'components/shared/Vendavall/Roles/ProtectedRolesSelect';
import FilialSelect from 'components/shared/Vendavall/Establishments/FilialSelect';
import ICreateParticipantIndicateDTO from 'services/participant-indication/dtos/ICreateParticipantIndicateDTO';
import IEditParticipantIndicateDTO from 'services/participant-indication/dtos/IEditParticipantIndicateDTO';
import { Establishment } from 'services/auth/getEstablishments';
import checkParticipantCpf, {
  IndicationCadidate,
  ValidationMessage,
} from 'services/participant-indication/checkParticipantCpf';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import getReportSubsidiary from 'services/storage/getReportSubsidiary';
import getschemaValidations from './getSchemaValidations';
import ImportFileForm from './ImportFile';

import {
  Container,
  Input,
  BoxPhone,
  Button,
  PanelIndication,
  InputLabel,
  RadioContainer,
  RadioGroup,
} from './styles';

export interface FormData {
  subsidiary_select: Option | null;
  role_select: Option | null;
  cpf: string;
  email: string;
  name: string;
  focal_point_admin: boolean | null;
  area_code: string;
  cell_phone: string;
  establishment_id: number;
}

interface Props {
  saveIndication(
    data: ICreateParticipantIndicateDTO | IEditParticipantIndicateDTO,
  ): Promise<boolean> | boolean;
  editing?: boolean;
  indicationData?: FormData;
  establishment: Establishment;
}

type Type = 'individual' | 'multiple';

const excludedRoles = ['Produtor'];

const Form: React.FC<Props> = ({
  saveIndication,
  editing = false,
  indicationData,
  establishment,
}) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<Type>('individual');
  const { addToast } = useToast();
  const { participant } = useAuth();

  const [cpfValue, setCPFValue] = useState(''); // Carrega valor do campo CPF
  const inputRole = 'secondary';

  const schema = getschemaValidations();

  const methods = useForm<FormData>({
    validationSchema: schema,
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
  });

  const { handleSubmit, reset, setValue } = methods;
  const [subsdiary, setSubsdiary] = useState('');
  const [isFocalPointAdmin, setIsFocalPointAdmin] = useState<boolean | null>(false);

  const checkCpf = useCallback(async (): Promise<void> => {
    if (!cpfValue.trim()) return;

    const validation = await checkParticipantCpf(cpfValue);

    if ((validation as IndicationCadidate).name) {
      const { name, email, areaCode, phone } = validation as IndicationCadidate;
      setValue('name', name);
      setValue('area_code', areaCode);
      setValue('cell_phone', phone);
      setValue('email', email);

      return;
    }

    if (!(validation as ValidationMessage).canIndicate) {
      const { message } = validation as ValidationMessage;
      addToast({ title: message, type: 'error' });
    }
  }, [addToast, cpfValue, setValue]);

  useEffect(() => {
    reset(indicationData || { subsidiary_select: null, role_select: null });
  }, [indicationData, reset]);

  useEffect(() => {
    if (editing) {
      setType('individual');
    }
  }, [editing]);

  useEffect(() => {
    getReportSubsidiary(establishment.id).then(data => {
      setSubsdiary(data);
    });
  }, [establishment]);

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    if (
      await saveIndication({
        role_id: parseInt(data.role_select?.value || '0', 0),
        cpf: data.cpf,
        email: data.email,
        name: data.name,
        area_code: data.area_code,
        cell_phone: data.cell_phone,
        establishment_id: establishment.id,
        subsidiary_id: parseInt(data.subsidiary_select?.value || '0', 0),
        focal_point_admin: isFocalPointAdmin,
      })
    ) {
      reset();
      setCPFValue('');
    }
    setLoading(false);
  });

  let styleType = '';
  if (participant.establishment.type_name === 'Terra Brasil') {
    styleType = 'terra';
  }

  return (
    <Container>
      {!editing && (
        <PanelIndication styleType={styleType}>
          <button
            type="button"
            className={type === 'individual' ? '_selected' : ''}
            onClick={() => setType('individual')}
          >
            Individual
          </button>
          <button
            type="button"
            className={type === 'multiple' ? '_selected' : ''}
            onClick={() => setType('multiple')}
          >
            Em Lote
          </button>
        </PanelIndication>
      )}
      {editing && <h3>Editando indicação</h3>}
      {type === 'multiple' ? (
        <ImportFileForm urlListSubsdiary={subsdiary} />
      ) : (
        <FormContext {...methods}>
          <form onSubmit={onSubmit}>
            <InputLabel>
              <FilialSelect
                className="filial"
                name="subsidiary_select"
                inputRole="secondary"
                establishmentId={establishment.id}
              />
              <RolesSelect
                name="role_select"
                inputRole="secondary"
                excludedRoles={excludedRoles}
              />
            </InputLabel>
            <Input
              name="cpf"
              label="CPF*"
              numbersOnly
              pattern="XXX.XXX.XXX-XX"
              inputRole={inputRole}
              onChange={e => setCPFValue(e.target.value)}
              onBlur={checkCpf}
            />
            <Input name="name" label="Nome completo*" inputRole={inputRole} />
            <BoxPhone>
              <Input
                name="area_code"
                numbersOnly
                pattern="(XX)"
                label="DDD"
                inputRole={inputRole}
              />
              <Input
                name="cell_phone"
                numbersOnly
                label="Celular"
                pattern="X XXXX-XXXX"
                inputRole={inputRole}
              />
            </BoxPhone>
            <Input name="email" label="E-mail*" inputRole={inputRole} />
            <RadioContainer>
              <span>Participante é FOCAL ADM?</span>
              <RadioGroup>
                <label>
                  <input
                    type="radio"
                    name="focalPointAdmin"
                    value="true"
                    checked={isFocalPointAdmin === true}
                    onChange={() => setIsFocalPointAdmin(true)}
                  />
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="focalPointAdmin"
                    value="false"
                    checked={isFocalPointAdmin === false}
                    onChange={() => setIsFocalPointAdmin(false)}
                  />
                  Não
                </label>
              </RadioGroup>
            </RadioContainer>
            <Button type="submit" buttonRole="primary" loading={loading}>
              Salvar
            </Button>
          </form>
        </FormContext>
      )}
    </Container>
  );
};

export default Form;
