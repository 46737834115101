import { Button as DefaultButton } from 'components/shared';
import DefaultModal from 'components/shared/Modal';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: #dcdcdc;
`;

interface ButtonProps {
  disabled: boolean;
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  position: relative;
  color: #fff;
  border-radius: 10px;
  background: #e63027;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  appearance: none;
  border: 0;
  width: 300px;
  max-width: 100%;
  padding: 10px 12px;
  text-transform: uppercase;
  font-size: 16px;
  z-index: 10;
  line-height: 1;
  font-family: ${({ theme }) => theme.font.fontFamily.condensed};

  @media (min-width: 1024px) {
    font-size: 22px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

export const Input = styled.input`
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 4px;
    max-width: 180px;
  }
`;

export const Modal = styled(DefaultModal)`
  background-color: #f0f0f0;
  ._modalContainer {
    width: 100%;
    max-width: 920px;
    margin: 0;
    padding: 0;
    max-height: 130vh;
    color: #fff;
    display: grid;

    .barCode {
      display: flex;
      max-width: 250px;
      position: relative;

      .barCodeImg {
        width: 300px;
        position: absolute;
        margin-top: 102px;
        margin-left: 420px;
      }
    }

    .alert--complete-register {
      padding: 40px 0;
      background-color: #3b302a;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        text-align: center;

        h3 {
          font-family: ${({ theme }) => theme.font.fontFamily.bold};
          font-size: 25px;
          margin-bottom: 15px;
        }

        .desc {
          max-width: 450px;
          margin: 0 auto;

          p,
          span {
            font-size: 18px;
          }

          p {
            margin-bottom: 15px;
          }
        }

        button {
          all: unset;
          background-color: #fff;
          color: #3b302a;
          cursor: pointer;
          margin-top: 25px;
          padding: 10px 15px;
          font-family: ${({ theme }) => theme.font.fontFamily.bold};
          border-radius: 10px;
        }
      }
    }

    .lineBarCode {
      position: absolute;
      width: 360px;
      height: 40px;
      border-bottom: 1.5px solid #000;
      border-right: 1.5px solid #000;
      background-color: transparent;
      transform: skew(-45deg);
      margin-top: 325px;
      margin-left: 64px;
    }

    @media (max-width: 768px) {
      .barCode {
        display: none;
      }
      .barCodeImg {
        display: none;
      }
      .lineBarCode {
        display: none;
      }
      ._modalContainer {
        max-width: 240px;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  width: 80%;
  max-height: 380px;
  padding: 0 45px;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  color: #020202;
  align-items: left;
  justify-content: center;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 22px;

  h3 {
    margin-top: 20px;
    font-size: 1.2em;
  }

  p {
    margin-top: 18px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
    text-transform: none;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
  }

  input {
    margin-top: 8px;
    height: 2.3rem;
    width: 68%;
    font-size: 0.9rem;
    border: 0.2px solid rgba(0, 0, 0, 0.16);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 12px;
    }
  }
`;

export const Title = styled.h3`
  margin-top: 30px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 1.6em;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1em;
  }
`;

export const Subtitle = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 12px;

  p {
    margin-top: 1em;
    text-align: left;
  }

  @media (max-width: 768px) {
    p {
      font-size: 11px;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const DoneButtonContainer = styled.div`
  width: 32%;
  display: flex;
  justify-content: left;

  button {
    width: 100px;
    border: none;
    background-color: #67554e;
    height: 2.3rem;
    font-size: 0.6rem;
    color: ${({ theme }) => theme.font.color.tertiary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
  button:hover {
    filter: grayscale(50%);
    transition: 0.5s;
  }
`;

export const DownloadButton = styled(DefaultButton)`
  border-radius: 0px !important;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 25px;
  max-width: 260px;
  align-self: center;
  height: 44px;
  background-color: #e63027;
  button:hover {
    filter: grayscale(50%);
    transition: 0.5s;
  }

  @media (max-width: 768px) {
    max-width: 180px;
    font-size: 12px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  gap: 16px; /* Espaçamento entre os grids */
  padding: 16px; /* Espaçamento interno opcional */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Em telas menores, 1 coluna */
  }
`;

export const GridItem = styled.div`
  background-color: #efefef;
  padding: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.font.fontFamily.condensed};  
  
  p {
    font-size: 12px;
    margin-top: auto; /* Empurra o texto para o final */
    margin-bottom: 0; /* Para remover espaçamento extra, se necessário */
    color: #333;
  }

  input {
    height: 2.3rem;
    width: 68%;
    font-size: 0.9rem;
    border: 0.2px solid rgba(0, 0, 0, 0.16);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  }
`;

export const GridItemTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.condensed};
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
  text-transform: uppercase;
  text-weight: bold;
`;
