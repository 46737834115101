import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  width: 500px;

  @media screen and (max-width: 720px) {
    ._modalContainer {
      width: 98%;
      padding-right: 0;
    }
  }
`;
export const Modal = styled(DefaultModal)`
  @media screen and (max-width: 720px) {
    ._modalContainer {
      padding-right: 0;
    }
  }
`;
export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.secondary};
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px; /* Espaço abaixo do título */
  @media (min-width: 768px) {
    font-size: 1.5em;
  }
`;
export const Input = styled.input`
  width: 70%;
  max-width: 300px; /* Largura máxima do input */
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 16px; /* Espaço entre o input e o botão */
  text-align: center;
  @media (max-width: 768px) {
    max-width: 180px; /* Ajuste para telas menores */
  }
`;
export const DoneButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza o botão horizontalmente */
  width: 100%;
  max-width: 300px; /* Largura máxima do contêiner do botão */
  button {
    background-color: #e63027;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    transition: background-color 0.3s;
    &:hover {
      background-color: #c3201f;
    }
  }
`;
export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  padding: 5px;
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: black;
    }
  }
`;

export const SubTitle = styled.p`
  color: #000;
  margin-bottom: 15px;
`;

export const TokenSendingNumber = styled.p`
  color: #3680c3;
  margin-bottom: 15px;
`;