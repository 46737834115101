import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { SafraSelect } from 'components/PointManagement/SafraSelect';
import { useAuth } from 'context/AuthContext';
import { KAM, CRM, GRM, RTC, RTCPOG } from 'config/constants';
import TeamIcon from 'assets/images/point-management/icons/team-icon.svg';
import ChannelIcon from 'assets/images/point-management/icons/channel-icon.svg';

import { EstablishmentSelection } from 'components/PointManagement/EstablishmentSelection';
import { Container, HeadWrapper, TabsWrapper, TabContainer } from './styles';
import { ChannelTab } from './channel-tab';
import { TeamPointsTab } from './team-tab';
import { usePoints } from '../use-points';

const Tabs = {
  'tab-canal': ChannelTab,
  'tab-equipe': TeamPointsTab,
};

export const PointDistributionPage = () => {
  const [tab, setTab] = useState<'tab-canal' | 'tab-equipe'>('tab-canal');
  const { selectedCampaign } = usePoints();
  const [valueCampaign, setValueCampaign] = useState(0);
  const {
    participant: { profile_value },
  } = useAuth();

  const ComponentTab = Tabs[tab];
  const showEstablishmentSelect = [KAM, CRM, GRM, RTC, RTCPOG].includes(
    profile_value,
  );

  useEffect(() => {
    setValueCampaign(selectedCampaign);
  }, [selectedCampaign]);

  return (
    <Container>
      <HeadWrapper>
        <div className="wrapper">
          <strong>Distribuidor de pontos</strong>

          <div className="fields">
            <SafraSelect />

            <EstablishmentSelection showSelection={showEstablishmentSelect} valueCampaign={valueCampaign} filter={0}/>
          </div>
        </div>
      </HeadWrapper>
      <div className="wrapper">
        <TabsWrapper>
          <strong className="title">Vamos distribuir</strong>
          <TabContainer>
            <button
              type="button"
              className="tab"
              data-selected={tab === 'tab-canal'}
              onClick={() => setTab('tab-canal')}
            >
              <ReactSVG src={ChannelIcon} />
              <strong>Premiação do Canal</strong>
            </button>
            <button
              type="button"
              className="tab"
              data-selected={tab === 'tab-equipe'}
              onClick={() => setTab('tab-equipe')}
            >
              <ReactSVG src={TeamIcon} />
              <strong>Premiação de Equipe</strong>
            </button>
          </TabContainer>
        </TabsWrapper>

        <ComponentTab />
      </div>
    </Container>
  );
};
