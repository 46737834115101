import React, { useEffect } from 'react';
import { CampaignStatus } from 'services/campaigns-manager/interfaces/Campaign';
import { useLocation } from 'react-router-dom';
import { ApproverApi } from 'services/campaigns-manager/interfaces/CampaignApi';
import { Container, NavigationItem } from './styles';

export type Tab = 'CUSTOMER_GROUP' | 'INFO_AND_RULES' | 'AWARDS' | 'APPROVAL';

interface Props {
  activeTab: Tab;
  setActiveTab(tab: Tab): void;
  isApproveProfile?: boolean;
  statusCampaign: CampaignStatus;
  approvers: ApproverApi[];
}

const FormTabs: React.FC<Props> = ({ activeTab, setActiveTab, approvers }) => {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const preSelect = params.get('pre_select');

    if (preSelect) {
      switch (preSelect) {
        case 'aprovacao':
          setActiveTab('APPROVAL');
          break;
        default:
          break;
      }
    }
  }, [search, setActiveTab]);

  return (
    <Container>
      <NavigationItem
        selected={activeTab === 'CUSTOMER_GROUP'}
        onClick={() => setActiveTab('CUSTOMER_GROUP')}
      >
        GRUPO DE CLIENTE <br />E METAS POR PRODUTOS
      </NavigationItem>
      <NavigationItem
        selected={activeTab === 'INFO_AND_RULES'}
        onClick={() => setActiveTab('INFO_AND_RULES')}
      >
        INFORMAÇÕES E REGRAS
      </NavigationItem>
      <NavigationItem
        selected={activeTab === 'AWARDS'}
        onClick={() => setActiveTab('AWARDS')}
      >
        PREMIAÇÃO
      </NavigationItem>
      {approvers.length > 0 && (
        <NavigationItem
          selected={activeTab === 'APPROVAL'}
          onClick={() => setActiveTab('APPROVAL')}
        >
          APROVAÇÃO
        </NavigationItem>
      )}
    </Container>
  );
};

export default FormTabs;
