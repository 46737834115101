import { pluginApi } from 'services/api';

interface Response {
  id: number;
  client_group: string;
}

export default async (): Promise<Response[]> => {
  const { data } = await pluginApi.get<Response[]>(
    '/participants/register/client-groups',
  );

  return data;
};
