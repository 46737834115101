import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { isPromise } from 'util/is-promise';
import { ActionsModalContainer, ModalContent } from './styles';

const ModalTitle = withStyles({
  root: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})(DialogTitle);

type Props = {
  isOpen: boolean;
  title: string;
  message: string | React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void | Promise<void>;
};

export const ConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  message,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (isPromise(onConfirm)) {
      setLoading(true);
      await onConfirm();
      setLoading(false);
      return;
    }

    onConfirm();
  };

  return (
    <Dialog open={isOpen}>
      <ModalTitle>{title}</ModalTitle>
      <DialogContent dividers>
        <ModalContent>
          <span>{message}</span>
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <ActionsModalContainer>
          <MaterialButton disabled={loading} onClick={onCancel}>
            Cancelar
          </MaterialButton>
          <MaterialButton disabled={loading} onClick={handleConfirm}>
            Confirmar
          </MaterialButton>
        </ActionsModalContainer>
      </DialogActions>
    </Dialog>
  );
};
