import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;

  .select-client {
    width: 300px;
  }
  ._inputContainer {
    height: 40px;
    max-width: 269px;
    margin: 0;
  }

  > button {
    border-radius: 12px;
    padding: 10px 18px;
    position: relative;
    background: #e63027;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    text-decoration: none;
    width: 100%;
    max-width: 150px;
    height: 40px;
    margin: 0;
  }
`;
