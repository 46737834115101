import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 572px;
    max-height: 330px;
    min-height: 330px;
    overflow-y: hidden;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 10px -10px;

  > button {
    border: none;
    background: transparent;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 45px;
  align-items: start;

  h1 {
    font-size: 21px;
    color: ${({ theme }) => theme.font.color.primary};
  }

  h2 {
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 2em;
  }
`;

export const ContentsButtons = styled.div`
  display: flex;
  margin-top: 2em;
  justify-content: center;

  button {
    border: none;
    border-radius: 4px;
    margin: 10px;
    width: 200px;
    height: 45px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  .register {
    color: #fff;
    background-color: ${({ theme }) => theme.font.color.primary};
  }

  .cancel {
    border: 1px solid ${({ theme }) => theme.font.color.primary};
    background-color: transparent;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Button = styled.button``;
