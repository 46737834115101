import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  --wheel-size: 380px;
  padding: 0 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  @media screen and (max-width: 800px) {
    --wheel-size: 300px;
  }
`;

export const WheelWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 630px) {
    flex-direction: column;
  }

  .wheel-details {
    &__container {
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }

    &__desc {
      height: 300px;
      padding: 30px 115px 30px 30px;
      overflow: hidden;
      border: 3px solid #ffffff;
      border-top-left-radius: 22px;
      border-bottom-left-radius: 22px;
      background: transparent linear-gradient(180deg, #95201a 0%, #e63027 100%)
        0% 0% no-repeat padding-box;

      h1 {
        font-size: 26px;
        margin-bottom: 10px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      p {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 10px;
      }

      .extract-link {
        font-size: 20px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
        outline: none;
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
      }

      @media screen and (max-width: 800px) {
        height: 250px;
        min-height: unset;

        h1 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
        }
      }

      @media screen and (max-width: 630px) {
        max-height: calc(var(--wheel-size) + 30px);
        min-height: unset;
        height: auto;
        max-width: var(--wheel-size);
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        text-align: center;
        border-radius: 22px;

        h1 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }

        .extract-link {
          font-size: 14px;
        }
      }
    }
  }
`;

export const WheelContainer = styled.div`
  height: var(--wheel-size);
  width: var(--wheel-size);
  border-radius: 50%;
  position: relative;

  img.arco {
    width: calc(var(--wheel-size) + 20px);
    height: calc(var(--wheel-size) + 20px);
    pointer-events: none;
    position: absolute;
    z-index: 99;
    top: 0;
    right: 10px;
    left: -10px;
    bottom: 0;
    margin: auto auto;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
  }

  img.wheel-indicator {
    pointer-events: none;
    position: absolute;
    z-index: 99;
    height: auto;
    max-width: 70px;
    right: 0;
    left: 0;
    top: -10px;
    margin: 0 auto;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));

    @media screen and (max-width: 800px) {
      max-width: 60px;
    }
  }

  img.wheel-base {
    position: absolute;
    z-index: 0;
    max-width: 200px;
    right: 0;
    left: 0;
    bottom: -50px;
    margin: 0 auto;
    pointer-events: none;

    @media screen and (max-width: 800px) {
      max-width: 150px;
      bottom: -40px;
    }
  }
`;

export const WheelButton = styled.div<{ disabled: boolean; loading: boolean }>`
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 97;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;

  @media screen and (max-width: 800px) {
    max-width: 85px;
    max-height: 85px;
  }

  img {
    /* filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5)); */
    transition: filter 250ms ease-in-out;
    width: 100%;
    height: 100%;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .btn-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    color: #a3231c;
    transition: opacity 250ms ease-in-out;
    border: 0px solid transparent;

    span {
      font-size: 22px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      margin-bottom: 3px;
    }

    small {
      font-size: 14px;

      strong {
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }
    }

    @media screen and (max-width: 800px) {
      span {
        font-size: 18px;
      }

      small {
        font-size: 12px;
      }
    }
  }

  ${p =>
    p.loading &&
    css`
      .btn-label {
        visibility: hidden;
      }
    `}

  ${p =>
    p.disabled &&
    css`
      // Disabled only styles
      cursor: not-allowed;

      .loader {
        opacity: 1;
      }

      .btn-label {
        opacity: 0.4;
      }
    `}

  ${p =>
    !p.disabled &&
    css`
      // Enabled only styles
      cursor: pointer;

      &:active {
        border-width: 2px;
      }

      &:hover {
        img {
          filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.6));
        }

        .btn-label {
          opacity: 0.9;
        }
      }
    `}
`;
