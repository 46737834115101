import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';

import { Alert, Avatar, Button, Input, PasswordInput } from 'components/shared';
import PasswordHelp from 'components/shared/PasswordHelp';
import { PROFILES } from 'config/constants';
import { useToast } from 'context/ToastContext';
import { PrimeiroAcessoDTO } from 'pages/primeiro-acesso';
import { useHistory } from 'react-router-dom';
import { Regulation } from 'services/auth/interfaces/Participant';
import getAllDataRegulations from 'services/register/regulation/getAllDataRegulations';
import saveParticipant from 'services/register/saveParticipant';
import { Form, FormField, FormFieldGroup, Info } from '../styles';
import { validationSchema } from './validation';

type FormData = {
  name: string;
  nickname: string;
  email: string;
  cpf: string;
  regulation: boolean;
  avatar: string;
  ddd: string;
  phone: string;
  policy_expose: string;
  password: string;
  confirm_password: string;
};

type Props = {
  initialData: PrimeiroAcessoDTO;
};

export const FmcRegisterForm: React.FC<Props> = ({ initialData }) => {
  const formMethods = useForm<FormData>({
    validationSchema,
    defaultValues: {
      avatar: initialData.participant?.picture,
      email: initialData.participant?.email,
      name: initialData.participant?.name,
      nickname: initialData.participant?.nick_name,
      cpf: initialData.cpf || initialData.participant?.cpf,
      ddd: initialData.participant?.area_code,
      phone: initialData.participant?.cell_phone,
      regulation: false,
    },
  });
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = formMethods;

  const { addToast } = useToast();
  const history = useHistory();

  const onFormSubmit = handleSubmit(async (data: FormData) => {
    try {
      const regulation = await getAllDataRegulations(
        PROFILES.fmc,
        initialData.participant?.id,
      );
      const regulationAcceptPayload = regulation.map(
        reg =>
          ({
            version: String(reg.version),
            regulation_id: String(reg.id),
            policyExposed: data.policy_expose === '1' ? 'yes' : 'no',
          } as Regulation),
      );

      await saveParticipant({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...initialData.participant!,
        profile: PROFILES.fmc,
        name: data.name,
        nick_name: data.nickname,
        cpf: data.cpf,
        email: data.email,
        area_code: data.ddd,
        cell_phone: data.phone,
        password: data.password,
        password_confirmation: data.confirm_password,
        picture: data.avatar,
        regulations_accepted: regulationAcceptPayload,
      });

      addToast({
        title:
          'Cadastro finalizado com sucesso, faça seu login para acessar a plataforma.',
        type: 'success',
      });

      setTimeout(() => {
        history.push('/', null);
      }, 3000);
    } catch (error) {
      addToast({
        type: 'error',
        title:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error as any).response?.data?.message ||
          'Ocorreu um erro ao tentar finalizar seu cadastro.',
      });
    }
  });

  const hasParticipantData = !!initialData.participant;

  useEffect(() => {
    /** Scrolla para o topo da página caso o usuário não tenha aceito os regulamentos */
    if (errors.regulation || errors.policy_expose) {
      const rootElement = document.getElementById('root');

      rootElement?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errors]);

  return (
    <FormContext {...formMethods}>
      <Form onSubmit={onFormSubmit}>
        {(errors.regulation || errors.policy_expose) && (
          <Alert variation="error">
            {errors.regulation?.message && (
              <div>{errors.regulation.message}</div>
            )}
            {errors.policy_expose?.message && (
              <div>{errors.policy_expose.message}</div>
            )}
          </Alert>
        )}
        <FormField className="checkbox">
          <Controller as={Checkbox} name="regulation" control={control} />
          <a
            href={`/regulamentos?role=fmc&userId=${initialData?.participant?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Eu concordo com os{' '}
            <span className="underline">Regulamentos e Termos</span> do Programa
            JUNTOS FMC.
          </a>
          <br />
        </FormField>

        <FormField className="policy-exposed-alert">
          <span>Você se considera uma pessoa politicamente exposta?</span>
          <p>
            São consideradas pessoas politicamente expostas: o agente público ou
            político eleito, candidato a qualquer cargo político ou de agente
            político, bem como seus parentes até segundo grau ou pessoas com
            relacionamento próximo, durante o desempenho ou que tenham
            desempenhado tal função nos últimos 5 anos. Por agente público
            entende-se a pessoa física ou jurídica que presta qualquer serviço
            ao estado ou às pessoas jurídicas da administração pública indireta,
            por exemplo, servidores públicos, agentes políticos, militares ou
            ainda particulares em colaboração com o poder público, ou aqueles
            equiparados ao agente público no exercício de cargo ou função.
          </p>

          <Controller
            name="policy_expose"
            as={props => (
              <RadioGroup {...props}>
                <FormFieldGroup>
                  <div className="policy_expose--option">
                    <Radio value="1" />
                    <span>Sim</span>
                  </div>
                  <div className="policy_expose--option">
                    <Radio value="0" />
                    <span>Não</span>
                  </div>
                </FormFieldGroup>
              </RadioGroup>
            )}
          />
        </FormField>
        <div className="divider" />
        <div className="form--fields">
          <FormField>
            <Avatar name="avatar" />
          </FormField>
          {hasParticipantData && (
            <>
              <FormField>
                <Info>
                  <span>Departamento</span>
                  <strong>
                    {initialData.participant?.department || 'Não informado'}
                  </strong>
                </Info>
              </FormField>
              <FormField>
                <Info>
                  <span>Cargo</span>
                  <strong>{initialData.participant?.role.name || '---'}</strong>
                </Info>
              </FormField>
              <FormField>
                <Info>
                  <span>UPN</span>
                  <strong>{initialData.participant?.upn || '---'}</strong>
                </Info>
              </FormField>
            </>
          )}
          <FormField>
            <Input name="name" label="Nome completo*" inputRole="secondary" />
          </FormField>
          <FormField>
            <Input
              name="nickname"
              label="Como gostaria de ser chamado(a)*"
              inputRole="secondary"
            />
          </FormField>
          <FormField>
            <Input name="email" label="Email*" inputRole="secondary" />
          </FormField>
          <FormField>
            <Input
              name="cpf"
              label="CPF*"
              inputRole="secondary"
              pattern="XXX.XXX.XXX-XX"
              numbersOnly
            />
          </FormField>
          <FormFieldGroup>
            <div style={{ maxWidth: '90px' }}>
              <Input
                name="ddd"
                numbersOnly
                pattern="(XX)"
                label="DDD*"
                inputRole="secondary"
              />
            </div>
            <Input
              name="phone"
              numbersOnly
              label="Celular*"
              pattern="X XXXX-XXXX"
              inputRole="secondary"
            />
          </FormFieldGroup>
        </div>

        <div className="divider" />
        <h1>Segurança</h1>

        <div className="form--fields">
          <FormField>
            <PasswordInput
              name="password"
              help={PasswordHelp}
              label="Senha"
              inputRole="secondary"
            />
          </FormField>
          <FormField>
            <PasswordInput
              name="confirm_password"
              label="Confirmar senha"
              inputRole="secondary"
            />
          </FormField>
        </div>

        <div className="form--action">
          <Button
            buttonRole="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            CONFIRMAR
          </Button>
        </div>
      </Form>
    </FormContext>
  );
};
