import { FONTS } from 'styles/font/globals';

export default {
  color: {
    primary: '#004E27',
    secondary: '#000',
    tertiary: '#fff',
    quartenary: '#808285',
    quintenary: '#004E27',
    senary: '#004E27',
    highlights: '#000000',
  },
  fontFamily: { ...FONTS },
};
