import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;

  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: ${({ theme }) => theme.font.color.secondary};
  }

  div {
    margin-top: 20px;
    width: 100%;
    display: flex;
    //border-styleflex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      max-width: 200px;
    }
  }
`;

export const AnswerLabel = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  margin-bottom: 5px;
  max-width: 160px;
`;
