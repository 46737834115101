import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputOtions, OptionsTitle, InputText } from './styles';

interface Props {
  name: string;
  title: string;
  extraInfo?: string;
  disabled?: boolean;
}

const CustomInput: React.FC<Props> = ({
  name,
  title,
  extraInfo,
  disabled = false,
}) => {
  const { setValue, watch, register } = useFormContext();

  const isChecked = watch(name) === '1';

  useEffect(() => {
    register(name);
    register('harvest.outras_quais');
  }, [register, name]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.checked ? '1' : '0');
    if (!e.target.checked && name === 'harvest.outras') {
      setValue('harvest.outras_quais', '');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('harvest.outras_quais', e.target.value);
  };

  return (
    <>
      <InputOtions
        type="checkbox"
        id={title}
        name={name}
        checked={isChecked}
        onChange={handleCheckboxChange}
        disabled={disabled}
      />
      <OptionsTitle htmlFor={title}>{title}</OptionsTitle>
      {extraInfo && isChecked && (
        <InputText
          type="text"
          id="Outros"
          placeholder="Quais?"
          name="harvest.outras_quais"
          value={watch('harvest.outras_quais') || ''}
          onChange={handleInputChange}
        />
      )}
    </>
  );
};

export default CustomInput;
