import * as Yup from 'yup';

const mandatoryReason = 'Campo obrigatório';
export default Yup.object().shape({
  reason: Yup.object()
    .shape({
      value: Yup.string().required(mandatoryReason),
    })
    .typeError(mandatoryReason)
    .required(mandatoryReason),
});
