import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { dismissRouletteModal } from 'state/modules/home/actions';
import { Extract } from 'components/Roulette/Extract';

import { Container } from './styles';

export const RouletteExtract: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    /** Fecha o modal da roleta caso esteja aberto */
    dispatch(dismissRouletteModal());
  }, [dispatch]);

  return (
    <Container>
      <h1>Extrato Roleta Premiada</h1>

      {/* Banner desabilitado temporariamente */}
      {/* <div className="banner">
        <img
          src={BannerDesk}
          alt="Banner roleta premiada"
          className="banner-desk"
        />
        <img
          src={BannerMobile}
          alt="Banner roleta premiada"
          className="banner-mobile"
        />
      </div> */}

      <Extract />
    </Container>
  );
};
