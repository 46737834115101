import { vendavallApi } from 'services/api';

interface Subject {
  id: string;
  title: string;
}

interface ApiResponse {
  data: Subject[];
}

interface Option {
  value: string;
  title: string;
}

const getPublicSubjects = async (): Promise<Subject[]> => {
  try {
    const {
      data: { data },
    } = await vendavallApi.get<ApiResponse>(`contacts/public-subjects`);
    return data || [];
  } catch (e) {
    return [];
  }
};

const getPublicSubjectsForSelect = async (): Promise<Option[]> => {
  const { data } = await vendavallApi.get('contacts/public-subjects');

  const transformedData = Object.entries(data.data);

  const subjectsMap: Option[] = [];
  // refatorar isso
  transformedData.forEach(item => {
    const [value, title] = item;
    subjectsMap.push({
      value,
      title: title as string,
    });
  });

  return subjectsMap.filter(item => parseInt(item.value, 10) !== 21);
};

export { getPublicSubjects, getPublicSubjectsForSelect };
