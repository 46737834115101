import styled from 'styled-components';
import BaseTextArea from 'components/shared/TextArea';

export const Container = styled.div`
  min-width: 400px;

  strong {
    font-size: 16px;
  }
`;

export const ActionsModalContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  place-items: center;
  padding: 5px 15px;

  button {
    margin: 0;
    max-width: 200px;
    height: auto;
    padding: 9px 25px;
    font-size: 14px;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};

    &:disabled {
      opacity: 0.5;
    }
  }

  // Cancel btn
  button:first-child {
    color: ${({ theme }) => theme.font.color.primary};
  }

  // Confirm btn
  button:last-child {
    background-color: ${({ theme }) => theme.font.color.primary};
    color: #fff;
  }
`;

export const TextArea = styled(BaseTextArea)`
  margin-bottom: 10px;
  width: 100%;
  ._inputContainer {
    width: 100%;
    height: 80px;
  }

  span {
    margin-bottom: 10px;
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;
