import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Establishment } from 'state/modules/point-management/common/types';
import { PointDistributorData } from 'services/point-management/dtos/points-distribution';
import { getPointsDistribution } from 'services/point-management/points-distribution';
import { getPointsDistributionToApprove } from 'services/point-management/points-distribuition-to-approve';

type Context = {
  selectedCampaign: number; // id
  selectedEstablishment: Establishment | null;
  points: PointDistributorData;
  isFetching: boolean;
  isFetched: boolean;
  fetchError: boolean;
  refresh: () => void;
  refreshWaiting: () => void;
  selectCampaign: (id: number) => void;
  selectEstablishment: (est: Establishment) => void;
  validatingEstablishmentEmpty: boolean | any;
};

type Props = {
  fetchPointsToDistribute?: boolean;
  fetchPointsInAnalysis?: boolean;
  fetchTeamPointsInAnalysis?: boolean;
  fetchTeamPoints?: boolean;
};

const PointsContext = createContext<Context>({} as Context);

export const PointsProvider: React.FC<Props> = ({ children }) => {
  const [selectedCampaign, setCampaign] = useState(0);
  const [validatingEstablishment, setvalidatingEstablishment] = useState(false);
  const [
    selectedEstablishment,
    setEstablishment,
  ] = useState<Establishment | null>(null);
  const [points, setPoints] = useState<PointDistributorData | any>({
    approved: {},
    disapproved: {},
    waiting_approved: {},
    waiting_distribution: {},
    published: {},
  });
  const [fetchState, setFetchState] = useState<'loading' | 'error' | 'success'>(
    'loading',
  );

  const selectCampaign = useCallback((id: number) => setCampaign(id), []);

  const validatingEstablishmentEmpty = useCallback(() => {
    setvalidatingEstablishment(true);
  }, [setvalidatingEstablishment]);

  const selectEstablishment = useCallback(
    (establishment: Establishment) => setEstablishment(establishment),
    [],
  );

  const getPoints = useCallback(async () => {
    if (selectedEstablishment) {
      await getPointsDistributionToApprove({
        establishmentId:
          selectedEstablishment && Number(selectedEstablishment?.value),
        campaignId: selectedCampaign,
      });
    }
  }, [selectedEstablishment, selectedCampaign]);

  const fetchData = useCallback(async () => {
    setFetchState('loading');
    const response = await getPointsDistribution({
      campaignId: selectedCampaign,
      establishmentId: Number(selectedEstablishment?.value || 0),
    });

    if (response) {
      // Filtro temporário pra excluir registros que são diferentes do estabelecimento selecionado
      setPoints(response);
      setFetchState('success');
      return;
    }

    setFetchState('error');
  }, [selectedEstablishment, selectedCampaign]);

  useEffect(() => {
    if (!selectedEstablishment || selectedCampaign === 0) return;

    fetchData();
    getPoints();
  }, [selectedCampaign, selectedEstablishment, fetchData, getPoints]);

  useEffect(() => {
    if(validatingEstablishment){
      setFetchState('success');
    } 
  }, [validatingEstablishment]);

  return (
    <PointsContext.Provider
      value={{
        selectedCampaign,
        selectedEstablishment,
        selectCampaign,
        selectEstablishment,
        points,
        refreshWaiting: getPoints,
        refresh: fetchData,
        isFetching: fetchState === 'loading',
        isFetched: fetchState === 'success',
        fetchError: fetchState === 'error',
        validatingEstablishmentEmpty,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => useContext(PointsContext);
