import React from 'react';

import { Product } from 'services/strategic-plans/actions/interfaces';
import { MdClose } from 'react-icons/md';
import { Container } from './styles';

interface ItemProps {
  product: Product;
  removeClickHandler: (id: number) => void;
  canEdit?: boolean;
}

const Item: React.FC<ItemProps> = ({
  product: { id, name },
  removeClickHandler,
  canEdit = true,
}) => {
  return (
    <Container>
      <span key={id}>{name}</span>
      {canEdit && <MdClose onClick={() => removeClickHandler(id)} size={24} />}
    </Container>
  );
};

export default Item;
