import { pluginApi } from 'services/api';
import {
  RegulationPolicy,
  RegulationPolicyResponse,
} from './interfaces/IRegulation';

export default async (): Promise<RegulationPolicy> => {
  const { data } = await pluginApi.get<RegulationPolicyResponse>(
    `participants/regulations/checkPolicy`,
  );
  return {
    message: data.regulations.message,
    status: data.regulations.status,
  };
};
