import routeMap from 'routes/route-map';
import ProductsDevelopment from 'pages/StrategicPlans/ActionView';
import { StrategicPlansPage } from 'pages/StrategicPlans/page';
import { PlanDetailsScreen } from 'pages/StrategicPlans/screens/plan-details';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.strategicPlans,
    component: StrategicPlansPage,
    accessPage: 'Planos de Estratégia',
  },
  {
    path: `${routeMap.strategicPlans}/:id`,
    component: PlanDetailsScreen,
    accessPage: 'Gerenciamento de Ações de Estratégia',
  },
  {
    path: `${routeMap.strategicPlans}/:planId/acao`,
    component: ProductsDevelopment,
    accessPage: 'Cadastrar Ação',
  },
];

export default routes;
