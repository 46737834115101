/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Button } from 'components/shared';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';

import { Modal, Container, Content, Close } from './styles';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  text: string | Node;
  subitle?: string | Node;
  className?: string;
}

const ConfirmEditRegister: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  text,
  subitle,
  className,
}) => {
  const onClickHandler = useCallback(() => {
    window.location.href = '/campanhas-aceleradoras';
    onRequestClose();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type="primary"
      className={className}
      zIndex={100}
    >
      <Container>
        <Close>
          <button type="button" onClick={onRequestClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <Content>
          <h1>{text}</h1>
          <h2>{subitle}</h2>
          <Button type="button" buttonRole="primary" onClick={onClickHandler}>
            Fechar
          </Button>
        </Content>
      </Container>
    </Modal>
  );
};

export default ConfirmEditRegister;
