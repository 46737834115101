import { pluginApi } from 'services/api';
import { RouletteAction, RouletteActionType } from '../types';

type RouletteApiResponse = {
  roulette_id: number;
  spins_total: number;
  action_type: number;
  action_ref: string;
};

export type RouletteData = {
  id: number;
  user_spins: number;
  action_type: RouletteActionType;
  action: RouletteAction;
};

export async function getRoulettes(): Promise<RouletteData[]> {
  const roulettesResponse = await pluginApi.post<RouletteApiResponse[]>(
    '/roulettes/participant-roulettes',
  );

  if (roulettesResponse.data && roulettesResponse.data.length) {
    return roulettesResponse.data.map(roulette => ({
      id: roulette.roulette_id,
      action: Number(roulette.action_ref),
      action_type: Number(roulette.action_type),
      user_spins: roulette.spins_total,
    }));
  }

  return [];
}
