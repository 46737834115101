import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const Container = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 40px auto;
  h1 {
    font-size: 24px;
    color: ${({ theme }) => theme.font.color.primary};
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 15px;
  }
  hr {
    height: 1px;
    background-color: #808285;
    margin-bottom: 35px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  margin-bottom: 25px;
  h2 {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 24px;
    font-size: 21px;
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 5px;
  }
  p {
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 14px;
    font-size: 12px;
    color: #000;
    margin-bottom: 15px;
  }
  span {
    color: ${({ theme }) => theme.font.color.primary};
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const MiniBox = styled.div``;

export const ContentInfo = styled.div`
  img {
    width: 100%;
    max-width: 1004;
    height: auto;
    margin-bottom: 20px;
  }
  iframe {
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    margin-bottom: 30px;
  }
`;

export const Title = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.font.color.primary};
  line-height: 28px;
  margin-bottom: 20px;
`;

export const FormControlRadio = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 25px;
  }
`;

export const RadioGroup = styled.div`
  > div {
    margin-top: 15px;
    span {
      font-size: 16px;
      color: ${({ theme }) => theme.font.color.primary};
    }
    strong {
      margin-right: 30px;
      input {
        width: 15px;
        height: 15px;
      }
      input[type='radio']:checked {
        width: 12px;
        height: 12px;
        background: ${({ theme }) => theme.font.color.primary};
      }
    }
  }
`;

export const FormGrupSelect = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 25px;
  }
  input {
  }
  option,
  select {
    padding: 0 10px;
    width: 299px;
    height: 40px;
  }
`;

export const FormControlBanner = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 23px;
  }
  .banner {
    height: 139px;
    background: #efefef;
  }
`;

export const FormControlData = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  p {
    font-size: 14px;
    color: #000;
  }
`;

export const KeyboardDate = styled.div`
  margin-top: 30px;
  input {
    width: 174px;
    height: 40px;
    color: ${({ theme }) => theme.font.color.primary};
    padding: 0 10px;
  }
`;

export const FormControlHour = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 25px;
  }
`;

export const KeyboardTime = styled.div`
  margin-top: 30px;
  input {
    width: 174px;
    height: 40px;
    padding: 0 10px;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Button = styled.button`
  margin-top: 30px;
  width: 265px;
  height: 36px;
  margin: 0 auto;
  background: ${({ theme }) => theme.font.color.primary};
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: bold;
  margin-top: 40px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

export const Player = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
  position: absolute;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 25px;

  @media screen and (max-width: 680px) {
    height: 300px;
  }
`;
