import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;

  thead {
    background-color: #343a40;
    color: white;

    th {
      padding: 10px;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      padding: 10px;
      color: #333;
    }
  }
`;