import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactLoading from 'react-loading';

import { Option } from 'components/shared/Select';
import { getListParticipantChannels, getUfList } from 'services/participant-channels';
import NoContent from 'components/shared/Table/NoContent';
import { ParticipantChannelsData } from 'services/participant-channels/interface';
import ListParticipantChannels from 'components/ParticipantChannels';
import { useAuth } from 'context/AuthContext';
import LocationSelect from 'components/ParticipantChannels/Filter';
import { Container, Content, Filters, Title, IntegratorContainer, Loading } from './styles';
import CustomSelect from 'components/ParticipantChannels/CustomSelect';
import { ufToOption } from 'services/participant-channels/transformer';

const ParticipantChannels: React.FC = () => {
  const [selectedUf, setSelectedUf] = useState<Option | null>(null);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedClientGroup, setSelectedClientGroup] = useState<Option | null>(null);
  const [channelsData, setChannelsData] = useState<ParticipantChannelsData[]>([]);
  const [filteredChannels, setFilteredChannels] = useState<ParticipantChannelsData[]>([]);
  const [ufOptions, setUfOptions] = useState<Option[]>([]);
  const [integratorFilter, setIntegratorFilter] = useState<'todos' | 'sim' | 'nao'>('todos');
  const [loading, setLoading] = useState(false);

  const { participant } = useAuth();

  // Aplicar filtros iniciais com base no endereço do participante
  const applyInitialFilters = useCallback((channels: ParticipantChannelsData[], address?: { state_code?: string; city?: string }) => {
    let filtered = channels;

    if (address?.state_code) {
      setSelectedUf({ value: address.state_code, title: address.state_code });
      filtered = filtered.filter(channel => channel.uf === address.state_code);
    }

    if (address?.city) {
      setSelectedCity({ value: address.city, title: address.city });
      filtered = filtered.filter(channel => channel.city === address.city);
    }

    setFilteredChannels(filtered);
  }, []);

  // Atualizar a cidade depois que a UF for carregada
  useEffect(() => {
    if (selectedUf && participant?.address?.city) {
      const cityExists = channelsData.some(channel => channel.uf === selectedUf.title && channel.city === participant.address.city);
      if (cityExists) {
        setSelectedCity({ value: participant.address.city, title: participant.address.city });
      }
    }
  }, [selectedUf, channelsData, participant]);

  // Carregar lista de canais e UFs ao montar o componente
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [channels, ufList] = await Promise.all([
          getListParticipantChannels(),
          getUfList(),
        ]);

        setChannelsData(channels);
        setUfOptions(ufToOption(ufList));
        applyInitialFilters(channels, participant?.address);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [participant, applyInitialFilters]);

  // Atualizar a lista de canais quando o filtro de UF for alterado
  useEffect(() => {
    console.log("UF Selecionada:", selectedUf);

    if (selectedUf) {
      const fetchDataByUf = async () => {
        try {
          setLoading(true);
          const response = await getListParticipantChannels(selectedUf.title, null, null, null);
          setChannelsData(response); // Atualiza a lista de canais com base na UF
          setSelectedCity(null); // Limpar filtro de cidade
          setSelectedClientGroup(null); // Limpar filtro de grupo de clientes
          setIntegratorFilter('todos'); // Limpar filtro de integrador
        } catch (error) {
          console.error('Erro ao carregar dados da UF:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataByUf();
    }
  }, [selectedUf]);

  // Aplicar filtros sempre que os dados ou filtros mudarem
  useEffect(() => {
    let filtered = channelsData;

    if (selectedUf) {
      filtered = filtered.filter(channel => channel.uf === selectedUf.title);
    }

    if (selectedCity) {
      filtered = filtered.filter(channel => channel.city === selectedCity.title);
    }

    if (selectedClientGroup) {
      filtered = filtered.filter(channel => channel.client_group === selectedClientGroup.value);
    }

    if (integratorFilter === 'sim') {
      filtered = filtered.filter(channel => channel.integrator === true);
    } else if (integratorFilter === 'nao') {
      filtered = filtered.filter(channel => channel.integrator === false || channel.integrator === null);
    }

    setFilteredChannels(filtered);
  }, [channelsData, selectedUf, selectedCity, selectedClientGroup, integratorFilter]);

  // Criar lista única de grupos de clientes com base nos filtros atuais
  const uniqueClientGroups = useMemo(() => {
    let filtered = channelsData;

    if (selectedUf) {
      filtered = filtered.filter(channel => channel.uf === selectedUf.title);
    }

    if (selectedCity) {
      filtered = filtered.filter(channel => channel.city === selectedCity.title);
    }

    return Array.from(new Set(filtered.map(channel => channel.client_group)))
      .map(clientGroup => ({ value: clientGroup, title: clientGroup }));
  }, [channelsData, selectedUf, selectedCity]);

  // Criar lista única de cidades com base na UF selecionada
  const uniqueCities = useMemo(() => {
    if (!selectedUf) return [];
    return Array.from(new Set(channelsData
      .filter(channel => channel.uf === selectedUf.title)
      .map(channel => channel.city)))
      .map(city => ({ value: city, title: city }));
  }, [channelsData, selectedUf]);

  // Manipular mudança no filtro de integrador
  const handleIntegratorChange = (option: 'todos' | 'sim' | 'nao') => {
    setIntegratorFilter(option);
  };

  return (
    <Container>
      <Content>
        <Title>Canais Participantes</Title>
        <span>Selecione os filtros abaixo para ver o canal mais próximo de você.</span>
        <Filters>
          <LocationSelect
            className="location-select"
            ufValue={selectedUf}
            cityValue={selectedCity}
            setUfValue={setSelectedUf}
            setCityValue={setSelectedCity}
            ufOptions={ufOptions}
            cityOptions={uniqueCities}
            channelsData={channelsData}
          />
          <CustomSelect
            label="Canais"
            name="clientGroup"
            className="client-group-select"
            placeholder="Selecione o Canal"
            loadItems={() => uniqueClientGroups}
            value={selectedClientGroup}
            onChange={(selectedOption: Option | null) => setSelectedClientGroup(selectedOption)}
          />

          <IntegratorContainer>
            <label>Canais com envio automático NF:</label>
            <label>
              Todos
              <input
                type="checkbox"
                checked={integratorFilter === 'todos'}
                onChange={() => handleIntegratorChange('todos')}
              />
            </label>
            <label>
              Sim
              <input
                type="checkbox"
                checked={integratorFilter === 'sim'}
                onChange={() => handleIntegratorChange('sim')}
              />
            </label>
            <label>
              Não
              <input
                type="checkbox"
                checked={integratorFilter === 'nao'}
                onChange={() => handleIntegratorChange('nao')}
              />
            </label>
          </IntegratorContainer>
        </Filters>
      </Content>

      {loading ? (
        <Loading>
          <ReactLoading type="bars" height={24} width={24} color="#808285" />
        </Loading>
      ) : filteredChannels.length > 0 ? (
        <ListParticipantChannels channelsList={filteredChannels} />
      ) : (
        <NoContent colSpan={1} text="Nenhum resultado encontrado." />
      )}
    </Container>
  );
};

export default ParticipantChannels;
