import styled from 'styled-components';

export const Banner = styled.div`
  > img {
    width: 856px;
    height: 250px;
    background-position: 10% 0, center;
    background-size: cover;

    @media screen and (max-width: 720px) {
      width: 100%;
      background-position: 10% 0, center;
      background-size: cover;
    }
  }
`;

export const Container = styled.div`
  flex-direction: column;
  display: flex;

  > img {
    max-width: 991px;
    object-fit: cover;
  }

  > h1 {
    text-transform: capitalize;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-top: 22px;
    font-size: 25px;
  }

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    font-size: 16px;
    margin-top: 10px;
  }

  > p {
    color: ${({ theme }) => theme.font.color.secondary};
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    font-size: 14px;
    margin-top: 20px;
  }
`;
