export const FETCH_CAMPAIGNS_ACTION = '@fmc/goals/FETCH_CAMPAIGNS_ACTION';
export const FETCH_CAMPAIGNS_FAILURE = '@fmc/goals/FETCH_CAMPAIGNS_FAILURE';
export const FETCH_CAMPAIGNS_SUCCESS = '@fmc/goals/FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CURRENT_CAMPAIGN_ACTION =
  '@fmc/goals/FETCH_CURRENT_CAMPAIGN_ACTION';
export const FETCH_CURRENT_CAMPAIGN_SUCCESS =
  '@fmc/goals/FETCH_CURRENT_CAMPAIGN_SUCCESS';
export const FETCH_CURRENT_FAILURE =
  '@fmc/goals/FETCH_CURRENT_CAMPAIGN_FAILURE';
export const SET_SELECTED_CAMPAIGN = '@fmc/goals/SET_SELECTED_CAMPAIGN';

export const FETCH_BILLING_POG_ACTION = '@fmc/goals/FETCH_BILLING_POG_ACTION';
export const FETCH_BILLING_POG_FAILURE = '@fmc/goals/FETCH_BILLING_POG_FAILURE';
export const FETCH_BILLING_POG_SUCCESS = '@fmc/goals/FETCH_BILLING_POG_SUCCESS';

export const FETCH_HOME_BILLING_POG_ACTION =
  '@fmc/goals/FETCH_HOME_BILLING_POG_ACTION';
export const FETCH_HOME_BILLING_POG_FAILURE =
  '@fmc/goals/FETCH_HOME_BILLING_POG_FAILURE';
export const FETCH_HOME_BILLING_POG_SUCCESS =
  '@fmc/goals/FETCH_HOME_BILLING_POG_SUCCESS';

export const FETCH_BIOLOGICAL_ACTION = '@fmc/goals/FETCH_BIOLOGICAL_ACTION';
export const FETCH_BIOLOGICAL_FAILURE = '@fmc/goals/FETCH_BIOLOGICAL_FAILURE';
export const FETCH_BIOLOGICAL_SUCCESS = '@fmc/goals/FETCH_BIOLOGICAL_SUCCESS';

export const FETCH_POTENTIALIZERS_ACTION =
  '@fmc/goals/FETCH_POTENTIALIZERS_ACTION';
export const FETCH_POTENTIALIZERS_FAILURE =
  '@fmc/goals/FETCH_POTENTIALIZERS_FAILURE';
export const FETCH_POTENTIALIZERS_SUCCESS =
  '@fmc/goals/FETCH_POTENTIALIZERS_SUCCESS';

export const FETCH_INFOS_ACTION = '@fmc/goals/FETCH_INFOS_ACTION';
export const FETCH_INFOS_FAILURE = '@fmc/goals/FETCH_INFOS_FAILURE';
export const FETCH_INFOS_SUCCESS = '@fmc/goals/FETCH_INFOS_SUCCESS';

export const FETCH_TOP_PURCHASING_PRODUCTS_ACTION =
  '@fmc/goals/FETCH_TOP_PURCHASING_PRODUCTS_ACTION';
export const FETCH_TOP_PURCHASING_PRODUCTS_FAILURE =
  '@fmc/goals/FETCH_TOP_PURCHASING_PRODUCTS_FAILURE';
export const FETCH_TOP_PURCHASING_PRODUCTS_SUCCESS =
  '@fmc/goals/FETCH_TOP_PURCHASING_PRODUCTS_SUCCESS';

export const FETCH_TOP_SELLING_PRODUCTS_ACTION =
  '@fmc/goals/FETCH_TOP_SELLING_PRODUCTS_ACTION';
export const FETCH_TOP_SELLING_PRODUCTS_FAILURE =
  '@fmc/goals/FETCH_TOP_SELLING_PRODUCTS_FAILURE';
export const FETCH_TOP_SELLING_PRODUCTS_SUCCESS =
  '@fmc/goals/FETCH_TOP_SELLING_PRODUCTS_SUCCESS';

export enum Tabs {
  Performance = 'performance',
  Top10Products = 'top-10-products',
  Biological = 'biological',
}
