import { EstablishmentTypesMap } from 'config/constants';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import getChannels from 'services/cockpit/getChannels';
import { Channel } from 'services/cockpit/interfaces/channel';

interface CockpitContextData {
  indirectChannels: Channel[];
  terraChannels: Channel[];
}

const CockpitContext = createContext<CockpitContextData | undefined>(undefined);
export const CockpitProvider: React.FC = ({ children }) => {
  const [indirectChannels, setIndirectChannels] = useState<Channel[]>([]);
  const [terraChannels, setTerraChannels] = useState<Channel[]>([]);

  const getEstablishmentTypeName = useCallback(
    (establishmentTypeId: number) => {
      const establishmentName = EstablishmentTypesMap[establishmentTypeId];
      if (establishmentName) {
        return establishmentName;
      }

      return null;
    },
    [],
  );

  useEffect(() => {
    getChannels().then(response => {
      const terraBrasilChannels = response.filter(
        c =>
          getEstablishmentTypeName(c.establishment_type_id) === 'Terra Brasil',
      );
      const indirects = response.filter(
        c =>
          getEstablishmentTypeName(c.establishment_type_id) !== 'Terra Brasil',
      );

      setIndirectChannels(indirects);
      setTerraChannels(terraBrasilChannels);
    });
  }, [getEstablishmentTypeName]);

  return (
    <CockpitContext.Provider value={{ indirectChannels, terraChannels }}>
      {children}
    </CockpitContext.Provider>
  );
};

export const useCockpitContext = (): CockpitContextData => {
  const context = useContext(CockpitContext);
  if (!context) {
    throw new Error('useTerraContext must be used within a TerraProvider');
  }

  return context;
};
