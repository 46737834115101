import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { ActionsModalContainer, Container } from './styles';

const ModalTitle = withStyles({
  root: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})(DialogTitle);

type Props = {
  isOpen: boolean;
  pointsDistribution: any;
  setOpenComments: any;
  onConfirm?: (reason: string) => void;
};

export const CommentModel = ({
  isOpen,
  pointsDistribution,
  setOpenComments,
}: Props) => {
  const handleCloseModal = () => {
    setOpenComments(!isOpen);
  };
  const getComments = pointsDistribution?.approvals?.disapprovals?.comments;

  return (
    <Dialog open={isOpen}>
      <ModalTitle>Motivos da Reprovação</ModalTitle>
      <DialogContent dividers>
        <Container>
          {getComments?.slice(-1).map((x: any) => (
            <p>{x.comment}</p>
          ))}
        </Container>
      </DialogContent>
      <DialogActions>
        <ActionsModalContainer>
          <MaterialButton type="button" onClick={handleCloseModal}>
            Fechar
          </MaterialButton>
        </ActionsModalContainer>
      </DialogActions>
    </Dialog>
  );
};
