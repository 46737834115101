import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import DefaultButton from 'components/shared/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2c2b2b;
  min-height: 100%;
  padding: 10px 10px 10px;
  margin: 5px 10px 5px;
`;

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 720px;
    width: 100%;
    min-height: 250px;
  }
`;

export const Button = styled(DefaultButton)`
  width: 150px;
  height: 45px;
  background-color: #e63027;
  border-radius: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 20px 0 10px;
`;

export const Header = styled.h1`
  width: 100%;
  text-align: center;
  color: #821c0e;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 1.5em;
  margin-top: 30px;
`;

export const Text = styled.p`
  color: #2c2b2b;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 1em;
  text-align: center;
  padding: 5px 15px;
  width: 100%;
`;
