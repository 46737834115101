import React from "react"
import bells from "./bells.png"

const Bells = () => {
    return (
        <div>
            <img src={bells} alt="bells" />&nbsp;&nbsp;
            <img src={bells} alt="bells" />&nbsp;&nbsp;
            <img src={bells} alt="bells" />&nbsp;&nbsp;
            <img src={bells} alt="bells" />
        </div>
    )
}

export default Bells