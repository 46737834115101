import React from 'react';
import { Points as IPoints } from 'services/cockpit/interfaces/channel';
import { Container, List, Item, Title, Value } from './styles';

interface Props {
  points: IPoints;
  establishmentType: string;
}

const Points: React.FC<Props> = ({ points, establishmentType }) => {
  const blockingAdditionalMargin =
    points.formattedAdditionalMargin.toUpperCase() === 'COOPERATIVA FECHADA';

  const dontShow = establishmentType !== 'Terra Brasil';

  const showPrizeCatalog = points.formattedPrizeCatalog !== '0,00';

  const showDevelopmentActions = points.formattedDevelopmentActions !== '0,00';

  return (
    <>
      <Container>
        <h3>Pontos Disponíveis</h3>
        <List>
          {dontShow && (
            <>
              {showPrizeCatalog && (
                <Item>
                  <Title>Saldo do canal no Catálogo de Prêmios</Title>
                  <Value>{points.formattedPrizeCatalog}</Value>
                </Item>
              )}
              <Item>
                <Title>Saldo do canal de Gestão e Desenvolvimento</Title>
                <Value>{points.formattedChannelGenerationDemand}</Value>
              </Item>
            </>
          )}
          {showDevelopmentActions && (
            <Item>
              <Title>Saldo do canal de Ações de Desenvolvimento</Title>
              <Value>{points.formattedDevelopmentActions}</Value>
            </Item>
          )}
          {!dontShow && (
            <Item>
              <Title>Saldo do canal de Gestão e Desenvolvimento</Title>
              <Value>{points.formattedBusinessGeneration}</Value>
            </Item>
          )}
          <Item>
            <Title>Saldo do canal de IGD</Title>
            <Value>{points.formattedIgdPointsAvailable}</Value>
          </Item>
        </List>
      </Container>
      <Container>
        <h3>Pontos do Programa</h3>
        <List>
          <Item>
            <Title>Total de pontos distribuidos para os vendedores</Title>
            <Value>{points.formattedSellerDistributedPoints}</Value>
          </Item>

          <Item>
            <Title>Solicitação de Retorno Financeiro</Title>
            <Value>{points.formattedFinancialReturn}</Value>
          </Item>

          <Item>
            <Title>Rebate</Title>
            <Value>{points.formattedRebate}</Value>
          </Item>

          {!blockingAdditionalMargin && dontShow && (
            <Item>
              <Title>Margem Adicional</Title>
              <Value>{points.formattedAdditionalMargin}</Value>
            </Item>
          )}
          <Item>
            <Title>IGD</Title>
            <Value>{points.formattedIgdBalance}</Value>
          </Item>
          {dontShow && (
            <>
              <Item>
                <Title>Gestão e Desenvolvimento</Title>
                <Value>{points.formattedProgramGenerationDemand}</Value>
              </Item>

              <Item>
                <Title>Biológicos</Title>
                <Value>{points.formattedBiological}</Value>
              </Item>
            </>
          )}
          <Item>
            <Title>Total de pontos ganhos pelo canal</Title>
            <Value>{points.formattedTotalPoinsChannel}</Value>
          </Item>
        </List>
      </Container>
    </>
  );
};

export default Points;
