import React, { useRef } from 'react';
import { ReactSVG } from 'react-svg';

import iconEmpty from 'assets/images/indication/status-empty.svg';
import iconFilled from 'assets/images/indication/status-filled.svg';

import useWindowSize from 'hooks/use-window-dimensions';

import { Container, AddButton, StatusIndicator } from './styles';

interface Props {
  percentActivated: number;
  onAddClick(): void;
  opened?: boolean;
}

const StatusBox: React.FC<Props> = ({
  percentActivated,
  onAddClick,
  opened = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [inactivated, setInactivated] = React.useState<number[]>([]);
  const [activated, setActivated] = React.useState<number[]>([]);

  const { width } = useWindowSize();

  React.useEffect(() => {
    const spotSvgWidth = 13; // px
    const containerGap = 5; // px
    const containerPadding = 130; // px
    const availableWidth =
      (containerRef.current?.offsetWidth || 0) - containerPadding;
    const availableSpots = Math.ceil(
      availableWidth / (spotSvgWidth + containerGap),
    );

    const active = Math.ceil((availableSpots * percentActivated) / 100);
    const inactive = availableSpots - active;

    setInactivated(Array.from({ length: inactive }, (v, k) => k + 1));
    setActivated(Array.from({ length: active }, (v, k) => k + 1));
  }, [containerRef, percentActivated, width]);

  return (
    <Container ref={containerRef}>
      <h3>Equipe ativa no sistema</h3>
      <div className="status_wrapper">
        <StatusIndicator percentActivated={percentActivated}>
          <>
            {activated.map((item, key) => (
              <span
                key={`activated-${item}`}
                className={key + 1 === activated.length ? '_last' : ''}
              >
                <ReactSVG src={iconFilled} className="_filled" />
              </span>
            ))}
            {inactivated.map(item => (
              <span key={`inactivated-${item}`}>
                <ReactSVG src={iconEmpty} className="_empty" />
              </span>
            ))}
          </>
        </StatusIndicator>
        <AddButton type="button" onClick={onAddClick} opened={opened} />
      </div>
    </Container>
  );
};

export default StatusBox;
