import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 25px 10px;
  text-align: center;

  .custom-file-input {
    background-color: ${({ theme }) => theme.font.color.primary};
    border-radius: 7px;
    height: 3.5em;
    border: 0;
    padding: 0 16px;
    width: 100%;
    color: #fff;
    font-family: Helvetica-Neue-Bold;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.5s;
    will-change: background-color;
  }
`;

export const ActionsModalContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  justify-content: center;
  padding: 5px 15px;
  width: 100%;

  button {
    margin: 0;
    max-width: 200px;
    height: auto;
    padding: 9px 25px;
    font-size: 14px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.font.color.primary};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};

    &:disabled {
      opacity: 0.5;
    }
  }

  // Confirm btn
  button:last-child {
    background: #e63027;
    color: #fff;
  }
`;
