/* eslint-disable @typescript-eslint/camelcase */
import { pluginApi } from 'services/api';

interface ApiResponse {
  register_to_complete: boolean;
}

export default async () => {
  const {
    data: { register_to_complete },
  } = await pluginApi.get<ApiResponse>('/participants/participant/status');

  return register_to_complete;
};
