/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';

import getNews from 'services/news/getNewsList';
import getNewsCategories from 'services/news/getNewsCategories';
import { News as INews } from 'services/news/interfaces';
import { Pagination } from 'config/constants/vendavallPaginationInterface';

import { Grid } from 'components/News';
import { Button } from 'components/shared';

// import { useAuth } from 'context/AuthContext';
import { Container, Content } from './styles';

type ResponseCategories = {
  id: number;
  name: string;
}[];

const News: React.FC = () => {
  const [news, setNews] = useState<INews[]>([]);
  const [categories, setCategories] = useState<ResponseCategories>([]);
  const [optionCategories, setOptionCategories] = useState<string>('Todos');
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);
  // const { participant } = useAuth();

  // Inicio Tampáo
  // const perfil = participant.establishment.type_name;
  // const checking = ['Revenda', 'Produtor', 'Equipe', 'Cooperativa'];

  // useEffect(() => {
  //   if (
  //     perfil === checking[0] ||
  //     perfil === checking[1] ||
  //     perfil === checking[2] ||
  //     perfil === checking[3]
  //   ){
  //     window.location.href = "https://juntosfmc.vendavall.com.br/home";
  //   }
  // });
  // Fim Tampáo

  useEffect(() => {
    getNews({ page: 1 }).then(data => {
      setPagination(data.pagination);
      setNews(data.news);
    });

    getNewsCategories().then(data => {
      setCategories(data.categories);
    });
  }, []);

  const handleLoadMore = useCallback(() => {
    if (pagination.current_page === pagination.last_page) return;
    getNews({ page: pagination.current_page + 1 }).then(data => {
      setPagination(data.pagination);
      setNews([...news, ...data.news]);
    });
  }, [news, pagination]);

  const filteredByCategories = news.filter(
    (newItem: any) => newItem.category.name === optionCategories,
  );

  return (
    <Container>
      <Content>
        <h3>Novidades</h3>

        <label className="custom-label">Selecione uma categoria:</label>
        <div className="custom-select-wrapper">
          <select
            className="custom-select"
            value={optionCategories}
            onChange={evt => setOptionCategories(evt.target.value)}
          >
            <option>Todos</option>
            {categories.map((option, index) => (
              <option
                key={index}
                value={option.name}
                className="custom-select-option"
              >
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <Grid
          news={optionCategories === 'Todos' ? news : filteredByCategories}
        />
        {pagination.current_page !== pagination.last_page && (
          <Button buttonRole="primary" type="button" onClick={handleLoadMore}>
            Carregar mais novidades
          </Button>
        )}
      </Content>
    </Container>
  );
};

export default News;
