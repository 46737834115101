/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import history from 'services/history';
import routeMap from 'routes/route-map';
import { useToast } from 'context/ToastContext';
import { FmcProducts } from 'state/modules/fmc-coins-simulator/interfaces';
import {
  getFmcProducts,
  getValueToConversion,
} from 'state/modules/fmc-coins-simulator/selectors';
import { formatPoints } from 'util/points';
import { Button } from 'components/shared';
import Cards from 'components/FmcCoinsSimulator/Cards';
import useWindowSize from 'hooks/use-window-dimensions';
import { saveSimulationProducts } from 'services/fmc-coins-simulator';
import {
  Container,
  MiniBox,
  Content,
  PageTitle,
  Separator,
  CardsContainer,
  ContentMobile,
  CatalogDesktop,
  CatalogMobile,
} from './styles';

type ProductCategory = 'Estratégico' | 'Inovação' | 'Complementar';

type ProductsArray = {
  product: string;
  value: number;
  fmcCoins: number;
}[];

const ProductCategory = {
  ESTRATEGICO: 'estratégico' as ProductCategory,
  INOVACAO: 'inovação' as ProductCategory,
  COMPLEMENTAR: 'complementar' as ProductCategory,
};

const Result: React.FC = () => {
  const productsSelector = useSelector(getFmcProducts);
  const valueToConversion = useSelector(getValueToConversion);
  const { width } = useWindowSize();
  const { addToast } = useToast();

  const getValues = localStorage.getItem('save-simulation');
  const parsedValues: {
    total: string;
    total_fmc_coin: string;
    products: ProductsArray;
  } | null = getValues ? JSON.parse(getValues) : null;

  const quantityItemsAdded: number = useMemo(
    () => productsSelector?.filter((item: any) => item.checked).length || 0,
    [productsSelector],
  );

  const [valueShow, setValueShow] = useState<string>('');
  const [fmcCoinsShow, setFmcCoinsShow] = useState<string>('');
  const [productStrategic, setProductStrategic] = useState<
    FmcProducts[] | undefined
  >([]);
  const [productInnovation, setProductInnovation] = useState<
    FmcProducts[] | undefined
  >([]);
  const [productComplementary, setProductComplementary] = useState<
    FmcProducts[] | undefined
  >([]);

  const newArray: ProductsArray = [];
  productsSelector
    .filter((product: any) => product.checked)
    .forEach((productEach: any) => {
      newArray.push({
        product: productEach.id,
        value: productEach.valueToConversion,
        fmcCoins: productEach.fmcCoins,
      });
    });

  const onSumValuesTotal = useCallback(
    (valueTotal: number, fmcCoins: number): void => {
      const formattedValueTotal: string = formatPoints(valueTotal);
      const formattedFmcCoins: string = formatPoints(fmcCoins);

      setValueShow(formattedValueTotal);
      setFmcCoinsShow(formattedFmcCoins);

      localStorage.setItem(
        'save-simulation',
        JSON.stringify({
          total: formattedValueTotal,
          total_fmc_coin: formattedFmcCoins,
          products: newArray,
        }),
      );
    },
    [],
  );

  const saveProducts = async (): Promise<void> => {
    try {
      const data: any = await saveSimulationProducts({
        campaign_id: 11,
        total: formatPoints(valueShow),
        total_fmc_coin: formatPoints(fmcCoinsShow),
        products: parsedValues ? parsedValues.products : [],
      });

      if (data.message === 'Simulação salva com sucesso!') {
        addToast({
          title: 'Simulação salva com sucesso!',
          type: 'success',
        });
      }
    } catch (err) {
      addToast({
        title: 'Erro ao salvar sua simulação!',
        type: 'error',
      });
    }

    addToast({
      title: 'Simulação salva com sucesso!',
      type: 'success',
    });

    setTimeout(() => {
      localStorage.removeItem('save-simulation');
      history.push(`${routeMap.fmcCoinsSimulator.selector}`);
    }, 1000);
  };

  useEffect(() => {
    const productsStrategic = productsSelector.filter(
      (product: FmcProducts) =>
        product.category.toLowerCase() === ProductCategory.ESTRATEGICO &&
        product.checked,
    );
    const productsInnovation = productsSelector.filter(
      (product: FmcProducts) =>
        product.category.toLowerCase() === ProductCategory.INOVACAO &&
        product.checked,
    );
    const productsComplementary = productsSelector.filter(
      (product: FmcProducts) =>
        product.category.toLowerCase() === ProductCategory.COMPLEMENTAR &&
        product.checked,
    );

    const sortedProductsStrategic = productsStrategic.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsInnovation = productsInnovation.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsComplementary = productsComplementary.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setProductStrategic(sortedProductsStrategic);
    setProductInnovation(sortedProductsInnovation);
    setProductComplementary(sortedProductsComplementary);
  }, [productsSelector]);

  const validateFields: boolean = parsedValues
    ? !parsedValues.products.length
    : false;

  return (
    <Container id="result">
      {width > 1000 ? (
        <div className="container-buttons">
          <div className="content-simulation">
            <PageTitle>Resultado da simulação</PageTitle>
            <span>Produtos adicionados ({quantityItemsAdded})</span>
          </div>
          <div className="buttons-desktop">
            <Button
              className="btn-save"
              style={{ opacity: validateFields ? 0.6 : 1 }}
              type="submit"
              buttonRole="quaternary"
              title={
                validateFields
                  ? 'Recalcule para poder salvar outra simulação'
                  : ''
              }
              onClick={saveProducts}
              disabled={validateFields}
            >
              Salvar
            </Button>
            <Button
              className="btn-back"
              type="submit"
              buttonRole="primary"
              onClick={() => history.push('/simulador-coins')}
            >
              Voltar
            </Button>
          </div>
        </div>
      ) : (
        <Content>
          <PageTitle>Resultado da simulação</PageTitle>
          <span>Produtos adicionados ({quantityItemsAdded})</span>
          <ContentMobile>
            <Button
              className="btn-save-mobile"
              style={{ opacity: validateFields ? 0.6 : 1 }}
              type="submit"
              buttonRole="quaternary"
              onClick={saveProducts}
            >
              Salvar
            </Button>
            <Button
              className="btn-back-mobile"
              type="submit"
              buttonRole="primary"
              onClick={() => history.push('/simulador-coins')}
            >
              Voltar
            </Button>
          </ContentMobile>
          <br />
          <Separator />
          <br />
        </Content>
      )}

      <Separator />
      <CardsContainer>
        <Cards
          products={productInnovation}
          type="result"
          categoryInterface="Inovação"
          categoryComponent="innovation"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
        />
        <Cards
          products={productStrategic}
          type="result"
          categoryInterface="Estratégico"
          categoryComponent="strategic"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
        />
        <Cards
          products={productComplementary}
          type="result"
          categoryInterface="Complementar"
          categoryComponent="complementary"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
        />
      </CardsContainer>

      <br />
      <br />
      <MiniBox>
        <div className="total-value">
          <span>Valor total</span>
          <span>R$ {valueShow}</span>
        </div>
        <div className="total-fmc-coins">
          <span>Total FMC Coins</span>
          <span>{fmcCoinsShow}</span>
        </div>
      </MiniBox>
      <br />
      <br />

      <Link to={`${routeMap.fmcCoinsSimulator.calculator}`} className="btn">
        Recalcular
      </Link>

      {width > 1000 ? (
        <CatalogDesktop>
          <h4>
            Conheça as opções de serviços e benefícios no Catálogo de Prêmios
          </h4>
          <Button
            type="button"
            onClick={() => history.push('/catalogo')}
            buttonRole="quaternary"
          >
            Acessar Catálogo de Prêmios
          </Button>
        </CatalogDesktop>
      ) : (
        <CatalogMobile>
          <h4>
            Conheça as opções de serviços e benefícios
            <br /> no Catálogo de Prêmios
          </h4>
          <Button
            type="button"
            onClick={() => history.push('/catalogo')}
            buttonRole="quaternary"
          >
            Acessar Catálogo de Prêmios
          </Button>
        </CatalogMobile>
      )}
    </Container>
  );
};

export default Result;
