import isEmpty from 'lodash.isempty';

import {
  fakeFormatDollars,
  formatKgl,
  formatPoints,
  formatKglInput,
} from 'util/points';
import {
  Product,
  BillingPog,
  Campaign,
  Potentializer,
  Infos,
  Biological,
} from 'state/modules/goals/types';
import {
  FetchCampaignsService,
  FetchProductsService,
  FetchBillingPogService,
  FetchPotentializersService,
  FetchInfosService,
  FetchCurrentCampaignService,
  FetchBiologicalService,
} from '.';

export const transformCampaigns = (
  data: FetchCampaignsService[],
): Campaign[] | null => {
  if (!data) return null;

  return data.map<Campaign>(campaign => ({
    id: campaign.id,
    title: campaign.title,
    isTerraBrasil: campaign.GrupoID === 6,
  }));
};

export const transformCurrentCampaing = (
  data: FetchCurrentCampaignService,
): Campaign | null => {
  if (!data) return null;
  return {
    id: data.id,
    title: data.title,
  };
};

export const transformBillingPog = (
  data: FetchBillingPogService,
): BillingPog | null => {
  if (!data) return null;

  const hasBilling = !isEmpty(data.faturamento);
  const hasPog = !isEmpty(data.pog);
  const hasDevolution = !isEmpty(data['devolução']);
  const hasAdditionalMargin = !isEmpty(data.margem_adicional);

  return {
    billing: {
      goal: fakeFormatDollars(hasBilling ? data.faturamento.value : 0, 0, 0),
      percentage: hasBilling
        ? Number((data.faturamento.progress.percentage * 100).toFixed())
        : 0,
      realized: fakeFormatDollars(
        hasBilling ? data.faturamento.progress.value : 0,
        0,
        0,
      ),
    },
    pog: {
      goal: fakeFormatDollars(hasPog ? data.pog.value : 0, 0, 0),
      percentage: hasPog
        ? Number((data.pog.progress.percentage * 100).toFixed())
        : 0,
      realized: fakeFormatDollars(hasPog ? data.pog.progress.value : 0, 0, 0),
    },
    devolution: {
      checked: hasDevolution ? data['devolução'].progress.checked : false,
      value: hasDevolution ? data['devolução'].value : '',
      realized: hasDevolution ? data['devolução'].progress.value : 0,
      punctuality: hasDevolution ? data['devolução'].punctuality : null,
    },
    margem_adicional: {
      meta: hasAdditionalMargin
        ? formatPoints(Number(data.margem_adicional.meta), 0, 0)
        : '0',
      realizado: hasAdditionalMargin
        ? formatPoints(Number(data.margem_adicional.realizado), 0, 0)
        : '0',
      porcentagem: hasAdditionalMargin
        ? Number(data.margem_adicional.porcentagem.toFixed())
        : 0,
    },
  };
};

export const transformBiological = (
  data: FetchBiologicalService,
): Biological | null => {
  if (!data) return null;

  const hasInvoice = !isEmpty(data.invoice);
  const hasPog = !isEmpty(data.pog);

  return {
    invoice: {
      goal: data.invoice.goal || 0,
      percentage: hasInvoice ? Number(data.invoice.percentage.toFixed()) : 0,
      realized: hasInvoice ? data.invoice.realized : 0,
    },
    pog: {
      goal: hasPog ? data.pog.goal : 0,
      percentage: hasPog ? Number(data.pog.percentage.toFixed()) : 0,
      realized: hasPog ? data.pog.realized : 0,
    },
  };
};

export const transformProducts = (
  data: FetchProductsService[],
): Product[] | null => {
  if (!data) return null;

  return data.map<Product>((product, index) => ({
    id: product.id,
    name: product.name,
    billing: fakeFormatDollars(product.value, 0, 0),
    volume: formatPoints(product.volume, 0, 0),
    position: (index + 1).toString().padStart(2, '0'),
  }));
};

export const transformPotentializers = (
  data: FetchPotentializersService[],
): Potentializer[] | null => {
  if (!data) return null;

  return data
    .filter(item => !item.balance_unit.name.includes('(U$)'))
    .map(potentializer => ({
      goal: formatKglInput(potentializer.value),
      name: potentializer.balance_unit.name,
      percentage: Number((potentializer.progress.percentage * 100).toFixed()),
      realized: formatKgl(potentializer.progress.value),
      color: potentializer.pcp.color,
      position: potentializer.pcp.position,
    }));
};

export const transformInfos = (data: FetchInfosService): Infos | null => {
  if (!data) return null;

  const points = data.importedPoints.map(point => ({
    total: formatPoints(point.total),
    name: point.name,
  }));

  return {
    points,
    potential: fakeFormatDollars(data.potential.value, 0, 0),
    generationDemand: fakeFormatDollars(data.generationDemand.value),
    businessGeneration: fakeFormatDollars(data.businessGeneration.value),
    additionalMargin: fakeFormatDollars(data.additionalMargin.value),
    biological: fakeFormatDollars(data.biological.value),
    lastUpdate: data.last_update,
    lastUpdateInvoicing: data.last_update_invoicing,
    lastUpdatePog: data.last_update_pog,
    excel: data.full_performance_report,
  };
};
