import styled from 'styled-components';
import banner from 'assets/images/accelerator-campaigns/banner-participants.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;

  h2 {
    font-size: 18px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-top: 2rem;
    margin-left: 13rem;

    @media screen and (max-width: 720px) {
      margin-left: 1rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 280px;
  background-image: url(${banner});
  background-position: 10% 0, center;
  background-size: cover;

  > h1 {
    margin-top: 4rem;
    margin-left: 10rem;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 21px;

    @media screen and (max-width: 720px) {
      margin-left: 2rem;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  width: 100%;
  background: #fff;
  padding: 1rem 11rem;

  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
  }
`;

export const CampaignBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  background: #efefef;
  margin-bottom: 16px;
  width: 450px;
  height: 300px;
  margin: 0 auto;
  padding: 15px;

  > img {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }

  .campaign-details {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .contents-details {
      flex: 1;
      display: flex;
      flex-direction: column;

      > h3 {
        color: ${({ theme }) => theme.font.color.primary};
        font-size: 14px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      > span {
        color: ${({ theme }) => theme.font.color.secondary};
        font-size: 14px;
        font-family: ${({ theme }) => theme.font.fontFamily.condensed};
        margin-top: 10px;
      }
    }

    > button {
      border-radius: 5px;
      text-transform: uppercase;
      width: 122px;
      height: 38px;
      font-size: 14px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 720px) {
    width: auto;
  }
`;

export const Alert = styled.div`
  display: flex;
  margin: 5em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
