import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswersData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';
import CheckboxList from './CheckBoxList';

import {
  Container,
  GridCheckBoxContent,
  CheckBoxContentGroup,
  GridRow,
  AnswerLabel,
} from './styles';

interface Props {
  question: string;
  answers: AnswersData[];
  id: number;
  topics: string[];
  canEdit: boolean;
}

const InputGridCheckBox: React.FC<Props> = ({
  question,
  answers,
  id,
  topics,
  canEdit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'many' });
    }
  }, [answers, canEdit, dispatch, id]);

  const changeHandler = useCallback(
    (
      answerValue: string,
      answerId: number,
      topic: string,
      deleteOperation: boolean,
    ) => {
      dispatch(
        setValueAnswer({
          answerValue,
          questionId: id,
          answerId,
          topic,
          multipleAnswers: 'many',
          deleteOperation,
        }),
      );
    },
    [dispatch, id],
  );

  return (
    <Container>
      <GridCheckBoxContent>
        <p>{question}</p>
        <CheckBoxContentGroup>
          <GridRow>
            <AnswerLabel>{'      '}</AnswerLabel>
            {topics.map(topic => (
              <span key={topic} style={{ textAlign: 'center' }}>
                {topic}
              </span>
            ))}
          </GridRow>
          {answers.map(answer => (
            <GridRow key={answer.id}>
              <AnswerLabel>{answer.answer}</AnswerLabel>
              <CheckboxList
                answer={answer}
                topics={topics}
                onChangeHandler={(
                  value: string,
                  topic: string,
                  deleteOperation: boolean,
                ) => {
                  changeHandler(value, answer.id, topic, deleteOperation);
                }}
                canEdit={canEdit}
              />
            </GridRow>
          ))}
        </CheckBoxContentGroup>
      </GridCheckBoxContent>
    </Container>
  );
};

export default InputGridCheckBox;
