import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Manutencao from 'assets/images/manutencao.jpg';

import {
  ApproverProfile,
  CRM,
  DM,
  DN,
  EstablishmentTypes,
  GC,
  GRM,
  GRV,
  KAM,
  MKT,
  PROFILES,
  RTC,
  RTCPOG,
} from 'config/constants';
import { Visible } from 'react-grid-system';
import { DefaultTheme, ThemeContext } from 'styled-components';

import ChangeProfile from 'components/ChangeProfileModal';
import Footer from 'components/Footer';
import Header from 'components/Header';
import MobileHeader from 'components/Header/MobileHeader';
import ModalRegulations from 'components/Regulation/AllRegulationsOneByOne';
import Roulette from 'components/Roulette';

import Logo, { LogoType } from 'components/shared/Logo';
import { useAuth } from 'context/AuthContext';
import { getChangeProfile } from 'state/modules/header/selectors';
import { fetchHasRegister } from 'state/modules/home/actions';
import {
  cooperativeTheme,
  defaultTheme,
  fmcTeamGrayTheme,
  fmcTeamRedTheme,
  producerTheme,
  terraBrasilTheme,
} from 'styles/theme';

import { StatusHarvest } from 'services/blockAccess/interfaces';
import { useToast } from 'context/ToastContext';
import { getTampaoSafra } from 'services/blockAccess';
import TampaoSafra from 'pages/BlockAccess/TampaoSafra';
import { Container, SimulateIndicator } from './styles';
import Popups from './Popups';

interface EstablishmentTypeMap {
  [key: string]: string;
}

const FMCTEAM = [GRV, DN, DM, CRM, MKT, RTC, KAM, GRM, GC, RTCPOG];

const Dashboard: React.FC = ({ children }) => {
  const {
    shouldShowRegulationsModal,
    participant,
    simulating,
    signOut,
  } = useAuth();
  const { addToast } = useToast();
  const [theme, setTheme] = useState<DefaultTheme | null>(null);
  const dispatch = useDispatch();
  const showProfileModal = useSelector(getChangeProfile);

  const [logoType, setLogoType] = useState<LogoType | undefined>(undefined);

  const [textSimulating, setTextSimulating] = useState('');

  const [checkParticipant, setCheckParticipant] = useState<StatusHarvest | {}>(
    {},
  );

  const getTypeNameLabel = (typeName: string, typeMap: EstablishmentTypeMap) =>
    typeMap[typeName] || '';

  const establishmentTypeMap: EstablishmentTypeMap = {
    [EstablishmentTypes.Resale]: 'Revenda',
    [EstablishmentTypes.Cooperative]: 'Cooperativa',
    [EstablishmentTypes.TerraBrasil]: 'Terra Brasil',
  };

  const getProfile = useCallback(() => {
    const { establishment, profile, profile_value: profileValue } = participant;
    const typeName = establishment?.type_name || '';
    const label = getTypeNameLabel(typeName, establishmentTypeMap);

    if (profile === 'PRODUTOR') {
      return 'Produtor';
    }

    if (FMCTEAM.includes(profileValue)) {
      return 'FMC';
    }

    return label;
  }, [establishmentTypeMap, participant]);

  const establishmentType = getProfile();

  const blockedAccess =
    Object.entries(checkParticipant).reduce<string[]>(
      (blockAccess, [typeName, response]) => {
        if (response === 'Ativo' && typeName === establishmentType) {
          blockAccess.push(typeName);
        }
        return blockAccess;
      },

      [],
    ).length > 0;

  useEffect(() => {
    getTampaoSafra()
      .then(response => setCheckParticipant(response))

      .catch(e =>
        addToast({
          title: e.response?.data?.message || 'Falha na requisição.',

          type: 'error',
        }),
      );
  }, [addToast]);

  useEffect(() => {
    const rtcDmKamProfiles: ApproverProfile[] = [DM, RTC, RTCPOG];

    if (!participant || !participant.id) {
      setTheme(null);
      return;
    }
    if (rtcDmKamProfiles.includes(participant.profile_value)) {
      setTheme(fmcTeamRedTheme);
      setLogoType('fmcTeam');
      return;
    }
    if (participant.profile === 'FMC') {
      setTheme(fmcTeamGrayTheme);
      setLogoType('fmcTeam');
      return;
    }
    if (participant.profile === 'PRODUTOR') {
      setTheme(producerTheme);
      setLogoType('fmcProdutor');
      return;
    }
    if (
      participant.establishment.type_name === EstablishmentTypes.Cooperative
    ) {
      setTheme(cooperativeTheme);
      setLogoType(EstablishmentTypes.Cooperative);
      return;
    }
    if (
      participant.establishment.type_name === EstablishmentTypes.TerraBrasil
    ) {
      setTheme(terraBrasilTheme);
      setLogoType(EstablishmentTypes.TerraBrasil);
      return;
    }
    setLogoType(EstablishmentTypes.Resale);
    setTheme(defaultTheme);
  }, [dispatch, participant]);

  useEffect(() => {
    setTextSimulating('');
    if (!simulating) {
      setTextSimulating('');
      return;
    }
    if (participant.profile === PROFILES.focalPoint) {
      setTextSimulating('Visão do Focal Point');
      return;
    }
    setTextSimulating('Visão do Participante');
  }, [participant, simulating]);

  useEffect(() => {
    if (participant && participant.profile === 'PRODUTOR') {
      dispatch(fetchHasRegister());
    }
  }, [dispatch, participant]);

  const manutencao = false;

  if (manutencao) {
    return (
      <Container simulating={false}>
        <Logo logoType="fmcTeam" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '80vh',
          }}
        >
          <img src={Manutencao} alt="Site em Manutenção" />
        </div>
      </Container>
    );
  }

  let content = (
    <>
      {simulating && (
        <SimulateIndicator>
          <span>{textSimulating}</span>
          <button onClick={signOut} type="button">
            Sair
          </button>
        </SimulateIndicator>
      )}
      <Container simulating={simulating}>
        <Logo logoType={logoType} />
        <Visible xl xxl>
          <Header />
        </Visible>
        <Visible xs sm md lg>
          <MobileHeader />
        </Visible>
        {children}
        {!shouldShowRegulationsModal && <Popups />}
        <Footer />
      </Container>

      <Roulette />
    </>
  );

  if (!simulating && shouldShowRegulationsModal) {
    content = <ModalRegulations opened={shouldShowRegulationsModal} />;
  }

  return blockedAccess ? (
    <TampaoSafra />
  ) : (
    theme && (
      <ThemeContext.Provider value={theme}>
        {content}
        {showProfileModal && <ChangeProfile isOpen={showProfileModal} />}
      </ThemeContext.Provider>
    )
  );
};

export default Dashboard;
