import { pluginApi } from 'services/api';
import { Harvest, Participant } from 'services/auth/interfaces/Participant';
import numbersOnly from 'util/numbersOnly';
import { formatPoints } from 'util/points';

export default async (): Promise<Participant> => {
  try {
    const { data: participantData } = await pluginApi.get<Participant>(
      'participants/profile',
    );
    const harvestData = participantData?.harvest;

    return {
      ...participantData,
      only_farm: Boolean(Number(participantData.only_farm)),
      harvest: {
        ...harvestData,
        algodao: formatPoints(
          parseInt(numbersOnly(harvestData?.algodao || '0') || '0', 0),
          0,
          0,
        ),
        arroz_irrigado: formatPoints(
          parseInt(numbersOnly(harvestData?.arroz_irrigado || '0') || '0', 0),
          0,
          0,
        ),
        batata: formatPoints(
          parseInt(numbersOnly(harvestData?.batata || '0') || '0', 0),
          0,
          0,
        ),
        cafe: formatPoints(
          parseInt(numbersOnly(harvestData?.cafe || '0') || '0', 0),
          0,
          0,
        ),
        cana: formatPoints(
          parseInt(numbersOnly(harvestData?.cana || '0') || '0', 0),
          0,
          0,
        ),
        cenoura: formatPoints(
          parseInt(numbersOnly(harvestData?.cenoura || '0') || '0', 0),
          0,
          0,
        ),
        cevada: formatPoints(
          parseInt(numbersOnly(harvestData?.cevada || '0') || '0', 0),
          0,
          0,
        ),
        citrus: formatPoints(
          parseInt(numbersOnly(harvestData?.citrus || '0') || '0', 0),
          0,
          0,
        ),
        feijao: formatPoints(
          parseInt(numbersOnly(harvestData?.feijao || '0') || '0', 0),
          0,
          0,
        ),
        mandioca: formatPoints(
          parseInt(numbersOnly(harvestData?.mandioca || '0') || '0', 0),
          0,
          0,
        ),
        melao: formatPoints(
          parseInt(numbersOnly(harvestData?.melao || '0') || '0', 0),
          0,
          0,
        ),
        milho: formatPoints(
          parseInt(numbersOnly(harvestData?.milho || '0') || '0', 0),
          0,
          0,
        ),
        outras: formatPoints(
          parseInt(numbersOnly(harvestData?.outras || '0') || '0', 0),
          0,
          0,
        ),
        soja: formatPoints(
          parseInt(numbersOnly(harvestData?.soja || '0') || '0', 0),
          0,
          0,
        ),
        tabaco: formatPoints(
          parseInt(numbersOnly(harvestData?.tabaco || '0') || '0', 0),
          0,
          0,
        ),
        tomate: formatPoints(
          parseInt(numbersOnly(harvestData?.tomate || '0') || '0', 0),
          0,
          0,
        ),
        trigo: formatPoints(
          parseInt(numbersOnly(harvestData?.trigo || '0') || '0', 0),
          0,
          0,
        ),
        uva: formatPoints(
          parseInt(numbersOnly(harvestData?.uva || '0') || '0', 0),
          0,
          0,
        ),
      } as Harvest,
    } as Participant;
  } catch {
    return {} as Participant;
  }
};
