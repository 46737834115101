import React from 'react';
import { useAuth } from 'context/AuthContext';

import { ApproverProfile, DM, RTC, RTCPOG } from 'config/constants';
// import NoticeToFMC from 'components/Auth/Modals/NoticeToFMC';

import Default from './Default';
import RtcKamDm from './RtcKamDm';

const FmcTeam: React.FC = () => {
  const { participant } = useAuth();

  const rtcDmKamProfiles: ApproverProfile[] = [DM, RTC, RTCPOG];

  // inicio Popup
  // const [modalOpened, setModalOpened] = useState(true);
  // Fim Popup

  if (rtcDmKamProfiles.includes(participant.profile_value)) {
    return (
      <>
        {/* <NoticeToFMC
          isOpen={modalOpened}
          onRequestClose={() => setModalOpened(false)}
        /> */}
        <RtcKamDm />
      </>
    );
  }

  return (
    <>
      {/* <NoticeToFMC
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
      /> */}
      <Default />
    </>
  );
};

export default FmcTeam;
