import React, {useState, useEffect} from 'react';
import { Container, Li } from './styles';

interface InputText {
  checkedText?: string;
}

const PasswordHelp: React.FC<InputText> = ({ checkedText }) => {

  const [isMinLengthValid, setIsMinLengthValid] = useState<boolean>(false);
  const [hasLowercaseLetter, setHasLowercaseLetter] = useState<boolean>(false);
  const [hasUppercaseLetter, setHasUppercaseLetter] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState<boolean>(false);

  useEffect(
    () => {
      if (checkedText === undefined) return;

      checkedText.length >= 10 ? setIsMinLengthValid(true) : setIsMinLengthValid(false);
      /[a-z]/.test(checkedText) ? setHasLowercaseLetter(true) : setHasLowercaseLetter(false);
      /[A-Z]/.test(checkedText) ? setHasUppercaseLetter(true) : setHasUppercaseLetter(false);
      /\d/.test(checkedText) ? setHasNumber(true) : setHasNumber(false);
      /[!@#$%^&*(),.?":{}|<>]/.test(checkedText) ? setHasSpecialCharacter(true) : setHasSpecialCharacter(false);


    }, [checkedText]
  );

  return (
    <Container>
      <h5>Sua senha deve conter:</h5>
      <ul>
        <Li isChecked={isMinLengthValid}>No mínimo de 10 dígitos</Li>
        <Li isChecked={hasLowercaseLetter}>Pelo menos uma letra minúscula</Li>
        <Li isChecked={hasUppercaseLetter}>Pelo menos uma letra maiúscula</Li>
        <Li isChecked={hasNumber}>Pelo menos um número</Li>
        <Li isChecked={hasSpecialCharacter}>{`Pelo menos um dos caracteres (!, $, #, %, @, &)  `}</Li>
      </ul>
    </Container>
  );
};

export default PasswordHelp;
