import styled from 'styled-components';

export const ActionsModalContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  place-items: center;
  padding: 5px 15px;

  button {
    margin: 0;
    max-width: 200px;
    height: auto;
    padding: 9px 25px;
    font-size: 14px;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};

    &:disabled {
      opacity: 0.5;
    }
  }

  // Cancel btn
  button:first-child {
    color: ${({ theme }) => theme.font.color.primary};
  }

  // Confirm btn
  button:last-child {
    background-color: ${({ theme }) => theme.font.color.primary};
    color: #fff;
  }
`;

export const ConfirmationModalContainer = styled.div`
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }

  .resume {
    margin-top: 20px;

    &--title {
      font-size: 14px;
    }

    &--distribution {
      border-radius: 8px;
      padding: 12px 18px;
      background-color: #efefef;
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
      min-width: 350px;

      strong {
        display: flex;
        flex: 1;
        justify-content: space-between;
      }

      &-total {
        display: grid;
        gap: 8px;

        span {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .alert {
    margin-top: 25px;
    padding: 10px 25px;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: #e4001b;
    }
  }
`;
