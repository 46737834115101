import styled, { css } from 'styled-components';
import { opacify } from 'polished';

export const Container = styled.div`
  flex-direction: column;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: ${({ theme }) => theme.font.color.quartenary};
    font-size: 14px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 30px;
  }

  input[type='text'] {
    width: 100%;
    max-width: 435px;
    height: 44px;
    padding: 0 15px;
    color: ${({ theme }) => theme.font.color.quartenary};
  }

  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
    padding: 0 15px;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`;

interface ButtonProps {
  loading: boolean;
}

export const Button = styled.button<ButtonProps>`
  text-transform: uppercase;
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 11px;
  height: 32px;
  border-radius: 7px;
  min-width: 136px;
  padding: 0 18px;
  border: 1px dashed ${({ theme }) => opacify(0.5, theme.font.color.primary)};
  position: relative;

  background: transparent;
  & + button {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;

    ${({ loading }) =>
      loading &&
      css`
        &:after {
          content: 'Aguarde...';
          position: absolute;
          left: 50%;
          top: 32px;
          transform: translateX(-50%);
          font-size: 11px;
          opacity: 0.7;
        }
      `}
  }
`;
