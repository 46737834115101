import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import banner from 'assets/images/banner-extract-notas.png';

export const Container = styled.div`
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 384px);
  color: ${({ theme }) => theme.font.color.secondary};
  background: #fff;
`;

export const Content = styled.div`
  display: block;
  padding-bottom: 40px;
  max-width: 1100px;
  width: 100%;
  margin: 1.5rem auto;
  background: rgba(239, 239, 239, 255);

  > img {
    width: 100%;
  }
`;

export const Banner = styled.div`
  background-image: url(${banner});
  background-position: 10% 0, center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 1.5rem;

  .coinsBanner {
    display: flex;
    z-index: 0;
    position: absolute;
    max-width: 240px;
    top: 130px;
    margin-left: 30%;
  }

  .coins1 {
    margin-top: 20px;
    max-width: 200px;
    margin-left: -30px;
    transform: rotateZ(-50deg);
  }

  .coins2 {
    max-width: 200px;
  }

  .coins3 {
    margin-top: 20px;
    max-width: 200px;
    margin-left: -30px;
    transform: rotateZ(-175deg);
  }
`;

export const InfoBanner = styled.div`
  margin: 1rem;

  .container-nf {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  h3 {
    font-weight: bold;
    margin: 1rem;
    font-size: 20px;
    font-family: Helvetica-Neue-Bold;
  }

  .select {
    width: auto;
    padding: 1rem;
    border-radius: 6px;
    background-color: #efefef;
    margin-right: 260px;
  }

  .container-nf {
    display: flex;
  }

  .add-nf {
    margin-top: 50px;
  }

  @media (min-width: 768px) {
    .select {
      width: 350px;
      padding: 1rem;
      border-radius: 6px;
      background-color: #efefef;
    }
  }
`;

export const Select = styled.select`
  width: 165px;
  height: 32px;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  color: #7b7b7b;
  background: #fff;
  margin: 0.5rem;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0, 0.5rem;
`;

export const PageTitle = styled.p`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  padding-top: 40px;
  margin-bottom: 32px;
`;

export const ExtractLegend = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 12px;

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`;

export const ExtractEmpty = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
  button {
    max-width: 150px;
    margin: 30px auto;
  }
`;

export const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  border-radius: ${({ theme }) => theme.button.primary.borderRadius};
  padding: 10px 15px;
  color: ${({ theme }) => theme.button.primary.fontColor};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-decoration: none;
  margin-top: 20px;
  display: inline-block;
`;

export const StatusContainer = styled.div`
  position: relative;
  padding: 12px;
  border-radius: 10px;
  background-size: 100% auto;
  background-position: center;
  width: 100%;

  background: linear-gradient(
    rgba(101, 85, 77, 0.17) 0%,
    rgba(255, 255, 255, 0.18) 47.78%,
    rgba(101, 85, 77, 0.09) 100%
  );
  color: ${({ theme }) => theme.font.color.primary};
`;

export const StatusContent = styled.div`
  position: relative;

  border: 1px dashed ${({ theme }) => theme.font.color.secondary};
  border-radius: 10px;
  padding: 24px;
  @media (min-width: 768px) {
    padding: 50px 24px;
  }
  a {
    text-decoration: none;
    color: #181818;
  }

  @media (max-width: 767px) {
    > * {
      margin-top: 20px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const StatusItem = styled.div`
  > * {
    width: 100% !important;
  }
  @media (min-width: 768px) {
    width: 30%;
  }
`;
export const StatusTitle = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 18px;
`;
export const StatusBox = styled.div`
  padding: 12px 22px;
  border-radius: 0px;
  background: #fff;
  border: 1px dashed ${({ theme }) => theme.font.color.secondary};
  margin-top: 10px;
`;

export const StatusButton = styled.div`
  padding: 12px 22px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  display: block;
  background: ${({ theme }) => theme.font.color.primary};
  margin-top: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const Details = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  > div {
    padding: 36px;
    background: #fff;
    width: 80%;
    max-width: 750px;
    max-height: 80vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TotalCoins = styled.div`
  padding: 15px 0;
  h3 {
    font-size: 14px;
    font-weight: normal;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    margin-bottom: 12px;
  }
  h4 {
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Header = styled.div`
  position: relative;
  background: rgba(239, 239, 239, 255);
  top: 2rem;
  padding: 1rem 2rem;

  display: flex;
  justify-content: space-between;
  > span {
    display: block;
    border-bottom: 1px dotted ${({ theme }) => theme.font.color.secondary};
    flex: 1 1 100%;
  }

  > div {
    flex-shrink: 0;
    h3 {
      font-size: 19px;
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      font-weight: normal;
    }
    h4 {
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }

    &:last-child {
      margin-top: 10px;
      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }
`;

interface ToggleProps {
  isOpen: boolean;
}

export const Toggle = styled.button<ToggleProps>`
  appearance: none;
  margin: auto;
  border: 0;
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
  background-color: transparent;
  background-position: center;
  background-size: auto 20px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-up' class='svg-inline--fa fa-chevron-up fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%233B302A' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'%3E%3C/path%3E%3C/svg%3E");

  @media (min-width: 768px) {
    position: absolute;
    top: 2rem;
    right: 0px;
    background-position: center;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(0deg);
    `};
`;
