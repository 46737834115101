import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import closeIcon from 'assets/images/training/close-icon.svg';
import { ReactSVG } from 'react-svg';
import { pluginApi } from 'services/api';
import history from 'services/history';
import uploadFileToStorage from 'services/storage/sendFile';

import {
  FaFileUpload,
  FaKey,
  FaCogs,
} from 'react-icons/fa';

import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import {
  Button,
  Close,
  Container,
  DoneButtonContainer,
  DownloadButton,
  Input,
  Modal,
  Title,
  GridContainer,
  GridItem,
  GridItemTitle
} from './styles';

interface AddNota {
  urlnota: string;
}

interface Props {
  onUpdate: () => void;
}

const Upload: React.FC<Props> = ({ onUpdate }) => {
  const [acessKey, setAcessKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [validUploadArchive, setValidUploadArchive] = useState(false);
  const { addToast } = useToast();

  const { isProfileRegisterComplete, participant } = useAuth();

  const onClickHandler = useCallback(() => {
    fileRef.current?.click();
  }, []);

  const handleAttachFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoading(true);
        if (e && e.target && e.target.files && e.target.files.length > 0) {
          const { url, extension } = await uploadFileToStorage(
            e.target.files[0],
            'notas',
          );

          const validArchive = ['jpg', 'png', 'pdf'].includes(extension);

          if (validArchive) {
            pluginApi.post<AddNota>('participants/UploadNota/add', {
              urlnota: url,
            });
            addToast({
              title: 'Arquivo carregado com sucesso',
              type: 'success',
            });
            setShowModal(false);
            setValidUploadArchive(false);

            onUpdate();
          } else {
            addToast({
              title: 'Arquivo com formato inválido!',
              type: 'error',
            });
          }
        }
      } catch (err) {
        addToast({
          title: err.response?.data?.message || 'Problemas ao adicionar anexo!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, onUpdate],
  );

  const validateAcessKey = useCallback(
    async (key: string) => {
      try {
        setLoading(true);
        const { data } = await pluginApi.get<{ message: string }>(
          `/participants/upload-nota/add-invoice-from-api-by-access-key?access_key=${key}`,
        );
        addToast({ title: data.message, type: 'success' });
      } catch (e) {
        addToast({
          title: e.response?.data?.message || 'Ocorreu um erro!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const acessKeyChangedHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAcessKey(e.target.value);
    },
    [],
  );

  const onRequestClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const navigateToProfile = () => {
    const { role } = participant;

    if (
      role?.complete_registration?.missing &&
      !role.complete_registration.missing.cultivo
    ) {
      history.push('/editar-perfil?pre_select=dados_de_cultivo');
    } else if (
      role?.complete_registration?.missing &&
      !role.complete_registration.missing.grupo_fazenda
    ) {
      history.push('/editar-perfil?pre_select=dados_fazenda');
    } else {
      history.push('/editar-perfil');
    }
  };

  return (
    <Container>
      <Button
        disabled={validUploadArchive}
        onClick={() => setShowModal(!showModal)}
      >
        <div>
          <span>Faça o upload da nota fiscal</span>
        </div>
      </Button>

      <Modal isOpen={showModal} onRequestClose={onRequestClose} zIndex={10}>
        <Close>
          <button type="button" onClick={onRequestClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>

        {!isProfileRegisterComplete && (
          <div className="alert--complete-register">
            <div>
              <h3>COMPLETE SEUS DADOS!</h3>
              <div className="desc">
                <p>
                  {`Termine seu cadastro com as informações referentes à "Dados da
                  minha fazenda e/ou CNPJ*" e "Dados da área de cultivo",
                  encontradas nas abas 2 e 3, para enviar sua(s) nota(s)
                  fiscal(is).`}
                </p>
                <span>Atualize agora clicando no botão abaixo.</span>
              </div>
              <button type="button" onClick={navigateToProfile}>
                PREENCHER DADOS
              </button>
            </div>
          </div>
        )}

        {isProfileRegisterComplete && (
          <>
            <Title>Como cadastrar suas notas fiscais?</Title>
            <GridContainer>
              <GridItem>
                <GridItemTitle>
                  <strong><FaFileUpload /> Envio de imagem da NF.</strong>
                </GridItemTitle>
                <DownloadButton
                  disabled={validUploadArchive}
                  buttonRole="primary"
                  type="button"
                  onClick={onClickHandler}
                  loading={loading}
                >
                  Carregar NF
                  <input
                    ref={fileRef}
                    type="file"
                    id="fileId"
                    accept="image/x-png, image/jpg,.pdf"
                    onChange={handleAttachFile}
                    style={{ display: 'none' }}
                  />
                </DownloadButton>
                <p>
                  Clique para fazer o upload. 
                  <br />
                  Arquivos em pdf, jpg ou png.
                </p>
              </GridItem>
          
              <GridItem>
                <GridItemTitle>
                  <FaKey /> Chave de acesso
                </GridItemTitle>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                  <Input
                    type="number"
                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                    name="access_key"
                    placeholder="Digite aqui a sua chave de acesso"
                    onChange={acessKeyChangedHandler}
                  />
                  <DoneButtonContainer>
                    <button
                      type="button"
                      onClick={() => validateAcessKey(acessKey)}
                      disabled={loading}
                    >
                      {loading ? 'CARREGANDO...' : 'ENVIAR CHAVE'}
                    </button>
                  </DoneButtonContainer>
                </div>
                <br /><br />
                <p>
                  Digite a chave da NF com 44 números, localizada junto ao código de barras.
                </p>
              </GridItem>

              <GridItem>
                <GridItemTitle>
                  <FaCogs /> Envio automático de NF
                </GridItemTitle>
                <p>
                  Se esta opção estiver ativada e a compra for efetuada em uma canal habilitado, sua nota será enviada automaticamente.
                </p>
                <p style={{ marginTop: '10px', padding: '10px', backgroundColor: participant.automatic_nf ? '#4472c4' : '#e63027', fontWeight: 'bold', textTransform: 'uppercase' }}>
                  Status: {participant.automatic_nf ? 'Ativado' : 'Desativado'} <a href='/canais-participantes' target='_blank'>Ver Canais</a>
                </p>
              </GridItem>
            </GridContainer>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default Upload;
