import React from 'react';

import { RouletteStatus } from 'components/Roulette/types';
import { useRoulette } from 'components/Roulette/hooks/use-roulette';

import ReactLoading from 'react-loading';
import { Container, Content, ErrorAlert } from './styles';

export const Overlay: React.FC = () => {
  const { status } = useRoulette();

  return (
    <>
      {status === RouletteStatus.LOADING && (
        <Container>
          <Content>
            <ReactLoading
              type="bars"
              className="loader"
              color="#fff"
              height={24}
              width={24}
            />
          </Content>
        </Container>
      )}

      {status === RouletteStatus.NO_SPINS && (
        <Container>
          <Content>
            <ErrorAlert>Você não possui nenhum giro restante.</ErrorAlert>
          </Content>
        </Container>
      )}
    </>
  );
};
