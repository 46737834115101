import { Award, Configuration } from 'state/modules/points-simulator/interfaces';
// import simulatedSale from './simulated-sale';
import simulatedPoints from './simulated-points';
import totalPoints from './total-points';

interface Props {
  award: Award;
  configuration: Configuration;
}

export default (props: Props) => {
  const { award } = props;
  const { configuration } = props;

  return `
  ${simulatedPoints({ award, configuration })}
  ${totalPoints({ award, configuration })}
`;
};
