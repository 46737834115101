/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import banner from 'assets/images/fmc-coins-simulator/banner.png';
import bannerMobile from 'assets/images/fmc-coins-simulator/bannerMobile.jpg';
import routeMap from 'routes/route-map';
import { Link } from 'react-router-dom';
import { Cards } from 'components/FmcCoinsSimulator/Cards';
import { useDispatch, useSelector } from 'react-redux';
import { getFmcProducts } from 'state/modules/fmc-coins-simulator/selectors';
import {
  getFmcProductsApi,
  getFmcProductsApiCategory,
} from 'services/fmc-coins-simulator';
import {
  fmcProductsApi,
  fmcProductsApiToFmcProducts,
} from 'state/modules/fmc-coins-simulator/transformers';
import {
  fetchFmcProductsSuccess,
  setFmcProductsValues,
} from 'state/modules/fmc-coins-simulator/actions';
import { FmcProductsApi } from 'services/fmc-coins-simulator/interfaces';
import { FmcProducts } from 'state/modules/fmc-coins-simulator/interfaces';
import { Pagination } from 'config/constants/vendavallPaginationInterface';
import useWindowSize from 'hooks/use-window-dimensions';
import {
  PageTitle,
  ContentHeader,
  Container,
  Separator,
  Button,
  CardBox,
  ContentsMobile,
  Filter,
  Label,
  ContentSimulations,
} from './styles';
import CompleteRegister from './SavedSimulationsModal';

const FmcCoinsSimulator: React.FC = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const productSelector = useSelector(getFmcProducts);
  const checked = productSelector.filter(p => p.checked === true).length > 0;
  const [productsApi, setProductsApi] = useState<FmcProductsApi[]>([]);
  const [loadState, setLoadState] = useState<boolean>(true);
  const [filterValue, setFilterValue] = useState('');

  const [productStrategic, setProductStrategic] = useState<
    FmcProducts[] | undefined
  >([]);
  const [productInnovation, setProductInnovation] = useState<
    FmcProducts[] | undefined
  >([]);
  const [productComplementary, setProductComplementary] = useState<
    FmcProducts[] | undefined
  >([]);
  // const [productEnhancer, setProductEnhancer] = useState<
  //   FmcProducts[] | undefined
  // >([]);

  const [
    productsPaginationInnovation,
    setProductsPaginationInnovation,
  ] = useState<Pagination>({} as Pagination);

  const [
    productsPaginationStrategic,
    setProductsPaginationStrategic,
  ] = useState<Pagination>({} as Pagination);
  const [
    productsPaginationComplementary,
    setProductsPaginationComplementary,
  ] = useState<Pagination>({} as Pagination);
  // const [productsPaginationEnhancer, setProductsPaginationEnhancer] = useState<
  //   Pagination
  // >({} as Pagination);

  useEffect(() => {
    const getFmcApiProducts = async () => {
      const productsFmcApi = await getFmcProductsApi({
        page: 1,
        limit: 5,
      });
      const allProductsApi = fmcProductsApi(productsFmcApi);
      setProductsApi(allProductsApi);
      const productsToInput = fmcProductsApiToFmcProducts(productsFmcApi);

      dispatch(fetchFmcProductsSuccess(productsToInput));
    };

    if (loadState) {
      getFmcApiProducts();
    }
  }, [dispatch, loadState]);

  useEffect(() => {
    const productsStrategic = productSelector.filter(
      product => product.category.toLocaleLowerCase() === 'estratégico',
    );

    const productsInnovation = productSelector.filter(
      product => product.category.toLocaleLowerCase() === 'inovação',
    );
    const productsComplementary = productSelector.filter(
      product => product.category.toLocaleLowerCase() === 'complementar',
    );

    const [productInnovationPagination] = productSelector.filter(
      product =>
        product.pagination &&
        product.category.toLocaleLowerCase() === 'inovação',
    );

    const [productComplementaryPagination] = productSelector.filter(
      product =>
        product.pagination &&
        product.category.toLocaleLowerCase() === 'complementar',
    );

    const [productStrategicPagination] = productSelector.filter(
      product =>
        product.pagination &&
        product.category.toLocaleLowerCase() === 'estratégico',
    );
    if (loadState) {
      if (
        productInnovationPagination &&
        productInnovationPagination.pagination.current_page === 1
      ) {
        setProductsPaginationInnovation(productInnovationPagination.pagination);
      }

      if (
        productStrategicPagination &&
        productStrategicPagination.pagination.current_page === 1
      ) {
        setProductsPaginationStrategic(productStrategicPagination.pagination);
      }

      if (
        productComplementaryPagination &&
        productComplementaryPagination.pagination.current_page === 1
      ) {
        setProductsPaginationComplementary(
          productComplementaryPagination.pagination,
        );
      }
    }

    const sortedProductsStrategic = productsStrategic.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsInnovation = productsInnovation.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsComplementary = productsComplementary.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setProductStrategic(sortedProductsStrategic);
    setProductInnovation(sortedProductsInnovation);
    setProductComplementary(sortedProductsComplementary);
  }, [productSelector, loadState]);

  const onLoadNextPage = useCallback(
    (category: string | undefined, productPagination: Pagination) => {
      if (productPagination.current_page === productPagination.last_page)
        return;
      setLoadState(false);
      getFmcProductsApiCategory({
        page: productPagination.current_page + 1,
        limit: 5,
        category,
      }).then(data => {
        const productsToInput = fmcProductsApiToFmcProducts(data);

        const allProductsApi = fmcProductsApi(data);
        setProductsApi([...allProductsApi, ...productsApi]);

        if (category) {
          const lowCategoryText = category.toLocaleLowerCase();

          if (productsToInput.length > 0) {
            switch (lowCategoryText) {
              case 'inovação':
                setProductsPaginationInnovation(productsToInput[0].pagination);
                break;
              case 'estratégico':
                setProductsPaginationStrategic(productsToInput[0].pagination);
                break;

              case 'complementar':
                setProductsPaginationComplementary(
                  productsToInput[0].pagination,
                );
                break;
              default:
                break;
            }
          }
        }

        dispatch(
          setFmcProductsValues([...productSelector, ...productsToInput]),
        );
      });
    },
    [
      dispatch,
      setProductsPaginationComplementary,
      setProductsPaginationInnovation,
      setProductsPaginationStrategic,
      productsApi,
      productSelector,
    ],
  );

  const filter = useCallback(
    value => {
      const name = value.toLowerCase();
      if (name) {
        const productFilter = productSelector.filter(product =>
          product.name.toLocaleLowerCase().includes(filterValue),
        );
        setFilterValue(name);
        dispatch(setFmcProductsValues(productFilter));
      } else {
        setFilterValue('');
        const getFmcApiProducts = async () => {
          const productsFmcApi = await getFmcProductsApi({
            page: 1,
            limit: 5,
          });
          const allProductsApi = fmcProductsApi(productsFmcApi);
          setProductsApi(allProductsApi);
          const productsToInput = fmcProductsApiToFmcProducts(productsFmcApi);

          dispatch(fetchFmcProductsSuccess(productsToInput));
        };

        getFmcApiProducts();
      }
    },
    [dispatch, filterValue, productSelector],
  );

  useEffect(() => {
    const path = window.location.pathname;
    console.log(path);
    if (path === '/simulador-coins/') {
      localStorage.removeItem('save-simulation');
      localStorage.removeItem('saved-simulation');
      localStorage.removeItem('calculate-products');
    }
  }, []);

  return (
    <Container id="selector">
      {width <= 1000 && (
        <>
          <ContentsMobile>
            <PageTitle>Simulador de FMC Coins</PageTitle>
            <span>
              Para simular seus FMC Coins, clique abaixo e adicione seus
              produtos.
            </span>
            <img src={bannerMobile} alt="Banner do Simulador de FMC Coins" />
            <h3>Adicionar produtos</h3>
            <input
              type="text"
              value={filterValue}
              onChange={e => filter(e.target.value)}
              placeholder="Digite o nome do produto"
            />

            <Button
              type="button"
              buttonRole="primary"
              onClick={() => setIsOpen(!isOpen)}
            >
              Simulações salvas
            </Button>
            <CompleteRegister isOpen={isOpen} setIsOpen={setIsOpen} />
          </ContentsMobile>
        </>
      )}
      <>
        <ContentHeader>
          <PageTitle>Simulador de FMC Coins</PageTitle>
          <img src={banner} alt="Banner do Simulador de FMC Coins" />
        </ContentHeader>
        {width > 700 && (
          <Filter>
            <ContentSimulations>
              <Label>Adicionar Produtos</Label>
              <CompleteRegister isOpen={isOpen} setIsOpen={setIsOpen} />
              <Button
                type="button"
                buttonRole="primary"
                onClick={() => setIsOpen(!isOpen)}
              >
                Simulações salvas
              </Button>
            </ContentSimulations>
            <input
              type="text"
              value={filterValue}
              onChange={e => filter(e.target.value)}
              placeholder="Digite o nome do produto"
            />
          </Filter>
        )}

        <Separator />
        <CardBox>
          <Cards
            type="checkbox"
            products={productInnovation}
            productsPagination={productsPaginationInnovation}
            categoryInterface="Inovação"
            categoryComponent="innovation"
            loadState={loadState}
            onLoadNextPage={onLoadNextPage}
          />
          <Cards
            type="checkbox"
            products={productStrategic}
            productsPagination={productsPaginationStrategic}
            categoryInterface="Estratégico"
            categoryComponent="strategic"
            loadState={loadState}
            onLoadNextPage={onLoadNextPage}
          />
          <Cards
            type="checkbox"
            products={productComplementary}
            productsPagination={productsPaginationComplementary}
            categoryInterface="Complementar"
            categoryComponent="complementary"
            loadState={loadState}
            onLoadNextPage={onLoadNextPage}
          />
        </CardBox>

        <Button type="button" buttonRole="primary" disabled={!checked}>
          <Link to={`${routeMap.fmcCoinsSimulator.calculator}`} className="btn">
            Fazer simulação
          </Link>
        </Button>
      </>
    </Container>
  );
};

export default FmcCoinsSimulator;
