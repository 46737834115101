import React from 'react';

import { InvoiceItems } from 'services/nf/interfaces';
import { formatPoints } from 'util/points';

import { ProductList, ProductItem, ProductItemContainer } from '../styles';

interface Props {
  items: InvoiceItems[];
}

const ItemList: React.FC<Props> = ({ items = [] }) => {
  return (
    <>
      <h3>Produtos FMC</h3>
      <ProductList>
        {items.length > 0 &&
          items.map(item => (
            <ProductItemContainer key={item.id}>
              <p>{item.category}</p>
              <ProductItem>
                <div>
                  <strong> {item.productName} </strong> <br />
                  <p> {item.productDescription} </p>
                </div>

                <div>
                  <strong>Volume KG/L:</strong> {item.volume} <br />
                  <strong>Valor unitário:</strong> R${' '}
                  {formatPoints(item.unitaryValue)}
                  <br />
                  <strong>Desconto:</strong>{' '}
                  {!item.discount
                    ? 'R$ 0,00'
                    : `R$ ${formatPoints(item.discount)}`}
                </div>

                <div>
                  <strong>Total Net: </strong> {formatPoints(item.netValue)}
                  <br />
                  <strong>Total: </strong> R$ {formatPoints(item.totalValue)}
                  <br />
                  <strong>FMC Coins: </strong> {formatPoints(item.fmcCoins)}
                </div>
              </ProductItem>
            </ProductItemContainer>
          ))}
      </ProductList>
    </>
  );
};

export default ItemList;
