import { pluginApi } from 'services/api';
import { Campaign } from './interfaces/campaign';
import { transform } from './transformers/campaignTransform';

export default async function getCampaigns(): Promise<Campaign[]> {
  try {
    const { data } = await pluginApi.get('/statement/campaigns');

    return transform(data.campaigns);
  } catch (error) {
    return [];
  }
}
