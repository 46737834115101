import { formatPoints } from 'util/points';
import { ParticipantGroup } from '../interfaces';

export interface Response {
  id: number;
  name: string;
  points: string;
  category: string;
  clientGroup: string;
  updateDate: string;
  cnpj: string;
  balance: string;
  balanceIGD: string;
  establishmentTypeId: string;
  campaignGroup: string;
}

export default (data: ParticipantGroup[]): Response[] => {
  return data.map(participant => ({
    id: participant.id,
    name: participant.name,
    points: `R$ ${formatPoints(participant.points)}`,
    category: participant.category,
    clientGroup: participant.client_group,
    updateDate: participant.update_date,
    cnpj: participant.cnpj,
    balance: `R$ ${formatPoints(participant.balance)}`,
    balanceIGD: `R$ ${formatPoints(participant.balanceIGD)}`,
    campaignGroup: participant.campaign_group,
    establishmentTypeId: participant.establishment_type_id,
  }));
};
