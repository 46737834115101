import React, { ButtonHTMLAttributes } from 'react';
import { ButtonContainer } from './styles';

const GoBackButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <ButtonContainer>
      <button type="button" {...props}>
        &lt; Voltar
      </button>
    </ButtonContainer>
  );
};

export default GoBackButton;
