import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fff;
  .btn {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    background-color: ${({ theme }) => theme.font.color.primary};
    @media screen and (max-width: 720px) {
      font-size: 20px;
    }
  }
`;
export const Filter = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media screen and (max-width: 720px) {
    max-width: 350px;
    align-items: center;
  }
  > input {
    width: 310px;
    height: 36px;
    background-color: #ffffff;
    margin-bottom: 1em;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 14px;
    padding: 10px;
    margin-top: -2.5rem;
  }
`;
export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 18px;
  margin-top: 18px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  position: relative;
  @media screen and (max-width: 720px) {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 310px;
  }
`;
export const ContentHeader = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;
export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  padding-top: 40px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 1030px;
  @media screen and (max-width: 720px) {
    max-width: 350px;
    margin-bottom: 0;
  }
`;
export const CardBox = styled.div`
  display: flex;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;
export const ContentsMobile = styled.section`
  padding: 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: -1px 15px 65px 15px;
  width: 100%;
  max-width: 350px;

  h3 {
    position: relative;
    top: 2rem;
    left: -3rem;
    color: #000;
    font-size: 1.5rem;
  }

  > input {
    width: 310px;
    height: 36px;
    background-color: #ffffff;
    margin-bottom: 1em;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 14px;
    padding: 10px;
    position: relative;
    top: 3rem;
  }

  button {
    display: block;
    margin-left: auto;
  }

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 14px;
    margin-bottom: 3em;
  }
  > img {
    width: 100%;
    max-width: 350px;
  }
`;
export const ButtonMobile = styled.button`
  color: #ffffff;
  width: 265px;
  max-width: 360px;
  height: 40px;
  font-size: 20px;
  margin: 50px 0px 50px 0px;
  border: none;
  border-radius: 4px;
`;
export const Button = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 265px;
  max-width: 360px;
  height: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-top: 3em;
  background-color: ${({ theme }) => theme.font.color.primary};
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
  &:hover {
    background-color: ${({ theme }) => theme.font.color.primary};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.font.color.primary};
  }
`;
export const Separator = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1030px;
  height: 1px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 5px 0 -5px 0;
  @media screen and (max-width: 720px) {
    width: 100%;
    max-width: 310px;
  }
`;

export const ContentSimulations = styled.div`
  display: flex;

  button {
    display: block;
    margin-left: auto;
  }
`;
