import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ActionPayload } from 'components/StrategicPlans/types';

import {
  Container,
  Content,
  Header,
  HeaderTitle,
} from 'pages/StrategicPlans/ActionView/styles';
import Form from 'components/StrategicPlans/ActionsForms';
import { pluginApi } from 'services/api';
import { ApiAction } from 'services/strategic-plans/actions/interfaces';
import { useAuth } from 'context/AuthContext';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';

const ActionView: React.FC = () => {
  const [action, setAction] = useState<ApiAction>();
  const [uuid, setUuid] = useState('');
  const { pathname, state } = useLocation<ActionPayload>();
  const { participant } = useAuth();

  const extrairIdDaUrl = (url: string): string => {
    const partesDaUrl = url.split('/');
    const idIndex = partesDaUrl.indexOf('planos-estrategicos') + 1;
    if (idIndex >= 0 && idIndex < partesDaUrl.length) {
      return partesDaUrl[idIndex];
    }

    return '';
  };
  const actionUuid = extrairIdDaUrl(pathname);

  useEffect(() => {
    if (state) {
      setAction(state.action);
      setUuid(state.uuid);
    }

    pluginApi
      .get(`strategy-action/${actionUuid}`)
      .then(response => setAction(response.data));
  }, [state, actionUuid]);

  const isCreatingNewAction = action?.id === 0;
  const canEdit =
    isCreatingNewAction ||
    (participant.id === action?.participant_id &&
      [strategyPlanStatus.inAdjustments, strategyPlanStatus.planning].includes(
        action.status_strategy_plan_id,
      ));

  return (
    <Container>
      <Content>
        <Header>
          <HeaderTitle>
            {canEdit ? 'Nova ação' : 'Detalhes da ação'}
          </HeaderTitle>
        </Header>
        {!!action && <Form action={action} planUuid={uuid} canEdit={canEdit} />}
      </Content>
    </Container>
  );
};

export default ActionView;
