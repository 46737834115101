import React from 'react';
import { CockpitProvider } from './CockpitContext';
import GeneralView from './GeneralView';
import ChannelView from './ChannelView';

import { Container, GeneralContent, Content, Separator } from './styles';

const Cockpit: React.FC = () => {
  return (
    <CockpitProvider>
      <Container>
        <GeneralContent>
          <Content>
            <h3>Cockpit - Visão Geral</h3>
            <GeneralView />
          </Content>
        </GeneralContent>
        <Content>
          <Separator />
          <ChannelView />
        </Content>
      </Container>
    </CockpitProvider>
  );
};

export default Cockpit;
