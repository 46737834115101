/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { Button } from 'components/shared';
import { downloadExcelInBatch } from 'util/download-excel';

import { ActionsModalContainer, ModalContent } from './styles';

const ModalTitle = withStyles({
  root: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})(DialogTitle);

type Props = {
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  participants: any;
  handleFileChanges: any;
};

export const UpLoadAndDownloadExcel = ({
  isOpen,
  onCancel,
  handleFileChanges,
  title,
  participants,
}: Props) => {
  const [participantsExcel, setParticipantsExcel] = useState<any>();

  useEffect(() => {
    const allParticipants: any[] = [];

    participants &&
      Object.values(participants)
        .flat()
        .forEach((r: any) => allParticipants.push(r.participant));

    setParticipantsExcel(allParticipants);
  }, [participants]);

  return (
    <Dialog open={isOpen}>
      <ModalTitle>{title}</ModalTitle>
      <DialogContent dividers>
        <ModalContent>
          <Button
            onClick={() => downloadExcelInBatch(participantsExcel)}
            buttonRole="secondary"
            type="button"
          >
            Download Excel - Participantes
          </Button>
          <br />
          <label className="custom-file-input">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChanges}
              style={{ display: 'none' }}
            />
            Importar Excel - Participantes
          </label>
        </ModalContent>
      </DialogContent>
      <DialogActions>
        <ActionsModalContainer>
          <MaterialButton onClick={onCancel}>Cancelar</MaterialButton>
        </ActionsModalContainer>
      </DialogActions>
    </Dialog>
  );
};
