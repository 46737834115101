/* eslint-disable @typescript-eslint/camelcase */
import { addHours } from 'date-fns';
import { Campaign, Comment, Highlight } from '../interfaces/Campaign';
import {
  CampaignApi,
  ApproverApi,
  Highlight as HighlightApi,
} from '../interfaces/CampaignApi';
import getPtStatus from '../util/getPtStatusText';

const extractComments = (data: ApproverApi[]): Comment[] => {
  return data
    ?.filter(item => !!item.comment)
    .map(item => ({
      name: item.nick_name,
      email: item.email,
      cpf: item.cpf,
      date: item.created,
      message: item.comment,
    }));
};

const extractHighlight = (data: HighlightApi[] | null): Highlight => {
  const found = data?.find(item => item.status) || null;

  return {
    id: found?.id || null,
    status: found?.status || false,
  };
};

export default (campaignApi: CampaignApi): Campaign => ({
  id: campaignApi.id,
  participant_id: campaignApi.participant_id,
  participantName: campaignApi.participant_name,
  campaign_id: campaignApi.campaign_id,
  title: campaignApi.name,
  description: campaignApi.description,
  startDate: addHours(new Date(campaignApi.start_date), 3),
  endDate: addHours(new Date(campaignApi.end_date), 3),
  checkRegulation: campaignApi.check_regulation,
  createdAt: campaignApi.created,
  affordPoints: campaignApi.points,
  complementaryAffordPoints: campaignApi.complementary_points,
  complementaryLocalBudget: campaignApi.local_points,
  complementaryCrmBudget: campaignApi.crm_points,
  expectedSellIn: campaignApi.sell_in_result,
  expectedSellOut: campaignApi.sell_out_result,
  observation: campaignApi.observation,
  goal: campaignApi.goal,
  summary: campaignApi.summary,
  rules_participation: campaignApi.rules_participation,
  stock: campaignApi.stock,
  status: {
    id: campaignApi.status,
    name: getPtStatus(campaignApi.status),
    statusText: campaignApi.status_text,
  },
  goals: campaignApi.products?.map(product => ({
    product: {
      id: product?.id,
      name: product?.name,
    },
    expectedVolume: product.volume,
    expectedSellIn: product.sellin,
    expectedSellOut: product.sellout,
    expectedPortfolioPog: product.portfolio_pog,
  })),
  mechanic: {
    id: campaignApi.campaign_type?.id,
    name: campaignApi.campaign_type?.name || '',
    homeImage: campaignApi.campaign_type?.picture || '',
    internalImage: campaignApi.campaign_type?.secondary_picture || '',
    emailImage: campaignApi.campaign_type?.third_picture || '',
    campaignListImage: campaignApi.campaign_type?.fourth_picture || '',
    description: campaignApi.campaign_type?.description || '',
    created: campaignApi.campaign_type?.created || '',
    materialLink: campaignApi.campaign_type?.file || '',
  },

  culture: campaignApi.crops || [],
  audience: campaignApi.establishments?.map(customer => ({
    customer: {
      id: customer.id,
      name: customer.name,
      cnpj: customer.cnpj,
      type: customer.type_name,
    },
    balance: customer.balance,
  })),
  prize: {
    description: campaignApi.reward_description,
    name: campaignApi.reward_name,
  },
  approvers: campaignApi.approvers,
  comments: campaignApi.approvers ? extractComments(campaignApi.approvers) : [],
  highlight: extractHighlight(campaignApi.highlights),
  sendEmail: campaignApi.send_emails,
  communicationKit: campaignApi.communication_kit,
  approver_in_turn: campaignApi.approver_in_turn,
});
