import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 20px;

  @media screen and (max-width: 1180px) {
    padding: 30px 1em;
  }

  h1 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 22px;
  }

  .banner {
    margin-top: 25px;

    img {
      max-width: 100%;
    }
  }

  .banner-desk {
    @media screen and (max-width: 501px) {
      display: none;
    }
  }

  .banner-mobile {
    @media screen and (min-width: 500px) {
      display: none;
    }
  }
`;
