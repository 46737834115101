import React, { useCallback } from 'react';
import indirectBanner from 'assets/images/bannerRevenda.svg';
import cooperativeBanner from 'assets/images/bannerCooperativa.svg';
import { ReactSVG } from 'react-svg';
import history from 'services/history';
import routeMap from 'routes/route-map';

import { useAuth } from 'context/AuthContext';
import { Container, Content, BannerCadastro, Button } from './styles';

interface Props {
  buttonRole: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
}

const CpfCrossDataForm: React.FC = () => {
  const { participant } = useAuth();
  const onClickHandler = useCallback(() => {
    history.push(routeMap.completeProducerRegistration);
  }, []);

  return (
    <Container>
      <Content>
        <BannerCadastro>
          <ReactSVG
            src={
              participant.establishment.type_name === 'Revenda'
                ? indirectBanner
                : cooperativeBanner
            }
          />
          <Button type="submit" buttonRole="primary" onClick={onClickHandler}>
            Ativar meu cadastro como PRODUTOR
          </Button>
        </BannerCadastro>
      </Content>
    </Container>
  );
};

export default CpfCrossDataForm;
