import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  margin-bottom: 15px;

  ._inputContainer {
    height: 40px;
    border-color: ${({ theme }) => theme.font.color.primary};
  }

  .establishment-select {
    > span {
      margin-bottom: 5px;
    }
  }

  span.error {
    color: #ee2e30;
  }
`;
