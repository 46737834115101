/* eslint-disable prefer-destructuring */
import { v4 as uuid } from 'uuid';
import { Award } from 'components/Roulette/types';

import {
  AwardResponseApi,
  AwardResponseRouletteData,
  AwardResponseRouletteAwards,
} from '../getRouletteAwards';

export const cssRotationDegs = [330, 270, 210, 150, 90, 30];

function orderAwardsToRoulette(awards: Award[]) {
  /** Ordena os prêmios nas divisões da roleta: 1/0/1/0/1/0
   * 1 = win
   * 0 = lose
   */
  const mappedIds: string[] = [];

  cssRotationDegs.forEach((deg, index) => {
    const piePosition = index + 1;
    const filteredAwards = awards.filter(
      award => mappedIds.indexOf(award.temp_id) === -1,
    );

    const winOrLose = piePosition % 2;
    const winningAwards = filteredAwards.filter(award => award.value > 0);
    const losingAwards = filteredAwards.filter(award => award.value <= 0);

    let award: Award | null = null;

    if (winningAwards.length && winOrLose === 1) {
      award = winningAwards[0];
    }

    if (losingAwards.length && winOrLose === 0) {
      award = losingAwards[0];
    }

    if (!award) {
      award = filteredAwards[0];
    }

    award.slice_data = {
      deg,
      index: piePosition,
    };

    mappedIds.push(award.temp_id);
  });

  awards.sort((a, b) => {
    if (a.slice_data.index > b.slice_data.index) return 1;
    if (a.slice_data.index < b.slice_data.index) return -1;

    return 0;
  });

  return awards;
}

export function mapRouletteData(data: AwardResponseApi) {
  const rouletteData = data[0] as AwardResponseRouletteData;
  const awards = data[1] as AwardResponseRouletteAwards[];

  const mappedAwards = awards.map(
    ({ Awards }) =>
      ({
        id: Awards.id,
        /** Para cada Reward, é gerado um id temporário
         * para identificação única do prêmio, esse id não é enviado
         * em nenhum momento para o back-end, apenas para uso e identificação
         * na roleta.
         * Obs: Os prêmios podem ter o campo `id` repetido, por isso é gerado
         * um id único no campo `temp_id`.
         */
        temp_id: uuid(),
        title: Awards.title,
        value: Number(Awards.value),
      } as Award),
  );

  return {
    rouletteData,
    awards: orderAwardsToRoulette(mappedAwards),
  };
}
