import React, { useCallback, useEffect, useState } from 'react';
import parser from 'html-react-parser';
import { Button } from 'components/shared';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import pdfIcon from 'assets/images/pdf.svg';
import getRegulation, {
  Response as IRegulation,
} from 'services/campaigns-manager/getRegulation';
import { Campaign } from 'services/campaigns-manager/interfaces/Campaign';
import getUrlRegulationToDownload from 'services/register/regulation/getUrlRegulationToDownload';
import { Modal, Container, Content, Close, RegulationBox } from './styles';

interface Props {
  isOpen: boolean;
  onRequestClose(): void;
  campaign: Campaign;
}

const RegulationModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  campaign,
}) => {
  const [regulation, setRegulation] = useState<IRegulation | null>(null);
  const [downloading, setDownloading] = useState(false);

  const handleDownloadClick = useCallback(async () => {
    if (!regulation) return;

    setDownloading(true);
    const url = await getUrlRegulationToDownload(
      regulation.id,
      `${regulation.name}.pdf`,
      campaign.id ?? 0,
    );
    setDownloading(false);
    const linkClick = document.createElement('a');
    linkClick.href = url;
    linkClick.download = `${regulation.name}.pdf`;
    document.body.appendChild(linkClick);
    linkClick.click();
    document.body.removeChild(linkClick);
  }, [campaign.id, regulation]);

  useEffect(() => {
    if (!campaign?.id) return;
    getRegulation(campaign.id).then(data => setRegulation(data));
  }, [campaign]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} type="primary">
      <Container>
        <Close>
          <button type="button" onClick={onRequestClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <h4>Regulamento</h4>
        <Content>
          {regulation && (
            <RegulationBox>{parser(regulation.content || '')}</RegulationBox>
          )}
        </Content>
        <button
          type="button"
          className="download-button"
          disabled={downloading}
          onClick={handleDownloadClick}
        >
          <ReactSVG src={pdfIcon} />
          Download Regulamento (PDF)
        </button>
        <Button type="button" buttonRole="primary" onClick={onRequestClose}>
          Fechar
        </Button>
      </Container>
    </Modal>
  );
};

export default RegulationModal;
