import { CAMPAIGN_STATUS } from '../interfaces/Campaign';

export default (statusText: number, plural = false): string => {
  switch (statusText) {
    case CAMPAIGN_STATUS.NEW_REQUEST_STATUS:
      return `Nova solicitação`;
    case CAMPAIGN_STATUS.DEVELOPMENT_STATUS:
      return `Em análise/desenvolvimento`;
    case CAMPAIGN_STATUS.UNDER_ANALYSIS_STATUS:
      return `Em análise`;
    case CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS:
      return `Em aprovação`;
    case CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS:
      return `Aprovada para publicação`;
    case CAMPAIGN_STATUS.PUBLISHED_STATUS:
      return `Publicada${plural ? 's' : ''}`;
    case CAMPAIGN_STATUS.FINISHED_STATUS:
      return `Finalizada${plural ? 's' : ''}`;
    case CAMPAIGN_STATUS.CANCELED_STATUS:
      return `Cancelada${plural ? 's' : ''}`;
    default:
      return 'Status não identificado';
  }
};
