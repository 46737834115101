import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px 0;
  color: #000;

  strong {
    font-size: 18px;
  }

  .tabs-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    margin-top: 30px;
    align-items: center;
    grid-gap: 15px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
    }
  }

  .filters-btn--wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    button {
      height: fit-content;
      max-width: 150px;
      font-size: 14px;
      padding: 8px 35px;
      max-height: 40px;
      margin: 0;

      @media screen and (max-width: 530px) {
        font-size: 12px;
      }
    }
  }

  .info-prazo {
    display: flex;
    align-items: center;
    margin: 15px 0;
    opacity: 0.6;
    font-size: 0.875rem;
    gap: 5px;
  }
  // MUI Overrides
  .MuiTabs-root,
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
`;

export const TableGrid = styled.div`
  .mobile-only {
    display: none;

    @media screen and (max-width: 530px) {
      display: block;
    }
  }

  .desktop-only {
    @media screen and (max-width: 530px) {
      display: none !important;
    }
  }

  .table--loading {
    display: flex;
    justify-content: center;
  }

  .table--empty {
    display: flex;
    justify-content: center;
    font-size: 0.875rem;
    opacity: 0.5;
    font-weight: bold;
    padding: 15px 0;
  }

  .table {
    display: grid;

    &[data-dnp='true'] {
      grid-template-columns: repeat(8, 1fr);
    }

    &[data-dnp='false'] {
      grid-template-columns: repeat(7, 1fr);
    }

    @media screen and (max-width: 530px) {
      &[data-dnp='false'],
      &[data-dnp='true'] {
        grid-template-columns: 1fr;
      }
    }

    &--head {
      font-size: 0.8em;
      text-transform: uppercase;
      background-color: ${({ theme }) =>
        theme.table.secondary.thead.tr.backgroundColor};
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      color: ${({ theme }) => theme.table.secondary.thead.tr.color};
      height: 40px;
      display: flex;
      align-items: center;
      text-align: left;

      .th {
        flex: 1;
        padding-left: 20px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      @media screen and (max-width: 530px) {
        height: auto;
      }
    }

    &--body {
      height: 50px;
      background-color: ${({ theme }) =>
        theme.table.secondary.thead.tr.backgroundColor};

      .row-block {
        padding-left: 20px;
        display: flex;
        align-items: center;
        font-size: 0.875rem;

        @media screen and (max-width: 530px) {
          padding-left: unset;
          padding: 10px 20px;
          gap: 3px;
          display: grid;
          grid-template-columns: 0.5fr 1.5fr;

          *:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            text-align: right;
          }
        }

        .details-link {
          color: ${({ theme }) => theme.font.color.primary};
          display: flex;
          align-items: center;
          gap: 3px;

          svg {
            line-height: 0;
          }
        }

        .title {
          font-weight: bold;
        }
      }

      @media screen and (max-width: 530px) {
        height: auto;
        margin-bottom: 8px;
      }
    }
  }
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  align-items: center;
`;
