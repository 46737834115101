import { pluginApi } from 'services/api';
import { AxiosError } from 'axios';

interface ApiResponse {
  success_count: number;
}

interface Response {
  success_count: number;
  errors: string[];
}

export default async (formData: FormData): Promise<Response> => {

  try {
    const {
      data: { success_count },
    } = await pluginApi.post<ApiResponse>(
      'participants/indications/importDirect',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return {
      success_count,
      errors: [],
    };
  } catch (error) {
    const axiosError = error as AxiosError<Response>;

    if (
      axiosError.response?.data?.errors &&
      typeof axiosError.response?.data?.success_count === 'number'
    ) {
      return {
        success_count: axiosError.response.data.success_count,
        errors: axiosError.response.data.errors,
      };
    }

    return {
      success_count: 0,
      errors: ['Erro não identificado, por favor entre em contato com o suporte'],
    };
  }
};