import React, { useState, useEffect, useCallback } from 'react';

import { Option } from 'components/shared/Select';
import { updateActionStatus } from 'services/strategic-plans/actions';
import { useToast } from 'context/ToastContext';
import { useSimpleToast } from 'components/shared';
import { Container, Select } from './styles';

interface StatusSelectProps {
  statuses: Option[];
  uuid?: string;
  defaultStatus?: number;
  disabled?: boolean;
}

const StatusSelect: React.FC<StatusSelectProps> = ({
  statuses,
  uuid,
  defaultStatus,
  disabled,
}) => {
  const [option, setOption] = useState<Option | null>(null);
  const { addToast } = useToast();
  const { showToast } = useSimpleToast();

  const loadItems = useCallback(() => {
    return statuses;
  }, [statuses]);

  const updateStatus = useCallback(
    async (status: number) => {
      if (uuid) {
        try {
          await updateActionStatus({ uuid, status });

          showToast({
            message: 'Status atualizado com sucesso!',
            type: 'success',
          });
        } catch (err) {
          addToast({
            title:
              err.response?.data?.message ||
              'Problemas ao atualizar status da ação!',
            type: 'error',
          });
        }
      }
    },
    [uuid, addToast, showToast],
  );

  useEffect(() => {
    if (defaultStatus) {
      setOption(
        statuses.find(({ value }) => value === defaultStatus.toString()) ||
          null,
      );
    }
  }, [defaultStatus, statuses]);

  useEffect(() => {
    if (option && option.value !== defaultStatus?.toString()) {
      const status = parseInt(option.value, 10);
      updateStatus(status);
    }
  }, [defaultStatus, option, updateStatus]);

  return (
    <Container>
      <Select
        loadItems={loadItems}
        inputRole="primary"
        value={option}
        setValue={setOption}
        disableClearable
        disabled={disabled}
      />
    </Container>
  );
};

export default StatusSelect;
