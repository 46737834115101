import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: #002128;
  min-height: 100vh;
`;

export const Header = styled.div`
  padding: 25px 35px;
  text-align: center;

  img {
    max-height: 80px;

    @media screen and (max-width: 440px) {
      max-height: 50px;
    }
  }
`;

export const LateralMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;

  a {
    text-decoration: none;
    color: #ffff;
    padding: 10px 8px;
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    transition: background-color 200ms ease-in-out;

    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    @media screen and (max-width: 440px) {
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  overflow: scroll;

  .term-content {
    padding: 20px;
    color: #000;
    height: 100%;
    overflow: auto;
    word-wrap: break-word;
    max-width: 800px;
  }
`;

export const Wrapper = styled.div<{ isLoading: boolean }>`
  height: 100%;
  grid-gap: 5px;
  padding: 0px 20px;

  ${p =>
    p.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
    `}

  ${p =>
    !p.isLoading &&
    css`
      display: grid;
      grid-template-columns: minmax(200px, 300px) 1fr;

      @media screen and (max-width: 620px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    `}
`;
