import styled from 'styled-components';

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;

  @media screen and (max-width: 720px) {
    margin-bottom: 5px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  margin-top: 4em;

  > span {
    font-size: 14px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-left: 2px;

    & + span {
      font-family: ${({ theme }) => theme.font.fontFamily.regular};
      margin-left: 36px;
    }
  }

  @media screen and (max-width: 720px) {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
    font-size: 16px;

    > span {
      margin-top: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fff;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 265px;
    max-width: 360px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 5em;
    background-color: ${({ theme }) => theme.font.color.primary};
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    font-size: 16px;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  @media screen and (max-width: 720px) {
    span {
      font-size: 14px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: ${({ theme }) => theme.font.color.primary};
      margin-bottom: 16px;
    }
    .btn {
      display: none;
    }
  }

  .bntMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 265px;
    max-width: 360px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 2em;
    background-color: ${({ theme }) => theme.font.color.primary};
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;

    @media screen and (min-width: 720px) {
      display: none;
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-top: -5px;
  align-items: start;

  > div {
    opacity: 1;
    transition: opacity 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1030px;
  height: 1px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 3em;

  @media screen and (max-width: 720px) {
    width: 100%;
    max-width: 350px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;
