import { pluginApi } from 'services/api';

interface Request {
  surveyId: number;
  formData: FormData;
}

export default async ({ surveyId, formData }: Request): Promise<void> => {
  try {
    await pluginApi.post(
      `/participants/surveys/sendAnswers?survey_id=${surveyId}`,
      formData,
    );
  } catch (error) {
    throw new Error(error);
  }
};
