import { pluginApi } from 'services/api';
import { History } from './interfaces';

interface FetchDictionaryParams {
  page?: number;
  limit?: number;
  id?: number;
}

export default async ({ page = 1, limit = 5, id }: FetchDictionaryParams): Promise<{ data: History[]; pagination: any }> => {
  try {
    // Garante que page seja um número válido
    const validPage = Number.isNaN(Number(page)) ? 1 : Number(page);
    const { data } = await pluginApi.get(`dictionary`, {
      params: {
        page: validPage,
        limit,
        id,
      },
    });

    return data;
  } catch {
    return { data: [], pagination: null };
  }
};



