import { pluginApi } from 'services/api';
import { ExtractCampaign } from 'services/nf/interfaces';

export default async (): Promise<ExtractCampaign[]> => {
  const { data } = await pluginApi.get<ExtractCampaign[]>(
    '/participants/upload-nota/campaigns',
  );

  return data;
};
