import { vendavallApi } from 'services/api';
import { Category } from 'services/news/interfaces/Category';

interface ApiResponse {
  categories: Category[];
}

export default async (): Promise<ApiResponse> => {
  const { data } = await vendavallApi.get<ApiResponse>(`news/categories`);
  return data;
};
