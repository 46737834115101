import { all, takeEvery, call, put } from 'redux-saga/effects';

import { handlerErrors } from 'util/handler-errors';
import fetchEstablishmentsService, {
  Establishment as IEstablishment,
} from 'services/auth/getEstablishments';
import { FETCH_ESTABLISHMENTS_ACTION } from './constants';
import * as actions from './actions';
import { Establishment } from './types';

export function* workerFetchEstablishments() {
  try {
    const establishments: IEstablishment[] = yield call(
      fetchEstablishmentsService,
    );

    if (!establishments) {
      throw new Error('Você não possui estabelecimentos');
    }

    const transformedEstablishments: Establishment[] = establishments.map(
      ({ id, client_group, type }: IEstablishment): Establishment => ({
        value: `${id}`,
        title: client_group,
        type: type.name,
      }),
    );

    yield put(actions.fetchEstablishmentsSuccess(transformedEstablishments));
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchEstablishmentsFailure);
  }
}

export default function* commonSagas() {
  yield all([
    takeEvery(FETCH_ESTABLISHMENTS_ACTION, workerFetchEstablishments),
  ]);
}
