import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 30px;

  ._inputContainer {
    max-width: 120px;
    height: 45px;
  }

  span {
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-bottom: 10px;
  }
`;
