import React, { useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import { useSimpleToast } from 'components/shared';
import Layout1 from './Layout1';
import Layout2 from './Layout2';

const ShowCase: React.FC = () => {
  const { participant, isLoggedFromAdmin } = useAuth();

  const { showToast } = useSimpleToast();

  useEffect(() => {
    if (isLoggedFromAdmin) {
      showToast({
        type: 'error',
        message:
          'Você não pode visualizar o catálogo de prêmios via acesso admin.',
      });
    }
  }, [showToast, isLoggedFromAdmin]);

  return participant.profile === 'FMC' ? <Layout2 /> : <Layout1 />;
};

export default ShowCase;
