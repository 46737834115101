/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import getCampaigns from 'services/producer-extract/getCampaigns';
import { ExtractCampaign } from 'services/producer-extract/interfaces';

import { getParticipantsToAccessPI } from 'services/showcase';
import { formatDate } from 'util/datetime';
import { useAuth } from 'context/AuthContext';

import { parseISO } from 'date-fns';

import RescuePoints from 'components/FcmCoinsExtract/RescuePoints';
import orderBy from 'lodash.orderby';

import { formatPointsExtract } from 'util/points';

import { Button } from 'components/shared';
import DatePicker from 'components/shared/DatePicker';

import { pluginApi } from 'services/api';
import useDimensions from 'hooks/use-window-dimensions';
import {
  Container,
  Content,
  ListTableContainer,
  PageTitle,
  StatusContainer,
  StatusContent,
  StatusItem,
  StatusBox,
  Banner,
  TotalCoins,
  ListTable,
  ContentMobile,
} from './styles';

interface ResponseExtract {
  datahora: Date | null;
  descricao: string | null;
  credito: number | null;
  debito: number | null;
  saldo: number | null;
  dataExpiracao: Date | null;
}

interface InfoDaysExpired {
  id: number;
  day: number;
}

const ProducerExtract: React.FC = () => {
  const location = useLocation();
  const { simulating } = useAuth();
  const [points, setPoints] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [researched, setResearched] = useState<boolean>(false);

  const { width } = useDimensions();

  const [infoExtract, setInfoExtract] = useState([]);

  const [initialDate, setInitialDate] = useState<Date>(() => {
    const now = new Date();
    return new Date(now.getFullYear() - 2, now.getMonth(), now.getDate());
  });
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  const [valueFmcCoins, setValueFmcCoins] = useState(0);

  const [pointsThatWillExpire, setPointsThatWillExpire] = useState(0);

  const [campaigns, setCampaigns] = useState<ExtractCampaign[]>([]);

  const getCampaign = campaigns
    .map(infoCampaign => infoCampaign.title)
    .slice(1)[0];
  const [select, setSelect] = useState(getCampaign);

  const [piAccess, setPiAccess] = useState('');
  const [
    currentCampaign,
    setCurrentCampaign,
  ] = useState<ExtractCampaign | null>(null);

  const getInfoCampaign = campaigns
    .map(infoCampaign => infoCampaign)
    .filter(x => x.title === select);

  // Formatadores de data
  const onEndDateChangeHandler = useCallback((value: Date): void => {
    setFinalDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);

  const onBeginDateChangeHandler = useCallback((value: Date): void => {
    setInitialDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const result = await getCampaigns();
      const ordered = orderBy(result, ['title'], ['desc']);
      setCampaigns(ordered.filter(item => item.id !== 2));
      setCurrentCampaign(ordered[0]);
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    getParticipantsToAccessPI().then(data => {
      setPiAccess(data.find(item => item.type === 'cpf')?.urlPi || '');
      setPoints(data.find(item => item.type === 'cpf')?.points || 0);
    });
  }, [location]);

  // Filtros do extrato (dias para expirar, total de fmc coins e informações do extrat)
  useEffect(() => {
    const getPointsExpired = async () => {
      try {
        const { data } = await pluginApi.get(
          '/participants/extrato-fmc-coins/get-points-expired?days=30',
        );
        setPointsThatWillExpire(data.data);
      } catch (_) {}
    };

    getPointsExpired();
  }, []);

  useEffect(() => {
    const getAllFmcCoins = async () => {
      try {
        const { data } = await pluginApi.get(
          `/participants/extrato-fmc-coins/get-all-points?campaign_id=${
            getInfoCampaign[0]?.id || (currentCampaign && currentCampaign.id)
          }`,
        );
        setValueFmcCoins(data.pointsTotal);
      } catch (err) {}
    };

    getAllFmcCoins();
  }, [select, currentCampaign]);

  const getInfoExtract = async () => {
    try {
      setLoading(true);
      const { data } = await pluginApi.get(
        `premio-ideall/points?start_date=${formatDate(
          initialDate,
          'yyyy-MM-dd',
        )}&final_date=${formatDate(finalDate, 'yyyy-MM-dd')}`,
      );
      setResearched(true);
      setLoading(false);
      setInfoExtract(data.data);
    } catch (err) {}
  };

  const getExtractValues =
    researched && infoExtract.length > 2 && !loading && width && width > 768;

  return (
    <Container>
      <Banner>
        <PageTitle>Extrato de FMC Coins</PageTitle>
        <StatusContainer>
          <StatusContent>
            <StatusItem>
              <StatusBox className="first-box">
                <RescuePoints
                  points={points}
                  piAccess={piAccess}
                  isSimulating={simulating}
                  isProducer
                />
              </StatusBox>
            </StatusItem>
            <StatusItem>
              <StatusBox>
                <TotalCoins>
                  <h3>Creditado na Safra:</h3>
                  <select
                    value={select}
                    onChange={e => setSelect(e.target.value)}
                  >
                    {campaigns.map(selectCampaign => (
                      <option
                        key={selectCampaign.title}
                        value={selectCampaign.title}
                      >
                        Safra {selectCampaign.title}
                      </option>
                    ))}
                  </select>
                  <h4>
                    {currentCampaign
                      ? formatPointsExtract(valueFmcCoins)
                      : '000.000,00'}{' '}
                    FMC Coins
                  </h4>
                </TotalCoins>
              </StatusBox>
            </StatusItem>
            <StatusItem>
              <StatusBox>
                <TotalCoins>
                  <h3>FMC Coins a expirar no mês vigente:</h3>
                  <h4>
                    {currentCampaign
                      ? formatPointsExtract(pointsThatWillExpire)
                      : '000.000,00'}{' '}
                    FMC Coins
                  </h4>
                </TotalCoins>
              </StatusBox>
            </StatusItem>
          </StatusContent>
        </StatusContainer>
      </Banner>
      <Content>
        <div className="title-extract">
          <h3>Extrato do Catálogo de Prêmios</h3>
        </div>
        <div className="parent-date">
          <div className="content-date">
            <div className="align-datepickers">
              <div className="first-datepicker">
                <DatePicker
                  label="Data inicial"
                  value={initialDate}
                  onChange={onBeginDateChangeHandler}
                />
              </div>
              <div className="content-date">
                <div className="second-datepicker">
                  <DatePicker
                    label="Data final"
                    value={finalDate}
                    onChange={onEndDateChangeHandler}
                    className="datepickerfinal"
                  />
                </div>
              </div>
            </div>
            <Button
              className="datepickerfinal btn"
              buttonRole="secondary"
              type="button"
              disabled={!initialDate || !finalDate}
              onClick={getInfoExtract}
            >
              Consultar
            </Button>
          </div>
        </div>
        <ListTableContainer>
          <div
            className={researched && infoExtract.length > 1 ? 'searched' : ''}
          >
            {researched && infoExtract.length < 2 && (
              <h3>Nenhum resultado encontrado</h3>
            )}
            {getExtractValues ? (
              <ListTable>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Descrição</th>
                    <th>Pontos</th>
                    <th>Débito</th>
                    <th>Saldo</th>
                    <th>Vencimento</th>
                  </tr>
                </thead>

                <tbody>
                  {infoExtract &&
                    infoExtract.slice(1).map((info: ResponseExtract) => (
                      <tr>
                        <td>
                          {(info.datahora &&
                            formatDate(info.datahora, 'dd/MM/yyyy')) ||
                            '-'}
                        </td>
                        <td>{info.descricao || '-'}</td>
                        <td>
                          {(info.credito &&
                            formatPointsExtract(info.credito)) ||
                            '-'}
                        </td>
                        <td>
                          {(info.debito && formatPointsExtract(info.debito)) ||
                            '-'}
                        </td>
                        <td>
                          {(info.saldo && formatPointsExtract(info.saldo)) ||
                            '-'}
                        </td>
                        <td>
                          {(info.dataExpiracao &&
                            formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                            '-'}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </ListTable>
            ) : (
              <ContentMobile>
                {infoExtract &&
                  infoExtract.slice(1).map((info: ResponseExtract) => (
                    <div className="card-items">
                      <p>
                        <span>Data</span>:{' '}
                        {(info.datahora &&
                          formatDate(info.datahora, 'dd/MM/yyyy')) ||
                          '-'}
                      </p>
                      <p>
                        <span>Descrição</span>: {info.descricao || '-'}
                      </p>
                      <p>
                        <span>Pontos</span>:{' '}
                        {(info.credito && formatPointsExtract(info.credito)) ||
                          '-'}
                      </p>
                      <p>
                        <span>Débito </span>:{' '}
                        {(info.debito && formatPointsExtract(info.debito)) ||
                          '-'}
                      </p>
                      <p>
                        <span>Saldo</span>:{' '}
                        {(info.saldo && formatPointsExtract(info.saldo)) || '-'}
                      </p>
                      <p>
                        <span>Vencimento</span>:{' '}
                        {(info.dataExpiracao &&
                          formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                          '-'}
                      </p>
                    </div>
                  ))}
              </ContentMobile>
            )}
          </div>
        </ListTableContainer>
      </Content>
    </Container>
  );
};

export default ProducerExtract;
