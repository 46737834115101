import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 4em;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 997px;
    padding: 33px 46px;
  }

  > div span {
    max-width: 790px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 35px;
    color: ${({ theme }) => theme.font.color.quartenary};
  }
  ._loading {
    svg {
      fill: ${({ theme }) => theme.font.color.primary};
      margin-top: 1px;
    }
  }
  .no-report {
    font-size: 15px;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  > li {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;

    a {
      color: ${({ theme }) => theme.font.color.primary};
    }
  }
`;
