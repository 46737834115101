export const FETCH_BANNERS_ACTION = '@fmc/home/FETCH_BANNERS_ACTION';
export const FETCH_BANNERS_FAILURE = '@fmc/home/FETCH_BANNERS_FAILURE';
export const FETCH_BANNERS_SUCCESS = '@fmc/home/FETCH_BANNERS_SUCCESS';

export const FETCH_HIGHLIGHTS_ACTION = '@fmc/home/FETCH_HIGHLIGHTS_ACTION';
export const FETCH_HIGHLIGHTS_FAILURE = '@fmc/home/FETCH_HIGHLIGHTS_FAILURE';
export const FETCH_HIGHLIGHTS_SUCCESS = '@fmc/home/FETCH_HIGHLIGHTS_SUCCESS';

export const FETCH_STRATEGIES_ACTION = '@fmc/home/FETCH_STRATEGIES_ACTION';
export const FETCH_STRATEGIES_FAILURE = '@fmc/home/FETCH_STRATEGIES_FAILURE';
export const FETCH_STRATEGIES_SUCCESS = '@fmc/home/FETCH_STRATEGIES_SUCCESS';

export const FETCH_ENGAGEMENTS_ACTION = '@fmc/home/FETCH_ENGAGEMENTS_ACTION';
export const FETCH_ENGAGEMENTS_FAILURE = '@fmc/home/FETCH_ENGAGEMENTS_FAILURE';
export const FETCH_ENGAGEMENTS_SUCCESS = '@fmc/home/FETCH_ENGAGEMENTS_SUCCESS';

export const FETCH_BELLS_ACTION = '@fmc/home/FETCH_BELLS_ACTION';
export const FETCH_BELLS_FAILURE = '@fmc/home/FETCH_BELLS_FAILURE';
export const FETCH_BELLS_SUCCESS = '@fmc/home/FETCH_BELLS_SUCCESS';

export const FETCH_RANKING_ACTION = '@fmc/home/FETCH_RANKING_ACTION';
export const FETCH_RANKING_FAILURE = '@fmc/home/FETCH_RANKING_FAILURE';
export const FETCH_RANKING_SUCCESS = '@fmc/home/FETCH_RANKING_SUCCESS';

export const FETCH_PERFORMANCE_ACTION = '@fmc/home/FETCH_PERFORMANCE_ACTION';
export const FETCH_PERFORMANCE_FAILURE = '@fmc/home/FETCH_PERFORMANCE_FAILURE';
export const FETCH_PERFORMANCE_SUCCESS = '@fmc/home/FETCH_PERFORMANCE_SUCCESS';

export const FETCH_SHOWCASEPRODUCTS_ACTION =
  '@fmc/home/FETCH_SHOWCASEPRODUCTS_ACTION';
export const FETCH_SHOWCASEPRODUCTS_FAILURE =
  '@fmc/home/FETCH_SHOWCASEPRODUCTS_FAILURE';
export const FETCH_SHOWCASEPRODUCTS_SUCCESS =
  '@fmc/home/FETCH_SHOWCASEPRODUCTS_SUCCESS';

export const FETCH_LUCKYNUMBER_ACTION = '@fmc/home/FETCH_LUCKYNUMBER_ACTION';
export const FETCH_LUCKYNUMBER_FAILURE = '@fmc/home/FETCH_LUCKYNUMBER_FAILURE';
export const FETCH_LUCKYNUMBER_SUCCESS = '@fmc/home/FETCH_LUCKYNUMBER_SUCCESS';

export const FETCH_HAS_REGISTER_ACTION = '@fmc/home/FETCH_HAS_REGISTER_ACTION';
export const FETCH_HAS_REGISTER_FAILURE =
  '@fmc/home/FETCH_HAS_REGISTER_FAILURE';
export const FETCH_HAS_REGISTER_SUCCESS =
  '@fmc/home/FETCH_HAS_REGISTER_SUCCESS';
export const DISMISS_HAS_REGISTER_TO_COMPLETE =
  '@fmc/home/DISMISS_HAS_REGISTER_TO_COMPLETE';

export const DISPLAY_ROULETTE_MODAL = '@fmc/home/DISPLAY_ROULETTE_MODAL';
export const DISMISS_ROULETTE_MODAL = '@fmc/home/DISMISS_ROULETTE_MODAL';
export const FETCH_USER_ROULETTES_ACTION =
  '@fmc/home/FETCH_USER_ROULETTES_ACTION';
export const FETCH_USER_ROULETTES_SUCCESS =
  '@fmc/home/FETCH_USER_ROULETTES_SUCCESS';
export const FETCH_USER_ROULETTES_FAILURE =
  '@fmc/home/FETCH_USER_ROULETTES_FAILURE';
export const UPDATE_USER_ROULETTE_SPINS =
  '@fmc/home/UPDATE_USER_ROULETTE_SPINS';

export enum HighlightTypes {
  Report = 'report',
  Quiz = 'quiz',
  FmcCampaign = 'fmc_campaign',
}
