import { Establishment } from 'services/auth/signIn';

export class CatalogStatusError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CatalogStatusError';
  }
}

export class TwoLoginException extends Error {
  private profiles: Establishment[];

  constructor(messagetwo: string, profiles: Establishment[]) {
    super(messagetwo);
    this.name = 'TwologinError';
    this.profiles = profiles;
  }

  public getProfiles(): Establishment[] {
    return this.profiles;
  }
}
