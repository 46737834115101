/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { TiTimes } from 'react-icons/ti';
import { useDispatch } from 'react-redux';

import menuIcon from 'assets/images/menu-icon.svg';
import { RouletteMenu } from 'components/Roulette/components/RouletteMenu';
import { CommercialTeam } from 'config/constants';
import { useAuth } from 'context/AuthContext';
import { useMenu } from 'context/MenuContext';
import history from 'services/history';
import { fetchCoinQuotation } from 'state/modules/header/actions';
import MobileMenu from './Menu/Mobile';
import {
  ClosesMenu,
  EstablishmentType,
  HeaderToggleMenu,
  MobileNav,
} from './MobileHeader.styles';
import ParticipantMobile from './Participant/ParticipantMobile';

const Header: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { participant, signOut, simulating } = useAuth();
  const { menus } = useMenu();

  const toggleMenu = useCallback(() => {
    setMenuIsOpen(!menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    dispatch(fetchCoinQuotation());
  }, [dispatch]);

  useEffect(() => {
    history.listen((_, action) => {
      if (action === 'PUSH' && menuIsOpen) toggleMenu();
    });
  }, [toggleMenu, menuIsOpen]);

  const getEstablishmentType = useCallback(() => {
    const isFarmer = participant.profile === 'PRODUTOR';
    const isCommercialTeam = Object.values(CommercialTeam).includes(
      participant.profile_value ?? '',
    );

    if (isCommercialTeam) {
      return 'Equipe Comercial';
    }
    if (isFarmer) {
      return 'PRODUTOR';
    }

    return participant.establishment.type_name;
  }, [
    participant.establishment.type_name,
    participant.profile,
    participant.profile_value,
  ]);

  return (
    <>
      <HeaderToggleMenu>
        <div className="burguer-container">
          <img
            src={menuIcon}
            alt="burguer menu"
            title="Menu"
            onClick={toggleMenu}
          />

          <RouletteMenu />
        </div>
        <EstablishmentType>{getEstablishmentType()}</EstablishmentType>
      </HeaderToggleMenu>
      <MobileNav open={menuIsOpen}>
        <ClosesMenu onClick={toggleMenu}>
          <TiTimes />
        </ClosesMenu>
        <ParticipantMobile
          name={participant.nick_name}
          picture={participant.picture}
          points={0}
          profile={participant.profile}
          establishment={
            /* participant.profile === 'FMC' ? null : participant.establishment */
            null
          }
          participant={participant}
        />
        {!!menus && (
          <MobileMenu items={menus} signOut={signOut} simulating={simulating} />
        )}
      </MobileNav>
    </>
  );
};

export default Header;
