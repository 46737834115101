/* eslint-disable @typescript-eslint/no-unused-vars */
import getAllNotas from './getAllNotas';
import { INVOICES_STATUS } from './constants';
import { InvoiceStatusSummary } from './interfaces';

export default async (campaignId: number): Promise<InvoiceStatusSummary> => {
  const data = await getAllNotas();

  const underAnalysis = data.invoices.filter(
    item => item.status === INVOICES_STATUS.underAnalysis,
  ).length;
  const released = data.invoices.filter(
    item => item.status === INVOICES_STATUS.released,
  ).length;
  const disqualified = data.invoices.filter(
    item => item.status === INVOICES_STATUS.disqualified,
  ).length;
  const awaitingApproval = data.invoices.filter(
    item => item.status === INVOICES_STATUS.awaitingApproval,
  ).length;

  return {
    underAnalysis,
    released,
    disqualified,
    awaitingApproval,
  };
};
