import React, { useEffect, useState } from 'react';

// import checkIcon from 'assets/images/goals/goals-check-icon.svg';
// import uncheckIcon from 'assets/images/goals/goals-times-icon.svg';
import CircularProgress from 'components/Home/Performance/CircularProgress';
import {
  BillingPog as BillingPogType,
  AdditionalMargin,
} from 'state/modules/goals/types';
import { useAuth } from 'context/AuthContext';
import { TitleSection, BoxBackground } from './styles';

import Box from '../Box';
import {
  WrapperValues,
  Item,
  ProgressTitle,
  GoalText,
  CircularSectionItem,
  // Icons,
  Goals,
} from '../Box/styles';
import RealizedProgress from '../RealizedProgress';

interface BillingPogProps {
  billingPog: BillingPogType | null;
  additionalMargin: AdditionalMargin | null;
}
const BillingPog: React.FC<BillingPogProps> = ({
  billingPog,
  additionalMargin,
}) => {
  const { participant } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // const hasPunctuality = billingPog?.devolution.punctuality === 'Sim';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dinamicTitle =
    participant.establishment.type_name === 'Terra Brasil'
      ? 'POG Fornecedor'
      : 'POG';

  if (!!billingPog && billingPog.billing.goal === '0') {
    return (
      <BoxBackground>
        <TitleSection>Aguarde a publicação das metas do seu canal</TitleSection>
      </BoxBackground>
    );
  }

  const shouldDisplayAdditionalMargin = additionalMargin
    ? Object.values(additionalMargin).some(value => value > 0)
    : false;

  return (
    <Box title="FATURAMENTO E POG" reverse>
      {!!billingPog && (
        <WrapperValues>
          <Goals isMobile={isMobile}>
            <Item>
              <ProgressTitle>FATURAMENTO</ProgressTitle>
              <GoalText>Objetivo US$ {billingPog.billing.goal}</GoalText>
              <CircularSectionItem>
                <CircularProgress
                  color="#FF4C16"
                  percent={billingPog.billing.percentage}
                >
                  <RealizedProgress
                    percent={billingPog.billing.percentage}
                    realized={`US$ ${billingPog.billing.realized}`}
                  />
                </CircularProgress>
              </CircularSectionItem>
            </Item>
            <Item>
              <ProgressTitle>{dinamicTitle}</ProgressTitle>
              <GoalText>Objetivo US$ {billingPog.pog.goal}</GoalText>
              <CircularSectionItem>
                <CircularProgress
                  color="#25CCE1"
                  percent={billingPog.pog.percentage}
                >
                  <RealizedProgress
                    percent={billingPog.pog.percentage}
                    realized={`US$ ${billingPog.pog.realized}`}
                  />
                </CircularProgress>
              </CircularSectionItem>
            </Item>
            {shouldDisplayAdditionalMargin && (
              <Item>
                <ProgressTitle>MARGEM ADICIONAL</ProgressTitle>
                <GoalText>
                  Objetivo {additionalMargin ? additionalMargin.meta : null} L
                </GoalText>
                <CircularSectionItem>
                  <CircularProgress
                    color="#47C246"
                    percent={
                      additionalMargin ? additionalMargin.porcentagem : 0
                    }
                  >
                    <RealizedProgress
                      percent={
                        additionalMargin ? additionalMargin.porcentagem : 0
                      }
                      realized={`${
                        additionalMargin ? additionalMargin.realizado : null
                      } L`}
                    />
                  </CircularProgress>
                </CircularSectionItem>
              </Item>
            )}
          </Goals>
          {/* <Icons>
            <ProgressTitle>DEVOLUÇÃO %</ProgressTitle>
            <GoalText>{billingPog.devolution.checked}</GoalText>
            <CircularSectionItem>
              {billingPog.devolution.checked ? (
                <img src={checkIcon} alt="Ícone check devolução" />
              ) : (
                <img
                  src={uncheckIcon}
                  className="uncheckIcon"
                  alt="Ícone uncheck devolução"
                />
              )}
            </CircularSectionItem>
          </Icons> */}
          {/* {participant.establishment.type_name === 'Terra Brasil' && (
            <Icons>
              <ProgressTitle className="pontual">PONTUALIDADE</ProgressTitle>
              <CircularSectionItem>
                {hasPunctuality ? (
                  <img src={checkIcon} alt="Ícone check pontualidade" />
                ) : (
                  <img
                    src={uncheckIcon}
                    className="uncheckIcon"
                    alt="Ícone uncheck pontualidade"
                  />
                )}
              </CircularSectionItem>
            </Icons>
          )} */}
        </WrapperValues>
      )}
    </Box>
  );
};

export default BillingPog;
