import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation: ${fadeIn} 250ms ease-out;
  background-color: #fff;
  padding: 15px 15px 8px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Details = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  button {
    all: unset;
    cursor: pointer;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;
