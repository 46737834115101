/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';

import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import history from 'services/history';
import routeMap from 'routes/route-map';
import { getSimulationsSaved } from 'services/fmc-coins-simulator';
import { Modal, Container, Close } from './styles';
// import { formatPoints } from 'util/points';

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const CompleteRegister: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getSimulationsSaved();
        setData(response.simulations);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const getCurrentCampaign = data && data['24/25'];

  const handleDirect = (id: string) => {
    history.replace(`${routeMap.fmcCoinsSimulator.resultSaved}/${id}`);
  };

  return (
    <Modal isOpen={isOpen}>
      <Close>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <ReactSVG src={closeIcon} style={{ fill: 'blue' }} />
        </button>
      </Close>
      <Container>
        {getCurrentCampaign === undefined && (
          <h2>Nenhuma simulação foi encontrada.</h2>
        )}
        {getCurrentCampaign &&
          Object.entries(getCurrentCampaign)
            .filter(
              ([key, value]) =>
                typeof value === 'object' && !Array.isArray(value),
            )
            .map(([key, value]) => {
              const item = value as {
                total: number;
                total_fmc_coin: number;
                id: string;
                created: string;
              };

              const createdDate = new Date(item.created);
              const formattedDate = `${createdDate.getDate()}/0${
                createdDate.getMonth() + 1
              }/${createdDate.getFullYear()}`;

              return (
                <table key={key}>
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Valor total</th>
                      <th>Total de FMC Coins</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bold">
                        Simulação realizada em: {formattedDate}
                      </td>
                      <td>{item.total}</td>
                      <td>{item.total_fmc_coin}</td>
                      <td>
                        <button onClick={() => handleDirect(item.id)}>
                          Visualizar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
      </Container>
    </Modal>
  );
};

export default CompleteRegister;
