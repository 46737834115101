import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';
import DefaultInput from 'components/shared/Input';

interface Active {
  active?: boolean;  // Definindo o tipo da prop `active`
}

export const TotalHectares = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 255px;
  margin-top: 10px;

  > p {
    font-size: 15px;
    color: #808285;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Obs = styled.p`
  font-size: 18px;
  color: #e63027;
  max-width: 590px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  margin: 40px 0;
`;
export const Input = styled(DefaultInput)`
  max-width: 178px;

  ._inputContainer {
    height: 40px;
  }
`;

export const Button = styled(DefaultButton)`
  margin-top: 30px;
  width: 100%;
  max-width: 225px;
  height: 48px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 40px 0;
`;


export const NewObs = styled.div`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.font.color.primary};

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export const BoxText = styled.div`
  max-width: 400px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 16px;

  @media screen and (max-width: 665px) {
    max-width: none;
    width: 100%;
  }
`;

export const BoxHectares = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (max-width: 665px) {
    width: 100%;
  }


  p {
    font-size: 16px;
  }
`;

export const BoxOptions = styled.div`
  background: #efefef;
  color: ${({ theme }) => theme.font.color.primary};

  width: 120%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  padding: 50px 40px;

  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: -10%;

  h3 {
    width: 100%;
    margin-bottom: 25px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Container = styled.div`
  column-count: 4;
  width: 100%;

  @media screen and (max-width: 592px) {
    column-count: 2;
  }

  @media screen and (max-width: 364px) {
    column-count: 1;
  }
`;

export const BoxInputOptions = styled.div<Active>`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 18px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
  }

  input[type="checkbox"] + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s;
  }

  input[type="checkbox"]:checked + label::before {
    background-color: ${({ theme }) => theme.font.color.primary};
    border-color: ${({ theme }) => theme.font.color.primary};
  }

  input[type="checkbox"]:checked + label::after {
    content: '✔';
    position: absolute;
    left: 4px;
    top: 0px;
    font-size: 16px;
    color: white;
  }
`;

export const InputOtions = styled.input`
  margin-right: 20px;   
`;

export const InputText = styled.input`
    background: #fff;
    border-radius: 0px;
    padding: 0 14px;
    width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #193b4e;
    color: #808285;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    border-color: #193b4e;
    color: #193b4e;
    border-width: 1px;
`;

export const OptionsTitle = styled.label<Active>`
  font-weight: bold;
  margin-right: ${(props) => (props.active ? '20px' : '0px')};
`;


export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
`;

