/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { vendavallApi } from 'services/api';

import { FloatingBanner, Container, Close } from './styles';

type Position = 'bottom' | 'top';
interface Props {
  isOpen?: boolean;
  onRequestClose(): void;
  initialPosition: Position;
}

interface CookieObjects {
  status: string;
  show: boolean;
  message: string;
}

const style = {
  bottom: { bottom: 0 },
  top: { top: 50 },
};

const CookieModal: React.FC<Props> = ({ initialPosition }) => {
  const defaultPosition = style[initialPosition];
  const [showMessageBanner, setShowMessageBanner] = useState(true);
  const [checkCokkieOK, setCheckCokkieOK] = useState<boolean>(true);
  const [cookie, setCookie] = useState<CookieObjects>();
  useEffect(() => {
    vendavallApi
      .get('/campaign_config/get_cookies')
      .then(response => setCookie(response.data));
  }, []);

  const handleAccept = () => {
    setShowMessageBanner(false);

    var dataAtual = new Date();
    var expire = new Date();
    expire.setDate(dataAtual.getDate() + 1000000);
    document.cookie = "CheckdCokkie; expires=" + expire.toString();
  };
  
  useEffect(
    () => {
      const getCokkie = document.cookie.split(';');
      let cookieFound = true;

      getCokkie.forEach(e => {
        if (e.trim() === 'CheckdCokkie') {
          cookieFound = false;
        }
      });

      setCheckCokkieOK(cookieFound);
    }, []);  

  if (!showMessageBanner) {
    return null;
  }

  return (
    <>
      {cookie?.show && checkCokkieOK && (
        <FloatingBanner>
          <Container style={defaultPosition}>
            <h3>POLÍTICA DE PRIVACIDADE E COOKIES</h3>
            <h4 dangerouslySetInnerHTML={{ __html: cookie?.message }} />
          </Container>
          <Close>
            <button type="button" onClick={handleAccept}>
              OK
            </button>
          </Close>
        </FloatingBanner>
      )}
    </>
  );
};

export default CookieModal;
