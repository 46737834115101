import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container } from './styles';

interface Props {
  question: string;
  id: number;
  answerId?: number;
  type: string;
  answers: AnswerData[];
  canEdit: boolean;
}

const QuestionGlobal: React.FC<Props> = ({
  question,
  type,
  id,
  answerId,
  answers,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch);
    }
  }, [answers, canEdit, dispatch, id]);

  useEffect(() => {
    setSelectedDate(
      answers[0].survey_participant_answers.length > 0
        ? answers[0].survey_participant_answers[0].answer
        : '',
    );
  }, [answers]);

  return (
    <Container inputType={type}>
      <p>{question}</p>
      <input
        type={type}
        placeholder="Insira os dados necessários"
        onChange={e => {
          setSelectedDate(e.target.value);
          dispatch(
            setValueAnswer({
              answerValue: e.target.value,
              questionId: Number(id),
              answerId: Number(answerId),
            }),
          );
        }}
        value={selectedDate}
        disabled={!canEdit}
      />
    </Container>
  );
};

export default QuestionGlobal;
