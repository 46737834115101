import React, { useContext, createContext, useCallback, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

/* import { ToastContainer, toast } from 'react-toastify'; */

import Test from 'components/Modals/InfoMessage';

export interface ToastMessage {
  type?: 'error' | 'success' | 'info';
  title: string;
  refresh?: String;
}

interface Options {
  onClose: () => void;
}

interface ToastContextData {
  addToast(message: ToastMessage, options?: Options): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [text, setText] = useState('');
  const [options, setOptions] = useState<Options | null>(null);

  const addToast = useCallback((message: ToastMessage, opts?: Options) => {
    setText(message.title);
    setIsOpen(true);
    if(message.refresh === 'true'){
      setRefresh(true);
    }    

    if (opts) {
      setOptions(opts);
    } else {
      // Clear for other toasts
      setOptions(null);
    }
  }, []);

  const handleRequestClose = () => {
    setIsOpen(false);

    if (options) {
      options?.onClose();
    }
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Test
        isOpen={isOpen}
        execRefresh={refresh}
        onRequestClose={handleRequestClose}
        text={text}
        className="_modal-toast"
      />
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextData => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};

export { ToastProvider, useToast };
