import { StoreState } from 'state/root-reducer';
import { AvailableProfiles, Coin, MenuItem } from './types';
import { HeaderState } from './reducer';

export const getCoinQuotations = (state: StoreState): Coin[] | null =>
  state.header.coinQuotations;

export const getMenu = (state: StoreState): MenuItem[] | null =>
  state.header.menu;

export const getHeader = (state: StoreState): HeaderState => state.header;

export const getChangeProfile = (state: StoreState): boolean =>
  state.header.changeLoginProfile;

export const getAvailableProfiles = (state: StoreState): AvailableProfiles =>
  state.header.availableProfiles;

export const getIsChangeProfileAvailable = (state: StoreState): boolean => {
  const { indirect, producer } = state.header.availableProfiles;
  return !!indirect && !!producer;
};
