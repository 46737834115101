import routeMap from 'routes/route-map';
import RtcExtract from 'pages/RtcExtract';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.rtc,
    component: RtcExtract,
    accessPage: 'Página de extrado RTC',
  },
];

export default routes;
