import React, { useState } from 'react';
import DataRegulation from 'components/Regulation/DataRegulation';
import {
  Regulation,
  RegulationPolicy,
} from 'services/register/regulation/interfaces/IRegulation';

import { Container, RegulationContent } from './styles';
import Header from '../shared/Header';

interface DataRegulationProps {
  onAccept: () => Promise<void> | void;
  profile: string;
  regulation: Regulation | null;
  regulationPolicy?: RegulationPolicy | undefined;
  onSetPolicy?: (value: string | undefined) => void;
}

const DataTermRegulation: React.FC<DataRegulationProps> = ({
  onAccept,
  profile,
  regulation,
  regulationPolicy,
  onSetPolicy,
}) => {
  const [policyExposed, setPolicyExposed] = useState<string | undefined>();

  if (onSetPolicy) onSetPolicy(policyExposed);

  return (
    <Container>
      <Header />
      <RegulationContent>
        <DataRegulation
          onAccept={onAccept}
          regulation={regulation}
          profile={profile}
          regulationPolicy={regulationPolicy}
          onSetPolicy={setPolicyExposed}
        />{' '}
      </RegulationContent>
    </Container>
  );
};

export default DataTermRegulation;
