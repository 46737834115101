import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PROFILES } from 'config/constants';
import { Participant } from 'services/auth/interfaces/Participant';
import { useAuth } from 'context/AuthContext';
import ProducerHeader, { Tab } from './Producer/Header';
import PersonalDataForm from './PersonalDataForm';
import FarmDataForm from './FarmDataForm';
import HarvestDataForm from './HarvestDataForm';
import SecurityDataForm from './SecurityDataForm';

import { Title } from './styles';

interface Props {
  saveParticipant(data: Participant): Promise<void>;
  editing?: boolean;
  completeRegister?: boolean;
}

const Form: React.FC<Props> = ({
  editing = false,
  completeRegister = false,
}) => {
  const [autoindicate, setAutoindicate] = useState(false);
  const [indicatorCode, setIndicatorCode] = useState('');
  const [activeTab, setActiveTab] = React.useState<Tab>('PERSONAL_DATA');
  const inputRole = 'secondary';

  const location = useLocation();
  const { participant } = useAuth();
  const isProducer = participant.profile === PROFILES.producer;

  useEffect(() => {
    const indicatorCodeFromUrl = location.search.replace('?code=', '');
    if (indicatorCodeFromUrl) {
      setIndicatorCode(indicatorCodeFromUrl);
    }

    if (participant.profile === PROFILES.focalPoint && !completeRegister) {
      setAutoindicate(
        participant.access_premio_ideall &&
          participant.establishment.team_receives_points,
      );
    }
  }, [
    participant.profile,
    participant.access_premio_ideall,
    participant.establishment.team_receives_points,
    location.search,
    completeRegister,
  ]);

  const getTitle = useCallback((): string => {
    if (completeRegister) {
      return 'Completar cadastro';
    }

    if (editing) {
      return 'Meu perfil';
    }

    if (participant.profile === PROFILES.producer) {
      return 'Bem-vindo Produtor(a)';
    }

    return 'Ativar cadastro';
  }, [editing, completeRegister, participant.profile]);

  const getExtraTitle = useCallback((): string => {
    if (
      window.location.pathname === '/completar-cadastro-produtor' ||
      isProducer
    )
      return ' - Produtor';

    if (participant.profile === 'FMC') return ' - Equipe FMC';

    if (participant.profile === 'FOCALPOINT') return ' - Focal Point';

    if (participant.profile === 'PARTICIPANTE') return ' - Participante';

    return '';
  }, [isProducer, participant.profile]);

  const title = getTitle();
  const extraTitle = getExtraTitle();

  return (
    <div>
      <Title>
        {title}
        {extraTitle && <strong>{extraTitle}</strong>}
      </Title>
      {isProducer && (
        <ProducerHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          completeRegister={completeRegister}
        />
      )}
      <PersonalDataForm
        participant={participant}
        editing={editing}
        inputRole={inputRole}
        completeRegister={completeRegister}
        autoIndicate={autoindicate}
        setAutoIndicate={setAutoindicate}
        indicatorCode={indicatorCode}
        actived={activeTab === 'PERSONAL_DATA'}
      />

      {isProducer && (
        <>
          <FarmDataForm actived={activeTab === 'FARM_DATA'} />
          <HarvestDataForm
            handleActionPageButton={() => setActiveTab('SECURITY_DATA')}
            actived={activeTab === 'HARVEST_DATA'}
            completeRegister={completeRegister}
          />
          <SecurityDataForm
            actived={activeTab === 'SECURITY_DATA'}
            inputRole="primary"
          />
        </>
      )}
    </div>
  );
};

export default Form;
