import React, { useState, useEffect } from 'react';
import { Campaign } from 'state/modules/goals/types';
import { fetchCampaignsService } from 'services/goals';
import { ApproverProfile } from 'config/constants';
import SelectProducts from '../../Selects/Products';
import SelectCustomers from '../../Selects/Customers';
import SelectCultures from '../../Selects/Cultures';
import { Container, Field } from './styles';

interface Props {
  myProfile: ApproverProfile;
  actived: boolean;
}

const CustomerGroupAndMetaProduct: React.FC<Props> = ({
  actived,
  myProfile,
}) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const data = await fetchCampaignsService();
      if (data && data.length > 0) {
        setCampaigns(data || []);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <Container style={{ display: actived ? 'block' : 'none' }}>
      <Field>
        <h4>Safra</h4>
        <span>Campanha referente a safra:</span>
        <input type="text" value={campaigns[0]?.title || ''} readOnly />
      </Field>
      <Field>
        <h4>Grupo de cliente</h4>
        <span>
          Escolha um grupo de cliente e veja o saldo disponível sem o debito de
          pedidos no Personal em Processamento.
        </span>
        <SelectCustomers myProfile={myProfile} />
      </Field>
      <Field>
        <h4>Cultura</h4>
        <span>Selecione a CULTURA referente a sua campanha</span>
        <SelectCultures />
      </Field>
      <Field>
        <h4>Produtos</h4>
        <span>
          Selecione os produtos de sua ação e o tipo de indicador que irá
          digitar a META necessária para premiação
        </span>
        <SelectProducts />
      </Field>
    </Container>
  );
};
export default CustomerGroupAndMetaProduct;
