import React from 'react';

import { Crops } from 'services/strategic-plans/actions/interfaces';
import { Container } from './styles';
import Item from './Item';

interface SelectedCultivationListProps {
  selectedCultivation: Crops[];
  removeClickHandler: (id: number) => void;
  canEdit?: boolean;
}

const SelectedCultivationList: React.FC<SelectedCultivationListProps> = ({
  selectedCultivation,
  removeClickHandler,
  canEdit = true,
}) => {
  return (
    <Container>
      {selectedCultivation?.map(crops => (
        <Item
          key={crops.id}
          cultivation={crops}
          removeClickHandler={removeClickHandler}
          canEdit={canEdit}
        />
      ))}
    </Container>
  );
};

export default SelectedCultivationList;
