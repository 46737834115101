import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CheckBoxContent = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;

  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  margin-top: 25px;
  color: #3b302a;
`;

export const InputLabel = styled.label`
  display: grid;

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    line-height: 24px;
  }
`;

export const InputCheckbox = styled.input`
  margin: 0 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const InputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const InputText = styled.input`
  display: grid;
  flex-direction: row;
  grid-auto-columns: 180px;
  justify-content: start;
  height: 40px;
  width: 100%;
  padding: 0 5px;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  color: ${({ theme }) => theme.font.color.primary};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.font.color.quartenary};

  &:focus {
    border-color: #3b302a;
  }
`;

export const LabelWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  width: 80%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    grid-template-columns: 100px 100%;
  }
`;
