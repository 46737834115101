import React, { useCallback } from 'react';
import { formatPointsExtract } from 'util/points';
import { useAuth } from 'context/AuthContext';
import { ExtractButton, ExtractHeader } from './styles';

interface Props {
  piAccess: string;
  isSimulating: boolean;
  points: number;
  isValid?: boolean;
  isProducer?: boolean;
}

const RescuePoints: React.FC<Props> = ({
  piAccess,
  isSimulating,
  points,
  isValid,
  isProducer,
}) => {
  const { participant } = useAuth();

  const handlePiAccess = useCallback(() => {
    if (!piAccess) return;

    const linkClick = document.createElement('a');
    linkClick.href = piAccess;
    linkClick.target = '_blank';
    document.body.appendChild(linkClick);
    linkClick.click();
    document.body.removeChild(linkClick);
  }, [piAccess]);

  const coinText = participant.profile === 'PRODUTOR' ? 'FMC Coins' : 'Pontos';

  return (
    <div>
      <ExtractHeader>
        <p>Saldo disponível para resgate:</p>
        <strong>
          {points !== undefined ? formatPointsExtract(points) : '---'}{' '}
          {coinText}{' '}
        </strong>

        {/* <ExtractButton
          type="button"
          buttonRole="primary"
          onClick={handlePiAccess}
          disabled={
            piAccess === '' ||
            isSimulating ||
            isLoggedFromAdmin ||
            (!isValid && !isProducer)
          }
        > */}
        <ExtractButton
          type="button"
          buttonRole="primary"
          onClick={handlePiAccess}
          disabled={
            piAccess === '' || isSimulating || (!isValid && !isProducer)
          }
        >
          RESGATAR
        </ExtractButton>
      </ExtractHeader>
    </div>
  );
};

export default RescuePoints;
