import routeMap from 'routes/route-map';
import ProducerRegistrator from 'pages/CompleteProducerRegistration';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.completeProducerRegistration,
    component: ProducerRegistrator,
    accessPage: 'Completar cadastro de Produtor',
  },
];

export default routes;
