import { pluginApi } from 'services/api';
import { mapRouletteData } from './mappers/award-mapper';

export type AwardResponseRouletteData = {
  id: number;
  title: string;
  description: string;
  messages: {
    lose: string;
    main: string;
    obs: string;
    try_again: string;
    win: string;
  };
};

export type AwardResponseRouletteAwards = {
  Awards: {
    id: number;
    title: string;
    value: string;
    description: string;
  };
};

export type AwardResponseApi = Array<
  AwardResponseRouletteData | AwardResponseRouletteAwards[]
>;

type Request = {
  roulette_id: number;
};

export async function getRouletteAwards({ roulette_id }: Request) {
  const awardsResponse = await pluginApi.post<AwardResponseApi>('/roulettes', {
    roulette_id,
  });

  const transformedAwards = mapRouletteData(awardsResponse.data);

  return {
    awards: transformedAwards.awards,
    data: transformedAwards.rouletteData,
  };
}
