import styled, { css } from 'styled-components';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface WrapperProps {
  isTerraBrasil: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  .slick-list {
    width: ${props => (props.isTerraBrasil ? '625px' : '435px')};
    position: relative;
    left: ${props => (props.isTerraBrasil ? '-1.5rem' : '-0.5rem')};
  }
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);
  height: 300px;
  width: 100%;
  padding: 40px 10px;

  @media screen and (max-width: 1240px) {
    flex-wrap: wrap;
    height: auto;
  }

  @media screen and (max-width: 1120px) {
    flex-wrap: wrap;
    height: auto;
  }

  @media screen and (max-width: 1080px) {
    flex-wrap: wrap;
    height: auto;
  }

  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    background-color: transparent;
    height: auto;
    box-shadow: none;
  }
`;

interface ProgressWrapperProps {
  noData?: boolean;
}

export const PerformanceWrapper = styled.div<ProgressWrapperProps>`
  ${props =>
    props.noData &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(125px, 2fr));
      gap: 10px;
      width: 95%;
    `}

  ${props =>
    !props.noData &&
    css`
      display: none;
    `}

  @media screen and (max-width: 1320px) {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    width: 100%;

    .slick-list {
        left: 0.5rem;
    }

    .slick-list {
        left: 0.5rem;
    }
  }

  @media screen and (max-width: 1240px) {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    width: 100%;

    .slick-list {
        left: 0.1rem;
        width: 500px;
    }
  }

  @media screen and (max-width: 1120px) {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

    .slick-list {
        left: 0.1rem;
    }
  }

    @media screen and (max-width: 1080px) {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
   }

  @media screen and (max-width: 1020px) {
    flex-wrap: wrap;
    flex-direction: row wrap;
    height: auto;
    max-width: 490px;
  }
`;

export const ProgressWrapperBasis = styled.div<ProgressWrapperProps>`
  ${props =>
    props.noData &&
    css`
      display: flex;
      justify-content: space-around;
    `}
`;

export const ProgressWrapper = styled.div`
  display: flex;
  justify-items: center;
  width: 100%;
  margin-top: 5px;

  @media screen and (max-width: 1010px) {
    flex-wrap: wrap;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    border-radius: 10px;
    flex-wrap: wrap;

    &:first-child {
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: 428px) {
    flex-wrap: wrap;
  }
`;

export const Label = styled.h3<{ $isSpecial?: boolean }>`
  margin-top: ${({ $isSpecial }) => ($isSpecial ? '-1rem' : '0')};
  color: #000000;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 8.9px;
  text-align: center;
  margin: 0.5em 0 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 1em;

  > span {
    font-size: 12px;
    transform: translateY(1px);
  }

  @media screen and (max-width: 1280px) {
  margin-top: ${({ $isSpecial }) => ($isSpecial ? '-1.5rem' : '0')};
    font-size: 10px;
  }

  @media screen and (max-width: 428px) {
    font-size: 10px;
  }
`;

export const IndividualProgressWrapper = styled.div<ProgressWrapperProps>`
  margin: 0.3rem;
  width: 130px;
  ${props =>
    props.noData &&
    css`
      width: 120px;
    `}

  .add-margim {
    position: relative;
    top: -1.2rem;
  }

  > h3:nth-of-type(3) {
    color: red;
  }

  p {
    color: #000000;
    font-size: 8px;
    text-align: center;
    margin-top: 0;
  }

  @media screen and (max-width: 1240px) {
    margin: 0.1rem;
    width: 110px;
  }

  @media screen and (max-width: 1080px) {
    margin: 0.1rem;
    width: 100px;
  }

`;

export const Separator = styled.div`
  width: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 1rem auto;

  @media screen and (max-width: 1320px) {
    margin-top: 1rem;
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
  }

  @media screen and (max-width: 1240px) {
    margin-top: 1rem;
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CompletePerformanceWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    a {
      position: relative;
      top: 1rem;
      left: 20%;
    }
  }

  a {
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.font.color.primary};
    border-radius: 4px;
    justify-content: center;
    text-decoration: none;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};

    svg {
      margin-left: 0.5em;
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const List = styled.ul`
  display: grid;
  flex-direction: column;
  margin: 0 5px;
  margin-right: 7em;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;



export const GoalText = styled.p`
  color: #000000;
  font-size: 12px;
  text-align: center;
  margin-top: 0;
`;

const arrowStyle = css`
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translateY(-20px);
  cursor: pointer;
  z-index: 1;
`;

export const Prev = styled.div`
  ${arrowStyle};
  left: 10px;
`;

export const Next = styled.div`
  ${arrowStyle};
  right: 10px;
`;

export const Dot = styled.div`
  text-decoration: none;

  &:before {
    content: '';
    width: 13px;
    height: 13px;
    background-color: transparent;
    border-radius: 50%;
    display: block;
    border: 1px solid #fff;
    cursor: pointer;
  }
`;

export const CustomSlider = styled(Slider)`
  margin-right: 10px;
  .slick-prev:before,
  .slick-next:before {
    color: green !important;
  }

  width: 420px;
  img {
    width: 100%;
  }

  .slick-dots {
    bottom: 10px;

    .slick-active {
      ${Dot} {
        &:before {
          background-color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 1320px) {
    margin-right: 1px;
  }


  @media screen and (max-width: 1080px) {
    margin-right: 5px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Item = styled.div`
  margin-right: -110px !important;

  &:last-child {
    margin-right: 0 !important;
  }
`;

export const MobileItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-content: center;
`;

export const Goals = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-content: center;
`;
