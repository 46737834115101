import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import DefaultButton from 'components/shared/Button';
import helpHeaderv2 from 'assets/images/strategic-plans/help-header-v2.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #2c2b2b;
  height: 100%;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 20px;
  margin-top: 35px;
  margin-left: 10px;
  position: relative;
  top: 3rem;
  left: 25rem;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    position: relative;
    font-size: 16px;
    top: 3rem;
    left: 8rem;
  }
`;

export const Header = styled.div`
  padding-bottom: 180px;
  background-image: url(${helpHeaderv2});
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
    background-size: cover;
    background-position: bottom;
  }
`;

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    width: 100%;
    min-height: 500px;
    max-height: 100vh !important;
    overflow: auto;
    padding: 0;

    ::-webkit-scrollbar {
      width: 6px;
      background: #f4f4f4;
    }
  }
  @media (min-width: 768px) {
    ._modalContainer {
      max-width: 740px;
      width: 100%;
      min-height: 500px;
      max-height: 100vh !important;
      overflow-y: auto;
      padding: 0;
      overflow-x: hidden;

      ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
      }
    }
  }
`;

export const Separator = styled.div`
  width: 95%;
  height: 2px;
  border-bottom: 1px solid #ffff;
`;

export const Button = styled(DefaultButton)`
  width: 150px;
  height: 45px;
  background-color: #e63027;
  border-radius: 5px;
`;

export const FlowColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 125px;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};

  color: #2c2b2b;
  font-family: 'Helvetica-Neue-Bold';
  font-size: 17px;
  text-align: start;
  margin-top: 5px;
  margin-bottom: 33px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 12px;
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const SubTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};

  width: 100%;
  background-color: #e63027;
  font-family: Helvetica-Neue-Bold;
  font-size: 0.9em;
  margin-top: 10px;
  color: #fff;
  font-size: 1.5rem;
  width: 236px;
  text-align: end;
  position: relative;
  left: -2.2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 165px;
  }
`;

export const ContentsInfo = styled.div`
  display: flex;
  margin-bottom: 3.5px;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #efefef;
  max-width: 700px;
  max-height: 90vh;

  @media (min-width: 768px) {
    img {
      width: 682px;
      position: relative;
      left: -1.5px;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 430px;
    img {
      max-width: 430px;
    }
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: dashed 1px #821c0e;
  width: 185px;

  h4 {
    color: #821c0e;
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  p {
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
