import styled, { css } from 'styled-components';
import plusButton from 'assets/images/indication/plus.svg';
import minusButton from 'assets/images/indication/minus2.svg';

export const Container = styled.div`
  padding: 20px 30px;
  background-color: #e9ece6;
  width: 100%;
  margin-top: 40px;

  .status_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-gap: 10px;
    align-items: center;
    margin-top: 10px;

    @media screen and (max-width: 800px) {
      grid-template-columns: auto 40px;
    }
  }

  > h3 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    text-transform: uppercase;
    margin-right: 10px;
  }
`;

interface AddButtonProps {
  opened: boolean;
}
export const AddButton = styled.button<AddButtonProps>`
  --button-size: 50px;
  width: var(--button-size);
  height: var(--button-size);
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: none;
  background: transparent;
  transition: background 0.2s ease, transform 0.3s;
  background: url(${({ opened }) => (opened ? minusButton : plusButton)}) center
    center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.font.color.primary};
  background-size: calc(var(--button-size) / 2);

  ${({ opened }) =>
    opened &&
    css`
      transform: rotate(180deg);
    `}

  @media screen and (max-width: 800px) {
    --button-size: 40px;
  }
`;

interface StatusIndicatorProps {
  percentActivated: number;
}

export const StatusIndicator = styled.div<StatusIndicatorProps>`
  flex: 1;
  display: flex;
  gap: 5px;

  > span {
    position: relative;

    ._filled {
      rect {
        fill: ${({ theme }) => theme.font.color.primary};
      }
    }
  }

  ._last {
    &::after {
      content: "${({ percentActivated }) => percentActivated}%";
      position: absolute;
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
