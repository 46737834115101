import React, { useState, useRef } from "react";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  isValidFile: (file: File) => boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload, isValidFile }) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && isValidFile(selectedFile)) {
      setFile(selectedFile);
    } else {
      alert("Selecione um arquivo válido (.xlsx)");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleSubmit = () => {
    if (file) {
      onFileUpload(file);
      // Limpa o campo de input e o estado após o upload
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFile(null);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", color: "#999" }}>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <button
        onClick={handleSubmit}
        disabled={!file}
        style={{
          padding: "10px 20px",
          backgroundColor: "#ffcc33",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Enviar
      </button>
    </div>
  );
};

export default FileUpload;