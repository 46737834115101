import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_TEXT,
  CampaignStatus,
} from './interfaces/Campaign';
import getPtStatusText from './util/getPtStatusText';

const data: CampaignStatus[] = [
  {
    id: CAMPAIGN_STATUS.PUBLISHED_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.PUBLISHED_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.PUBLISHED_STATUS),
  },
  {
    id: CAMPAIGN_STATUS.FINISHED_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.FINISHED_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.FINISHED_STATUS),
  },
  {
    id: CAMPAIGN_STATUS.DEVELOPMENT_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.DEVELOPMENT_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.DEVELOPMENT_STATUS),
  },
  {
    id: CAMPAIGN_STATUS.CANCELED_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.CANCELED_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.CANCELED_STATUS),
  },
  {
    id: CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.WAITING_FOR_APPROVAL_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS),
  },

  {
    id: CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.APPROVED_FOR_PUBLICATION_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS),
  },

  {
    id: CAMPAIGN_STATUS.NEW_REQUEST_STATUS,
    statusText: CAMPAIGN_STATUS_TEXT.NEW_REQUEST_STATUS,
    name: getPtStatusText(CAMPAIGN_STATUS.NEW_REQUEST_STATUS),
  },
];

export default async (): Promise<CampaignStatus[]> => {
  return data;
};
