import React from 'react';

import CircularProgress from '../Performance/CircularProgress';
import Title from '../Title';

import {
  TitleWrapper,
  BiologicalBanner,
  Wrapper,
  PerformanceWrapper,
  ProgressWrapper,
  IndividualProgressWrapper,
  Label,
} from './styles';

export interface BiologicalProps {
  invoiceBiologicalPercent: number;
  invoiceBiologicalGoal: number;
  pogBiologicalPercent: number;
  pogBiologicalGoal: number;
  currentCampaign?: string;
}

const BiologicalPerformance: React.FC<BiologicalProps> = ({
  invoiceBiologicalPercent,
  invoiceBiologicalGoal,
  pogBiologicalPercent,
  pogBiologicalGoal,
  currentCampaign,
}) => {
  if (invoiceBiologicalGoal <= 0 || pogBiologicalGoal <= 0) {
    return <></>;
  }
  return (
    <>
      <TitleWrapper>
        <Title>Performance Biológicos FMC - Safra {currentCampaign}</Title>
      </TitleWrapper>
      <Wrapper>
        <BiologicalBanner />
        <PerformanceWrapper>
          <ProgressWrapper>
            <IndividualProgressWrapper>
              <Label>FATURAMENTO</Label>
              <CircularProgress
                color="#FF4C16"
                percent={invoiceBiologicalPercent || 0}
              />
            </IndividualProgressWrapper>
            <IndividualProgressWrapper>
              <Label>POG</Label>
              <CircularProgress
                color="#25CCE1"
                percent={pogBiologicalPercent || 0}
              />
            </IndividualProgressWrapper>
          </ProgressWrapper>
        </PerformanceWrapper>
      </Wrapper>
    </>
  );
};

export default BiologicalPerformance;
