import { all, takeEvery, call, put } from 'redux-saga/effects';

import { handlerErrors } from 'util/handler-errors';

import getSurveysList from 'services/surveys/getSurveysList';
import * as actions from './actions';
import * as constants from './constants';

export function* workerFetchSurveys() {
  try {
    const { available_surveys, performed_surveys } = yield call(getSurveysList);

    yield put(
      actions.fetchSurveysSuccess({
        available: available_surveys,
        performed: performed_surveys,
      }),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchSurveysFailure);
  }
}

export default function* surveysSagas() {
  yield all([takeEvery(constants.FETCH_SURVEYS_ACTION, workerFetchSurveys)]);
}
