import styled from 'styled-components';
import DefaultInput from 'components/shared/Input';
interface Active {
  active?: boolean;
}

export const InputText = styled.input`
    background: #fff;
    border-radius: 0px;
    padding: 0 14px;
    width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #193b4e;
    color: #808285;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    border-color: #193b4e;
    color: ${({ theme }) => theme.font.color.primary};
    border-width: 1px;
    margin-left: 10px;
`;

export const InputOtions = styled.input`
  margin-right: 20px;   
`;

export const OptionsTitle = styled.label<Active>`
  font-weight: bold;
  margin-right: ${(props) => (props.active ? '20px' : '0px')};
`;

export const Input = styled(DefaultInput)`
  max-width: 178px;

  ._inputContainer {
    height: 40px;
  }
`;

export const ExtraInput = styled(Input)`
  max-width: 120px;
  margin-left: 10px;
`;
