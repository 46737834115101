import React, { useState, useEffect, useCallback } from 'react';
import { Participant } from 'services/showcase/interfaces';
import { useAuth } from 'context/AuthContext';
import { ReactSVG } from 'react-svg';
import sendFile from 'services/storage/sendFile';
import changeAvatar from 'services/auth/changeAvatar';
import { PROFILES } from 'config/constants';

import cameraIcon from 'assets/images/showcase/camera.svg';
import transformer, {
  Response as Data,
} from 'services/showcase/transformers/toParticipantTransformer';

import { Container, Content, Circle } from './styles';

interface Props {
  participantData: Participant;
  className?: string;
  showChangePicture: boolean;
  simulating: boolean;
}

const ParticipantInfo: React.FC<Props> = ({
  participantData,
  className,
  showChangePicture,
}) => {
  const [data, setData] = useState<Data | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const { participant, simulating } = useAuth();
  const esocialValid =
    participant.esocial_valid &&
    participant.esocial_validation_status === 'Aprovado';

  useEffect(() => {
    setData(transformer(participantData));
    setImageUrl(participantData.imageUrl || '');
  }, [participantData]);

  const handleAttachFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e && e.target && e.target.files && e.target.files.length > 0) {
        const { url } = await sendFile(e.target.files[0], 'avatar');
        setImageUrl(url);
        changeAvatar({ type: participantData.type, pictureUrl: url });
      }
    },
    [participantData],
  );

  const isFocalTerra =
    participant.profile === PROFILES.focalPoint &&
    participant.establishment.type_name === 'Terra Brasil';

  return (
    <Container className={className}>
      {!!data && (
        <Content>
          <Circle>
            {imageUrl ? (
              <img src={imageUrl} alt={data.name} />
            ) : (
              <span>
                {`${
                  participantData.type === 'cnpj'
                    ? 'Imagem do canal'
                    : 'Imagem do perfil'
                } `}
              </span>
            )}
            {showChangePicture && (
              <div>
                <label htmlFor="fileAvatarId">
                  <input
                    type="file"
                    id="fileAvatarId"
                    accept="image/*"
                    onChange={handleAttachFile}
                  />
                  <ReactSVG src={cameraIcon} />
                </label>
              </div>
            )}
          </Circle>
          <h3>{data.name}</h3>
          <h4>{data.points} pontos</h4>
          {data.type === 'cnpj' && !isFocalTerra ? (
            <>
              {simulating ? (
                <span>Catálogo do canal</span>
              ) : (
                <a
                  href={data.urlAccess}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Catálogo do canal
                </a>
              )}
            </>
          ) : (
            <></>
          )}
          {data.type === 'cpf' && esocialValid ? (
            <>
              {simulating ? (
                <span>Meu catálogo</span>
              ) : (
                <a
                  href={data.urlAccess}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Meu catálogo
                </a>
              )}
            </>
          ) : (
            <></>
          )}
        </Content>
      )}
    </Container>
  );
};

export default ParticipantInfo;
