/* 1	Em Planejamento
2	Em Aprovação
3	Em Ajustes
4	Aprovado
5	Fora do Prazo */

export const strategyPlanStatus = {
  planning: 1,
  onApproval: 2,
  inAdjustments: 3,
  approved: 4,
  outOfTime: 5,
};

export const canEditStatuses = [
  strategyPlanStatus.planning,
  strategyPlanStatus.inAdjustments,
];
