import { pluginApi } from 'services/api';
import { InvoiceDataApi } from './interfaces';

export default async (): Promise<InvoiceDataApi> => {
  try {
    const { data } = await pluginApi.get<InvoiceDataApi>(
      '/participants/uploadNota',
    );
    return data;
  } catch {
    return { invoices: [] };
  }
};
