import React from 'react';

import { Container, Separator } from './styles';

interface Props {
  prizeTitle: string;
  prizeDescription: string;
}

const capitalizeTitle = (text: string) => {
  return text.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase());
};

const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const Prize: React.FC<Props> = ({ prizeTitle, prizeDescription }) => {
  return (
    <Container>
      <h2>Premiações</h2>
      <Separator />
      <h3>{capitalizeTitle(prizeTitle)}</h3>
      <span>{capitalizeText(prizeDescription)}</span>
    </Container>
  );
};

export default Prize;
