export const formatPoints = (
  value: number | string,
  minFractionDigits = 2,
  maxFractionDigits = 2,
) =>
  (value || 0).toLocaleString('pt-BR', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });

export const formatPointsBudget = (
  value: number,
  minFractionDigits = 0,
  maxFractionDigits = 2,
) =>
  value.toLocaleString('pt-BR', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });

export const formatPointsInput = (value: string | number) => {
  if (!Number(value)) return '';

  const amount = formatPoints(Number(value) / 100);

  return `${amount}`;
};

export const formatKglInput = (value: string | number) => {
  if (!Number(value)) return '';

  const amount = formatPoints(Number(value) / 1, 0, 0);

  return `${amount}`;
};

export const formatNumber = (num: any) => {
  if (typeof num !== 'string' && typeof num !== 'number') {
    console.error('O valor fornecido não é uma string ou número válido.');
    return '';
  }

  const numeroComoNumero = parseFloat(String(num).replace(/[^\d.-]/g, ''));

  if (isNaN(numeroComoNumero)) {
    console.error('O valor fornecido não é um número válido.');
    return '';
  }

  return numeroComoNumero.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatLiter = (value: number, maxFractionDigits = 3) => {
  return (value || 0)
    .toLocaleString('en-US', {
      maximumFractionDigits: maxFractionDigits,
    })
    .replace(/,/g, '.');
};

export const formatRebatePointsInput = (value: string | number) => {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(value) / 100);

  return `${amount} pontos`;
};

export const formatPointsExtract = (value: number | string) =>
  value.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

export const formatQuotedCoin = (value: number) =>
  value.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

/*
  Alterado o nome da função para fake, pois o mesmo formata para o PT/BR mesmo agora,
  será necessário refator aqui e quem usa essa func. No momento pela pressa do cliente
  de algo que ja está em produção, apenas foi decidido que toda visualização de dollar
  seria no padrão real mesmo, para nao perder as referencias no momento de onde está mostrando
  os números em Dollar, a função foi renomeada para 'fakeFormarDollar'
*/
export const fakeFormatDollars = (
  value: number,
  minFractionDigits = 2,
  maxFractionDigits = 2,
) => {
  return (value || 0).toLocaleString('pt-br', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });
};

export const formatKgl = (value: number | string) => {
  if (!Number(value)) return '';
  const valueToFormat = typeof value === 'string' ? parseFloat(value) : value;
  return Intl.NumberFormat('pt-br', { maximumSignificantDigits: 3 }).format(
    valueToFormat,
  );
};

export const formatPercent = (value: number) =>
  value.toLocaleString('pt-br', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

export const formatValue = (value: string) => {
  const numericalValue = value.replace(/[^0-9]/g, '');
  const numberValue = Number(numericalValue);
  const formatter = new Intl.NumberFormat('pt-BR');
  const formattedValue = formatter.format(numberValue);
  return formattedValue;
};
