import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';
import DefaultModal from 'components/shared/Modal';

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 40px 0;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: baseline;

  .simple-button {
    color: ${({ theme }) => theme.font.color.primary};
    background: none;
    text-transform: uppercase;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 250px;
  height: 50px;
  background-color: transparent;
  border: 2px solid;
  color: ${({ theme }) => theme.font.color.primary};
  text-transform: uppercase;
  margin-left: 20px;

  ._loading {
    svg {
      fill: ${({ theme }) => theme.font.color.primary};
      margin-top: 1px;
    }
  }

  &:hover {
    color: #fff;
    ._loading {
      svg {
        fill: #fff;
        margin-top: 1px;
      }
    }
  }
`;

export const Main = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: ${({ theme }) => theme.font.color.primary};
    color: #fff;

    ._loading {
      svg {
        fill: #fff;
        margin-top: 1px;
      }
    }

    &:hover {
      color: #fff;

      ._loading {
        svg {
          fill: #fff;
          margin-top: 1px;
        }
      }
    }
  }
`;

export const Modal = styled(DefaultModal)`
  text-align: center;

  ._modalContainer {
    padding: 0;
    max-width: 590px;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.modal.primary.backgroundColor};
    color: ${({ theme }) => theme.font.color.primary};
    max-height: 300px;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;

  .content-modal {
    margin-left: 2rem;

    h3 {
      text-align: left;
      font-size: 1.1rem;
    }
  }

  h2 {
    margin: 2rem;
    text-align: left;
    font-size: 1.3rem;
    font-family: 'Helvetica-Neue-medium';
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Content = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  margin-bottom: 0.5rem;

  > button {
    width: 166px;
    height: 30px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 11px;
  }

  .register {
    background: ${({ theme }) => theme.font.color.primary};
    color: #fff;
    margin-left: 1rem;
  }

  .cancel {
    background: transparent;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: #000;
    }
  }
`;
