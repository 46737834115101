import {
  ApproverProfile,
  EstablishmentTypes,
  IProfile,
} from 'config/constants';

interface Address {
  id?: number;
  zip_code: string;
  public_place: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state_code: string;
}

interface Role {
  id: number;
  identifier: string;
  name: string;
  expiratedPassword: boolean;
  expiratedPasswordMsg?: string;
  complete_registration: {
    complete: boolean;
    missing: {
      cultivo: boolean;
      grupo_fazenda: boolean;
    };
  };
}

export interface Establishment {
  id: number;
  name: string;
  cnpj: string;
  type_name: EstablishmentTypes;
  type_id: number;
  category: 'Água' | 'Semente' | 'Terra' | 'Raiz' | 'Trader' | 'Fechada';
  rtc_id: number | null;
  rtc_name: string | null;
  team_receives_points: boolean;
  client_group: string;
  biological: boolean;
  classificacao: string;
}

interface Subsidiary {
  id: number;
  name: string;
  city: string;
}

export interface Regulation {
  regulation_id: string;
  version: string;
  policyExposed?: string;
}

export enum MemberType {
  farm = 'fazenda',
  family = 'familia',
  partner = 'socio',
  principal = 'matriz',
  branch = 'filial',
  agriculture = 'agropecuaria',
}

export interface MemberGroup {
  id?: number;
  type: MemberType | string;
  cpf_cnpj: string;
  name: string;
  ie: string;
  city: string;
  uf: string;
  farm_term_accepted?: boolean;
  group_name?: string;
  created?: string;
}

export interface Harvest {
  algodao: string;
  arroz_irrigado: string;
  batata: string;
  cafe: string;
  cana: string;
  cenoura: string;
  cevada: string;
  citrus: string;
  feijao: string;
  mandioca: string;
  melao: string;
  milho: string;
  outras: string;
  outras_quais: string;
  soja: string;
  tabaco: string;
  tomate: string;
  trigo: string;
  uva: string;
  hectares: string;
}

export interface Participant {
  id: number;
  cpf: string;
  state_registration: string;
  rg: string;
  name: string;
  email: string;
  picture: string;
  cell_phone: string;
  birth_date: string;
  created: string; // "2020-06-10T14:47:34-03:00";
  gender: string;
  area_code: string;
  upn: string;
  nick_name: string;
  nationality: string;
  place_of_birth: string;
  // marital_status: string;
  rg_emitter: string;
  rg_emitter_uf: string;
  pis_nis: string;
  department: string;
  access_premio_ideall: boolean;
  profile: IProfile;
  address: Address;
  role: Role;
  establishment: Establishment;
  subsidiary: Subsidiary;
  password?: string;
  password_confirmation?: string;
  // education_level: string;
  regulations_accepted: Regulation[];
  profile_value: ApproverProfile;
  campaign_id: number;
  get_to_know: string;
  medium: string;
  only_farm: boolean;
  user_farm_agree: boolean;
  status: number;
  indicator_code_used: string;
  indicator_code: string;
  mother_name: string;
  father_name: string;
  uf_naturalness: string;
  naturalness: string;
  marital_status_code: string;
  code_schooling: string;
  shipping_date: string;
  voter_registration: string;

  // Producers
  producer_group_name: string;
  members_group: MemberGroup[];
  harvest: Harvest;
  registration_origin?: string;
  other_channels?: string;

  // validação e-social
  esocial_valid: boolean;
  esocial_validation_status: string;
  automatic_nf: boolean;
}
