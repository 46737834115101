import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 40px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;

  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const AnswerRow = styled.div`
  display: grid;
  grid-template-columns: minmax(80px, 120px) minmax(80px, 1fr);
  column-gap: 5px;
  min-height: 50px;
  align-items: center;
  padding-top: 2px;
  margin: 10px 0 10px 5px;
  color: ${({ theme }) => theme.font.color.secondary};
  font-family: ${({ theme }) => theme.font.fontFamily.condensed};
  font-size: 14px;
`;
