import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 25px 10px;
  text-align: center;
`;

export const ActionsModalContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  justify-content: center;
  padding: 5px 15px;
  width: 100%;

  button {
    margin: 0;
    max-width: 200px;
    height: auto;
    padding: 9px 25px;
    font-size: 14px;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};

    &:disabled {
      opacity: 0.5;
    }
  }

  // Cancel btn
  button:first-child {
    color: ${({ theme }) => theme.font.color.primary};
  }

  // Confirm btn
  button:last-child {
    background-color: ${({ theme }) => theme.font.color.primary};
    color: #fff;
  }
`;
