import React, { useCallback, useEffect, useState } from 'react';
import { Product } from 'state/modules/points-simulator/interfaces';

import { useSelector } from 'react-redux';
import {
  getProducts,
  getDollarBaseValue,
  getConfiguration
} from 'state/modules/points-simulator/selectors';
import Table from 'components/PointsSimulator/Calculator/Table';
import { Tab } from 'components/PointsSimulator/Calculator/Header';
import DollarPanel from 'components/PointsSimulator/Calculator/Footer';
import { Container, Content, Item } from './styles';

import Result from '../Result';

const Pdf = React.forwardRef<HTMLDivElement>((props, ref) => {
  const products = useSelector(getProducts);
  const dollarBaseValue = useSelector(getDollarBaseValue);
  const configuration = useSelector(getConfiguration);
  const [enhancerProducts, setEnhancerProducts] = useState<Product[]>([]);
  const [participantProducts, setParticipantProducts] = useState<Product[]>([]);
  const [biologicalProducts, setBiologicalProducts] = useState<Product[]>([]);
  const [quantityItemsAdded, setQuantityItemsAdded] = useState(0);

  const isValidProductToPrint = useCallback((product: Product): boolean => {
    const {
      unitValueInDollar,
      revenuesInKilosPerLiter,
      pogInKilosPerLiter,
    } = product.simulationData;
    return (
      unitValueInDollar > 0 &&
      revenuesInKilosPerLiter > 0 &&
      pogInKilosPerLiter > 0
    );
  }, []);

  useEffect(() => {
    if (!products) return;

    const validItems = products.filter(item => isValidProductToPrint(item));

    setEnhancerProducts(validItems.filter(item => item.isEnhancer));

    setParticipantProducts(validItems.filter(item => !item.isEnhancer));

    setBiologicalProducts(validItems.filter(item => item.isBiological));

    setQuantityItemsAdded(validItems.length);
  }, [products, isValidProductToPrint]);

  const fn = () => null;

  return (
    <Container ref={ref} id="_container-pdf">
      <Content>
        <Result />
        <DollarPanel
          changeDollarBaseValue={fn}
          dollarBaseValue={dollarBaseValue}
          handleButtonAction={fn}
          buttonActionText=""
          quantityItemsAdded={quantityItemsAdded} 
          GroosNet={configuration?.pogRealizedNetPercentage}        
        />
        {enhancerProducts.length > 0 && (
          <>
            <Item>{Tab.enhancerSegmentTab}</Item>
            <Table
              products={enhancerProducts}
              setRevenuesInKilosPerLiter={fn}
              setUnitValueInDollar={fn}
              setPogInKilosPerLiter={fn}
              tabSelected={Tab.enhancerSegmentTab}
              onCheckUncheckProductHandle={fn}
              onCheckUncheckProductHandleEnhancer={fn}
              channelId="0"
            />
          </>
        )}
        {participantProducts.length > 0 && (
          <>
            <Item>{Tab.participateProductsTab}</Item>
            <Table
              products={participantProducts}
              setRevenuesInKilosPerLiter={fn}
              setUnitValueInDollar={fn}
              setPogInKilosPerLiter={fn}
              tabSelected={Tab.participateProductsTab}
              onCheckUncheckProductHandle={fn}
              onCheckUncheckProductHandleEnhancer={fn}
              channelId="0"
            />
          </>
        )}
        {biologicalProducts.length > 0 && (
          <>
            <Item>{Tab.participateBiologicosTab}</Item>
            <Table
              products={biologicalProducts}
              setRevenuesInKilosPerLiter={fn}
              setUnitValueInDollar={fn}
              setPogInKilosPerLiter={fn}
              tabSelected={Tab.participateBiologicosTab}
              onCheckUncheckProductHandle={fn}
              channelId="0"
              onCheckUncheckProductHandleEnhancer={function ({ id, enhancersChecked, }: { id: number; enhancersChecked: boolean; }): void {
                throw new Error('Function not implemented.');
              } }            
              />
          </>
        )}
      </Content>
    </Container>
  );
});

export default Pdf;
