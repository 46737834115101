/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react';

import { getCampaigns, getInvoicesByCampaignId } from 'services/nf';
import { ExtractCampaign, InvoiceData } from 'services/nf/interfaces';
import orderBy from 'lodash.orderby';
import { formatPoints } from 'util/points';

import { pluginApi } from 'services/api';
import {
  Container,
  Content,
  Banner,
  Header,
  Toggle,
  InfoBanner,
  Select,
} from './styles';
import ListDetails from './List/ListDetails';

import AddNF from './AddNF';

const Receipts: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [invoicesList, setInvoicesList] = useState<InvoiceData[]>([]);
  const [loading, setLoading] = useState(false);

  const [invoiceStatus, setInvoiceStatus] = useState({
    analysis: 0,
    freedom: 0,
    discredit: 0,
    wainting_aprovation: 0,
  });

  const [campaigns, setCampaigns] = useState<ExtractCampaign[]>([]);
  const getCampaign = campaigns.map(campaign => campaign.title)[1];

  const currentCampaign = campaigns.map(campaign => campaign.title)[0];

  const [select, setSelect] = useState(getCampaign);

  const getInfoCampaign = campaigns
    .map(campaign => campaign)
    .filter(
      campaignSelected =>
        campaignSelected.title === (select || currentCampaign),
    );

  useEffect(() => {
    const fetchCampaigns = async () => {
      const result = await getCampaigns();
      const filtered = result.filter(item => item.id !== 2);
      const ordered = orderBy(filtered, ['title'], ['desc']);
      setCampaigns(ordered);
    };

    const getStatusQuantity = async () => {
      const { data } = await pluginApi.get(
        `participants/upload-nota?campaign_id=${
          getInfoCampaign[0]?.id || currentCampaign
        }`,
      );
      setInvoiceStatus(data.invoice_status_quantity);
    };

    getStatusQuantity();
    fetchCampaigns();
  }, [select]);

  const toggleClickHandler = useCallback(async (campaignId: number) => {
    setIsOpen(current => !current);
    setLoading(true);
    const invoices = await getInvoicesByCampaignId(campaignId);
    setInvoicesList(invoices);
    setLoading(false);
  }, []);

  useEffect(() => {
    toggleClickHandler(getInfoCampaign[0]?.id);
    setIsOpen(current => !current);
  }, [select]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Container>
      <Banner>
        <InfoBanner>
          <h3>Cadastre suas notas fiscais e garanta seus FMC Coins</h3>
          <div className="container-nf">
            <div className="select">
              <Select
                name="select"
                value={select}
                onChange={e => setSelect(e.target.value)}
              >
                {campaigns.map(selectCampaign => (
                  <option
                    key={selectCampaign.title}
                    value={selectCampaign.title}
                  >
                    Safra {selectCampaign.title}
                  </option>
                ))}
              </Select>
              <p>
                Em análise................................................
                {Number(invoiceStatus.analysis) +
                  Number(invoiceStatus.wainting_aprovation) || 0}
              </p>
              <p>
                Liberadas..................................................
                {invoiceStatus.freedom || 0}
              </p>
              <p>
                Descredenciadas......................................
                {invoiceStatus.discredit || 0}
              </p>
            </div>
            <AddNF layout="secondary" />
          </div>
        </InfoBanner>
      </Banner>
      <Content>
        <Container>
          <Header>
            <div>
              <h3>Safra</h3>
              <h4>{getInfoCampaign[0]?.title || currentCampaign}</h4>
            </div>
            <span />
            <div>
              <h3>Total</h3>
              <h4>{formatPoints(getInfoCampaign[0]?.total)} FMC Coins</h4>
            </div>
            <Toggle
              onClick={() => toggleClickHandler(getInfoCampaign[0]?.id)}
              isOpen={isOpen}
            />
          </Header>
          {isOpen && <ListDetails items={invoicesList} loading={loading} />}
        </Container>
      </Content>
    </Container>
  );
};

export default Receipts;
