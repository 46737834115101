import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { getPlanAuthor as getData } from 'services/strategic-plans/actions';
import { BaseSelect } from 'components/StrategicPlans/ActionsForms/shared/styles';
import { StrategicPlan } from 'services/strategic-plans/interfaces';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  plans: StrategicPlan[];
}

const PlanAuthor: React.FC<Props> = ({
  className,
  value,
  setValue,
  placeholder,
  plans,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData().then(data => {
      const filteredOptions = data.filter(option =>
        plans.some(
          plan =>
            plan.participantName?.toLowerCase() === option.title.toLowerCase(),
        ),
      );

      filteredOptions.sort((a, b) => a.title.localeCompare(b.title));

      setOptions(filteredOptions);
    });
  }, [plans]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <BaseSelect
      label="Autor do Plano"
      loadItems={loadItems}
      className={className}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      inputRole="primary"
    />
  );
};

export default PlanAuthor;
