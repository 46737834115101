/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { fetchCampaignsService } from 'services/goals';
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from 'react-icons/ai';
import { Campaign } from 'state/modules/goals/types';
import { Container } from './styles';

interface CampaignsListProps {
  selectedCampaignId?: number;
  selectCampaign: (campaignId: number) => void;
}

const Campaigns: React.FC<CampaignsListProps> = ({ selectCampaign }) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const itemsPerPage = 1;

  const isFirstRender = useRef(true);

  // Busca campanhas e aplica o filtro
  useEffect(() => {
    const fetchCampaigns = async () => {
      const data = await fetchCampaignsService();
      data?.reverse();

      const filteredData = data?.filter(
        campaign => !['19/20', '20/21', '21/22', '2023', '2024', '22/23'].includes(campaign.title),
      );

      setCampaigns(filteredData || []);

      if (filteredData?.length) {
        setCurrentIndex(filteredData.length - 1); // Inicializa no último índice
      } else {
        setCurrentIndex(-1); // Nenhuma campanha disponível
      }

      isFirstRender.current = false;
    };
    fetchCampaigns();
  }, []);

  // Atualiza a campanha selecionada
  useEffect(() => {
    if (campaigns.length > 0 && currentIndex !== -1) {
      console.log('Selecionando campanha:', campaigns[currentIndex]);
      selectCampaign(campaigns[currentIndex].id);
    }
  }, [campaigns, currentIndex, selectCampaign]);

  // Obtém itens da página atual
  const getCurrentPageItems = useCallback(() => {
    if (!campaigns) return [];
    const current = currentIndex;
    const last = current + itemsPerPage;
    return campaigns.slice(current, last);
  }, [campaigns, currentIndex]);

  const currentPageItems = getCurrentPageItems();

  // Navegação
  const handleNextClick = useCallback(() => {
    if (!campaigns) return;
    const lastIndex = campaigns.length - itemsPerPage;
    if (currentIndex === lastIndex) return;
    setCurrentIndex(prevIndex => Math.min(prevIndex + itemsPerPage, lastIndex));
  }, [campaigns, currentIndex]);

  const handlePrevClick = useCallback(() => {
    if (currentIndex === 0) return;
    setCurrentIndex(prevIndex => Math.max(prevIndex - itemsPerPage, 0));
  }, [currentIndex]);

  // Renderização condicional
  if (campaigns.length === 0 || currentIndex === -1) {
    return <p>Nenhuma campanha disponível.</p>;
  }

  return (
    <Container>
      {!!currentPageItems &&
        currentPageItems.map(campaign => (
          <div className="slider" key={campaign.id}>
            <button
              disabled={currentIndex === 0}
              onClick={handlePrevClick}
              type="button"
              title="Safra anterior"
            >
              <AiOutlineDoubleLeft /> ANTERIOR
            </button>
            <span>{`Safra ${campaign.title}`}</span>
            <button
              disabled={currentIndex === campaigns.length - 1}
              onClick={handleNextClick}
              type="button"
              title="Próxima safra"
            >
              PRÓXIMA <AiOutlineDoubleRight />
            </button>
          </div>
        ))}
    </Container>
  );
};

export default Campaigns;
