import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import { Button } from 'components/shared';
import {
  Campaign,
  Mechanic,
} from 'services/campaigns-manager/interfaces/Campaign';
import { useDispatch } from 'react-redux';
import getData from 'services/campaigns-manager/getMechanics';
import { useToast } from 'context/ToastContext';
import ReactLoading from 'react-loading';
import {
  setMechanicID,
  updateStatus,
} from 'state/modules/campaigns-manager/actions';
import {
  Close,
  Container,
  Content,
  Modal,
  Alert,
  Card,
  CardImage,
} from './styles';

interface ChangeMechanicsProps {
  onClose: () => void;
  isOpen: boolean;
  campaign: Campaign;
}

const ChangeMechanicsModal: React.FC<ChangeMechanicsProps> = ({
  onClose,
  isOpen,
  campaign,
}) => {
  const [mechanics, setMechanics] = useState<Mechanic[]>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    setLoading(true);
    getData()
      .then(data => {
        setMechanics(data);
        setLoading(false);
      })
      .catch(_ => {
        addToast({
          title: 'Erro ao carregar campanhas, por favor contate o admnistrador',
        });
      });
  }, [addToast]);

  const handleChangeMechanics = async (id: number, name: string) => {
    try {
      const updatedCampaign = {
        ...campaign,
        mechanic: {
          id,
          name,
        },
      };

      await dispatch(setMechanicID(id));
      dispatch(updateStatus(updatedCampaign));

      onClose();
    } catch (e) {
      addToast({
        title: 'Falha ao selecionar mecânica. Por favor contate o suporte.',
        type: 'error',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} type="primary">
      <Container>
        <Close>
          <button type="button" onClick={onClose}>
            <ReactSVG src={closeIcon} />
          </button>
        </Close>
        <Content>
          <h3>Escolha o tipo de mecânica da sua campanha</h3>

          {loading && (
            <Alert>
              <ReactLoading
                type="bars"
                color="#8d8d8d"
                height={24}
                width={24}
              />
            </Alert>
          )}

          {mechanics.map(mechanic => (
            <Card key={mechanic.id}>
              <CardImage>
                <img src={mechanic.internalImage} alt="logo-campaign" />
              </CardImage>
              <div>
                <h1>{mechanic.name}</h1>
                <p>{mechanic.description}</p>
              </div>
              <Button
                buttonRole="primary"
                type="button"
                onClick={() =>
                  handleChangeMechanics(mechanic.id, mechanic.name)
                }
              >
                SELECIONAR
              </Button>
            </Card>
          ))}
        </Content>
      </Container>
    </Modal>
  );
};

export default ChangeMechanicsModal;
