import styled from 'styled-components';
import background from 'assets/images/background.svg';
// import backgroundHeader from 'assets/images/background-header.svg';

export const RegulationContent = styled.div`
  max-height: calc(100% - 161px);
`;

export const Container = styled.div`
  width: 100%;
  //height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${background});
  position: absolute;
  z-index: 50;
  ${RegulationContent} {
    max-width: 1100px;
  }
  top: 0;
  left: 0;
`;

/* export const Header = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundHeader});
  > img {
    width: 364px;
  }

  @media screen and (max-width: 500px) {
    > img {
      width: 280px;
    }
  }
`; */
