import styled, { css } from 'styled-components';

import { EstablishmentTypes } from 'config/constants';
import { Button as DefaultButton } from 'components/shared';
import backGraph from '../../../assets/images/extract/backgroundGraphPoints.png';
import backGraph1 from '../../../assets/images/extract/backgroundGraphPoints_1.png';

interface ContainerProps {
  userType: EstablishmentTypes;
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  ${({ userType }) =>
    userType === EstablishmentTypes.Resale &&
    `background: url(${backGraph}), url(${backGraph1}),
    linear-gradient(#395389 0%, #395389 100%) 0% 0% no-repeat padding-box});`};
  ${({ userType }) =>
    userType === EstablishmentTypes.Cooperative &&
    `background: url(${backGraph}), url(${backGraph1}),
    linear-gradient(#2a4207fc 0%, #4a7b03 100%) 0% 0% no-repeat padding-box});`};
  ${({ userType }) =>
    userType === EstablishmentTypes.TerraBrasil &&
    `background: url(${backGraph}), url(${backGraph1}),
linear-gradient(#2a4207fc 0%, #4a7b03 100%) 0% 0% no-repeat padding-box});`};
  color: ${({ theme }) => theme.font.color.secondary};
  display: flex;
  background-position: bottom left, bottom center;
  background-repeat: no-repeat, no-repeat, no-repeat;
`;

export const AccumulatedBalance = styled.div`
  flex: 4;
  padding: 18px 15px 30px 27px;

  h2 {
    color: ${({ theme }) => theme.font.color.tertiary};
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    font-size: 16px;
    margin-bottom: 20px;

    strong {
      font-size: 28px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }
  }
`;

export const BalanceBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, max-content));
  grid-gap: 5px;
`;

export const BalanceItem = styled.div`
  background: ${({ theme }) => theme.layout.secondary.backgroundColor};
  color: ${({ theme }) => theme.font.color.primary};
  padding: 22px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .title {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    line-height: 20px;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
  }

  .value {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 26px;
    text-align: center;
    color: ${({ theme }) => theme.font.color.primary};
  }

  @media only screen and (max-width: 495px) {
    display: flex;

    .title {
      font-size: 13px;
      flex-wrap: wrap;
    }

    .value {
      font-size: 18px;
    }
  }
`;

export const CalltoActionContainer = styled.div`
  flex: 1.4;
`;

export const CalltoActionBox = styled.div`
  background: ${({ theme }) => theme.layout.secondary.backgroundColor};
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
  min-height: 150px;
  padding: 0 0 10px 0;

  span {
    display: block;
    text-align: center;
  }
  button {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
    height: 42px;
    max-width: 130px;
    margin: auto;
  }
  .available {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 14px;
    padding: 10px 10px 15px 15px;
    strong {
      display: block;
      font-size: 18px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }
  }
  .shared-actions {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.regular};
    font-size: 14px;
    padding: 15px;

    > span {
      text-align: left;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 40px 0;
`;

export const Title = styled.div`
  margin: 25px 0 5px 0;

  strong {
    display: block;
    font-size: 24px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};

    @media screen and (max-width: 420px) {
      font-size: 21px;
    }
  }
`;

export const Subtitle = styled.div`
  margin-top: 5px;
  span {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily};

    @media screen and (max-width: 420px) {
      font-size: 14px;
      justify-content: start;
      text-align: left;
    }
  }
`;

export const HeaderTabWrapper = styled.div`
  margin-top: 45px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  overflow: hidden;
`;

interface HeaderTabProps {
  isSelected: boolean;
}

export const HeaderTab = styled.button<HeaderTabProps>`
  position: relative;
  opacity: 1;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  border-radius: 12px 12px 0 0;
  color: ${({ theme }) => theme.font.color.primary};
  min-width: 140px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

  &:nth-child(n + 2) {
    margin-right: -12px;
    padding: 10px 4px 10px 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.font.color.primary};
    color: ${({ theme }) => theme.font.color.tertiary};
    font-weight: bold;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.font.color.primary};
      color: ${({ theme }) => theme.font.color.tertiary};
      font-weight: bold;
    `}
`;

export const RescueBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 10px;
  margin-top: 10px;
`;

export const RescueBox = styled.div`
  background: #efefef;
  position: relative;
  border-radius: 5px;
  color: ${({ theme }) => theme.font.color.primary};
  margin: 5px;
  padding: 20px;

  &.catalogo-premios {
    display: flex;
    justify-content: space-between;

    p {
      width: max-content;
    }
  }

  .title {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    line-height: 20px;
    margin-top: 15px;
    min-height: 40px;
    text-align: center;
  }
  .value {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 18px;
    text-align: center;
  }

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 22px;
    text-align: center;
    color: #000;
  }

  p {
    margin-bottom: 5px;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  div {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 0.9em;
    width: 300px;
    height: 120px;
    background: #707070;
    color: white;
    position: absolute;
    margin-left: 10px;
    margin-top: -150px;
    border-radius: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px #00000050;

    @media only screen and (max-width: 1800px) {
      margin-left: -190px;
    }

    p {
      width: 250px;
      text-align: justify;
    }
  }

  &:hover div {
    display: flex;
  }
`;

export const CustomButton = styled(DefaultButton)`
  max-width: 140px;
  margin-top: 0;
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 20px;

  span {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    color: #707070;
    opacity: 0.7;
  }
`;
