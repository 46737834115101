export default {
  primary: {
    thead: {
      tr: {
        backgroundColor: '#193f4e',
        color: '#fff',
      },
    },
    tbody: {
      tr: {
        borderColor: '#fff',
        borderWidth: '8px',
        color: '#193f4e',
      },
      td: {
        backgroundColor: '#f5f5f5',
      },
    },
  },
  secondary: {
    thead: {
      tr: {
        backgroundColor: '#fff',
        color: '#232129',
      },
    },
    tbody: {
      tr: {
        borderColor: '#f5f5f5',
        borderWidth: '8px',
        color: '#707070',
      },
      td: {
        backgroundColor: '#fff',
      },
    },
  },
};
