import React from 'react';
import { Product as IProduct } from 'services/campaigns/getCampaign';

import { Container, Separator, Box, Table, Th, Td } from './styles';

interface Props {
  data: IProduct[];
}

const Product: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <h2>Aceleradores</h2>
      <Separator />
      {data.map(item => (
        <Table>
          <Box>{item.title}</Box>

          <thead>
            <tr>
              <Th />
              <Th />
              <Th>Meta</Th>
              <Th>Realizado</Th>
            </tr>
          </thead>
          <tbody>
            <>
              {/* <tr>
                <Td className="box">
                  <Box>{item.title}</Box>
                </Td>
              </tr> */}
              {item.volume && (
                <tr>
                  <Td className="box" />
                  <Td>
                    <span>Volume</span>
                  </Td>
                  <Td>{item.volume}</Td>
                  <Td>{item.partialVolume}</Td>
                </tr>
              )}
              {item.sellIn && (
                <tr key={`item-${item.id}`}>
                  <Td className="box" />
                  <Td>
                    <span>Faturamento</span>
                  </Td>
                  <Td>{item.sellIn}</Td>
                  <Td>{item.partialSellIn}</Td>
                </tr>
              )}
              {item.sellOut && (
                <tr key={`item-${item.id}`}>
                  <Td className="box" />
                  <Td>
                    <span>POG</span>
                  </Td>
                  <Td>{item.sellOut}</Td>
                  <Td>{item.partialSellOut}</Td>
                </tr>
              )}
              {item.expectedPortfolioPog && (
                <tr key={`item-${item.id}`}>
                  <Td className="box" />
                  <Td>
                    <span>POG de Carteira</span>
                  </Td>
                  <Td>{item.expectedPortfolioPog}</Td>
                  <Td />
                </tr>
              )}
            </>
          </tbody>
        </Table>
      ))}
    </Container>
  );
};

export default Product;
