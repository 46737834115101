import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container, TextArea } from './styles';

interface QuestionGlobalProps {
  question: string;
  id: number;
  name?: string;
  answerId?: number;
  answers: AnswerData[];
  canEdit: boolean;
}

const QuestionGlobal: React.FC<QuestionGlobalProps> = ({
  question,
  id,
  answerId,
  name,
  answers,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch);
    }
  }, [answers, canEdit, dispatch, id]);

  useEffect(() => {
    const [answer] = answers;

    if (answer) {
      const { survey_participant_answers } = answer;
      setValue(
        survey_participant_answers.length > 0
          ? survey_participant_answers[0].answer
          : '',
      );
    }
  }, [answers]);

  return (
    <Container>
      <p>{question}</p>
      <TextArea
        name={name}
        placeholder="Insira os dados necessários"
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={() =>
          dispatch(
            setValueAnswer({
              answerValue: value,
              questionId: Number(id),
              answerId: Number(answerId),
              deleteOperation: !value,
            }),
          )
        }
        disabled={!canEdit}
      />
    </Container>
  );
};

export default QuestionGlobal;
