import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import { Button as DefaultButton } from 'components/shared';
import backgroundCoins2 from 'assets/images/fmcProdutor/NF/coins-desktop--2.svg';

export const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 75px;
  background-color: transparent;

  .coinsModal1 {
    z-index: 1;
    position: absolute;
    max-width: 180px;
    margin-left: 500px;
    margin-top: 300px;
  }

  .coinsModal2 {
    z-index: 1;
    position: absolute;
    max-width: 150px;
    margin-left: 550px;
    margin-top: 2px;
  }

  @media (min-width: 768px) {
    background: url(${backgroundCoins2});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 90% auto;
  }

  @media (max-width: 420px) {
    .coinsModal1 {
      display: none;
    }
    .coinsModal2 {
      display: none;
    }

    background: none;
`;

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 750px;
    max-height: 450px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
  }

  @media (max-width: 420px) {
    max-width: 320px;
    max-height: 300px;
  }
`;

export const Title = styled.h3`
  margin-top: 22px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 1.6em;
  text-align: center;
  background-color: transparent;

  @media (max-width: 420px) {
    font-size: 1.2em;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding: 0 20px;
  /* background-color: transparent; */

  > img {
    margin-top: 45px;
    max-width: 140px;

    .arrow {
      max-width: 40px;
    }

    .ImgStep1 {
      max-width: 160px;
    }
  }

  > span {
    margin-top: 5px;
    max-width: 210px;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  @media (max-width: 420px) {
    .arrow {
      display: none;
    }
    .ImgStep1 {
      display: none;
    }
    img {
      display: none;
    }
    span {
      justify-content: none;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  max-width: 220px;
  height: 48px;
  background: #65554d;
  color: #fff;

  &:hover {
    background: #65554d;
    filter: grayscale(20%);
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;
