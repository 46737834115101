/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { Container, AnswerItem, IconWrapper } from './styles';

interface CustomizedRatingProps {
  question: string;
  answers: AnswerData[];
  id: number;
  canEdit: boolean;
}

const CustomizedRating: React.FC<CustomizedRatingProps> = ({
  question,
  answers,
  id,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const [currentAnswer, setCurrentAnswer] = useState('');

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'unique' });
    }
  }, [answers, canEdit, dispatch, id]);

  useEffect(() => {
    const answered = answers.find(
      answer => answer.survey_participant_answers.length > 0,
    );

    if (answered) {
      setCurrentAnswer(answered.survey_participant_answers[0].answer);
    }
  }, [answers]);

  const getIcon = useCallback((text: string) => {
    switch (text) {
      case 'Indiferente':
        return <SentimentSatisfiedIcon fontSize="large" />;
      case 'Satisfeito':
        return <SentimentSatisfiedAltIcon fontSize="large" />;
      case 'Insatisfeito':
        return <SentimentDissatisfiedIcon fontSize="large" />;
      case 'Pouco Satisfeito':
        return <SentimentVeryDissatisfiedIcon fontSize="large" />;
      default:
        return <SentimentVerySatisfiedIcon fontSize="large" />;
    }
  }, []);

  const setAnswer = useCallback(
    (answerValue: string, answerId: number) => {
      setCurrentAnswer(answerValue);
      dispatch(
        setValueAnswer({
          answerValue,
          questionId: id,
          answerId,
          multipleAnswers: 'unique',
        }),
      );
    },
    [dispatch, id],
  );

  return (
    <Container>
      <p>{question}</p>
      <div>
        {answers.map(answer => (
          <AnswerItem key={answer.id}>
            <label>
              {answer.answer}
              <input
                type="radio"
                name={`name-${id.toString}`}
                id={`id-${id.toString}`}
                value={answer.answer}
                onChange={() => setAnswer(answer.answer, answer.id)}
                defaultValue={currentAnswer}
                disabled={!canEdit}
              />
              <IconWrapper selected={answer.answer === currentAnswer}>
                {getIcon(answer.icon_attributes.name)}
              </IconWrapper>
            </label>
          </AnswerItem>
        ))}
      </div>
    </Container>
  );
};

export default CustomizedRating;
