import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getHasRegisterToComplete } from 'state/modules/home/selectors';
import { useSpring } from 'react-spring';
import {
  Regulation,
  RegulationPolicy,
} from 'services/register/regulation/interfaces/IRegulation';
import getDataRegulation from 'services/register/regulation/getDataRegulation';
import getRegulationPolicy from 'services/register/regulation/getRegulationPolicy';
import { PROFILES } from 'config/constants';
import NoResults from 'components/shared/NoResults';

import DataRegulation from '../DataTermRegulation';
import {
  Container,
  Content,
  contentAnimation,
  Message,
} from '../shared/styles';
import Form from './Form';
import Header from '../shared/Header';

const CompleteRegister: React.FC = () => {
  const hasRegisterToComplete = useSelector(getHasRegisterToComplete);
  const [showDataRegulation, setShowDataRegulation] = useState(true);
  const [dataRegulation, setDataRegulation] = useState<Regulation | null>(null);
  const [regulationPolicy, setRegulationPolicy] = useState<
    RegulationPolicy | undefined
  >(undefined);
  const [policyExposed, setPolicyExposed] = useState<string>();

  const props = useSpring(contentAnimation);

  useEffect(() => {
    const fetchDataRegulation = async () => {
      const result = await getDataRegulation(PROFILES.participant);
      setDataRegulation(result);
    };
    fetchDataRegulation();
  }, []);

  const onAcceptRegulationHandler = useCallback(() => {
    setShowDataRegulation(false);
  }, []);

  useEffect(() => {
    const fetchRegulationPolicy = async () => {
      const result = await getRegulationPolicy();
      setRegulationPolicy(result);
    };
    fetchRegulationPolicy();
  }, []);

  const getAcceptedRegulation = useCallback(() => {
    return dataRegulation
      ? {
          id: dataRegulation.id,
          version: dataRegulation.version,
          policyExposed,
        }
      : null;
  }, [dataRegulation, policyExposed]);

  if (!hasRegisterToComplete) {
    return <NoResults text="Você não possui cadastro para completar" />;
  }

  return (
    <>
      {showDataRegulation ? (
        <DataRegulation
          onAccept={onAcceptRegulationHandler}
          regulation={dataRegulation}
          profile={PROFILES.participant}
          regulationPolicy={regulationPolicy}
          onSetPolicy={setPolicyExposed}
        />
      ) : (
        <Container>
          <Header />
          <Content style={props}>
            <Message>
              <h1>Complete seu cadastro</h1>
            </Message>
            <Form acceptedRegulation={getAcceptedRegulation()} />
          </Content>
        </Container>
      )}
    </>
  );
};

export default CompleteRegister;
