import React, { useState, useEffect } from 'react';
import { Option } from 'components/shared/Select';
import { Campaign } from 'state/modules/goals/types';
import DirectorsSelect from 'components/Cockpit/Selects/Directors';
import RegionalSelect from 'components/Cockpit/Selects/Regional';
import HarvestsSelect from 'components/Cockpit/Selects/Harvest';
import getRegionals from 'services/cockpit/getRegional';
import getDirectors from 'services/cockpit/getDirectors';

import { Container, SelectContainer } from './styles';

export interface Filters {
  directorName?: string;
  regionalName?: string;
  campaignId?: number;
  isTerraBrasil?: string;
}

interface Props {
  onFilter(filters: Filters): Promise<void>;
  juntosCampaign?: Campaign[];
  terraCampaign?: Campaign[];
}

const FiltersComponent: React.FC<Props> = ({
  onFilter,
  juntosCampaign,
  terraCampaign,
}) => {
  const [directorSelected, setDirectorSelected] = useState<Option | null>(null);
  const [regionalSelected, setRegionalSelected] = useState<Option | null>(null);
  const [showRegionalSelect, setShowRegionalSelect] = useState(true);
  const [showDirectorSelect, setShowDirectorSelect] = useState(true);
  const [terraBrasil, setTerraBrasil] = useState(false);

  const [harvestSelected, setHarvestSelected] = useState<Option | null>(null);

  useEffect(() => {
    getRegionals().then(data => {
      setShowRegionalSelect(data.length > 1);
      if (data.length === 1) {
        setRegionalSelected({
          value: data[0].id.toString(),
          title: data[0].name,
        });
      }
    });

    getDirectors().then(data => {
      setShowDirectorSelect(data.length > 1);
      if (data.length === 1) {
        setDirectorSelected({
          value: data[0].id,
          title: data[0].name,
        });
      }
    });
  }, []);

  useEffect(() => {
    let filters: Filters = {
      directorName: directorSelected?.title,
      regionalName: regionalSelected?.title,
    };

    if (harvestSelected?.value) {
      filters = { ...filters, campaignId: Number(harvestSelected.value) };
    }

    if (harvestSelected?.Grupo) {
      filters = {
        ...filters,
        isTerraBrasil: String(harvestSelected.Grupo),
      };
    }

    if (terraCampaign) {
      const hasTerraBrasil =
        terraCampaign.filter(c => c.isTerraBrasil).length > 0;
      setTerraBrasil(hasTerraBrasil);
    }

    onFilter(filters);
  }, [
    directorSelected,
    regionalSelected,
    onFilter,
    harvestSelected,
    terraCampaign,
  ]);

  return (
    <Container>
      {terraBrasil && terraCampaign ? (
        <SelectContainer>
          <span>Ano: </span>
          <HarvestsSelect
            setValue={value => setHarvestSelected(value)}
            value={harvestSelected}
            placeholder=""
            options={terraCampaign.map(c => ({
              title: c.title,
              value: String(c.id),
              Grupo: String(c.isTerraBrasil),
            }))}
            initialValue
          />
        </SelectContainer>
      ) : (
        <>
          {juntosCampaign && (
            <SelectContainer>
              <span>Safra: </span>
              <HarvestsSelect
                setValue={value => setHarvestSelected(value)}
                value={harvestSelected}
                placeholder=""
                options={juntosCampaign.map(c => ({
                  title: c.title,
                  value: String(c.id),
                  Grupo: String(c.isTerraBrasil),
                }))}
                initialValue
              />
            </SelectContainer>
          )}
          <SelectContainer>
            <span>Diretoria: </span>
            {showDirectorSelect ? (
              <DirectorsSelect
                setValue={value => setDirectorSelected(value)}
                value={directorSelected}
                placeholder=""
              />
            ) : (
              <span>{directorSelected?.title}</span>
            )}
          </SelectContainer>
          <SelectContainer>
            <span>Regional: </span>
            {showRegionalSelect ? (
              <RegionalSelect
                setValue={value => setRegionalSelected(value)}
                value={regionalSelected}
                placeholder=""
                directorName={directorSelected?.title}
              />
            ) : (
              <span>{regionalSelected?.title}</span>
            )}
          </SelectContainer>
        </>
      )}
    </Container>
  );
};

export default FiltersComponent;
