export const getNameAbbr = (name?: string): string => {
  if (!name) return 'AA';

  const parts = name.split(' ');

  if (parts.length === 1) return name.substr(0, 2).toUpperCase();

  const getFirstLetter = (str: string) => str.charAt(0).toUpperCase();

  const firstNameInitial = getFirstLetter(parts[0]);
  const lastNameInitial = getFirstLetter(parts[parts.length - 1]);

  return `${firstNameInitial}${lastNameInitial}`;
};

export const getFirstName = (name: string): string => {
  if (!name) return '';

  const [firstName] = name.split(' ');

  return firstName;
};

export const getFirstAndLastName = (name: string): string => {
  if (!name) return '';

  const parts = name.split(' ');

  if (parts.length === 1) return name;

  return `${parts[0]} ${parts[parts.length - 1]}`;
};

export const limitChars = (term: string, limit = 100): string => {
  if (!term) return '';

  if (term.length > limit) return `${term.substr(0, limit)}...`;

  return term;
};

export const slugify = (value: string) => {
  let str = value.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};
