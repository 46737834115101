import styled from 'styled-components';

export const FloatingBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 60%;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 15px 15px 10px 15px;
  margin: 0 0 20px 0;
  transform: translate(-50%);

  @media screen and (max-width: 1200px) {
    padding: 10px 5px 10px 10px;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
    height: 150px;
    width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  h3 {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #dd171b;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 12px;
    color: #333;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 15px;

    h3 {
      font-size: 13px;
      margin-bottom: 5px;
    }

    h4 {
      font-size: 10px;
      text-align: justify;
    }
  }
`;

export const Close = styled.div`
  width: 20%;
  display: flex;
  justify-content: right;
  transform: translateX(-4px) translateY(4px);
  button {
    border: none;
    background: ${({ theme }) => theme.font.color.secondary};
    padding: 15px 60px;
    border-radius: 8px;
    color: ${({ theme }) => theme.font.color.tertiary};
  }
  button:hover {
    filter: grayscale(50%);
    transition: 0.5s;
  }

  @media screen and (max-width: 720px) {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      padding: 15px 40px;
    }
  }

  @media screen and (max-width: 420px) {
    justify-content: center;

    button {
      margin: 5px 0 5px 0;
    }
  }
`;
