import styled from 'styled-components';

export const Container = styled.div`
  padding: 35px 25px;
  min-height: 60vh;

  .header {
    &--title {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        all: unset;
        display: flex;
        gap: 5px;
        text-transform: uppercase;
        font-size: 12px;
        align-items: center;
        color: #000;
        opacity: 0.5;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          opacity: 0.3;
        }
      }

      strong {
        font-size: 20px;
        color: #000;
      }
    }

    &--details {
      margin-top: 35px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &_title {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;

        strong {
          color: #000;
          font-size: 16px;
        }

        span {
          color: #000;
          opacity: 0.6;
          font-size: 14px;
        }
      }
    }
  }

  .content {
    margin-top: 25px;

    .table--info {
      tr td {
        padding: 10px 0px 10px 20px;
      }

      &_data {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }

    a {
      color: ${({ theme }) => theme.font.color.primary};
    }
  }
`;
