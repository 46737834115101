import styled from 'styled-components';

export const Form = styled.form`
  color: #808285;
  margin-top: 25px;

  .form--fields {
    max-width: 430px;
  }

  .form--action {
    width: 100%;
    margin-top: 30px;

    button {
      margin: 0 auto;
      height: 48px;
      max-width: 270px;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #808285;
    margin: 30px 0;
  }
`;

export const FormField = styled.div`
  margin: 16px 0;

  &.checkbox {
    display: flex;
    align-items: center;
    color: #e63027;
    font-size: 16px;
    margin-left: -10px;

    a {
      text-decoration: none;
      color: unset;

      span.underline {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }

  &.policy-exposed-alert {
    span {
      color: #e63027;
      font-size: 16px;
    }

    p {
      margin-top: 5px;
      font-size: 12px;
    }

    .policy_expose--option {
      display: flex;
      align-items: center;
      margin-left: -10px;

      span {
        color: #808285;
      }
    }
  }
`;

export const FormFieldGroup = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 15px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    color: #e63027;
  }

  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;
