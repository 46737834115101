import React from 'react';

import { Container } from './styles';

const HeaderText: React.FC = () => {
  return (
    <Container>
      <h3>Promoção encerrada!</h3>
      <p>
        Se você enviou sua nota fiscal até o dia 16/05/2021, aguarde o seu
        número da sorte e confira no dia 23/06/2021 o resultado do ganhador.
      </p>
    </Container>
  );
};

export default HeaderText;
