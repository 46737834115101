import { pluginApi } from 'services/api';
import { Participant } from 'services/auth/interfaces/Participant';
import buildRequest from 'services/register/getSaveRequestFromParticipant';

export default async (data: Participant): Promise<void> => {
  const request = buildRequest(data);
  await pluginApi.post('/participants/produtor/add', request);

  return;
};
