import styled from 'styled-components';

import {
  Input as DefaultInput,
  Button as DefaultButton,
} from 'components/shared';

interface StyleType {
  styleType: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h3 {
    font-size: 24px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 10px;
  }

  > h4 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-top: 25px;
  }

  > span {
    font-size: 18px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    opacity: 0.7;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 18px 0;

    > div {
      margin-top: 10px;
    }
    ._inputContainer {
      height: 44px;
    }
  }
`;

export const BoxPhone = styled.div`
  display: flex;
  > div {
    justify-content: flex-end;
    width: 140px;
    margin-right: 20px;
    & + div {
      margin-right: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 720px) {
    > div {
      width: 95px;
      & + width {
        width: 100%;
      }
    }
    /*flex-direction: column;
    > div + div {
      margin-top: 8px;
    }*/
  }
`;

export const Input = styled(DefaultInput)`
  span {
    color: ${({ theme }) => theme.font.color.senary};
  }
`;

export const Button = styled(DefaultButton)`
  height: 44px;
  width: 200px;
  align-self: flex-end;
  margin-left: 10px;
  @media screen and (max-width: 720px) {
    width: 100%;
    margin: 20px 0 10px 0;
  }
`;

export const PanelIndication = styled.div<StyleType>`
  display: flex;
  width: 100%;
  justify-content: ${props =>
    props.styleType === 'terra' ? 'center' : 'space-around'};

  margin-bottom: 5px;
  transform: translateY(-5px);

  > button {
    width: ${props => (props.styleType === 'terra' ? '40%' : '100%')};
    height: ${props => (props.styleType === 'terra' ? '70px' : '55px')};
    border: ${props =>
      props.styleType === 'terra' ? '2px solid #808285' : 'none'};
    border-bottom: ${props =>
      props.styleType === 'terra' ? '' : '4px solid rgba(0, 0, 0, 0.3)'};
    border-radius: ${props => (props.styleType === 'terra' ? '30px' : '20px')};
    background: transparent;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    margin: ${props => (props.styleType === 'terra' ? '10px 2px 0px 2px' : '')};
  }

  > ._selected {
    border: ${props =>
      props.styleType === 'terra' ? '2px solid #004E27' : 'none'};
    border-bottom: ${props =>
      props.styleType === 'terra' ? '' : '4px solid rgba(0, 0, 0, 0.7)'};
    color: ${props =>
      props.styleType === 'terra' ? '#004E27' : 'rgba(0, 0, 0, 0.8)'};
  }
`;

export const InputLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .filial {
    margin-bottom: 10px;
  }

  span {
    color: ${({ theme }) => theme.font.color.senary};
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > span {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 8px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 20px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.regular};

    input {
      appearance: none;
      width: 18px;
      height: 18px;
      border: 2px solid ${({ theme }) => theme.font.color.primary};
      border-radius: 50%;
      margin-right: 8px;
      position: relative;
      cursor: pointer;

      &:checked {
        border: 5px solid ${({ theme }) => theme.font.color.primary};
      }
    }
  }
`;

