import React from 'react';

import { Award as AwardType } from 'components/Roulette/types';

import { Container } from './styles';

type Props = {
  index: number;
  award: AwardType;
  isWinner: boolean;
};

export const Award: React.FC<Props> = ({ index, award, isWinner }) => {
  return (
    <Container isWinner={isWinner} className={`content-${index}`}>
      <span>{award.title}</span>
    </Container>
  );
};
