import React, { useState } from 'react';

import { Button } from 'components/shared';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import NegotiationModal from 'components/StrategicPlans/Modals/ReproveModal';
import { Comment } from 'services/strategic-plans/interfaces';
import ApproveModal from 'components/StrategicPlans/Modals/ApproveModal';
import { Container } from './styles';

type Props = {
  planUUID: string;
  comments: Comment[];
  status: number;
  canApproveOrReprove: boolean;
};

export const ApproverActions: React.FC<Props> = ({
  planUUID,
  comments,
  status,
  canApproveOrReprove,
}) => {
  const [adjustsModal, setAdjustsModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const handleApprove = () => setShowApproveModal(true);

  const handleRepprove = () => setAdjustsModal(true);

  const isActionsEnabled = status === strategyPlanStatus.onApproval;

  return (
    <Container>
      {canApproveOrReprove && (
        <>
          <Button
            type="button"
            buttonRole="primary"
            onClick={handleApprove}
            disabled={!isActionsEnabled}
          >
            Aprovar
          </Button>
          <Button
            type="button"
            buttonRole="tertiary"
            onClick={handleRepprove}
            disabled={!isActionsEnabled}
          >
            Solicitar ajustes
          </Button>
        </>
      )}

      <Button
        type="button"
        buttonRole="tertiary"
        onClick={() => setAdjustsModal(true)}
      >
        Comentários
      </Button>

      <NegotiationModal
        isOpen={adjustsModal}
        comments={comments}
        onRequestClose={() => setAdjustsModal(false)}
        selectedUuids={[planUUID]}
        onlyShow={!isActionsEnabled || !canApproveOrReprove}
        onSuccess={() => window.location.reload()}
      />

      <ApproveModal
        isOpen={showApproveModal}
        onRequestClose={() => {
          setShowApproveModal(false);
        }}
        selectedUuids={[planUUID]}
      />
    </Container>
  );
};
