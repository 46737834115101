import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
`;

export const List = styled.ul`
  list-style: none;
  font-size: 0.95em;
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-column-gap: 10px;
  width: 100%;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  position: relative;

  svg {
    cursor: pointer;
    color: #821c0e;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    color: #821c0e;
  }

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Name = styled.span`
  padding-left: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const Date = styled.span`
  padding-right: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
`;
