/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-danger */
import FmcLogo from 'assets/images/logo.png';
import { PROFILES } from 'config/constants';
import { useToast } from 'context/ToastContext';
import useSearchParam from 'hooks/use-search-param';
import React, { useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link, useLocation } from 'react-router-dom';
import getAllDataRegulations from 'services/register/regulation/getAllDataRegulations';
import { Regulation } from 'services/register/regulation/interfaces/IRegulation';
import getUrlToForceDownload from 'services/storage/getUrlToForceDownload';
import { Container, Content, Header, LateralMenu, Wrapper } from './styles';

type Role = 'producer' | 'participant' | 'fmc' | 'focalpoint';

const privacyPolicy = getUrlToForceDownload({
  filename: 'Portal_Juntos_FMC-POLITICA_DE_PRIVACIDADE.pdf',
  url:
    'https://storage.vendavall.com.br/photos/1622492212.60b54434f2f525.21535283.pdf',
});
const termsOfUse = getUrlToForceDownload({
  filename: 'Portal_Juntos_FMC-TERMO_DE_USO.pdf',
  url:
    'https://storage.vendavall.com.br/photos/1622492254.60b5445e8d30e9.15840996.pdf',
});

export const Regulations = () => {
  const termId = useSearchParam<string>('term');
  const userId = useSearchParam<string>('userId');
  const role = useSearchParam<Role>('role');
  const location = useLocation();
  const firstRender = useRef(false);
  const { addToast } = useToast();

  const [regulations, setRegulations] = useState<Regulation[]>([]);
  const [selectedTerm, setSelectedTerm] = useState(termId || '');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const term = searchParams.get('term');

    if (term) setSelectedTerm(term);
  }, [location]);

  useEffect(() => {
    const sanitizedUserId = isNaN(Number(userId)) ? undefined : Number(userId);
    switch (role) {
      case 'producer':
        getAllDataRegulations(PROFILES.producer, sanitizedUserId)
          .then(data => setRegulations(data))
          .catch(_ =>
            addToast({ title: 'Falha ao carregar os termos', type: 'error' }),
          )
          .finally(() => setLoading(false));
        break;
      case 'fmc':
        getAllDataRegulations(PROFILES.fmc, sanitizedUserId)
          .then(data => setRegulations(data))
          .catch(_ =>
            addToast({ title: 'Falha ao carregar os termos', type: 'error' }),
          )
          .finally(() => setLoading(false));
        break;
      case 'focalpoint':
        getAllDataRegulations(PROFILES.focalPoint, sanitizedUserId)
          .then(data => setRegulations(data))
          .catch(_ =>
            addToast({ title: 'Falha ao carregar os termos', type: 'error' }),
          )
          .finally(() => setLoading(false));
        break;
      case 'participant':
        getAllDataRegulations(PROFILES.participant, sanitizedUserId)
          .then(data => setRegulations(data))
          .catch(_ =>
            addToast({ title: 'Falha ao carregar os termos', type: 'error' }),
          )
          .finally(() => setLoading(false));
        break;
      default:
        getAllDataRegulations(PROFILES.producer, sanitizedUserId)
          .then(data => setRegulations(data))
          .catch(_ =>
            addToast({ title: 'Falha ao carregar os termos', type: 'error' }),
          )
          .finally(() => setLoading(false));
        break;
    }
  }, [role, addToast, userId]);

  useEffect(() => {
    /** Seleciona o primero termo ao carregar a página */
    if (regulations.length && !firstRender.current && !selectedTerm) {
      firstRender.current = true;

      setSelectedTerm(String(regulations[0].id));
    }
  }, [regulations, selectedTerm]);

  const viewTermUrl = (id: number) => {
    const params = new URLSearchParams(location.search);
    params.set('term', String(id));

    return `${location.pathname}?${params.toString()}`;
  };

  return (
    <Container>
      <Header>
        <img src={FmcLogo} alt="Logo Fmc" />
      </Header>
      <Wrapper isLoading={isLoading}>
        {isLoading && (
          <div>
            <ReactLoading type="bars" color="#fff" height={24} width={24} />
          </div>
        )}
        {!isLoading && (
          <>
            <LateralMenu>
              {regulations.map(regulation => (
                <Link
                  key={regulation.id}
                  to={viewTermUrl(regulation.id)}
                  className={
                    selectedTerm === String(regulation.id) ? 'selected' : ''
                  }
                >
                  {regulation.name}
                </Link>
              ))}
              <a href={termsOfUse} target="_blank" rel="noopener noreferrer">
                Termos de uso
              </a>
              <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                Política de privacidade
              </a>
            </LateralMenu>
            <Content>
              <div
                className="term-content"
                dangerouslySetInnerHTML={{
                  __html:
                    regulations.find(r => String(r.id) === selectedTerm)
                      ?.content || '',
                }}
              />
            </Content>
          </>
        )}
      </Wrapper>
    </Container>
  );
};
