import React from 'react';
import { useSelector } from 'react-redux';
import { isWithinInterval } from 'date-fns';
import { Link } from 'react-router-dom';
import { formatDate } from 'util/datetime';
import routeMap from 'routes/route-map';
import { getAvailableSurveys } from 'state/modules/surveys/selectors';
import EmptyList from 'components/Surveys/shared/EmptyList';

import { Container, MiniBox } from './styles';

const CardList: React.FC = () => {
  const availableSurveys = useSelector(getAvailableSurveys);

  return (
    <>
      {availableSurveys.length === 0 && <EmptyList />}
      <Container>
        {availableSurveys &&
          availableSurveys.map(survey => (
            <MiniBox key={`key-cards-${survey.id}`}>
              <img src={survey.picture} alt={survey.title} />
              <h1>{survey.title}</h1>
              <span>
                {` De ${formatDate(survey.start_datetime, 'dd/MM/yyyy')}
               até
               ${formatDate(survey.end_datetime, 'dd/MM/yyyy')}
              `}
              </span>
              {/* <p dangerouslySetInnerHTML={{ __html: survey.description }} /> */}
              <p>{survey.guidelines}</p>
              {survey?.points.map(item => {
                if (
                  isWithinInterval(new Date(), {
                    start: new Date(item.start_date),
                    end: new Date(item.end_date),
                  })
                ) {
                  return (
                    <h2 key={item.id}>Vale {item.points_count} FMC Coins</h2>
                  );
                }

                return <div key={item.id} />;
              })}
              <Link
                to={`${routeMap.InternalPage.questions}?item=${survey.id}`}
                className="btn"
              >
                Responder
              </Link>
            </MiniBox>
          ))}
      </Container>
    </>
  );
};

export default CardList;
