import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';

import { MenuItem } from 'state/modules/header/types';
import { MenuTypes } from 'state/modules/header/constants';
import { BLOCKED_ROUTES_IN_MIRROR_VIEW as blockeRoutes } from 'config/constants';
import { useAuth } from 'context/AuthContext';

interface LinkProps {
  menuItem: MenuItem;
  subMenu?: boolean;
}
const Linker: React.FC<LinkProps> = ({
  menuItem: { to, label, type, externalLink },
  subMenu = false,
}) => {
  const { simulating } = useAuth();

  const blockItem = useMemo(() => simulating && blockeRoutes.includes(to), [
    simulating,
    to,
  ]);

  const item = blockItem ? (
    <span style={{ cursor: 'not-allowed' }}>{label}</span>
  ) : (
    <Link to={to}>{label}</Link>
  );

  return {
    [MenuTypes.Menu]: (
      <a href={`#${label}`}>
        <span>{label}</span>
        {subMenu ? <AiFillCaretRight /> : <AiFillCaretDown />}
      </a>
    ),
    [MenuTypes.Internal]: item,
    [MenuTypes.Link]: <a href={externalLink}>{label}</a>,
  }[type];
};
export default Linker;
