/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';

import { Checkbox, PointsInput } from 'components/PointManagement';

import { Alert, Button, useSimpleToast } from 'components/shared';
import { formatPoints } from 'util/points';
import ThemeLoading from 'components/shared/ThemeLoading';
import routeMap from 'routes/route-map';
import { distributionSaved } from 'services/point-management/distribution-saved';
import { removeDistributionSaved } from 'services/point-management/remove-distribution-saved';
import { TeamCard } from './team-card';
import { useTeamDistribution } from './use-distribution';
import { ConfirmModal } from './modals/confirm-modal';
import { useTeam } from './use-team';
import {
  Container,
  TeamLayout,
  TeamDistributionResume,
  TeamDistributionResumePoints,
  TeamDistributedPoints,
  TeamDistributedResume,
  TeamBlock,
  TeamBlockRole,
  TeamBlockCards,
  TeamDistributionFinishOrSave,
  TeamLoading,
  TeamHead,
} from './styles';
import { Filter } from './filter';
import { ConfirmDistributionModal } from './modals/confirm-distribution-modal';
import { UpLoadAndDownloadExcel } from './modals/upload-download-excel';
import { useToast } from 'context/ToastContext';

type PageModals =
  | 'attribution-remove-confirm'
  | 'save-distribution'
  | 'finish-distribution'
  | null;

type Filters = {
  selectedSubsidiaries: string[];
  selectedRoles: string[];
  participantName: string;
};

export const TeamDistributionPage: React.FC = () => {
  const [equallyDistributionAmount, setEquallyDistributionAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [activeModal, setActiveModal] = useState<PageModals>(null);
  const { addToast } = useToast();

  const [filters, setFilters] = useState<Filters>({
    selectedRoles: [],
    selectedSubsidiaries: [],
    participantName: '',
  });

  const { team, isFetching, isFetched, fetchError } = useTeam();
  const { showToast } = useSimpleToast();
  const history = useHistory();
  const {
    flags,
    teampoint,
    selectAll,
    distribution,
    isAllSelected,
    assignEqually,
    clearDistribution,
    hasSelectedMembers,
    getDistributionResume,
    setEquallyDistribution,
    availablePointsToDistribute,
    handleFileChanges,
    openModal,
    setOpenModal,
  } = useTeamDistribution();

  const roles = Object.keys(team.participants || {}).filter(r =>
    filters.selectedRoles.length ? filters.selectedRoles.includes(r) : true,
  );

  const handleEquallyDistribution = () => {
    if (equallyDistributionAmount < 1) {
      setErrorMessage('O valor mínimo para distribuir igualmente é 1,00.');
      return;
    }
    if (!hasSelectedMembers) {
      setErrorMessage(
        'Selecione ao menos um membro da equipe para dividir os pontos.',
      );
      return;
    }
    assignEqually(equallyDistributionAmount);
    setEquallyDistributionAmount(0);
  };

  const handleDistributionClear = async () => {
    await removeDistributionSaved({
      undistributed_point_id: teampoint.undistributed_point_id,
    });

    clearDistribution();
    setActiveModal(null);
    showToast({
      type: 'info',
      message: 'Atribuições removidas com sucesso.',
    });
  };

  const valuesToSave = Object.values(distribution)
    .flat()
    .map((participant: any) => ({
      participant_id: participant.participant.id,
      value: participant.value,
    }));

  const handleDistributionSave = async () => {
    const response = await distributionSaved({
      undistributed_point_id: teampoint.undistributed_point_id,
      participants: valuesToSave,
    });

    if (response.message === 'Feito com sucesso!') {
      showToast({
        type: 'success',
        message: 'Sua distribuição foi salva com sucesso!',
      });

      setActiveModal(null);
      history.push(routeMap.pointDistribution);
      return;
    }

    showToast({
      type: 'error',
      message: response.message,
    });
  };

  const handleRoleSelect = (role: string | undefined) => {
    if (!role) {
      setFilters(state => ({
        ...state,
        selectedRoles: [],
      }));
      return;
    }
    if (filters.selectedRoles.find(i => i === role)) {
      setFilters(state => ({
        ...state,
        selectedRoles: state.selectedRoles.filter(i => i !== role),
      }));
      return;
    }

    setFilters(state => ({
      ...state,
      selectedRoles: [...state.selectedRoles, role],
    }));
  };

  const handleSubsidiarySelect = (name: string | undefined) => {
    if (!name) {
      setFilters(state => ({
        ...state,
        selectedSubsidiaries: [],
      }));
      return;
    }
    if (filters.selectedSubsidiaries.find(i => i === name)) {
      setFilters(state => ({
        ...state,
        selectedSubsidiaries: state.selectedSubsidiaries.filter(
          i => i !== name,
        ),
      }));
      return;
    }

    setFilters(state => ({
      ...state,
      selectedSubsidiaries: [...state.selectedSubsidiaries, name],
    }));
  };

  const handleParticipantSearch = useCallback((term: string) => {
    setFilters(state => ({
      ...state,
      participantName: term,
    }));
  }, []);

  const handleDistributionFinished = () => {
    history.replace(routeMap.pointDistribution);
  };

  useEffect(
    function clearEquallyValueOnAvailablePointsChange() {
      setEquallyDistributionAmount(0);
    },
    [availablePointsToDistribute],
  );

  useEffect(
    function clearErrorOnDistributionChange() {
      setErrorMessage('');
    },
    [distribution],
  );

  const distributionResume = getDistributionResume();

  const filteredByName = distribution.filter(dist =>
    filters.participantName.length > 2
      ? dist.participant.name
          .toLowerCase()
          .includes(filters.participantName.toLowerCase())
      : true,
  );

  const filteredBySubsidiary = filteredByName.filter(dist =>
    filters.selectedSubsidiaries.length
      ? filters.selectedSubsidiaries.includes(dist.participant.subsidiary)
      : true,
  );

  const canFinishOrSaveDistribution =
    !!distributionResume.length &&
    distributionResume.map(dr => dr.totalDistributed).reduce((a, b) => a + b) >
      0;

  const filteredData = roles.map(role =>
    filteredBySubsidiary.filter(
      dist => dist.participant.role === role && roles.includes(role),
    ),
  );

  const handleOpenModal = (modal: any) => {
      if (modal == 'finish-distribution' && (distribution.some(item => (item.value > 0 && item.value < 150)))) {
          addToast({
            title: "O valor mínimo para distribuição é de 150 pontos.",
            type: 'error',
          });
          return
      }

      setActiveModal(modal)
  };

  return (
    <Container>
      <div className="head">
        <h1>Premiação de equipe</h1>
        <Link to={routeMap.pointDistribution}>Voltar</Link>
      </div>
      <TeamLayout>
        <div>
          <TeamHead>
            <h3>Equipe</h3>
            <button
              type="button"
              className="btn--filter"
              onClick={() => setShowFilter(!showFilter)}
            >
              Filtros <BsFilter size={24} />
            </button>
          </TeamHead>
          <div>
            <Filter
              isVisible={showFilter}
              establishmentId={teampoint.establishment_id}
              selectedRoles={filters.selectedRoles}
              selectedSubsidiaries={filters.selectedSubsidiaries}
              onRoleSelect={handleRoleSelect}
              onSubsidiarySelect={handleSubsidiarySelect}
              onParticipantSearch={handleParticipantSearch}
            />
          </div>
          {isFetching && (
            <TeamLoading>
              <ThemeLoading size={25} />
              <span>Carregando equipe...</span>
            </TeamLoading>
          )}

          {!isFetching && fetchError && (
            <TeamLoading>
              <Alert variation="error">
                Ocorreu um erro ao carregar sua equipe.
              </Alert>
            </TeamLoading>
          )}

          {isFetched &&
            roles.map(role => (
              <TeamBlock key={role}>
                <TeamBlockRole>
                  <strong>
                    {role} (
                    {
                      filteredBySubsidiary.filter(
                        dist => dist.participant.role === role,
                      ).length
                    }
                    )
                  </strong>
                  <Checkbox
                    checked={isAllSelected(role)}
                    onChange={() => selectAll(role)}
                    label="Marcar todos"
                  />
                </TeamBlockRole>
                <TeamBlockCards>
                  {filteredBySubsidiary
                    .filter(dist => dist.participant.role === role)
                    .map(dist => (
                      <TeamCard
                        id={dist.participant.id}
                        name={dist.participant.name}
                        photo={dist.participant.photo || ''}
                      />
                    ))}
                </TeamBlockCards>
              </TeamBlock>
            ))}
        </div>
        <TeamDistributionResume>
          <TeamDistributionResumePoints>
            <span>Pontos carregados</span>
            <strong>{formatPoints(availablePointsToDistribute)}</strong>
          </TeamDistributionResumePoints>

          <PointsInput
            onChange={setEquallyDistributionAmount}
            value={equallyDistributionAmount}
            placeholder="DIGITE AQUI O VALOR"
            maxLength={availablePointsToDistribute}
          />

          <a
            style={{
              padding: '1rem',
              display: 'block',
              textDecoration: 'underline black',
              color: '#000',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(!openModal)}
          >
            Em lote
          </a>

          <UpLoadAndDownloadExcel
            isOpen={openModal}
            onCancel={() => setOpenModal(!openModal)}
            title="Distribuição em lote"
            handleFileChanges={handleFileChanges}
            participants={filteredData}
          />

          <Checkbox
            label="Distribuir igualmente"
            checked={flags.equallyDistribution}
            onChange={() => setEquallyDistribution(!flags.equallyDistribution)}
          />

          {errorMessage.length && (
            <div className="alert--error">
              <Alert variation="error">
                <span>{errorMessage}</span>
              </Alert>
            </div>
          )}

          <Button
            buttonRole="primary"
            type="button"
            onClick={handleEquallyDistribution}
            disabled={
              !flags.equallyDistribution || equallyDistributionAmount <= 0
            }
          >
            ATRIBUIR PONTOS
          </Button>

          <TeamDistributedPoints>
            <h3>Resumo da Distribuição</h3>
            <TeamDistributedResume>
              {!distributionResume.length && (
                <div className="empty">
                  <span>Vazio</span>
                </div>
              )}
              {distributionResume.map(resume => (
                <div key={resume.role}>
                  <span>
                    {resume.role} {resume.totalMembersScored}x
                  </span>
                  <strong>
                    {formatPoints(resume.totalDistributed)} pontos
                  </strong>
                </div>
              ))}
            </TeamDistributedResume>
          </TeamDistributedPoints>

          <TeamDistributionFinishOrSave>
            <Button
              buttonRole="quaternary"
              className="btn--save"
              type="button"
              disabled={!canFinishOrSaveDistribution}
              onClick={() => setActiveModal('save-distribution')}
            >
              SALVAR DISTRIBUIÇÃO
            </Button>
            <button
              type="button"
              className="btn--rmv-attrs"
              onClick={() => setActiveModal('attribution-remove-confirm')}
            >
              REMOVER ATRIBUIÇÕES
            </button>
            {/** TODO: Implementar resumo e confirmação de distribuição  */}
            <Button
              buttonRole="primary"
              type="button"
              disabled={!canFinishOrSaveDistribution}
              onClick={() => handleOpenModal('finish-distribution')}
            >
              FINALIZAR DISTRIBUIÇÃO
            </Button>
          </TeamDistributionFinishOrSave>
        </TeamDistributionResume>
      </TeamLayout>

      {/** Modals */}
      <ConfirmModal
        isOpen={activeModal === 'attribution-remove-confirm'}
        onConfirm={handleDistributionClear}
        onCancel={() => setActiveModal(null)}
        title="Remover atribuições"
        message="Deseja remover todas as atribuições?"
      />

      <ConfirmModal
        isOpen={activeModal === 'save-distribution'}
        onConfirm={handleDistributionSave}
        onCancel={() => setActiveModal(null)}
        title="Salvar distribuição"
        message={
          <span>
            Deseja salvar sua distribuição?
            <br />
            Ao salvar, você pode continuar sua distribuição em outro momento.
          </span>
        }
      />

      <ConfirmDistributionModal
        isOpen={activeModal === 'finish-distribution'}
        onCancel={() => setActiveModal(null)}
        onConfirm={handleDistributionFinished}
      />
    </Container>
  );
};
