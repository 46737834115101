import { fetchParticipantsService } from 'services/point-management/team-awards';

type Param = {
  establishmentId: number;
};

export async function fetchTeam({ establishmentId }: Param) {
  const response = await fetchParticipantsService(establishmentId, {
    participantFinder: null,
    roles: [],
    subsidiaries: [], // Filters
  });

  return response;
}
