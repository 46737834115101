import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;

  > div {
    width: 100%;
    max-width: 997px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 997px;
  background-color: #fff;
  padding: 33px 46px;
  flex: 1;

  > span {
    margin-left: 16px;
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  margin: 1rem;
  margin-bottom: 2em;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-top: 2em;
  margin-left: 15px;
  border: 1px solid #808285;
  padding: 32px;
`;

export const IntegratorContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  label {
    color: ${({ theme }) => theme.font.color.primary};
    margin-right: 10px;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-left: 5px;
    }
  }

  label:first-child {
    margin-right: 15px;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;