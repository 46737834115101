import React, { useCallback, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { formatPointsInput } from 'util/points';

interface Props {
  component?: React.FunctionComponent;
  placeholder?: string;
  onChange(v: number): void;
  value: any;
  maxLength?: number | null;
  disabled?: boolean;
}
const PointsInput: React.FC<Props> = ({
  component,
  placeholder = '0,00',
  onChange,
  value,
  maxLength = null,
  disabled = false,
}) => {
  const [errorNotMinimumPoints, setErrorNotMinimumPoints] = useState(false); // Controle do erro

  const handleChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      const normalizedValue = floatValue ? floatValue / 100 : 0;

      // Validação: erro se o valor estiver maior que 0 e menor que 150
      const error = normalizedValue > 0 && normalizedValue < 150;

      setErrorNotMinimumPoints(error);

      onChange(normalizedValue);
    },
    [onChange],
  );

  return (
    <div>
      <NumberFormat
        thousandSeparator
        type="tel"
        value={value * 100}
        format={formatPointsInput}
        allowNegative={false}
        placeholder={placeholder}
        customInput={component}
        onValueChange={handleChange}
        isAllowed={(props: NumberFormatValues) => {
          const { formattedValue, floatValue } = props;
          if (maxLength === null || !floatValue || !formattedValue) return true;

          const valueMaxlength = parseFloat((maxLength * 100).toFixed(2));

          return floatValue <= valueMaxlength;
        }}
        disabled={disabled}
        className={`number-input ${
          errorNotMinimumPoints ? 'number-input-error' : ''
        }`}
      />
      {errorNotMinimumPoints && (
        <p className="error-message">
          Valor mínimo de distribuição é 150 pontos
        </p>
      )}

      <style jsx>
        {`
          .number-input {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }

          .number-input-error {
            border-color: red;
            background-color: #ffe6e6;
          }

          .error-message {
            color: red;
            font-size: 12px;
            margin-top: 4px;
          }
        `}
      </style>
    </div>
  );
};

export default PointsInput;
