import styled from 'styled-components';

export const Container = styled.div`
  padding: 35px 25px;
  min-height: 60vh;

  .header {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const TitlePrimary = styled.h2`
  color: #821c0e;
  font-size: 24px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};

  @media screen and (max-width: 530px) {
    font-size: 18px;
  }
`;

interface ButtonProps {
  toggle?: boolean;
}

export const HelpButton = styled.button<ButtonProps>`
  background: transparent;
  border: 2px solid #b8261f;
  color: #b8261f;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 0;
`;

export const FilterButton = styled.button<ButtonProps>`
  font-size: 14px;
  background: transparent;
  text-decoration: underline;
  color: #821c0e;
  width: 980px;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 30px;
  border: none;
`;

export const ButtonPrimary = styled.button`
  background: #2c2b2b;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 18px;
  text-decoration: none;
  position: relative;
  .arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;

    &.right {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
`;
