export default {
  place_of_birth: '',
  nationality: '',
  marital_status_code_select: null,
  rg: '',
  rg_emitter: '',
  rg_emitter_uf_select: null,
  pis_nis: '',
  mother_name: '',
  father_name: '',
  naturalness_select: null,
  uf_naturalness_select: null,
  code_schooling_select: null,
  voter_registration: null,
};
