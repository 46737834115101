import styled from 'styled-components';

import HeaderBanner from 'assets/images/point-management/header_points.png';

export const Container = styled.div`
  .empty-container {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: rgba(0, 0, 0, 0.4);

      &.error {
        color: red;
      }
    }
  }

  ._loading {
    margin: 0 auto;

    svg {
      fill: ${({ theme }) => theme.button.primary.backgroundColor};
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;

  .extract-body {
    min-height: 40vh;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-image: url(${HeaderBanner});
  background-size: cover;
  padding: 40px 0;

  h2 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const HeaderRescueBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, max-content));
  grid-gap: 10px;
  margin-top: 23px;

  @media only screen and (max-width: 495px) {
    display: flex;
    padding: 0 0 0 10px;

    .title {
      font-size: 12px;
      flex-wrap: wrap;
    }

    .value {
      font-size: 14px;
    }
  }
`;

export const HeaderRescueBox = styled.div`
  background: #efefef;
  position: relative;
  border-radius: 5px;
  color: ${({ theme }) => theme.font.color.primary};
  padding: 20px;

  &.catalogo-premios {
    display: grid;
    grid-template-columns: max-content 150px;
    grid-gap: 10px;
  }

  .redeem-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 12px 14px;
      font-size: 14px;
      width: unset;
      height: unset;
      margin: 0;

      a {
        text-decoration: none;
        color: #fff;
        padding: 10px;
        margin: -10px;
      }
    }
  }

  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 22px;
    text-align: center;
  }

  p {
    margin-bottom: 5px;
  }

  .tooltip {
    position: absolute;
    right: 10px;
    top: 10px;

    svg {
      height: 20px;
      width: 20px;
      opacity: 0.5;
    }
  }
`;
