import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { useForm, FormContext } from 'react-hook-form';
import {
  Attachment,
  Product,
  ActionRequest,
  Crops,
  StrategyActionType,
  TypeHiring,
  Action,
  ApiAction,
} from 'services/strategic-plans/actions/interfaces';
import { ActionTypeName as type } from 'config/constants';
import {
  Button,
  Buttons,
} from 'components/StrategicPlans/ActionsForms/shared/styles';
import { useToast } from 'context/ToastContext';
import { formatDate } from 'util/datetime';
import { saveAction, editAction } from 'services/strategic-plans/actions';
import {
  getActionTypeName,
  getTypeHiringName,
} from 'components/StrategicPlans/utils';
import history from 'services/history';
import { formatPoints } from 'util/points';
import GoBackButton from 'components/StrategicPlans/GoBackButton';
import CancelModal from 'components/StrategicPlans/Modals/CancelModal';
import deletePlan from 'services/strategic-plans/actions/deleteAction';
import {
  buildActionRequest,
  toDevelopmentAction as transform,
} from 'services/strategic-plans/actions/transformers';
import ActionLocation from './ActionLocation';
import Attachments from './Attachments';
import Budgets from './Budgets';
import Common from './Common';
import Selections from './Selections';

import {
  Container,
  Content,
  FormActions,
  AttachmentsWrapper,
  HeaderRow,
} from './styles';

import getSchemaValidations from './validations/getSchemaValidation';

import StrategyActionAts from './Selections/StrategyActionAt';
import DeleteModal from '../Modals/DeleteModal';

interface FormProps {
  action: ApiAction;
  planUuid: string;
  canEdit?: boolean;
}

const validateProductActionTypes = [
  type.leadershipVisits,
  type.otherDevelopmentProducts,
  type.events,
];

interface FormData extends ActionRequest {
  strategy_action_type_select: { title: string; value: string } | null;
  action_location_state_code_select: { title: string; value: string } | null;
  type_hiring_id_at_select: { title: string; value: string } | null;
  exclusive_fmc_at_select: { title: string; value: string } | null;
}

const Form: React.FC<FormProps> = ({
  action: _action,
  planUuid,
  canEdit = true,
}) => {
  const [action, setAction] = useState<Action>(transform(_action));
  const [showBackModal, setShowBackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [strategyActionTypeName, setStrategyActionTypeName] = useState<string>(
    '',
  );
  const [typeHiringName, setTypeHiringName] = useState<string>('');
  const [typeHiringId, setTypeHiringId] = useState(0);
  const editMode = useMemo(() => !!action.id, [action]);

  const methods = useForm<FormData>({
    mode: 'onSubmit',
    validationSchema: getSchemaValidations,
    defaultValues: {
      id: action.id ?? undefined,
      uuid: action.uuid ?? undefined,
      action_location_name: action.actionLocation?.name || '',
      action_location_city: action.actionLocation?.city || '',
      action_location_state_code_select: action.actionLocation?.stateCode
        ? {
            title: action.actionLocation.stateCode || '',
            value: action.actionLocation.stateCode || '',
          }
        : null,
      action_location_total_area:
        action.actionLocation?.totalArea.toString() || '',
      action_location_treated_area:
        action.actionLocation?.treatedArea.toString() || '',
      points: action.points ? formatPoints(action.points, 0, 0) : '',
      budget: action.budget ? formatPoints(action.budget) : '',
      igd: action.igd ? formatPoints(action.igd, 0, 0) : '',
      expected_date: action.expectedDate ? formatDate(action.expectedDate) : '',
      title: action.title || '',
      description: action.description || '',
      strategy_action_type_select: action.strategyActionTypeId
        ? {
            title: strategyActionTypeName,
            value: action.strategyActionTypeId.toString(),
          }
        : null,
      type_hiring_id_at_select: action.typeHiring
        ? {
            title: typeHiringName,
            value: typeHiringId.toString(),
          }
        : null,
      exclusive_fmc_at_select: action.exclusiveFmcAt
        ? {
            title: action.exclusiveFmcAt,
            value: action.exclusiveFmcAt,
          }
        : null,
      quantity_at: action.quantityAt ? action.quantityAt.toString() : '',
      cost_monthy_at: action.costMonthyAt
        ? formatPoints(action.costMonthyAt)
        : '',
      begin_date_at: action.beginDateAt ? formatDate(action.beginDateAt) : '',
      end_date_at: action.endDateAt ? formatDate(action.endDateAt) : '',
    },
  });

  const backHandler = useCallback(() => {
    if (planUuid) {
      history.push(`/planos-estrategicos/${planUuid}`);
    } else {
      history.goBack();
    }
  }, [planUuid]);

  const { addToast } = useToast();

  const { handleSubmit, formState } = methods;

  const onSubmit = handleSubmit(async data => {
    if (
      action.products.length < 1 &&
      !validateProductActionTypes.includes(strategyActionTypeName || '')
    ) {
      addToast({
        title: 'Selecione um produto!',
        type: 'error',
      });
      return;
    }

    const {
      products,
      attachments,
      strategyPlanId,
      strategyActionTypeId,
      strategyActionType,
      uuid,
      crops,
      created,
      modified,
      typeHiring,
    } = action;
    const act = {
      ...data,
      strategy_action_type: data.strategy_action_type_select
        ? data.strategy_action_type_select.value
        : '',
      type_hiring_id_at: data.type_hiring_id_at_select
        ? data.type_hiring_id_at_select.value
        : '',
      exclusive_fmc_at: data.exclusive_fmc_at_select
        ? data.exclusive_fmc_at_select.value
        : '',
      action_location_state_code:
        data.action_location_state_code_select?.value ?? '',
    };

    const newAction = buildActionRequest({
      data: act,
      products,
      attachments,
      strategyPlanId,
      strategyActionTypeId,
      strategyActionType,
      uuid,
      crops,
      created,
      modified,
      typeHiring,
    });

    try {
      action.id
        ? await editAction(newAction, action.id)
        : await saveAction(newAction);
      addToast({ title: 'Ação salva com sucesso', type: 'success' });
      backHandler();
    } catch (err) {
      addToast({
        title:
          err.response?.data?.message ||
          `Problemas ao salvar ação ${strategyActionTypeName}!`,
        type: 'error',
      });
    }
  });

  const removeAttachmentClickHandler = useCallback((itemId: string) => {
    setAction(current => ({
      ...current,
      attachments: current.attachments.filter(item => item.id !== itemId),
    }));
    setFormChanged(true);
  }, []);

  const addAttachmentClickHandler = useCallback((attachment: Attachment) => {
    setAction(current => ({
      ...current,
      attachments: [...current.attachments, attachment],
    }));
    setFormChanged(true);
  }, []);

  const selectProductHandler = useCallback((product: Product) => {
    setFormChanged(true);
    if (product.name.toLowerCase() === 'todos') {
      setAction(current => ({
        ...current,
        products: [product],
      }));
      return;
    }

    setAction(current => ({
      ...current,
      products: current.products ? [...current.products, product] : [product],
    }));
  }, []);

  const removeProductHandler = useCallback((productId: number) => {
    setAction(current => ({
      ...current,
      products: current.products.filter(({ id }) => id !== productId),
    }));
    setFormChanged(true);
  }, []);

  const selectCultivationHandler = useCallback((crop: Crops) => {
    setFormChanged(true);
    if (crop.name.toLowerCase() === 'todos') {
      setAction(current => ({
        ...current,
        crops: [crop],
      }));
      return;
    }

    setAction(current => ({
      ...current,
      crops: current.crops ? [...current.crops, crop] : [crop],
      crop_id: [crop.id],
    }));
  }, []);

  const removeCultivationHandler = useCallback((cropsId: number) => {
    setAction(current => ({
      ...current,
      crops: current.crops.filter(({ id }) => id !== cropsId),
    }));
    setFormChanged(true);
  }, []);

  const selectActionTypeHandler = useCallback(
    (actionType: StrategyActionType) => {
      setFormChanged(true);
      setAction(current => ({
        ...current,
        strategyActionTypeId: actionType.id,
        strategyActionType: {
          title: strategyActionTypeName,
          value: actionType.id.toString(),
        },
      }));

      setStrategyActionTypeName(actionType.name);
    },
    [strategyActionTypeName],
  );

  useEffect(() => {
    const fetchStrategyActionTypeName = () => {
      try {
        const strategyActionType = getActionTypeName(
          action.strategyActionTypeId,
        );
        setStrategyActionTypeName(strategyActionType);
      } catch (err) {
        addToast({
          title:
            err.response?.data?.message ||
            'Erro ao buscar o nome do tipo de formulário!',
          type: 'error',
        });
      }
    };

    if (action.strategyActionTypeId) {
      fetchStrategyActionTypeName();
    }
  }, [action.strategyActionTypeId, addToast]);

  const selectTypeHiringHandler = useCallback(
    (typeHiring: TypeHiring) => {
      setFormChanged(true);

      setAction(current => ({
        ...current,
        typeHiringIdAt: typeHiring.id,
        typeHiring: {
          title: typeHiringName,
          value: typeHiringId.toString(),
        },
      }));

      setTypeHiringName(typeHiring.name);
      setTypeHiringId(typeHiring.id);
    },
    [typeHiringId, typeHiringName],
  );

  useEffect(() => {
    const fetchTypeHiringName = () => {
      try {
        const typeHiring = getTypeHiringName(action.typeHiringIdAt);

        setTypeHiringName(typeHiring);
      } catch (err) {
        addToast({
          title:
            err.response?.data?.message ||
            'Erro ao buscar o nome do tipo de formulário!',
          type: 'error',
        });
      }
    };

    if (action.typeHiringIdAt) {
      fetchTypeHiringName();
    }
  }, [action.typeHiringIdAt, addToast]);

  const handleRemoveAction = useCallback(async () => {
    if (!action.id) return;
    try {
      await deletePlan(action.id);
      addToast({
        title: 'Ação deletada com sucesso!',
        type: 'success',
      });
      backHandler();
    } catch (err) {
      addToast({
        title: err.response?.data?.message || 'Erro ao deletar Ação!',
        type: 'error',
      });
    }
  }, [action.id, addToast, backHandler]);

  const clickBackHandler = useCallback(() => {
    if ((formState.dirty || formChanged) && editMode && canEdit) {
      setShowBackModal(true);
      return;
    }

    backHandler();
  }, [backHandler, editMode, formChanged, formState.dirty, canEdit]);

  return (
    <Container>
      <HeaderRow>
        <GoBackButton onClick={clickBackHandler} />
      </HeaderRow>
      <FormContext {...methods}>
        <form onSubmit={onSubmit}>
          {action.modified && (
            <FormActions alignment="flex-end">
              <span>
                <b>Atualizado em:</b> {formatDate(action.modified)}
              </span>
            </FormActions>
          )}
          <Content>
            <Selections
              canEdit={canEdit}
              products={action.products}
              addProductHandler={selectProductHandler}
              removeProductHandler={removeProductHandler}
              crops={action.crops}
              addCultivationHandler={selectCultivationHandler}
              removeCultivationHandler={removeCultivationHandler}
              addActionTypeHandler={selectActionTypeHandler}
              defaultValue={action.id ? strategyActionTypeName : ''}
              actionTypeId={action.id ? action.strategyActionTypeId : 0}
              requiredLabel={
                ![type.strategicVision].includes(strategyActionTypeName || '')
              }
            />
            <Common canEdit={canEdit} />
            {strategyActionTypeName === type.leadershipVisits && (
              <StrategyActionAts
                addTypeHiringHandler={selectTypeHiringHandler}
                typeHiringId={action.id ? action.typeHiringIdAt : 0}
                defaultValue={action.id ? typeHiringName : ''}
                canEdit={canEdit}
              />
            )}
            <Budgets canEdit={canEdit} />
            <ActionLocation canEdit={canEdit} />
            <AttachmentsWrapper>
              <Attachments
                attachments={action?.attachments || []}
                removeClickHandler={removeAttachmentClickHandler}
                addClickHandler={addAttachmentClickHandler}
                canEdit={canEdit}
              />
            </AttachmentsWrapper>
          </Content>
          {canEdit && (
            <Buttons>
              <button
                className="delete"
                type="button"
                onClick={() => setShowDeleteModal(true)}
              >
                Desejo excluir esta ação
              </button>
              <Button type="submit" buttonRole="primary">
                Salvar
              </Button>
            </Buttons>
          )}
        </form>
      </FormContext>
      <CancelModal
        isOpen={showBackModal}
        onRequestClose={() => setShowBackModal(false)}
        onConfirm={backHandler}
        modaType="action"
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        onConfirm={handleRemoveAction}
      />
    </Container>
  );
};

export default Form;
