import React from 'react';
import { useTheme } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { BsCheck } from 'react-icons/bs';
import { AiOutlineToTop, AiOutlineCloseCircle } from 'react-icons/ai';

import IconWaiting from 'assets/images/point-management/icons/icon-waiting.svg';
import { Tooltip } from 'components/shared';
import { ApprovationStatus } from 'services/point-management/dtos/point';

const Icons = {
  [ApprovationStatus.APROVADO]: () => <BsCheck size={20} />,
  [ApprovationStatus.AGUARDANDO_APROVACAO]: () => (
    <ReactSVG src={IconWaiting} style={{ width: '20px', height: '20px' }} />
  ),
  [ApprovationStatus.AGUARDANDO_DISTRIBUICAO]: () => (
    <ReactSVG src={IconWaiting} style={{ width: '20px', height: '20px' }} />
  ),
  [ApprovationStatus.PUBLICADO]: () => <AiOutlineToTop size={20} />,
  [ApprovationStatus.REPROVADO]: () => <AiOutlineCloseCircle size={20} />,
};

type Props = {
  status: number;
  statusText: string;
};

export const StatusIcon = ({ status, statusText }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore

  const Icon = status ? Icons[status] : null;
  const {
    font: {
      color: { primary },
    },
  } = useTheme();

  return (() => {
    switch (status) {
      case ApprovationStatus.AGUARDANDO_APROVACAO:
        return (
          <Tooltip
            title={<b style={{ color: '#821c0e' }}>{statusText}</b>}
            customStyles={{
              border: '1px solid #821c0e',
            }}
            type="neutral"
            position="top"
          >
            <Icon />
          </Tooltip>
        );
      case ApprovationStatus.AGUARDANDO_DISTRIBUICAO:
        return (
          <Tooltip
            title={<b style={{ color: '#821c0e' }}>{statusText}</b>}
            customStyles={{
              border: '1px solid #821c0e',
            }}
            type="neutral"
            position="top"
          >
            <Icon />
          </Tooltip>
        );
      case ApprovationStatus.APROVADO:
        return (
          <Tooltip
            title={<b style={{ color: '#437e63' }}>{statusText}</b>}
            customStyles={{
              border: '1px solid #437e63',
            }}
            type="neutral"
            position="top"
          >
            <Icon />
          </Tooltip>
        );
      case ApprovationStatus.REPROVADO:
        return (
          <Tooltip
            title={<b style={{ color: '#e4001b' }}>{statusText}</b>}
            customStyles={{
              border: '1px solid #e4001b',
            }}
            type="neutral"
            position="top"
          >
            <Icon />
          </Tooltip>
        );
      case ApprovationStatus.PUBLICADO:
        return (
          <Tooltip
            title={<b style={{ color: primary }}>{statusText}</b>}
            customStyles={{
              border: `1px solid ${primary}`,
            }}
            type="neutral"
            position="top"
          >
            <Icon />
          </Tooltip>
        );
      default:
        return null;
    }
  })();
};
