import styled, { css } from 'styled-components';

export const Container = styled.div<{ isWinner: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 18px;
  line-height: 1;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-align: center;
  padding-top: 50px;
  user-select: none;
  border-radius: 50%;

  @media screen and (max-width: 800px) {
    font-size: 15px;
    padding-top: 35px;
  }

  span {
    word-wrap: break-word;
    display: inline-block;
    max-width: 105px;
    opacity: 0.7;

    @media screen and (max-width: 800px) {
      max-width: 90px;
    }
  }

  ${p =>
    p.isWinner &&
    css`
      span {
        opacity: 1;
      }
    `}

  &.content-1 {
    transform: rotate(30deg);

    span {
      background: linear-gradient(#95201a, #e60019);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.content-2 {
    transform: rotate(90deg);
  }

  &.content-3 {
    transform: rotate(150deg);

    span {
      background: linear-gradient(#95201a, #e60019);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.content-4 {
    transform: rotate(210deg);
  }

  &.content-5 {
    transform: rotate(270deg);

    span {
      background: linear-gradient(#95201a, #e60019);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.content-6 {
    transform: rotate(330deg);
  }
`;
