import { Configuration } from '../interfaces/api-interface';

const configuration: Configuration = {
  partial_date: new Date(),
  partial_date_invoicing: new Date(),
  partial_date_pog: new Date(),
  percent_to_pay: {
    percent_to_pay_rebate: [
      {
        balance_type_name: 'Cliente',
        value: 0.85,
        pay_percent: 0.6,
      },
      {
        balance_type_name: 'Cliente',
        value: 0.9,
        pay_percent: 0.7,
      },
    ],
    percent_to_pay_seller: [
      {
        balance_type_name: 'Vendedor',
        value: 1.0,
        pay_percent: 1,
      },
      {
        balance_type_name: 'Vendedor',
        value: 0.95,
        pay_percent: 0.8,
      },
    ],
    percent_to_pay_biological: [
      {
        balance_type_name: 'Biologico',
        value: 0.8,
        pay_percent: 0.5,
      },
      {
        balance_type_name: 'Biologico',
        value: 0.85,
        pay_percent: 0.6,
      },
    ],
  },
  pog_realized_net_percentage: 0.1694,
  minimum_rebate_percentage_to_participate: 90,
  minimum_seller_percentage_to_participate: 70,
  minimum_biological_percentage_to_participate: 70,
  biological_simulation_value: 0,
  final_calculation: 0,
};

export default configuration;
