import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 95px);
  background: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 1100px;
  flex-direction: column;
  background: #fff;
  padding: 40px 60px;

  > h3 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin: 0 0 10px 35px;
    font-size: 24px;
  }

  @media screen and (max-width: 720px) {
    padding: 20px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 500px;
`;

export const SearchInput = styled.input`
  width: 100%;
  margin-left: 20px;
  padding: 10px 15px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.font.color.primary};
  border-radius: 5px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  color: ${({ theme }) => theme.font.color.primary};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.font.color.primary};
    opacity: 0.7;
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 35px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    color: red;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  @media screen and (max-width: 720px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const PaginationButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.font.color.primary};
  color: #fff;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.font.color.secondary};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const PaginationInfo = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  font-size: 14px;
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;