import routeMap from 'routes/route-map';
import { DistributionPage } from 'pages/PointManagement/distribution-page';
import { PointsApprovementPage } from 'pages/PointManagement/approvement-page';
import { TeamDistribution } from 'pages/PointManagement/team-distribution-page';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.pointDistribution,
    component: DistributionPage,
    accessPage: 'Página de distribuição de pontos',
  },
  {
    path: routeMap.pointApprovement,
    component: PointsApprovementPage,
    accessPage: 'Página de aprovação de distribuição de pontos',
  },
  {
    path: routeMap.pointTeamDistribution,
    component: TeamDistribution,
    accessPage: 'Página de distribuição de pontos de equipe',
  },
];

export default routes;
