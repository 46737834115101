import { Indicator } from 'state/modules/points-simulator/interfaces';
import title from './title';
import revenuesPog from './revenues-pog';
import productsTemplate from './products';
import warn from './warn';

interface Props {
  revenues: Indicator;
  revenuesBiological: Indicator;
  pog: Indicator;
  pogBiological: Indicator;
  products: Indicator[];
}

export default (props: Props) => {
  const { revenues, pog, products, pogBiological, revenuesBiological } = props;

  return `
  ${title}

  ${revenuesPog({
    revenues,
    pog,
    pogBiological,
    revenuesBiological,
  })}

  ${productsTemplate({ products })}

  ${warn}
`;
};
