import React from 'react';

import { Response as Data } from 'services/showcase/transformers/toParticipantGroupGridTransformer';
import { format as formatDate, parseISO } from 'date-fns';
import { Container, DateUpload, TableContent } from './styles';
import Actions from '../Actions';

interface Props {
  tableData: Data[];
}

const ParticipantsGrid: React.FC<Props> = ({ tableData }) => {
  function formatData(updateDate: string | undefined) {
    if (!updateDate) return '---';
    const formatted = formatDate(parseISO(updateDate), 'dd/MM/yyyy HH:mm:ss');
    return formatted;
  }

  return (
    <>
      <Container>
        <TableContent>
          <thead>
            <tr>
              <th>Grupo de Clientes</th>
              {/* <th>Categoria</th> */}
              <th>Saldo Gestão e Desenvolvimento</th>
              <th> </th>
              <th>Saldo IGD</th>
              <th> </th>
            </tr>
          </thead>
          {tableData &&
            tableData
              .filter(group => group.campaignGroup !== 'Terra Brasil')
              .map(item => (
                <tbody key={item.id}>
                  <tr>
                    <td>{item.clientGroup}</td>
                    {/* <td>{item.category}</td> */}
                    <td>{item.points}</td>
                    <td>
                      <Actions
                        establishmentId={item.id}
                        campaignId={268}
                        campaignGroup={item.campaignGroup}
                      />
                    </td>
                    <td>{item.balanceIGD}</td>
                    <td>
                      <Actions
                        establishmentId={item.id}
                        campaignId={372}
                        campaignGroup={item.campaignGroup}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
        </TableContent>
      </Container>
      <DateUpload>
        Data da última atualização de pontos:
        <span> {formatData(tableData[0]?.updateDate)}</span>
      </DateUpload>
    </>
  );
};

export default ParticipantsGrid;
