import { pluginApi } from 'services/api';

interface Response {
  download_url: string;
}

export default async (
  campaignId: number,
  establishmentsId?: number,
): Promise<string> => {
  try {
    const { data } = await pluginApi.get<Response>(
      `/reports/return-checkout?campaign_id=${campaignId}&id_establishments=${establishmentsId}`,
    );
    return data.download_url;
  } catch (error) {
    return '';
  }
};
