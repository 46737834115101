import { pluginApi } from 'services/api';
import { fakeFormatDollars, formatPercent } from 'util/points';
import { StatisticsWithProduct } from './interfaces/generalStatisticsWithProducts';
import { Product } from '../../state/modules/home/types';

export interface ApiResponse {
  revenues: {
    pog: number;
    reached: number;
    percentage: number;
  };
  pog: {
    pog: number;
    reached: number;
    percentage: number;
  };
  focus_product: Product[];
}

const transformer = (data: ApiResponse): StatisticsWithProduct => {
  return {
    revenues: {
      goal: data.revenues.pog,
      result: data.revenues.reached,
      percentage: data.revenues.percentage,
      formattedGoal: fakeFormatDollars(data.revenues.pog, 0, 0),
      formattedResult: fakeFormatDollars(data.revenues.reached, 0, 0),
      formattedPercentage: formatPercent(data.revenues.percentage),
    },
    pog: {
      goal: data.pog.pog,
      result: data.pog.reached,
      percentage: data.pog.percentage,
      formattedGoal: fakeFormatDollars(data.pog.pog, 0, 0),
      formattedResult: fakeFormatDollars(data.pog.reached, 0, 0),
      formattedPercentage: formatPercent(data.pog.percentage),
    },
    focus_product: {
      ...data.focus_product,
    },
  };
};

export default async (campaignId: number): Promise<StatisticsWithProduct> => {
  const { data } = await pluginApi.get<ApiResponse>(
    `cockpit/getPogRevenues?campaign_id=${campaignId}`,
  );
  return transformer(data);
};
