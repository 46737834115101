import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Cards from 'components/FmcCoinsSimulator/Cards';
import useDimensions from 'hooks/use-window-dimensions';
import {
  getFmcProducts,
  getValueToConversion,
} from 'state/modules/fmc-coins-simulator/selectors';
import routeMap from 'routes/route-map';
import {
  CardsContainer,
  Container,
  Content,
  PageTitle,
  Separator,
} from './styles';

const Calculator = () => {
  const [productStrategic, setProductStrategic] = useState<any>([]);
  const [productInnovation, setProductInnovation] = useState<any>([]);
  const [productComplementary, setProductComplementary] = useState<any>([]);
  const productsSelector = useSelector(getFmcProducts);
  const valueToConversion = useSelector(getValueToConversion);
  const { width } = useDimensions();

  const quantityItemsAdded =
    productsSelector?.filter(item => item.checked).length || 0;

  useEffect(() => {
    const productsStrategic = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'estratégico' &&
        product.checked,
    );
    const productsInnovation = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'inovação' && product.checked,
    );
    const productsComplementary = productsSelector.filter(
      product =>
        product.category.toLocaleLowerCase() === 'complementar' &&
        product.checked,
    );

    const sortedProductsStrategic = productsStrategic.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsInnovation = productsInnovation.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const sortedProductsComplementary = productsComplementary.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setProductStrategic(sortedProductsStrategic);
    setProductInnovation(sortedProductsInnovation);
    setProductComplementary(sortedProductsComplementary);
  }, [productsSelector]);

  return (
    <Container id="calculator">
      <Content>
        <PageTitle>Simulador de FMC Coins</PageTitle>
        <span>Produtos adicionados ({quantityItemsAdded})</span>
      </Content>
      <Separator />
      {width <= 500 && (
        <span>Preencha os valores e clique em &quot;Calcular&quot;.</span>
      )}

      <CardsContainer>
        <Cards
          products={productInnovation}
          type="money"
          categoryInterface="Inovação"
          categoryComponent="innovation"
          valueToConversion={valueToConversion}
        />
        <Cards
          products={productStrategic}
          type="money"
          categoryInterface="Estratégico"
          categoryComponent="strategic"
          valueToConversion={valueToConversion}
        />
        <Cards
          products={productComplementary}
          type="money"
          categoryInterface="Complementar"
          categoryComponent="complementary"
          valueToConversion={valueToConversion}
        />
      </CardsContainer>
      {width >= 700 ? (
        <Link to={`${routeMap.fmcCoinsSimulator.result}`} className="btn">
          Calcular
        </Link>
      ) : (
        <Link to={`${routeMap.fmcCoinsSimulator.result}`} className="bntMobile">
          Calcular
        </Link>
      )}
      {width <= 500 && (
        <Link
          to={`${routeMap.fmcCoinsSimulator.selector}`}
          className="bntMobile"
        >
          Adicionar produtos
        </Link>
      )}
    </Container>
  );
};

export default Calculator;
