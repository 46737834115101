import React, { useState, useEffect } from 'react';
import getParticipants from 'services/showcase/getMyGroupOfParticipantsToAccessPI';
import getNewPoints from 'services/showcase/getNewPoints';
import transformer, {
  Response as Data,
} from 'services/showcase/transformers/toParticipantGroupGridTransformer';
import Grid from './ParticipantsGrid';
import Table from './TerraBrasil';
import { Container, Content, Box, PanelIndication } from './styles';

type Type = 'terra' | 'indiretos';

const Layout2: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [tableData, setTableData] = useState<Data[]>([]);
  const [type, setType] = useState<Type>('indiretos');

  const CAMPAIGN_TERRA_BRASIL = 347;
  const CAMPAIGN_DEFAULT = 336;
  const CAMPAIGN_IGD = 372; 

  useEffect(() => {
    setIsFetching(true);

    const getCampaignId = (campaignGroup: string): number => {
      if (campaignGroup === 'Terra Brasil') {
        return CAMPAIGN_TERRA_BRASIL;
      }
      return CAMPAIGN_DEFAULT;
    };

    function fetchParticipants() {
      getParticipants({})
        .then(async data => {
          const promises = data.map(async participant => {
            const campaignId = getCampaignId(participant.campaign_group);
            const points = await getNewPoints(participant.cnpj, campaignId);
            const pointsIGD = await getNewPoints(participant.cnpj, CAMPAIGN_IGD);
            return {
              ...participant,
              balance: points,
              balanceIGD: pointsIGD,
            };
          });
          const newData = await Promise.all(promises);
          setTableData(transformer(newData));
        })
        .finally(() => setIsFetching(false));
    }

    const fifteenMinutes = 900000;
    fetchParticipants();
    const interval = setInterval(() => {
      fetchParticipants();
    }, fifteenMinutes);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Content>
        <h3>Catálogo de Prêmios</h3>
        <PanelIndication>
          <button
            type="button"
            className={type === 'indiretos' ? '_selected' : ''}
            onClick={() => setType('indiretos')}
          >
            Indiretos
          </button>
          <button
            type="button"
            className={type === 'terra' ? '_selected' : ''}
            onClick={() => setType('terra')}
          >
            Terra Brasil
          </button>
        </PanelIndication>
        {type === 'indiretos' && !isFetching ? (
          <Box>
            <h3>Meus Clientes</h3>
            <Grid tableData={tableData} />
          </Box>
        ) : (
          <Box>
            <h3>Meus Clientes</h3>
            <Table tableData={tableData} />
          </Box>
        )}
      </Content>
    </Container>
  );
};

export default Layout2;
