import React, { useEffect, useState } from 'react';

import CultivationsSelect from 'components/StrategicPlans/ActionsForms/Selects/Cultivations';
import ProductsSelect from 'components/StrategicPlans/ActionsForms/Selects/Products';
import {
  Crops,
  Product,
  StrategyActionType,
} from 'services/strategic-plans/actions/interfaces';
import { Option } from 'components/shared/Select';
import SelectedProductsList from '../Selects/Products/SelectedProductsList';
import {
  Container,
  SelectedCultivationWrapper,
  SelectedProductsWrapper,
  Separator,
  Wrapper,
} from './styles';
import ActionsType from '../Selects/ActionsType';
import SelectedCultivationList from '../Selects/Cultivations/SelectedCultivationList';

interface SelectionsProps {
  products: Product[];
  addProductHandler: (product: Product) => void;
  removeProductHandler: (id: number) => void;
  hideProducts?: boolean;
  crops: Crops[];
  addCultivationHandler: (crops: Crops) => void;
  removeCultivationHandler: (id: number) => void;
  hideCultivation?: boolean;
  requiredLabel?: boolean;
  productsError?: boolean;
  canEdit?: boolean;
  addActionTypeHandler: (type: StrategyActionType) => void;
  defaultValue: string;
  actionTypeId: number;
}

const Selections: React.FC<SelectionsProps> = ({
  products,
  addProductHandler,
  removeProductHandler,
  hideProducts,
  crops,
  addCultivationHandler,
  removeCultivationHandler,
  hideCultivation,
  addActionTypeHandler,
  requiredLabel,
  canEdit = true,
  defaultValue,
  actionTypeId,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Option | null>(null);
  const [selectedCultivation, setSelectedCultivation] = useState<Option | null>(
    null,
  );
  const [selectedActionType, setSelectedActionType] = useState<Option | null>(
    null,
  );

  useEffect(() => {
    if (!selectedProduct) return;
    const { value, title } = selectedProduct;

    if (products?.some(({ id }) => id === Number(value))) {
      setTimeout(() => setSelectedProduct(null), 1000);
      return;
    }

    addProductHandler({
      id: Number(value),
      name: title,
    });

    setTimeout(() => setSelectedProduct(null), 1000);
  }, [addProductHandler, products, selectedProduct]);

  useEffect(() => {
    if (!selectedCultivation) return;

    const { value, title } = selectedCultivation;

    if (crops?.some(({ id }) => id === Number(value))) {
      setTimeout(() => setSelectedCultivation(null), 1000);
      return;
    }

    addCultivationHandler({
      id: Number(value),
      name: title,
    });

    setTimeout(() => setSelectedCultivation(null), 1000);
  }, [addCultivationHandler, crops, selectedCultivation]);

  useEffect(() => {
    if (!selectedActionType) return;
    const { value, title } = selectedActionType;

    addActionTypeHandler({
      id: Number(value),
      name: title,
    });
  }, [addActionTypeHandler, selectedActionType]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedActionType({
        title: defaultValue,
        value: actionTypeId.toString(),
      });
    }
  }, [actionTypeId, defaultValue]);

  return (
    <Container>
      <Wrapper>
        <ActionsType
          name="strategy_action_type_select"
          label={`Tipo de Ação ${requiredLabel ? '*' : ''}`}
          inputRole="primary"
          defaultValue={selectedActionType?.title}
          value={selectedActionType}
          setValue={setSelectedActionType}
          disabled={!canEdit}
        />
      </Wrapper>
      <Separator />
      {!hideCultivation && (
        <Wrapper>
          <CultivationsSelect
            label={`Cultura ${requiredLabel ? '*' : ''}`}
            inputRole="primary"
            setValue={setSelectedCultivation}
            value={selectedCultivation}
            disabled={!canEdit}
          />
          <SelectedCultivationWrapper>
            <SelectedCultivationList
              selectedCultivation={crops}
              removeClickHandler={removeCultivationHandler}
              canEdit={canEdit}
            />
          </SelectedCultivationWrapper>
        </Wrapper>
      )}
      <Separator />
      {!hideProducts && (
        <Wrapper>
          <ProductsSelect
            label={`Produtos ${requiredLabel ? '*' : ''}`}
            inputRole="primary"
            setValue={setSelectedProduct}
            value={selectedProduct}
            disabled={!canEdit}
          />
          <SelectedProductsWrapper>
            <SelectedProductsList
              selectedProducts={products}
              removeClickHandler={removeProductHandler}
              canEdit={canEdit}
            />
          </SelectedProductsWrapper>
        </Wrapper>
      )}
    </Container>
  );
};

export default Selections;
