import { pluginApi } from 'services/api';
import { ExtractApi } from './interfaces';

import transformer from './transformers/apiToDisplay';

export async function getExtractUserEstablishment() {
  const { data } = await pluginApi.get<ExtractApi>(`statement/establishment`);

  return transformer(data);
}
