import React, { useState, useEffect } from 'react';
import { Option } from 'components/shared/Select';
import { ReactSVG } from 'react-svg';
import deleteIcon from 'assets/images/campaigns/delete-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCampaign,
  getErrors,
} from 'state/modules/campaigns-manager/selectors';
import {
  addCulture,
  removeCulture,
} from 'state/modules/campaigns-manager/actions';

import { Container, CultureSelect, SelectedCulture } from './styles';

const Cultures: React.FC = () => {
  const [cultureSelected, setCultureSelected] = useState<Option | null>(null);
  const dispatch = useDispatch();
  const errors = useSelector(getErrors);
  const campaign = useSelector(getCampaign);

  useEffect(() => {
    if (cultureSelected) {
      const newCulture = {
        id: parseInt(cultureSelected.value, 10),
        name: cultureSelected.title,
      };

      dispatch(addCulture(newCulture));
      setTimeout(() => {
        setCultureSelected(null);
      }, 1000);
    }
  }, [dispatch, cultureSelected]);

  return (
    <Container>
      <CultureSelect
        setValue={value => setCultureSelected(value)}
        value={cultureSelected}
        placeholder="Selecione"
        error={errors.culture}
      />
      <SelectedCulture>
        {campaign.culture.map(item => (
          <div key={item.id} className="_cultureName">
            <h3>{item.name}</h3>
            <ReactSVG
              src={deleteIcon}
              onClick={() => dispatch(removeCulture({ ...item }))}
            />
          </div>
        ))}
      </SelectedCulture>
    </Container>
  );
};

export default Cultures;
