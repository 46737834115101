import React, { useEffect, useState } from 'react';
import BaseSelect from 'components/shared/Select/BaseSelect';
import { Option } from 'components/shared/Select';
import { useSimpleToast } from 'components/shared';

import getCampaigns from 'services/cockpit/getCampaign';
import { usePoints } from 'pages/PointManagement/use-points';
import { EstablishmentTypes } from 'config/constants';
import { Container } from './styles';

export const SafraSelect: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Option[]>([
    {
      title: 'Carregando...',
      value: '-1',
    },
  ]);
  const [value, setValue] = useState<Option | null>(campaigns[0]);
  const { showToast } = useSimpleToast();
  const { selectCampaign, selectedEstablishment } = usePoints();

  const isEstablishmentTerraBrasil =
    selectedEstablishment?.type === EstablishmentTypes.TerraBrasil;

  useEffect(() => {
    getCampaigns()
      .then(response => {
        const options: Option[] = response
          .map(campaign => ({
            title: campaign.label,
            value: String(campaign.id),
          }));

        const mostRecentCampaign = options.length > 1 ? options[0] : options[1];
        setCampaigns(options);

        if (mostRecentCampaign) {
          setValue(mostRecentCampaign);
          selectCampaign(Number(mostRecentCampaign.value));
        }
      })
      .catch(() => {
        showToast({
          type: 'error',
          message: 'Ocorreu um erro ao carregar a lista de campanhas.',
        });
      });
  }, [showToast, selectCampaign, isEstablishmentTerraBrasil]);

  return (
    <Container>
      <strong>{isEstablishmentTerraBrasil ? 'Ano:' : 'Safra:'}</strong>
      <BaseSelect
        disableClearable
        loadItems={() => campaigns}
        setValue={option => {
          if (option?.value === '-1') return;

          selectCampaign(Number(option?.value));
          setValue(option);
        }}
        value={value}
        inputRole="primary"
      />
    </Container>
  );
};
