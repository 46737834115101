import styled from 'styled-components';
import Slider from 'react-slick';
import { Dot } from '../../Performance/styles';

export const List = styled.ul`
  display: grid;
  flex-direction: column;
  margin: 0 5px;
`;

export const Label = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em 0 0.7em 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > span {
    font-size: 12px;
    transform: translateY(1px);
  }
`;

export const IndividualProgressWrapper = styled.div`
  margin: 1em;
  width: 160px;

  @media screen and (max-width: 730px) {
    margin: 0;
  }
`;

export const IndividualWatchProduct = styled.div`
  margin: 1em;
  width: 110px;

  @media screen and (max-width: 730px) {
    margin: 0;
  }
`;
export const GoalText = styled.p`
  color: #000000;
  font-size: 12px;
  text-align: center;
`;

export const AllBox = styled.div`
  background-color: #fff;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);
  height: auto;
`;

export const LinkBox = styled.div`
  width: 100%;
  padding: 20px;

  .link-cockipt {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #262727;
    position: relative;
    left: 16%;
    margin: 0 auto;
    font-weight: bold;
    font-family: Helvetica-Neue-Medium;
    font-size: 15px;

    @media screen and (max-width: 730px) {
      left: 0;
    }
  }
`;

export const BoxForAllWatches = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  align-items: center;

  margin-bottom: 10px;

  @media screen and (max-width: 730px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const WatchBox = styled.div`
  margin: 1em;
  width: 160px;
`;

export const WatchBoxCockpit = styled.div`
  margin: 1em;
  width: 110px;
`;

export const Separator = styled.hr`
  width: 2px;
  height: 110px;
  border: 1px solid rgba(0, 0, 0, 0.18);

  margin: 0px 70px 0px 70px;

  @media screen and (max-width: 730px) {
    width: 80%;
    height: 2px;
    border: 1px solid rgba(0, 0, 0, 0.18);

    margin: 30px 0px 30px 0px;
  }
`;

export const CustomSlider = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: green !important;
  }

  .slick-prev,
  .slick-next {
    margin-top: 40px;
    margin-left: 15px;
    margin-right: 30px;
  }

  width: 600px;
  img {
    width: 100%;
  }

  .slick-dots {
    bottom: 10px;

    .slick-active {
      ${Dot} {
        &:before {
          background-color: #fff;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
