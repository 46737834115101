import { Product, Award, Configuration, Indicator } from '../interfaces';
import { getBiologicalFinalMultiplierValue } from './get-calculated-products-with-points';

interface DefaultProps {
  products: Product[];
  indicators: Indicator[];
  configuration: Configuration;
}

const calculateAwards = ({
  products,
  indicators,
  configuration,
}: DefaultProps): Award => {
  const simulatedRebateNotBiological = products
    .filter(item => item.checked && !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.rebateReachedInRealSimulated || 0),
      0,
    );

  const simulatedRebateBiologicalJuntosFmc = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.rebateReachedInRealSimulated || 0),
      0,
    );

  const simulatedRebate =
    simulatedRebateNotBiological + simulatedRebateBiologicalJuntosFmc;

  const simulatedRebateToSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .rebateReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const simulatedRebateJuntosFmcToSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .rebateReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const realizedRebate = products
    .filter(item => !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.rebateReachedInRealRealized || 0),
      0,
    );

  const simulatedSellerNotBiological = products
    .filter(item => item.checked && !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.sellerReachedInRealSimulated || 0),
      0,
    );

  const simulatedSellerBiologicalJuntosFmc = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.sellerReachedInRealSimulated || 0),
      0,
    );
  const simulatedSeller =
    simulatedSellerNotBiological + simulatedSellerBiologicalJuntosFmc;

  const simulatedSellerToSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .sellerReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const realizedSeller = products
    .filter(item => !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.sellerReachedInRealRealized || 0),
      0,
    );

  const simulatedAdditionalMargin = products
    .filter(item => item.checked && !item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator + (product.simulationPoints.additionalMarginSimulated || 0),
      0,
    );

  const realizedAdditionalMargin = products.reduce(
    (accumulator, product) =>
      accumulator + (product.simulationPoints.additionalMarginRealized || 0),
    0,
  );

  const totalAdditionalMargin =
    realizedAdditionalMargin + simulatedAdditionalMargin;

  const simulatedRebateBiological = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.rebateBiologicalReachedInRealSimulated || 0),
      0,
    );

  const simulatedRebateBiologicaloSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .rebateBiologicalReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const realizedRebateBiologicalJuntosFmc = products
    .filter(item => item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.rebateReachedInRealRealized || 0),
      0,
    );
  const biologicalFinalMultiplierValue = getBiologicalFinalMultiplierValue({
    configuration,
    indicators,
  });

  let realizedRebateBiological = 0;
  let totalRebateBiological = 0;
  let totalRebate = 0;

  if (biologicalFinalMultiplierValue === 0) {
    const totalRebateBiologicalSum =
      realizedRebateBiologicalJuntosFmc +
      simulatedRebateBiologicaloSumWithRealizedToGetTheTotal;

    totalRebate =
      realizedRebate +
      simulatedRebateToSumWithRealizedToGetTheTotal +
      simulatedRebateJuntosFmcToSumWithRealizedToGetTheTotal +
      totalRebateBiologicalSum;

    totalRebateBiological =
      totalRebateBiologicalSum * biologicalFinalMultiplierValue;
  } else {
    realizedRebateBiological = products
      .filter(item => item.isBiological)
      .reduce(
        (accumulator, product) =>
          accumulator +
          (product.simulationPoints.rebateBiologicalReachedInRealRealized || 0),
        0,
      );

    const totalRebateBiologicalSum =
      realizedRebateBiological +
      simulatedRebateBiologicaloSumWithRealizedToGetTheTotal;

    totalRebate =
      realizedRebate +
      simulatedRebateToSumWithRealizedToGetTheTotal +
      simulatedRebateJuntosFmcToSumWithRealizedToGetTheTotal +
      realizedRebateBiologicalJuntosFmc;

    totalRebateBiological =
      totalRebateBiologicalSum * biologicalFinalMultiplierValue;
  }

  const simulatedSellerBiological = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.sellerBiologicalReachedInRealSimulated || 0),
      0,
    );

  const simulatedSellerBiologicalToSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .sellerBiologicalReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const simulatedSellerBiologicalJuntosFmcToSumWithRealizedToGetTheTotal = products
    .filter(item => item.checked && item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints
          .sellerReachedInRealSimulatedButUsedToGetTotal || 0),
      0,
    );

  const realizedSellerBiologicalJuntosFmc = products
    .filter(item => item.isBiological)
    .reduce(
      (accumulator, product) =>
        accumulator +
        (product.simulationPoints.sellerReachedInRealRealized || 0),
      0,
    );

  let realizedSellerBiological = 0;
  let totalSellerBiological = 0;
  let totalSeller = 0;
  if (biologicalFinalMultiplierValue === 0) {
    const totalSellerBiologicalSum =
      realizedSellerBiologicalJuntosFmc +
      simulatedSellerBiologicalToSumWithRealizedToGetTheTotal;

    totalSeller =
      realizedSeller +
      simulatedSellerToSumWithRealizedToGetTheTotal +
      simulatedSellerBiologicalJuntosFmcToSumWithRealizedToGetTheTotal +
      totalSellerBiologicalSum;

    totalSellerBiological =
      totalSellerBiologicalSum * biologicalFinalMultiplierValue;
  } else {
    realizedSellerBiological = products
      .filter(item => item.isBiological)
      .reduce(
        (accumulator, product) =>
          accumulator +
          (product.simulationPoints.sellerBiologicalReachedInRealRealized || 0),
        0,
      );

    const totalSellerBiologicalSum =
      realizedSellerBiological +
      simulatedSellerBiologicalToSumWithRealizedToGetTheTotal;

    totalSeller =
      realizedSeller +
      simulatedSellerToSumWithRealizedToGetTheTotal +
      simulatedSellerBiologicalJuntosFmcToSumWithRealizedToGetTheTotal +
      realizedSellerBiologicalJuntosFmc;

    totalSellerBiological =
      totalSellerBiologicalSum * biologicalFinalMultiplierValue;
  }

  return {
    simulatedRebate,
    realizedRebate,
    totalRebate,
    simulatedSeller,
    realizedSeller,
    totalSeller,
    simulatedAdditionalMargin,
    realizedAdditionalMargin,
    totalAdditionalMargin,
    simulatedRebateBiological,
    realizedRebateBiological,
    totalRebateBiological,
    simulatedSellerBiological,
    realizedSellerBiological,
    totalSellerBiological,
  };
};

export default calculateAwards;
