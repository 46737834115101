import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  @media screen and (max-width: 720px) {
    position: relative;
    margin-top: 25px;
    margin-left: auto;

    right: 0px !important;
    bottom: 35px !important;
    order: -1;
  }
`;

export const Content = styled.div<{ size: number }>`
  background-color: ${({ theme }) => theme.contact.ballon.backgroundColor};
  border-radius: 50%;
  width: ${p => p.size * 1.3}px; /* Aumenta o tamanho do círculo */
  height: ${p => p.size * 1.3}px; 
  display: flex;
  flex-direction: column; /* Empilha os itens verticalmente */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden; /* Evita que o conteúdo ultrapasse o círculo */

  span {
    text-align: center;
    line-height: 1; /* Ajusta o espaçamento vertical */

    &:first-child {
      font-size: ${p => p.size / 2}px; /* Tamanho do "?" */
      font-weight: bold;
    }

    &:last-child {
      font-size: ${p => p.size / 4}px; /* Tamanho de "Dúvidas" */
      margin-top: 3px; /* Espaçamento entre o "?" e "Dúvidas" */
    }
  }

  transition: transform 150ms ease;
  will-change: transform;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 720px) {
    width: 60px; /* Ajusta o tamanho para telas menores */
    height: 60px; 
  }
`;