import styled from 'styled-components';
import banner from 'assets/images/producer-who-buy/banner.png';
import bannerMobile from 'assets/images/producer-who-buy/banner_mobile.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background-image: url(${banner});
  background-position: 10% 0, center;
  background-size: cover;
  width: 100%;
  min-height: 277px;
  display: flex;
  justify-content: start;
  align-items: start;

  h1 {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 24px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin: 2em 5em;

    @media (max-width: 480px) {
      font-size: 18px;
      margin-left: 40px;
    }
  }

  @media (max-width: 480px) {
    background-image: url(${bannerMobile});
  }
  @media (max-width: 780px) {
    background-position: 50% 0, center;
  }
`;
