import { Data } from '..';

export interface Client {
  name: string;
  biological: boolean;
}

type DataColumnName =
  | 'billing_goal'
  | 'billing_result'
  | 'billing_percentage'
  | 'pog_goal'
  | 'pog_result'
  | 'pog_percentage'
  | 'premio_goal'
  | 'premio_result'
  | 'premio_percentage'
  | 'premio_star_goal'
  | 'premio_star_result'
  | 'premio_star_percentage'
  | 'stone_goal'
  | 'stone_result'
  | 'stone_percentage'
  | 'verimark_goal'
  | 'verimark_result'
  | 'verimark_percentage'
  | 'aurora_goal'
  | 'aurora_result'
  | 'aurora_percentage'
  | 'hero_goal'
  | 'hero_result'
  | 'hero_percentage'
  | 'talisman_goal'
  | 'talisman_result'
  | 'talisman_percentage'
  | 'biological_pog_goal'
  | 'biological_pog_realized'
  | 'biological_pog_percentage'
  | 'biological_invoice_goal'
  | 'biological_invoice_realized'
  | 'biological_invoice_percentage'
  | 'quartzo_goal'
  | 'quartzo_percentage'
  | 'quartzo_result'
  | 'reator_goal'
  | 'reator_percentage'
  | 'reator_result'
  | 'altacor_goal'
  | 'altacor_result'
  | 'altacor_percentage'
  | 'boral_goal'
  | 'boral_percentage'
  | 'boral_result'
  | 'fungicidas_goal'
  | 'fungicidas_result'
  | 'fungicidas_percentage'
  | 'herbicidas_goal'
  | 'herbicidas_result'
  | 'herbicidas_percentage'
  | 'inseticidas_by_ryna_goal'
  | 'inseticidas_by_ryna_result'
  | 'inseticidas_by_ryna_percentage'
  | 'inseticidas_goal'
  | 'inseticidas_result'
  | 'inseticidas_percentage';

export type ChartCategory = 'NewNormal' | 'normal' | 'biological' | 'terraBrasil' | 'all';

interface ChartData {
  labels: string[];
  dataset: {
    data: number[];
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    label?: string;
    visible?: boolean;
  }[];
  title: string;
  divideValueBy?: number;
  category: ChartCategory;
}

export type ChartName =
  | 'billingRealized'
  | 'pogRealized'
  | 'premioRealized'
  | 'auroraRealized'
  | 'heroRealized'
  | 'talismaRealized'
  | 'quartzoRealized'
  | 'reatorRealized'
  | 'altacorRealized'
  | 'boralRealized'
  | 'herbicides'
  | 'inseticidas'
  | 'inseticidasByRyna'
  | 'fungicidas';

export interface Charts {
  billingRealized: ChartData;
  pogRealized: ChartData;
  premioRealized: ChartData;
  auroraRealized: ChartData;
  heroRealized: ChartData;
  talismaRealized: ChartData;
  biologicalPogRealized: ChartData;
  biologicalInvoiceRealized: ChartData;
  quartzoRealized: ChartData;
  boralRealized: ChartData;
  premioStarRealized: ChartData;
  stoneRealized: ChartData;
  verimarkRealized: ChartData;
  altacorRealized: ChartData;
  herbicides: ChartData;
  inseticidas: ChartData;
  inseticidasByRyna: ChartData;
  fungicidas: ChartData;
  reatorRealized: ChartData;
}
const getDataLabels = (data: Data[], filter?: Client[]): string[] => {
  if (filter) {
    return data
      .filter(item => !!filter.find(i => i.name === item.name))
      .map(item => item.name);
  }

  return data.map(item => item.name);
};

const getDataNumbers = (data: Data[], columnName: DataColumnName): number[] => {
  return data.map(item => item[columnName]);
};

const getClients = (data: Data[], isTerraBrasil: boolean): Client[] => {
  return data
    .filter(item =>
      isTerraBrasil
        ? item.establishment_type === 'Terra Brasil'
        : item.establishment_type !== 'Terra Brasil',
    )
    .filter(item => Number(item.billing_goal) > 0)
    .map(item => ({
      name: item.name,
      biological: item.check_biologic?.biological,
    }));
};

/** TODO: Refatorar a forma de tratar as informações usando o retorno de `products` do endpoint */
const getCharts = (
  data: Data[],
  isTerraBrasil: boolean,
  filter?: Client[],
): Charts => {
  /** TODO: Refatorar
   * Aqui foi adicionado um filtro para mostrar no gráfico apenas
   * produtos que possuem meta maior que 0.
   */
  const channels = data
    .filter(item =>
      isTerraBrasil
        ? item.establishment_type === 'Terra Brasil'
        : item.establishment_type !== 'Terra Brasil',
    )
    .filter(item => Number(item.billing_goal) > 0);

  const labels = getDataLabels(channels, filter);
  const filteredData = data.filter(item => labels.indexOf(item.name) >= 0);

  return {
    billingRealized: {
      labels,
      category: 'all',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'billing_goal'),
          backgroundColor: '#CDD6E1',
          borderColor: '#2464A3',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'billing_result'),
          backgroundColor: '#FF6565',
          borderColor: '#A32B2B',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'billing_percentage'),
          visible: false,
        },
      ],
      title: 'Faturamento (MM US$)',
    },
    pogRealized: {
      labels,
      category: 'all',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'pog_goal'),
          backgroundColor: '#CDD6E1',
          borderColor: '#2464A3',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'pog_result'),
          backgroundColor: '#FF6565',
          borderColor: '#A32B2B',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'pog_percentage'),
          visible: false,
        },
      ],
      title: 'POG (MM US$)' || 'POG Fornecedor (MM US$)',
    },
    premioRealized: {
      labels,
      category: 'normal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'premio_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'premio_result'),
          backgroundColor: '#EC959F',
          borderColor: '#913944',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'premio_percentage'),
          visible: false,
        },
      ],
      title: 'PREMIO<span>®</span> (L)',
    },
    auroraRealized: {
      labels,
      category: 'normal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'aurora_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'aurora_result'),
          backgroundColor: '#EC959F',
          borderColor: '#913944',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'aurora_percentage'),
          visible: false,
        },
      ],
      title: 'AURORA<span>®</span> (L)',
    },
    heroRealized: {
      labels,
      category: 'normal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'hero_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'hero_result'),
          backgroundColor: '#C1F7D2',
          borderColor: '#47C246',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'hero_percentage'),
          visible: false,
        },
      ],
      title: 'HERO<span>®</span> (L)',
    },
    talismaRealized: {
      labels,
      category: 'normal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'talisman_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'talisman_result'),
          backgroundColor: '#B5BDF3',
          borderColor: '#838BC5',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'talisman_percentage'),
          visible: false,
        },
      ],
      title: 'TALISMAN<span>®</span> (L)',
    },
    quartzoRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'quartzo_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'quartzo_result'),
          backgroundColor: '#838BC5',
          borderColor: '#838BC5',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'quartzo_percentage'),
          visible: false,
        },
      ],
      title: 'QUARTZO<span>®</span> (L)',
    },

    altacorRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'altacor_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'altacor_result'),
          backgroundColor: '#47C246',
          borderColor: '#47C246',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'altacor_percentage'),
          visible: false,
        },
      ],
      title: 'ALTACOR<span>®</span> (L)',
    },
    stoneRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'stone_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'stone_result'),
          backgroundColor: '#47C246',
          borderColor: '#47C246',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'stone_percentage'),
          visible: false,
        },
      ],
      title: 'STONE<span>®</span> (L)',
    },
    verimarkRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'verimark_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'verimark_result'),
          backgroundColor: '#47C246',
          borderColor: '#47C246',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'verimark_percentage'),
          visible: false,
        },
      ],
      title: 'VERIMARK<span>®</span> (L)',
    },
    premioStarRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'premio_star_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'premio_star_result'),
          backgroundColor: '#47C246',
          borderColor: '#47C246',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'premio_star_percentage'),
          visible: false,
        },
      ],
      title: 'PREMIO STAR<span>®</span> (L)',
    },
    boralRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'boral_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'boral_result'),
          backgroundColor: '#913944',
          borderColor: '#913944',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'boral_percentage'),
          visible: false,
        },
      ],
      title: 'BORAL FULL<span>®</span> (L)',
    },

    reatorRealized: {
      labels,
      category: 'terraBrasil',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'reator_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'reator_result'),
          backgroundColor: '#C9ED09',
          borderColor: '#C9ED09',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'reator_percentage'),
          visible: false,
        },
      ],
      title: 'REATOR 360 CS<span>®</span> (L)',
    },

    biologicalInvoiceRealized: {
      labels,
      category: 'biological',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'biological_invoice_goal'),
          backgroundColor: '#CDD6E1',
          borderColor: '#2464A3',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'biological_invoice_realized'),
          backgroundColor: '#FF6565',
          borderColor: '#A32B2B',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'biological_invoice_percentage'),
          visible: false,
        },
      ],
      title: 'Faturamento (MM US$)',
    },
    biologicalPogRealized: {
      labels,
      category: 'biological',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'biological_pog_goal'),
          backgroundColor: '#CDD6E1',
          borderColor: '#2464A3',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'biological_pog_realized'),
          backgroundColor: '#FF6565',
          borderColor: '#A32B2B',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'biological_pog_percentage'),
          visible: false,
        },
      ],
      title: 'POG (MM US$)' || 'POG Fornecedor (MM US$)',
    },
    herbicides: {
      labels,
      category: 'NewNormal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'herbicidas_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'herbicidas_result'),
          backgroundColor: '#4CC151',
          borderColor: '##4CC151',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'herbicidas_percentage'),
          visible: false,
        },
      ],
      title: 'HERBICIDAS (US$)',
    },
    inseticidas: {
      labels,
      category: 'NewNormal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'inseticidas_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'inseticidas_result'),
          backgroundColor: '#903944',
          borderColor: '#903944',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'inseticidas_percentage'),
          visible: false,
        },
      ],
      title: 'INSETICIDAS (US$)',
    },
    inseticidasByRyna: {
      labels,
      category: 'NewNormal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'inseticidas_by_ryna_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'inseticidas_by_ryna_result'),
          backgroundColor: '#838CC3',
          borderColor: '#838CC3',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'inseticidas_by_ryna_percentage'),
          visible: false,
        },
      ],
      title: 'INSETICIDAS BY RYNAXYPYR (US$)',
    },
    fungicidas: {
      labels,
      category: 'NewNormal',
      dataset: [
        {
          data: getDataNumbers(filteredData, 'fungicidas_goal'),
          backgroundColor: '#EAEAEA',
          borderColor: '#B4B4B4',
          borderWidth: 1,
          label: 'Meta',
        },
        {
          data: getDataNumbers(filteredData, 'fungicidas_result'),
          backgroundColor: '#CAEB3C',
          borderColor: '#CAEB3C',
          borderWidth: 1,
          label: 'Realizado',
        },
        {
          data: getDataNumbers(filteredData, 'fungicidas_percentage'),
          visible: false,
        },
      ],
      title: 'FUNGICIDAS (US$)',
    }
  };
};

export { getClients, getCharts };
