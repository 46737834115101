import React, { useCallback, useRef, useState } from 'react';

import { Attachment } from 'services/strategic-plans/actions/interfaces';
import sendFile from 'services/storage/sendFile';
import { useToast } from 'context/ToastContext';
import AttachmentsList from './AttachmentsList';
import { Container } from './styles';
import { Button } from '../shared/styles';

interface AttachmentsProps {
  attachments: Attachment[];
  removeClickHandler: (itemId: string) => void;
  addClickHandler: (file: Attachment) => void;
  canEdit?: boolean;
}

const Attachments: React.FC<AttachmentsProps> = ({
  attachments,
  removeClickHandler,
  addClickHandler,
  canEdit = true,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const onClickHandler = useCallback(() => {
    fileRef.current?.click();
  }, []);

  const handleAttachFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setLoading(true);
        if (e && e.target && e.target.files && e.target.files.length > 0) {
          const fileName = e.target.files[0].name;
          const result = await sendFile(
            e.target.files[0],
            'strategy-plans/attachments',
          );

          const attachment: Attachment = {
            id: result.id,
            name: fileName,
            url: result.url,
            uploadDate: new Date(),
          };

          addClickHandler(attachment);
        }
      } catch (err) {
        addToast({
          title: err.response?.data?.message || 'Problemas ao adicionar anexo!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addClickHandler, addToast],
  );

  return (
    <Container>
      <Button
        type="button"
        buttonRole="primary"
        disabled={!canEdit}
        onClick={onClickHandler}
        loading={loading}
      >
        INCLUIR ANEXO
        <input
          ref={fileRef}
          type="file"
          id="fileId"
          onChange={handleAttachFile}
          style={{ display: 'none' }}
        />
      </Button>
      <AttachmentsList
        attachments={attachments}
        removeClickHandler={removeClickHandler}
        canEdit={canEdit}
      />
    </Container>
  );
};

export default Attachments;
