import React, { useState, useEffect, useCallback } from 'react';
import history from 'services/history';

import { getParticipantsToAccessPI } from 'services/showcase';
import { useAuth } from 'context/AuthContext';

import { Container } from './styles';

const CatalogoPi: React.FC = () => {
  const [piAccess, setPiAccess] = useState('');
  const { participant, isLoggedFromAdmin } = useAuth();

  const handlePiAccess = useCallback(() => {
    if (!piAccess || isLoggedFromAdmin)
      return history.push('/catalogo-de-premios');

    if (
      participant.profile === 'PRODUTOR' &&
      !participant.role.expiratedPassword
    ) {
      window.open(piAccess, '_blank');
    }

    return history.push('/home');
  }, [piAccess, participant, isLoggedFromAdmin]);

  useEffect(() => {
    getParticipantsToAccessPI().then(data => {
      setPiAccess(data.find(item => item.type === 'cpf')?.urlPi || '');
      handlePiAccess();
    });
  }, [piAccess, handlePiAccess]);

  return (
    <Container>
      <p>Redirecionando...</p>
    </Container>
  );
};

export default CatalogoPi;
