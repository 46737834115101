import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import getReportIncome from 'services/report-income/getReportIncome';
import { ReportIncome as ReportIncomeData } from 'services/report-income/interfaces';
import { Container, List, Title } from './styles';

const ReportIncome: React.FC = () => {
  const [reportIncome, setReportIncome] = useState<ReportIncomeData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadLink = async () => {
      setLoading(true);
      const responseData = await getReportIncome();
      if (responseData[0] !== null) {
        setReportIncome(responseData);
      }
      setLoading(false);
    };
    loadLink();
  }, []);

  return (
    <Container>
      <div>
        <Title>Informe de Rendimentos</Title>
        <span>
          O informe de rendimentos é referente aos resgates realizados no
          Catálogo de Prêmios durante o ano. <br />
          Selecione o ano a seguir e clique para baixar o arquivo.
        </span>
        {loading && <ReactLoading type="bars" height={32} width={32} />}
        {reportIncome.length > 0 && !loading ? (
          <List>
            {reportIncome.map(item => (
              <li key={item?.date_time}>
                Informe de Rendimentos {item?.date_time} -{' '}
                <a
                  href={`https://adm.juntosfmc.com.br/download?name=Portal_Juntos_FMC-INFORME_DE_RENDIMENTOS.pdf&url=${item?.path}`}
                  download
                >
                  Download
                </a>
              </li>
            ))}
          </List>
        ) : (
          <span className="no-report">Não há Informe de Rendimentos.</span>
        )}
      </div>
    </Container>
  );
};

export default ReportIncome;
