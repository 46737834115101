import React, { useEffect, useCallback } from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import {
  Answer as AnswerData,
  ParticipantAnswer,
} from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import { AnswerRow, Container } from './styles';

interface MultipleLinearScaleProps {
  question: string;
  answers: AnswerData[];
  id: number;
  canEdit: boolean;
}

const MultipleLinearScale: React.FC<MultipleLinearScaleProps> = ({
  question,
  answers,
  id,
  canEdit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch);
    }
  }, [answers, canEdit, dispatch, id]);

  const getValue = useCallback(
    (participantAnswer: ParticipantAnswer[], answerId: number): number => {
      const findedAnswer = participantAnswer.find(
        item => item.survey_question_answer_id === answerId,
      );

      return findedAnswer ? Number(findedAnswer.answer) : 0;
    },
    [],
  );

  return (
    <Container>
      <p>{question}</p>
      {answers.map(answer => (
        <AnswerRow key={answer.id}>
          <Typography component="span">{answer.answer}</Typography>
          <Rating
            name={answer.id.toString()}
            size="large"
            onChange={(e, newValue) => {
              dispatch(
                setValueAnswer({
                  answerValue: newValue?.toString() ?? '',
                  questionId: Number(id),
                  answerId: Number(answer.id),
                }),
              );
            }}
            defaultValue={getValue(
              answer.survey_participant_answers,
              answer.id,
            )}
            disabled={!canEdit}
          />
        </AnswerRow>
      ))}
    </Container>
  );
};

export default MultipleLinearScale;
