import React from 'react';

import {
  BillingPog as BillingPogType,
  Potentializer,
  Biological as BiologicalType,
  Infos,
} from 'state/modules/goals/types';
import { useAuth } from 'context/AuthContext';
import BillingPog from './BillingPog';
import Potentializers from './Potentializers';
import PerformancePotentialTable from './PerformancePotentialTable';
import BiologicalPerformance from './BiologicalPerformance';
import ExcelDownload from './ExcelDownload';
import { LastUpdate } from './styles';

interface PerformanceTabContentProps {
  billingPog: BillingPogType | null;
  potentializers: Potentializer[] | null;
  biological: BiologicalType | null;
  infos: Infos | null;
}
const PerformanceTabContent: React.FC<PerformanceTabContentProps> = ({
  billingPog,
  potentializers,
  infos,
  biological,
}) => {
  const hasPotentializers = !!potentializers && potentializers.length > 0;
  const { participant } = useAuth();
  const isBiological = !!participant.establishment.biological;

  return (
    <>
      <BillingPog
        billingPog={billingPog}
        additionalMargin={
          billingPog?.margem_adicional ? billingPog.margem_adicional : null
        }
      />
      {hasPotentializers && <Potentializers potentializers={potentializers} />}
      {isBiological && <BiologicalPerformance biological={biological} />}
      {!!infos && (
        <>
          <PerformancePotentialTable
            potential={infos.potential}
            points={infos.points}
            generationDemand={infos.generationDemand}
            businessGeneration={infos.businessGeneration}
            additionalMargin={infos.additionalMargin}
            biological={infos.biological}
          />
          {!!infos.excel && infos.excel !== '#' && (
            <ExcelDownload url={infos.excel} />
          )}
          {!!infos.lastUpdateInvoicing && (
            <LastUpdate>Última atualização Faturamento: {infos.lastUpdateInvoicing}</LastUpdate>
          )}
            {!!infos.lastUpdatePog && (
            <LastUpdate>Última atualização POG: {infos.lastUpdatePog}</LastUpdate>
          )}
        </>
      )}
    </>
  );
};

export default PerformanceTabContent;
