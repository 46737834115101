import React from 'react';
import { useSelector } from 'react-redux';
import { isWithinInterval } from 'date-fns';

import { Link } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { formatDate } from 'util/datetime';
import { getPerformedSurveys } from 'state/modules/surveys/selectors';

import { Container, MiniBox } from './styles';

const Cards: React.FC = () => {
  const performedSurveys = useSelector(getPerformedSurveys);

  return (
    <Container>
      {performedSurveys &&
        performedSurveys.map(answeredSurvey => (
          <MiniBox key={`key-cards-${answeredSurvey.id}`}>
            <img src={answeredSurvey.picture} alt={answeredSurvey.title} />
            <h1>{answeredSurvey.title}</h1>
            <span>
              {` De ${formatDate(answeredSurvey.start_datetime, 'dd/MM/yyyy')}
                até
               ${formatDate(answeredSurvey.end_datetime, 'dd/MM/yyyy')}
              `}
            </span>
            {/* <p
              dangerouslySetInnerHTML={{ __html: answeredSurvey.description }}
            /> */}
            <p>{answeredSurvey.guidelines}</p>
            {answeredSurvey.answered_at && (
              <p>
                Respondida em :{' '}
                {formatDate(answeredSurvey.answered_at, 'dd/MM/yyyy')}
              </p>
            )}
            {answeredSurvey?.points?.map(item => {
              if (
                isWithinInterval(new Date(), {
                  start: new Date(item.start_date),
                  end: new Date(item.end_date),
                })
              ) {
                return (
                  <h2 key={item.id}>Vale {item.points_count} FMC Coins</h2>
                );
              }

              return <div key={item.id} />;
            })}

            {answeredSurvey.show_answer &&
              isWithinInterval(new Date(), {
                start: new Date(answeredSurvey.start_datetime),
                end: new Date(answeredSurvey.end_datetime),
              }) && (
                <Link
                  to={`${routeMap.InternalPage.answers}?item=${answeredSurvey.id}`}
                  className="btn"
                >
                  Ver respostas
                </Link>
              )}
          </MiniBox>
        ))}
    </Container>
  );
};

export default Cards;
