import styled from 'styled-components';

import DefaultUfSelect from 'components/Auth/Register/Form/Selects/UfsSelect';
import { Input as DefaultInput } from '../shared/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  position: relative;
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
`;

export const Input = styled(DefaultInput)`
  max-width: 350px;
  margin-right: 20px;

  ._inputcontainer {
    max-width: 300px;
  }
`;

export const UfSelect = styled(DefaultUfSelect)`
  margin-bottom: 15px;
  max-width: 120px;

  span {
    margin-bottom: 8px;
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const LocationHeader = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #821c0e;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 370px;
  align-items: center;
  span {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: #821c0e;
  }
`;
