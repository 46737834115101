/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { fixedPrecisionOf } from 'util/numbers';
import { useSimpleToast } from 'components/shared';
import { convertCSVToArray } from 'convert-csv-to-array';
import { formatPoints } from 'util/points';
import routeMap from 'routes/route-map';
import { useHistory } from 'react-router-dom';
import { getTeamPointsDistribution } from 'services/point-management/team-points-distributions';
import { useTeam } from './use-team';

type Context = {
  totalpoints: number;
  teampoint: any;
  distribution: any[];
  availablePointsToDistribute: number;
  hasSelectedMembers: boolean;
  flags: {
    equallyDistribution: boolean;
  };
  setEquallyDistribution: (value: boolean) => void;
  assignPointsTo: (memberId: any, value: any) => void;
  assignEqually: (amount: number) => void;
  enablePointsReceive: (memberId: number) => void;
  disablePointsReceive: (memberId: number) => void;
  selectAll: (role: string) => void;
  isAllSelected: (role: string) => boolean;
  getDistributionResume: () => DistributionResume[];
  clearDistribution: () => void;
  file: any;
  openModal: any;
  setOpenModal: any;
  handleFileChanges: any;
  valueAttributed: any;
  setDistribution: any;
  // savedDistribution: any;
};

export type MemberDistribution = {
  participant: {
    id: number;
    name: string;
    photo: string | null;
    role: string;
    roleId: number;
    subsidiary: string;
  };
  canReceivePoints: boolean;
  value: number;
};

const DistributionContext = createContext<Context>({} as Context);

type Props = {
  point: any;
};

type DistributionResume = {
  role: string;
  totalDistributed: number;
  totalMembersScored: number;
};

export const TeamDistributionProvider: React.FC<Props> = ({
  children,
  point,
}) => {
  const [distribution, setDistribution] = useState<MemberDistribution[]>([]);
  const [flagEquallyDistribution, setEquallyDistribution] = useState(false);
  const [file, setFile] = useState<any>();

  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const [pointsTeam, setPointsTeam] = useState<any>([]);

  const { team, establishmentId, campaignId } = useTeam();

  const [valueAttributed, setValueAttributed] = useState<any>();
  const { showToast } = useSimpleToast();
  const totalpoints = point.value;

  useEffect(() => {
    const fetchData = async () => {
      const response = await getTeamPointsDistribution({
        establishmentId,
        campaignId,
      });
      setPointsTeam(response.distribution);
    };

    fetchData();
  }, [campaignId, establishmentId]);

  const assignPointsTo = useCallback((memberId: number, value: number) => {
    setDistribution(state =>
      state.map(dist =>
        dist.participant.id === memberId
          ? {
              ...dist,
              value,
            }
          : dist,
      ),
    );
  }, []);

  const assignEqually = useCallback(
    (amount: number) => {
      /** Distribui uma quantidade de pontos igualmente entre membros selecionados
       * e caso não seja uma divisão exata, retorna o resto. */
      // TODO: Melhorar essa função
      const receivers = distribution.filter(d => d.canReceivePoints);
      const isExactDivision = amount % receivers.length === 0;
      const valuePerParticipant = fixedPrecisionOf(
        amount / receivers.length,
        2,
      );

      setDistribution(state =>
        state.map(d =>
          d.canReceivePoints
            ? {
                ...d,
                value: d.value + valuePerParticipant,
              }
            : d,
        ),
      );

      if (!isExactDivision) {
        const resto = fixedPrecisionOf(
          amount - valuePerParticipant * receivers.length,
          2,
        );

        if (resto < 0) {
          /** Se a soma da divisão dê alguns centavos
           * a mais que o valor total dos pontos, ajusta os valores entre
           * as distribuições */
          const qntParticipantsToSubtractCents = Number(resto * -1) * 100;

          // Subtrai os centavos a mais
          const participantsToRemove = distribution
            .filter(d => d.canReceivePoints)
            .slice(0, qntParticipantsToSubtractCents)
            .map(d => d.participant.id);

          setDistribution(state =>
            state.map(d =>
              participantsToRemove.includes(d.participant.id)
                ? {
                    ...d,
                    value: d.value - 0.01,
                  }
                : d,
            ),
          );
        } else {
          /** Distribui os centavos que sobraram */
          const qntParticipantsToAddCents = resto * 100;
          const participantsToAdd = distribution
            .filter(d => d.canReceivePoints)
            .slice(0, qntParticipantsToAddCents)
            .map(d => d.participant.id);
          setDistribution(state =>
            state.map(d =>
              participantsToAdd.includes(d.participant.id)
                ? {
                    ...d,
                    value: d.value + 0.01,
                  }
                : d,
            ),
          );
        }
      }
    },
    [distribution],
  );

  const enablePointsReceive = useCallback(
    (memberId: number) =>
      setDistribution(state =>
        state.map(dist =>
          dist.participant.id === memberId
            ? {
                ...dist,
                canReceivePoints: true,
              }
            : dist,
        ),
      ),
    [],
  );

  const disablePointsReceive = useCallback(
    (memberId: number) =>
      setDistribution(state =>
        state.map(dist =>
          dist.participant.id === memberId
            ? {
                ...dist,
                canReceivePoints: false,
              }
            : dist,
        ),
      ),
    [],
  );

  // Função auxiliar para obter o primeiro valor com um sketch válido
  function getFirstValidPoint(points: any) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 3; i++) {
      if (points?.[i]?.resume?.sketch) {
        return points[i];
      }
    }
    return null;
  }

  // Acessa pontos da equipe de maneira segura
  const getPointsTeam = getFirstValidPoint(pointsTeam);

  // Obtém o resumo dos pontos da equipe se existir
  const getResume = getPointsTeam?.resume;

  // Obtém os participantes do resumo se existir
  const getParticipants = getResume?.participants;

  // Verifica se há valores válidos na estrutura de dados
  const hasValues =
    pointsTeam?.[0]?.resume?.sketch ||
    pointsTeam?.[1]?.resume?.sketch ||
    pointsTeam?.[2]?.resume?.sketch;

  // Função auxiliar para criar dados de um participante
  const createUserData = (user: any) => ({
    id: user.id,
    name: user.name,
    role: user.role.name,
    roleId: user.role.id,
    photo: user.picture || null,
    subsidiary: user.subsidiary,
  });

  // Função auxiliar para construir a distribuição de um participante
  const buildParticipantDistribution = (
    userData: any,
    value: any,
    canReceivePoints: any,
  ) => ({
    participant: userData,
    value,
    canReceivePoints,
  });

  const buildDistribution = useCallback(() => {
    if (team.participants) {
      const distributionData: any = [];
      const roles = Object.keys(team.participants);

      roles.forEach(role => {
        const { list } = team.participants![role];

        list.forEach(user => {
          const userData = createUserData(user);

          if (hasValues) {
            const filteredParticipantSaved = getParticipants?.find(
              (item: any) => item.participant_id === user.id,
            );

            if (filteredParticipantSaved) {
              distributionData.push(
                buildParticipantDistribution(
                  userData,
                  filteredParticipantSaved.value,
                  false,
                ),
              );
            }
          } else {
            distributionData.push(
              buildParticipantDistribution(userData, 0, false),
            );
          }
        });
      });

      setDistribution(distributionData);
    }
  }, [team, getParticipants]);

  const getDistributionResume = useCallback(() => {
    /** Monta um resumo do que foi distribuído até o momento */
    const scoredMembers = distribution.filter(d => d.value > 0);
    const resume: DistributionResume[] = [];

    scoredMembers.forEach(member => {
      const roleResumeIndex = resume.findIndex(
        r => r.role === member.participant.role,
      );

      if (roleResumeIndex !== -1) {
        const r = resume[roleResumeIndex];
        resume[roleResumeIndex] = {
          role: r.role,
          totalDistributed: r.totalDistributed + member.value,
          totalMembersScored: r.totalMembersScored + 1,
        };
      } else {
        resume.push({
          role: member.participant.role,
          totalDistributed: member.value,
          totalMembersScored: 1,
        });
      }
    });

    return resume;
  }, [distribution]);

  const availablePointsToDistribute = useMemo(
    function calculateAvailablePointsToDistribute() {
      if (!distribution.length) return totalpoints;

      const distributedValue = distribution
        .map(d => d.value)
        .reduce((a, b) => a + b);

      if (
        fixedPrecisionOf(totalpoints, 2) ===
        fixedPrecisionOf(distributedValue, 2)
      )
        return 0;

      return totalpoints - distributedValue;
    },
    [distribution, totalpoints],
  );

  const isAllSelected = useCallback(
    (role: string) =>
      distribution.filter(
        d => d.participant.role === role && !d.canReceivePoints,
      ).length === 0,
    [distribution],
  );

  const selectAll = useCallback(
    (role: string) => {
      /** Seleciona todos os membros de determinado cargo */
      const isAllAlreadySelected = isAllSelected(role);

      if (isAllAlreadySelected) {
        setDistribution(state =>
          state.map(d =>
            d.participant.role === role
              ? {
                  ...d,
                  canReceivePoints: false,
                }
              : d,
          ),
        );
      } else {
        setDistribution(state =>
          state.map(d =>
            d.participant.role === role
              ? {
                  ...d,
                  canReceivePoints: true,
                }
              : d,
          ),
        );
      }
    },
    [isAllSelected],
  );

  const clearDistribution = useCallback(() => {
    setDistribution(state =>
      state.map(d => ({
        ...d,
        canReceivePoints: false,
        value: 0,
      })),
    );
    history.replace(routeMap.pointTeamDistribution);
  }, []);

  useEffect(() => {
    buildDistribution();
  }, [team, buildDistribution]);

  const handleFileChanges = (e: any) => {
    const fileArq = e.target.files[0];

    if (fileArq) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const csvData = event.target.result;
        const csvToArrayreceiving = convertCSVToArray(csvData, {
          header: false,
          separator: ';',
        });

        let csvToArray: any[] = [];

        csvToArrayreceiving.forEach((i: any) => {
          let pontos = i["Pontos\r"] ? i["Pontos\r"].toString().trim() : i["Pontos"] ? i["Pontos"].toString().trim() : '';
          if (pontos.includes(',')) {
            const reversed = pontos.replace(/\./g, '').replace(',', '.');
            const num = parseFloat(reversed);            
            if (!isNaN(num)) {
              i['Pontos'] = num;
            } else {
              i['Pontos'] = 0;
            }
          } else {
            const num = parseFloat(pontos.replace(/\./g, '').replace(',', '.'));
            if (!isNaN(num)) {
              i['Pontos'] = num;
            } else {
              i['Pontos'] = 0;
            }
          }
          csvToArray.push(i);
        });

        const emptyPoints = csvToArray.every(
          (y: any) => y.Pontos === '' || y.Pontos === undefined,
        );

        const hasAlreadyId = csvToArray.find(
          (y: any) =>
            y.Participant_Id &&
            csvToArray.filter((x: any) => y.Participant_Id === x.Participant_Id)
              .length > 1,
        );

        const getIdsCsv: any = [];
        const getIdsOfParticipants: any = [];

        csvToArray.every((y: any) => getIdsCsv.push(y.Participant_Id));
        distribution.map((y: any) =>
          getIdsOfParticipants.push(y.participant.id),
        );

        const hasTheSameValues = getIdsCsv.every(
          (y: any, index: any) => y === getIdsOfParticipants[index],
        );

        const sumOfPoints = csvToArray
          ? csvToArray.reduce((total: number, entry: any) => {
              const points = parseFloat(entry.Pontos);
              return !isNaN(points) ? total + points : total;
            }, 0)
          : 0;

        if (emptyPoints) {
          showToast({
            type: 'error',
            message: `
                    Parece que você importou uma planilha com os pontos vazios, por favor verifique.
                  `,
          });

          setOpenModal(!openModal);
          return;
        }

        if (hasAlreadyId || !hasTheSameValues) {
          showToast({
            type: 'error',
            message: `
              Parece que algum Participant_Id da planilha veio duplicado ou não existe, por favor verfique.
            `,
          });

          setOpenModal(!openModal);
          return;
        }

        if (sumOfPoints > availablePointsToDistribute) {
          showToast({
            type: 'error',
            message: `
              Você não pode importar mais pontos do que foram carregados. Por favor, verifique seus Pontos.
              Pontos Importados: ${formatPoints(sumOfPoints)} -
              Pontos Carregados ${formatPoints(availablePointsToDistribute)}
            `,
          });

          setOpenModal(!openModal);
          return;
        }
        setFile(csvToArray);
        setOpenModal(!openModal);
      };
      reader.readAsText(fileArq);
    }
  };

  useEffect(() => {
    const allParticipants: any[] = [];

    file &&
      file.forEach(
        (obj: any) =>
          obj &&
          allParticipants.push({
            participant_id: obj.Participant_Id,
            pontos: obj.Pontos,
          }),
      );
    setValueAttributed(allParticipants);
  }, [file]);

  // useEffect(() => {
  //   const fetchData = useCallback(async () => {
  //     const establishmentId =
  //       selectedEstablishment && selectedEstablishment.value !== null
  //         ? Number(selectedEstablishment.value)
  //         : 0;

  //     const response = await getTeamPointsDistribution({
  //       establishmentId,
  //       campaignId: selectedCampaign,
  //     });

  // }, [])

  return (
    <DistributionContext.Provider
      value={{
        file,
        setDistribution,
        handleFileChanges,
        valueAttributed,
        openModal,
        setOpenModal,
        teampoint: point,
        totalpoints,
        distribution,
        isAllSelected,
        selectAll,
        assignPointsTo,
        assignEqually,
        enablePointsReceive,
        disablePointsReceive,
        availablePointsToDistribute,
        clearDistribution,
        setEquallyDistribution,
        getDistributionResume,
        // savedDistribution,
        hasSelectedMembers:
          distribution.filter(d => d.canReceivePoints).length > 0,
        flags: {
          equallyDistribution: flagEquallyDistribution,
        },
      }}
    >
      {children}
    </DistributionContext.Provider>
  );
};

export const useTeamDistribution = () => useContext(DistributionContext);
