import { ActionCreator, ActionCreatorPayload } from '@types';
import { Subject } from 'services/contact/connected/privateSubjects';
import { Category } from 'services/contact/connected/privateCategories';
import { SET_SUBJECT, SET_CATEGORY, RESET } from './constants';

export const reset = (): ActionCreator<typeof RESET> => <const>{ type: RESET };

export const setSubject = (
  data: Subject,
): ActionCreatorPayload<typeof SET_SUBJECT, Subject> =>
  <const>{
    type: SET_SUBJECT,
    payload: data,
  };

export const setCategory = (
  data: Category,
): ActionCreatorPayload<typeof SET_CATEGORY, Category> =>
  <const>{
    type: SET_CATEGORY,
    payload: data,
  };

export type ContactActions = ReturnType<
  typeof reset | typeof setSubject | typeof setCategory
>;
