import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Form from 'components/Auth/Register/Form';
import indirectHasProducerLink from 'services/participants/indirectHasProducerLink';
import getDataRegulation from 'services/register/regulation/getDataRegulation';
import {
  Regulation,
  RegulationPolicy,
} from 'services/register/regulation/interfaces/IRegulation';

import NoResults from 'components/shared/NoResults';
import { PROFILES } from 'config/constants';
import { useToast } from 'context/ToastContext';
import { useSpring } from 'react-spring';
import { Participant } from 'services/auth/interfaces/Participant';
import history from 'services/history';
import addProducerLinkToParticipant from 'services/participants/addProducerLinkToParticipant';
import { acceptRegulation } from 'services/register/regulation';
import getRegulationPolicy from 'services/register/regulation/getRegulationPolicy';
import { showProfileChange } from 'state/modules/header/actions';
// import { ProdutorRegisterForm } from 'pages/primeiro-acesso/components/RegisterForms/produtor';
import DataRegulation from '../DataTermRegulation';
import Header from '../shared/Header';
import { Container, Content, contentAnimation } from '../shared/styles';

const ProducerRegistrator: React.FC = () => {
  const [showDataRegulation, setShowDataRegulation] = useState(true);
  const [dataRegulation, setDataRegulation] = useState<Regulation | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [regulationPolicy, setRegulationPolicy] = useState<
    RegulationPolicy | undefined
  >(undefined);
  const [policyExposed, setPolicyExposed] = useState<string>();

  const { addToast } = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchShowForm = async () => {
      const result = await indirectHasProducerLink();
      setShowForm(!result);
    };
    fetchShowForm();
  }, []);
  useEffect(() => {
    const fetchRegulationPolicy = async () => {
      const result = await getRegulationPolicy();
      setRegulationPolicy(result);
    };
    fetchRegulationPolicy();
  }, []);

  useEffect(() => {
    const fetchDataRegulation = async () => {
      const result = await getDataRegulation(PROFILES.producer);
      setDataRegulation(result);
    };
    fetchDataRegulation();
  }, []);

  const props = useSpring(contentAnimation);

  const saveParticipant = useCallback(
    async (data: Participant): Promise<void> => {
      try {
        const payload = {
          ...data,
          profile: PROFILES.producer,
          establishment: { id: 1, team_receives_points: false },
          role: { id: 26, identifier: 'produtor', name: 'Produtor' },
          campaign_id: 1,
        } as Participant;

        await addProducerLinkToParticipant(payload);

        if (dataRegulation) {
          const { id, version } = dataRegulation;
          await acceptRegulation(id, version, policyExposed);
        }

        addToast(
          {
            title: `Parabéns!
            Cadastro efetuado com sucesso no Juntos Produtor.`,
            type: 'success',
          },
          {
            onClose: () => {
              dispatch(showProfileChange());
            },
          },
        );

        history.push('/');
      } catch (err) {
        addToast({
          title:
            err.response?.data?.message ||
            'Problemas ao completar o seu cadastro!',
          type: 'error',
        });
      }
    },
    [addToast, dataRegulation, policyExposed, dispatch],
  );

  const onAcceptHandler = useCallback(() => {
    setShowDataRegulation(false);
  }, []);

  if (!showForm) {
    return <NoResults text="Você já possuí um vínculo com Produto!" />;
  }

  return showDataRegulation ? (
    <DataRegulation
      regulation={dataRegulation}
      profile={PROFILES.producer}
      onAccept={onAcceptHandler}
      regulationPolicy={regulationPolicy}
      onSetPolicy={setPolicyExposed}
    />
  ) : (
    <Container>
      <Header />
      <Content style={props}>
        <Form saveParticipant={saveParticipant} editing completeRegister />
      </Content>
    </Container>
  );
};

export default ProducerRegistrator;
