import React from "react";

interface DownloadModelProps {
  fileName?: string; // Nome do arquivo baixado
  modelFile: string; // URL do arquivo
  title?: string;
}

const DownloadModelLink: React.FC<DownloadModelProps> = ({ fileName, modelFile, title }) => {
  return (
    <a
      href={modelFile}
      download={fileName || "modelo-importador.xlsx"}
      style={{
        marginLeft: "auto",
        marginRight: "8px",
        paddingRight: "4px",
        cursor: "pointer",
        fontSize: "16px",
        textDecoration: "none",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
      onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
    >
      {title || "Baixar Modelo"}
    </a>
  );
};

export default DownloadModelLink;