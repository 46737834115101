import React from 'react';
import { Answer as AnswerData } from 'components/Surveys/types';

import { InputContainer } from './styles';

interface CheckBoxListProps {
  answer: AnswerData;
  topics: string[];
  onChangeHandler: (
    value: string,
    topic: string,
    deleteOperation: boolean,
  ) => void;
  canEdit: boolean;
}

const InputGridCheckBox: React.FC<CheckBoxListProps> = ({
  answer,
  topics,
  onChangeHandler,
  canEdit,
}) => {
  const { answer: answerValue, survey_participant_answers, id } = answer;
  return (
    <>
      {topics.map(topic => (
        <InputContainer key={`id-${topic}-${answerValue}`}>
          <input
            type="checkbox"
            id={`id-${topic}-${answerValue}`}
            value={`${topic}, ${answerValue}`}
            name={`checkbox-${topic}-${answerValue}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(answerValue, topic, !e.target.checked)
            }
            disabled={!canEdit}
            defaultChecked={survey_participant_answers.some(
              item =>
                item.survey_question_answer_id === id &&
                item.alternative_group === topic,
            )}
          />
        </InputContainer>
      ))}
    </>
  );
};

export default InputGridCheckBox;
