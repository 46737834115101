import React, { useEffect, useState, useCallback } from 'react';
import { Option } from 'components/shared/Select';
import { getTypeHiringForSelect as getData } from 'services/strategic-plans/actions';
import { BaseSelect } from '../../shared/styles';
import { Container } from './styles';

interface SelectTypeHiringProps {
  className?: string;
  placeholder?: string;
  inputRole: 'primary' | 'secondary';
  setValue(value: Option | null): void;
  value: Option | null;
  error?: string;
  disabled?: boolean;
  name: string;
  defaultValue?: string;
}

const SelectTypeHiring: React.FC<SelectTypeHiringProps> = ({
  className,
  placeholder,
  inputRole,
  setValue,
  value,
  error,
  disabled,
  name,
  defaultValue,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getData().then(data => setOptions(data));
  }, []);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  useEffect(() => {
    if (!defaultValue) {
      setOptions([]);
      setValue(value);
    }
  }, [defaultValue, setValue, value]);

  return (
    <Container>
      <BaseSelect
        label="Tipo de Contratação *"
        loadItems={loadItems}
        className={className}
        placeholder={placeholder}
        inputRole={inputRole}
        value={value}
        setValue={setValue}
        error={error}
        disabled={disabled}
        name={name}
      />
    </Container>
  );
};

export default SelectTypeHiring;
