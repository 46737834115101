/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import { isWithinInterval } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from 'context/ToastContext';

import { useLocation } from 'react-router-dom';
import { formatDate } from 'util/datetime';

import history from 'services/history';
import saveSurveyAnswers from 'services/surveys/saveSurveyAnswers';
import 'react-toastify/dist/ReactToastify.css';
import { pluginApi } from 'services/api';
import { getAnswers } from 'state/modules/surveys/selectors';
import { clearAnswers } from 'state/modules/surveys/actions';
import {
  QuestionsData,
  PointsData,
  SurveysDataForm,
} from 'components/Surveys/types';
import QuestionType from 'components/Surveys/QuestionType';
import RequiredQuestionsModal from 'components/Surveys/RequiredQuestionsModal';
import validateRequiredQuestions from '../Validators/validateRequiredQuestions';
import { buildRequestPayload } from './utils';

import {
  Container,
  Content,
  ContentInfo,
  MiniBox,
  Title,
  Form,
  Button,
  ActionsWrapper,
  Player,
  PlayerWrapper,
} from './styles';

const ProducerResearch: React.FC = () => {
  const answerList = useSelector(getAnswers);
  const location = useLocation();
  const [survey, setSurvey] = useState<SurveysDataForm>({} as SurveysDataForm);
  const [questions, setQuestions] = useState<QuestionsData[]>([]);
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requiredQuestions, setRequiredQuestions] = useState<string[]>([]);

  const { addToast } = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchSurveys() {
      const surveyId = location.search.replace('?item=', '');
      const response = await pluginApi.get(
        `participants/surveys/getSurveyById?survey_id=${surveyId}`,
      );

      setSurvey(response.data.data);
      setQuestions(response.data.data.survey_questions);
      setThankYouMessage(response.data.data.thank_you_message);
    }
    fetchSurveys();
  }, [location]);

  const handleSave = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const { valid, questions: required } = validateRequiredQuestions(
        answerList,
        questions,
      );
      if (!valid) {
        setRequiredQuestions(required ?? []);
        setIsModalOpen(true);
        return;
      }

      try {
        const surveyId = Number(location.search.replace('?item=', ''));
        const formData = buildRequestPayload(answerList);

        await saveSurveyAnswers({ surveyId, formData });

        addToast({
          title: thankYouMessage || `Obrigado por participar da pesquisa!`,
          type: 'success',
        });
        history.push('/pesquisas');
        dispatch(clearAnswers());
      } catch (err) {
        addToast({
          title:
            err.response?.data?.message ||
            'Falha ao enviar respostas. Por favor tente novamente',
          type: 'error',
        });
      }
    },
    [
      addToast,
      answerList,
      dispatch,
      location.search,
      questions,
      thankYouMessage,
    ],
  );

  const cancelClickHandler = useCallback(() => {
    history.push('/pesquisas');
    dispatch(clearAnswers());
  }, [dispatch]);

  return (
    <Container>
      <h1>Pesquisas</h1>
      <MiniBox key={`key-cards-${survey.id}`}>
        <Content>
          <h2>{survey.title}</h2>
          <p>
            {` De ${formatDate(
              survey.start_datetime,
              'dd/MM/yyyy',
            )} até ${formatDate(survey.end_datetime, 'dd/MM/yyyy')}`}
          </p>
          {/* Adicionar os pontos respeitando o entervalo das datas */}
          {survey?.points?.map((item: PointsData) => {
            if (
              isWithinInterval(new Date(), {
                start: new Date(item.start_date),
                end: new Date(item.end_date),
              })
            ) {
              return <h2>Vale {item.points_count} Coins</h2>;
            }
            return <></>;
          })}
        </Content>
        <ContentInfo>
          {survey.banner_picture && (
            <img src={survey.banner_picture} alt={survey.title} />
          )}
          <p dangerouslySetInnerHTML={{ __html: survey.description }} />
          {survey.video && (
            <PlayerWrapper>
              <Player url={survey.video} controls />
            </PlayerWrapper>
          )}
        </ContentInfo>
      </MiniBox>

      <hr />

      <Title>Perguntas</Title>

      <Form onSubmit={handleSave}>
        {questions.map(question => (
          <QuestionType
            type={Number(question.type)}
            name={question.name}
            question={question.question}
            answers={question.survey_question_answers}
            questioId={question?.id}
            answerId={question?.survey_question_answers[0]?.id}
            scaleType={question.scale_type}
            alternativeGroups={question.alternative_groups}
            alreadyAnswered={survey.already_answered}
            canEdit
          />
        ))}
        <ActionsWrapper>
          <Button type="button" onClick={cancelClickHandler}>
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </ActionsWrapper>
      </Form>

      <RequiredQuestionsModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        text="As questões listadas abaixo são obrigatórias!"
        questions={requiredQuestions}
      />
    </Container>
  );
};
export default ProducerResearch;
