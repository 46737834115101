import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 95px);
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background: #f6f6f6;
  padding: 5px 10rem;

  .campaigns-pagination {
    list-style-type: none;
    display: flex;
    justify-content: center;
    color: #707070;
    font-size: 0.9em;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 5px;
      width: 10px;
      height: 10px;

      a {
        display: flex;
        align-items: center;
      }

      svg {
        width: 23px;
        height: 23px;
      }

      &:hover {
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      &.selected {
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }
    }
  }

  @media (max-width: 820px) {
    padding: 5px 20px;
  }
`;

export const Teste = styled.div`
  max-width: 1100px;
  width: 100%;
  display: block;
  margin: 0 auto;
`;

export const LegendStatus = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.font.color.primary};
  margin: 22px 0 22px 22px;
  text-decoration: underline;
  text-align: left;

  svg {
    color: ${({ theme }) => theme.font.color.primary};
    margin: -4px 10px;
  }
`;
