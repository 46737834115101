import { pluginApi } from 'services/api';

export interface CreatePlanPayload {
  establishment_id: string | null;
}

export interface CreatePlanResponse {
  data: {
    uuid: string;
    id: number;
  };
}

export default async (
  createPlanPayload: CreatePlanPayload,
): Promise<CreatePlanResponse> => {
  return pluginApi.post('strategy-plans/add', createPlanPayload);
};
