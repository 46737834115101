import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HeaderBanner from 'assets/images/point-management/header_points.png';

export const Container = styled.div`
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 384px);
  color: ${({ theme }) => theme.font.color.secondary};
  background: #fff;
`;

export const Banner = styled.div`
  background-image: url(${HeaderBanner});
  background-position: 10% 0, center;
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`;

export const Content = styled.div`
  display: block;
  padding-bottom: 40px;
  max-width: 1100px;
  width: 100%;
  margin: auto;

  .title-extract {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-left: 4.8rem;
  }

  .content-date span {
    color: #193b4e;
    font-size: 1rem;
  }

  ._inputContainer {
    height: 35px;
    border-radius: 4.3px;
    border: 1.5px solid #193b4e;
  }

  .content-date {
    display: flex;
    margin: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .first-datepicker {
      display: flex;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      .align-datepickers {
        display: flex;
        align-items: center;
        width: 75.01%;
      }
    }

    .second-datepicker {
      display: flex;
      flex-direction: column;
      margin-left: 0.6rem;
    }
  }

  button {
    width: auto;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    margin: 0.3rem 1rem;
    position: relative;
    top: 0.3rem;
    height: 2.2rem;
    font-size: 0.9rem;
    padding: 7px 10px;
    display: block;
    background: red;
    margin-top: 10px;
    font-family: Helvetica-Neue-Bold;
  }

  button:disabled {
    opacity: 0.8;
    background: red !important;
    cursor: not-allowed;
  }

  button:hover {
    background: red;
  }

  > img {
    width: 100%;
  }
`;

export const ContentMobile = styled.div`
  .card-items {
    background: #fff;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .card-header {
    display: flex;
  }

  h4,
  a {
    margin-bottom: 0.5rem;
  }

  .details {
    margin-left: 1.5rem;
  }

  span {
    font-weight: bold;
  }
`;

export const ListTable = styled.table`
  width: 92%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;

  margin: 10px auto;
  tr {
    text-align: left;
  }

  th {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 14px;
    text-align: left;
    padding: 17px 10px;
    position: relative;
    top: 0.5rem;
  }
  td {
    background: #fff;
    padding: 7px 10px;
    border-top: 3px solid #efefef;
  }
`;

export const ListTableContainer = styled.div`
  width: 100%;
  padding-top: 10px;

  .searched {
    background: rgba(239, 239, 239, 255);
    padding-bottom: 10px;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
  }

  h3 {
    text-align: center;
  }
`;

export const PageTitle = styled.p`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 22px;
  position: relative;
  top: 40px;
  display: block;
  margin-right: auto;
  padding-left: 14.6%;
  margin-bottom: 32px;
  text-align: center !important;
`;

export const ExtractLegend = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 12px;

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`;

export const ExtractEmpty = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
  button {
    max-width: 150px;
    margin: 30px auto;
  }
`;

export const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  border-radius: ${({ theme }) => theme.button.primary.borderRadius};
  padding: 10px 15px;
  color: ${({ theme }) => theme.button.primary.fontColor};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-decoration: none;
  margin-top: 20px;
  display: inline-block;
`;

export const StatusContainer = styled.div`
  position: relative;
  padding: 12px;
  border-radius: 10px;
  background-size: 100% auto;
  width: 100%;
  background-position: center;
  @media (min-width: 1252px) {
    width: 75%;
  }
  color: ${({ theme }) => theme.font.color.primary};
`;

export const StatusContent = styled.div`
  position: relative;

  border-radius: 10px;
  a {
    text-decoration: none;
    color: #181818;
  }

  @media (max-width: 767px) {
    > * {
      margin: 20px 0;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
`;

export const StatusItem = styled.div`
  .first-box {
    padding: 28px 22px;
  }

  margin: 1rem 0;
`;
export const StatusTitle = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 18px;
`;
export const StatusBox = styled.div`
  background: #efefef;
  border-radius: 3px;
  height: 150px;
  margin-left: 1rem;
  margin-top: 10px;
  padding: 5px;
  width: 298px !important;
`;

export const Details = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  > div {
    padding: 36px;
    background: #fff;
    width: 80%;
    max-width: 750px;
    max-height: 80vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }
`;
export const TotalCoins = styled.div`
  select {
    width: 64%;
    height: 2rem;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    margin: 0 auto;
    display: block;
  }
  padding: 26px 0;

  h3 {
    font-size: 16px;
    color: 3b308a;
    font-weight: weight;
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  p {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    position: relative;
    top: 0.5rem;
    text-align: center;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;
