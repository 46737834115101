import React from 'react';
import Select from 'components/shared/Select';

interface Props {
  name: string;
  className?: string;
  inputRole?: 'primary' | 'secondary';
}

const reasons = [
  'O programa JUNTOS não serve para mim ou para minha empresa (inadequação).',
  'O programa JUNTOS não atendeu às minhas expectativas (insatisfação).',
  'Não consegui utilizar o programa JUNTOS (impossibilidade).',
  'O cadastro foi feito sem meu consentimento (inconsciência).',
];

const ReasonsSelect: React.FC<Props> = ({
  name,
  className,
  inputRole = 'secondary',
}) => {
  return (
    <Select
      loadItems={() => reasons.map(item => ({ value: item, title: item }))}
      name={name}
      className={className}
      label="Por que escolheu sair do programa?"
      inputRole={inputRole}
    />
  );
};

export default ReasonsSelect;
