import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { ActionsModalContainer, Container, TextArea } from './styles';

const ModalTitle = withStyles({
  root: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})(DialogTitle);

type Props = {
  isOpen: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
};

type FormData = {
  comment: string;
};

export const ReproveModal = ({
  isOpen,
  onCancel,
  onConfirm,
  loading = false,
}: Props) => {
  const methods = useForm<FormData>({
    validationSchema: Yup.object().shape({
      comment: Yup.string()
        .required('Campo obrigatório')
        .min(5, 'Mínimo de 5 caracteres'),
    }),
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(data => {
    onConfirm(data.comment);
  });

  return (
    <Dialog open={isOpen}>
      <ModalTitle>Reprovar distribuição</ModalTitle>
      <FormContext {...methods}>
        <form onSubmit={onSubmit}>
          <DialogContent dividers>
            <Container>
              <TextArea
                name="comment"
                label="Informe o motivo da reprovação:"
              />
            </Container>
          </DialogContent>
          <DialogActions>
            <ActionsModalContainer>
              <MaterialButton disabled={loading} onClick={onCancel}>
                Cancelar
              </MaterialButton>
              <MaterialButton disabled={loading} type="submit">
                Reprovar
              </MaterialButton>
            </ActionsModalContainer>
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};
