import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 250px;
`;

export const Container = styled.div`
  width: 100%;
  margin: 25px 0;

  .loading-wrapper {
    display: flex;
    place-content: center;
    padding: 25px 0;

    strong.empty {
      color: #000000;
      opacity: 0.6;
    }
  }

  .cards-wrapper {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @media screen and (max-width: 590px) {
      padding: 0 15px;
    }
  }

  .error {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
