import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import routeMap from 'routes/route-map';
import { Modal, Container, Close } from './styles';

interface Props {
  expiredMessage: string;
}
const ExpiredPassword: React.FC<Props> = ({ expiredMessage }) => {
  const history = useHistory();
  const location = useLocation();
  const goToEditProfilePage = () => {
    history.push(routeMap.profile);
  };
  const userIsInProfilePage = location.pathname === routeMap.profile;
  return (
    <Modal
      isOpen={!userIsInProfilePage}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      type="primary"
    >
      <Container>
        <h3>Senha expirada!</h3>
        <p>{expiredMessage}</p>
      </Container>
      <Close>
        <button type="button" onClick={goToEditProfilePage}>
          Alterar minha senha
        </button>
      </Close>
    </Modal>
  );
};
export default ExpiredPassword;
