import React, { useCallback, KeyboardEvent, useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';

import PasswordHelp from 'components/shared/PasswordHelp';
import { PROFILES } from 'config/constants';
import { Participant } from 'services/auth/interfaces/Participant';
import { useAuth } from 'context/AuthContext';
import saveParticipant from 'services/register/saveParticipant';
import { useToast } from 'context/ToastContext';
import numbersOnly from 'util/numbersOnly';
import editParticipant from 'services/auth/editParticipant';
import updateToCross from 'services/participants/addProducerLinkToParticipant';
import ComponentsByProfile from '../Commom/ComponentsByProfile';
import ExtraFieldsForParticipant from '../Commom/ExtraFieldsForParticipant';
import AddressFields from '../Commom/AddressFields';
import {
  Container,
  Title,
  Separator,
  Avatar,
  Input,
  PasswordInput,
  Button,
  BoxPhone,
  NextButtonContainer,
  NextButton,
  ComoFicouConhecendoSelectStyled,
} from './styles';
import { GenderSelect, ChannelSelect } from '../Commom/styles';
import { getValidationSchema } from './validation';

interface Props {
  participant: Participant;
  editing: boolean;
  autoIndicate: boolean;
  setAutoIndicate(checked: boolean): void;
  inputRole: 'primary' | 'secondary';
  actived: boolean;
  indicatorCode: string;
  completeRegister?: boolean;
}

type FormData = {
  nick_name: string;
  name: string;
  email: string;
  cpf: string;
  area_code: string;
  cell_phone: string;
  formatted_birth_date: string;
  mother_name: string;
  father_name: string;
  formatted_shipping_date: string;
  voter_registration: string;
  pis_nis: string;
  password?: string;
  password_confirmation?: string;
  address: Pick<Participant, 'address'>['address'];
  gender_select: { title: string; value: string } | null;
  public_place_select: { title: string; value: string } | null;
  state_code_select: { title: string; value: string } | null;
  get_to_know_select: { title: string; value: string } | null;
  medium_select: { title: string; value: string } | null;
  rg_emitter_uf_select: { title: string; value: string } | null;
  uf_naturalness_select: { title: string; value: string } | null;
  naturalness_select: { title: string; value: string } | null;
  marital_status_code_select: { title: string; value: string } | null;
  code_schooling_select: { title: string; value: string } | null;
};

const PersonalDataForm: React.FC<Props> = ({
  participant,
  editing,
  inputRole,
  autoIndicate,
  setAutoIndicate,
  actived,
  completeRegister,
  indicatorCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [valueInput, setvalueInput] = useState<string>('');
  const [variavel, setVariavel] = useState<boolean>(false);
  const { addToast } = useToast();
  const { isPasswordExpired, signOut } = useAuth();

  const isEditing =
    window.location.pathname === '/editar-perfil' ||
    window.location.pathname === '/completar-cadastro-produtor';

  const formMethods = useForm<FormData>({
    mode: 'onSubmit',
    validationSchema: getValidationSchema(participant.profile, isEditing),
    defaultValues: {
      ...participant,
      cpf: participant.cpf,
      nick_name: participant.nick_name,
      name: participant.name,
      area_code: participant.area_code,
      cell_phone: participant.cell_phone,
      email: participant.email,
      address: participant.address,
      gender_select: participant.gender
        ? {
            value: participant.gender,
            title:
              participant.gender.toLowerCase() === 'm'
                ? 'Masculino'
                : 'Feminino',
          }
        : null,
      get_to_know_select: participant.get_to_know
        ? {
            title: participant.get_to_know || '',
            value: participant.get_to_know || '',
          }
        : null,
      medium_select: participant.medium
        ? {
            title: participant.medium || '',
            value: participant.medium || '',
          }
        : null,
      formatted_birth_date:
        participant?.birth_date?.replace(
          /(\d{4})-(\d{2})-(\d{2}).*/,
          '$3/$2/$1',
        ) || '',
      public_place_select: participant.address?.public_place
        ? {
            title: participant.address.public_place || '',
            value: participant.address.public_place || '',
          }
        : null,
      state_code_select: participant.address?.state_code
        ? {
            title: participant.address.state_code || '',
            value: participant.address.state_code || '',
          }
        : null,
      rg_emitter_uf_select: participant.rg_emitter_uf
        ? {
            title: participant.rg_emitter_uf,
            value: participant.rg_emitter_uf,
          }
        : null,
      mother_name: participant.mother_name || '',
      father_name: participant.father_name || '',
      uf_naturalness_select: participant.uf_naturalness
        ? {
            title: participant.uf_naturalness,
            value: participant.uf_naturalness,
          }
        : null,
      naturalness_select: participant.naturalness
        ? {
            title: participant.naturalness,
            value: participant.naturalness,
          }
        : null,
      marital_status_code_select: participant.marital_status_code
        ? {
            title: participant.marital_status_code,
            value: participant.marital_status_code,
          }
        : null,
      code_schooling_select: participant.code_schooling
        ? {
            title: participant.code_schooling,
            value: participant.code_schooling,
          }
        : null,
      formatted_shipping_date:
        participant?.shipping_date?.replace(
          /(\d{4})-(\d{2})-(\d{2}).*/,
          '$3/$2/$1',
        ) || '',
      voter_registration: participant.voter_registration || '',
      pis_nis: participant.pis_nis || '',
    },
  });
  const { handleSubmit: submit } = formMethods;

  const shouldShowMarketPlaceFields =
    autoIndicate ||
    (participant.profile !== PROFILES.producer &&
      participant.profile !== PROFILES.fmc &&
      editing);

  const onKeyDownHandler = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.keyCode > 47 && e.keyCode < 58) ||
      (e.keyCode > 95 && e.keyCode < 106)
    ) {
      e.preventDefault();
    }
  }, []);

  const getValueInput = useCallback(
      (value) => {
        setvalueInput(value.value);
      }, []
    );

  let handleSubmit;

  if(window.location.pathname === '/completar-cadastro-produtor'){
    handleSubmit = async (data: any) => {
      setLoading(true);

      try {
        const request = {
          ...participant,
          ...data,
          area_code: numbersOnly(data.area_code),
          cell_phone: numbersOnly(data.cell_phone),
          cpf: numbersOnly(data.cpf),
          pis_nis: numbersOnly(data.pis_nis),
          regulations_accepted: [],
          get_to_know: data?.get_to_know_select?.value || '',
          members_group: [...(participant.members_group || [])],
          gender: data?.gender_select?.value || '',
          address: {
            ...data.address,
            public_place: data?.public_place_select?.value || '',
            state_code: data?.state_code_select?.value || '',
          },
          birth_date: data?.formatted_birth_date,
          rg_emitter_uf: data?.rg_emitter_uf_select?.value || '',
          access_premio_ideall:
            participant.profile !== PROFILES.focalPoint || autoIndicate,
          indicator_code_used: indicatorCode,
          medium: data?.medium_select?.value || '',
          mother_name: data.mother_name,
          father_name: data.father_name,
          uf_naturalness: data.uf_naturalness_select?.value || '',
          naturalness: data.naturalness_select?.value || '',
          marital_status_code: data.marital_status_code_select?.value || '',
          code_schooling: data.code_schooling_select?.value || '',
          shipping_date: data.formatted_shipping_date,
          voter_registration: data.voter_registration,
        } as Participant;
        
        updateToCross(request);

        addToast({
          title: 'Solicitação enviada com sucesso',
          type: 'success',
        });
        
        signOut();

      } catch (error) {
        addToast({
          title:
            error.response?.data?.message ||
            'Problemas ao completar o seu cadastro!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }        
  } else {

    handleSubmit = submit(async data => {
      setLoading(true);
  
      if (!editing) {
        try {
          await saveParticipant({
            ...(data as any),
            get_to_know: data?.get_to_know_select?.value || '',
            members_group: [...(participant.members_group || [])],
            gender: data?.gender_select?.value || '',
            address: {
              ...data.address,
              public_place: data?.public_place_select?.value || '',
              state_code: data?.state_code_select?.value || '',
            },
            birth_date: data?.formatted_birth_date,
            rg_emitter_uf: data?.rg_emitter_uf_select?.value || '',
            access_premio_ideall:
              participant.profile !== PROFILES.focalPoint || autoIndicate,
            indicator_code_used: indicatorCode,
            medium: data?.medium_select?.value || '',
            mother_name: data.mother_name,
            father_name: data.father_name,
            uf_naturalness: data.uf_naturalness_select?.value || '',
            naturalness: data.naturalness_select?.value || '',
            marital_status_code: data.marital_status_code_select?.value || '',
            code_schooling: data.code_schooling_select?.value || '',
            shipping_date: data.formatted_shipping_date,
            voter_registration: data.voter_registration,
            pis_nis: data.pis_nis,
          });
  
          addToast({
            title: 'Dados atualizados com sucesso',
            type: 'success',
          });
  
          // window.location.reload();
        } catch (error) {
          addToast({
            title:
              error.response?.data?.message ||
              'Ocorreu um erro ao salvar seus dados, tente novamente',
            type: 'error',
          });
        } finally {
          setLoading(false);
        }
      }
  
      if (editing && window.location.pathname === '/editar-perfil') {
        try {
          const request = {
            ...participant,
            ...data,
            area_code: numbersOnly(data.area_code),
            cell_phone: numbersOnly(data.cell_phone),
            cpf: numbersOnly(data.cpf),
            pis_nis: numbersOnly(data.pis_nis),
            regulations_accepted: [],
            get_to_know: data?.get_to_know_select?.value || '',
            members_group: [...(participant.members_group || [])],
            gender: data?.gender_select?.value || '',
            address: {
              ...data.address,
              public_place: data?.public_place_select?.value || '',
              state_code: data?.state_code_select?.value || '',
            },
            birth_date: data?.formatted_birth_date,
            rg_emitter_uf: data?.rg_emitter_uf_select?.value || '',
            access_premio_ideall:
              participant.profile !== PROFILES.focalPoint || autoIndicate,
            indicator_code_used: indicatorCode,
            medium: data?.medium_select?.value || '',
            mother_name: data.mother_name,
            father_name: data.father_name,
            uf_naturalness: data.uf_naturalness_select?.value || '',
            naturalness: data.naturalness_select?.value || '',
            marital_status_code: data.marital_status_code_select?.value || '',
            code_schooling: data.code_schooling_select?.value || '',
            shipping_date: data.formatted_shipping_date,
            voter_registration: data.voter_registration,
          } as Participant;
  
          await editParticipant(request);
          addToast({
            title:  participant.role.identifier === 'produtor' ? 
              'Dados atualizados com sucesso' :
              'Dados atualizados com sucesso. Para que possamos validar seus dados para resgate é necessário que todos os campos deste formulário sejam preenchidos.',
            type: 'success',
            refresh: 'true',
          });
        } catch (error) {
          addToast({
            title:
              error.response?.data?.message ||
              'Problemas ao completar o seu cadastro!',
            type: 'error',
          });
        } finally {
          setLoading(false);
        }
      }
    });

  }
  
  useEffect(
    function scrollToPasswordUpdate() {
      if (loading || !isPasswordExpired) return;

      /** Foca no campo de nova senha caso a senha atual esteja expirada. */
      const passwordInput = document.querySelector('input[type=password]');

      if (passwordInput) {
        passwordInput.scrollIntoView({
          behavior: 'smooth',
        });

        setTimeout(() => {
          (passwordInput as HTMLInputElement).focus();
        }, 300);
      }
    },
    [isPasswordExpired, loading],
  );

  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    const fetchData = async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
    };
 
    fetchData();
  }, []);
 
  useEffect(() => {
    if (!isLoading && window.location.pathname === '/editar-perfil') {
      const inputs = document.querySelectorAll('input');
 
      inputs.forEach((input) => {
          const ignoreInputs = [
              'password',
              'password_confirmation',
              'address.complement'
          ];
          
          if (!input.value && !ignoreInputs.includes(input.name)) {
              const parentDiv = input.closest('div._inputContainer')?.parentElement;
              const span = parentDiv?.querySelector('span');
              if (span) {
                  const existingMessage = span.querySelector('.pendente-message');
                  if (!existingMessage) {
                      const message = document.createElement('span');
                      message.textContent = '*Preenchimento pendente';
                      message.className = 'pendente-message';
                      message.style.color = 'red';
                      message.style.marginLeft = '5px';
                      span.appendChild(message);
                  }
              }
          }
      });
    }
  }, [isLoading]); // Executa sempre que o estado de carregamento mudar

  return (
    <>
      {window.location.pathname === '/editar-perfil' ? (
        <Container actived={actived}>
          <FormContext {...formMethods}>
            <form onSubmit={handleSubmit}>
              {!completeRegister && (
                <Avatar name="picture" inputRole={inputRole} />
              )}
              <ComponentsByProfile
                participant={participant}
                inputRole={inputRole}
              />
              <Input
                name="nick_name"
                label="Como gostaria de ser chamado*"
                inputRole={inputRole}
                disabled={completeRegister || participant.esocial_valid}
                onKeyDown={onKeyDownHandler}
              />
              <Input
                name="name"
                label="Nome completo*"
                inputRole={inputRole}
                disabled={
                  participant.profile === 'FMC' ||
                  participant.profile === 'FOCALPOINT' ||
                  completeRegister ||
                  participant.esocial_valid ||
                  participant.esocial_validation_status ===
                    'Aguardando aprovação'
                }
                onKeyDown={onKeyDownHandler}
              />
              <Input
                name="email"
                label="E-mail*"
                inputRole={inputRole}
                disabled={
                  participant.profile === 'FMC' ||
                  participant.profile === 'FOCALPOINT' ||
                  completeRegister
                }
              />              
              <Input
                name="cpf"
                label="CPF*"
                numbersOnly
                pattern="XXX.XXX.XXX-XX"
                inputRole={inputRole}
                disabled={participant.cpf !== ''}
              />
              {
                participant.profile === PROFILES.producer && (
                  <>
                    <Input
                      name="state_registration"
                      label="Inscrição Estadual"
                      inputRole="secondary"
                      pattern="XX.XXX.XXXX-X"
                      numbersOnly
                    />
                  </>
                )
              }

              <BoxPhone>
                <Input
                  name="area_code"
                  numbersOnly
                  pattern="(XX)"
                  label="DDD*"
                  inputRole={inputRole}
                  disabled={completeRegister}
                />
                <Input
                  name="cell_phone"
                  numbersOnly
                  label="Celular*"
                  pattern="X XXXX-XXXX"
                  inputRole={inputRole}
                />
              </BoxPhone>

              {participant.profile === PROFILES.producer && (
                <>
                  <GenderSelect
                    name="gender_select"
                    inputRole={inputRole}
                    disabled={participant.esocial_valid}
                  />

                  <ChannelSelect
                    name="medium_select"
                    inputRole={inputRole}
                    label="Em qual Canal você compra os produtos FMC?*"
                  />

                  <ComoFicouConhecendoSelectStyled
                    name="get_to_know_select"
                    inputRole={inputRole}
                  />
                  <Input
                    name="formatted_birth_date"
                    label="Data de nascimento* (idade mínima: 18 anos)"
                    inputRole={inputRole}
                    pattern="XX/XX/XXXX"
                    numbersOnly
                    disabled={completeRegister || participant.esocial_valid}
                  />
                </>
              )}
              {shouldShowMarketPlaceFields && editing && (
                <>
                  <ExtraFieldsForParticipant
                    inputRole={inputRole}
                    participant={participant}
                    autoIndicate={autoIndicate}
                    setAutoIndicate={setAutoIndicate}
                  />
                </>
              )}
              {participant.profile === PROFILES.producer && (
                <AddressFields inputRole={inputRole} />
              )}
              {(!completeRegister &&
                participant.profile !== PROFILES.producer) ||
              isPasswordExpired ? (
                <>
                  <Separator />
                  <Title>Segurança</Title>
                  {isPasswordExpired && (
                    <p className="expired-password-error-alert">
                      {participant.role.expiratedPasswordMsg}
                    </p>
                  )}
                  <PasswordInput
                    name="password"
                    label="Senha"
                    inputRole={inputRole}
                    onChange={e => getValueInput(e.target)}
                    onFocus={() => setVariavel(true)}
                    onBlur={() => setVariavel(false)}
                  />
                  {
                    variavel &&
                    <PasswordHelp checkedText={valueInput} />
                  }
                  <PasswordInput
                    name="password_confirmation"
                    label="Confirmar Senha"
                    inputRole={inputRole}
                  />
                  <Button
                    type="submit"
                    buttonRole="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    Confirmar
                  </Button>
                </>
              ) : (
                <NextButtonContainer>
                  <NextButton
                    type="submit"
                    buttonRole="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    Salvar
                  </NextButton>
                </NextButtonContainer>
              )}
            </form>
          </FormContext>
        </Container>
      ) : (
        <Container actived={actived}>
          <FormContext {...formMethods}>
            <form onSubmit={handleSubmit}>
              {!completeRegister && (
                <Avatar name="picture" inputRole={inputRole} />
              )}
              <Input
                name="name"
                label="Nome completo*"
                inputRole={inputRole}
                disabled={
                  participant.profile === 'FMC' ||
                  participant.profile === 'FOCALPOINT' ||
                  completeRegister ||
                  participant.esocial_valid ||
                  participant.esocial_validation_status ===
                    'Aguardando aprovação'
                }
                onKeyDown={onKeyDownHandler}
              />
              <Input
                name="nick_name"
                label="Como gostaria de ser chamado*"
                inputRole={inputRole}
                disabled={completeRegister || participant.esocial_valid}
                onKeyDown={onKeyDownHandler}
              />
              <Input
                name="email"
                label="E-mail*"
                inputRole={inputRole}
                disabled={
                  participant.profile === 'FMC' ||
                  participant.profile === 'FOCALPOINT' ||
                  completeRegister
                }
              />
              <Input
                name="cpf"
                label="CPF*"
                numbersOnly
                pattern="XXX.XXX.XXX-XX"
                inputRole={inputRole}
                disabled={participant.cpf !== ''}
              />
              <BoxPhone>
                <Input
                  name="area_code"
                  numbersOnly
                  pattern="(XX)"
                  label="DDD*"
                  inputRole={inputRole}
                  disabled={completeRegister}
                />
                <Input
                  name="cell_phone"
                  numbersOnly
                  label="Celular*"
                  pattern="X XXXX-XXXX"
                  inputRole={inputRole}
                />
              </BoxPhone>
              <>
                <GenderSelect
                  name="gender_select"
                  inputRole={inputRole}
                  disabled={participant.esocial_valid}
                />

                <ChannelSelect
                  name="medium_select"
                  inputRole={inputRole}
                  label="Em qual estabelcimento você compra os produtos FMC?*"
                />

                <ComoFicouConhecendoSelectStyled
                  name="get_to_know_select"
                  inputRole={inputRole}
                />
                <Input
                  name="formatted_birth_date"
                  label="Data de nascimento* (idade mínima: 18 anos)"
                  inputRole={inputRole}
                  pattern="XX/XX/XXXX"
                  numbersOnly
                  // disabled={completeRegister || participant.esocial_valid}
                />
              </>
              )}
              <AddressFields inputRole={inputRole} />
              {(!completeRegister &&
                participant.profile !== PROFILES.producer) ||
              isPasswordExpired ? (
                <>
                  <Separator />
                  <Title>Segurança</Title>
                  {isPasswordExpired && (
                    <p className="expired-password-error-alert">
                      {participant.role.expiratedPasswordMsg}
                    </p>
                  )}
                  <PasswordInput
                    name="password"
                    label="Senha"
                    inputRole={inputRole}
                    help={PasswordHelp}
                  />
                  <PasswordInput
                    name="password_confirmation"
                    label="Confirmar Senha"
                    inputRole={inputRole}
                  />
                  <Button
                    type="submit"
                    buttonRole="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    Confirmar
                  </Button>
                </>
              ) : (
                <NextButtonContainer>
                  <NextButton
                    type="submit"
                    buttonRole="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    Salvar
                  </NextButton>
                </NextButtonContainer>
              )}
            </form>
          </FormContext>
        </Container>
      )}
    </>
  );
};

export default PersonalDataForm;
