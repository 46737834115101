/* eslint-disable @typescript-eslint/no-explicit-any */
export function isPromise(p: any) {
  if (
    (typeof p === 'object' && typeof p.then === 'function') ||
    p?.constructor.name === 'AsyncFunction'
  ) {
    return true;
  }

  return false;
}
