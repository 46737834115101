import { Award, Channel } from 'state/modules/points-simulator/interfaces';
import { EstablishmentTypes } from 'config/constants';
import simulatedSale from './simulated-sale';
import simulatedPoints from './simulated-points';
import totalPoints from './total-points';

interface Props {
  totalRevenues: number;
  totalPog: number;
  award: Award;
  channelType: EstablishmentTypes;
  channel: Channel;
}

export default (props: Props) => {
  const { totalPog, totalRevenues, award, channelType, channel } = props;

  return `
  ${simulatedSale({ totalRevenues, totalPog })}
  ${simulatedPoints({ award, channelType, channel })}
  ${totalPoints({ award, channelType, channel })}
`;
};
