/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState } from 'react';
import { IoLogoInstagram } from 'react-icons/io';
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { AiOutlineLink } from 'react-icons/ai';
import { FiChevronsDown } from 'react-icons/fi';

import Logo from 'assets/images/logo.png';
import FMCLogo from 'assets/images/auth/auth-fmc-logo.png';
import Phone from 'assets/images/auth/auth-phone.jpg';
import AppStore from 'assets/images/auth/auth-app-store.svg';
import GooglePlay from 'assets/images/auth/auth-google-play.svg';
import Step1 from 'assets/images/auth/auth-step-1.svg';
import Step2 from 'assets/images/auth/auth-step-2.svg';
import Step3 from 'assets/images/auth/auth-step-3.svg';
import Step4 from 'assets/images/auth/auth-step-4.svg';
import CookieModal from 'components/Auth/Modals/CookieModal';

import Contact from 'components/Contact';

import { getMaintenance } from 'services/blockAccess';
import Maintenance from 'pages/BlockAccess/Maintenance';
import SignUp from './Form/SignUp';
import SignIn from './Form/SignIn';

import {
  FormContainer,
  Overlay,
  Header,
  HeaderLogo,
  HeaderDescription,
  SignFormContainer,
  Container,
  StepsContainer,
  MobileInfoContainer,
  MobileInfoContent,
  Background,
  SocialContainer,
  Footer,
  ScrollArrow,
} from './styles';

const FMC_EXTERNAL_LINKS = {
  INSTAGRAM: 'https://www.instagram.com/fmcagricola/',
  FACEBOOK: 'https://pt-br.facebook.com/fmcagricola/',
  LINKEDIN: 'https://www.linkedin.com/company/fmc-agricola/',
  YOUTUBE: 'https://www.youtube.com/channel/UCWROGSXVKKf5Ph_Kgfzb-OA',
  WEBSITE: 'https://www.fmcagricola.com.br/',
  APP_STORE: 'https://apps.apple.com/us/app/juntos-fmc/id1523719913',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=br.com.juntosfmc.app',
};

const Auth: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [maintenanceResponse, setMaintenanceResponse] = useState(0);
  const [isOpenCookie, setIsOpenCookie] = useState(true);

  const stepsContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleRegisterClick = () => {
    const registerInput = document.querySelector('input[name="cpf_or_name"]');

    if (registerInput) {
      registerInput.scrollIntoView({
        behavior: 'smooth',
      });
      (registerInput as HTMLInputElement).focus();
    }
  };

  const handleStepDotPress = (step: number) => {
    setActiveStep(step);

    if (stepsContainerRef.current) {
      const screenSize = stepsContainerRef.current?.offsetWidth;

      stepsContainerRef.current.scrollTo({
        behavior: 'smooth',
        left: screenSize * (step - 1),
      });
    }
  };

  const handleScrollDownClick = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: document.body.clientHeight,
    });
  };

  useEffect(
    function handleStepsDot() {
      function onStepScroll() {
        if (!stepsContainerRef.current) return;

        const screenSize = stepsContainerRef.current?.offsetWidth;
        const leftScroll = stepsContainerRef.current?.scrollLeft;
        const viewingStep = Math.round(leftScroll / screenSize) + 1;

        setActiveStep(viewingStep);
      }

      if (stepsContainerRef.current) {
        stepsContainerRef.current.addEventListener('scroll', onStepScroll);
      }

      return () => {
        stepsContainerRef?.current?.removeEventListener('scroll', onStepScroll);
      };
    },
    [stepsContainerRef],
  );

  useEffect(() => {
    getMaintenance().then(
      (response: { data: React.SetStateAction<number> }) => {
        setMaintenanceResponse(response.data);
      },
    );
  }, []);

  const isInMaintenanceMode = maintenanceResponse === 1;

  if (isInMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <Container ref={containerRef}>
      <FormContainer>
        <Overlay>
          <Header>
            <HeaderLogo>
              <img src={Logo} alt="FMC Logo" loading="lazy" />
            </HeaderLogo>
            <HeaderDescription>
              <h2>
                <b>JUNTOS</b> é o programa da FMC que reconhece sua parceria,
                fortalecendo o agronegócio brasileiro para, lado a lado,
                crescermos cada vez mais.
              </h2>
            </HeaderDescription>
          </Header>

          <SignFormContainer>
            <SignIn />
            <SignUp />
          </SignFormContainer>
        </Overlay>

        <Background />

        <ScrollArrow>
          <FiChevronsDown
            size={30}
            onClick={handleScrollDownClick}
            role="button"
          />
        </ScrollArrow>
      </FormContainer>

      <StepsContainer>
        <div className="title">
          <h1>PRODUTOR, COMECE AGORA MESMO A GANHAR!</h1>
          <h2>São apenas 4 passos para conquistar sua premiação.</h2>
        </div>

        <div className="steps" ref={stepsContainerRef}>
          <div className="steps__step">
            <img src={Step1} alt="Step 1" />
            <span>
              Compre produtos <br />
              da FMC
            </span>
          </div>
          <div className="steps__step">
            <img src={Step2} alt="Step 2" />
            <span>
              Cadastre suas <br />
              notas fiscais
            </span>
          </div>
          <div className="steps__step">
            <img src={Step3} alt="Step 3" />
            <span>
              Conquiste FMC <br />
              Coins
            </span>
          </div>
          <div className="steps__step">
            <img src={Step4} alt="Step 4" />
            <span>Resgate prêmios</span>
          </div>
        </div>

        <div className="steps__dots">
          <div
            className={`steps__dots___dot ${activeStep === 1 ? 'active' : ''}`}
            onClick={() => handleStepDotPress(1)}
          />
          <div
            className={`steps__dots___dot ${activeStep === 2 ? 'active' : ''}`}
            onClick={() => handleStepDotPress(2)}
          />
          <div
            className={`steps__dots___dot ${activeStep === 3 ? 'active' : ''}`}
            onClick={() => handleStepDotPress(3)}
          />
          <div
            className={`steps__dots___dot ${activeStep === 4 ? 'active' : ''}`}
            onClick={() => handleStepDotPress(4)}
          />
        </div>

        <div className="steps__action">
          <button type="button" onClick={handleRegisterClick}>
            CADASTRE-SE JÁ!
          </button>
        </div>
      </StepsContainer>

      <MobileInfoContainer>
        <MobileInfoContent>
          <div className="image">
            <img src={Phone} alt="Juntos FMC Mobile" />
          </div>

          <div className="info">
            <div className="info__title">
              <span>TENHA O JUNTOS NA PALMA DA SUA MÃO!</span>
            </div>
            <div className="info__desc">
              <p>
                Acesse o programa a qualquer hora e de qualquer lugar.
                <br />
                Baixe o app no seu celular.
              </p>
            </div>
            <div className="info__stores">
              <a href={FMC_EXTERNAL_LINKS.APP_STORE}>
                <img src={AppStore} alt="App store" />
              </a>
              <a href={FMC_EXTERNAL_LINKS.PLAY_STORE}>
                <img src={GooglePlay} alt="Play store" />
              </a>
            </div>
          </div>
        </MobileInfoContent>
      </MobileInfoContainer>

      <SocialContainer>
        <div className="logo">
          <img src={FMCLogo} alt="fmc logo" />
        </div>

        <div className="social">
          <a
            className="social__network"
            href={FMC_EXTERNAL_LINKS.INSTAGRAM}
            rel="noopener noreferrer"
            target="_blank"
          >
            <IoLogoInstagram size={15} />
          </a>
          <a
            className="social__network"
            href={FMC_EXTERNAL_LINKS.FACEBOOK}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebookF size={15} />
          </a>
          <a
            className="social__network"
            href={FMC_EXTERNAL_LINKS.LINKEDIN}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaLinkedinIn size={15} />
          </a>
          <a
            className="social__network"
            href={FMC_EXTERNAL_LINKS.YOUTUBE}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaYoutube size={15} />
          </a>
          <a
            className="social__network"
            href={FMC_EXTERNAL_LINKS.WEBSITE}
            rel="noopener noreferrer"
            target="_blank"
          >
            <AiOutlineLink size={15} />
          </a>
        </div>
      </SocialContainer>

      <Footer>
        <span>
          Copyright &copy; {new Date().getFullYear()} FMC Corporation. Todos os
          direitos reservados.
        </span>
      </Footer>

      <Contact initialPosition="right-bottom" size={60} />

      <CookieModal
        isOpen={isOpenCookie}
        onRequestClose={() => setIsOpenCookie(false)}
        initialPosition="bottom"
      />
    </Container>
  );
};

export default Auth;
