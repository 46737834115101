/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import avatar from 'assets/images/nophoto.png';

import { Checkbox, PointsInput } from 'components/PointManagement';
import { Container, ParticipantInfo, Photo } from './styles';
import { useTeamDistribution } from '../use-distribution';

type Props = {
  id: number;
  name: string;
  photo: string;
};

export const TeamCard: React.FC<Props> = ({ id, name, photo }) => {
  const {
    distribution,
    valueAttributed,
    availablePointsToDistribute,
    enablePointsReceive,
    assignPointsTo,
    disablePointsReceive,
  } = useTeamDistribution();

  const [inputValue, setInputValue] = useState<number | undefined>();

  const { value, canReceivePoints } = distribution.find(
    d => d.participant.id === id,
  )!;

  const handleCheckboxChange = () => {
    if (!canReceivePoints) enablePointsReceive(id);
    else disablePointsReceive(id);
  };

  const attributedParticipant =
    valueAttributed &&
    valueAttributed.find((attr: any) => attr.participant_id === id);

  useEffect(() => {
    if (attributedParticipant) {
      setInputValue(attributedParticipant.pontos);
    }
  }, [attributedParticipant]);

  return (
    <Container>
      <Photo src={photo || avatar} />
      <ParticipantInfo>
        <div className="name-and-selection">
          <strong>{name}</strong>
          <Checkbox
            onChange={handleCheckboxChange}
            checked={canReceivePoints}
            label=""
          />
        </div>
        <PointsInput
          onChange={v => {
            setInputValue(v);
            assignPointsTo(id, v);
          }}
          value={inputValue !== undefined ? inputValue : value}
          disabled={!canReceivePoints}
          maxLength={availablePointsToDistribute + value}
        />
      </ParticipantInfo>
    </Container>
  );
};
