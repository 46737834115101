import {
  ApiStrategicPlan,
  StrategicPlan,
  ApiStrategicVision,
  StrategicVision,
  StrategicVisionFormData,
  Attachment,
} from './interfaces';

const transformStrategicVisionFromApi = (
  data: ApiStrategicVision,
): StrategicVision => {
  return {
    id: data.id || 0,
    force: data.force,
    oportunity: data.oportunity,
    weakness: data.weakness,
    guide: data.guide,
    threat: data.threat,
    strategyPlanId: data.strategy_plan_id,
    uuid: data.uuid || '',
    strategicVisionAttachments: data.strategic_vision_attachments ?? [],
  };
};

const transformStrategicPlanFromApi = (
  data: ApiStrategicPlan[],
): StrategicPlan[] => {
  return data.map(item => ({
    approvers: item.strategy_plans_approvers,
    approveStrategyPlan: item.approve_strategy_plan,
    askApprovations: item.strategy_plan_ask_approvations?.map(ask => ({
      id: ask.id,
      participantProfile: ask.participant.profile,
      dateTime: ask.created,
      participantName: ask.participant.name,
      participantId: ask.participant_id,
      approvationStatus: ask.sp_ask_approvation_status_id,
    })),
    campaignId: item.campaign_id,
    campaignTitle: item.campaign_title,
    category: item.category || 'Sem categoria',
    clientCode: item.client_code,
    concludeStrategyPlan: item.conclude_strategy_plan,
    created: item.created,
    createStrategyPlan: item.create_strategy_plan,
    establishmentId: item.establishment_id,
    establishmentName: item.client_group,
    id: item.id,
    modified: item.modified,
    nameStatusPlan: item.name_status_plan,
    oldStatus: item.old_status,
    participantId: item.participant_id,
    participantName: item.participant?.participant_name,
    profile: item.participant?.participant_role,
    status: item.status_strategy_plan_id,
    uuid: item.uuid,
    strategyActions: item.strategy_actions,
    selected: false,
    isLoading: false,
    campaignEndDate: item.campaign_end_date,
    campaignStartDate: item.campaign_start_date,
    totalBudget: item.strategy_actions.length
      ? item.strategy_actions
          .map(i => i.custom_fields.budget)
          .reduce((previus, current) => previus + current)
      : 0,
    totalPoints: item.strategy_actions.length
      ? item.strategy_actions
          .map(i => i.custom_fields.points)
          .reduce((previus, current) => previus + current)
      : 0,
    totalIGD: item.strategy_actions.length
      ? item.strategy_actions
          .map(i => i.custom_fields.igd)
          .reduce((previus, current) => previus + current)
      : 0,
  }));
};
interface Request {
  data: StrategicVisionFormData;
  attachments: Attachment[];
  strategyPlanId: number;
}

const buildRequestData = ({
  data,
  attachments,
  strategyPlanId,
}: Request): ApiStrategicVision => {
  const { force, weakness, oportunity, threat, guide } = data;

  return {
    force,
    oportunity,
    threat,
    weakness,
    guide,
    strategy_plan_id: strategyPlanId,
    strategic_vision_attachments: attachments.map(
      ({ id, file_name, url, created }) => ({
        id,
        file_name,
        url,
        created,
      }),
    ),
  };
};

export {
  transformStrategicPlanFromApi,
  transformStrategicVisionFromApi,
  buildRequestData,
};
