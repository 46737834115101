import React, { useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import Input, { InputProps } from '../Input';

import { Container } from './styles';
import { fetchCep } from './fetchCep';

export type CepData = {
  state: string;
  city: string;
  bairro: string;
  street: string;
};

interface Props extends InputProps {
  onCepDataFetch: (data: CepData) => void;
}

export const CepInput: React.FC<Props> = ({ onCepDataFetch, ...rest }) => {
  const [isFetching, setFetching] = useState(false);

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const isCep = /[0-9]{5}-[0-9]{3}/g.test(event.target.value);

    if (isCep) {
      setFetching(true);

      fetchCep(event.target.value)
        .then(data => {
          onCepDataFetch({
            city: data.city,
            bairro: data.neighborhood,
            state: data.state,
            street: data.street,
          });
        })
        .catch(() => null)
        .finally(() => setFetching(false));
    }
  };

  return (
    <Container>
      <Input
        {...rest}
        icon={isFetching ? FiLoader : null}
        onBlur={handleInputBlur}
        pattern="XXXXX-XXX"
        numbersOnly
        disabled={isFetching}
      />
    </Container>
  );
};
