/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { formatPoints } from 'util/points';
import { downloadExcel } from 'util/download-excel';
import { ApprovationStatus } from 'services/point-management/dtos/point';
import { StatusIcon } from 'components/PointManagement/point-card/status-icon';
import { Container } from './styles';
import { Participant } from './types/PointCard';

type Props = {
  pointsAlready?: any;
  tab?: string;
};

export const PointCardSecondPhase: React.FC<Props> = ({
  pointsAlready,
  tab,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [participantExcel, setParticipantExcel] = useState<Participant[]>([]);

  useEffect(() => {
    const allParticipants: Participant[] = [];

    pointsAlready &&
      pointsAlready.participants &&
      pointsAlready.participants.forEach(
        (obj: Participant | null) => obj && allParticipants.push(obj),
      );

    setParticipantExcel(allParticipants);
  }, [pointsAlready, pointsAlready.participants]);

  return (
    <Container status={3 || 5}>
      <div className="point-wrapper">
        <div className="point-name">
          <strong>{pointsAlready.name_point}</strong>
        </div>
        <div className="point-divider" />
        <div className="point-value">
          <strong>{formatPoints(pointsAlready.value)} pontos</strong>
        </div>
        <div className="point-action">
          <button
            type="button"
            className="details-action"
            onClick={() => setOpen(!isOpen)}
          >
            Detalhes
          </button>

          <StatusIcon
            status={
              tab === 'approved'
                ? ApprovationStatus.APROVADO
                : ApprovationStatus.AGUARDANDO_DISTRIBUICAO
            }
            statusText={
              pointsAlready.status === 5 || pointsAlready.status === 3
                ? 'Aprovado'
                : 'Aguardando aprovação'
            }
          />
        </div>
      </div>
      {isOpen && (
        <div className="open--content">
          <div className="report--link">
            <a href="#" onClick={() => downloadExcel(participantExcel)}>
              Fazer o download do relatório para ver os detalhes da distribuição
            </a>
          </div>
        </div>
      )}
    </Container>
  );
};
