import React, { useCallback, useState } from 'react';

import { useAuth } from 'context/AuthContext';
import Campaigns from 'components/StrategicPlans/CampaignList';

import { canCreatePlans, canViewApprovePlanList } from './rules';

import { TitlePrimary, Container } from './styles';
import { PlanCreationScreen } from './screens/plan-creation-list';
import { PlanApprovalScreen } from './screens/plan-approval-list';

export const StrategicPlansPage = () => {
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const {
    participant: { profile_value },
  } = useAuth();

  const userCanCreatePlans = canCreatePlans(profile_value);
  const userCanViewPlanApproveList = canViewApprovePlanList(profile_value);

  const handleCampaignSelection = useCallback(
    (campaignId: number) => setSelectedCampaign(campaignId),
    [],
  );

  return (
    <Container>
      <div className="header">
        <TitlePrimary>Planejamento da Safra</TitlePrimary>
        <Campaigns
          selectedCampaignId={selectedCampaign}
          selectCampaign={handleCampaignSelection}
        />
      </div>

      {userCanCreatePlans && (
        <PlanCreationScreen campaignId={selectedCampaign} />
      )}
      {userCanViewPlanApproveList && (
        <PlanApprovalScreen campaignId={selectedCampaign} />
      )}
    </Container>
  );
};
