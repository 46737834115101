/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import routeMap from 'routes/route-map';
import history from 'services/history';
import { fetchCurrentCampaign } from 'state/modules/goals/actions';
import { useAuth } from 'context/AuthContext';
import { fetchCurrentCampaignTerraService as getCurrentCampaignTerra } from 'services/goals';

import { EstablishmentTypes } from 'config/constants';
import { pluginApi } from 'services/api';
import {
  Wrapper,
  PointsWrapper,
  TotalPointsText,
  SafraText,
  CustomButton,
} from './styles';

interface MyPointsProps {
  isParticipant: boolean;
  points: number;
}

const MyPoints: React.FC<MyPointsProps> = ({ isParticipant }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentCampaign());
  }, [dispatch]);

  const handleClickExtract = useCallback(() => {
    if (isParticipant) {
      return history.push(routeMap.extract.my);
    }

    return history.push(routeMap.extract.channel);
  }, [isParticipant]);

  const { participant } = useAuth();

  const url = `marketplace/pointsAdditionalMargin?participant_id=${participant.id}`;
  const [currentCampaignTerra, setCurrentCampaignTerra] = useState('');

  const isTerraBrasil =
    participant.establishment.type_name.toLowerCase() === 'terra brasil';

  useEffect(() => {
    async function fetchPercentParticipants() {
      const response = await pluginApi.get(url);
      // Se você precisar da variável 'value' no futuro, você pode adicionar ela de volta aqui
      // setValue(response.data?.['value']);
    }

    fetchPercentParticipants();
  }, [url]);

  const blockingAdditionalMargin =
    participant.establishment.classificacao.toUpperCase() ===
    'COOPERATIVA FECHADA';

  const showSafraText =
    !blockingAdditionalMargin &&
    participant.establishment.type_name === EstablishmentTypes.TerraBrasil;

  useEffect(() => {
    const fetchCurrentCampaignTerra = async () => {
      const currentTerra = await getCurrentCampaignTerra();
      if (isTerraBrasil && currentTerra) {
        setCurrentCampaignTerra(currentTerra.title);
      }
    };

    fetchCurrentCampaignTerra();
  }, [isTerraBrasil]);

  return (
    <Wrapper style={{ color: '#000' }}>
      <PointsWrapper>
        {/* {showSafraText ? (
          <>
            <SafraText>Ano</SafraText>
            <SafraText>{currentCampaignTerra}</SafraText>
          </>
        ) : (
          <>
            <TotalPointsText>Pontos Canal</TotalPointsText>
          </>
        )} */}
        <TotalPointsText>Pontos Canal</TotalPointsText>
      </PointsWrapper>
      <CustomButton
        type="submit"
        buttonRole="primary"
        onClick={handleClickExtract}
      >
        VER EXTRATO
      </CustomButton>
    </Wrapper>
  );
};

export default MyPoints;
