import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RouletteIcon from 'assets/images/roulette-icon.png';

import { getUserRoulette } from 'components/Roulette/utils/get-user-roulette';
import { Tooltip } from 'components/shared';
import { displayRouletteModal } from 'state/modules/home/actions';
import { getUserRoulettes } from 'state/modules/home/selectors';
import { Container } from './styles';

export const RouletteMenu: React.FC = () => {
  const [spins, setSpins] = useState(0);
  const rouletteStore = useSelector(getUserRoulettes);
  const dispatch = useDispatch();

  const handleRouletteOpen = () => dispatch(displayRouletteModal());

  useEffect(() => {
    const roulette = getUserRoulette(rouletteStore?.roulettes || []);

    if (roulette && spins !== roulette.user_spins) {
      setSpins(roulette.user_spins);
    }
  }, [rouletteStore, spins]);

  const label = (
    <span>
      Você possui <b>{spins}</b>&nbsp;
      {`giro${spins !== 1 ? 's disponíveis' : ' disponível'}`}
    </span>
  );

  /** Habilitar o menu da roleta apenas se o usuário possuir giros */
  const canShowRoulette = spins > 0;

  if (!canShowRoulette) {
    return null;
  }

  return (
    <Container role="button" onClick={handleRouletteOpen}>
      <Tooltip title={label} type="neutral" className="tooltip">
        <b>{spins}</b>
        <img src={RouletteIcon} alt="roulette icon" />
      </Tooltip>
    </Container>
  );
};
