import { formatDate } from 'util/datetime';
import { toDate, isAfter } from 'date-fns';
import { Training } from '../interfaces';
import getStatusText from '../util/getStatusText';

export interface Response {
  id: number;
  title: string;
  category: string;
  startDateParticipation: string;
  endDate: string;
  status: string;
  certificateUrl: string;
  custom: {
    id: number;
    approved: boolean;
    certificateUrl: string;
    showAnswers: boolean;
    statusButton: boolean;
  };
}

export default (data: Training[]): Response[] => {
  return data.map(item => {
    return {
      id: item.id,
      title: item.title,
      category: item.categories.map(i => i.name).join(', '),
      startDateParticipation: item.participation?.startedAt
        ? formatDate(item.participation.startedAt)
        : '',
      endDate: item.participation?.finishedDate
        ? formatDate(item.participation.finishedDate)
        : ``,
      status:
        item.participation?.approved ||
        (item.maxTries <= (item.participation?.totalAttempts || 0) &&
          item.maxTries !== 0)
          ? 'Finalizado'
          : getStatusText(item.status),
      certificateUrl: '',
      custom: {
        id: item.id,
        approved: !!item.participation?.approved,
        certificateUrl: '',
        showAnswers: isAfter(toDate(new Date()), new Date(item.correctionDate)),
        statusButton: item.participation?.approved ||
        (item.maxTries <= (item.participation?.totalAttempts || 0) &&
          item.maxTries !== 0)
          ? true
          : false
      },
    };
  });
};
