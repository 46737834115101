import React from 'react';

import { PointsProvider } from '../use-points';
import { ApprovementPage } from './page';

export const PointsApprovementPage = () => (
  <PointsProvider fetchPointsToDistribute={false} fetchTeamPoints={false}>
    <ApprovementPage />
  </PointsProvider>
);
