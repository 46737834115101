import { pluginApi } from 'services/api';
import { StatusHarvest } from './interfaces/StatusHarvest';

export const getMaintenance = async () => {
  const response = await pluginApi.get('/participants/Tampao');

  return response;
};

export const getTampaoSafra = async () => {
  const { data } = await pluginApi.get<StatusHarvest>(
    '/participants/participant/statusHarvestInterne',
  );

  return data;
};
