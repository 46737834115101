import React, { useCallback } from 'react';

import { formatPoints, formatPointsBudget } from 'util/points';
import {
  Header,
  ColumnWrapper,
} from 'components/StrategicPlans/ActionsForms/shared/styles';
import numbersOnly from 'util/numbersOnly';

import { useFormContext } from 'react-hook-form';
import { Container, Content, Text, InputWrapper, Input, Obs } from './styles';

interface BudgetsProps {
  available?: number;
  channelTotal?: number;
  canEdit?: boolean;
}

const Budgets: React.FC<BudgetsProps> = ({
  available,
  channelTotal,
  canEdit = true,
}) => {
  const { setValue } = useFormContext();

  const handleChangeValuePoints = useCallback(
    (v: string) => {
      setValue(
        'points',
        `${formatPointsBudget(parseInt(numbersOnly(v) || '0', 0))}`,
      );
    },
    [setValue],
  );

  const handleChangeBudgetPoints = useCallback(
    (v: string) => {
      setValue(
        'budget',
        `${formatPointsBudget(parseInt(numbersOnly(v) || '0', 0))}`,
      );
    },
    [setValue],
  );

  const handleChangeIGDPoints = useCallback(
    (v: string) => {
      setValue(
        'igd',
        `${formatPointsBudget(parseInt(numbersOnly(v) || '0'))}`,
      );
    },
    [setValue],
  );

  return (
    <Container>
      <Header>Indique a quantidade de pontos que deseja utilizar: 1 Ponto = 1 Real</Header>
      <Content>
        <ColumnWrapper>
          <Text>1. Pontos do Juntos (Gestão e Desenvolvimento)</Text>
          <InputWrapper>
            <Input
              name="points"
              placeholder="0"
              onChange={e => handleChangeValuePoints(e.target.value)}
              disabled={!canEdit}
            />
            <span title="1 ponto = 1 real"> R$</span>
          </InputWrapper>
          {available && (
            <Obs>Disponível para ações: {formatPoints(available)} pontos</Obs>
          )}
          {channelTotal && (
            <Obs>Total do canal: {formatPoints(channelTotal)} pontos</Obs>
          )}
        </ColumnWrapper>

        <ColumnWrapper>
          <Text>2. IGD (Investimento de Geração de Demanda)</Text>
          <InputWrapper>
            <Input
              name="igd"
              placeholder="0"
              onChange={e => handleChangeIGDPoints(e.target.value)}
              disabled={!canEdit}
            />
            <span title="1 ponto = 1 real"> R$</span>
          </InputWrapper>
        </ColumnWrapper>

        <ColumnWrapper>
          <Text>3. Verba de Budget planejada com a área</Text>
          <InputWrapper>
            <Input
              name="budget"
              placeholder="0"
              onChange={e => handleChangeBudgetPoints(e.target.value)}
              disabled={!canEdit}
            />
            <span> R$</span>
          </InputWrapper>
        </ColumnWrapper>

      </Content>
    </Container>
  );
};

export default Budgets;
