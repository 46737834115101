import { pluginApi, vendavallApi } from 'services/api';
import { CatalogStatusError } from 'services/auth/errors';
import { TwoLoginException } from 'services/auth/errors/TwoLoginException';
import { PROFILES } from 'config/constants';

interface SignInParams {
  cpf: string;
  password: string;
  establishment_id?: number;
  role_id?: number;
  tokenMfa?: string;
}

interface MfaTokenParams {
  cpf: string;
  password: string;
  method: string;
}

export interface Establishment {
  establishment: {
    id: number;
    type: {
      name: string;
    };
  };
  role: {
    id: number;
  };
  profile: string;
}

interface ApiResponse {
  token: string;
  establishments: Establishment[];
  premioideall_url?: string;
}

export interface SignInResponse {
  token: string;
}

export interface MfaResponse {
  message: string;
}

export interface PreviousSignInResponse {
  success: boolean;
  contacts: {
    obfuscatedEmail: string;
    obfuscatedCellPhone: string;
  };
}

interface PreviousSignInParams {
  cpf: string;
  password: string;
}

export interface previousRecoverPasswordResponse {
  success: boolean;
  contacts: {
    obfuscatedEmail: string;
    obfuscatedCellPhone: string;
  };
}

interface PreviousRecoverPasswordParams {
  cpf: string;
}

export interface RecoverPasswordSmsTokenResponse {
  success: boolean;
  message: string;
}

interface RecoverPasswordSmsTokenParams {
  cpf: string;
}

export interface ValidateRecoverPasswordTokenResponse {
  success: boolean;
  message: string;
}

interface ValidateRecoverPasswordTokenParams {
  cpf: string;
  tokenSms: string;
}

const INDIRECTS = [PROFILES.focalPoint, PROFILES.participant];

const signInByToken = async (
  tokenToAuthenticate: string,
): Promise<SignInResponse> => {
  const {
    data: { token },
  } = await vendavallApi.post<SignInResponse>('login/token', {
    token: tokenToAuthenticate,
  });

  return { token };
};

export const MfaToken = async ({
  cpf,
  password,
}: MfaTokenParams): Promise<MfaResponse> => {
  try {
    const { data: dataAuth } = await pluginApi.post<MfaResponse>(
      'participants/mfa-token',
      {
        identifier: cpf,
        password,
      },
    );

    localStorage.setItem('identifier', cpf);
    localStorage.setItem('password', password);

    return { message: dataAuth.message };
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(
        'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
      );
    }
  }
};

export const MfaTokenSms = async ({
  cpf,
  password,
}: MfaTokenParams): Promise<MfaResponse> => {
  try {
    const { data: dataAuth } = await pluginApi.post<MfaResponse>(
      'participants/mfa-token/send-sms-token',
      {
        identifier: cpf,
        password,
      },
    );

    localStorage.setItem('identifier', cpf);
    localStorage.setItem('password', password);

    return { message: dataAuth.message };
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
    throw new Error(errorMessage);
  }
};

export const signIn = async ({
  cpf,
  password,
  tokenMfa,
}: SignInParams): Promise<SignInResponse> => {
  const { data: dataLogin } = await pluginApi.post<ApiResponse>(
    'participants/login',
    {
      identifier: cpf,
      password,
      tokenMfa,
    },
  );

  let { token } = dataLogin;

  if (dataLogin.premioideall_url) {
    throw new CatalogStatusError(dataLogin.premioideall_url);
  }

  if (dataLogin.establishments && dataLogin.establishments.length > 1) {
    const { establishments } = dataLogin;
    const producer = establishments.find(
      establishment => establishment.profile === PROFILES.producer,
    );
    const indirect = establishments.find(establishment =>
      INDIRECTS.includes(establishment.profile),
    );

    if (producer && indirect) {
      const profiles: Establishment[] = [producer, indirect];
      throw new TwoLoginException('Possui dois perfis', profiles);
    }
  }

  if (
    !token &&
    dataLogin.establishments &&
    dataLogin.establishments.length > 0
  ) {
    const { data } = await pluginApi.post<ApiResponse>('participants/login', {
      identifier: cpf,
      password,
      establishment_id: dataLogin.establishments[0].establishment.id,
      role_id: dataLogin.establishments[0].role.id,
    });
    token = data.token;
  }

  return { token };
};

export default async (data: SignInParams | string): Promise<SignInResponse> => {
  return typeof data === 'string' ? signInByToken(data) : signIn(data);
};

export const singnInWithProfile = async (
  params: SignInParams,
): Promise<SignInResponse> => {
  const { cpf, password, establishment_id, role_id } = params;

  if (!establishment_id || !role_id) {
    throw new Error('Estabelecimento e cargo são obrigatórios!');
  }

  const { data } = await pluginApi.post<ApiResponse>('participants/login', {
    identifier: cpf,
    password,
    establishment_id,
    role_id,
  });

  const { token } = data;

  return { token };
};

export const previousSignInParticipantData = async ({
  cpf,
  password,
}: PreviousSignInParams): Promise<PreviousSignInResponse['contacts']> => {
  try {
    const { data } = await pluginApi.post<PreviousSignInResponse>(
      'participants/mfa-token/login-participant-data',
      {
        identifier: cpf,
        password,
      },
    );

    return data.contacts;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
    throw new Error(errorMessage);
  }
};

export const previousRecoverPasswordParticipant = async ({
  cpf
}: PreviousRecoverPasswordParams): Promise<previousRecoverPasswordResponse['contacts']> => {
  try {
    const { data } = await pluginApi.post<previousRecoverPasswordResponse>(
      'participants/recover-password/recover-password-data',
      {
        identifier: cpf,
      },
    );

    return data.contacts;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
    throw new Error(errorMessage);
  }
};

export const recoverPasswordSmsToken = async ({
  cpf
}: RecoverPasswordSmsTokenParams): Promise<RecoverPasswordSmsTokenResponse> => {
  try {
    const { data } = await pluginApi.post<RecoverPasswordSmsTokenResponse>(
        '/participants/mfa-token/recover-password-token-sms',
      {
        identifier: cpf,
      },
    );
  return data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
    throw new Error(errorMessage);
  }
};

export const validateRecoverPasswordToken = async ({
  cpf,
  tokenSms
}: ValidateRecoverPasswordTokenParams): Promise<ValidateRecoverPasswordTokenResponse> => {
  try {
    const { data } = await pluginApi.post<ValidateRecoverPasswordTokenResponse>(
        '/participants/mfa-token/recover-password-token-validate',
      {
        identifier: cpf,
        token: tokenSms
      },
    );
  return data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.';
    throw new Error(errorMessage);
  }
};
