import React from 'react';
import Select from 'components/shared/Select';
import { Container } from './styles';

interface Props {
  name: string;
  className?: string;
  inputRole?: 'primary' | 'secondary';
}

const exclusive = ['Sim', 'Não'];

const SelectExclusiveFmc: React.FC<Props> = ({
  name,
  className,
  inputRole = 'secondary',
}) => {
  return (
    <Container>
      <Select
        loadItems={() => exclusive.map(item => ({ value: item, title: item }))}
        name={name}
        className={className}
        label="Exclusivo FMC?"
        inputRole={inputRole}
      />
    </Container>
  );
};

export default SelectExclusiveFmc;
