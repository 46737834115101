export default `
<thead>
<tr style="">
  <th style="padding-left: 4px;">Segmento/Produto</th>
  <th>Faturamento(US$)</th>
  <th>POG(US$)</th>
  <th>Estoque</th>
  <th>Valor Unitário(US$)</th>
  <th>Faturamento(Kg/L)</th>
  <th>Faturamento(US$)</th>
  <th>POG(Kg/L)</th>
</tr>
</thead>
`;
