import React, { useEffect, useState } from 'react';

import { EstablishmentTypes, PROFILES } from 'config/constants';
import { Extract, Point, Resume } from 'services/extract/interfaces';
import { formatPointsExtract } from 'util/points';
import { useAuth } from 'context/AuthContext';
import { useLocation } from 'react-router-dom';
import { parseResumeCampaignTitle } from './utils/parseResumeCampaingTitle';

import {
  AccumulatedBalance,
  BalanceItem,
  BalanceBoxContainer,
  Container,
  Title,
  Subtitle,
  HeaderTabWrapper,
  HeaderTab,
  Empty,
} from './styles';

interface Props {
  extract: Extract;
  establishmentType: EstablishmentTypes;
}

const cardOrders = [
  'rebate',
  'biológicos',
  'premiação de vendedor',
  'margem adicional',
  'ponto extra',
];

const TOTAL_CAMPAING_ID = 0;
const MYEXTRACT = '/myextract';

const ExtractHeader: React.FC<Props> = ({ extract, establishmentType }) => {
  const [selectedResume, setSelectedResume] = useState<Resume>();
  const { participant } = useAuth();

  const handleResumeTabChange = (resume: Resume) => {
    setSelectedResume(resume);
  };

  const orderBalanceCards = (points: Point[]) =>
    points.sort((item1, item2) => {
      let itemOneIndex = cardOrders.indexOf(item1.name.toLowerCase());
      let itemTwoIndex = cardOrders.indexOf(item2.name.toLowerCase());

      if (itemOneIndex === -1) {
        itemOneIndex = 999;
      }

      if (itemTwoIndex === -1) {
        itemTwoIndex = 999;
      }

      return itemOneIndex > itemTwoIndex ? 1 : -1;
    });

  useEffect(
    function setDefaultSelectedResume() {
      const resumeTotal = extract.resume.find(
        resume => resume.campaing.id === TOTAL_CAMPAING_ID,
      );
      setSelectedResume(resumeTotal);

      extract.resume.reverse();
    },
    [extract],
  );

  const isResumeEmpty = !extract.resume.length;

  const { pathname } = useLocation();
  const isMyExtract = pathname === MYEXTRACT;

  return (
    <>
      <Title>
        <strong>Pontos Recebidos</strong>
      </Title>
      {participant.profile === PROFILES.focalPoint && !isMyExtract && (
        <Subtitle>
          <span>
            Os pontos abaixo são referentes aos valores que o seu canal recebeu
            no programa JUNTOS FMC. Navegue pela safra desejada:
          </span>
        </Subtitle>
      )}
      {isResumeEmpty && (
        <Empty>
          <span>Nenhum ponto recebido.</span>
        </Empty>
      )}

      {!isResumeEmpty && (
        <>
          <HeaderTabWrapper>
            {extract.resume.map(resume => (
              <HeaderTab
                isSelected={resume.campaing.id === selectedResume?.campaing.id}
                key={resume.campaing.id}
                type="button"
                onClick={() => handleResumeTabChange(resume)}
              >
                {parseResumeCampaignTitle(
                  establishmentType,
                  resume.campaing.title,
                )}
              </HeaderTab>
            ))}
          </HeaderTabWrapper>

          <Container userType={establishmentType}>
            <AccumulatedBalance>
              {!!selectedResume && selectedResume.total > 0 && (
                <h2>
                  Pontos:
                  <strong> {formatPointsExtract(selectedResume.total)}</strong>
                </h2>
              )}
              <BalanceBoxContainer>
                {orderBalanceCards(selectedResume?.points || []).map(point => (
                  <BalanceItem key={point.id}>
                    <div className="title">
                      <span>{point.name}</span>
                    </div>
                    <div className="value">
                      {formatPointsExtract(point.total)}{' '}
                    </div>
                  </BalanceItem>
                ))}
              </BalanceBoxContainer>
            </AccumulatedBalance>
          </Container>
        </>
      )}
    </>
  );
};

export default ExtractHeader;
