import styled from 'styled-components';

export const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
  position: relative;

  ._inputContainer {
    border: 1px solid #821c0e;
    height: 40px;
  }
`;

export const Contents = styled.div`
  display: flex;
  width: 100%;
`;

export const QuantityWrapper = styled.div`
  margin-right: 20px;

  ._inputContainer {
    margin-bottom: 15px;
    max-width: 90px;
    height: 45px;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 0.7rem;
  }
  span {
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-bottom: 10px;
  }
`;

export const DatesPiker = styled.div`
  display: flex;
  display: flex;
  justify-content: space-around;
  margin-right: 320px;
  margin-left: -35px;

  ._inputContainer {
    max-width: 250px;
    height: 40px;
  }
`;
export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: #821c0e;
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  max-width: 280px;
  align-items: center;

  ._inputContainer {
    max-width: 250px;
    height: 45px;
  }
  span {
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-bottom: 20px;
  }
`;
