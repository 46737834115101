import React from 'react';

import CloseButton from 'components/shared/CloseButton';
import {
  Container,
  Modal,
  ButtonWrapper,
  Button,
  Header,
  Text,
} from './styles';

type ModalType = 'plan' | 'action';

const planText = 'desse Plano Estratégico';
const actionText = 'dessa Ação Estratégica';

interface ApproveModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  modaType?: ModalType;
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  modaType = 'plan',
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={1}>
      <CloseButton onClickHandler={onRequestClose} />
      <Container>
        <Header>
          Tem certeza que deseja abandonar a edição{' '}
          {modaType === 'plan' ? planText : actionText}
        </Header>
        <Text>
          Você editou campos {modaType === 'plan' ? planText : actionText}.
        </Text>
        <Text> Se clicar em sim, você perderá as alterações feitas.</Text>
        <ButtonWrapper>
          <Button type="button" buttonRole="primary" onClick={onConfirm}>
            Sim
          </Button>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            Não
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default ApproveModal;
