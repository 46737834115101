import { pluginApi } from 'services/api';
import { InvoiceApi, InvoiceDetails } from 'services/nf/interfaces';
import { transformInvoiceDetailsFromApi } from 'services/nf/transformers';

export default async (invoiceId: number): Promise<InvoiceDetails> => {
  const { data } = await pluginApi.post<InvoiceApi>(
    'participants/upload-nota/invoice',
    {
      id: invoiceId,
    },
  );

  return transformInvoiceDetailsFromApi(data);
};
