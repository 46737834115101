import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px;
  margin-bottom: 3em;

  button {
    background: none;
    border: none;
    color: #821c0e;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;
