import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 40px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;
  color: ${({ theme }) => theme.font.color.primary};

  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: ${({ theme }) => theme.font.color.primary};
  }

  div {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    fieldset {
      display: flex;
      flex-direction: row;
      align-items: center;
      -webkit-appearance: none;

      width: 50px;
      justify-content: center;
      color: black;

      legend {
        margin-left: 20px;
      }
    }
  }
`;

export const AnswerItem = styled.div`
  display: flex;
  flex-direction: column;

  input {
    display: none;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const IconWrapper = styled.span<{ selected?: boolean }>`
  svg {
    fill: ${({ theme }) => theme.font.color.quartenary};
    cursor: pointer;
    margin-top: 0.5rem;
    transition: 0.3s;

    ${({ selected }) =>
      selected &&
      css`
        fill: ${({ theme }) => theme.font.color.primary};
        transform: scale(1.2);
      `}

    &:hover {
      fill: ${({ theme }) => theme.font.color.primary};
      transform: scale(1.2);
      transition: 0.3s ease-in-out, fill 0.3s;
    }
  }
`;
