/* eslint-disable radix */
import React, { useState } from 'react';

import { GoAlert } from 'react-icons/go';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import { formatPoints } from 'util/points';
import { fixedPrecisionOf } from 'util/numbers';
import { useSimpleToast } from 'components/shared';
import { sendTeamDistributionToApprovation } from 'services/point-management/send-team-to-approvation';
import { ActionsModalContainer, ConfirmationModalContainer } from './styles';
import { useTeamDistribution } from '../../use-distribution';

const ModalTitle = withStyles({
  root: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})(DialogTitle);

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmDistributionModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    getDistributionResume,
    teampoint,
    distribution,
  } = useTeamDistribution();
  const { showToast } = useSimpleToast();

  const resume = getDistributionResume();

  const totalDistributed = resume.length
    ? resume
        .map(r => r.totalDistributed)
        .reduce((a, b) => fixedPrecisionOf(a + b, 2))
    : 0;

  const saldoRestante = fixedPrecisionOf(teampoint.value - totalDistributed, 2);

  const handleConfirm = async () => {
    setLoading(true);

    showToast({
      message: 'Confirmando distribuição...',
      type: 'info',
    });

    const payload = {
      undistributed_point_id: teampoint.undistributed_point_id,
      value_total: +totalDistributed.toFixed(2),
      campaign_id: teampoint.campaign_id,
      point_type_id: teampoint.balance_unit_id,
      establishment_id: teampoint.establishment_id,

      participants: distribution
        // .filter(d => d.value > 0)
        .map(d => ({
          participant_id: d.participant.id,
          value: d.value,
        })),
    };
    const response = await sendTeamDistributionToApprovation(payload);

    if (response.message === 'Pontos não podem ser distribuídos.') {
      showToast({
        message: response.message,
        type: 'error',
      });
      onCancel();
      setLoading(false);
    } else {
      showToast({
        message: response.message || '',
        type: 'success',
      });
      onConfirm();
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen}>
      <ModalTitle>Deseja finalizar a distribuição?</ModalTitle>
      <DialogContent dividers>
        <ConfirmationModalContainer>
          <strong className="title">
            <span>{teampoint.balance_unit_name}</span>
            <span>{formatPoints(teampoint.value)} pontos</span>
          </strong>
          <div className="resume">
            <span className="resume--title">Resumo da distribuição:</span>
            <div className="resume--distribution">
              {resume.map(r => (
                <strong>
                  <span>
                    {r.role} {r.totalMembersScored}x
                  </span>
                  <span>{formatPoints(r.totalDistributed)} pontos</span>
                </strong>
              ))}
              <hr />
              <div className="resume--distribution-total">
                <span>
                  Saldo total: <b>{formatPoints(teampoint.value)} pontos</b>
                </span>
                <span>
                  Total distribuído:{' '}
                  <b>{formatPoints(totalDistributed)} pontos</b>
                </span>
                <span>
                  Saldo restante: <b>{formatPoints(saldoRestante, 2)} pontos</b>
                </span>
              </div>
            </div>
          </div>
          <div className="alert">
            <GoAlert />
            <span>Ao confirmar, você não poderá reverter essa ação!</span>
          </div>
        </ConfirmationModalContainer>
      </DialogContent>
      <DialogActions>
        <ActionsModalContainer>
          <MaterialButton onClick={onCancel} disabled={loading}>
            Cancelar
          </MaterialButton>
          <MaterialButton onClick={handleConfirm} disabled={loading}>
            {loading ? 'Carregando...' : 'Confirmar'}
          </MaterialButton>
        </ActionsModalContainer>
      </DialogActions>
    </Dialog>
  );
};
