import styled from 'styled-components';
import bannerPH from 'assets/images/bannerPH.jpg';

export const TitleWrapper = styled.div`
  margin-left: 690px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    text-align: left;
    float: left;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  text-align: center;

  @media screen and (max-width: 768px) {
    background-color: transparent;
    height: auto;
    box-shadow: none;
    flex-direction: column;
    border-radius: 10px;
  }
`;

export const BiologicalBanner = styled.div`
  width: 50%;
  height: 270px;
  border-radius: 10px;
  display: flex;
  padding: 0.5em;
  position: relative;
  background-image: url(${bannerPH});
  background-size: cover;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 180px;
    padding: 0;

    &:first-child {
      margin-bottom: 1em;
    }
  }
`;

export const PerformanceWrapper = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  flex-direction: column;
  float: right;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);

  @media screen and (max-width: 768px) {
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);
    width: 100%;

    &:first-child {
      margin-bottom: 1em;
    }
  }
`;

export const ProgressWrapper = styled.div`
  background-color: none;
  margin-left: 0.5em;
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;

    &:first-child {
      margin-bottom: 1em;
    }
  }
`;

export const IndividualProgressWrapper = styled.div`
  margin: 0.5em 2em 0.5em 2em;
  width: 160px;
`;

export const Label = styled.h3`
  color: #000000;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 1em;
  text-align: center;
  margin: 0.5em 0 1em 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const CompletePerformanceWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;

  a {
    justify-content: center;
    text-align: center;
    display: flex;
    text-decoration: none;
    font-size: 0.9em;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};

    svg {
      margin-left: 0.5em;
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;
