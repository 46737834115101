import React from "react";
import { StyledTable } from "./styles";

interface DataTableHistoryProps {
  data: Array<{
    participant_name: string;
    file_name: string;
    status: string;
    end_datetime: string;
    message: string;
    info: React.ReactNode; 
    processedRows: number;
    download: string | null; 
  }>;
}

const DataTableHistory: React.FC<DataTableHistoryProps> = ({ data = [] }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Arquivo</th>
          <th>Status</th>
          <th>Data</th>
          <th>Mensagem</th>
          <th>Nome</th>
          <th>Info</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.file_name}</td>
            <td>
              <span
                style={{
                  color:
                    row.status === "success"
                      ? "green"
                      : row.status === "error"
                      ? "red"
                      : "orange",
                }}
              >
                {row.status === "success"
                  ? "Sucesso"
                  : row.status === "error"
                  ? "Erro"
                  : "Processando"}
              </span>
            </td>
            <td>{row.end_datetime}</td>
            <td>{row.message}</td>
            <td>{row.participant_name}</td>
            <td>{typeof row.info === "string" ? row.info : <>{row.info}</>}</td>
            <td>
              {row.download ? (
                <a href={row.download} target="_blank" rel="noopener noreferrer">
                  Baixar
                </a>
              ) : (
                <span style={{ color: "gray" }}>Indisponível</span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default DataTableHistory;