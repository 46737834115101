import React, { useState, useEffect } from 'react';
import { EstablishmentTypes } from 'config/constants';
import { Option } from 'components/shared/Select';
import Select from 'components/shared/Select/BaseSelect';

interface Props {
  className?: string;
  inputRole?: 'primary' | 'secondary';
  disabled?: boolean;
  label?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  placeholder?: string;
  isTerraBrasil?: boolean;
  isIndirect?: boolean;
}

const TypeSelect: React.FC<Props> = ({
  className,
  inputRole = 'primary',
  disabled = false,
  label,
  setValue,
  value,
  placeholder,
  isTerraBrasil,
  isIndirect,
}) => {
  const [data, setData] = useState<Option[]>([]);

  useEffect(() => {
    const newData = [];

    if (isTerraBrasil) {
      newData.push({
        title: EstablishmentTypes.TerraBrasil,
        value: EstablishmentTypes.TerraBrasil,
      });
    }
    if (isIndirect) {
      newData.push(
        {
          title: EstablishmentTypes.Resale,
          value: EstablishmentTypes.Resale,
        },
        {
          title: EstablishmentTypes.Cooperative,
          value: EstablishmentTypes.Cooperative,
        },
      );
    }

    setData(newData);
  }, [isIndirect, isTerraBrasil]);

  return (
    <Select
      label={label}
      loadItems={() => data}
      className={className}
      inputRole={inputRole}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      setValue={setValue}
    />
  );
};

export default TypeSelect;
