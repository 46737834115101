import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isOpen: boolean;
}>`
  background-color: #fff;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: 30px 1fr 20px;
  align-items: center;
  border-bottom: 2px solid #747474b5;
  padding: 30px 15px;

  ${p =>
    p.isOpen &&
    css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .chevron {
        transform: rotate(180deg);
      }
    `}

  > div,
  > div div,
  > div div svg {
    height: 27px;
  }

  span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  .chevron {
    transition: all 200ms ease-in;
    cursor: pointer;
  }
`;

export const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  max-height: 57rem;
  overflow: auto;
  overflow-x: hidden;
`;

export const Details = styled.div`
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 10px; */
  text-transform: capitalize;
  overflow-x: auto;
  padding: 1em 3em;

  .campaign--details {
    color: ${({ theme }) => theme.font.color.primary};
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;

    .contents {
      display: flex;
      max-width: 800px;
    }

    & > div {
      max-width: 180px; /* ou ajuste conforme necessário */
    }

    > div {
      margin-top: 4px;
    }

    span {
      font-size: 14px;
    }

    .more {
      margin-left: 3px;
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin-left: 10px;
    }

    strong {
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      font-size: 16px;
      text-decoration: underline;
    }
  }

  .campaign--actions {
    display: inline-flex;
    justify-content: end;
    font-size: 14px;

    button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      margin-left: 70px;
      min-width: 35px;
    }
  }
`;

export const Title = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-align: left;
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
