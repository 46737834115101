import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 20px 30px 20px;
  background: transparent
    linear-gradient(
      219deg,
      #40535b 0%,
      #3e525a 1%,
      #283f48 31%,
      #203842 50%,
      #0e2b34 80%,
      #002128 100%
    )
    0% 0% no-repeat padding-box;
`;

export const Head = styled.a`
  display: flex;
  justify-content: center;
  padding: 40px 0;

  img {
    max-height: 100px;

    @media screen and (max-width: 500px) {
      max-height: 70px;
    }
  }
`;

export const FormContainer = styled.div`
  background-color: #fff;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 70px;
  color: #808285;

  @media screen and (max-width: 640px) {
    padding: 30px 15px;
  }

  .head--title {
    h1 {
      font-size: 20px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      margin-bottom: 5px;
    }

    span {
      font-size: 18px;
    }
  }
`;
