/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react';
import AccordionItem from 'components/Extract/Accordion/Item';
import { Container as AccordionContainer } from 'components/Extract/Accordion/styles';
import { Extract as IExtract } from 'services/extract/interfaces';
import { EstablishmentTypes } from 'config/constants';
import DatePicker from 'components/shared/DatePicker';
import { Button } from 'components/shared';
import { formatDate } from 'util/datetime';
import { pluginApi } from 'services/api';
import useDimensions from 'hooks/use-window-dimensions';
import { formatPointsExtract } from 'util/points';
import { parseISO } from 'date-fns';

import { useAuth } from 'context/AuthContext';
import {
  Container as DetailsContainer,
  Title,
  Select,
  ListTable,
  ListTableContainer,
  ContentMobile,
  Content,
  Main,
  TitleExtract,
} from './styles';

interface Props {
  details: IExtract;
  establishmentType: EstablishmentTypes;
  establishmentId: string;
}

interface ResponseExtract {
  datahora: Date | null;
  descricao: string | null;
  credito: number | null;
  debito: number | null;
  saldo: number | null;
  dataExpiracao: Date | null;
}

interface InfoTypeExtract {
  id: number;
  title: string;
  value: number;
}

const ExtractDetails: React.FC<Props> = ({
  details,
  establishmentType,
  establishmentId,
}) => {
  const accordionTitle =
    establishmentType === EstablishmentTypes.TerraBrasil ? 'Ano' : 'Safra';

  const [loading, setLoading] = useState<boolean>(false);
  const [researched, setResearched] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { participant } = useAuth();

  const [typeSelected, setTypeSelected] = useState(268);

  // checando se participante é FOCALPOINT se for não aprecentamos a opção de IGD
  const checkProfile =
    participant.profile === 'FOCALPOINT'
      ? [
          { id: 1, title: 'Gestão e Desenvolvimento', value: 268 },
          { id: 2, title: 'Ações de Desenvolvimento', value: 336 },
        ]
      : [
          { id: 1, title: 'Gestão e Desenvolvimento', value: 268 },
          { id: 2, title: 'Ações de Desenvolvimento', value: 336 },
          { id: 3, title: 'IGD', value: 372 },
        ];
  const typeExtract: Array<InfoTypeExtract> = checkProfile;

  const idCampaingPi = 347;
  const idIGD = 372;

  const { width } = useDimensions();

  const [infoExtract, setInfoExtract] = useState([]);

  const [initialDate, setInitialDate] = useState<Date>(
    new Date('2020-01-01T00:00:00-03:00'),
  );
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  // Formatadores de data
  const onEndDateChangeHandler = useCallback((value: Date): void => {
    setFinalDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);

  const onBeginDateChangeHandler = useCallback((value: Date): void => {
    setInitialDate(parseISO(formatDate(value, 'yyyy-MM-dd')));
  }, []);

  const getInfoExtract = async () => {
    try {
      setLoading(true);
      const { data } = await pluginApi.get('premio-ideall/points', {
        params: {
          start_date: formatDate(initialDate, 'yyyy-MM-dd'),
          final_date: formatDate(finalDate, 'yyyy-MM-dd'),
          establishment_id: establishmentId,
          campaign_id_pi:
            participant.establishment.classificacao === 'Terra Brasil' ||
            establishmentType === 'Terra Brasil'
              ? idCampaingPi
              : typeSelected,
        },
      });
      setResearched(true);
      setLoading(false);
      setInfoExtract(data.data);
      setError(false);
    } catch (err) {
      setError(true);
    }
  };

  const getExtractValues =
    researched && !loading && infoExtract.length > 1 && width && width > 768;

  return (
    <Main>
      <Title>
        <strong>Movimentação de Pontos Detalhados</strong>
      </Title>
      <DetailsContainer>
        <AccordionContainer>
          {!!establishmentId &&
            details.statement?.map(statement => (
              <AccordionItem
                key={statement.campaign.id}
                extractStatement={statement}
                title={accordionTitle}
                establishmentId={establishmentId}
              />
            ))}
        </AccordionContainer>
      </DetailsContainer>
      <div className="title-extract">
        <TitleExtract>Extrato do Catálogo de Prêmios</TitleExtract>
      </div>
      <Content>
        <div className="parent-date">
          <div className="content-date">
            {establishmentType === 'Terra Brasil' ? (
              <Select>
                <option defaultValue={idCampaingPi}>
                  Gestão e Desenvolvimento
                </option>
                <>
                  {participant.role.name === 'KAM' && (
                    <option defaultValue={idIGD}>IGD</option>
                  )}
                </>
              </Select>
            ) : (
              <Select
                value={Number(typeSelected)}
                onChange={evt => {
                  setTypeSelected(Number(evt.target.value));
                }}
              >
                {typeExtract.map((typeSelectedMap: InfoTypeExtract) => (
                  <option
                    key={typeSelectedMap.id}
                    value={typeSelectedMap.value}
                  >
                    {typeSelectedMap.title}
                  </option>
                ))}
              </Select>
            )}
            <div className="first-datepicker">
              <DatePicker
                label="Data inicial"
                value={initialDate}
                onChange={onBeginDateChangeHandler}
              />
            </div>
            <div className="content-date">
              <div className="second-datepicker">
                <DatePicker
                  label="Data final"
                  value={finalDate}
                  onChange={onEndDateChangeHandler}
                  className="datepickerfinal"
                />
              </div>
            </div>
            <Button
              className="datepickerfinal btn"
              buttonRole="primary"
              type="button"
              disabled={!initialDate || !finalDate}
              onClick={getInfoExtract}
            >
              Consultar
            </Button>
          </div>
        </div>
      </Content>

      <ListTableContainer>
        <div
          className={
            researched && infoExtract.length > 1 && !loading ? 'searched' : ''
          }
        >
          {error && <h3>Nenhum resultado encontrado</h3>}
          {researched && infoExtract.length < 2 && (
            <h3>Nenhum resultado encontrado</h3>
          )}
          {getExtractValues && (
            <ListTable>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Crédito</th>
                  <th>Débito</th>
                  <th>Saldo</th>
                  <th>Vencimento</th>
                </tr>
              </thead>

              <tbody>
                {infoExtract &&
                  infoExtract.slice(1).map((info: ResponseExtract) => (
                    <tr>
                      <td>
                        {(info.datahora &&
                          formatDate(info.datahora, 'dd/MM/yyyy')) ||
                          '-'}
                      </td>

                      <td>{info.descricao || '-'}</td>

                      <td>
                        {(info.credito && formatPointsExtract(info.credito)) ||
                          '-'}
                      </td>

                      <td>
                        {(info.debito && formatPointsExtract(info.debito)) ||
                          '-'}
                      </td>

                      <td>
                        {(info.saldo && formatPointsExtract(info.saldo)) || '-'}
                      </td>
                      <td>
                        {(info.dataExpiracao &&
                          formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                          '-'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </ListTable>
          )}

          {width && width < 768 && (
            <ContentMobile>
              {infoExtract.slice(1).map((info: ResponseExtract) => (
                <div className="card-items">
                  <p>
                    {' '}
                    <span>Data</span>:{' '}
                    {(info.datahora &&
                      formatDate(info.datahora, 'dd/MM/yyyy')) ||
                      '-'}
                  </p>
                  <p>
                    {' '}
                    <span>Descrição</span>: {info.descricao || '-'}
                  </p>
                  <p>
                    {' '}
                    <span>Pontos</span>:{' '}
                    {(info.credito && formatPointsExtract(info.credito)) || '-'}
                  </p>
                  <p>
                    {' '}
                    <span>Débito </span>:{' '}
                    {(info.debito && formatPointsExtract(info.debito)) || '-'}
                  </p>
                  <p>
                    <span>Saldo</span>:{' '}
                    {(info.saldo && formatPointsExtract(info.saldo)) || '-'}
                  </p>
                  <p>
                    <span>Vencimento</span>:{' '}
                    {(info.dataExpiracao &&
                      formatDate(info.dataExpiracao, 'dd/MM/yyyy')) ||
                      '-'}
                  </p>
                </div>
              ))}
            </ContentMobile>
          )}
        </div>
      </ListTableContainer>
    </Main>
  );
};

export default ExtractDetails;
