import styled, { css } from 'styled-components';

export const Container = styled.div<{ isWinner: boolean }>`
  width: var(--wheel-size);
  height: var(--wheel-size);
  clip: rect(
    0px,
    var(--wheel-size),
    var(--wheel-size),
    calc(var(--wheel-size) / 2)
  );
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: '';
    width: var(--wheel-size);
    height: var(--wheel-size);
    border-radius: 50%;
    clip: rect(
      0px,
      calc(var(--wheel-size) / 2),
      calc(var(--wheel-size) - 100px),
      0px
    );
    transform: rotate(60deg);
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: black 10px 15px 250px inset;
    transition: box-shadow 250ms ease-in-out;
  }

  ${p =>
    p.isWinner &&
    css`
      &:after {
        box-shadow: none;
      }
    `}

  &.slice_1 {
    transform: rotate(60deg);

    &:after {
      background-color: #e60019;
    }
  }

  &.slice_2 {
    transform: rotate(120deg);

    &:after {
      background-color: #fff;
    }
  }

  &.slice_3 {
    transform: rotate(180deg);

    &:after {
      background-color: #e60019;
    }
  }

  &.slice_4 {
    transform: rotate(240deg);

    &:after {
      background-color: #fff;
    }
  }

  &.slice_5 {
    transform: rotate(300deg);

    &:after {
      background-color: #e60019;
    }
  }

  &.slice_6 {
    transform: rotate(360deg);

    &:after {
      background-color: #fff;
    }
  }
`;
