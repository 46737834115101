import { pluginApi } from 'services/api';
import { PointDistributorData } from 'services/point-management/dtos/points-distribution';

type Params = {
  establishmentId: number;
  campaignId: number;
};

export async function getPointsDistribution({
  establishmentId,
  campaignId,
}: Params): Promise<PointDistributorData> {
  const { data } = await pluginApi.get(
    `/points-distribution?establishment_id=${establishmentId}&campaign_id=${campaignId}`,
  );
  return data;
}
