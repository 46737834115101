import React from 'react';
import CloseButton from 'components/shared/CloseButton';
import stepByStep from 'assets/images/strategic-plans/stepByStep.jpg';
import approvalFlow from 'assets/images/strategic-plans/approval-flow.jpeg';
import {
  Container,
  Modal,
  Header,
  Button,
  Text,
  InfoWrapper,
  InfoHeader,
  ContentsInfo,
  InfoBox,
  InfoColumn,
  ButtonWrapper,
  Title,
  SubTitle,
} from './styles';

interface HelpModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  approveStrategyPlan: string;
  campaignStartDate: string;
  campaignEndDate: string;
  createStrategyPlan: string;
}

const HelpModal: React.FC<HelpModalProps> = ({
  isOpen,
  onRequestClose,
  createStrategyPlan,
  approveStrategyPlan,
  campaignStartDate,
  campaignEndDate,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zIndex={3}>
      <Container>
        <CloseButton onClickHandler={onRequestClose} />
        <Header>
          <Title>PLANOS ESTRATÉGICOS</Title>
        </Header>
        <InfoWrapper>
          <InfoHeader>
            <SubTitle>Como funciona?</SubTitle>
            <Text>
              O RTC/RTC-POG: deve criar planos estratégicos das ações que serão
              realizadas durante a safra com cada canal.
            </Text>
          </InfoHeader>
          <InfoBox>
            <img src={stepByStep} alt="Passo a passo" />

            <Text>Prazo para efetuar os planos</Text>
            <ContentsInfo>
              <InfoColumn style={{ width: '150px' }}>
                <h4>{campaignStartDate}</h4>
                <p>Início da Safra</p>
              </InfoColumn>
              <InfoColumn>
                <h4>{createStrategyPlan}</h4>
                <p>Limite de envio dos planos para aprovação</p>
              </InfoColumn>
              <InfoColumn>
                <h4>{approveStrategyPlan}</h4>
                <p>Limite de aprovação dos planos</p>
              </InfoColumn>
              <InfoColumn style={{ borderRight: 'none', width: '150px' }}>
                <h4>{campaignEndDate}</h4>
                <p>Final da Safra</p>
              </InfoColumn>
            </ContentsInfo>
            <img src={approvalFlow} alt="Fluxo de aprovação" className="flow" />
          </InfoBox>
        </InfoWrapper>
        <ButtonWrapper>
          <Button type="button" buttonRole="primary" onClick={onRequestClose}>
            OK
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default HelpModal;
