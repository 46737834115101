import React from 'react';

import { Container } from './styles';

type Props = {
  isWinner: boolean;
  index: number;
};

export const Slice: React.FC<Props> = ({ isWinner, index }) => {
  return <Container className={`slice_${index}`} isWinner={isWinner} />;
};
