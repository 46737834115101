import styled from 'styled-components';
import DefaultInput from 'components/shared/Input/BaseInput';
import DefaultCategoriesProductsSelect from './categories';
import DefaultProductsSelect from './select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoriesProductsSelect = styled(DefaultCategoriesProductsSelect)`
  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
  }
  margin-bottom: 12px;
`;

export const ProductsSelect = styled(DefaultProductsSelect)`
  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
  }
  margin-bottom: 7px;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  max-width: 730px;
  border: 1px solid ${({ theme }) => theme.font.color.quartenary};
  padding-bottom: 25px;

  svg {
    cursor: pointer;
  }

  > ._productName {
    width: 100%;
    max-width: 730px;
    height: 44px;
    border-bottom: 1px solid ${({ theme }) => theme.font.color.quartenary};
    display: flex;
    align-items: center;
    padding: 0 16px;

    > h3 {
      flex: 1;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      font-size: 16px;
      color: ${({ theme }) => theme.font.color.quartenary};
    }
  }
`;

export const ContainerBox = styled.div`
  width: 100%;
  max-width: 710px;
  display: flex;
  flex-direction: column;

  .grid-row {
    display: flex;
    margin-left: 30px;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 8px;

      > h5 {
        font-size: 16px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
        color: ${({ theme }) => theme.font.color.primary};
        flex: 1;
        text-align: left;
      }
    }

    ._inputContainer {
      height: 30px;
      input {
        text-align: right;
      }
    }
  }
`;

export const Input = styled(DefaultInput)`
  width: 100%;
  max-width: 180px;
  margin-right: 30px;
`;
