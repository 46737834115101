import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 33px;
  border-left-width: 1px;
  border-right-width: 1px;
  margin-top: 23px;

  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.fontFamily.condensed};
    color: #000;
    margin-bottom: 25px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-height: 300px;
  color: #000;
  font-family: ${({ theme }) => theme.font.fontFamily.regular};
  padding: 10px;
`;
