/* eslint-disable @typescript-eslint/camelcase */
import {
  InvoiceData,
  InvoiceDataApi,
  InvoiceApi,
  InvoiceDetails,
  InvoiceItems,
} from './interfaces';

export const transformInvoiceFromApi = (
  data: InvoiceDataApi,
): InvoiceData[] => {
  return data.invoices.map(
    ({
      id,
      total_fmccoins,
      totalvalue,
      purchase_date,
      status,
      status_text,
      url,
      establishment,
      access_key,
      invoice_from_api,
      origin
    }) => ({
      id,
      totalFmcCoins: total_fmccoins,
      total: Number(totalvalue),
      purchaseData: purchase_date,
      status,
      statusText: status_text,
      url,
      establishmentName: establishment?.name,
      establishmentClientGroup: establishment?.client_group,
      accessKey: access_key ?? '',
      fromApi: invoice_from_api,
      origin: origin
    }),
  );
};

export const transformInvoiceDetailsFromApi = ({
  dadosnota,
  itensNota,
  valorTotalItens,
}: InvoiceApi): InvoiceDetails => {
  const invoice = dadosnota[0];

  const items = itensNota
    ? itensNota.map<InvoiceItems>(item => ({
        id: item.id,
        category: item.Categoria,
        discount: item.desconto,
        fmcCoins: item.FMCCOINS,
        invoiceId: item.invoice_id,
        participantId: item.participant_id,
        productDescription: item.DescricaoProduto,
        productId: item.product_id,
        productName: item.NomeProduto,
        totalValue: item.total_value,
        unitPoints: item.unit_points,
        netValue: item.valor_net,
        volume: item.volume,
        unitaryValue: item.unitary_value,
      }))
    : [];

  return {
    channel: invoice.canal,
    cnpj: invoice.cnpj,
    cpf: invoice.cpf,
    purchaseDate: invoice.datacompra,
    invoiceNumber: invoice.numeronota,
    items,
    itemsTotal: valorTotalItens,
    status: invoice.status,
    reason: invoice.reason,
    observation: invoice.observation,
    sendDate: invoice.dataenviada,
    origin: invoice.origin
  };
};
