import React, { useCallback, useEffect, useState } from 'react';
import getNaturalness from 'services/register/getNaturalnessForSelect';

import Select, { Option } from 'components/shared/Select';

interface Props {
  className?: string;
  inputRole: 'primary' | 'secondary';
  idState: string | null;
  disabled?: boolean;
}

const NaturalnessSelect: React.FC<Props> = ({
  className,
  inputRole = 'primary',
  idState,
  disabled = false,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (idState) {
      getNaturalness({ idState }).then(data => {
        setOptions(data);
      });
    }
  }, [idState]);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <Select
      name="naturalness_select"
      label="Naturalidade"
      loadItems={loadItems}
      className={className}
      inputRole={inputRole}
      disabled={disabled}
    />
  );
};

export default NaturalnessSelect;
