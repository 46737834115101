import { call, put, all, takeEvery } from 'redux-saga/effects';
import { FmcProductsApi } from 'services/fmc-coins-simulator/interfaces';
import { getFmcProductsApi } from 'services/fmc-coins-simulator/index';
import { handlerErrors } from 'util/handler-errors';
import { ActionCreatorPayload } from '@types';
import { emptyFetchState } from 'state/utils';
import { Pagination } from 'config/constants/vendavallPaginationInterface';
import * as actions from './actions';
import * as transformers from './transformers';
import * as constants from './constants';
import { FmcCoinsSimulatorState, FmcProducts } from './interfaces';
import { initialState } from './reducer';

const formData = {
  page: 1,
  limit: 5,
};
interface ApiResponse {
  data: FmcProductsApi[];
  pagination: Pagination;
}
export function* fetchFmcProducts() {
  try {
    const result: ApiResponse[] = yield call(getFmcProductsApi, formData);
    yield put(
      actions.fetchFmcProductsSuccess(
        transformers.fmcProductsApiToFmcProducts(result),
      ),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFmcProductsFailure);
  }
}

export function* fetchFmcLoadState({
  payload,
}: ActionCreatorPayload<
  typeof constants.FETCH_FMC_LOAD_STATE,
  FmcCoinsSimulatorState
>) {
  try {
    yield put(actions.resetFmc());

    const { fmcProductsApi } = yield call(getFmcProductsApi, formData);

    const productsToSet = (fmcProductsApi as FmcProducts[]).map(product => {
      const productPayloadFound = payload.products.find(
        item => item.id === product.id,
      );
      if (productPayloadFound) {
        product.checked = productPayloadFound.checked;
      }
      return product;
    });

    const state: FmcCoinsSimulatorState = {
      products: productsToSet,
      fetchFmcProducts: emptyFetchState,
      valueToConversion: payload.valueToConversion,
    };
    yield put(actions.fetchFmcLoadStateSuccess(state));
  } catch (error) {
    yield put(actions.fetchFmcLoadStateSuccess(initialState));
  }
}

export default function* fmcCoinsSimulatorSagas() {
  yield all([
    takeEvery(constants.FETCH_FMC_PRODUCTS_ACTION, fetchFmcProducts),
    takeEvery(constants.FETCH_FMC_LOAD_STATE, fetchFmcLoadState),
  ]);
}
