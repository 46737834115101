import React from 'react';
import { EstablishmentTypes, EstablishmentCategory } from 'config/constants';
import { Option } from 'components/shared/Select';
import BaseSelect from 'components/shared/Select/BaseSelect';

interface Props {
  className?: string;
  label?: string;
  setValue(value: Option | null): void;
  value: Option | null;
  placeholder?: string;
  directorName?: string;
  regionalName?: string;
  typeName?: EstablishmentTypes;
  categoryName?: EstablishmentCategory;
  investmentGroupCampaign: string[];
}

const InvestmentGroupSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
  label,
  placeholder,
  investmentGroupCampaign,
}) => {
  const getOptions = () =>
    investmentGroupCampaign.map(x => ({ value: x, title: x }));

  return (
    <BaseSelect
      label={label}
      loadItems={getOptions}
      className={className}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      inputRole="secondary"
    />
  );
};

export default InvestmentGroupSelect;
