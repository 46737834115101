import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import finishIndirectRegister, {
  Request,
} from 'services/participants/finishIndirectRegister';
import { acceptRegulation } from 'services/register/regulation';
import { fetchProfiles } from 'state/modules/header/actions';
import { getAvailableProfiles } from 'state/modules/header/selectors';
import { useAuth } from 'context/AuthContext';
import history from 'services/history';
import { useToast } from 'context/ToastContext';
import getSchemaValidations from './getSchemaValidations';
import defaultFormValues from './defaultFormValues';

import {
  Container,
  Input,
  GraduationLevelSelect,
  UfSelect,
  MaritalSelect,
  Button,
  ActionsWrapper,
} from './styles';

interface FormData extends Request {
  code_schooling_select: { title: string; value: string } | null;
  marital_status_code_select: { title: string; value: string } | null;
  rg_emitter_uf_select: { title: string; value: string } | null;
}

interface FormProps {
  acceptedRegulation: {
    id: number;
    version: number;
  } | null;
}

const Form: React.FC<FormProps> = ({ acceptedRegulation }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const profiles = useSelector(getAvailableProfiles);
  const { changeProfile } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    if (profiles.indirect) {
      const { establishmentId, roleId } = profiles.indirect;
      changeProfile({ establishmentId, roleId });

      setTimeout(() => {
        history.push('/');
      }, 1000);

      setLoading(false);
    }
  }, [changeProfile, profiles]);

  const methods = useForm<FormData>({
    mode: 'onSubmit',
    validationSchema: getSchemaValidations(),
    defaultValues: defaultFormValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        code_schooling: data.code_schooling_select?.value || '',
        marital_status_code: data.marital_status_code_select?.value || '',
        rg_emitter_uf: data.rg_emitter_uf_select?.value || '',
      };

      await finishIndirectRegister(payload);

      if (acceptedRegulation) {
        const { id, version } = acceptedRegulation;
        await acceptRegulation(id, version);
      }

      dispatch(fetchProfiles());
    } catch (err) {
      addToast({
        title:
          err.response?.data?.message ||
          'Problemas ao completar o seu cadastro!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GraduationLevelSelect name="code_schooling_select" />
          <Input name="place_of_birth" label="Naturalidade" />
          <Input name="nationality" label="Nacionalidade" />
          <MaritalSelect name="marital_status_code_select" />
          <Input name="rg_emitter" label="Órgão emissor" />
          <UfSelect name="rg_emitter_uf_select" label="UF do Órgão Emissor" />
          <Input
            name="pis_nis"
            label="N° inscrição na Previdência Social (PIS ou NIS)"
          />
          <Input name="rg" label="RG" />
          <ActionsWrapper>
            <Button buttonRole="quaternary" type="submit" loading={loading}>
              Confirmar
            </Button>
          </ActionsWrapper>
        </form>
      </FormContext>
    </Container>
  );
};

export default Form;
