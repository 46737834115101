import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  > h2 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 20px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 3px;
  margin-bottom: 13px;
`;

export const Box = styled.h3`
  width: 240px;
  height: 38px;
  border: 1px solid ${({ theme }) => theme.font.color.primary};
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: uppercase;
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  padding: 10px;
  font-size: 16px;
  background-color: #fff;

  @media screen and (max-width: 700px) {
    width: 155px;
  }
`;

export const Table = styled.table`
  width: 100%;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }

  tr {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    text-transform: uppercase;
    height: 40px;
  }

  tbody {
    background-color: #efefef;
    border: 1px solid #fff;
  }

  .box {
    background-color: #fff;
    padding-left: 0;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
`;

export const Th = styled.th`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 16px;
  text-transform: uppercase;
  padding: 6px;
  width: 200px;

  @media screen and (max-width: 700px) {
    width: 155px;
    font-size: 14px;
  }
`;

export const Td = styled.td`
  color: ${({ theme }) => theme.font.color.quartenary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 14px;
  width: 200px;
  text-align: left;
  padding-left: 15px;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
  }

  @media screen and (max-width: 700px) {
    width: 155px;
    font-size: 12px;
  }
`;
