import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 40;
  font-size: 12px;
`;

interface ModalContainerProps {
  modalOpen: boolean;
}

export const ModalContainer = styled(Container)<ModalContainerProps>`
  visibility: hidden;
  ${({ modalOpen }) =>
    modalOpen &&
    css`
      visibility: visible;
    `}
`;

export const PageTitle = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  padding: 36px;
  position: relative;
  z-index: 50;
  background: #fff;
  width: 80%;
  max-width: 750px;
  max-height: 80vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow-y: scroll;
  h3,
  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const DetailsSection = styled.div<{ fullWidth?: boolean }>`
  background: #efefef;
  padding: 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    svg {
      cursor: pointer;
    }
    > div {
      width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
    }
  }
`;

export const CloseModalOverlay = styled.div`
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
`;

export const BottomText = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  margin-top: 32px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};

  @media (min-width: 768px) {
    font-size: 16px;
  }
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 1.3em;
  margin-top: 1rem;
`;

export const DetailsText = styled.p`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5rem;
`;

export const DetailsHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

export const InvoiceLink = styled.a`
  color: ${({ theme }) => theme.font.color.tertiary};
  background-color: ${({ theme }) => theme.font.color.primary};
  padding: 1px 5px;
  border-radius: 5px;
  decoration: none;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.font.color.primary};
    background-color: ${({ theme }) => theme.font.color.tertiary};
  }
`;
