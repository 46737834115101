import { AnswerData } from './types';

const getAlreadyAnsweredIndex = (
  answers: AnswerData[],
  data: AnswerData,
): number => {
  switch (data.multipleAnswers) {
    case 'many':
      return answers.findIndex(
        ({ answerId, topic }) =>
          answerId === data.answerId && topic === data.topic,
      );
    case 'unique':
      return answers.findIndex(
        ({ questionId }) => questionId === data.questionId,
      );

    default:
      return answers.findIndex(({ answerId }) => answerId === data.answerId);
  }
};

const removeItem = (index: number, answers: AnswerData[]): AnswerData[] => {
  answers.splice(index, 1);
  return answers;
};

export const getAnswersValues = (
  answers: AnswerData[],
  data: AnswerData,
): AnswerData[] => {
  const index = getAlreadyAnsweredIndex(answers, data);

  if (index < 0) return [...answers, data];

  if (index >= 0 && data.deleteOperation) return removeItem(index, answers);

  answers[index] = { ...data };
  return [...answers];
};
