import { pluginApi } from 'services/api';
import { transformStrategicPlanFromApi } from 'services/strategic-plans/transformer';
import { StrategicPlan, PlanFilters as Filters } from './interfaces';

type Response = {
  success: boolean;
  data?: StrategicPlan[];
  error?: string;
};

type Options = {
  campaignId: number;
  fetch: 'created-plans' | 'to-approve-plans';
  filters: Filters;
};

export async function getStrategicPlans({
  campaignId,
  fetch,
  filters,
}: Options): Promise<Response> {
  try {
    const { data } = await pluginApi.get('/strategy-plans', {
      params: {
        order: 'desc',
        campaign_id: campaignId,
        create: fetch === 'created-plans',
        client_group_id: filters.channelId,
        team_participant_id: filters.teamMemberId,
        status: filters.status,
        product_id: filters.productId,
        crop_id: filters.cropId,
        author_id: filters.authorId,
        approver_id: filters.approverId,
      },
    });

    return {
      success: true,
      data: transformStrategicPlanFromApi(data),
    };
  } catch (error) {
    return {
      success: false,
      error: 'Falha ao consultar os planos estratégicos',
    };
  }
}
