import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 15px;
  justify-content: flex-end;

  button {
    font-size: 14px;
    padding: 10px 25px;
    margin: 0;
    height: auto;
    width: fit-content;
    min-width: 150px;
  }
`;
