import React from 'react';
import Select from 'components/shared/Select/BaseSelect';
import { Option } from 'components/shared/Select';

interface Props {
  className?: string;
  disabled?: boolean;
  inputRole?: 'primary' | 'secondary';
  loadItems(search: string): Option[] | Promise<Option[]>;
  value: Option | null;
  setValue(value: Option | null): void;
}

const ClientGroupSelect: React.FC<Props> = ({
  className,
  loadItems,
  value,
  setValue,
  disabled = false,
  inputRole = 'primary',
}) => {
  return (
    <Select
      label=""
      value={value}
      setValue={setValue}
      loadItems={loadItems}
      className={className}
      disabled={disabled}
      inputRole={inputRole}
    />
  );
};

export default ClientGroupSelect;
