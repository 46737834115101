import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import numbersOnly from 'util/numbersOnly';

import { formatPoints } from 'util/points';
import { Container, Button } from './styles';
import * as actions from 'state/modules/points-simulator/actions';

interface Props {
  dollarBaseValue: number;
  changeDollarBaseValue(dollarValue: number): void;
  buttonActionText: string;
  handleButtonAction(): void;
  quantityItemsAdded: number;
  GroosNet: number;
}

const Footer: React.FC<Props> = ({
  dollarBaseValue,
  handleButtonAction,
  buttonActionText,
  quantityItemsAdded,
  changeDollarBaseValue,
  GroosNet
}) => {
  const [inputValue, setInputValue] = useState(formatPoints(dollarBaseValue));
  const dispatch = useDispatch(); // Obtenha a função de despacho do Redux
  let newGroos = (100 - GroosNet) / 100 * 100;
  const [newGroosNet, setNewGroosNet] = useState(formatPoints(newGroos));

  const handleDollarChange = useCallback(() => {
    changeDollarBaseValue(
      parseFloat(inputValue.replace(/\./gi, '').replace(/,/gi, '.')),
    );
  }, [changeDollarBaseValue, inputValue]);

  const handleCrop = useCallback(() => {
    const newPogRealizedNetPercentage = parseFloat(numbersOnly(newGroosNet)) / 100;
    setNewGroosNet(formatPoints(newPogRealizedNetPercentage));
    const newPercentageCalc = ((100 - newPogRealizedNetPercentage) * 100) / 100;
    dispatch(actions.setPogRealizedNetPercentage(newPercentageCalc));
  }, [dispatch, newGroosNet]);

  useEffect(() => {
    setNewGroosNet(formatPoints(newGroos));
  }, [newGroos]);

  return (
    <Container className="_footer-dolar-container">
      <div>
        <span>Dólar base</span>
        <span>US$</span>
        <input
          type="text"
          value={inputValue}
          onChange={e => {
            setInputValue(
              formatPoints(
                parseFloat(numbersOnly(e.currentTarget.value)) / 100,
              ),
            );
          }}
          onBlur={handleDollarChange}
          disabled={buttonActionText !== 'Calcular'}
        />
      </div>

      <div>
        <span>Gross/Net</span>
        <span>%</span>
        <input 
          type="text" 
          value={newGroosNet} 
          onBlur={handleCrop}
          onChange={e => {
            setNewGroosNet(
              formatPoints(
                parseFloat(numbersOnly(e.currentTarget.value)) / 100,
              ),
            );
          }}
          disabled={buttonActionText !== 'Calcular'}        
        />
      </div>
      
      <div>
        <span>Itens adicionados ({quantityItemsAdded})</span>
        <Button
          buttonRole="tertiary"
          type="button"
          onClick={handleButtonAction}
          className="_calculator-action-button"
        >
          {buttonActionText}
        </Button>
      </div>
    </Container>
  );
};

export default Footer;
