import styled from 'styled-components';
import bgCampaigns from 'assets/images/accelerator-campaigns/bg-campaigns-list.png';

export const Container = styled.div`
  background-image: url(${bgCampaigns});
  background-position: 10% 0;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  height: auto;
  min-height: 250px;
  padding: 40px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Information = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  display: flex;
  flex-direction: column;
  margin-left: 180px;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 20px;
    margin-bottom: 20px;

    @media (max-width: 920px) {
      font-size: 16px;
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;

    @media (max-width: 920px) {
      font-size: 14px;
    }
  }

  @media (max-width: 920px) {
    margin-left: 2rem;
    padding: 0 25px 0 25px;
  }
`;

export const ContentButton = styled.div`
  width: 17rem;
  padding: 0.7rem 1.2rem;

  button {
    width: 15rem;
    height: 45px;
    border-radius: 1px;
    position: relative;
    top: -0.3rem;
  }

  @media (max-width: 920px) {
    margin-top: 20px;
  }
`;
