import { Option } from 'components/shared/Select';
import { formatPoints } from 'util/points';
import { Audience } from '../interfaces/Campaign';

export default (data: Audience[]): Option[] => {
  return data
    .sort((a, b) => a.customer.name.localeCompare(b.customer.name))
    .map(item => ({
      title: `${item.customer.name} - Saldo R$ ${formatPoints(item.balance)}`,
      value: item.customer.id.toString(),
    }));
};
