/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import history from 'services/history';
import routeMap from 'routes/route-map';
import { useToast } from 'context/ToastContext';
import {
  getFmcProducts,
  getValueToConversion,
} from 'state/modules/fmc-coins-simulator/selectors';
import { formatPoints } from 'util/points';
import { Button } from 'components/shared';
import {
  Container,
  MiniBox,
  Content,
  PageTitle,
  Separator,
  CardsContainer,
  ContentMobile,
  CatalogDesktop,
  CatalogMobile,
  InformationSimulatedDesktop,
  InformationSimulatedMobile,
} from './styles';
import useWindowSize from 'hooks/use-window-dimensions';
import {
  getFmcProductsApi,
  getSimulationSaved,
  saveSimulationProducts,
} from 'services/fmc-coins-simulator';
import {
  fmcProductsApi,
  fmcProductsApiToFmcProducts,
} from 'state/modules/fmc-coins-simulator/transformers';
import CardsSaved from 'components/FmcCoinsSimulator/CardsSaved';
import { FmcProductsApi } from 'services/fmc-coins-simulator/interfaces';
import { fetchFmcProductsSuccess } from 'state/modules/fmc-coins-simulator/actions';

type Params = {
  id: string;
};
const ResultSaved: React.FC = () => {
  const { id } = useParams<Params>();
  const productsSelector = useSelector(getFmcProducts);
  const valueToConversion = useSelector(getValueToConversion);
  const { width } = useWindowSize();

  const { addToast } = useToast();
  const [productsApi, setProductsApi] = useState<FmcProductsApi[]>([]);

  const getValues = localStorage.getItem('saved-simulation');
  const parsedValues = getValues && JSON.parse(getValues);

  const getValuest = localStorage.getItem('calculate-products');
  const parsedValuest = getValuest && JSON.parse(getValuest);

  const [loadState, setLoadState] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [product, setProduct] = useState<any>();

  useEffect(() => {
    const getFmcApiProducts = async () => {
      const productsFmcApi = await getFmcProductsApi({
        page: 1,
        limit: 100,
      });
      const allProductsApi = fmcProductsApi(productsFmcApi);
      setProductsApi(allProductsApi);
      const productsToInput = fmcProductsApiToFmcProducts(productsFmcApi);
      dispatch(fetchFmcProductsSuccess(productsToInput));
    };

    if (loadState) {
      getFmcApiProducts();
    }
  }, [dispatch, loadState]);

  useEffect(() => {
    const getSimulation = async () => {
      const response = await getSimulationSaved(id);
      setProduct(response);
    };

    getSimulation();
  }, []);

  const getProductMock = product && product;

  const [valueShow, setValueShow] = useState<any>();
  const [fmcCoinsShow, setFmcCoinsShow] = useState<any>();

  let newArray: any[] = [];

  parsedValuest &&
    parsedValuest
      .filter(
        (product: any) =>
          getProductMock &&
          getProductMock.products.find(
            (value: any) =>
              value.product === product.id ||
              value.id === product.id ||
              value.product === product.product,
          ),
      )
      .forEach((prod: any) => {
        newArray.push({
          product: prod.id,
          value: prod.valueToConversion,
          fmcCoins: prod.fmcCoins,
        });
      });

  const onSumValuesTotal = useCallback(
    (valueTotal: number, fmcCoins: number) => {
      const formattedValueTotal = formatPoints(valueTotal);
      const formattedFmcCoins = formatPoints(fmcCoins);

      setValueShow(formattedValueTotal);
      setFmcCoinsShow(formattedFmcCoins);

      localStorage.setItem(
        'saved-simulation',
        JSON.stringify({
          campaign_id: 14,
          products: newArray,
          total: formattedValueTotal,
          total_fmc_coin: formattedFmcCoins,
        }),
      );
    },
    [newArray],
  );

  const saveProducts = async () => {
    try {
      const data = await saveSimulationProducts({
        campaign_id: 14,
        total: formatPoints(valueShow),
        total_fmc_coin: formatPoints(fmcCoinsShow),
        products: newArray,
      });

      if (data.message === 'Simulação salva com sucesso!') {
        addToast({
          title: 'Simulação salva com sucesso!',
          type: 'success',
        });
      }
    } catch (err) {
      addToast({
        title: 'Erro ao salvar sua simulação!',
        type: 'error',
      });
    }

    addToast({
      title: 'Simulação salva com sucesso!',
      type: 'success',
    });

    setTimeout(() => {
      localStorage.removeItem('saved-simulation');
      localStorage.removeItem('calculate-products');

      history.push(`${routeMap.fmcCoinsSimulator.selector}`);
    }, 1000);
  };

  const getSpecificProduct = productsSelector.filter(
    p =>
      getProductMock &&
      getProductMock.products.find(
        (product: any) => p.id === product.product || p.id === product.id,
      ),
  );

  const filterByCategory = (products: any, category: string) => {
    return products
      .filter(
        (product: any) =>
          getProductMock &&
          getProductMock.products.find(
            (p: any) => p.id === product.product || p.id === product.id,
          ),
      )
      .filter((p: any) => p.category === category);
  };

  const sortProductsAlphabetically = (products: any[]) => {
    return products.sort((a, b) => a.name.localeCompare(b.name));
  };

  const getStrategic = sortProductsAlphabetically(
    filterByCategory(getSpecificProduct, 'Estratégico'),
  );
  const getInnovation = sortProductsAlphabetically(
    filterByCategory(getSpecificProduct, 'Inovação'),
  );
  const getComplementary = sortProductsAlphabetically(
    filterByCategory(getSpecificProduct, 'Complementar'),
  );

  const findValueById = (products: any, findArray: any) => {
    return (
      products &&
      products.filter(
        (product: any) =>
          findArray &&
          findArray.find(
            (item: any) =>
              item.id === product.product || item.id === product.id,
          ),
      )
    );
  };

  const getValueDefault = (findArray: any) => {
    return (
      findArray &&
      findArray.map((item: any) => ({
        value: item.value,
        fmcCoins: item.fmcCoins,
      }))
    );
  };

  const findValueByIdStrategic = findValueById(
    getProductMock && getProductMock.products,
    getStrategic,
  );
  const findValueByIdInnovation = findValueById(
    getProductMock && getProductMock.products,
    getInnovation,
  );
  const findValueByIdComplementary = findValueById(
    getProductMock && getProductMock.products,
    getComplementary,
  );

  const getValueDefaultStrategic = getValueDefault(findValueByIdStrategic);
  const getValueDefaultInnovation = getValueDefault(findValueByIdInnovation);
  const getValueDefaultComplementary = getValueDefault(
    findValueByIdComplementary,
  );

  const { total_fmc_coin, total } = parsedValues || {};

  const validateFields = total_fmc_coin === undefined || total === undefined;
  const validateAux =
    parseFloat(valueShow) <= 0 ||
    valueShow === undefined ||
    parseFloat(fmcCoinsShow) <= 0 ||
    fmcCoinsShow === undefined;

  const createdDate = new Date(product && product.created);
  const formattedDate = `${createdDate.getDate()}/0${
    createdDate.getMonth() + 1
  }/${createdDate.getFullYear()}`;

  const backBtn = () => {
    localStorage.removeItem('save-simulation');
    localStorage.removeItem('saved-simulation');
    localStorage.removeItem('calculate-products');
    history.push('/simulador-coins');
  };

  return (
    <Container id="result">
      {width > 1000 ? (
        <div className="container-buttons">
          <div className="content-simulation">
            <PageTitle>Resultado da simulação</PageTitle>
            <span>
              Produtos adicionados (
              {getSpecificProduct && getSpecificProduct.length})
            </span>
          </div>
          <div className="buttons-desktop">
            <Button
              className="btn-save"
              style={{ opacity: validateFields ? '0.6' : '1' }}
              type="submit"
              buttonRole="quaternary"
              title={
                validateFields
                  ? 'Recalcule para poder salvar outra simulação'
                  : ''
              }
              onClick={saveProducts}
              disabled={validateFields}
            >
              Salvar
            </Button>
            <Button
              className="btn-back"
              type="submit"
              buttonRole="primary"
              onClick={backBtn}
            >
              Voltar
            </Button>
          </div>
        </div>
      ) : (
        <Content>
          <PageTitle>Resultado da simulação</PageTitle>
          <span>
            Produtos adicionados (
            {getSpecificProduct && getSpecificProduct.length})
          </span>
          <ContentMobile>
            <Button
              className="btn-save-mobile"
              style={{ opacity: validateFields ? '0.6' : '1' }}
              type="submit"
              buttonRole="quaternary"
              onClick={saveProducts}
            >
              Salvar
            </Button>
            <Button
              className="btn-back-mobile"
              type="submit"
              buttonRole="primary"
              onClick={backBtn}
            >
              Voltar
            </Button>
          </ContentMobile>
          <br />
          <Separator />
          <br />
        </Content>
      )}
      {width > 1000 ? (
        <InformationSimulatedDesktop>
          Simulação realizada em {formattedDate}. os valores recalculados sempre
          utilizam os valores da safra vigente no momento da simulação.
        </InformationSimulatedDesktop>
      ) : (
        <InformationSimulatedMobile>
          Simulação realizada em! {formattedDate}. os valores recalculados
          sempre utilizam os valores da safra vigente no momento da simulação.
        </InformationSimulatedMobile>
      )}

      <Separator />

      <CardsContainer>
        <CardsSaved
          defaultVal={
            validateAux
              ? getValueDefaultInnovation &&
                getValueDefaultInnovation &&
                getValueDefaultInnovation[0] &&
                getValueDefaultInnovation[0].value
              : valueToConversion
          }
          products={getInnovation}
          type="result"
          categoryInterface="Inovação"
          categoryComponent="innovation"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
          defaultFmcCoins={
            validateAux &&
            getValueDefaultInnovation &&
            getValueDefaultInnovation &&
            getValueDefaultInnovation[0] &&
            getValueDefaultInnovation[0].fmcCoins
          }
        />

        <CardsSaved
          defaultVal={
            validateAux
              ? getValueDefaultStrategic &&
                getValueDefaultStrategic &&
                getValueDefaultStrategic[0] &&
                getValueDefaultStrategic[0].value
              : valueToConversion
          }
          products={getStrategic}
          type="result"
          categoryInterface="Estratégico"
          categoryComponent="strategic"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
          defaultFmcCoins={
            validateAux &&
            getValueDefaultStrategic &&
            getValueDefaultStrategic &&
            getValueDefaultStrategic[0] &&
            getValueDefaultStrategic[0].fmcCoins
          }
        />
        <CardsSaved
          defaultVal={
            validateAux
              ? getValueDefaultComplementary &&
                getValueDefaultComplementary &&
                getValueDefaultComplementary[0] &&
                getValueDefaultComplementary[0].value
              : valueToConversion
          }
          products={getComplementary}
          type="result"
          categoryInterface="Complementar"
          categoryComponent="complementary"
          valueToConversion={valueToConversion}
          onSumValuesTotal={onSumValuesTotal}
          defaultFmcCoins={
            validateAux &&
            getValueDefaultComplementary &&
            getValueDefaultComplementary &&
            getValueDefaultComplementary[0] &&
            getValueDefaultComplementary[0].fmcCoins
          }
        />
      </CardsContainer>

      <br />
      <br />
      <MiniBox>
        <div className="total-value">
          <span>Valor total</span>
          <span>
            R${' '}
            {parseFloat(valueShow) > 0
              ? valueShow
              : getProductMock && getProductMock.total}
          </span>
        </div>
        <div className="total-fmc-coins">
          <span>Total FMC Coins</span>
          <span>
            {parseFloat(fmcCoinsShow) > 0
              ? fmcCoinsShow
              : getProductMock && getProductMock.total_fmc_coin}
          </span>
        </div>
      </MiniBox>
      <br />
      <br />
      <Link
        to={`${routeMap.fmcCoinsSimulator.calculatorSaved}/${id}`}
        className="btn"
      >
        Recalcular
      </Link>

      {width > 1000 ? (
        <CatalogDesktop>
          <h4>
            Conheça as opções de serviços e benefícios no Catálogo de Prêmios
          </h4>
          <Button
            type="button"
            onClick={() => history.push('/catalogo')}
            buttonRole="quaternary"
          >
            Acessar Catálogo de Prêmios
          </Button>
        </CatalogDesktop>
      ) : (
        <CatalogMobile>
          <h4>
            Conheça as opções de serviços e benefícios
            <br /> no Catálogo de Prêmios
          </h4>
          <Button
            type="button"
            onClick={() => history.push('/catalogo')}
            buttonRole="quaternary"
          >
            Acessar Catálogo de Prêmios
          </Button>
        </CatalogMobile>
      )}
    </Container>
  );
};

export default ResultSaved;
