import React, { useCallback, useState } from 'react';
import { deleteFarm } from 'services/participants/fazendas';

import { useToast } from 'context/ToastContext';
import { MemberGroup } from 'services/auth/interfaces/Participant';
import { pluginApi } from 'services/api';
import { Alert, useSimpleToast } from 'components/shared';
import { Checkbox } from '@material-ui/core';
import { useAuth } from 'context/AuthContext';
import useDimensions from 'hooks/use-window-dimensions';
import {
  Obs,
  Button,
  ListContainer,
  Title,
  ActionsContainer,
  CustomLink,
  ContainerComponent,
  ContentMobile,
} from './styles';
import { FarmRegisterAndEdit } from './modals/FarmRegisterAndEdit';
import { FormField } from './modals/FarmRegisterAndEdit/styles';

interface Props {
  actived: boolean;
}

const FarmDataForm: React.FC<Props> = ({ actived }) => {
  const { participant, refreshParticipant } = useAuth();
  const { addToast } = useToast();
  const { showToast } = useSimpleToast();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(participant.only_farm);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [editingData, setEditingData] = useState<MemberGroup | null>(null);

  const { width } = useDimensions();

  const handleRemoveMember = useCallback(
    async (member: MemberGroup) => {
      try {
        await deleteFarm(member);

        showToast({
          message: 'Fazenda excluída com sucesso',
          type: 'success',
        });

        refreshParticipant();
      } catch (error) {
        addToast({
          title:
            (error as any).response?.data?.message ||
            'Falha ao excluir a fazenda. Por favor entre em contato com o suporte',
          type: 'error',
        });
      }
    },
    [showToast, refreshParticipant, addToast],
  );

  const handleFarmAdd = () => {
    // Reset form
    setEditingData(null);
    setRegisterModalVisible(!registerModalVisible);
  };

  const handleCheckboxChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    _checked: boolean,
  ) => {
    const old = checked;
    setChecked(_checked);
    setLoading(true);

    pluginApi
      .post('/participants/fazendas/addFarm', {
        only_farm: _checked,
      })
      .then(() => {
        showToast({
          message: 'Opção salva com sucesso.',
          type: 'success',
        });

        refreshParticipant();
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        setChecked(old);
      
        if (error.response) {
          const errorMessage = error.response.data?.message || 'Falha ao executar ação.';
      
          showToast({
            message: errorMessage,
            type: 'error',
          });
        } else if (error.request) {
          // A requisição foi feita, mas nenhuma resposta foi recebida
          showToast({
            message: 'Sem resposta do servidor. Verifique sua conexão.',
            type: 'error',
          });
        } else {
          // Algo aconteceu na criação da requisição que causou um erro
          showToast({
            message: 'Erro ao configurar a requisição.',
            type: 'error',
          });
        }
      });
  };

  const hasRegisteredFarms = participant.members_group.length > 0;

  const isRegistrationComplete = checked || hasRegisteredFarms;

  return (
    <>
      <main style={{ display: actived ? 'block' : 'none' }}>
        {!isRegistrationComplete && (
          <div style={{ marginBottom: '20px' }}>
            <Alert style={{ textAlign: 'center' }} variation="error">
              Você precisa cadastrar os dados da sua fazenda/CNPJ para completar
              seu cadastro!
            </Alert>
          </div>
        )}

        <ContainerComponent>
          <div className="alert">
            <strong>
              Caso não possua outro CPF/CNPJ além do que utiliza para acessar a
              plataforma, marque a opção a seguir.
            </strong>
            <br /> <br />
            <FormField>
              <Checkbox
                onChange={handleCheckboxChange}
                checked={checked}
                disabled={loading}
              />
              <span className="span-cpf">Não tenho outro CPF/CNPJ.</span>
            </FormField>
          </div>

          <hr />
          <Title>Razões Sociais do Grupo</Title>
          <Obs>
            Se você utiliza mais de um CPF/CNPJ para efetuar compras, clique no
            botão abaixo para adicioná-lo(s) <br /> para que todas as notas do
            grupo sejam válidas.
          </Obs>
          {width > 768 ? (
            <ListContainer>
              {hasRegisteredFarms && (
                <table>
                  <thead>
                    <tr>
                      <th>Tipo:</th>
                      <th>
                        Nome ou <br /> Razão Social:
                      </th>
                      <th>CPF ou CNPJ:</th>
                      <th>
                        Inscrição
                        <br /> Estadual:
                      </th>
                      <th>Cidade:</th>
                      <th>Estado:</th>
                      <th>
                        Data da <br />
                        Associação:
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participant.members_group.map(item => (
                      <tr key={item.cpf_cnpj}>
                        <td>{item.type}</td>
                        <td>{item.name}</td>
                        <td>{item.cpf_cnpj}</td>
                        <td>{item.ie}</td>
                        <td>{item.city}</td>
                        <td>{item.uf}</td>
                        <td>{item.created}</td>
                        <td>
                          <ActionsContainer>
                            <CustomLink
                              onClick={() => {
                                setEditingData(item);
                                setRegisterModalVisible(true);
                              }}
                            >
                              Editar
                            </CustomLink>
                            <CustomLink
                              onClick={() => handleRemoveMember(item)}
                            >
                              Excluir
                            </CustomLink>
                          </ActionsContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </ListContainer>
          ) : (
            <ContentMobile>
              {participant.members_group.map(item => (
                <div className="card-items" key={item.cpf_cnpj}>
                  <p>
                    <span>Tipo: {item.type}</span>
                  </p>
                  <p>
                    <span>Nome ou Razão Social: {item.name}</span>
                  </p>
                  <p>
                    <span>CPF ou CNPJ: {item.cpf_cnpj}</span>
                  </p>
                  <p>
                    <span>Inscrição Estadual: {item.ie}</span>
                  </p>
                  <p>
                    <span>Cidade: {item.city}</span>
                  </p>
                  <p>
                    <span>Estado: {item.uf}</span>
                  </p>

                  <p>
                    <span>Data da Associação: {item.created}</span>
                  </p>

                  <p>
                    <span>Estado: {item.uf}</span>
                  </p>

                  <ActionsContainer>
                    <CustomLink
                      onClick={() => {
                        setEditingData(item);
                        setRegisterModalVisible(true);
                      }}
                    >
                      Editar
                    </CustomLink>
                    <CustomLink onClick={() => handleRemoveMember(item)}>
                      Excluir
                    </CustomLink>
                  </ActionsContainer>
                </div>
              ))}
            </ContentMobile>
          )}
          <div className="actions">
            <Button type="button" buttonRole="primary" onClick={handleFarmAdd}>
              Adicionar fazenda
            </Button>
          </div>
        </ContainerComponent>
        <FarmRegisterAndEdit
          initialData={editingData}
          isEditing={editingData !== null}
          onClose={() => setRegisterModalVisible(false)}
          isOpen={registerModalVisible}
        />
      </main>
    </>
  );
};

export default FarmDataForm;
