import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import Dialog from '@material-ui/core/Dialog';
import MaterialButton from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button, Table, useSimpleToast } from 'components/shared';
import { getStrategicPlans } from 'services/strategic-plans/getStrategicPlans';
import {
  DateLimit,
  PlanFilters,
  StrategicPlan,
} from 'services/strategic-plans/interfaces';
import history from 'services/history';
import requestPlanApproval from 'services/strategic-plans/requestPlanApproval';
import createPlan, {
  CreatePlanPayload,
} from 'services/strategic-plans/createPlan';
import { getStatusText } from 'components/StrategicPlans/utils';
import { Option } from 'components/shared/Select';
import { fakeFormatDollars } from 'util/points';
import { strategyPlanStatus } from 'components/StrategicPlans/constants';
import { useAuth } from 'context/AuthContext';
import ClientSelector from 'components/StrategicPlans/ClientSelector';
import { HelpButton } from 'pages/StrategicPlans/styles';
import { useToast } from 'context/ToastContext';
import HelpModal from 'components/StrategicPlans/Modals/HelpModal';
import { formatDate } from 'util/datetime';
import Filters from 'components/StrategicPlans/Filters';
import getDateLimitForPlans from 'services/strategic-plans/getDateLimitForPlans';
import { Container, Head, Label } from './styles';

const statusClasses = {
  [strategyPlanStatus.approved]: 'status--approved',
  [strategyPlanStatus.inAdjustments]: 'status--adjustments',
  [strategyPlanStatus.onApproval]: 'status--onapproval',
  [strategyPlanStatus.planning]: 'status--planning',
  [strategyPlanStatus.outOfTime]: 'status--outoftime',
};

type Props = {
  campaignId: number;
};

export const PlanCreationScreen = ({ campaignId }: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [plans, setPlans] = useState<StrategicPlan[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<PlanFilters>({});
  const [showHelp, setShowHelp] = useState(false);
  const [askApprovationConfirm, setAskApprovationConfirm] = useState('');
  const [clientGroupSelected, setClientGroupSelected] = useState<Option | null>(
    null,
  );
  const [dateLimit, setDateLimit] = useState<DateLimit | null>(null);

  const {
    participant,
    participant: { profile_value: profileValue },
  } = useAuth();

  const { addToast } = useToast();
  const { showToast } = useSimpleToast();

  const fetchPlans = useCallback(() => {
    setLoading(true);
    setPlans([]);
    getStrategicPlans({
      campaignId,
      fetch: 'created-plans',
      filters,
    })
      .then(response => {
        if (response.success && response.data) {
          setPlans(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [campaignId, filters]);

  useEffect(() => {
    if (campaignId === 0) return;
    fetchPlans();
  }, [campaignId, fetchPlans, filters]);

  useEffect(() => {
    getDateLimitForPlans(campaignId).then(response => setDateLimit(response));
  }, [campaignId]);

  const requestApprovation = async (uuid: string) => {
    const index = plans.findIndex(plan => plan.uuid === uuid);
    const plan = plans[index];
    setAskApprovationConfirm('');
    plan.isLoading = true;
    setPlans(old => old.map((p, i) => (i === index ? plan : p)));

    try {
      await requestPlanApproval(uuid);
      showToast({
        message: 'Solicitação de aprovação realizada com sucesso!',
        type: 'success',
      });
      plan.isLoading = false;
      plan.status = strategyPlanStatus.onApproval;
      setPlans(old => old.map((p, i) => (i === index ? plan : p)));
      fetchPlans();
    } catch (error) {
      addToast({
        title:
          (error as any).response?.data?.message ||
          'Problemas ao Solicitar Aprovação!',
        type: 'error',
      });
      plan.isLoading = false;
      setPlans(old => old.map((p, i) => (i === index ? plan : p)));
    }
  };

  const handleCreatePlan = async () => {
    if (!clientGroupSelected && profileValue !== 'DM') {
      return false;
    }

    const establishmentId = clientGroupSelected
      ? clientGroupSelected.value
      : null;

    const payload: CreatePlanPayload = {
      establishment_id: establishmentId,
    };

    try {
      const { data } = await createPlan(payload);
      history.push(`/planos-estrategicos/${data.uuid}`, { uuid: data.uuid });
    } catch (err) {
      addToast({
        title:
          (err as any).response?.data?.message || 'Falha ao criar novo plano',
        type: 'error',
      });
      return false;
    }

    return true;
  };

  const handleFilterChange = useCallback(
    (planfilters: PlanFilters) => setFilters(planfilters),
    [],
  );

  const getCurrentApprover = (plan: StrategicPlan) => {
    if (plan.askApprovations?.length) {
      const approver = plan.askApprovations[0];

      if (approver.participantProfile === 'CRM') {
        return 'CRM';
      }

      return (
        <div>
          <span>
            {approver.participantName} ({approver.participantProfile})
          </span>
        </div>
      );
    }
    return '---';
  };

  const handleFilterDisplay = () => setShowFilters(!showFilters);

  const campaignStartDate = dateLimit?.campaign_start_date;
  const campaignEndDate = dateLimit?.campaign_end_date;
  const createStrategyPlan = dateLimit?.create_strategy_plan;
  const approveStrategyPlan = dateLimit?.approve_strategy_plan;
  const isDM = profileValue === 'DM';

  return (
    <Container>
      <HelpModal
        isOpen={showHelp}
        onRequestClose={() => setShowHelp(false)}
        campaignStartDate={formatDate(campaignStartDate)}
        createStrategyPlan={formatDate(createStrategyPlan)}
        campaignEndDate={formatDate(campaignEndDate)}
        approveStrategyPlan={formatDate(approveStrategyPlan)}
      />
      {profileValue !== 'DM' && (
        <>
          <Label>
            Selecione um Grupo de cliente e clique no botão para criar seu
            plano.
          </Label>
          <div className="creation--select">
            <ClientSelector
              clientGroupSelected={clientGroupSelected}
              setClientGroupSelected={setClientGroupSelected}
              handleCreatePlan={handleCreatePlan}
              onFocus={() => null}
              onBlur={() => null}
            />
            <HelpButton
              onClick={() => setShowHelp(!showHelp)}
              toggle={showHelp}
            >
              ?
            </HelpButton>
          </div>
        </>
      )}
      {!isDM && (
        <>
          <div className="creation--select">
            <Button
              type="button"
              buttonRole="primary"
              onClick={handleCreatePlan}
            >
              Criar Plano
            </Button>
            {/* <HelpButton
              onClick={() => setShowHelp(!showHelp)}
              toggle={showHelp}
            >
              ?
            </HelpButton> */}
          </div>
          <Head>
            <div>
              <strong>Meus planos estratégicos</strong>
              <p className="info-prazo">
                <FiAlertCircle />
                Prazo para criar e solicitar aprovação dos planos:{' '}
                {formatDate(createStrategyPlan)}
              </p>
            </div>
            <div className="filters-btn--wrapper">
              <Button
                buttonRole="primary"
                type="button"
                onClick={handleFilterDisplay}
              >
                Filtros [{showFilters ? '-' : '+'}]
              </Button>
            </div>
          </Head>
        </>
      )}
      <Filters
        changeFilterHandler={handleFilterChange}
        hideChannelFilter={isDM}
        hide={!showFilters}
      />
      {!isDM && (
        <Table
          className="table--plans"
          tableRole="secondary"
          isFetching={isLoading}
          noResultText="Nenhum plano criado para a safra selecionada."
          data={plans.map(plan => ({
            ...plan,
            className: statusClasses[plan.status],
          }))}
          headers={[
            {
              column: 'Cliente',
              dataValue: 'establishmentName',
              fn: (value: string) => <b title={value as string}>{value}</b>,
            },
            { column: 'Categoria', dataValue: 'category' },
            {
              column: 'Gestão e Desenvolvimento (R$)',
              dataValue: 'totalPoints',
              fn: (value: string) => fakeFormatDollars(Number(value || 0)),
            },
            {
              column: 'Budget (R$)',
              dataValue: 'totalBudget',
              fn: (value: string) => fakeFormatDollars(Number(value || 0)),
            },
            {
              column: 'IGD (R$)',
              dataValue: 'totalIGD',
              fn: (value: string) => fakeFormatDollars(Number(value || 0)),
            },
            {
              column: 'Status',
              dataValue: 'status',
              fn: (value: string) => <b>{getStatusText(Number(value))}</b>,
            },
            {
              column: 'Detalhes',
              dataValue: 'uuid',
              fn: (value: string) => (
                <Link
                  className="details-link"
                  to={`/planos-estrategicos/${value}`}
                >
                  Ver detalhes
                </Link>
              ),
            },
            {
              column: 'Aprovação',
              dataValue: 'uuid',
              fn: (uuid: string) => {
                const data = plans.find(plan => plan.uuid === uuid);
                if (!data) return '---';
                const alreadyApproved =
                  data.status === strategyPlanStatus.approved;
                const canRequestApprovation =
                  [
                    strategyPlanStatus.inAdjustments,
                    strategyPlanStatus.planning,
                  ].includes(data.status) &&
                  data.participantId === participant.id;
                if (alreadyApproved) {
                  return 'Aprovação concluída';
                }
                if (canRequestApprovation) {
                  return (
                    <Button
                      type="button"
                      buttonRole="tertiary"
                      loading={data.isLoading}
                      disabled={data.isLoading}
                      onClick={() => setAskApprovationConfirm(uuid as string)}
                    >
                      Solicitar aprovação
                    </Button>
                  );
                }
                if (data.askApprovations?.length) {
                  return (
                    <div>
                      <span>Aguardando aprovação:</span>
                      <br />
                      {getCurrentApprover(data)}
                    </div>
                  );
                }
                return `Aguardando aprovação`;
              },
            },
          ].filter(column =>
            isDM ? !['Categoria', 'Cliente'].includes(column.column) : column,
          )}
        />
      )}
      <Dialog open={askApprovationConfirm.length > 1}>
        <DialogTitle>Deseja solicitar a aprovação deste plano?</DialogTitle>
        <DialogActions>
          <MaterialButton onClick={() => setAskApprovationConfirm('')}>
            Cancelar
          </MaterialButton>
          <MaterialButton
            onClick={() => requestApprovation(askApprovationConfirm)}
          >
            Confirmar
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
