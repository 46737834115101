import {
  Strategy,
  Engagement,
  Bell,
  Ranking,
  Performance,
} from 'state/modules/home/types';
import { pluginApi } from 'services/api';
import { bells, ranking } from './mock';

export const getStrategies = async (): Promise<Strategy[]> => {
  const { data } = await pluginApi.get<Strategy[]>(
    '/participants/goals-summary/get-strategies',
  );
  return data;
};

export const getEngagements = async (): Promise<Engagement[]> => {
  const { data } = await pluginApi.get<Engagement[]>(
    '/participants/goals-summary/get-engagements',
  );
  return data;
};

export const getBells = async (): Promise<Bell[]> => {
  return bells; // substituir pelo endpoint
};

export const getRanking = async (): Promise<Ranking> => {
  return ranking; // substituir pelo endpoint
};

export const getPerformance = async (): Promise<Performance> => {
  const { data } = await pluginApi.get<Performance>('cockpit/management');
  return data;
};
