import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import deleteIcon from 'assets/images/campaigns/delete-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCampaign,
  getErrors,
} from 'state/modules/campaigns-manager/selectors';
import {
  addAudience,
  removeAudience,
} from 'state/modules/campaigns-manager/actions';

import { ApproverProfile, CRM, EstablishmentTypes } from 'config/constants';
import { Audience } from 'services/campaigns-manager/interfaces/Campaign';
import { formatPoints } from 'util/points';
import {
  Actions,
  Button,
  Container,
  CustomersSelect,
  SelectedGroups,
} from './styles';
import { AudienceHandles } from './select';

type Fn = () => Promise<void>;

enum ButtonSelectText {
  ResaleAdd,
  CoopAdd,
  RemoveAll,
}
interface Props {
  myProfile: ApproverProfile;
}

const Customers: React.FC<Props> = ({ myProfile }) => {
  const dispatch = useDispatch();
  const errors = useSelector(getErrors);
  const campaign = useSelector(getCampaign);
  const audienceRef = useRef<AudienceHandles>(null);

  const [loading, setLoading] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState<Audience | null>(
    null,
  );
  const [actionSelected, setActionSelected] = useState<ButtonSelectText | null>(
    null,
  );

  const handleButtonClick = useCallback(
    async (fn: Fn, actionName: ButtonSelectText) => {
      setLoading(true);
      setActionSelected(actionName);
      await fn();
      setLoading(false);
      setActionSelected(null);
    },

    [],
  );

  const addAllResales = useCallback(async (): Promise<void> => {
    const data = audienceRef.current?.options.filter(
      item => item.customer.type === EstablishmentTypes.Resale,
    );

    if (data) {
      dispatch(addAudience(data));
    }
  }, [dispatch]);

  const addAllCooperative = useCallback(async (): Promise<void> => {
    const data = audienceRef.current?.options.filter(
      item => item.customer.type === EstablishmentTypes.Cooperative,
    );

    if (data) {
      dispatch(addAudience(data));
    }
  }, [dispatch]);

  const removeAll = useCallback(async (): Promise<void> => {
    if (audienceRef.current?.options) {
      dispatch(removeAudience(audienceRef.current.options));
    }
  }, [dispatch]);

  useEffect(() => {
    if (audienceSelected) {
      dispatch(addAudience(audienceSelected));

      setTimeout(() => {
        setAudienceSelected(null);
      }, 1000);
    }
  }, [dispatch, audienceSelected]);

  return (
    <Container>
      <CustomersSelect
        setValue={value => setAudienceSelected(value)}
        value={audienceSelected}
        placeholder="Selecionar Clientes"
        error={errors.customer}
        ref={audienceRef}
      />
      {myProfile === CRM && (
        <Actions>
          <Button
            type="button"
            onClick={() =>
              handleButtonClick(addAllResales, ButtonSelectText.ResaleAdd)
            }
            disabled={loading}
            loading={loading && actionSelected === ButtonSelectText.ResaleAdd}
          >
            Todas as Revendas
          </Button>

          <Button
            type="button"
            onClick={() =>
              handleButtonClick(addAllCooperative, ButtonSelectText.CoopAdd)
            }
            disabled={loading}
            loading={loading && actionSelected === ButtonSelectText.CoopAdd}
          >
            Todas as Cooperativas
          </Button>

          <Button
            type="button"
            onClick={() =>
              handleButtonClick(removeAll, ButtonSelectText.RemoveAll)
            }
            disabled={loading}
            loading={loading && actionSelected === ButtonSelectText.RemoveAll}
          >
            Remover Todos
          </Button>
        </Actions>
      )}
      {campaign.audience?.map(item => (
        <SelectedGroups key={`audience${item.customer.id}`}>
          <h3>{item.customer.name}</h3>
          <h4>Saldo R$ {formatPoints(item.balance)}</h4>
          <ReactSVG
            src={deleteIcon}
            onClick={() => dispatch(removeAudience(item))}
          />
        </SelectedGroups>
      ))}
    </Container>
  );
};

export default Customers;
