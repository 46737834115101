import * as Yup from 'yup';
const validateEmptyText = (value: string) => {
  return value.trim() !== '';
};
const mandatoryMessage = 'Campo obrigatório';
const inValidTextMessage = 'Digite um texto válido';
const validators = {
  expected_date: Yup.string().required(mandatoryMessage),
  title: Yup.string()
    .required(mandatoryMessage)
    .test('valid-text', inValidTextMessage, validateEmptyText),
  description: Yup.string()
    .required(mandatoryMessage)
    .test('valid-text', inValidTextMessage, validateEmptyText),
  budget: Yup.string(),
  points: Yup.string(),
  igd: Yup.string(),
};
export default Yup.object().shape({
  ...validators,
  budget: Yup.string().test('at-least-one', 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!', function (value) {
    const { budget, points, igd } = this.parent;
    if (!budget && !points && !igd) {
      return this.createError({
        path: 'budget',
        message: 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!',
      });
    }
    return true;
  }),
  points: Yup.string().test('at-least-one', 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!', function (value) {
    const { budget, points, igd } = this.parent;
    if (!budget && !points && !igd) {
      return this.createError({
        path: 'points',
        message: 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!',
      });
    }
    return true;
  }),
  igd: Yup.string().test('at-least-one', 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!', function (value) {
    const { budget, points, igd } = this.parent;
    if (!budget && !points && !igd) {
      return this.createError({
        path: 'igd',
        message: 'Indique ao menos uma quantidade de pontos ou verba que deseja utilizar!',
      });
    }
    return true;
  }),
});