import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Obs = styled.p`
  font-size: 18px;
  color: #000000;
  margin: 0 0;
  padding-left: 2rem;
`;

export const Button = styled(DefaultButton)`
  margin-top: 15px;
  max-width: 285px;
  height: 44px;
  margin: 5px auto;
  margin-bottom: 36px;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 18px;
  margin: 1.5rem 2rem;

  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const ListContainer = styled.div`
  margin: 32px auto;
  color: #3b302a;
  width: 85%;
  background: #fff;
  table {
    width: 98%;
    border-collapse: collapse;
    font-size: 14px;
    margin: 15px auto;
  }

  thead {
    height: 45px;
  }
  th {
    padding: 3px 8px;
    font-weight: normal;
    text-align: left;
    font-weight: bold;
  }
  td {
    padding: 20px 8px;
  }
  tr:nth-child(odd) {
    td {
      background: #efefef;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-bottom: 10px;
    button {
      margin: 0;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
  }

  > span {
    margin-right: 10px;
    margin-bottom: 0.8rem;
  }
`;

export const CustomLink = styled.span`
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-decoration: underline;
  cursor: pointer;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  background-color: #f2f2f2;
`;

export const TableHeaderCell = styled.th`
  padding: 12px;
  font-weight: bold;
  text-align: left;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 12px;
`;

export const ContainerComponent = styled.section`
  background: #efefef;
  @media (min-width: 1200px) {
    width: 1000px;
    left: -16%;
    position: relative;
    gap: 0rem;
    display: flex;
    flex-direction: column;
  }

  .alert {
    margin: 2rem;

    .span-cpf {
      position: relative;
      top: 0.5rem;
    }
  }

  strong {
    color: #000;
    font-size: 18px;
    text-decoration: underline;
  }

  span {
    position: relative;
    top: 0.15rem;
  }
`;

export const ContentMobile = styled.div`
  color: #000;
  .card-items {
    background: #fff;
    margin: 0.5rem;
    padding: 0.5rem;
  }
  .card-header {
    display: flex;
  }
  h4;
  a {
    margin-bottom: 0.5rem;
  }
  .details {
    margin-left: 1.5rem;
  }
  span {
    font-weight: bold;
  }
`;
