import React from 'react';
import { MdSentimentDissatisfied } from 'react-icons/md';
import { Container } from './styles';

interface NoResultsProps {
  iconSize?: number;
  text?: string;
}

const NoResults: React.FC<NoResultsProps> = ({
  iconSize = 24,
  text = 'Sem Resultados',
}) => {
  return (
    <Container>
      <MdSentimentDissatisfied size={iconSize} />
      <h4>{text}</h4>
    </Container>
  );
};

export default NoResults;
