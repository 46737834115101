import { pluginApi } from 'services/api';

interface Response {
  download_url: string;
}

export default async (campaignId?: number): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/strategy-plans-report?campaign_id=${campaignId}`,
  );
  return data.download_url;
};
