import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-height: 450px;
  max-width: 750px;
  width: 100%;
  padding: 30px 32px 10px 32px;
  margin: 0;

  img {
    margin-bottom: 60px;
    margin-left: 220px;
    max-width: 200px;
  }
`;

export const StyledText = styled.h3`
  align-items: flex-start;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 14px;
  height: 100%;
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 35px;
  margin-top: 0;
  padding: 0;
`;

export const ColoredSpan = styled.span`
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 750px;
    max-height: 450px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
  }
  @media (max-width: 420px) {
    max-width: 320px;
    max-height: 300px;
  }
`;
export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translateX(-4px) translateY(4px);

  > button {
    border: none;
    background: transparent;
    svg path {
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;
