import React from 'react';
import './ConfirmationModal.css';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmAndCancelButton: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmAndCancelButton = true,
}) => {
  if (!isOpen) return null;


  return (
    <div className="confirmation-modal">
      <div>
        <h3>{title}</h3>
        <div className="desc">
          <p>
            {message}
          </p>
        </div>
        {confirmAndCancelButton ? (
          <>
            <button type="button" onClick={onConfirm}>
              Confirmar
            </button>
            <button type="button" onClick={onCancel}>
              Cancelar
            </button>
          </>
        ) : (
          <button type="button" onClick={onConfirm}>
            PREENCHER DADOS
          </button>
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal;
