import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition } from 'react-spring';
import { GrClose } from 'react-icons/gr';

import { dismissRouletteModal } from 'state/modules/home/actions';
import { getUserRoulettes } from 'state/modules/home/selectors';
import { useRoulette } from 'components/Roulette/hooks/use-roulette';
import { RouletteStatus } from 'components/Roulette/types';
import { Wheel } from '../Wheel';
import { Container, Overlay, SpinContainer } from './styles';

export const Modal: React.FC = () => {
  const rouletteStore = useSelector(getUserRoulettes);
  const dispatch = useDispatch();
  const { status } = useRoulette();

  const isOpen = rouletteStore?.isOpen || false;

  const transition = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 300, // ms
    },
  });

  const handleModalClose = () => {
    dispatch(dismissRouletteModal());
  };

  useEffect(() => {
    /** Desabilita o scroll da página enquanto o
     * modal da roleta estiver aberto */
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <Overlay key={key} style={props}>
              {status !== RouletteStatus.DRAWING && (
                <GrClose
                  onClick={handleModalClose}
                  color="#fff"
                  className="close-icon"
                  title="Fechar"
                />
              )}
              <Container>
                <SpinContainer>
                  <Wheel />
                </SpinContainer>
              </Container>
            </Overlay>
          ),
      )}
    </>
  );
};
