import { useAuth } from 'context/AuthContext';
import React from 'react';
import { List, Item } from './styles';

interface Point {
  name: string;
  total: string;
}

interface PerformancePotentialTableProps {
  potential: string;
  points: Point[];
  businessGeneration: string;
  generationDemand: string;
  biological: string;
  additionalMargin: string;
}
const PerformancePotentialTable: React.FC<PerformancePotentialTableProps> = ({
  potential,
  points,
  generationDemand,
  businessGeneration,
  biological,
  additionalMargin,
}) => {
  const { participant } = useAuth();
  const blockingAdditionalMargin =
    participant.establishment.classificacao.toUpperCase() ===
    'COOPERATIVA FECHADA';

  return (
    <List>
      <Item style={{ marginBottom: '25px' }}>
        <span>Potencial (US$)</span>
        <span>{potential}</span>
      </Item>
      {points.map((point: Point) => (
        <Item key={point.name}>
          <span>{`${point.name} (R$)`}</span>
          <span>{point.total}</span>
        </Item>
      ))}
      {generationDemand > '0.00' ? (
        <Item>
          <span>Gestão e Desenvolvimento (R$)</span>
          <span>{generationDemand}</span>
        </Item>
      ) : (
        <></>
      )}
      {businessGeneration > '0.00' ? (
        <Item>
          <span>Geração de Negócios (R$)</span>
          <span>{businessGeneration}</span>
        </Item>
      ) : (
        <></>
      )}
      {additionalMargin > '0.00' && !blockingAdditionalMargin ? (
        <Item>
          <span>Margem Adicional (R$)</span>
          <span>{additionalMargin}</span>
        </Item>
      ) : (
        <></>
      )}
      {biological > '0.00' ? (
        <Item>
          <span>Biológicos (R$)</span>
          <span>{biological}</span>
        </Item>
      ) : (
        <></>
      )}
    </List>
  );
};

export default PerformancePotentialTable;
