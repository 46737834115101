import React from 'react';

import { InvoiceDetails } from 'services/nf/interfaces';
import { DetailsSection, DetailsBlock } from '../styles';

interface Props {
  details: InvoiceDetails;
}

const labels = new Map<string, string>([
  ['canal-nao-participante', 'Canal não participante'],
  ['ilegivel', 'Ilegível'],
  ['nota-cortada', 'Nota Cortada'],
  ['nota-ja-cadastrada', 'Nota já cadastrada'],
  ['produto-nao-participante', 'Produto não participante'],
  ['fora-prazo-safra', 'Fora do prazo da Safra'],
  ['outros', 'Outros'],
]);

const Discredited: React.FC<Props> = ({ details }) => {
  const { reason, observation } = details;

  return (
    <DetailsSection fullWidth>
      <DetailsBlock>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Status: </strong>
              </td>
              <td>Descredenciada</td>
            </tr>
            <tr>
              <td>
                <strong>Motivo:</strong>
              </td>
              <td>{labels.get(reason)}</td>
            </tr>
            <tr>
              <td>
                <strong>Observação:</strong>
              </td>
              <td>{observation}</td>
            </tr>
          </tbody>
        </table>
      </DetailsBlock>
    </DetailsSection>
  );
};

export default Discredited;
