import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 98;
  top: 0;
  backdrop-filter: blur(8px);
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorAlert = styled.span`
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  text-align: center;
  color: #fff;
`;
