import { Badge } from './constants';
import { MenuItem } from './types';

export const getParticipantBadgeByPortugueseTerm = (
  term: 'Água' | 'Semente' | 'Terra' | 'Raiz' | 'Trader' | 'Fechada',
): Badge => {
  const portugueseTermsMap: { [key: string]: Badge } = {
    Água: Badge.Water,
    Semente: Badge.Seed,
    Terra: Badge.Land,
    Raiz: Badge.Root,
    Trader: Badge.Trader,
    Fechada: Badge.Closed,
  };

  return portugueseTermsMap[term];
};

export const getMenuByUrl = (
  url: string,
  menus: MenuItem[] | null,
): MenuItem | null => {
  if (!menus) return null;

  return (
    menus.find((menu: MenuItem) => {
      const hasChildren = menu.children && menu.children.length > 0;
      if (!hasChildren) return menu.to === url;

      return getMenuByUrl(url, menu.children);
    }) || null
  );
};

export const getAllowedRoutes = (menus: MenuItem[], mapped?: string[]) => {
  if (!menus || !menus.length) return null;

  let allowedRoutes = mapped || [];

  menus.forEach(menu => {
    const hasChildren = menu.children && menu.children.length > 0;

    if (hasChildren) {
      allowedRoutes =
        getAllowedRoutes(menu.children as MenuItem[], allowedRoutes) || [];
    }

    if (!allowedRoutes.find(path => path === menu.to) && menu.to)
      allowedRoutes.push(menu.to);
  });

  return allowedRoutes;
};

export const isSelectedMenu = (
  currentMenu: MenuItem,
  selectedMenu?: MenuItem | null,
): boolean => {
  if (!selectedMenu) return false;

  return selectedMenu.label === currentMenu.label;
};
