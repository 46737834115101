import React, { useCallback, useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { Statement, StatementPoints } from 'services/extract/interfaces';
import getDistributionReport from 'services/extract/getDistributionReport';
import { formatPointsExtract } from 'util/points';
import { groupPointsByUnitName } from './utils/groupPointsByUnitName';
import { orderGroupedPoints } from './utils/orderGroupedPoints';
import { Container, Header, Content } from './styles';

interface Props {
  extractStatement: Statement;
  title: 'Ano' | 'Safra';
  establishmentId: string;
}

interface GroupedPoints {
  statementId: number;
  title: string;
  total: number;
  total_resume: number;
  points: StatementPoints[];
  showDistributeButton: boolean;
  order?: number;
  className?: string;
}

const MYEXTRACT = '/myextract';

const AccordionItem: React.FC<Props> = ({
  extractStatement,
  title,
  establishmentId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [points, setPoints] = useState<GroupedPoints[]>([]);
  const { pathname } = useLocation();
  const isMyExtract = pathname === MYEXTRACT;

  const handleClick = () => setIsOpen(!isOpen);
  const exportClickHandler = useCallback(async () => {
    if (!extractStatement.campaign?.id) return;

    const url = await getDistributionReport(
      extractStatement.campaign.id,
      Number(establishmentId),
    );

    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.click();
    }
  }, [extractStatement, establishmentId]);

  useEffect(() => {
    if (!extractStatement) return;

    const groupedPoints = groupPointsByUnitName(extractStatement);
    const orderedPoints = orderGroupedPoints(groupedPoints);

    setPoints(orderedPoints);
  }, [extractStatement]);

  const statementHasDistributedPoints =
    (extractStatement?.points?.filter(
      statementPoint =>
        (statementPoint.distributed?.length || 0) > 0,
    ).length || 0) > 0 || false;

  if (extractStatement.campaign?.id) {
    return (
      <Container>
        <Header onClick={handleClick}>
          <div>
            {title}
            <strong>{extractStatement.campaign.title}</strong>
          </div>
          <div className="divider" />
          <div>
            <div className="text-right">Total</div>
            <strong>
              {formatPointsExtract(extractStatement.campaign.total)} pontos
            </strong>
          </div>
          <div className={`chevron ${isOpen ? 'open' : ''}`} />
        </Header>
        {isOpen && (
          <Content>
            {points.map(point => {
              // Filtrar e ordenar os pontos principais por data, da mais nova para a mais antiga
              const filteredPoints = point.points
                .filter(statementPoint => {
                const description = statementPoint.balanceUnit?.description;
                return description !== 'Gestão e Desenvolvimento';
              })
                .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

              return (
                <div className="content-row" key={point.statementId}>
                  {filteredPoints.length > 0 && (
                    <>
                      <div className="row-header">
                        <div className={point.className}>{point.title}</div>
                        <div className={point.className}>
                          {formatPointsExtract(point.total_resume)}
                        </div>
                      </div>
                      {filteredPoints.map(statementPoint => {
                        const hasDistributedPoints = !!statementPoint.distributed?.length;
                        const isNegative = statementPoint.value < 0;

                        // Ordenar valores distribuídos por data
                        const sortedDistributed = statementPoint.distributed
                          ? [...statementPoint.distributed].sort(
                              (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
                            )
                          : [];

                        return (
                        <div key={statementPoint.id}>
                          {/* Exibição dos valores distribuídos, ordenados por data */}
                          {hasDistributedPoints && (
                              <div className="distributed">
                                {sortedDistributed.map((distributedPoint, index) => (
                                  <div
                                    key={`${statementPoint.id}-${index}`}
                                    className="row-description distributed"
                                  >
                                    <span>
                                      {`${formatDate(distributedPoint.created, 'dd/MM/yyyy')} - `}
                                      Distribuição {distributedPoint.balanceUnitName}
                                    </span>
                                    <p>
                                      - {formatPointsExtract(distributedPoint.value)}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                          <div
                            className={`row-description ${
                              isNegative ? 'negative' : ''
                            }`}
                          >
                            <span>
                              {`${formatDate(statementPoint.created, 'dd/MM/yyyy')} - `}
                              {statementPoint.description}
                            </span>

                            <div className="value-to-distribute">
                              {point.showDistributeButton &&
                                !hasDistributedPoints &&
                                !isMyExtract && (
                                  <a
                                    href="/distribuicao-de-pontos"
                                    target="__blank"
                                  >
                                    distribuir
                                  </a>
                                )}
                              <p>{formatPointsExtract(statementPoint.value)}</p>
                            </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
          </Content>
        )}
        <br />
        {statementHasDistributedPoints && (
          <Header onClick={exportClickHandler}>
            <div>
              <strong>
                <FaDownload /> Detalhes da distribuição da premiação de
                vendedores
              </strong>
            </div>
          </Header>
        )}
      </Container>
    );
  }
  return <div />;
};

export default AccordionItem;
