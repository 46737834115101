import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 40px;
  padding: 10px;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 12px;
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.font.color.primary};
  text-transform: uppercase;
  margin-right: 15px;
  margin-top: 10px;

  svg {
    cursor: pointer;
    color: #e63027;
  }
`;
