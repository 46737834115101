import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import routeMap from 'routes/route-map';
import history from 'services/history';
import { PROFILES } from 'config/constants';

import { Button } from 'components/shared';
import { Container, Content } from './styles';

interface Props {
  completeRegister?: boolean;
  simulating?: boolean;
}

const ShowValidation: React.FC<Props> = ({ completeRegister }) => {
  const { participant, simulating } = useAuth();
  const [
    esocialValidationStatusToUpperCase,
    setEsocialValidationStatusToUpperCase,
  ] = useState('');

  const onClickHandler = useCallback(() => {
    history.push(routeMap.profile);
  }, []);

  useEffect(() => {
    setEsocialValidationStatusToUpperCase(
      participant.esocial_validation_status.toUpperCase(),
    );
  }, [participant.esocial_validation_status]);

  const checkProfile = participant.profile;

  const eSocialWaitingApproval =
    esocialValidationStatusToUpperCase === 'AGUARDANDO APROVAÇÃO';
  const eSocialPending = esocialValidationStatusToUpperCase === 'PENDENTE';
  const eSocialRejected = esocialValidationStatusToUpperCase === 'REJEITADO';
  const isParticipant = participant.profile === PROFILES.participant;
  const eSocialStatus = participant.esocial_valid === false;

  return (
    <Container>
      <Content>
        {isParticipant && eSocialStatus && <h3>Catálogo de prêmios </h3>}
        {!completeRegister && eSocialPending && checkProfile !== 'PRODUTOR' ? (
          <>
            {simulating ? (
              <span>
                Para resgatar seus pontos, é necessário completar o cadastro e
                aguardar a validação dos dados. <br /> Preencha agora e vamos
                juntos colher os resultados desta parceria.
              </span>
            ) : (
              <>
                <span>
                  Para resgatar seus pontos, é necessário completar todo o
                  cadastro e aguardar a validação dos dados. Clique no botão
                  abaixo, <br /> preencha todos os campos e vamos juntos colher
                  os resultados desta parceria.
                </span>
                <Button
                  type="submit"
                  buttonRole="primary"
                  onClick={onClickHandler}
                >
                  COMPLETAR CADASTRO
                </Button>
                <span>
                  Após o preenchimento de todos os campos, aguarde alguns dias
                  para análise dos seus dados.
                </span>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {eSocialWaitingApproval && (
          <span>
            Preenchimento realizado com sucesso, aguarde alguns dias enquanto
            fazemos a validação dos seus dados.
          </span>
        )}
        {eSocialRejected && (
          <>
            <span>
              Encontramos divergência nos dados informados no seu cadastro,
              enviamos um e-mail com os detalhes que estão incorretos. <br />{' '}
              Clique no botão abaixo, revise e corrija as informações e campos.
            </span>
            <Button type="submit" buttonRole="primary" onClick={onClickHandler}>
              CORRIGIR CADASTRO
            </Button>
            <span>
              Após a correção dos dados, aguarde alguns dias para análise.
            </span>
          </>
        )}
      </Content>
    </Container>
  );
};

export default ShowValidation;
