import { pluginApi } from 'services/api';

type Params = {
  establishmentId: number | any;
  campaignId: number;
};

export async function getPointsDistributionToApprove({
  establishmentId,
  campaignId,
}: Params): Promise<any> {
  const { data } = await pluginApi.get(
    `/points-distribution/points-distribution-to-approvals?establishment_id=${establishmentId}&campaign_id=${campaignId}`,
  );
  return data;
}
