/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import { formatPoints } from 'util/points';
import { Checkbox } from '@material-ui/core';
import { Button } from 'components/shared';
import { CommentModelTeam } from 'components/PointManagement/point-card/comment-model-team';
import { Container, CheckboxContainer } from './styles';

type Props = {
  point: any;
  selectedId: number;
  onSelect: (id: number) => void;
  pointsDistribution: any;
  getSketch: any;
  handleDistribution: any;
  selectedPointId: any;
};

export const TeamPoint: React.FC<Props> = ({
  point,
  selectedId,
  onSelect,
  pointsDistribution,
  getSketch,
  handleDistribution,
  selectedPointId,
}) => {
  const [isChecked, setChecked] = useState(false);
  const [isOpenComments, setOpenComments] = useState(false);

  useEffect(() => {
    setChecked(getSketch);
  }, [getSketch]);

  useEffect(() => {
    if (selectedId && point.undistributed_point_id) {
      setChecked(point.undistributed_point_id === selectedId && getSketch);
      onSelect(
        point.undistributed_point_id === selectedId
          ? point.undistributed_point_id
          : selectedId,
      );
    }
  }, [selectedId, point.undistributed_point_id]);

  const handleCheckboxClick = () => {
    const newCheckedState = !isChecked;
    setChecked(newCheckedState);
    onSelect(newCheckedState ? point.undistributed_point_id : 0);
  };

  return (
    <>
      <Container selected={isChecked}>
        <CheckboxContainer onClick={handleCheckboxClick}>
          <Checkbox onChange={() => {}} checked={isChecked} color="default" />
        </CheckboxContainer>
        <strong>{formatPoints(point.value)} pontos</strong>
        <span>{point.balance_unit_name}</span>

        <CommentModelTeam
          isOpen={isOpenComments}
          comments={point.comments}
          setOpenComments={setOpenComments}
        />

        {point.approval_status_name === 'Reprovado' && (
          <button
            type="button"
            className="details"
            onClick={() => setOpenComments(!isOpenComments)}
          >
            Comentários
          </button>
        )}
      </Container>

      <div className="points-action">
        <Button
          type="button"
          buttonRole="tertiary"
          onClick={handleDistribution}
          disabled={!isChecked}
        >
          Distribuir agora
        </Button>
      </div>
    </>
  );
};
