import styled from 'styled-components';
import {
  Button as DefaultButton,
  Input as DefaultInput,
} from 'components/shared';
import Modal from 'components/shared/Modal';
import DefaultUfSelect from '../../../Selects/UfsSelect';

export const StyledModal = styled(Modal)`
  ._modalContainer {
    width: 100%;
    padding: 20px 25px;
    max-width: 500px;

    .modal--header {
      color: ${({ theme }) => theme.font.color.primary};
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;

      &__title strong {
        font-size: 18px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      &__close {
        height: fit-content;

        svg {
          cursor: pointer;
          transition: transform 250ms ease-in;
          will-change: contents;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .modal--body {
      margin-top: 25px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;

      &__input-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        @media screen and (max-width: 430px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .modal--footer {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }
`;

export const Input = styled(DefaultInput)`
  max-width: 499px;

  ._inputContainer {
    height: 40px;
  }
`;

export const UfSelect = styled(DefaultUfSelect)`
  ._inputContainer {
    width: 100%;
    height: 40px;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 179px;
  height: 44px;
`;

export const FormField = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 14px;

  .MuiCheckbox-root {
    padding: 0;
  }
`;

export const Container = styled.div``;
