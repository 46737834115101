import React, { useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';

import { FormContext, useForm, Controller } from 'react-hook-form';
import { Alert, useSimpleToast } from 'components/shared';
import numbersOnly from 'util/numbersOnly';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import { MemberGroup, MemberType } from 'services/auth/interfaces/Participant';
import { addFarm, editFarm } from 'services/participants/fazendas';
import { getValidationSchema } from './validation';
import { StyledModal, Input, UfSelect, Button, FormField } from './styles';
import MemberTypeSelect from '../../../Selects/MemberTypeSelect';

type Props = {
  isOpen: boolean;
  initialData: MemberGroup | null;
  onClose?: () => void;
  isEditing: boolean;
};

type FormData = {
  regulation: boolean;
  cpf_cnpj: string;
  name: string;
  ie: string;
  group_name: string;
  city: string;
  member_type: { title: string; value: string } | null;
  uf: { title: string; value: string } | null;
};

export const FarmRegisterAndEdit: React.FC<Props> = ({
  isOpen,
  isEditing,
  onClose = () => null,
  initialData,
}) => {
  const { participant, refreshParticipant } = useAuth();
  const { addToast } = useToast();
 // const [check, setCheck] = useState<boolean>(false);
 // const [entro, setEntro] = useState<boolean>(true);
  const { showToast } = useSimpleToast();
  const formMethods = useForm<FormData>({
    mode: 'onSubmit',
    validationSchema: getValidationSchema(),
    defaultValues: {
      member_type: initialData?.type
        ? { value: initialData?.type, title: initialData?.type }
        : null,
      uf: initialData?.uf
        ? { value: initialData?.uf, title: initialData?.uf }
        : null,
      regulation: initialData?.farm_term_accepted || false,
      city: initialData?.city || '',
      cpf_cnpj: initialData?.cpf_cnpj || '',
      group_name: initialData?.group_name || '',
      name: initialData?.name || '',
      ie: initialData?.ie || '',
    },
  });
  const {
    handleSubmit: submit,
    control,
    errors,
    setError,
    formState: { isSubmitting },
    setValue,
    clearError,
  } = formMethods;

  useEffect(() => {
    if (initialData) {
      clearError([
        'member_type',
        'uf',
        'regulation',
        'city',
        'cpf_cnpj',
        'group_name',
        'name',
        'ie',
      ]);
      setTimeout(() => {
        setValue(
          'member_type',
          initialData?.type
            ? { value: initialData?.type, title: initialData?.type }
            : null,
        );
        setValue(
          'uf',
          initialData?.uf
            ? { value: initialData?.uf, title: initialData?.uf }
            : null,
        );
        setValue('regulation', true);
        setValue('city', initialData?.city || '');
        setValue('cpf_cnpj', initialData?.cpf_cnpj || '');
        setValue('group_name', participant.producer_group_name || '');
        setValue('name', initialData?.name || '');
        setValue('ie', initialData?.ie || '');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, isOpen]);

  const handleSubmit = submit(async data => {
    if (numbersOnly(data.cpf_cnpj) === numbersOnly(participant.cpf)) {
      addToast({ type: 'error', title: 'CPF já utilizado no cadastro!' });
      setError('cpf_cnpj', 'CPF já utilizado no cadastro!');
      return;
    }

    const newDate = new Date()
      .toLocaleDateString()
      .replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$3/$2/$1');

    if (isEditing) {
      try {
        const member = {
          id: initialData?.id,
          city: data.city,
          cpf_cnpj: data.cpf_cnpj,
          ie: data.ie,
          name: data.name,
          type: data.member_type?.value as MemberType,
          uf: data.uf?.value || '',
          created: newDate,
          farm_term_accepted: data.regulation,
          group_name: data.group_name,
        };

        const mensagecheck = await editFarm(member);

        if (mensagecheck.ret === "1") {
            showToast({
              message:  mensagecheck.msg,
              type: 'success',
            });
        }else{
          showToast({
            message: mensagecheck.msg,
            type: 'error',
          });
        }

        onClose();
        refreshParticipant();
      } catch (error) {
        addToast({
          title:
            (error as any).response?.data?.message ||
            'Falha ao editar a fazenda. Por favor entre em contato com o suporte',
          type: 'error',
        });
      }
      return;
    }

    if (participant.members_group !== undefined) {
      let registeredDocument = false;
      participant.members_group.forEach(item => {
        if (numbersOnly(data.cpf_cnpj) === numbersOnly(item.cpf_cnpj)) {
          addToast({ type: 'error', title: 'CPF ou CNPJ já cadastrados!' });
          setError('cpf_cnpj', 'CPF ou CNPJ já cadastrados!');
          registeredDocument = true;
        }
      });
      if (registeredDocument) {
        return;
      }
    }

    try {
      const member = {
        city: data.city,
        cpf_cnpj: data.cpf_cnpj,
        ie: data.ie,
        name: data.name,
        type: data.member_type?.value as MemberType,
        uf: data.uf?.value || '',
        created: newDate,
        farm_term_accepted: data.regulation,
        group_name: data.group_name,
      } as MemberGroup;

      const mensagecheck = await addFarm(member);

    //   setCheck(mensagecheck);
    if (mensagecheck.ret === "1") {
        showToast({
          message:  mensagecheck.msg,
          type: 'success',
        });
    }else{
      showToast({
        message: mensagecheck.msg,
        type: 'error',
      });
    }

      onClose();
      refreshParticipant();
    } catch (error) {
      addToast({
        title:
          (error as any).response?.data?.message ||
          'Falha ao editar a fazenda. Por favor entre em contato com o suporte',
        type: 'error',
      });
    }
  });

  // if (check && entro) {
  //   setEntro(false);
  //   addToast({ type: 'error', title: 'CPF ou CNPJ já cadastrados!' });
  //   setError('cpf_cnpj', 'CPF ou CNPJ já cadastrados!');
  // }

  return (
    <StyledModal isOpen={isOpen} onRequestClose={onClose}>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit}>
          <div className="modal--header">
            <div className="modal--header__title">
              <strong>{isEditing ? 'Editar' : 'Adicionar'} fazenda</strong>
            </div>
            <div className="modal--header__close">
              <IoMdClose size={18} title="Fechar" onClick={onClose} />
            </div>
          </div>
          <div className="modal--body">
            <div className="modal--body__input-group">
              <MemberTypeSelect inputRole="primary" label="Tipo*" />

              <Input
                name="cpf_cnpj"
                label="CPF ou CNPJ*"
                inputRole="primary"
                numbersOnly
              />
            </div>

            <Input
              label="Nome do grupo do produtor*"
              name="group_name"
              inputRole="primary"
            />

            <Input
              label="Nome ou Razão Social*"
              name="name"
              inputRole="primary"
            />

            <Input label="Inscrição Estadual" name="ie" inputRole="primary" />

            <div className="modal--body__input-group">
              <UfSelect label="Estado*" name="uf" inputRole="primary" />
              <Input label="Município*" name="city" inputRole="primary" />
            </div>

            <div style={{ display: !isEditing ? 'block' : 'none' }}>
              {errors.regulation?.message && (
                <div style={{ marginTop: '10px' }}>
                  <Alert variation="error">
                    Você precisa aceitar os termos para continuar
                  </Alert>
                </div>
              )}

              <FormField>
                <Controller as={Checkbox} name="regulation" control={control} />
                <span>
                  Estou ciente dos termos da lei de proteção de dados e me
                  responsabilizo pela informação de terceiros preenchidas nos
                  campos acima.
                </span>
              </FormField>
            </div>
          </div>
          <div className="modal--footer">
            <Button
              type="submit"
              buttonRole="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </div>
        </form>
      </FormContext>
    </StyledModal>
  );
};
