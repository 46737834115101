import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCampaigns,
  getCurrentCampaign,
  getSelectedCampaignId,
} from 'state/modules/goals/selectors';
import {
  fetchCampaigns,
  fetchCurrentCampaign,
  setSelectedCampaignId,
} from 'state/modules/goals/actions';
import { fetchCurrentCampaignTerraService as getCurrentCampaignTerra } from 'services/goals';
import getNameProducer, {
  ParticipantData,
} from 'services/producers-who-buy/getNameProducer';
import NoResults from 'components/shared/NoResults';
import { useAuth } from 'context/AuthContext';
import { CampaignsList, Container, ListContent } from './styles';

const formatNoteCount = (count: number): string => {
  return count.toString().padStart(3, '0');
};

const ListProducers: React.FC = () => {
  const { participant } = useAuth();
  const dispatch = useDispatch();

  const [producers, setProducers] = useState<ParticipantData[]>([]);
  const [producersList, setProducersList] = useState<ParticipantData[]>([]);
  const [terraCampaign, setTerraCampaign] = useState(0);

  const isTerraBrasil = participant.establishment.type_name === 'Terra Brasil';

  const [campaigns, selectedCampaignId, currentCampaign] = [
    useSelector(getCampaigns),
    useSelector(getSelectedCampaignId),
    useSelector(getCurrentCampaign),
  ];

  const filteredSafra = campaigns
    ? campaigns.filter(c => c.title !== '19/20' && !c.isTerraBrasil)
    : [];
  const campaignsTerra = campaigns
    ? campaigns.filter(c => c.title !== '2022' && c.isTerraBrasil)
    : [];

  const selectCampaign = useCallback(
    (id: number) => {
      dispatch(setSelectedCampaignId(id));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchCurrentCampaign());
    dispatch(fetchCampaigns());
  }, [dispatch]);

  useEffect(() => {
    const fetchCurrentCampaignTerra = async () => {
      const currentCampaignTerra = await getCurrentCampaignTerra();
      if (isTerraBrasil && currentCampaignTerra) {
        setTerraCampaign(currentCampaignTerra.id);
      }
    };

    fetchCurrentCampaignTerra();
  }, [isTerraBrasil]);

  const isSelectedCampaign = useCallback(
    (campaignId: number) => {
      if (isTerraBrasil && !selectedCampaignId) {
        return campaignId === terraCampaign;
      }
      if (!selectedCampaignId) {
        return campaignId === currentCampaign?.id;
      }
      const isSelectedCampaignId: boolean = campaignId === selectedCampaignId;
      return isSelectedCampaignId;
    },

    [isTerraBrasil, selectedCampaignId, terraCampaign, currentCampaign],
  );

  useEffect(() => {
    getNameProducer().then(response => setProducers(response));
  }, []);

  useEffect(() => {
    if (selectedCampaignId) {
      const filteredProducers = producers
      .filter(c => c.campaign_id === selectedCampaignId)
      .sort((a, b) => a.name.localeCompare(b.name));

      setProducersList(filteredProducers);
    }
  }, [producers, selectedCampaignId]);

  const toTitleCase = (producerName: string) => {
    return producerName
      .toLowerCase()
      .split(' ')
      .map(name => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  };

  return (
    <>
      <Container>
        <h2>
          Produtores que compram no seu estabelecimento e participam enviando
          notas no Juntos Produtor.
        </h2>

        <CampaignsList>
          {isTerraBrasil ? (
            <>
              {campaignsTerra.map(campaign => (
                <li key={campaign.title}>
                  <button
                    className={
                      isSelectedCampaign(campaign.id) ? 'selected' : ''
                    }
                    type="button"
                    onClick={() => selectCampaign(campaign.id)}
                  >
                    {`Ano ${campaign.title}`}
                  </button>
                </li>
              ))}
            </>
          ) : (
            <>
              {filteredSafra.map(campaign => (
                <li key={campaign.title}>
                  <button
                    className={
                      isSelectedCampaign(campaign.id) ? 'selected' : ''
                    }
                    type="button"
                    onClick={() => selectCampaign(campaign.id)}
                  >
                    {`Safra ${campaign.title}`}
                  </button>
                </li>
              ))}
            </>
          )}
        </CampaignsList>

        {producersList.length === 0 ? (
          <NoResults text="Nenhum produtor encontrado." />
        ) : (
          <ListContent>
            <thead className="contents-header">
              <tr>
                <th>Nome do produtor</th>
                <th>Quantidade de notas enviadas</th>
                <th>Total FMC Coins</th>
              </tr>
            </thead>
            <tbody>
              {producersList.map((producer, index, array) => (
                <tr
                  key={producer.id}
                  className={`contents-body ${
                    index === array.length - 1 ? 'last-row' : ''
                  }`}
                >
                  <td>{toTitleCase(producer.name)}</td>
                  <td className="count">
                    {formatNoteCount(producer.noteCount)}
                  </td>
                  <td>
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(Number(producer.totalValue))}
                  </td>
                </tr>
              ))}
            </tbody>
          </ListContent>
        )}
      </Container>
    </>
  );
};

export default ListProducers;
