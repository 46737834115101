import React, { useState } from 'react';
import { Button } from 'components/shared';
import { Modal, Title, Close, Container } from './style';

interface ChooseResetPasswordProps {
  isOpen: boolean;
  cpf: string;
  onRequestClose: () => void;
  onChooseEmail: () => void;
  onChooseSms: () => void;
  obfuscatedEmail: string;
  obfuscatedCellPhone: string;
}

const ChooseResetPassword: React.FC<ChooseResetPasswordProps> = ({
  isOpen,
  onRequestClose,
  onChooseEmail,
  onChooseSms,
  obfuscatedEmail,
  obfuscatedCellPhone,
}) => {
  const [loading, setLoading] = useState(false); // Controla o estado de carregamento

  const handleChooseEmail = async () => {
    setLoading(true);
    try {
      await onChooseEmail();
    } finally {
      setLoading(false);
    }
  };

  const handleChooseSms = async () => {
      setLoading(true);
    try {
      await onChooseSms();
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Escolha o método de recebimento do token"
    >
      <Close>
        <button onClick={onRequestClose}>✕</button>
      </Close>
      <Container>
        <Title>Escolha como deseja recuperar a senha</Title>
        <div>
          <>
            <Button
              onClick={handleChooseEmail}
              buttonRole="primary"
              type="button"
              disabled={loading}
              style={{ textTransform: 'uppercase' }}
            >
              Receber em {obfuscatedEmail}
            </Button>
            <Button
              onClick={handleChooseSms}
              buttonRole="secondary"
              type="button"
              style={{ textTransform: 'uppercase' }}
            >
              Receber sms em {obfuscatedCellPhone}
            </Button>
          </>
        </div>
      </Container>
    </Modal>
  );
};

export default ChooseResetPassword;
