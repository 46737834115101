import { pluginApi } from '../api';

export interface Response {
  cpf: string;
  data: Response;
  checkResponse: boolean;
}

export async function checkPendingLead() {
  try {
    const { data } = await pluginApi.get<Response>('/leads/checkPendingLead');

    if (data && data.data && data.data.cpf) {
      return data.data;
    }
    return null;
  } catch {
    return null;
  }
}
