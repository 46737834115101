import { GroupedPoints } from './groupPointsByUnitName';

const pointsOrder = [
  'rebate',
  'premiação de vendedores',
  'ponto extra',
  'ações de desenvolvimento',
];

/**
 * Ordena os pontos com base no nome
 * @param groupedPoints Pontos ordenados por nome
 */
export function orderGroupedPoints(
  groupedPoints: GroupedPoints[],
): GroupedPoints[] {
  const orderedPoints =
    groupedPoints
      .map(item => {
        let order = pointsOrder.indexOf(item.title.toLowerCase());
        const className =
          item.title.toLowerCase() === 'ações de desenvolvimento'
            ? 'special'
            : '';
        if (order === -1) {
          order = 999;
        }

        return { ...item, order, className };
      })
      .sort((item1, item2) => (item1.order > item2.order ? 1 : -1)) || [];

  return orderedPoints;
}
