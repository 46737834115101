import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { fetchCampaignsService } from 'services/goals';
import { Campaign } from 'state/modules/goals/types';

import { ProductType } from 'pages/Dashboard';
import { List, ListWrapper } from './styles';

export type CampaignsListHandle = {
  changeSelectedCampaignToFirst: () => void;
};

interface CampaignsListProps {
  selectedCampaignId?: number;
  selectCampaign: (campaignId: number) => void;
  campaignType: ProductType;
}

const CampaignsList = forwardRef<CampaignsListHandle, CampaignsListProps>(
  ({ selectedCampaignId, selectCampaign, campaignType }, ref) => {
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const idSafra1920 = 2;

    const filteredCampaigns = campaigns
      .filter(c =>
        campaignType === 'terrabrasil' ? c.isTerraBrasil : !c.isTerraBrasil,
      )
      .filter(c => c.id !== idSafra1920);

    filteredCampaigns.reverse();

    useImperativeHandle(ref, () => ({
      changeSelectedCampaignToFirst() {
        if (filteredCampaigns.length)
          selectCampaign(filteredCampaigns[filteredCampaigns.length - 1].id);
      },
    }));

    useEffect(() => {
      const fetchCampaigns = async () => {
        const data = await fetchCampaignsService();
        setCampaigns(data || []);
      };

      fetchCampaigns();
    }, []);

    // Só para prod
    if(campaignType === 'terrabrasil'){
      selectedCampaignId = selectedCampaignId === 0 ? 10 : selectedCampaignId;
    }else{
      selectedCampaignId = selectedCampaignId === 0 ? 11 : selectedCampaignId;
    }

    return (
      <ListWrapper>
        {filteredCampaigns.map(campaign => (
          <List
            isSelected={selectedCampaignId === campaign.id}
            key={campaign.id}
            type="button"
            onClick={() => selectCampaign(campaign.id)}
          >
            {campaign.isTerraBrasil
              ? `Ano ${campaign.title}`
              : `Safra ${campaign.title}`}
          </List>
        ))}
      </ListWrapper>
    );
  },
);

export default CampaignsList;
