import { pluginApi } from 'services/api';

type Params = {
  establishmentId: number;
  campaignId: number;
};

export async function getPointsAlreadyDistributed({
  establishmentId,
  campaignId,
}: Params) {
  const { data } = await pluginApi.get(
    `points-distribution/points-already-distribution?establishment_id=${establishmentId}&campaign_id=${campaignId}`,
  );
  return data;
}
