import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import DefaultButton from 'components/shared/Button';
import { ReactSVG } from 'react-svg';

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const Container = styled.div`
  color: #2c2b2b;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: left;

  span {
    font-size: 14px;
    margin-top: 30px;
  }
`;
export const Title = styled.h1`
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  margin: 55px 0 35px 10px;
  text-align: left;
`;
export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 750px;
    width: 100%;
    min-height: 250px;
    max-height: 100vh !important;
    overflow-y: unset;
    padding: 0 30px;
  }
`;
export const Button = styled(DefaultButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 161px;
  height: 45px;
  background-color: ${({ theme }) => theme.font.color.primary};
  border-radius: 5px;
  text-transform: uppercase;
  margin: 25px 0 30px 0;
`;

export const Content = styled.div`
  text-align: left;
  margin: 0 0 20px 10px;
  display: flex;
  align-items: center;
`;

export const Icon = styled(ReactSVG)`
  margin-right: 8px; /* Espaçamento entre o ícone e o texto */
`;

export const BoldText = styled.p`
  color: ${({ theme }) => theme.font.color.primary};
  font-weight: bold;
  white-space: pre-wrap;
`;
