import styled, { css } from 'styled-components';
import { opacify } from 'polished';
import DefaultCustomerSelect from './select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomersSelect = styled(DefaultCustomerSelect)`
  ._inputContainer {
    width: 100%;
    max-width: 435px;
    height: 44px;
  }
  margin-bottom: 7px;
`;
export const SelectedGroups = styled.div`
  width: 100%;
  max-width: 586px;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.font.color.quartenary};
  display: flex;
  align-items: center;
  padding: 0 16px;

  svg {
    cursor: pointer;
  }

  > h3 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.quartenary};
  }

  > h4 {
    flex: 1;
    text-align: right;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.font.color.primary};
    padding-right: 30px;
    margin-top: 0 !important;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`;

interface ButtonProps {
  loading: boolean;
}

export const Button = styled.button<ButtonProps>`
  text-transform: uppercase;
  color: ${({ theme }) => theme.font.color.primary};
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 10px;
  height: 32px;
  border-radius: 7px;
  max-width: 150px;
  padding: 0 18px;
  border: 1px dashed ${({ theme }) => opacify(0.5, theme.font.color.primary)};
  position: relative;

  background: transparent;
  & + button {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;

    ${({ loading }) =>
      loading &&
      css`
        &:after {
          content: 'Aguarde...';
          position: absolute;
          left: 50%;
          top: 32px;
          transform: translateX(-50%);
          font-size: 11px;
          opacity: 0.7;
        }
      `}
  }
`;
