import { pluginApi } from 'services/api';

interface Response {
  download_url: string;
}

export const getParticipantIndicationsReport = async (
  establishmentId: number,
): Promise<string> => {
  const { data } = await pluginApi.get<Response>(
    `/reports/indication-participant?establishment_id=${establishmentId}`,
  );
  return data.download_url || '';
};
