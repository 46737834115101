/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import checkIcon from 'assets/images/goals/goals-check-icon.svg';
import uncheckIcon from 'assets/images/goals/goals-times-icon.svg';
import {
  Performance as IPerformance,
  Statistics as IStatistics,
} from 'services/cockpit/interfaces/channel';

import useDimensions from 'hooks/use-window-dimensions';
import { pluginApi } from 'services/api';
import RealizedProgress from 'components/Goals/RealizedProgress';
import { formatPoints } from 'util/points';
import { useAuth } from 'context/AuthContext';
import CircularPerformance from './CircularPerformance';
import { CircularSectionItem } from './CircularPerformance/styles';

import {
  Content,
  Item,
  Icons,
  TableStyle,
  TableRow,
  TableHeader,
  TableCell,
  ProgressTitleV,
  WrapperValues,
  Goals,
  Details,
  Title,
  Row,
  GoalText,
  LastUpdate
} from './styles';

interface Props {
  performance: IPerformance;
  statistics: IStatistics;
  getSegments: any;
}

const Performance: React.FC<Props> = ({
  performance,
  statistics,
  getSegments,
}) => {
  const {
    revenues,
    pog,
    // pog_volume,
    additionalMargin,
    focusProduct,
    last_update: lastUpdate,
    last_update_invoicing: lastUpdateInvoicing,
    last_update_pog: lastUpdatePog,
  } = performance;
  const { establishment } = statistics;
  const [products, setProducts] = useState<any>([]);

  const orderProducts = focusProduct?.sort((a, b) => a.order - b.order);

  const dinamicTitle =
    establishment.type === 'Terra Brasil' ? 'POG Fornecedor' : 'POG';

  const hasPunctuality = performance.punctuality === 'Sim';
  const { participant } = useAuth();

  const { width } = useDimensions();
  const isWide = width > 768;

  useEffect(() => {
    (async () => {
      try {
        const { data } = await pluginApi(`/goals/product-segment`);
        const orderedData: any = {};
        [
          'Herbicidas',
          'Inseticidas',
          'Inseticidas by Rynaxypyr',
          'Fungicidas',
        ].forEach(key => {
          if (data[key]) {
            orderedData[key] = data[key];
          }
        });
        setProducts(orderedData);
      } catch (error) {
        console.error('Erro ao obter os dados:', error);
      }
    })();
  }, []);

  const result = orderProducts.find(
    obj => obj.name.toLowerCase() === 'fungicidas',
  );

  return (
    <>
      <h3 style={{ marginTop: '2rem' }}>Performance</h3>
      <WrapperValues>
        <Goals>
          <Item>
            <Title>Faturamento</Title>
            <CircularPerformance
              color="#FF4C16"
              percent={parseInt(revenues.formattedPercentage, 0)}
            />
            <Details>
              <span>Objetivo: US$ {revenues.formattedGoal}</span>
              <span>Realizado: US$ {revenues.formattedResult}</span>
            </Details>
          </Item>
          <Item>
            <Title>{dinamicTitle}</Title>
            <CircularPerformance
              color="#25CCE1"
              percent={parseInt(pog.formattedPercentage, 0)}
            />
            <Details>
              <span>Objetivo: US$ {pog.formattedGoal}</span>
              <span>Realizado: US$ {pog.formattedResult}</span>
            </Details>
          </Item>

          {additionalMargin && additionalMargin.goal > 0 && (
            <Item>
              <Title>MARGEM ADICIONAL</Title>
              <GoalText>
                (Premio ou Premio Star 3,33:1)
              </GoalText>
              <CircularPerformance
                color="#47C246"
                percent={additionalMargin.percentage}
              />
              <Details>
                <span>Objetivo: {additionalMargin.formattedGoal} L</span>
                <span>Realizado: {additionalMargin.formattedResult} L</span>
              </Details>
            </Item>
          )}

          {/* {establishment.type === 'Terra Brasil' && (
            <>
              <Icons>
                <Title>Pontualidade</Title>
                {hasPunctuality ? (
                  <img
                    src={checkIcon}
                    className="checkIcon"
                    alt="Ícone check pontualidade"
                  />
                ) : (
                  <img
                    src={uncheckIcon}
                    className="uncheckicon"
                    alt="Ícone x pontualidade"
                  />
                )}
              </Icons>
            </>
          )} */}
        </Goals>

        {!!orderProducts && orderProducts && result ? (
          <WrapperValues>
            <Item key={orderProducts[0]?.name}>
              <>
                <ProgressTitleV color="#3ba13b" isWide={isWide}>
                  <strong>{orderProducts[0]?.name}</strong>
                </ProgressTitleV>
              </>
              <Content>
                <CircularSectionItem>
                  <CircularPerformance
                    color="#3ba13b"
                    percent={parseInt(orderProducts[0]?.formattedPercentage, 0)}
                  >
                    <RealizedProgress
                      percent={orderProducts[0]?.percentage}
                      realized={`US$: ${formatPoints(
                        orderProducts[0]?.result,
                      )}`}
                    />
                  </CircularPerformance>
                  <Details>
                    <span>Objetivo: US$ {orderProducts[0].formattedGoal}</span>
                    <span>
                      Realizado: US$ {orderProducts[0].formattedResult}
                    </span>
                  </Details>
                </CircularSectionItem>
                <TableStyle>
                  <table className="table-1">
                    {getSegments &&
                      getSegments.Herbicidas &&
                      getSegments.Herbicidas.map((product: any) => (
                        <tbody key={product.id}>
                          <TableRow>
                            <TableHeader>
                              {product.name}
                              <span>®</span>
                            </TableHeader>
                            <TableCell>
                              Objetivo: <br /> US${' '}
                              {formatPoints(product.meta, 0, 0)}
                            </TableCell>
                            <TableCell>
                              Realizado <br /> US${' '}
                              {formatPoints(product.result, 0, 0 || 0)}
                            </TableCell>
                          </TableRow>
                        </tbody>
                      ))}
                  </table>
                </TableStyle>
              </Content>
            </Item>

            <Item key={orderProducts[1]?.name}>
              <ProgressTitleV color="#913944" isWide={isWide}>
                <strong>{orderProducts[1]?.name}</strong>
              </ProgressTitleV>

              <Content>
                <CircularSectionItem>
                  <CircularPerformance
                    color="#913944"
                    percent={parseInt(orderProducts[1]?.formattedPercentage, 0)}
                  >
                    <RealizedProgress
                      percent={orderProducts[1]?.percentage}
                      realized={`US$: ${formatPoints(
                        orderProducts[1]?.result,
                      )}`}
                    />
                  </CircularPerformance>
                  <Details>
                    <span>Objetivo: US$ {orderProducts[1].formattedGoal}</span>
                    <span>
                      Realizado: US$ {orderProducts[1].formattedResult}
                    </span>
                  </Details>
                </CircularSectionItem>

                <TableStyle>
                  <table className="table-2">
                    {getSegments &&
                      getSegments.Inseticidas &&
                      getSegments.Inseticidas.map((product: any) => (
                        <tbody key={product.id}>
                          <TableRow>
                            <TableHeader>
                              {product.name}
                              <span>®</span>
                            </TableHeader>
                            <TableCell>
                              Objetivo: <br /> US${' '}
                              {formatPoints(product.meta, 0, 0)}
                            </TableCell>
                            <TableCell>
                              Realizado <br /> US${' '}
                              {formatPoints(product.result, 0, 0 || 0)}
                            </TableCell>
                          </TableRow>
                        </tbody>
                      ))}
                  </table>
                </TableStyle>
              </Content>
            </Item>

            <Item key={orderProducts[2]?.name}>
              <ProgressTitleV color="#838bc5" isWide={isWide}>
                <strong>{orderProducts[2]?.name}</strong>
              </ProgressTitleV>

              <Content>
                <CircularSectionItem>
                  <CircularPerformance
                    color="#838bc5"
                    percent={parseInt(orderProducts[2]?.formattedPercentage, 0)}
                  >
                    <RealizedProgress
                      percent={orderProducts[2]?.percentage}
                      realized={`US$: ${formatPoints(
                        orderProducts[2]?.result,
                      )}`}
                    />
                  </CircularPerformance>
                  <Details>
                    <span>Objetivo: US$ {orderProducts[2].formattedGoal}</span>
                    <span>
                      Realizado: US$ {orderProducts[2].formattedResult}
                    </span>
                  </Details>
                </CircularSectionItem>

                <TableStyle>
                  <table className="table-3">
                    {getSegments &&
                      getSegments['Inseticidas by Rynaxypyr'] &&
                      getSegments['Inseticidas by Rynaxypyr'].map(
                        (product: any) => (
                          <tbody key={product.id}>
                            <TableRow>
                              <TableHeader>
                                {product.name}
                                <span>®</span>
                              </TableHeader>
                              <TableCell>
                                Objetivo: <br /> US${' '}
                                {formatPoints(product.meta, 0, 0)}
                              </TableCell>
                              <TableCell>
                                Realizado <br /> US${' '}
                                {formatPoints(product.result, 0, 0 || 0)}
                              </TableCell>
                            </TableRow>
                          </tbody>
                        ),
                      )}
                  </table>
                </TableStyle>
              </Content>
            </Item>
            <Item key={orderProducts[3]?.name}>
              <ProgressTitleV color="#c8ed06" isWide={isWide}>
                <strong className="last-segment">
                  {orderProducts[3]?.name}
                </strong>
              </ProgressTitleV>

              <Content>
                <CircularSectionItem>
                  <CircularPerformance
                    color="#c8ed06"
                    percent={parseInt(orderProducts[3]?.formattedPercentage, 0)}
                  >
                    <RealizedProgress
                      percent={orderProducts[3]?.percentage}
                      realized={`US$: ${formatPoints(
                        orderProducts[3]?.result,
                      )}`}
                    />
                  </CircularPerformance>
                  <Details>
                    <span>Objetivo: US$ {orderProducts[3].formattedGoal}</span>
                    <span>
                      Realizado: US$ {orderProducts[3].formattedResult}
                    </span>
                  </Details>
                </CircularSectionItem>
                <TableStyle>
                  <table className="table-4">
                    {getSegments &&
                      getSegments.Fungicidas &&
                      getSegments.Fungicidas.map((product: any) => (
                        <tbody key={product.id}>
                          <TableRow>
                            <TableHeader>
                              {product.name}
                              <span>®</span>
                            </TableHeader>
                            <TableCell>
                              Objetivo: <br /> US${' '}
                              {formatPoints(product.meta, 0, 0)}
                            </TableCell>
                            <TableCell>
                              Realizado <br /> US${' '}
                              {formatPoints(product.result, 0, 0 || 0)}
                            </TableCell>
                          </TableRow>
                        </tbody>
                      ))}
                  </table>
                </TableStyle>
              </Content>
            </Item>
          </WrapperValues>
        ) : (
          <Row>
            {focusProduct.map(item => (
              <Item key={`prod-${item.name}`}>
                <Title>
                  {item.name} <span>®</span>
                </Title>
                <CircularPerformance
                  color={item.color}
                  percent={parseInt(item.formattedPercentage, 0)}
                />
                <Details>
                  <span>Objetivo: {item.formattedGoal} L</span>
                  <span>Realizado: {item.formattedResult} L</span>
                </Details>
              </Item>
            ))}
          </Row>
        )}
        {lastUpdate && (
          <LastUpdate>
            {/* <p>Última atualização: {lastUpdate} </p> */}
            <p>Última atualização Faturamento: {lastUpdateInvoicing}</p>
            <p>Última atualização Pog: {lastUpdatePog}</p>
          </LastUpdate>
        )}
      </WrapperValues>
    </>
  );
};

export default Performance;
