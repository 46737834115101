/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Option } from 'components/shared/Select';
import DirectorsSelect from 'components/Cockpit/Selects/Directors';
import RegionalSelect from 'components/Cockpit/Selects/Regional';
import HarvestsSelect from 'components/Cockpit/Selects/Harvest';
import HarvestsInvestmentGroupSelect from 'components/Cockpit/Selects/InvestmentGroupSelect/Harvest';
import TypeSelect from 'components/shared/Vendavall/Establishments/TypeSelect';
import CategorySelect from 'components/shared/Vendavall/Establishments/CategorySelect';
import ChannelsSelect from 'components/Cockpit/Selects/Channels';
import getRegionals from 'services/cockpit/getRegional';
import getDirectors from 'services/cockpit/getDirectors';
import { Campaign } from 'services/cockpit/interfaces/campaign';
import getCampaigns from 'services/cockpit/getCampaign';
import InvestmentGrup from 'components/Cockpit/Selects/InvestmentGroupSelect';
import { EstablishmentCategory, EstablishmentTypes } from 'config/constants';
import { Channel, InvestmentGroup } from 'services/cockpit/interfaces/channel';
import { Container, Fields } from './styles';
import { FilterArgs } from '..';

interface Props {
  onFilter(params: FilterArgs): Promise<void>;
  clear(): void;
  NewMenu: boolean;
  investmentGroupCampaign?: InvestmentGroup[];
  hasTerraChannels?: Channel[];
  hasIndirectChannels?: Channel[];
}

const FiltersComponent: React.FC<Props> = ({
  onFilter,
  clear,
  NewMenu,
  investmentGroupCampaign,
  hasTerraChannels,
  hasIndirectChannels,
}) => {
  const [directorSelected, setDirectorSelected] = useState<Option | null>(null);
  const [regionalSelected, setRegionalSelected] = useState<Option | null>(null);
  const [typeSelected, setTypeSelected] = useState<Option | null>(null);
  const [categorySelected, setCategorySelected] = useState<Option | null>(null);
  const [channelSelected, setChannelSelected] = useState<Option | null>(null);
  const [opened, setOpened] = useState(false);
  const [showRegionalSelect, setShowRegionalSelect] = useState(true);
  const [showDirectorSelect, setShowDirectorSelect] = useState(true);
  const [harvestSelected, setHarvestSelected] = useState<Option | null>(null);
  const [investmentGroup, setInvestmentGroup] = useState<Option | null>(null);
  const [investmentGroupCampaignId, setInvestmentGroupCampaignId] = useState<
    number[]
  >([]);
  const [investmentGroupName, setInvestmentGroupName] = useState<string[]>([]);
  const [terraCampaign, setTerraCampaigns] = useState<Campaign[]>([]);
  const [juntosCampaign, setJuntosCampaigns] = useState<Campaign[]>([]);
  const isTerra = hasTerraChannels && hasTerraChannels.length > 0;
  const isIndirect = hasIndirectChannels && hasIndirectChannels.length > 0;

  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await getCampaigns();
      const campaignTerra2022 = '2022';

      const isTerraBrasil = data.filter(
        c => c.isTerraBrasil && c.label !== campaignTerra2022,
      );

      const isIndirects = data.filter(c => !c.isTerraBrasil);
      setJuntosCampaigns(isIndirects);
      setTerraCampaigns(isTerraBrasil);
    };
    fetchCampaign();
  }, []);

  useEffect(() => {
    getRegionals().then(data => {
      setShowRegionalSelect(data.length > 1);
      if (data.length === 1) {
        setRegionalSelected({
          value: data[0].id.toString(),
          title: data[0].name,
        });
      }
    });

    getDirectors().then(data => {
      setShowDirectorSelect(data.length > 1);
      if (data.length === 1) {
        setDirectorSelected({
          value: data[0].id,
          title: data[0].name,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (investmentGroup && harvestSelected) {
      onFilter({
        campaignId: parseInt(String(harvestSelected?.value || 0)),
        investmentGroups: investmentGroup?.title,
      });
      return;
    }

    if (channelSelected && harvestSelected) {
      onFilter({
        campaignId: parseInt(String(harvestSelected?.value || '0')),
        establishmentId: parseInt(String(channelSelected?.value || '0')),
      });
      return;
    }
    clear();
  }, [
    onFilter,
    clear,
    channelSelected,
    harvestSelected,
    terraCampaign,
    investmentGroup,
  ]);

  useEffect(() => {
    if (!investmentGroupCampaign) return;
    const getInvestmentCampaignId = investmentGroupCampaign.map(
      (campaign: { campaignsId: number }) => campaign.campaignsId,
    );
    setInvestmentGroupCampaignId(getInvestmentCampaignId);
    if (harvestSelected) {
      const filteredInvestmentGroup = investmentGroupCampaign.filter(
        campaign => campaign.campaignsId === parseInt(harvestSelected.value),
      );
      const getInvestmentGroupName = filteredInvestmentGroup.map(
        i => i.investmentGroup,
      );
      setInvestmentGroupName(getInvestmentGroupName);
    }
  }, [harvestSelected, investmentGroupCampaign]);

  return (
    <Container>
      <button type="button" onClick={() => setOpened(status => !status)}>
        {opened ? '-' : '+'} Filtrar
      </button>
      {NewMenu ? (
        <>
          <Fields opened={opened}>
            <TypeSelect
              setValue={value => setTypeSelected(value)}
              value={typeSelected}
              placeholder="Tipo"
              isTerraBrasil={isTerra}
              isIndirect={isIndirect}
            />
            {(!isIndirect && hasTerraChannels && hasTerraChannels.length > 0) ||
            typeSelected?.value === 'Terra Brasil' ? (
              <HarvestsSelect
                setValue={value => setHarvestSelected(value)}
                value={harvestSelected}
                placeholder="Ano"
                options={terraCampaign.map(c => ({
                  title: c.label,
                  value: String(c.id),
                  Grupo: String(c.isTerraBrasil),
                }))}
              />
            ) : (
              <HarvestsSelect
                setValue={value => setHarvestSelected(value)}
                value={harvestSelected}
                placeholder="Safra"
                options={juntosCampaign.map(c => ({
                  title: c.label,
                  value: String(c.id),
                  Grupo: String(c.isTerraBrasil),
                }))}
              />
            )}
            {showDirectorSelect ? (
              <DirectorsSelect
                setValue={value => setDirectorSelected(value)}
                value={directorSelected}
                placeholder="Diretoria"
                hasIndirectChannels={hasIndirectChannels}
                directorName={directorSelected?.title || ''}
                categoryName={categorySelected?.title as EstablishmentCategory}
                typeName={typeSelected?.title as EstablishmentTypes}
                regionalName={regionalSelected?.title}
              />
            ) : (
              <span>Diretoria: {directorSelected?.title}</span>
            )}
            {showRegionalSelect ? (
              <RegionalSelect
                key={`regional-${directorSelected?.title || ''}`}
                setValue={value => setRegionalSelected(value)}
                value={regionalSelected}
                placeholder="Regional"
                directorName={directorSelected?.title || ''}
                categoryName={categorySelected?.title as EstablishmentCategory}
                typeName={typeSelected?.title as EstablishmentTypes}
                regionalName={regionalSelected?.title}
              />
            ) : (
              <span>Regional: {regionalSelected?.title}</span>
            )}

            <CategorySelect
              setValue={value => setCategorySelected(value)}
              value={categorySelected}
              placeholder="Categoria"
              directorName={directorSelected?.title || ''}
              categoryName={categorySelected?.title as EstablishmentCategory}
              typeName={typeSelected?.title as EstablishmentTypes}
              regionalName={regionalSelected?.title}
            />
            <ChannelsSelect
              setValue={value => setChannelSelected(value)}
              value={channelSelected}
              placeholder="Canal"
              directorName={directorSelected?.title}
              categoryName={categorySelected?.title as EstablishmentCategory}
              typeName={typeSelected?.title as EstablishmentTypes}
              regionalName={regionalSelected?.title}
            />
          </Fields>
        </>
      ) : (
        <>
          <Fields opened={opened}>
            <HarvestsInvestmentGroupSelect
              setValue={value => setHarvestSelected(value)}
              value={harvestSelected}
              placeholder="Safra"
              investmentGroupCampaign={investmentGroupCampaignId}
            />
            <InvestmentGrup
              setValue={value => setInvestmentGroup(value)}
              value={investmentGroup}
              placeholder="Grupo de investimento"
              directorName={directorSelected?.title}
              categoryName={categorySelected?.title as EstablishmentCategory}
              typeName={typeSelected?.title as EstablishmentTypes}
              regionalName={regionalSelected?.title}
              investmentGroupCampaign={investmentGroupName}
            />
          </Fields>
        </>
      )}
    </Container>
  );
};

export default FiltersComponent;
