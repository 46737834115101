import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  background-color: #305368;
  background-position: center;
  background-image: url('https://s3.amazonaws.com/vendavall/photo/1674507828.63cef634d86799.31970923.gif');
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100vh;

    background-color: #305368;
    background-position: center;
    background-image: url('https://storage.vendavall.com.br/photo/1674509463.63cefc9753cc63.75060723.gif');
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
