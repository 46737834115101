import { v4 as uuid } from 'uuid';
import { SpinExtract, SpinExtractResponse } from '../types';

const DATA_SORTEIO_ROLETA = '29/03/2023';

export function transform(data: SpinExtractResponse): SpinExtract {
  return {
    // Título fixo temporário
    title: data.title || 'Roleta Premiada',
    total_coins: data.fmc_total_coins_earned,
    data_sorteio: DATA_SORTEIO_ROLETA,
    is_lucky_number: data.is_lucky_number,
    luckynumbers: data.luckynumbers.map(ln => ({
      id: uuid(),
      number: ln.number.replace(/[^0-9]/g, ''),
      spin_date: ln.spin_date,
      status: ln.status_luckynumber,
    })),
    user_spins: data.spin.map(spin => ({
      id: uuid(),
      coins_earned: spin.Valor,
      title: spin.Titulo,
      date: spin.Data,
      status: spin.Status_spin,
    })),
  };
}
