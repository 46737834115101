import styled from 'styled-components';
import {
  Button as DefaultButton,
  BaseSelect as DefaultBaseSelect,
} from '../../shared/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 720px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 250px;
  min-width: 75px;
  height: 50px;
  margin-left: 20px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const BaseSelect = styled(DefaultBaseSelect)`
  max-width: 500px;
`;
