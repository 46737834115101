import React from 'react';
import { Answer as AnswerData } from 'components/Surveys/types';

import { InputContainer } from './styles';

interface RadioListProps {
  answer: AnswerData;
  topics: string[];
  onChangeHandler: (value: string, topic: string) => void;
  canEdit: boolean;
}

const RadioList: React.FC<RadioListProps> = ({
  answer,
  topics,
  onChangeHandler,
  canEdit,
}) => {
  const { answer: answerValue, survey_participant_answers, id } = answer;

  return (
    <>
      {topics.map(topic => (
        <InputContainer key={`id-${topic}-${answerValue}`}>
          <input
            type="radio"
            id={`id-${topic}-${answerValue}`}
            value={`${topic}-${answerValue}`}
            name={`radio-${answerValue}`}
            onChange={() => onChangeHandler(answerValue, topic)}
            disabled={!canEdit}
            defaultChecked={survey_participant_answers.some(
              item =>
                item.survey_question_answer_id === id &&
                item.alternative_group === topic,
            )}
          />
        </InputContainer>
      ))}
    </>
  );
};

export default RadioList;
