/* eslint-disable @typescript-eslint/camelcase */
import {
  ApiAction,
  CustomFields,
  ActionRequest,
  Product,
  Attachment,
  Crops,
  Action,
} from 'services/strategic-plans/actions/interfaces';
import { formatDate } from 'util/datetime';

const toApiAction = (data: Action): ApiAction => {
  const {
    id,
    uuid,
    title,
    description,
    created,
    expectedDate,
    strategyPlanId,
    strategyActionTypeName,
    strategyActionTypeId,
    customFields,
    status,
    crops,
    modified,
    beginDateAt,
    costMonthyAt,
    endDateAt,
    exclusiveFmcAt,
    quantityAt,
    typeHiringIdAt,
    strategyActionAt,
    strategyActionType,
    participantId,
    strategyPlanStatus,
  } = data;

  return {
    id,
    uuid,
    title,
    description,
    created,
    status_strategy_plan_id: strategyPlanStatus,
    participant_id: participantId,
    custom_fields: {
      attachments: customFields?.attachments,
      products: customFields?.products,
      budget: customFields.budget,
      points: customFields.points,
      actionLocation: customFields.actionLocation,
      igd: customFields.igd,
    },
    strategy_action_type_name: strategyActionTypeName,
    expected_date: expectedDate,
    strategy_plan_id: strategyPlanId,
    strategy_action_type_id: strategyActionTypeId,
    points: customFields?.points,
    budget: customFields?.budget,
    status_strategy_action_id: status || 1,
    crops,
    modified,
    begin_date_at: beginDateAt,
    cost_monthy_at: costMonthyAt,
    end_date_at: endDateAt,
    exclusive_fmc_at: exclusiveFmcAt,
    quantity_at: quantityAt,
    type_hiring_id_at: typeHiringIdAt,
    strategy_action_at: strategyActionAt,
    strategy_action_type: strategyActionType,
  };
};

const toDevelopmentAction = (data: ApiAction): Action => {
  const {
    id,
    uuid,
    title,
    description,
    created,
    expected_date,
    strategy_plan_id,
    strategy_action_type_name,
    strategy_action_type_id,
    custom_fields,
    status_strategy_action_id,
    crops,
    modified,
    begin_date_at,
    cost_monthy_at,
    end_date_at,
    exclusive_fmc_at,
    quantity_at,
    type_hiring_id_at,
    strategy_action_at,
    status_strategy_plan_id,
  } = data;

  const customFields: CustomFields = custom_fields as CustomFields;

  return {
    id,
    uuid,
    title,
    participantId: data.participant_id,
    description,
    created,
    customFields: custom_fields,
    strategyActionTypeName: strategy_action_type_name,
    expectedDate: expected_date,
    strategyPlanId: strategy_plan_id,
    strategyActionTypeId: strategy_action_type_id,
    points: customFields?.points,
    budget: customFields?.budget,
    igd: customFields?.igd,
    attachments: customFields?.attachments,
    strategyPlanStatus: status_strategy_plan_id,
    products: customFields?.products,
    actionLocation: customFields?.actionLocation,
    status: status_strategy_action_id || 1,
    crops,
    modified,
    beginDateAt: begin_date_at,
    costMonthyAt: cost_monthy_at,
    endDateAt: end_date_at,
    exclusiveFmcAt: exclusive_fmc_at,
    quantityAt: quantity_at,
    typeHiringIdAt: type_hiring_id_at,
    strategyActionAt: strategy_action_at,
    strategyActionType: {
      title: strategy_action_type_name,
      value: strategy_action_type_id?.toString(),
    },
    typeHiring: {
      title: type_hiring_id_at?.toString() || '',
      value: type_hiring_id_at?.toString() || '',
    },
  };
};

interface ActionPayload {
  data: ActionRequest;
  products: Product[];
  crops: Crops[];
  attachments: Attachment[];
  strategyPlanId: number;
  strategyActionTypeId: number;
  strategyActionType?: {};
  uuid: string | undefined;
  created?: string;
  modified?: string;
  typeHiring: { title: string; value: string };
}

const buildActionRequest = ({
  data,
  products,
  crops,
  attachments,
  strategyPlanId,
  strategyActionTypeId,
  strategyActionType,
  uuid,
  typeHiring,
}: ActionPayload): ApiAction => {
  let custom: CustomFields = {
    attachments,
    products,
    budget: data.budget ? Number(data.budget.toString().replace(/\./g, '').replace(/,/g, '.')) : 0,
    points: data.points ? Number(data.points.toString().replace(/\./g, '')) : 0,
    igd: data.igd ? Number(data.igd.toString().replace(/\./g, '')) : 0
  };

  if (
    data.action_location_name &&
    data.action_location_city &&
    data.action_location_state_code
  ) {
    custom = {
      ...custom,
      actionLocation: {
        name: data.action_location_name,
        city: data.action_location_city,
        stateCode: data.action_location_state_code,
        totalArea: Number(data.action_location_total_area || 0),
        treatedArea: Number(data.action_location_treated_area || 0),
      },
    };
  }

  return {
    participant_id: 0,
    title: data.title,
    description: data.description,
    expected_date: formatDate(data.expected_date, 'dd-MM-yy'),
    custom_fields: custom,
    strategy_plan_id: strategyPlanId,
    status_strategy_plan_id: 0,
    strategy_action_type_id: strategyActionTypeId,
    crop_id: crops.map(i => i.id),
    uuid,
    strategy_action_type: strategyActionType,
    crops,
    strategy_action_at: {
      begin_date: data.begin_date_at,
      cost_monthy: Number(
        data.cost_monthy_at?.replace(/\./g, '').replace(/,/g, '.'),
      ),
      end_date: data.end_date_at,
      exclusive_fmc: data.exclusive_fmc_at,
      quantity: data.quantity_at,
      type_hiring_id: Number(typeHiring.value),
    },
    budget: data.budget ? Number(data.budget.toString().replace(/\./g, '').replace(/,/g, '.')) : 0,
    points: data.points ? Number(data.points.toString().replace(/\./g, '')) : 0,
    begin_date_at: data.begin_date_at,
    cost_monthy_at: Number(
      data.cost_monthy_at?.replace(/\./g, '').replace(/,/g, '.'),
    ),
    end_date_at: data.end_date_at,
    exclusive_fmc_at: data.exclusive_fmc_at,
    quantity_at: Number(data.quantity_at),
    type_hiring_id_at: Number(typeHiring.value || 0),
  };
};

export { toDevelopmentAction, toApiAction, buildActionRequest };
