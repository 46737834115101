import styled, { keyframes } from 'styled-components';
import BackgroundImage from 'assets/images/auth/auth-background.jpg';
import { shade } from 'polished';

import * as ContactBalloonStyles from 'components/Contact/styles';

const zoomOut = keyframes`
  from {
    transform: scale(1.3);
  }

  to {
    transform: scale(1);
  }
`;

export const Container = styled.div`
  --text-color: #28221f;
  --fmc-color: #dd171b;

  ${ContactBalloonStyles.Container} {
    position: fixed;
    right: 20px;
    bottom: 20px;

    @media screen and (max-width: 425px) {
      right: 20px !important;
      bottom: 40px !important;
    }
  }
`;

export const FormContainer = styled.section`
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  overflow: hidden;
  /* height: 100%; */

  @media screen and (max-width: 425px) {
    overflow: unset;
  }
`;

export const Background = styled.div`
  // background-color: #021834;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  z-index: -1;

  animation-name: ${zoomOut};
  animation-duration: 1s;

  @media screen and (max-width: 1750px) {
    background-size: cover;
  }

  @media screen and (max-width: 650px) {
    background-image: url(${BackgroundImage});
    background-position: center;
  }
`;

export const Overlay = styled.div`
  padding: 0 15px;
  height: max-content;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 425px) {
    padding: 50px 15px 50px 15px;
  }
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
`;

export const HeaderLogo = styled.div`
  margin-bottom: 30px;
  padding-top: 20px;

  img {
    object-fit: cover;
    width: 40vw;
    max-width: 800px;
    min-width: 500px;
    height: auto;

    @media screen and (max-width: 768px) {
      max-width: 400px;
      min-width: unset;
    }

    @media screen and (max-width: 425px) {
      max-width: 300px;
      min-width: unset;
    }
  }
`;

export const HeaderDescription = styled.div`
  margin-top: 20px;
  text-align: center;
  opacity: 0;

  h2 {
    font-size: 21px;
    font-weight: 400;
    max-width: 800px;
    margin: 0 auto;

    b {
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 35px;

    br {
      display: none;
    }

    h2 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    h2 {
      font-size: 14px;

      &.bold {
        max-width: 260px;
        margin: 15px auto 0 auto;
      }
    }
  }
`;

export const SignFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-top: 70px;

  @media screen and (max-width: 768px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 40px;
  }
`;

export const StepsContainer = styled.section`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  @media screen and (max-width: 425px) {
    margin-top: 25px;
    padding: 0 15px;
  }

  .title {
    color: var(--text-color);

    h1 {
      font-size: 24px;
      margin-bottom: 5px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }

    h2 {
      font-size: 22px;
    }

    @media screen and (max-width: 425px) {
      max-width: 300px;
      margin: 0 auto;

      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 16px;
      }
    }
  }

  .steps {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 70px;
    justify-content: center;

    @media screen and (max-width: 768px) {
      gap: 30px;
    }

    @media screen and (max-width: 425px) {
      grid-template-columns: repeat(4, 100vw);
      justify-content: start;
      gap: 0;
      overflow-x: scroll;
      margin: 30px -15px 0 -15px;
      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__step {
      width: min-content;

      img {
        margin-bottom: 10px;
      }

      span {
        color: var(--text-color);
        font-size: 18px;
        white-space: nowrap;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
      }

      @media screen and (max-width: 425px) {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        scroll-snap-align: center;
      }
    }

    &__action {
      max-width: 690px;
      width: 100%;
      margin: 40px auto 0 auto;

      button {
        all: unset;
        cursor: pointer;
        height: 44px;
        background-color: var(--text-color);
        width: 100%;
        border-radius: 5px;
        font-family: ${({ theme }) => theme.font.fontFamily.bold};
        transition: background-color 150ms ease-in-out;

        &:hover {
          background-color: ${shade(0.1, '#28221f')};
        }

        &:active {
          background-color: ${shade(0.3, '#28221f')};
        }
      }
    }
  }

  .steps__dots {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    margin: 20px auto 0 auto;

    &___dot {
      width: 8px;
      height: 8px;
      border: 1px solid var(--text-color);
      border-radius: 99px;
      transition: background-color 200ms ease-in-out;

      &.active {
        background-color: var(--text-color);
      }
    }

    @media screen and (min-width: 425px) {
      display: none;
    }
  }
`;

export const MobileInfoContainer = styled.section`
  background-color: var(--fmc-color);
  margin-top: 60px;
  padding: 40px 0;
`;

export const MobileInfoContent = styled.div`
  display: grid;
  grid-template-columns: 215px 1fr;
  grid-gap: 15px;
  max-width: max-content;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }

  .image {
    background-color: #fff;
    border-radius: 999px;
    overflow: hidden;

    img {
      transition: transform 200ms ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }

    @media screen and (max-width: 425px) {
      width: 160px;
      height: 160px;
      margin: 0 auto;

      img {
        object-fit: contain;
        width: 160px;
        height: 160px;
      }
    }
  }

  .info {
    text-align: center;

    &__title span {
      font-size: 24px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &__desc p {
      margin-top: 10px;
      font-size: 20px;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }

    &__stores {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: max-content;
      grid-gap: 20px;
      margin: 30px auto 0 auto;

      img {
        height: 44px;
        cursor: pointer;
        transition: transform 200ms ease-in-out;

        &:hover {
          transform: translateY(-2px);
        }

        &:active {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const SocialContainer = styled.section`
  padding: 20px 0;
  background-color: #fff;
  display: flex;
  justify-content: center;

  .logo {
    margin-right: 140px;

    @media screen and (max-width: 425px) {
      margin-right: 0;
    }
  }

  .social {
    display: flex;
    align-items: center;

    &__network {
      background-color: var(--fmc-color);
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      margin: 0 5px;
      transition: transform 200ms ease-in-out;
      cursor: pointer;
      text-decoration: none;

      svg {
        color: #fff;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }

    @media screen and (max-width: 425px) {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Footer = styled.footer`
  padding: 14px 0;
  text-align: center;
  color: var(--text-color);
  font-size: 12px;
`;

const arrowAnimation = keyframes`
  0% {
    bottom: 30px;
  }

  50% {
    bottom: 26px;
  }

  100% {
    bottom: 30px;
  }
`;

export const ScrollArrow = styled.div`
  position: absolute;
  height: min-content;
  width: min-content;
  bottom: 30px;
  right: 0;
  left: 0;
  margin: 0 auto;
  animation: ${arrowAnimation} 1.5s ease-in-out infinite;

  svg {
    cursor: pointer;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;
