import styled from 'styled-components';

export const Main = styled.main`
  h2 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: 'Helvetica-Neue-Bold';
    font-size: 24px;
  }
`;

export const Container = styled.div`
  margin: 30px 0;
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 40px 0;
`;

export const Title = styled.div`
  text-align: center;
  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    display: block;
    font-size: 24px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-top: 32px;
  }
`;

export const Select = styled.select`
  width: 210px;
  height: 35px;
  border: none;
  cursor: pointer;
  color: #7b7b7b;
  background: #fff;
  margin: 0.5rem;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0, 0.5rem;
  position: relative;
  top: 0.5rem;
`;

export const Content = styled.div`
  ._inputContainer {
    height: 35px;
    border-radius: 7px;
  }

  .content-date {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem;
    justify-content: center;
    align-items: center;

    .first-datepicker {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }

    @media (min-width: 768px) {
      .align-datepickers {
        display: flex;
        align-items: center;
        width: 75.01%;
      }
    }

    .second-datepicker {
      display: flex;
      flex-direction: column;
    }
  }

  button {
    width: auto;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    margin: 0.3rem 1rem;
    position: relative;
    top: 0.3rem;
    height: 2.2rem;
    font-size: 0.9rem;
    padding: 7px 10px;
    display: block;
    background: #e72e29;
    margin-top: 10px;
    font-family: Helvetica-Neue-Bold;
  }

  button:disabled {
    opacity: 0.8;
    background: #e72e29;
    cursor: not-allowed;
  }

  > img {
    width: 100%;
  }

  .title-extract strong {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-left: 5rem;
    font-weight: bold;
    color: #000 !important;
    font-size: 24px;
  }
`;

export const ContentMobile = styled.div`
  .card-items {
    color: #000;
    margin: 0.5rem;
    padding: 0.5rem;
    background: #fff;
  }

  .card-header {
    display: flex;
  }

  h4,
  a {
    margin-bottom: 0.5rem;
  }

  .details {
    margin-left: 1.5rem;
  }

  span {
    font-weight: bold;
  }
`;

export const ListTable = styled.table`
  width: 92%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  margin: 10px auto;

  tbody,
  thead {
    color: #000;
  }

  tr {
    text-align: left;
  }

  th {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 14px;
    text-align: left;
    padding: 17px 10px;
    position: relative;
    top: 0.5rem;
  }
  td {
    background: #fff;
    padding: 7px 10px;
    border-top: 3px solid #efefef;
  }
`;

export const ListTableContainer = styled.div`
  width: 100%;
  padding-top: 10px;

  .searched {
    background: rgba(239, 239, 239, 255);
    padding-bottom: 10px;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
  }

  h3 {
    text-align: center;
    color: #000;
  }
`;

export const TitleExtract = styled.strong`
  color: ${({ theme }) => theme.font.color.primary};
  display: block;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 24px;
  margin-top: 32px;
  text-align: center;
`;
