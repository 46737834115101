import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-grid-system';
import { useAuth } from 'context/AuthContext';

import {
  PerformanceTabContent,
  Top10ProductsTabContent,
} from 'components/Goals';
import { Tabs } from 'state/modules/goals/constants';
import {
  getCampaigns,
  getBillingPog,
  getPotentializers,
  getBiological,
  getTopPurchasingProducts,
  getTopSellingProducts,
  getInfos,
  getSelectedCampaignId,
  getCurrentCampaign,
} from 'state/modules/goals/selectors';
import {
  fetchCampaigns,
  fetchCurrentCampaign,
  fetchBillingPog,
  fetchPotentializers,
  fetchBiological,
  fetchInfos,
  fetchTopPurchasingProducts,
  fetchTopSellingProducts,
  setSelectedCampaignId,
} from 'state/modules/goals/actions';
import { fetchCurrentCampaignTerraService as getCurrentCampaignTerra } from 'services/goals';
import { Wrapper, TabWrapper, TabsList, Tab, CampaignsList } from './styles';

const Goals: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Performance);
  const { participant: _participant } = useAuth();
  const [terraCampaign, setTerraCampaign] = useState(0);

  const isTerraBrasil =
    _participant.establishment.type_name.toLowerCase() === 'terra brasil';

  const [
    campaigns,
    billingPog,
    purchasingProducts,
    sellingProducts,
    potentializers,
    biological,
    infos,
    selectedCampaignId,
    currentCampaign,
  ] = [
    useSelector(getCampaigns),
    useSelector(getBillingPog),
    useSelector(getTopPurchasingProducts),
    useSelector(getTopSellingProducts),
    useSelector(getPotentializers),
    useSelector(getBiological),
    useSelector(getInfos),
    useSelector(getSelectedCampaignId),
    useSelector(getCurrentCampaign),
  ];
  const dispatch = useDispatch();

  const filteredSafra = campaigns
    ? campaigns.filter(c => c.title !== '19/20' && !c.isTerraBrasil)
    : [];

  const filteredTerra = campaigns
    ? campaigns.filter(c => c.title !== '2022' && c.isTerraBrasil)
    : [];

  useEffect(() => {
    const fetchCurrentCampaignTerra = async () => {
      const currentCampaignTerra = await getCurrentCampaignTerra();
      if (isTerraBrasil && currentCampaignTerra) {
        setTerraCampaign(currentCampaignTerra.id);
        dispatch(setSelectedCampaignId(currentCampaignTerra.id));
      }
    };

    fetchCurrentCampaignTerra();
  }, [dispatch, isTerraBrasil]);

  useEffect(() => {
    dispatch(fetchCurrentCampaign());
    dispatch(fetchCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (activeTab === Tabs.Performance) {
      dispatch(fetchBillingPog());
      dispatch(fetchPotentializers());
      dispatch(fetchBiological());
      dispatch(fetchInfos());
      return;
    }

    dispatch(fetchTopPurchasingProducts());
    dispatch(fetchTopSellingProducts());
  }, [dispatch, activeTab, selectedCampaignId]);

  const isSelectedCampaign = useCallback(
    (campaignId: number) => {
      if (isTerraBrasil && !selectedCampaignId) {
        return campaignId === terraCampaign;
      }
      if (!selectedCampaignId) {
        return campaignId === currentCampaign?.id;
      }
      const isSelectedCampaignId: boolean = campaignId === selectedCampaignId;
      return isSelectedCampaignId;
    },

    [isTerraBrasil, selectedCampaignId, terraCampaign, currentCampaign],
  );

  const selectCampaign = useCallback(
    (id: number) => {
      dispatch(setSelectedCampaignId(id));
    },
    [dispatch],
  );

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Wrapper>
        {!isTerraBrasil ? (
          <CampaignsList>
            {filteredSafra.map(campaign => (
              <li key={campaign.title}>
                <button
                  className={isSelectedCampaign(campaign.id) ? 'selected' : ''}
                  type="button"
                  onClick={() => selectCampaign(campaign.id)}
                >
                  {`Safra ${campaign.title}`}
                </button>
              </li>
            ))}
          </CampaignsList>
        ) : (
          <CampaignsList>
            {filteredTerra.map(campaign => (
              <li key={campaign.title}>
                <button
                  className={isSelectedCampaign(campaign.id) ? 'selected' : ''}
                  type="button"
                  onClick={() => selectCampaign(campaign.id)}
                >
                  {`Ano ${campaign.title}`}
                </button>
              </li>
            ))}
          </CampaignsList>
        )}
        <TabWrapper>
          <TabsList>
            <Tab
              onClick={() => setActiveTab(Tabs.Performance)}
              active={activeTab === Tabs.Performance}
            >
              <span>PERFORMANCE</span>
            </Tab>
            <Tab
              onClick={() => setActiveTab(Tabs.Top10Products)}
              active={activeTab === Tabs.Top10Products}
            >
              <span>TOP 10 PRODUTOS</span>
            </Tab>
          </TabsList>
          {activeTab === Tabs.Performance && (
            <PerformanceTabContent
              billingPog={billingPog}
              potentializers={potentializers}
              biological={biological}
              infos={infos}
            />
          )}
          {activeTab === Tabs.Top10Products && (
            <Top10ProductsTabContent
              purchasingProducts={purchasingProducts}
              sellingProducts={sellingProducts}
            />
          )}
        </TabWrapper>
      </Wrapper>
    </Container>
  );
};

export default Goals;
