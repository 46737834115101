import routeMap from 'routes/route-map';
import { Regulations } from 'pages/Regulations';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.regulations,
    component: Regulations,
    accessPage: 'Regulamentos',
    isPublic: true,
  },
];

export default routes;
