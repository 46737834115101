import React from 'react';

import MultipleLinearScale from 'components/Surveys/SearchForms/AnswerSurvey/MultipleLinearScale';

import InputCheckBox from 'components/Surveys/SearchForms/AnswerSurvey/InputCheckBox';
import InputGridCheckBox from 'components/Surveys/SearchForms/AnswerSurvey/InputGridCheckBox';
import InputGridRadio from 'components/Surveys/SearchForms/AnswerSurvey/InputGridRadio';
import InputRadios from 'components/Surveys/SearchForms/AnswerSurvey/InputRadios';
import InputText from 'components/Surveys/SearchForms/AnswerSurvey/InputText';
import InputDate from 'components/Surveys/SearchForms/AnswerSurvey/InputDate';
import InputTime from 'components/Surveys/SearchForms/AnswerSurvey/InputTime';
import DropDownList from 'components/Surveys/SearchForms/AnswerSurvey/DropDownList';

import TypeStar from 'components/Surveys/SearchForms/AnswerSurvey/LinearScal/TypeStar';
import TypeCheckBox from 'components/Surveys/SearchForms/AnswerSurvey/LinearScal/TypeCheckBox';
import TypeRadio from 'components/Surveys/SearchForms/AnswerSurvey/LinearScal/TypeRadio';
import TypeEmoji from 'components/Surveys/SearchForms/AnswerSurvey/LinearScal/TypeEmoji';
import { Answer } from 'components/Surveys/types';
// release

interface FormTypeProps {
  type: number;
  name: string;
  question: string;
  answers: Answer[];
  questioId: number;
  answerId?: number;
  scaleType?: number;
  alternativeGroups?: string[];
  alreadyAnswered: boolean;
  canEdit?: boolean;
}

const FormType: React.FC<FormTypeProps> = ({
  type,
  name,
  question,
  answers,
  questioId,
  answerId,
  scaleType,
  alternativeGroups,
  alreadyAnswered,
  canEdit,
}) => {
  switch (type) {
    case 2: {
      return (
        <MultipleLinearScale
          question={question}
          answers={answers}
          id={questioId}
          canEdit={canEdit ?? true}
        />
      );
    }
    case 1:
      if (scaleType === 1) {
        return (
          <TypeStar
            question={question}
            answers={answers}
            id={questioId}
            canEdit={canEdit ?? true}
          />
        );
      }
      if (scaleType === 2) {
        return (
          <TypeCheckBox
            question={question}
            answers={answers}
            id={questioId}
            canEdit={canEdit ?? true}
          />
        );
      }
      if (scaleType === 3) {
        return (
          <TypeRadio
            question={question}
            answers={answers}
            id={questioId}
            canEdit={canEdit ?? true}
          />
        );
      }

      return (
        <TypeEmoji
          question={question}
          answers={answers}
          id={questioId}
          canEdit={canEdit ?? true}
        />
      );

    case 3:
      return (
        <InputRadios
          question={question}
          answers={answers}
          id={questioId}
          canEdit={canEdit ?? true}
        />
      );

    case 4:
      return (
        <InputCheckBox
          question={question}
          answers={answers}
          id={questioId}
          canEdit={canEdit ?? true}
        />
      );

    case 5:
      return (
        <InputText
          question={question}
          name={name}
          id={questioId}
          answerId={answerId}
          answers={answers}
          canEdit={canEdit ?? true}
        />
      );

    case 6:
      return (
        <InputGridRadio
          question={question}
          answers={answers}
          id={questioId}
          topics={alternativeGroups ?? []}
          canEdit={canEdit ?? true}
        />
      );

    case 7:
      return (
        <InputGridCheckBox
          question={question}
          answers={answers}
          id={questioId}
          topics={alternativeGroups ?? []}
          canEdit={canEdit ?? true}
        />
      );

    case 8:
      return (
        <InputDate
          question={question}
          type="date"
          id={questioId}
          answerId={answerId}
          answers={answers}
          canEdit={canEdit ?? true}
        />
      );

    case 9:
      return (
        <InputTime
          question={question}
          type="time"
          id={questioId}
          answerId={answerId}
          answers={answers}
          canEdit={canEdit ?? true}
        />
      );

    case 10:
      return (
        <DropDownList
          question={question}
          answers={answers}
          id={questioId}
          canEdit={canEdit ?? true}
          alreadyAnwered={alreadyAnswered}
        />
      );

    default:
      return <div />;
  }
};

export default FormType;
