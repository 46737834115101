import React, { useState, useEffect } from 'react';
import parser from 'html-react-parser';
import transformer, {
  Response as Data,
} from 'services/news/transformers/toNewsView';
import { News } from 'services/news/interfaces';
import { pluginApi } from 'services/api';

// import { useAuth } from 'context/AuthContext';
import { Container, Content } from './styles';

interface Props {
  news: News;
}

const View: React.FC<Props> = ({ news }) => {
  const [data, setData] = useState<Data | null>(null);
  const idNews = news.id.toString();
  // const { participant } = useAuth();

  useEffect(() => {
    setData(transformer(news));
  }, [news]);

  useEffect(() => {
    pluginApi.post('/report-news', { page: 'novidades', description: idNews });
  });

  // Inicio Tampáo
  // const perfil = participant.establishment.type_name;
  // const checking = ['Revenda', 'Produtor', 'Equipe', 'Cooperativa'];

  // useEffect(() => {
  //   if (
  //     perfil === checking[0] ||
  //     perfil === checking[1] ||
  //     perfil === checking[2] ||
  //     perfil === checking[3]
  //   ){
  //     window.location.href = "https://juntosfmc.vendavall.com.br/home";
  //   }
  // });
  // Fim Tampáo

  return (
    data && (
      <Container>
        <h4>Novidades</h4>
        <h3>{data.title}</h3>
        <span>{`${data.date} ${data.category}`}</span>
        <p>{data.summary}</p>
        <Content>{parser(data.body || '')}</Content>
      </Container>
    )
  );
};

export default View;
