import { IProfile, PROFILES } from 'config/constants';
import { pluginApi } from 'services/api';
import { Regulation } from './interfaces/IRegulation';

interface ApiResponse {
  regulations: Regulation[];
}

export default async (
  profile: IProfile,
  userId?: number,
): Promise<Regulation[]> => {
  const isProducer = profile === PROFILES.producer;
  const { data } = await pluginApi.get<ApiResponse>(`regulations/terms`, {
    params: {
      type: isProducer ? 'data_term_produtores' : undefined,
      id: userId || undefined,
    },
  });
  return data.regulations;
};
