import { pluginApi } from 'services/api';

interface Request {
  uuid: string;
  status: number;
}

export default async ({ uuid, status }: Request): Promise<void> => {
  await pluginApi.put(`/strategy-action/update-status/${uuid}`, {
    status_strategy_action_id: status,
  });
};
