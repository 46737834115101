import {
  ActionCreator,
  ActionCreatorPayload,
  ActionCreatorFailure,
} from '@types';
import {
  FETCH_ESTABLISHMENTS_ACTION,
  FETCH_ESTABLISHMENTS_FAILURE,
  FETCH_ESTABLISHMENTS_SUCCESS,
} from './constants';
import { CommonState } from './reducer';
import { Establishment } from './types';

export const fetchEstablishments = (): ActionCreator<
  typeof FETCH_ESTABLISHMENTS_ACTION
> =>
  <const>{
    type: FETCH_ESTABLISHMENTS_ACTION,
  };

export const fetchEstablishmentsFailure = (
  error: string,
): ActionCreatorFailure<typeof FETCH_ESTABLISHMENTS_FAILURE> =>
  <const>{
    type: FETCH_ESTABLISHMENTS_FAILURE,
    payload: {
      error,
    },
  };

export const fetchEstablishmentsSuccess = (
  establishments: Establishment[] | null,
): ActionCreatorPayload<
  typeof FETCH_ESTABLISHMENTS_SUCCESS,
  Pick<CommonState, 'establishments'>
> =>
  <const>{
    type: FETCH_ESTABLISHMENTS_SUCCESS,
    payload: { establishments },
  };

export type CommonActions = ReturnType<
  | typeof fetchEstablishments
  | typeof fetchEstablishmentsFailure
  | typeof fetchEstablishmentsSuccess
>;
