import styled from 'styled-components';
import { Button } from 'components/shared';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 3px 10px rgb(0 0 0 / 13%);
  padding: 1em;
  display: flex;
  text-transform: uppercase;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const Period = styled.div`
  span {
    display: block;
    text-align: center;
  }
`;

export const PeriodText = styled.span`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const NewBox = styled.div`
  margin-bottom: 20px;
`;

export const SafraText = styled.h4`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 0.9em;
  font-weight: 100;
  justify-content: center;
`;

export const TotalPointsText = styled.h4`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.fontFamily.medium};
  font-size: 1.2em;
  font-weight: 900;
  justify-content: center;
  margin-top: 25px;
  position: relative;
  top: 2rem;
`;

export const PointsWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Points = styled.h3`
  font-size: 2em;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
`;

export const Tooltip = styled.div`
  div {
    width: 300px;
    height: 120px;
    background: #707070;
    color: white;
    position: absolute;
    margin-left: 10px;
    margin-top: -150px;
    border-radius: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px #00000050;

    @media only screen and (max-width: 1800px) {
      margin-left: -190px;
    }

    p {
      width: 250px;
      text-align: justify;
    }
  }

  &:hover div {
    display: flex;
  }
`;


export const CustomButton = styled(Button)`
  background-color: ${({ theme }) => theme.font.color.primary};
  border-radius: 24px;
  color: #fff;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 12px;
  justify-content: center;
  text-decoration: none;
  width: 170px;
  position: relative;
  top: -2rem;
`;
