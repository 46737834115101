import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Participant } from 'services/auth/interfaces/Participant';
import FMCLogo from 'assets/images/logo.png';

import { PROFILES } from 'config/constants';
import {
  FmcRegisterForm,
  FocalpointRegisterForm,
  ProdutorRegisterForm,
} from './components/RegisterForms';
import { Container, FormContainer, Head } from './styles';

export type PrimeiroAcessoDTO = {
  participant?: Participant;
  cpf?: string;
};

const forms = {
  [PROFILES.fmc]: FmcRegisterForm,
  [PROFILES.focalPoint]: FocalpointRegisterForm,
  [PROFILES.participant]: FocalpointRegisterForm,
  [PROFILES.producer]: ProdutorRegisterForm,
};

export const PrimeiroAcesso: React.FC = () => {
  const location = useLocation<PrimeiroAcessoDTO>();

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const initialData = location.state;
  const RegisterFormComponent = initialData.participant?.profile
    ? forms[initialData.participant.profile]
    : forms[PROFILES.producer];

  const isProducerProfile =
    initialData.participant?.profile === PROFILES.producer;

  return (
    <Container>
      <Head href="/">
        <img src={FMCLogo} alt="Logo FMC" />
      </Head>

      <FormContainer>
        <div className="head--title">
          <h1>
            {isProducerProfile
              ? 'Comece por aqui, Produtor(a)!'
              : 'Inicie seu cadastro por aqui!'}
          </h1>

          {isProducerProfile && (
            <span>
              Aceite as opções abaixo para ter acesso ao formulário de cadastro.
              Em seguida, preencha corretamente TODOS os campos com seus dados.
            </span>
          )}
        </div>

        <RegisterFormComponent initialData={initialData} />
      </FormContainer>
    </Container>
  );
};
