import { IProfile, PROFILES, CommercialTeam } from 'config/constants';
import { pluginApi } from 'services/api';
import { Regulation } from './interfaces/IRegulation';

interface ApiResponse {
  regulations: Regulation[];
}

export default async (
  profile: IProfile,
  profileValue: string | null = null,
): Promise<Regulation> => {
  let extraParams = '';
  const isProducer = profile === PROFILES.producer;
  const isCommercialTeam = Object.values(CommercialTeam).includes(
    profileValue ?? '',
  );

  if (isProducer) {
    extraParams = '?type=data_term_produtores';
  } else if (isCommercialTeam) {
    extraParams = '?type=data_term_commercial_team';
  }

  const { data } = await pluginApi.get<ApiResponse>(
    `regulations/terms${extraParams}`,
  );
  return data.regulations[0];
};
