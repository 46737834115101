import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    max-width: 500px;
    width: 100%;
    height: max-content;
    background: ${({ theme }) => theme.modal.primary.backgroundColor};
    color: ${({ theme }) => theme.font.color.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-bottom: 10px;
  }
  p {
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  transform: translateX(-4px) translateY(4px);
  button {
    border: none;
    background: ${({ theme }) => theme.font.color.primary};
    padding: 15px 60px;
    border-radius: 8px;
    color: ${({ theme }) => theme.font.color.tertiary};
  }
  button:hover {
    filter: grayscale(50%);
    transition: 0.5s;
  }
`;
