import styled from 'styled-components';
import {
  Input as DefaultInput,
  Button as DefaultButton,
} from 'components/shared';
import DefaultRolesSelect from 'components/shared/Vendavall/Roles/ProtectedRolesSelect';
import DefaultFilialSelect from 'components/shared/Vendavall/Establishments/FilialSelect';

export const Container = styled.div`
  margin-top: 20px;

  form {
    display: grid;
    grid-template-areas:
      'name role role role button'
      'filial filial filial filial button';

    grid-gap: 1em;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 720px) {
    margin-top: 10px;
    form {
      display: flex;
      flex-direction: column;

      > button {
        width: 100%;
      }
    }
  }
`;

export const Buttons = styled.div`
  grid-area: button;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ClearButton = styled.button`
  width: 137px;
  height: 36px;
  background: none;
  text-decoration: underline;
  border: none;
  color: ${({ theme }) => theme.font.color.primary};
`;

export const Button = styled(DefaultButton)`
  width: 137px;
  height: 36px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  font-size: 16px;
`;

export const IndicatedName = styled(DefaultInput)`
  grid-area: name;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    margin-left: 0;
    font-size: 16px;
  }
  ._inputContainer {
    height: 40px;
  }
`;

export const RolesSelect = styled(DefaultRolesSelect)`
  grid-area: role;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    margin-left: 0;
    font-size: 16px;
  }

  ._inputContainer {
    height: 40px;
  }

  @media screen and (max-width: 720px) {
    margin-right: 0;
    margin-top: 8px;
  }
`;

export const FilialSelect = styled(DefaultFilialSelect)`
  grid-area: filial;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    margin-left: 0;
    font-size: 16px;
  }
  ._inputContainer {
    height: 40px;
  }

  @media screen and (max-width: 720px) {
    margin-right: 0;
  }
`;
