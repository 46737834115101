import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import getInvoiceStatusSummary from 'services/nf/getInvoiceStatusSummary';

import { InvoiceStatusSummary } from 'services/nf/interfaces';
import Upload from './Upload';

import {
  Container,
  Content,
  Title,
  RightSideBox,
  AdjustStatusTable,
  ButtonDownload,
} from './styles';

interface Props {
  layout?: string;
}

const AddNF: React.FC<Props> = Props => {
  const history = useHistory();
  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatusSummary>({
    underAnalysis: 0,
    released: 0,
    disqualified: 0,
    awaitingApproval: 0,
  });

  const updateStatus = useCallback(async () => {
    const status = await getInvoiceStatusSummary(0);
    setInvoiceStatus(status);
  }, []);

  const refreshPage = useCallback(() => {
    history.go(0);
  }, [history]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  return (
    <Container>
      {Props.layout !== 'secondary' && (
        <Content>
          <Title>
            Cadastre suas notas fiscais <br /> e garanta seus FMC Coins!
          </Title>
          <div>
            <p>
              {/* Clique no botão para enviar sua nota fiscal em formato JPG, PNG ou
              PDF. Em caso de JPG e PNG, certifique-se de que a imagem está
              nítida e legível. */}
            </p>
            <RightSideBox className="right-side">
              <div className="right-side--box">
                <AdjustStatusTable status={invoiceStatus} display="1" />

                <ButtonDownload onUpdate={() => updateStatus()} />
              </div>
            </RightSideBox>
          </div>
        </Content>
      )}
      {Props.layout === 'secondary' && (
        <Upload onUpdate={() => refreshPage()} secondary />
      )}
    </Container>
  );
};

export default AddNF;
