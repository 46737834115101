import {
  ActionCreator,
  ActionCreatorFailure,
  ActionCreatorPayload,
} from '@types';
import {
  FETCH_COIN_QUOTATION_ACTION,
  FETCH_COIN_QUOTATION_FAILURE,
  FETCH_COIN_QUOTATION_SUCCESS,
  FETCH_MENU_ACTION,
  FETCH_MENU_FAILURE,
  FETCH_MENU_SUCCESS,
  SHOW_PROFILE_CHANGE,
  HIDE_PROFILE_CHANGE,
  FETCH_PROFILES_ACTION,
  FETCH_PROFILES_FAILURE,
  FETCH_PROFILES_SUCCESS,
} from './constants';
import { HeaderState } from './reducer';
import { AvailableProfiles, Coin, MenuItem } from './types';

export const fetchCoinQuotation = (): ActionCreator<
  typeof FETCH_COIN_QUOTATION_ACTION
> =>
  <const>{
    type: FETCH_COIN_QUOTATION_ACTION,
  };

export const fetchCoinQuotationFailure = (
  error: string,
): ActionCreatorFailure<typeof FETCH_COIN_QUOTATION_FAILURE> =>
  <const>{
    type: FETCH_COIN_QUOTATION_FAILURE,
    payload: {
      error,
    },
  };

export const fetchCoinQuotationSuccess = (
  coinQuotations: Coin[] | null,
): ActionCreatorPayload<
  typeof FETCH_COIN_QUOTATION_SUCCESS,
  Pick<HeaderState, 'coinQuotations'>
> =>
  <const>{
    type: FETCH_COIN_QUOTATION_SUCCESS,
    payload: { coinQuotations },
  };

export const fetchMenu = (): ActionCreator<typeof FETCH_MENU_ACTION> =>
  <const>{
    type: FETCH_MENU_ACTION,
  };

export const fetchMenuFailure = (
  error: string,
): ActionCreatorFailure<typeof FETCH_MENU_FAILURE> =>
  <const>{
    type: FETCH_MENU_FAILURE,
    payload: {
      error,
    },
  };

export const fetchMenuSuccess = (
  menu: MenuItem[] | null,
): ActionCreatorPayload<typeof FETCH_MENU_SUCCESS, Pick<HeaderState, 'menu'>> =>
  <const>{
    type: FETCH_MENU_SUCCESS,
    payload: { menu },
  };

export const showProfileChange = (): ActionCreator<
  typeof SHOW_PROFILE_CHANGE
> =>
  <const>{
    type: SHOW_PROFILE_CHANGE,
  };

export const hideProfileChange = (): ActionCreator<
  typeof HIDE_PROFILE_CHANGE
> =>
  <const>{
    type: HIDE_PROFILE_CHANGE,
  };

export const fetchProfiles = (): ActionCreator<typeof FETCH_PROFILES_ACTION> =>
  <const>{
    type: FETCH_PROFILES_ACTION,
  };

export const fetchProfilesFailure = (
  error: string,
): ActionCreatorFailure<typeof FETCH_PROFILES_FAILURE> =>
  <const>{
    type: FETCH_PROFILES_FAILURE,
    payload: {
      error,
    },
  };

export const fetchProfilesSuccess = (
  availableProfiles: AvailableProfiles,
): ActionCreatorPayload<
  typeof FETCH_PROFILES_SUCCESS,
  Pick<HeaderState, 'availableProfiles'>
> =>
  <const>{
    type: FETCH_PROFILES_SUCCESS,
    payload: { availableProfiles },
  };

export type HeaderActions = ReturnType<
  | typeof fetchCoinQuotation
  | typeof fetchCoinQuotationFailure
  | typeof fetchCoinQuotationSuccess
  | typeof fetchMenu
  | typeof fetchMenuFailure
  | typeof fetchMenuSuccess
  | typeof showProfileChange
  | typeof hideProfileChange
  | typeof fetchProfiles
  | typeof fetchProfilesFailure
  | typeof fetchProfilesSuccess
>;
