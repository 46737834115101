import React, { useEffect, useState, useCallback } from 'react';
import { MemberType } from 'services/auth/interfaces/Participant';
import Select, { Option } from 'components/shared/Select';

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  inputRole: 'primary' | 'secondary';
}

const MemberTypeSelect: React.FC<Props> = ({
  className,
  label,
  placeholder,
  inputRole = 'primary',
  ...rest
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const data = Object.values(MemberType).map(item => ({
      value: item.toLowerCase(),
      title: item,
    }));
    setOptions(data);
  }, []);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  return (
    <Select
      {...rest}
      label={label}
      name="member_type"
      loadItems={loadItems}
      className={className}
      placeholder={placeholder}
      inputRole={inputRole}
    />
  );
};

export default MemberTypeSelect;
