import getActionsPlanId from './getActionsPlanId';
import { ApiAction } from './interfaces';

export default async (strategicPlanId: number): Promise<ApiAction[]> => {
  const data = await getActionsPlanId(
    strategicPlanId,
    '/relationship-actions/get-actions-by-plan-id',
  );

  return data.map(action => action);
};
