import React, { useState, useEffect } from 'react';
import { Button, useSimpleToast } from 'components/shared';
import { saveDistribution } from 'services/point-management/save-distribution';

import { useAuth } from 'context/AuthContext';
import { usePoints } from 'pages/PointManagement/use-points';
import routeMap from 'routes/route-map';
import { useHistory } from 'react-router-dom';
import { requestDistributionToApprove } from 'services/point-management/request-distribution-to-approve';
import { getPointsDistributionToApprove } from 'services/point-management/points-distribuition-to-approve';
import { ReproveModal } from './reprove-modal';

type Props = {
  point: any;
  name: string;
  pointsToApprove: any;
};

export const ApprovationActions = ({ point, name }: Props) => {
  const [action, setAction] = useState<'is_approving' | 'is_reproving' | null>(
    null,
  );
  const [reproveOpen, setReproveModalOpen] = useState(false);
  const [valuesPointsToApprove, setValuesPointsToApprove] = useState();

  const { participant } = useAuth();
  const { showToast } = useSimpleToast();
  const history = useHistory();

  const { refresh, selectedCampaign, selectedEstablishment } = usePoints();

  useEffect(() => {
    const getPoints = async () => {
      if (selectedEstablishment) {
        const dados = await getPointsDistributionToApprove({
          establishmentId:
            selectedEstablishment && Number(selectedEstablishment?.value),
          campaignId: selectedCampaign,
        });
        setValuesPointsToApprove(dados.distribution);
      }
    };

    getPoints();
  }, [selectedCampaign, selectedEstablishment]);

  const pointsToApprove =
    valuesPointsToApprove && Object.values(valuesPointsToApprove).length > 0;

  const reload = () => {
    if (
      participant.profile_value === 'CRM' ||
      (participant.profile_value === 'GRM' && pointsToApprove)
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const approveActions =
    point.name_point === 'Premiação de Equipe' ||
    point.name_point === 'Premiação de Vendedores' ||
    point.name_point === 'Recompensa';

  const approve = async () => {
    setAction('is_approving');

    showToast({
      type: 'info',
      message: 'Confirmando aprovação...',
    });

    if (!approveActions) {
      const payloadFirstPhase = {
        undistributed_point_id: point.undistributed_point_id,
        establishment_id:
          selectedEstablishment && Number(selectedEstablishment.value),
        participant_id: participant.id,
        profile: participant.profile_value,
        approval_status: 3,
      };

      const response = await saveDistribution(payloadFirstPhase);

      try {
        showToast({
          type: 'success',
          message: response.message,
        });
        history.push(routeMap.pointApprovement);
        reload();
        refresh();
      } catch (e) {
        showToast({
          type: 'error',
          message: 'Erro ao aprovar distribuição.',
        });
        history.push(routeMap.pointApprovement);
        refresh();
      }
    } else {
      const payloadSecondPhase = {
        undistributed_point_id: point.undistributed_point_id,
        establishment_id:
          selectedEstablishment && Number(selectedEstablishment.value),
        participant_id: participant.id,
        profile: participant.profile_value,
        approval_status: 3,
        campaign_id: selectedCampaign,
      };

      const response = await requestDistributionToApprove(payloadSecondPhase);

      try {
        showToast({
          type: 'success',
          message: response.message,
        });
        history.push(routeMap.pointApprovement);
        reload();
        refresh();
      } catch (e) {
        showToast({
          type: 'error',
          message: 'Erro ao aprovar distribuição.',
        });
        history.push(routeMap.pointApprovement);
        refresh();
      }
    }
  };

  const reprove = async (comment: string) => {
    setAction('is_reproving');

    showToast({
      type: 'info',
      message: 'Confirmando a reprovação...',
    });

    if (!approveActions) {
      const payloadFirstPhaseReprove = {
        undistributed_point_id: point.undistributed_point_id,
        establishment_id:
          selectedEstablishment && Number(selectedEstablishment.value),
        participant_id: participant.id,
        profile: participant.profile_value,
        approval_status: 4,
        comment,
      };

      const response = await saveDistribution(payloadFirstPhaseReprove);

      try {
        showToast({
          type: 'success',
          message: response.message,
        });
        history.push(routeMap.pointApprovement);
        refresh();
        reload();
      } catch (e) {
        showToast({
          type: 'error',
          message: 'Tente mais tarde.',
        });
        history.push(routeMap.pointApprovement);
        refresh();
      }
    } else {
      const payloadSecondPhaseReprove = {
        undistributed_point_id: point.undistributed_point_id,
        establishment_id:
          selectedEstablishment && Number(selectedEstablishment.value),
        participant_id: participant.id,
        profile: participant.profile_value,
        campaign_id: pointsToApprove && selectedCampaign,
        approval_status: 4,
        comment,
      };

      const response = await requestDistributionToApprove(
        payloadSecondPhaseReprove,
      );

      try {
        showToast({
          type: 'success',
          message: response.message,
        });
        refresh();
        reload();
        history.push(routeMap.pointApprovement);
      } catch (e) {
        showToast({
          type: 'error',
          message: 'Tente mais tarde.',
        });
        history.push(routeMap.pointApprovement);
        refresh();
      }
    }
  };

  return (
    <div className="approver-actions">
      <div
        className="approver-actions--info"
        data-hide={
          point.approval_status_name === 'Aprovado' ||
          point.approval_status_name === 'Publicado' ||
          point.approval_status_name === 'Aguardando distribuição'
        }
      >
        <strong>Aguardando aprovação:</strong>
        <span>{name}</span>
      </div>
      <div
        className="approver-actions--btns"
        data-hide={
          point.approval_status_name === 'Aprovado' ||
          point.approval_status_name === 'Publicado' ||
          (name && name === 'CRM' && participant.profile_value === 'GRM') ||
          point.approval_status_name === 'Aguardando distribuição'
        }
      >
        <Button
          type="button"
          buttonRole="quaternary"
          disabled={action !== null}
          loading={action === 'is_approving'}
          onClick={approve}
        >
          APROVAR
        </Button>
        <Button
          type="button"
          buttonRole="quaternary"
          disabled={action !== null}
          loading={action === 'is_reproving'}
          onClick={() => setReproveModalOpen(true)}
        >
          REPROVAR
        </Button>
        <ReproveModal
          isOpen={reproveOpen}
          loading={action === 'is_reproving'}
          onCancel={() => setReproveModalOpen(false)}
          onConfirm={reprove}
        />
      </div>
    </div>
  );
};
