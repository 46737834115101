import { vendavallApi } from 'services/api';
import { FaqResponse } from './interfaces';

export default async (
  filter: string = '',
  page: number = 1,
  limit: number = 10
): Promise<FaqResponse> => {
  try {
    const { data } = await vendavallApi.get<FaqResponse>('faq', {
      params: {
        filter,
        page,
        limit,
      },
    });
    return data;
  } catch {
    return { data: [], pagination: { page_total: 0, current_page: 1, last_page: 1, per_page: limit, total: 0 } };
  }
};
