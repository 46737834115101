import styled from 'styled-components';
import BaseSelect from 'components/shared/Select/BaseSelect';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4em;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 14px;
`;

export const Item = styled.div`
  margin-bottom: 5px;

  strong {
    margin-right: 5px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin: 25px 0;
`;

export const Select = styled(BaseSelect)`
  ._inputContainer {
    margin: 5px 0 10px;
  }
`;
