/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setValueAnswer } from 'state/modules/surveys/actions';
import { Answer as AnswerData } from 'components/Surveys/types';
import setFetchedAnswers from 'components/Surveys/utils/setFetchedAnswers';

import {
  Container,
  RadioContent,
  InputGroup,
  InputText,
  InputRadio,
  InputItem,
  InputLabel,
  LabelWrapper,
} from './styles';

interface Props {
  question: string;
  answers: AnswerData[];
  id: number;
  canEdit: boolean;
}

const InputRadios: React.FC<Props> = ({ question, answers, id, canEdit }) => {
  const dispatch = useDispatch();
  const [freeAnswerValue, setFreeAnswerValue] = useState('');

  const changeFreeAnswerHandler = useCallback(
    (
      answerValue: string,
      questionId: number,
      answerId: number,
      freeAnswer: boolean,
    ) => {
      dispatch(
        setValueAnswer({
          answerValue: !freeAnswer ? answerValue : freeAnswerValue,
          questionId,
          answerId,
          multipleAnswers: 'unique',
        }),
      );
    },
    [dispatch, freeAnswerValue],
  );

  useEffect(() => {
    if (canEdit) {
      setFetchedAnswers(answers, id, dispatch, { multipleAnswers: 'unique' });
    }
  }, [answers, canEdit, dispatch, id]);

  return (
    <Container>
      <RadioContent>
        <p>{question}</p>
        <InputGroup>
          {answers.map(
            ({
              id: answerId,
              survey_question_id,
              picture,
              answer,
              survey_participant_answers,
              free_answer,
            }) => (
              <InputItem key={`${answerId}-${survey_question_id}`}>
                <InputLabel>
                  {picture && (
                    <img
                      src={picture}
                      alt={answer}
                      style={{ width: '75px', height: '50px' }}
                    />
                  )}
                  <InputRadio
                    type="radio"
                    id={answer}
                    value={answer}
                    name={`${survey_question_id}`}
                    onChange={e => {
                      changeFreeAnswerHandler(
                        e.target.value,
                        id,
                        answerId,
                        free_answer,
                      );
                    }}
                    defaultChecked={survey_participant_answers.some(
                      item => item.survey_question_answer_id === answerId,
                    )}
                    disabled={!canEdit}
                  />
                </InputLabel>

                <LabelWrapper>
                  <span>{answer}</span>

                  {free_answer && (
                    <InputText
                      type="text"
                      // placeholder={answer}
                      onChange={e => setFreeAnswerValue(e.target.value)}
                      onBlur={e => {
                        changeFreeAnswerHandler(
                          e.target.value,
                          id,
                          answerId,
                          free_answer,
                        );
                      }}
                      defaultValue={
                        survey_participant_answers.length > 0
                          ? survey_participant_answers[0].answer
                          : ''
                      }
                      disabled={!canEdit}
                    />
                  )}
                </LabelWrapper>
              </InputItem>
            ),
          )}
        </InputGroup>
      </RadioContent>
    </Container>
  );
};

export default InputRadios;
