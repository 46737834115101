import { pluginApi } from 'services/api';
import { ApiAction } from 'services/strategic-plans/actions/interfaces';

export default async (
  strategyPlanId: number,
  url: string,
): Promise<ApiAction[]> => {
  const { data } = await pluginApi.get<ApiAction[]>(`${url}/${strategyPlanId}`);
  return data;
};
