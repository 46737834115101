import { Reducer } from 'redux';

import { FetchState } from '@types';
import { emptyFetchState, fetchingState, fetchErrorState } from 'state/utils';
import { CommonActions } from './actions';
import {
  FETCH_ESTABLISHMENTS_ACTION,
  FETCH_ESTABLISHMENTS_FAILURE,
  FETCH_ESTABLISHMENTS_SUCCESS,
} from './constants';
import { Establishment } from './types';

export type CommonState = {
  fetchEstablishments: FetchState;
  establishments: Establishment[] | null;
};

export const initialState: CommonState = {
  fetchEstablishments: emptyFetchState,
  establishments: null,
};

const commonReducer: Reducer<CommonState, CommonActions> = (
  state = initialState,
  action: CommonActions,
): CommonState => {
  switch (action.type) {
    case FETCH_ESTABLISHMENTS_ACTION:
      return { ...state, fetchEstablishments: fetchingState };
    case FETCH_ESTABLISHMENTS_FAILURE:
      return {
        ...state,
        fetchEstablishments: fetchErrorState(action),
      };
    case FETCH_ESTABLISHMENTS_SUCCESS:
      return {
        ...state,
        fetchEstablishments: emptyFetchState,
        establishments: action.payload.establishments,
      };
    default:
      return state;
  }
};

export default commonReducer;
