import {
  ApproverProfile as IApproverProfile,
  EstablishmentTypes,
} from 'config/constants';
import { ApproverApi } from './CampaignApi';

export const CAMPAIGN_STATUS = {
  NEW_REQUEST_STATUS: 4,
  DEVELOPMENT_STATUS: 5,
  UNDER_ANALYSIS_STATUS: 2,
  WAITING_FOR_APPROVAL_STATUS: 3,
  APPROVED_FOR_PUBLICATION_STATUS: 7,
  PUBLISHED_STATUS: 1,
  FINISHED_STATUS: 9,
  CANCELED_STATUS: 0,
};

export const CAMPAIGN_STATUS_TEXT = {
  NEW_REQUEST_STATUS: 'new_request',
  UNDER_ANALYSIS_STATUS: 'under_analysis',
  DEVELOPMENT_STATUS: 'development',
  WAITING_FOR_APPROVAL_STATUS: 'waiting_for_approval',
  APPROVED_FOR_PUBLICATION_STATUS: 'approved_for_publication',
  PUBLISHED_STATUS: 'published',
  FINISHED_STATUS: 'completed',
  CANCELED_STATUS: 'canceled',
};

export interface EstablishmentApi {
  id: number;
  name: string;
  status: number;
  cnpj: string;
  street: string;
  number: string;
  zip_code: string;
  country: string;
  complement: string;
  created: Date;
  establishment_type_id: number;
  establishment_id: number;
  code: string;
  city: string;
  state: string;
  state_code: string;
  picture: string;
  fantasy_name: string;
  district: string;
  cell_phone: string;
  email: string;
  phone: string;
  activation_date: Date;
  focal_point_participant_id: number;
  client_code: string;
  client_group: string;
  culture: string;
  category: string;
  regional_id: number;
  type: {
    id: number;
    name: EstablishmentTypes;
  };
  balance: number;
}

export type StatusText =
  | typeof CAMPAIGN_STATUS_TEXT.NEW_REQUEST_STATUS
  | typeof CAMPAIGN_STATUS_TEXT.DEVELOPMENT_STATUS
  | typeof CAMPAIGN_STATUS_TEXT.PUBLISHED_STATUS
  | typeof CAMPAIGN_STATUS_TEXT.CANCELED_STATUS
  | typeof CAMPAIGN_STATUS_TEXT.WAITING_FOR_APPROVAL_STATUS
  | typeof CAMPAIGN_STATUS_TEXT.FINISHED_STATUS;

export type Status =
  | typeof CAMPAIGN_STATUS.CANCELED_STATUS
  | typeof CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS
  | typeof CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS
  | typeof CAMPAIGN_STATUS.DEVELOPMENT_STATUS
  | typeof CAMPAIGN_STATUS.PUBLISHED_STATUS
  | typeof CAMPAIGN_STATUS.NEW_REQUEST_STATUS;

export interface CampaignStatus {
  id: Status;
  name: string;
  statusText: StatusText;
}

export interface Mechanic {
  id: number;
  name: string;
  homeImage?: string;
  internalImage?: string;
  emailImage?: string;
  campaignListImage?: string;
  materialLink?: string;
  description?: string;
  created?: string;
}

export interface Culture {
  id: number;
  name: string;
}

export interface Regional {
  id: number;
  name: string;
  code: string;
  directorship: string;
  upnDv: string;
  upnDvParticipantId: number;
  upnGrv: string;
  upnGrvParticipantId: number;
  generatedAt: Date;
  created: Date;
}

export interface Director {
  id: number;
  name: string;
  code: string;
  directorship: string;
  upnDv: string;
  upnDvParticipantId: number;
  upnGrv: string;
  upnGrvParticipantId: number;
  generatedAt: Date;
  created: Date;
}

export interface Customer {
  id: number;
  name: string;
  cnpj?: string;
  type?: EstablishmentTypes;
}

export interface Audience {
  customer: Customer;
  balance: number;
}

export interface Category {
  id: string;
  name: string;
}

export interface Product {
  id: number;
  name: string;
}

export interface Prize {
  name: string;
  description: string;
}

export interface Goal {
  product?: Product;
  expectedVolume: number;
  expectedSellIn: number;
  expectedSellOut: number;
  expectedPortfolioPog: number;
}

export type ApproverProfile = IApproverProfile;
export type ApprovalStatus = 'approved' | 'disapproved' | 'pending';

export interface Approver {
  id?: number;
  profile?: ApproverProfile;
  status?: ApprovalStatus;
  comments?: string[];
  name?: string;
  created: string;
  type?: string;
  status_campaign: number;
}

export interface Comment {
  message: string;
}

export interface Highlight {
  id: number | null;
  status: boolean;
}

export interface Campaign {
  id: number;
  participant_id: number;
  participantName: string;
  campaign_id: number;
  title: string;
  description: string;
  mechanic: Mechanic;
  culture: Culture[];
  audience: Audience[];
  startDate: Date | null;
  endDate: Date | null;
  prize: Prize;
  affordPoints: number;
  checkRegulation: boolean;
  complementaryAffordPoints: number;
  complementaryLocalBudget: number;
  complementaryCrmBudget: number;
  expectedSellIn: number;
  expectedSellOut: number;
  goals: Goal[];
  observation: string;
  status: CampaignStatus;
  createdAt: string | null;
  goal: string;
  rules_participation: string;
  summary: string;
  approvers?: ApproverApi[];
  comments: Comment[];
  highlight: Highlight;
  sendEmail: boolean;
  profileTurnToApprove?: ApproverProfile[] | null;
  communicationKit: string;
  stock: string;
  approver_in_turn?: string;
}
