import React from 'react';

import CompleteRegistration from 'components/CompleteRegister/IndirectCompleteRegister';

import { Container } from './styles';

const CompleteIndirectRegistration: React.FC = () => {
  return (
    <Container>
      <CompleteRegistration />
    </Container>
  );
};

export default CompleteIndirectRegistration;
