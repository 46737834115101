import React, { useState } from 'react';

import { Participant } from 'services/auth/interfaces/Participant';
import { PROFILES } from 'config/constants';
import { Option } from 'components/shared/Select';
import { formatDate } from 'util/datetime';
import UfSelectNaturalidade from '../Selects/UfNaturalnessSelect';
import AddressFields from './AddressFields';
import {
  Title,
  Input,
  Separator,
  MaritalStatusSelect,
  GenderSelect,
  UfSelectRG,
  SelectNaturalidade,
  SchoolingSelect,
} from './styles';

interface Props {
  inputRole: 'primary' | 'secondary';
  participant: Participant;
  autoIndicate: boolean;
  setAutoIndicate(checked: boolean): void;
  completeRegister?: boolean;
}

const ExtraFieldsForParticipant: React.FC<Props> = ({
  inputRole,
  participant,
  autoIndicate,
}) => {
  const [selectedUfOption, setSelectedOption] = useState<Option | null>(null);

  return (
    <>
      {participant.profile !== PROFILES.producer && (
        <>
          <Separator />
          <Title>
            Para poder resgatar os seus prêmios, é necessário que você preencha
            todos os campos abaixo
          </Title>
        </>
      )}

      {autoIndicate || participant.profile !== PROFILES.producer ? (
        <>
          <Input
            name="mother_name"
            label="Nome da Mãe"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />
          <Input
            name="father_name"
            label="Nome do Pai"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          {participant.profile !== PROFILES.producer && (
            <Input
              name="formatted_birth_date"
              label="Data de nascimento (idade mínima: 18 anos)"
              inputRole={inputRole}
              pattern="XX/XX/XXXX"
              disabled={
                participant.esocial_valid ||
                participant.esocial_validation_status === 'Aguardando aprovação'
              }
              numbersOnly
              defaultValue={
                participant?.birth_date && formatDate(participant.birth_date)
              }
            />
          )}

          <GenderSelect
            name="gender_select"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <UfSelectNaturalidade
            inputRole={inputRole}
            setValue={setSelectedOption}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <SelectNaturalidade
            inputRole={inputRole}
            idState={selectedUfOption ? selectedUfOption.title : null}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <Input
            name="nationality"
            label="Nacionalidade"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <MaritalStatusSelect
            name="marital_status_code_select"
            label="Estado civil "
            inputRole={inputRole}
          />

          <SchoolingSelect
            name="code_schooling_select"
            label="Grau de instrução"
            inputRole={inputRole}
          />

          <Input
            name="rg"
            label="RG"
            inputRole={inputRole}
            maxLength={13}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <Input
            name="rg_emitter"
            label="Órgão emissor"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <UfSelectRG
            name="rg_emitter_uf_select"
            label="UF do Órgão Emissor"
            inputRole={inputRole}
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <Input
            name="formatted_shipping_date"
            label="Data de Emissão"
            inputRole={inputRole}
            numbersOnly
            pattern="XX/XX/XXXX"
            defaultValue={
              participant?.shipping_date &&
              participant?.shipping_date?.replace(
                /(\d{4})-(\d{2})-(\d{2}).*/,
                '$3/$2/$1',
              )
            }
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <Input
            name="voter_registration"
            label="Título de Eleitor"
            inputRole={inputRole}
            maxLength={12}
            numbersOnly
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />

          <Input
            name="pis_nis"
            label="N° inscrição na Previdência Social (PIS ou NIS)"
            inputRole={inputRole}
            maxLength={11}
            numbersOnly
            disabled={
              participant.esocial_valid ||
              participant.esocial_validation_status === 'Aguardando aprovação'
            }
          />
          <AddressFields inputRole={inputRole} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExtraFieldsForParticipant;
