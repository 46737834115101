import React from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import {
  Button,
  Close,
  Container,
  Content,
  ContentsButtons,
  Modal,
} from './styles';

interface RequestApprovalModalProps {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

const RequestApproval: React.FC<RequestApprovalModalProps> = ({
  onClose,
  onConfirm,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Container>
        <Close>
          <button type="button">
            <ReactSVG src={closeIcon} onClick={() => onClose()} />
          </button>
        </Close>
        <Content>
          <h1>
            Tem certeza de que esta campanha está pronta para ser enviada para
            aprovação?
          </h1>
          <h2>
            Enquanto estiver com status &quot;Em aprovação&quot;, você <br />{' '}
            não poderá fazer nenhuma alteração.
          </h2>
        </Content>
        <ContentsButtons>
          <Button type="button" className="cancel" onClick={() => onClose()}>
            CANCELAR
          </Button>
          <Button
            className="register"
            type="button"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            SIM, TENHO CERTEZA
          </Button>
        </ContentsButtons>
      </Container>
    </Modal>
  );
};

export default RequestApproval;
