import { pluginApi } from 'services/api';
import { ExtractCampaign } from './interfaces';

export default async (): Promise<ExtractCampaign[]> => {
  try {
    const { data } = await pluginApi.get<ExtractCampaign[]>(
      'participants/extrato-fmc-coins/campaigns',
    );
    return data;
  } catch {
    return [];
  }
};
