import React from 'react';
import history from 'services/history';
import routeMap from 'routes/route-map';
import { Button } from 'components/shared';

import { useAuth } from 'context/AuthContext';
import { Container, Content, Information, ContentButton } from './styles';

const Resume: React.FC = () => {
  const { participant } = useAuth();
  const viewProfile =
    participant.profile_value !== 'GRV' && participant.profile_value !== 'GC';

  return (
    <Container>
      {viewProfile ? (
        <Content>
          <Information>
            <h1>Campanhas aceleradoras</h1>
            <span>
              Solicite aqui as campanhas aceleradoras de seus clientes. Depois
              basta acompanhar <br /> na lista abaixo a atualização do status de
              sua campanha.
            </span>
          </Information>
          <ContentButton>
            <Button
              buttonRole="primary"
              type="button"
              onClick={() =>
                history.push(routeMap.campaignManagement.newCampaign)
              }
            >
              NOVA CAMPANHA
            </Button>
          </ContentButton>
        </Content>
      ) : (
        <Content>
          <Information>
            <h1>Campanhas aceleradoras</h1>
            <span>
              Solicite aqui as campanhas aceleradoras de seus clientes. Depois
              basta acompanhar <br /> na lista abaixo a atualização do status de
              sua campanha.
            </span>
          </Information>
        </Content>
      )}
    </Container>
  );
};

export default Resume;
