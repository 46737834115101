import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  padding: 20px 25px;

  .head {
    display: flex;
    justify-content: space-between;

    h1 {
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      font-size: 1.3em;
    }

    a {
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
    }
  }
`;

export const TeamLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  padding: 20px 0;
  gap: 25px;
`;

export const TeamLoading = styled.div`
  display: flex;
  place-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 25px 10px;
  width: 100%;

  span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 14px;
  }
`;

export const TeamHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  h3 {
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  .btn--filter {
    all: unset;
    text-decoration: underline;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    color: ${({ theme }) => theme.font.color.primary};
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    display: flex;
    align-items: center;

    &:active {
      color: #e63027;
    }
  }
`;

export const TeamBlock = styled.div`
  margin-top: 35px;
`;

export const TeamBlockRole = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 2px solid ${({ theme }) => theme.font.color.primary};

  strong {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const TeamBlockCards = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 15px 0;
`;

export const TeamDistributionResume = styled.div`
  animation: ${fadeIn} 250ms ease-out;
  border: 1px solid ${({ theme }) => theme.font.color.primary};
  border-radius: 8px;
  padding: 25px 20px;
  height: fit-content;
  position: sticky;
  top: 25px;
  max-height: 90vh;
  overflow-y: auto;

  button {
    height: 3.1rem;
    font-size: 0.9rem;
    position: relative;
    top: -1rem;
  }

  btn-lote {
    color: #000;
    cursor: pointer;
    display: block;
    text-align: end;
    margin-top: 0.5rem;
    text-decoration: underline;
  }

  input {
    width: 100%;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 1rem;
    color: ${({ theme }) => theme.font.color.primary};
    text-align: center;
    background-color: #efefef;
    outline: none;
    border: 1px solid ${({ theme }) => theme.font.color.primary};
    height: 55px;
    border-radius: 6px;
    margin-top: 1em;

    &::placeholder {
      color: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const TeamDistributionResumePoints = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.font.color.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: center;

  span {
    font-size: 13px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
  }

  strong {
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const TeamDistributedPoints = styled.div`
  margin: 15px 0;

  h3 {
    position: relative;
    top: -1rem;
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const TeamDistributedResume = styled.div`
  margin-top: 5px;
  background-color: #193b4e15;
  padding: 10px;
  border-radius: 8px;
  display: grid;
  gap: 5px;
  position: relative;
  top: -1rem;

  .empty {
    padding: 12px 0;
    color: #000;
    opacity: 0.4;
    text-align: center;
    font-size: 12px;

  }

  > div:not(.empty) {
    color: #3c3c3c;
    font-size: 12px;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    strong {
      font-family: ${({ theme }) => theme.font.fontFamily.medium};
    }
  }
`;

export const TeamDistributionFinishOrSave = styled.div`
  display: grid;

  .btn--save {
    margin: 0;
    color: #fff;
    background-color: #e63027;

    &:hover {
      background-color: #e63027;
    }
  }

  .btn--rmv-attrs {
    all: unset;
    position: relative;
    top: -1.5rem;
    margin: 30px 0px 0px 0px;
    width: 100%;
    text-decoration: underline;
    font-family: ${({ theme }) => theme.font.fontFamily.medium};
    color: ${({ theme }) => theme.font.color.primary};
    cursor: pointer;
    text-align: center;
    font-size: 14px;

    &:active {
      color: #e63027;
    }
  }
`;
