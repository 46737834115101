import {
  FmcProductsApi,
  ProductsFilterData,
} from 'services/fmc-coins-simulator/interfaces';
import { FmcProducts } from './interfaces';

interface Pagination {
  page_total: number;
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

interface ApiResponse {
  data: FmcProductsApi[];
  pagination: Pagination;
}

const fmcProductsApiToFmcProducts = (products: ApiResponse[]): FmcProducts[] =>
  products.flatMap(productsToInput =>
    productsToInput.data.map(product => ({
      id: product.id,
      checked: false,
      name: product.name,
      productTypeId: product.product_type_id,
      picture: product.picture,
      externalLink: product.external_link,
      description: product.description,
      file: product.file,
      status: product.status,
      created: product.created,
      modified: product.modified,
      category: product.category,
      pagination: productsToInput.pagination,
      conversion_factor: product.conversion_factor,
      valueToConversion: 0,
      valueToShow: '0',
      fmcCoins: 0,
    })),
  );

const fmcProductsApi = (products: ApiResponse[]): FmcProductsApi[] =>
  products.flatMap(productsToInput =>
    productsToInput.data.map(product => ({
      id: product.id,
      name: product.name,
      product_type_id: product.product_type_id,
      picture: product.picture,
      external_link: product.external_link,
      description: product.description,
      file: product.file,
      status: product.status,
      created: product.created,
      modified: product.modified,
      category: product.category,
      conversion_factor: product.conversion_factor,
    })),
  );

const productsFilter = (products: ApiResponse[]): ProductsFilterData[] =>
  products.flatMap(filterProducts =>
    filterProducts.data.map(product => ({
      id: product.id,
      name: product.name,
      product_type_id: product.product_type_id,
      picture: product.picture,
      external_link: product.external_link,
      description: product.external_link,
      file: product.file,
      status: product.status,
      created: product.created,
      modified: product.modified,
    })),
  );
export { fmcProductsApiToFmcProducts, fmcProductsApi, productsFilter };
