import { EstablishmentTypes } from 'config/constants';
import { pluginApi } from 'services/api';

export interface Establishment {
  id: number;
  client_group: string;
  type: {
    name: EstablishmentTypes;
  };
}

interface Response {
  establishments: Establishment[];
}

export async function getEstablishmentList() {
  const { data } = await pluginApi.get<Response>('participants/establishments');

  return data.establishments;
}
