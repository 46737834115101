/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useState, useEffect } from 'react';

import {
  Statistics as IStatistics,
  Performance as IPerformance,
  Points as IPoints,
  InvestmentGroup,
} from 'services/cockpit/interfaces/channel';
import getChannelStatistics from 'services/cockpit/getChannelStatistics';
import getChannelPerformance from 'services/cockpit/getChannelPerformance';
import getChannelPoints from 'services/cockpit/getChannelPoints';
import getEstablishmentsInvestmentGroup from 'services/cockpit/getEstablishmentsInvestmentGroup';
import { pluginApi } from 'services/api';
import Filters from './Filters';

import Statistics from './Statistics';
import Performance from './Performance';
import Points from './Points';
import Biological from './Biological';

import { Container, Line } from './styles';
import { useCockpitContext } from '../CockpitContext';

export type FilterArgs = {
  establishmentId?: number;
  campaignId?: number;
  investmentGroups?: string;
};

const ChannelView: React.FC = () => {
  const [statistics, setStatistics] = useState<IStatistics | null>(null);
  const [getSegments, setGetSegment] = useState<any>();
  const [performance, setPerformance] = useState<IPerformance | null>(null);
  const [points, setPoints] = useState<IPoints | null>(null);
  const [biological, setBiological] = useState<IPerformance | null>(null);
  const [investmentGroup, setInvestmentGroup] = useState<InvestmentGroup[]>([]);
  const { terraChannels, indirectChannels } = useCockpitContext();

  const getSegmentByEstablishment = async ({
    establishmentId,
    campaignId,
  }: any) => {
    try {
      const { data } = await pluginApi.get(
        `/goals/product-segment?campaign_id=${campaignId}&establishment_id=${establishmentId}`,
      );
      return data;
    } catch (error) {
      console.error('Erro ao obter os dados do segment:', error);
      throw error;
    }
  };

  const getSegmentByName = async ({ investmentGroups, campaignId }: any) => {
    try {
      const { data } = await pluginApi.get(
        `/goals/product-segment?campaign_id=${campaignId}&investiment_groups=${investmentGroups}`,
      );
      return data;
    } catch (error) {
      console.error('Erro ao obter os dados do segment:', error);
      throw error;
    }
  };

  const onFilter = useCallback(
    async ({
      establishmentId,
      campaignId,
      investmentGroups,
    }: FilterArgs): Promise<void> => {
      if (establishmentId !== undefined && campaignId !== undefined) {
        getChannelStatistics(establishmentId, campaignId).then(data =>
          setStatistics(data || null),
        );
        getChannelPoints(establishmentId, campaignId).then(data =>
          setPoints(data || null),
        );
        const segmentData = await getSegmentByEstablishment({
          establishmentId,
          campaignId,
        });
        setGetSegment(segmentData);
      }

      if (establishmentId === undefined) {
        const segmentData = await getSegmentByName({
          campaignId,
          investmentGroups
        });
        setGetSegment(segmentData);
      }

      getChannelPerformance({
        establishmentId,
        campaignId,
        investmentGroups,
      }).then(data => {
        if (campaignId === 11) {
          delete data.additionalMargin;
        }
        setPerformance(data || null);
        setBiological(data || null);
      });
    },
    [],
  );

  const clear = useCallback((): void => {
    setStatistics(null);
    setPerformance(null);
    setPoints(null);
  }, []);
  useEffect(() => {
    getEstablishmentsInvestmentGroup().then(response =>
      setInvestmentGroup(response),
    );
  }, []);

  if (statistics == null) {
    const otherValue = {
      establishment: {
        name: '',
        category: '',
        type: '',
        status: '',
        campaign: '',
      },
      participants: {
        active: 0,
        precharge: 0,
        formatedActive: '',
        formatedPrecharge: '',
      },
      fmcSharedActions: {
        balance: 0,
        formattedBalance: '',
      },
    };
    setStatistics(otherValue);
  }


  return (
    <Container>
      <Line>
        <div>
          <h3>Visão por canal</h3>
          <Filters
            onFilter={onFilter}
            clear={clear}
            NewMenu
            hasTerraChannels={terraChannels}
            hasIndirectChannels={indirectChannels}
          />
        </div>

        {investmentGroup.length > 0 && (
          <div>
            <h3>Visão por grupo de investimento</h3>

            <Filters
              onFilter={onFilter}
              clear={clear}
              NewMenu={false}
              investmentGroupCampaign={investmentGroup}
            />
          </div>
        )}
      </Line>
      {statistics && <Statistics statistics={statistics} />}
      {performance && statistics && (
        <Performance
          getSegments={getSegments}
          performance={performance}
          statistics={statistics}
        />
      )}
      {biological && <Biological biological={biological} />}
      {points && (
        <Points
          points={points}
          establishmentType={statistics?.establishment.type || ''}
        />
      )}
    </Container>
  );
};

export default ChannelView;
