import styled from 'styled-components';
import { animated } from 'react-spring';
import { Button as DefaultButton } from 'components/shared';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;

  > img {
    width: 260px;
    margin: 15px 0;
  }
`;

export const Content = styled(animated.div)`
  max-width: 720px;
  width: 100%;
  background: #fff;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const BannerCadastro = styled.div`
  max-height: 520px;
  position: relative;

  svg {
    max-width: 680px;
    width: 100%;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 360px;
  height: 40px;
  position: absolute;
  bottom: 75px;
  right: 3rem;

  @media screen and (max-width: 720px) {
    margin-bottom: 5rem;
    width: 170px;
    right: 1rem;
    height: 32px;
  }

  @media screen and (max-width: 420px) {
    margin-bottom: 5rem;
    width: 170px;
    right: 1rem;
    height: 32px;
  }
`;
