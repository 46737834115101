import React from 'react';
import { Comment } from 'services/strategic-plans/interfaces';

import NoResults from 'components/shared/NoResults';
import { formatDate } from 'util/datetime';
import { Container, MessageBox, Content } from './styles';

interface CommentsListProps {
  comments: Comment[];
}

const CommentsList: React.FC<CommentsListProps> = ({ comments }) => {
  return (
    <Container>
      {comments.length === 0 && <NoResults text="Não há comentários" />}
      {comments.map(({ comment, participantName, dateTime }) => (
        <Content>
          <MessageBox>
            <span>
              {`${formatDate(
                dateTime,
                'dd/MM/yyyy hh:mm:ss',
              )} - ${participantName}`}
            </span>
            <p>{comment}</p>
          </MessageBox>
        </Content>
      ))}
    </Container>
  );
};

export default CommentsList;
