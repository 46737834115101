import React, { useState } from 'react';

import { Option } from 'components/shared/Select';
// import { useAuth } from 'context/AuthContext';
import OpenTrainingsGrid from './OpenTrainingsGrid';
import FinishedTrainingsTable from './FinishedTrainingsTable';

import { Container, Content, CategoriesSelect, Box } from './styles';

const Training: React.FC = () => {
  const [categorySelected, setCategorySelected] = useState<Option | null>(null);
  // const { participant } = useAuth();
  // Inicio Tampáo
  // const perfil = participant.establishment.type_name;
  // const checking = ['Revenda', 'Produtor', 'Equipe', 'Cooperativa'];

  // useEffect(() => {
  //   if (
  //     perfil === checking[0] ||
  //     perfil === checking[1] ||
  //     perfil === checking[2] ||
  //     perfil === checking[3]
  //   ){
  //     window.location.href = "https://juntosfmc.vendavall.com.br/home";
  //   }
  // });
  // Fim Tampáo

  return (
    <Container>
      <Content>
        <h3>Treinamentos</h3>
        <CategoriesSelect
          value={categorySelected}
          setValue={value => setCategorySelected(value)}
          label="Treinamentos disponíveis"
        />
        <OpenTrainingsGrid
          categoryId={
            categorySelected?.value ? parseInt(categorySelected.value, 0) : null
          }
        />
        <Box>
          <h3>Meus treinamentos</h3>
          <FinishedTrainingsTable />
        </Box>
      </Content>
    </Container>
  );
};

export default Training;
