import React, { useContext, createContext, useCallback, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

/* import { ToastContainer, toast } from 'react-toastify'; */

import Test from 'components/Modals/SuccessMessage';

export interface ToastMessage {
  type?: 'error' | 'success' | 'info';
  title: string;
  subtitle?: string;
}

interface Options {
  onClose: () => void;
}

interface ToastContextData {
  addToastConfirm(message: ToastMessage, options?: Options): void;
}

const ToastProviderConfirmContext = createContext<ToastContextData>(
  {} as ToastContextData,
);

const ToastProviderConfirm: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [subtitle, setSubtitle] = useState<string>();
  const [options, setOptions] = useState<Options | null>(null);

  const addToastConfirm = useCallback(
    (message: ToastMessage, opts?: Options) => {
      setText(message.title);
      setSubtitle(message.subtitle);
      setIsOpen(true);
      if (opts) {
        setOptions(opts);
      } else {
        // Clear for other toasts
        setOptions(null);
      }
    },
    [],
  );

  const handleRequestClose = () => {
    setIsOpen(false);

    if (options) {
      options?.onClose();
    }
  };

  return (
    <ToastProviderConfirmContext.Provider value={{ addToastConfirm }}>
      {children}
      <Test
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        text={text}
        subitle={subtitle}
        className="_modal-toast"
      />
    </ToastProviderConfirmContext.Provider>
  );
};

const useToastConfirm = (): ToastContextData => {
  const context = useContext(ToastProviderConfirmContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};

export { ToastProviderConfirm, useToastConfirm };
