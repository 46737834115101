import styled, { css, keyframes } from 'styled-components';
import { FONTS } from 'styles/font/globals';
import { ApprovationStatus } from 'services/point-management/dtos/point';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div<{
  status: number;
}>`
  --color-approved: #437e63;
  --color-waiting-approvation: #821c0e;
  --color-reproved: #e4001b;
  --color-published: ${({ theme }) => theme.font.color.primary};

  background-color: #efefef;
  animation: ${fadeIn} 300ms ease-out;
  padding: 12px 20px;
  border-left: 2px solid transparent;

  .field--input input {
    display: block;
    margin-left: auto;
    position: relative;
    top: -2rem;
  }

  svg {
    cursor: pointer;
  }


  ${props =>
    props.status === ApprovationStatus.APROVADO &&
    css`
      border-color: var(--color-approved);

      svg {
        color: var(--color-approved);
      }
    `}

    ${props =>
      (props.status === ApprovationStatus.AGUARDANDO_APROVACAO ||
        props.status === ApprovationStatus.AGUARDANDO_DISTRIBUICAO) &&
      css`
        border-color: var(--color-waiting-approvation);

        svg {
          color: var(--color-waiting-approvation);
        }
      `}

  ${props =>
    props.status === ApprovationStatus.PUBLICADO &&
    css`
      border-color: var(--color-published);

      svg {
        color: var(--color-published);
      }
    `}

  ${props =>
    props.status === ApprovationStatus.REPROVADO &&
    css`
      border-color: var(--color-reproved);

      svg {
        color: var(--color-reproved);
      }
    `}


    .container-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .details {
        color: #707070;
        font-size: 12px;
        cursor: pointer;
        border: none;
        text-decoration: underline;
      }
    }

  .tooltip-text {
    &.status--${ApprovationStatus.APROVADO} {
      color: var(--color-approved);
    }
    &.status--${ApprovationStatus.AGUARDANDO_DISTRIBUICAO} {
      color: var(--color-waiting-approvation);
    }
    &.status--${ApprovationStatus.AGUARDANDO_APROVACAO} {
      color: var(--color-waiting-approvation);
    }
    &.status--${ApprovationStatus.PUBLICADO} {
      color: var(--color-published);
    }
    &.status--${ApprovationStatus.REPROVADO} {
      color: var(--color-reproved);
    }
  }

  .open--content {
    margin-top: 20px;

    .report--link {
      margin-bottom: 25px;

      a {
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
      }

      button {
        all: unset;
        text-decoration: underline;
        cursor: pointer;
        color: #000;
        font-family: ${FONTS.medium};
      }
    }

    .field-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      // grid-gap: 15px;

      .form--actions {
        margin-top: 15px;
        display: flex;
        gap: 10px;

        button {
          margin: 0;
          max-width: 200px;
          height: auto;
          padding: 12px 25px;
          font-size: 14px;

          &.save {
            background-color: #e63027;

            &:hover {
              background-color: #e63027;
            }
          }

          &.cancel {
            color: ${({ theme }) => theme.font.color.primary};
            border: 1px solid ${({ theme }) => theme.font.color.primary};
          }
        }
      }

      .field {
        display: grid;
        align-items: center;
        // grid-template-columns: max-content 1fr max-content minmax(
        //     max-content,
        //     150px
        //   );

        &[data-hide=true] {
          display: none;
        }

        &--title {
          grid-column: 1 / 2;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 3px;

          strong {
            color: ${({ theme }) => theme.font.color.primary};
            font-size: 16px;
          }

          span {
            color: #000;
            font-size: 12px;
          }

          @media screen and (max-width: 530px) {
            grid-column: unset;
          }
        }

        &--input {
          grid-column: 3 / 4;
          width: 100%;

          input {
            height: 35px;
            padding: 0px 10px;
            border-radius: 8px;
            border: 1px solid gray;
          }

          @media screen and (max-width: 530px) {
            grid-column: unset;
          }
        }

        .fill-grid-space {
          width: 100%;
        }

        @media screen and (max-width: 530px) {
          grid-template-columns: 1fr 1fr;
          gap: 10px;

          .fill-grid-space {
            display: none;
          }
        }
      }
    }

    .approver-actions {
      margin-top: 10px;
      padding: 8px 0 0 0;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 530px) {
        &--info {
          color: #000;
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 12px;
        }
      }
      &--info {
        color: #000;
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 12px;

        &[data-hide=true] {
          display: none;
        }
      }

      &--btns {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 10px;

        &[data-hide=true] {
          display: none;
        }

        button {
          margin: 0;
          width: 130px;
          height: auto;
          padding: 8px 20px;
          font-size: 12px;
          background-color: transparent;

          &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        /** Botão aprovar */
        button:first-child {
          color: var(--color-approved);
          border: 1px solid var(--color-approved);

          svg {
            fill:var(--color-approved);
          }
        }
        /** Botão reprovar */
        button:last-child {
          color: var(--color-reproved);
          border: 1px solid var(--color-reproved);

          svg {
            fill:var(--color-reproved);
          }
        }
      }
    }
  }

  .point {
    &-wrapper {
      display: grid;
      align-items: center;
      grid-template-columns: max-content 1fr max-content minmax(
          max-content,
          150px
        );

      @media screen and (max-width: 530px) {
        gap: 8px;
        grid-template-columns: unset;
        grid-template-areas: 'a c'
                             'b c';
        .point-name {
          grid-area: a;
          font-size: 16px;
        }

        .point-value {
          grid-area: b;
          font-size: 14px;
          opacity: 0.8;
        }

        .point-action {
          grid-area: c;
        }

        .point-divider {
          display: none;
        }
      }
    }

    &-name {
      display: flex;
      align-items: center;
      gap: 10px;
      color: ${({ theme }) => theme.font.color.primary};
      font-size: 18px;
    }

    &-value {
      font-size: 18px;
      color: ${({ theme }) => theme.font.color.primary};
    }

    &-action {
      width: 100%;
      padding-left: 25px;
      display: flex;
      gap: 10px;
      align-items: center;

      .comment-action {
        height: fit-content;
        padding: 10px 5px;
        border: none;
        text-decoration: underline;
      }

      .details-action {
        height: fit-content;
        padding: 10px 5px;
        border: none;
        text-decoration: underline;
      }

      button {
        white-space: nowrap;
        margin: 0;
        width: 100%;
        height: auto;
        padding: 12px 25px;
        text-transform: uppercase;
        font-size: 12px;

        @media screen and (max-width: 530px) {
          padding: 10px 20px;
          font-size: 10px;
        }
      }
    }

    &-divider {
      position: relative;
      height: 50%;

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: ${({ theme }) => theme.font.color.primary};
        opacity: 0.5;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }
`;
