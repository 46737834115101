import React, { useEffect, useState, useCallback } from 'react';
import BaseSelect, { Option, SelectProps } from 'components/shared/Select';
import { useFormContext } from 'react-hook-form';
import { Input } from 'components/Auth/Register/Form/PersonalDataForm/styles';

import getChannelsForSelect from 'services/register/getChannelsForSelect';
// release
interface ChannelSelectProps extends Omit<SelectProps, 'loadItems'> {
  className?: string;
  label?: string;
  placeholder?: string;
  inputRole: 'primary' | 'secondary';
  name: string;
}

const ChannelSelect: React.FC<ChannelSelectProps> = ({
  label,
  className,
  placeholder,
  inputRole,
  name,
  ...rest
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const { getValues, watch, reset } = useFormContext();

  useEffect(() => {
    const fetchChannels = async () => {
      const result = await getChannelsForSelect();
      const channels = result.map<Option>(({ client_group }) => ({
        value: client_group,
        title: client_group,
      }));

      setOptions([{ title: 'OUTROS', value: 'OUTROS' }, ...channels]);
    };

    fetchChannels();
  }, []);

  const loadItems = useCallback(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const selected = getValues(name);
    setSelectedValue(selected);
  }, [getValues, name, watch]);

  useEffect(() => {
    if (selectedValue && selectedValue.value !== 'OUTROS') {
      reset({ ...getValues(), other_channels: '' });
    }
  }, [getValues, reset, selectedValue]);

  return (
    <>
      <BaseSelect
        {...rest}
        name={name}
        label={label}
        loadItems={loadItems}
        className={className}
        inputRole={inputRole}
        placeholder={placeholder}
      />
      {selectedValue?.value === 'OUTROS' && (
        <Input name="other_channels" inputRole={inputRole} />
      )}
    </>
  );
};

export default ChannelSelect;
