import routeMap from 'routes/route-map';
import CampaignForm from 'pages/CampaignsManagement/Main';
import NewCampaign from 'pages/CampaignsManagement/NewCampaign';
import CampaignsList from 'pages/CampaignsManagement/List';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: routeMap.campaignManagement.newCampaign,
    component: NewCampaign,
    accessPage: 'Página de criação de uma nova campanha',
  },
  {
    path: routeMap.campaignManagement.acceleratedCampaigns,
    component: CampaignsList,
    accessPage: 'Lista de campanhas',
  },
  // {
  //   path: `${routeMap.campaignManagement.acceleratedCampaigns}/editar/:id`,
  //   component: CampaignForm,
  //   accessPage: 'Página de edição e gerenciamento de uma campanha',
  // },
  {
    path: `${routeMap.campaignManagement.acceleratedCampaigns}/:id`,
    component: CampaignForm,
    accessPage: 'Página de edição e gerenciamento de uma campanha',
  },
  {
    path: `${routeMap.campaignManagement.newCampaign}/:name`,
    component: CampaignForm,
    accessPage: 'Formulário de criação de campanha',
  },
];

export default routes;
