import React from 'react';
import Table from 'components/ProducerIndication/Table/index';
import Bainer from 'components/ProducerIndication/Bainer';
import { Container } from './styles';

const ProducerIndication: React.FC = () => {
  return (
    <Container>
      <Bainer />
      <Table />
    </Container>
  );
};

export default ProducerIndication;
