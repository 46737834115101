export enum RouletteStatus {
  READY,
  DRAWING,
  LOADING,
  DRAWED,
  NO_SPINS,
}

export type Award = {
  id: number;
  temp_id: string;
  title: string;
  value: number;
  slice_data: {
    /** 1 à 6 */
    index: number;
    /** Usado para identificar a posição na roleta, ex.: 45º */
    deg: number;
  };
};

/** Tipo de ação, com base nas configurações de roleta do vendavall */
export enum RouletteActionType {
  HOME = 2,
  MENU_ICON = 3,
  TRAININGS = 1,
}

/** Ação da roleta, com base nas configurações de roleta do vendavall */
export enum RouletteAction {
  PRODUTOR = 91,
}
