import { Indicator } from 'state/modules/points-simulator/interfaces';
import title from './title';
import revenuesPog from './revenues-pog';
import warn from './warn';

interface Props {
  revenues: Indicator;
  pog: Indicator;
}

export default (props: Props) => {
  const { revenues, pog } = props;

  return `
  ${title}

  ${revenuesPog({ revenues, pog })}

  ${warn}
`;
};
