import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 770px) {
    flex-direction: column;
  }
`;

export const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px;

  span {
    font-weight: 400;
  }

  button {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    margin: 10px 0;
  }
`;

export const Box = styled.div`
  margin-top: 5px;
  background: transparent linear-gradient(180deg, #95201a 0%, #e63027 100%) 0%
    0% no-repeat padding-box;
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 500px) {
    padding: 20px;
  }

  h2 {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 20px;
    color: #fff;

    @media screen and (max-width: 500px) {
      font-size: 18px;
    }
  }

  table {
    border-spacing: 0 8px;
    text-align: left;
    margin-top: 20px;
    width: 100%;

    thead tr {
      th {
        padding: 5px 0 5px 30px;
        font-size: 14px;

        @media screen and (max-width: 1100px) {
          padding: 5px 0 5px 20px;
        }

        @media screen and (max-width: 500px) {
          font-size: 12px;
          padding: 5px 0 5px 10px;
        }
      }
    }

    tbody tr {
      margin-bottom: 8px;
      background-color: #fff;

      td {
        padding: 10px 30px;
        font-size: 14px;
        color: ${({ theme }) => theme.font.color.primary};

        @media screen and (max-width: 1100px) {
          padding: 10px 20px;
        }

        @media screen and (max-width: 500px) {
          font-size: 12px;
          padding: 10px 10px;
        }
      }

      &:last-child {
        background-color: transparent;
        font-size: 14px;

        td {
          color: #fff;
          padding: 15px 0 0 0;

          b {
            font-family: ${({ theme }) => theme.font.fontFamily.bold};
          }
        }
      }
    }
  }
`;
