import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 0;
    width: 510px;
    max-width: 510px;
    height: 320px;
    max-height: 50vh;
  }
`;
export const Container = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Content = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > h3 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    color: ${({ theme }) => theme.font.color.primary};
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 80%;

    svg {
      margin-right: 12px;
      width: 40px;
      height: 40px;
    }
  }

  > textarea {
    width: 400px;
    border: 1px solid ${({ theme }) => theme.font.color.primary};
    height: 18vh;
    overflow-y: auto;
    resize: none;
  }

  > button {
    max-width: 185px;
    height: 43px;
    align-items: center;
    justify-content: center;
  }
`;
export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button {
    border: none;
    background: transparent;
  }
`;
