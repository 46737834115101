import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { pluginApi } from 'services/api';
import { Container, TableContent, Title } from './styles';

interface ParticipantData {
  id: number;
  name: string;
  created: string;
  status_description: string;
  status: number;
  productor: string;
}

const Table: React.FC = () => {
  const [participants, setParticipants] = useState(
    new Array<ParticipantData>(),
  );

  useEffect(() => {
    async function fetchParticipants() {
      const response = await pluginApi.get('participants/profile/referral');

      setParticipants(response.data.featured);
    }

    fetchParticipants();
  }, []);

  return (
    <Container>
      <Title>Participantes Cadastrados</Title>
      <span>
        Participantes que se cadastraram usando o seu código de indicação
      </span>

      <TableContent>
        <thead>
          <tr>
            <th>Nome do produtor</th>
            <th>Data de cadastro</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {participants &&
            participants.map(participant => (
              <tr key={participant.id}>
                <td>{participant.name}</td>
                <td>{format(parseISO(participant.created), 'dd/MM/yyyy')}</td>
                <td>{participant.status_description}</td>
              </tr>
            ))}
        </tbody>
      </TableContent>
    </Container>
  );
};

export default Table;
