import React, { useState, useEffect } from 'react';
import { getParticipantsToAccessPI } from 'services/showcase';
import { Participant } from 'services/showcase/interfaces';
import { useAuth } from 'context/AuthContext';
import { EstablishmentTypes, PROFILES } from 'config/constants';
import ShowValidation from 'components/Home/ShowValidation';

import FeatureProducts from './FeatureProducts';
import BoxParticipantInfo from './Box';

import { Container, Content, Separator } from './styles';

const ShowCase: React.FC = () => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const { participant: _participant } = useAuth();

  useEffect(() => {
    getParticipantsToAccessPI().then(data => {
      setParticipants(data);
    });
  }, []);

  const eSocialApproval = _participant.esocial_validation_status === 'Aprovado';
  const isFocalPoint = _participant.profile === PROFILES.focalPoint;
  const isNotTerraBrasil =
    _participant.establishment.type_name !== EstablishmentTypes.TerraBrasil;

  return (
    <Container>
      {eSocialApproval ? <h3>Catálogo de prêmios</h3> : <></>}
      {isFocalPoint && isNotTerraBrasil ? (
        <Content>
          <span>Selecione o catálogo de prêmios para acessar</span>
          {participants.map((participant, key) => (
            <>
              <BoxParticipantInfo
                participant={participant}
                key={`participant-${participant.id}`}
              />
              {key < participants.length - 1 && <Separator />}
            </>
          ))}
        </Content>
      ) : (
        _participant.esocial_valid &&
        eSocialApproval && (
          <Content>
            <h3>Catálogo de prêmios</h3>
            {participants.map((participant, key) => (
              <>
                <BoxParticipantInfo
                  participant={participant}
                  key={`participant-${participant.id}`}
                />
                {key < participants.length - 1 && <Separator />}
              </>
            ))}
          </Content>
        )
      )}
      {_participant.esocial_valid && eSocialApproval ? (
        <Content>
          {participants.map((participant, key) => (
            <>
              <FeatureProducts
                participant={participant}
                key={`participant-${participant.id}`}
              />
              {key < participants.length - 1 && <Separator />}
            </>
          ))}
        </Content>
      ) : (
        <ShowValidation />
      )}
    </Container>
  );
};

export default ShowCase;
