import React, { ChangeEvent, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Option } from 'components/shared/Select';

import {
  Container,
  Button,
  RolesSelect,
  FilialSelect,
  IndicatedName,
  Buttons,
  ClearButton,
} from './styles';

interface FilterFormData {
  roles: Option;
  filiais: Option;
}
interface Props {
  filter(
    establishmentId: number,
    roleId: number,
    subsidiaryId: number,
    participantName: string,
  ): void | Promise<void>;
  establishmentId: number;
}

const Filters: React.FC<Props> = ({ filter, establishmentId }) => {
  const methods = useForm<FilterFormData>({
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
  });

  const [filterValue, setFilterValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = /[a-záàâãéèêíïóôõöúçñ ]+$/i;
    const isOnlyLettersOrSpace = regex.exec(value);
    if (!isOnlyLettersOrSpace) {
      event.preventDefault();
      setFilterValue('');
      return;
    }
    setFilterValue(value);
  };

  const { handleSubmit, reset } = methods;
  const onSubmit = handleSubmit(async data => {
    const participantName = filterValue;
    const roleId = parseInt(data.roles?.value || '0', 0) || 0;
    const subsidiaryId = parseInt(data.filiais?.value || '0', 0);
    filter(establishmentId, roleId, subsidiaryId, participantName);
  });

  return (
    <Container>
      <FormContext {...methods}>
        <form onSubmit={onSubmit}>
          <IndicatedName
            name="participant_name"
            type="text"
            inputRole="secondary"
            label="Nome"
            onChange={handleChange}
            value={filterValue}
          />
          <RolesSelect
            name="roles"
            inputRole="secondary"
            excludedRoles={['Produtor']}
          />
          <FilialSelect
            name="filiais"
            inputRole="secondary"
            establishmentId={establishmentId}
          />
          <Buttons>
            <Button type="submit" buttonRole="primary">
              Filtrar
            </Button>
            <ClearButton
              type="submit"
              onClick={() => {
                reset();
                setFilterValue('');
              }}
            >
              Limpar
            </ClearButton>
          </Buttons>
        </form>
      </FormContext>
    </Container>
  );
};
export default Filters;
