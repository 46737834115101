import React from 'react';
import { useToast } from 'context/ToastContext';
import { ContentDownload } from './styles';

interface DownloadPDFProps {
  pdfUrl: string;
  pdfFileName?: string;
  textName?: string;
}
const DownloadPDF: React.FC<DownloadPDFProps> = ({
  pdfUrl,
  pdfFileName,
  textName,
}) => {
  const { addToast } = useToast();

  const handleDownload = async () => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      // window.open(url, '_blank');
      link.href = url;
      link.download = pdfFileName || 'estoque-final.pdf';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      addToast({
        title: e.response?.data?.message || 'Erro ao baixar o PDF.',
        type: 'error',
      });
    }
  };

  return (
    <ContentDownload
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      tabIndex={0}
      role="button"
      onClick={handleDownload}
      onKeyDown={e => e.key === 'Enter' && handleDownload()}
    >
      {textName}
    </ContentDownload>
  );
};

export default DownloadPDF;
