import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    padding: 15px;
    max-width: 900px;
    width: 100%;
    height: auto;
    text-align: center;
    max-height: 420px;
    background-color: transparent;
    overflow-x: hidden;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: #fff;
  color: #000;

  h1 {
    color: ${({ theme }) => theme.font.color.primary};
    position: relative;
    top: -3.5rem;
    left: 2rem;
    font-family: 'Helvetica-Neue-Medium';
    font-size: 25px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .bold {
    font-weight: bold;
    color: #242d2c;
  }

  th {
    padding: 12px;
    text-align: left;
    color: #303636;
  }

  td {
    padding: 12px;
    color: #686c6a;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    text-decoration: underline blue;
  }

  a {
    text-decoration: underline #353f3f;
    cursor: pointer;
    color: #353f3f;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  > h4 {
    font-size: 24px;
    color: ${({ theme }) => theme.font.color.tertiary};
    text-align: center;
    padding: 0 20px;
  }

  > button {
    width: 181px;
    height: 48px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 18px;
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  background-color: #fff;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  position: relative;
  left: 0.25rem;
  > button {
    border: none;
    background: transparent;
    position: relative;
    left: -0.25rem;
    top: 0.25rem;
    svg path {
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;
