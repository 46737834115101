import React, { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import getImportHistory from "services/data-dictionary/getImportHistory";
import { getImportChannelHierarchy } from "services/data-dictionary/getImportChannelHierarchy";
import { getImportDownload } from "services/data-dictionary/getImportDownload";
import { Participant as IParticipant } from 'services/auth/interfaces/Participant';
import importDictionary from "services/data-dictionary/importDictionary";
import FileUpload from "components/DataDictionary/FileUploads";
import DownloadModelLink from "components/DataDictionary/DownloadModel";
import DataTable from "components/DataDictionary/DataTable";
import DataTableHistory from "components/DataDictionary/DataTableHistory"; 
import Modal from "components/DataDictionary/Modals";
import { ImportIdentifiers } from "components/DataDictionary/ImportIdentifiers";
import { Container, Item, Title, DownloadLink, PaginationContainer, PaginationButton } from "./styles";

import { getFirstName } from 'util/string';
import Tooltip from 'components/shared/Tooltip';

interface ParticipantProps {
  name: string;
  participant: IParticipant;
}
interface ImportResponse {
  success_count: number;
  success: boolean;
  processedRows: number;
  message: string;
  errors: string[];
}
interface RowData {
  identifier: string;
  fileName: string;
  status: string;
  date: string;
  user: string;
  info: React.ReactNode; 
  download: string;
}

interface History {
  file_name: string;
  status: string;
  message: string;
  end_datetime: string;
  participant_name: string;
  info: React.ReactNode; 
  processedRows: number;
  download: string | null;
  import: string;
}

const pathModel = "https://storage.vendavall.com.br/photos/1738355971.679d350395f3b6.05012427.xlsx";
//const roleModel = "https://storage.vendavall.com.br/photos/1738327501.679cc5cdf0ab53.07580995.xlsx";

const ChannelHierarchy: React.FC = () => {
  const { participant } = useAuth(); 
  const participantName = getFirstName(participant?.name || "Usuário");
  const [data, setData] = useState<RowData[]>([]); // Dados para o DataTable
  const [historyData, setHistoryData] = useState<History[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [channelHierarchyData, setChannelHierarchyData] = useState<string | null>(null);
  const [importedFileName, setImportedFileName] = useState<string | null>(null);

    useEffect(() => {
      const fetchChannelHierarchy = async () => {
        try {
          const response = await getImportChannelHierarchy("67a25ff3ad14b3");
    
          if (response?.download_url) {
            setChannelHierarchyData(response.download_url);
            console.log("Link de download carregado:", response.download_url);
          } else {
            console.warn("Nenhum link de download disponível.");
          }
        } catch (error) {
          console.error("Erro ao buscar link de download:", error);
        }
      };
    
      fetchChannelHierarchy();
    }, []); 

  const fetchHistory = async (page: number) => {
    try {
      const id = ImportIdentifiers.ChannelHierarchy;
      const { data, pagination } = await getImportHistory({ page, id });
      const historyWithDownload = await Promise.all(
        data.map(async (entry) => {
          const downloadData = await getImportDownload(entry.import, id);
          const resolvedDownloadLink = downloadData ? downloadData.download_url : null; 
          console.log(resolvedDownloadLink);
          return {
            ...entry,
            info: (
              <Tooltip title={`Linhas processadas: ${entry.processedRows || 0}`} type="info">
                <span style={{ textDecoration: "underline", cursor: "pointer" }}>Info</span>
              </Tooltip>
            ),
            download: resolvedDownloadLink, // Valor resolvido da Promise
          
          };
        })
      );

      setHistoryData(historyWithDownload);
      setTotalPages(pagination.last_page);
    } catch (error) {
      console.error('Erro ao buscar histórico:', error);
    }
  };

  useEffect(() => {
    fetchHistory(currentPage);
  }, [currentPage]);

  const handleFileUpload = async (file: File) => {
    setImportedFileName(file.name);
    const id = ImportIdentifiers.ChannelHierarchy;
    const identifier = `${Date.now()}`;
    const id_import = `${Date.now()}-${Math.random().toString(9).substr(2, 9)}`;
    const timestamp = new Date().toLocaleString(); 

    // Adicionar uma entrada "processando" ao DataTable
    const processingEntry: RowData = {
      identifier,
      fileName: file.name,
      status: "processando",
      date: timestamp,
      user: participantName,
      info: (
        <Tooltip title="Processando..." type="info">
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>Info</span>
        </Tooltip>
      ),
      download:  " - "
    };

    setData((prevData) => [...prevData, processingEntry]);

    try {
      const result: ImportResponse = await importDictionary(file, Number(id), id_import); // Simula upload
      if (result.success) {
        setMessage(`Sucesso: ${result.message} Linhas processadas: ${result.success}`);
        setData((prevData) =>
          prevData.map((row) =>
            row.identifier === identifier ? { 
              ...row, 
              status: "success",
              info: (
                <Tooltip title={`Linhas processadas: ${result.success}`} type="info">
                  <span style={{ textDecoration: "underline", cursor: "pointer" }}>Info</span>
                </Tooltip>
              )
             } : row
          )
        );
      } else {
        setMessage(`Erro: ${result.message}`);
        setData((prevData) =>
          prevData.map((row) =>
            row.identifier === identifier ? { 
              ...row, 
              status: "error",
              info: (
                <Tooltip title={`Linhas processadas: ${result.processedRows}`} type="info">
                  <span style={{ textDecoration: "underline", cursor: "pointer" }}>Info</span>
                </Tooltip>
              ) 
            } : row
          )
        );
      }
      setShowModal(true);
    } catch (error) {
      console.error("Erro no upload do arquivo:", error);
      setMessage("Erro ao tentar enviar o arquivo.");
      setData((prevData) =>
        prevData.map((row) =>
          row.identifier === identifier ? { ...row, status: "error" } : row
        )
      );
    } finally {
     // setIsUploading(false);
    }
  };

  const validateFile = (file: File) =>
    file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const handleCloseModal = () => {
    setShowModal(false);
    setMessage(null);
  };

  return (
    <Container>
      <Title>Alteração de Hierarquia de Canais</Title>
      <Item>
      <span style={{ color: "#007bff", fontSize: "16px" }}>Upload de Arquivo</span>
        <DownloadLink>
          <DownloadModelLink fileName="modelo_hierarquia-canal.xlsx" modelFile={pathModel} title="Baixar Modelo"/> | 
          <DownloadModelLink fileName={importedFileName || "hierarquia_canais_fmc.xlsx"} modelFile={channelHierarchyData || "" } title="Hierarquia de canais FMC" />
        </DownloadLink>
      </Item>
      <Item>
        <FileUpload onFileUpload={handleFileUpload} isValidFile={validateFile} />
      </Item>
      <Title>Status de importação</Title>
      <DataTable data={data}/>
      <Title>Histórico</Title>
      <DataTableHistory data={historyData} /> 
      <PaginationContainer>
        <PaginationButton disabled={currentPage === 1} onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
          Anterior
        </PaginationButton>
        <span>Página {currentPage} de {totalPages}</span>
        <PaginationButton disabled={currentPage === totalPages} onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>
          Próximo
        </PaginationButton>
      </PaginationContainer>

      {showModal && <Modal message={message!} onClose={handleCloseModal} />}
    </Container>
  );
};

export default ChannelHierarchy;