import routeMap from 'routes/route-map';
import ParticipantChannels from 'pages/ParticipantChannels/';
import { RouteModule } from './route-module';

const routes: RouteModule[] = [
  {
    path: `${routeMap.participantChannels}`,
    component: ParticipantChannels,
    accessPage: 'Canais Participantes',
  },
];

export default routes;
