import React, { useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import getFileData from 'services/campaigns-manager/getMechanics';
import { useToast } from 'context/ToastContext';
import getUrlToDownloadFinalResults from 'services/campaigns-counting/getUrlToDownloadFinalResults';

import CheckIcon from 'assets/images/campaigns/check-icon.svg';
import ClockIcon from 'assets/images/campaigns/clock-icon.svg';
import CanceledIcon from 'assets/images/campaigns/canceled-icon.svg';
import ChevronIcon from 'assets/images/extract/chevron.svg';
import {
  CAMPAIGN_STATUS,
  Campaign,
  Status,
} from 'services/campaigns-manager/interfaces/Campaign';
import { formatDate } from 'util/datetime';
import history from 'services/history';
import routeMap from 'routes/route-map';
import { useAuth } from 'context/AuthContext';
import ReactLoading from 'react-loading';
import getUrlToDownloadRegulationsAccepted from 'services/campaigns-manager/getUrlToDownloadRegulationsAccepted';
import { Container, Details, DetailsWrapper, Title } from './styles';

type Props = {
  icon: 'clock' | 'check' | 'block';
  title: string;
  displayStatus: Status[];
  isLoading: { [key: number]: boolean };
  handleRequestApproval: (data: Campaign) => void;
  handleApprove: (data: Campaign) => void;
  openCommentPopup: (data: number) => void;
  handleApprovalView: (id: number) => void;
  handleDisplayHighlight: (
    highlightId: number | null,
    campaignId: number,
  ) => void;
  handleHideHighlight: (highlightId: number | null) => void;
  handlePublishUnpublishToggle: (data: Campaign) => void;
  campaigns: Campaign[];
};

const icons = {
  clock: ClockIcon,
  check: CheckIcon,
  block: CanceledIcon,
};

const approvers = ['crm', 'grm'];

export const Accordion: React.FC<Props> = ({
  icon,
  title,
  displayStatus,
  openCommentPopup,
  handleRequestApproval,
  handleApprove,
  handleApprovalView,
  handleDisplayHighlight,
  handlePublishUnpublishToggle,
  handleHideHighlight,
  campaigns,
  isLoading,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [finalResultsUrl, setFinalResultsUrl] = useState('');
  const [acceptanceRegulationUrl, setAcceptanceRegulationUrl] = useState('');
  const [isLoadingKit, setLoadingKit] = useState<{ [key: number]: boolean }>(
    {},
  );
  const [isLoadingResults, setIsLoadingResults] = useState<{
    [key: number]: boolean;
  }>({});
  const [isLoadingRegulations, setIsLoadingRegulations] = useState<{
    [key: number]: boolean;
  }>({});
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const {
    participant: { profile_value: profile },
  } = useAuth();
  const { addToast } = useToast();
  const dataToDisplay = campaigns?.filter(campaign =>
    displayStatus.includes(campaign.status.id),
  );

  const handleClick = useCallback((campaignId: number) => {
    history.push(
      `${routeMap.campaignManagement.acceleratedCampaigns}/${campaignId}`,
    );
  }, []);

  const toCapitalize = (apiText: string | null) => {
    if (apiText === null) {
      return '';
    }
    if (typeof apiText !== 'string') {
      throw new Error('O argumento deve ser uma string ou nulo.');
    }

    return apiText
      .toLowerCase()
      .split(' ')
      .map(text => text.charAt(0).toUpperCase() + text.slice(1))
      .join(' ');
  };

  const handleCommunicationKit = useCallback(
    async (data: Campaign, typeId: number) => {
      setLoadingKit(prevLoadings => ({
        ...prevLoadings,
        [data.id]: true,
      }));

      await getFileData().then(response => {
        const searchFile = response
          .filter(m => m.id === typeId)
          .map(item => item.materialLink)[0];

        if (searchFile !== undefined && searchFile !== '') {
          window.location.href = searchFile;
        } else {
          addToast({
            title: 'Não há Kit de Comunicação para download.',
            type: 'error',
          });
        }

        setLoadingKit(prevLoadings => ({
          ...prevLoadings,
          [data.id]: false,
        }));
      });
    },
    [addToast],
  );

  const handleResultsReport = useCallback(
    async (id: number) => {
      if (!id) return;
      setIsLoadingResults(prevLoadings => ({
        ...prevLoadings,
        id: true,
      }));
      try {
        await getUrlToDownloadFinalResults(id).then(response =>
          setFinalResultsUrl(response),
        );
      } catch (e) {
        addToast({
          title: 'Não há Relatório de Resultado para download.',
          type: 'error',
        });
      }

      setIsLoadingResults(prevLoadings => ({
        ...prevLoadings,
        id: false,
      }));
    },
    [addToast],
  );

  const handleAcceptanceReport = useCallback(
    async (id: number) => {
      setIsLoadingRegulations(prevLoadings => ({
        ...prevLoadings,
        id: true,
      }));
      if (!id) return;
      try {
        await getUrlToDownloadRegulationsAccepted(id).then(response =>
          setAcceptanceRegulationUrl(response),
        );
      } catch (e) {
        addToast({
          title: 'Não há Relatório de Aceite para download.',
          type: 'error',
        });
      }

      setIsLoadingRegulations(prevLoadings => ({
        ...prevLoadings,
        id: false,
      }));
    },
    [addToast],
  );

  useEffect(() => {
    if (finalResultsUrl) {
      window.location.href = finalResultsUrl;
    }
  }, [addToast, finalResultsUrl]);

  useEffect(() => {
    if (acceptanceRegulationUrl) {
      window.location.href = acceptanceRegulationUrl;
    }
  }, [acceptanceRegulationUrl, addToast]);

  const handleDropdownClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <Container isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        <ReactSVG src={icons[icon]} />
        <span>
          {title} ({dataToDisplay.length})
        </span>
        <ReactSVG className="chevron" src={ChevronIcon} />
      </Container>
      {isOpen && (
        <DetailsWrapper>
          {dataToDisplay.map(campaign => (
            <Details key={`${campaign.id}`}>
              <div className="campaign--details">
                <div className="contents">
                  <span style={{ marginRight: '5px' }}>
                    {campaign.mechanic.name || '---'}
                  </span>
                  <></>
                  {campaign.audience && campaign.audience.length > 0 && (
                    <>
                      <span>
                        - {toCapitalize(campaign.audience[0].customer.name)}
                        {campaign.audience.length > 1 && (
                          <span
                            className="more"
                            role="button"
                            tabIndex={0}
                            onClick={handleDropdownClick}
                            onKeyPress={e =>
                              e.key === 'Enter' && handleDropdownClick()
                            }
                          >
                            {`(+${campaign.audience.length - 1})`}
                          </span>
                        )}
                        {isDropdownOpen && (
                          <div className="dropdown">
                            {campaign.audience
                              .slice(1, 2)
                              .map((establishment, index) => (
                                <div key={Number(index)}>
                                  {toCapitalize(establishment.customer.name)}
                                </div>
                              ))}
                          </div>
                        )}
                      </span>
                    </>
                  )}
                </div>
                <Title onClick={() => handleClick(campaign.id)}>
                  {toCapitalize(campaign.title) || '---'}
                </Title>

                {displayStatus.includes(CAMPAIGN_STATUS.NEW_REQUEST_STATUS) && (
                  <>
                    <div>
                      <span>Autor: {campaign.participantName}</span>
                      <br />
                      <span>
                        Solicitado em: {formatDate(campaign.createdAt)}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="campaign--actions">
                {displayStatus.includes(
                  CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS,
                ) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => handleApprove(campaign)}
                    >
                      {isLoading[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Aprovar'
                      )}
                    </button>
                  )}
                {displayStatus.includes(
                  CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS,
                ) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => openCommentPopup(campaign?.id)}
                    >
                      {isLoading[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Recusar'
                      )}
                    </button>
                  )}
                {displayStatus.includes(CAMPAIGN_STATUS.DEVELOPMENT_STATUS) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => handleRequestApproval(campaign)}
                    >
                      {isLoading[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Solicitar aprovação'
                      )}
                    </button>
                  )}
                {(displayStatus.includes(
                  CAMPAIGN_STATUS.WAITING_FOR_APPROVAL_STATUS,
                ) ||
                  displayStatus.includes(CAMPAIGN_STATUS.DEVELOPMENT_STATUS)) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => handleApprovalView(campaign?.id)}
                    >
                      Ver aprovações
                    </button>
                  )}
                {displayStatus.includes(
                  CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS,
                ) &&
                  approvers.includes(profile.toLowerCase()) &&
                  !campaign?.highlight.status && (
                    <button
                      type="button"
                      onClick={() =>
                        handleDisplayHighlight(
                          campaign?.highlight.id,
                          campaign?.id,
                        )
                      }
                    >
                      Exibir destaque
                    </button>
                  )}
                {displayStatus.includes(CAMPAIGN_STATUS.PUBLISHED_STATUS) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() =>
                        handleHideHighlight(campaign?.highlight.id)
                      }
                    >
                      Ocultar destaque
                    </button>
                  )}
                {displayStatus.includes(
                  CAMPAIGN_STATUS.APPROVED_FOR_PUBLICATION_STATUS,
                ) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => handlePublishUnpublishToggle(campaign)}
                    >
                      {isLoading[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Publicar'
                      )}
                    </button>
                  )}
                {displayStatus.includes(CAMPAIGN_STATUS.PUBLISHED_STATUS) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() =>
                        handleCommunicationKit(campaign, campaign.mechanic.id)
                      }
                    >
                      {isLoadingKit[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Kit de comunicação'
                      )}
                    </button>
                  )}
                {displayStatus.includes(CAMPAIGN_STATUS.PUBLISHED_STATUS) &&
                  approvers.includes(profile.toLowerCase()) && (
                    <button
                      type="button"
                      onClick={() => handlePublishUnpublishToggle(campaign)}
                    >
                      {isLoading[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Desfazer publicação'
                      )}
                    </button>
                  )}
                {(displayStatus.includes(CAMPAIGN_STATUS.PUBLISHED_STATUS) ||
                  displayStatus.includes(CAMPAIGN_STATUS.FINISHED_STATUS)) && (
                  <button
                    type="button"
                    onClick={() => handleAcceptanceReport(campaign.id)}
                  >
                    {isLoadingRegulations[campaign.id] ? (
                      <ReactLoading
                        type="bars"
                        color="#8d8d8d"
                        height={10}
                        width={10}
                      />
                    ) : (
                      'Relatório de aceite'
                    )}
                  </button>
                )}
                {displayStatus.includes(CAMPAIGN_STATUS.FINISHED_STATUS) &&
                  campaign.stock && (
                    <button
                      type="button"
                      onClick={() => handleResultsReport(campaign?.id)}
                    >
                      {isLoadingResults[campaign.id] ? (
                        <ReactLoading
                          type="bars"
                          color="#8d8d8d"
                          height={10}
                          width={10}
                        />
                      ) : (
                        'Relatório de resultados'
                      )}
                    </button>
                  )}
              </div>
            </Details>
          ))}
        </DetailsWrapper>
      )}
    </>
  );
};
