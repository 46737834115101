import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useAuth } from 'context/AuthContext';
import imageArrowMobile from 'assets/images/producer-indication/arrows-mobile.svg';
import imageArrow from 'assets/images/producer-indication/arrows.svg';
import useDimensions from 'hooks/use-window-dimensions';
import {
  Button,
  CardBox,
  CodeCard,
  Container,
  Content,
  Img,
  InfoCard,
  TextType,
  Title,
} from './styles';

const Bainer: React.FC = () => {
  const { participant } = useAuth();
  const initialUrl = `${process.env.REACT_APP_HOST}?code=`;
  const [value] = useState(initialUrl + participant.indicator_code);
  const [copied, setCopied] = useState(false);
  const { width } = useDimensions();

  const dinamicIcon = width < 600 ? imageArrowMobile : imageArrow;

  let colorType = '';
  switch (participant.establishment.type_name) {
    case 'Cooperativa':
      colorType = 'verde';
      break;

    case 'Revenda':
      colorType = 'azul';
      break;

    case 'Terra Brasil':
      colorType = 'verde';
      break;

    default:
      colorType = 'marron';
  }

  if (participant.profile === 'FMC') {
    colorType = 'cinza';
  }

  let diminacType = '';
  switch (participant.establishment.type_name) {
    case 'Cooperativa':
      diminacType =
        'Para indicar você precisa copiar o link e enviar para seus cooperados.';
      break;
    case 'Revenda':
      diminacType =
        'Para indicar você precisa copiar o link e enviar para seus clientes.';
      break;

    default:
      diminacType =
        'Para indicar você precisa copiar o link e enviar para seu contato.';
  }

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Container colorType={colorType}>
      <Content>
        <Title>Indicação de Produtor</Title>
        <h2>
          Indique seus conhecidos, produtores agrícolas para participarem do
          JUNTOS FMC.
        </h2>
      </Content>
      <CardBox>
        <InfoCard>
          <h3>Como funciona</h3>
          <div>
            <Img src={dinamicIcon} />
            <TextType diminacType={diminacType}>{diminacType}</TextType>
          </div>
          <div>
            <Img src={dinamicIcon} />
            <p>
              Assim que o seu indicado receber o link ele já poderá se
              cadastrar.
            </p>
          </div>
        </InfoCard>
        <CodeCard colorType={colorType}>
          <p>Compartilhe seu link de Indicação</p>
          <span>{value}</span>
          <CopyToClipboard text={value} onCopy={() => handleCopy()}>
            <Button type="button" buttonRole="primary">
              {copied ? <p>Copiado</p> : <p>Copiar</p>}
            </Button>
          </CopyToClipboard>
        </CodeCard>
      </CardBox>
    </Container>
  );
};

export default Bainer;
