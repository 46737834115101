import { pluginApi } from 'services/api';

interface ApiResponse {
  name: string;
  email: string;
  area_code: string;
  cell_phone: string;
}

export interface IndicationCadidate {
  name: string;
  email: string;
  areaCode: string;
  phone: string;
}

export interface ValidationMessage {
  canIndicate: boolean;
  message: string;
}

export default async (
  cpf: string,
): Promise<IndicationCadidate | ValidationMessage> => {
  try {
    const { data } = await pluginApi.get<ApiResponse>(
      `/participants/indications/check/${cpf}`,
    );

    if (data && data.name) {
      const { name, email, area_code, cell_phone } = data;
      return {
        name,
        email,
        areaCode: area_code,
        phone: cell_phone,
      };
    }

    return {
      canIndicate: true,
      message: 'CPF não encontrado',
    };
  } catch (error) {
    return {
      canIndicate: false,
      message: error.response?.data?.message || 'Erro',
    };
  }
};
