import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Title = styled.h3`
  color: ${({ theme }) => theme.font.color.primary};
  margin-bottom: 10px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  strong {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  max-width: 425px;
  height: 48px;
  background: #e63027;
  color: #fff;

  &:hover {
    background: #e63027;
    filter: grayscale(20%);
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;
