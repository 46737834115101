import { Reducer } from 'redux';
import produce from 'immer';
import { emptyFetchState, fetchErrorState, fetchingState } from 'state/utils';
import { fakeFormatDollars } from 'util/points';
import { FmcCoinsSimulatorState } from './interfaces';
import { FmcCoinsSimulatorActions } from './actions';
import * as constants from './constants';

export const initialState: FmcCoinsSimulatorState = {
  fetchFmcProducts: emptyFetchState,
  products: [],
  valueToConversion: 0,
};

const FmcCoinsSimulatorReducer: Reducer<
  FmcCoinsSimulatorState,
  FmcCoinsSimulatorActions
> = (
  state = initialState,
  action: FmcCoinsSimulatorActions,
): FmcCoinsSimulatorState => {
  switch (action.type) {
    case constants.SET_FMC_PRODUCTS_VALUES:
      return produce(state, draft => {
        draft.products = action.payload;
      });
    case constants.SET_FMC_PRODUCT_CHECK:
      return produce(state, draft => {
        const { checked, productId } = action.payload;
        draft.products.map(products => {
          if (products.id === productId) {
            products.checked = checked;
          }
          return products;
        });
      });

    case constants.SET_FMC_PRODUCT_VALUE:
      return produce(state, draft => {
        const { value, productId } = action.payload;
        draft.products.map(products => {
          if (products.id === productId) {
            const factorConvert = parseFloat(
              products.conversion_factor.replace(/,/g, '.'),
            );
            products.fmcCoins = value * factorConvert;
            products.valueToConversion = value;
            products.valueToShow = fakeFormatDollars(value);
          }
          return products;
        });
      });
    case constants.FETCH_FMC_PRODUCTS_ACTION:
      return { ...state, fetchFmcProducts: fetchingState };
    case constants.FETCH_FMC_PRODUCTS_FAILURE:
      return { ...state, fetchFmcProducts: fetchErrorState(action) };
    case constants.FETCH_FMC_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetchFmcProducts: emptyFetchState,
        products: action.payload,
      };

    case constants.FETCH_FMC_LOAD_STATE_SUCCESS:
      return action.payload;
    case constants.RESET_FMC:
      return initialState;
    default:
      return state;
  }
};

export default FmcCoinsSimulatorReducer;
