/* eslint-disable radix */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatDate } from 'util/datetime';
import { useFormContext } from 'react-hook-form';
import { toDate } from 'date-fns';
import { formatPointsBudget } from 'util/points';
import numbersOnly from 'util/numbersOnly';
import { TypeHiring } from 'services/strategic-plans/actions/interfaces';
import { Option } from 'components/shared/Select';
import { DatePicker, Input } from '../../shared/styles';
import SelectExclusiveFmc from '../../Selects/SelectExclusiveFmc';
import SelectTypeHiring from '../../Selects/SelectTypeHiring';
import {
  Container,
  Contents,
  DatesPiker,
  InputWrapper,
  QuantityWrapper,
  Text,
} from './styles';

interface Props {
  canEdit?: boolean;
  addTypeHiringHandler: (type: TypeHiring) => void;
  defaultValue: string;
  typeHiringId: number;
}
const StrategyActionAts: React.FC<Props> = ({
  canEdit = true,
  defaultValue,
  typeHiringId,
  addTypeHiringHandler,
}) => {
  const { errors, register, setValue, watch } = useFormContext();
  const [beginDate, setBeginDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedTypeHiring, setSelectedTypeHiring] = useState<Option | null>(
    null,
  );
  const hiddenRef = useRef<HTMLInputElement>();

  const defaultEndDate = watch('end_date_at');
  const defaultBeginDate = watch('begin_date_at');

  const handleChangeCostMonthy = useCallback(
    (value: string) => {
      setValue(
        'cost_monthy_at',
        `${formatPointsBudget(parseInt(numbersOnly(value) || '0'))}`,
      );
    },
    [setValue],
  );

  const handleChangeQuantity = useCallback(
    (value: string) => {
      setValue('quantity_at', parseInt(numbersOnly(value) || '0'));
    },
    [setValue],
  );

  const onEndDateChangeHandler = useCallback(
    (value: Date): void => {
      setEndDate(toDate(value));
      if (value) {
        const data = formatDate(value, 'yyyy-MM-dd');
        setValue('end_date_at', data);
      }
    },
    [setValue],
  );

  const onBeginDateChangeHandler = useCallback(
    (value: Date): void => {
      setBeginDate(toDate(value));
      if (value) {
        const data = formatDate(value, 'yyyy-MM-dd');
        setValue('begin_date_at', data);
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (defaultEndDate) {
      const data = new Date(formatDate(defaultEndDate, 'yyyy-MM-dd hh:mm:ss'));
      setEndDate(data);
    }
  }, [defaultEndDate]);

  useEffect(() => {
    if (defaultBeginDate) {
      const data = new Date(
        formatDate(defaultBeginDate, 'yyyy-MM-dd hh:mm:ss'),
      );
      setBeginDate(data);
    }
  }, [defaultBeginDate]);

  useEffect(() => {
    if (!selectedTypeHiring) return;
    const { value, title } = selectedTypeHiring;

    addTypeHiringHandler({
      id: Number(value),
      name: title,
    });
  }, [addTypeHiringHandler, selectedTypeHiring]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedTypeHiring({
        title: defaultValue,
        value: typeHiringId.toString(),
      });
    }
  }, [typeHiringId, defaultValue]);

  return (
    <Container>
      <Contents>
        <QuantityWrapper>
          <Input
            name="quantity_at"
            label="Quantidade de Ats:"
            onChange={e => handleChangeQuantity(e.target.value)}
            placeholder="0"
          />
        </QuantityWrapper>
        <SelectExclusiveFmc
          inputRole="primary"
          name="exclusive_fmc_at_select"
        />
        <SelectTypeHiring
          inputRole="primary"
          name="type_hiring_id_at_select"
          defaultValue={selectedTypeHiring?.title}
          value={selectedTypeHiring}
          setValue={setSelectedTypeHiring}
          disabled={!canEdit}
        />
      </Contents>
      <DatesPiker>
        <DatePicker
          label="Início *"
          value={beginDate}
          onChange={onBeginDateChangeHandler}
          name="teste"
          error={errors.begin_date?.message ?? false}
          disabled={!canEdit}
        />
        <input
          type="hidden"
          name="begin_date_at"
          ref={(e: HTMLInputElement) => {
            hiddenRef.current = e;
            register(e);
          }}
        />
        <DatePicker
          label="Fim *"
          value={endDate}
          onChange={onEndDateChangeHandler}
          name="teste"
          error={errors.end_date?.message ?? false}
          disabled={!canEdit}
        />
        <input
          type="hidden"
          name="end_date_at"
          ref={(e: HTMLInputElement) => {
            hiddenRef.current = e;
            register(e);
          }}
        />
      </DatesPiker>
      <Text>Custo mensal por AT (contemplando despesas com viagens):</Text>
      <InputWrapper>
        <Input
          name="cost_monthy_at"
          placeholder="0"
          onChange={e => handleChangeCostMonthy(e.target.value)}
          disabled={!canEdit}
        />
        <span> R$</span>
      </InputWrapper>
    </Container>
  );
};

export default StrategyActionAts;
