import React from 'react';
import { formatDate } from 'util/datetime';

import { useSelector } from 'react-redux';
import {
  getChannel,
  getIndicators,
} from 'state/modules/points-simulator/selectors';
import { EstablishmentTypes } from 'config/constants';
import { Container } from './styles';
import ChannelBox from '../ChannelBox';
import HelpIcon from '../HelpIcon';

interface Props {
  simulatedDate: Date;
  partialDate: Date;
  partialDateInvoicing: Date;
  partialDatePog: Date;
  channelName: string;
  channelCategory?: string;
}

const Header: React.FC<Props> = props => {
  const channel = useSelector(getChannel);
  const indicators = useSelector(getIndicators);

  const indicatorCampaignNotTerraBrasil = indicators.find(
    indicator => !indicator.isTerraBrasil,
  );

  const indicatorCampaignTerraBrasil = indicators.find(
    indicator => indicator.isTerraBrasil,
  );

  const {
    simulatedDate,
    //partialDate,
    partialDateInvoicing,
    partialDatePog,
    channelName,
    channelCategory = '',
  } = props;

  const year =
    channel?.type === EstablishmentTypes.TerraBrasil
      ? indicatorCampaignNotTerraBrasil?.currentYear
      : indicatorCampaignTerraBrasil?.currentYear;

  return (
    <Container id="_points-simulator-header">
      <h3>Simulador de Pontos do Programa de Relacionamento JUNTOS FMC</h3>
      <div>
        <h3>Resultado da Simulação</h3>
        <span>
          (em {formatDate(simulatedDate)} com parciais de Faturamento em {' '}
          {formatDate(partialDateInvoicing)} e POG em {' '}
          {formatDate(partialDatePog)}
          )
        </span>

        <ChannelBox
          channelName={channelName}
          text={year}
          category={channelCategory}
        />
        <HelpIcon channel={channel} initialPosition="right-top" />
      </div>
    </Container>
  );
};

export default Header;
