import React, { useState, useEffect } from 'react';
import { Container, Header, Content, ContainerAll } from './styles';
import { pluginApi } from 'services/api';
import chevronDown from './ArrowDown.png';
import chevronUp from './ArrowUp.png';
import Bells from "./bells"
import { useAuth } from '../../context/AuthContext';

const ExtratRtc: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { participant } = useAuth();

  // TODOS DADOS
  const [data, setData] = useState();

  // ARMAZENAR OS DADOS DO PERIODO
  const [april, setApril] = useState();
  const [may, setMay] = useState();
  const [june, setJune] = useState();
  const [july, setJuly] = useState();
  const [august, setAugust] = useState();
  const [september, setSeptember] = useState();
  const [october, setOctober] = useState();
  const [november, setNovember] = useState();
  const [december, setDecember] = useState();

  // ARMAZENAR OS PONTOS DE ACESSO DE CADA MÊS
  const [valuePointAccessApril, setValuePointAccessApril] = useState();
  const [valuePointAccessMay, setValuePointAccessMay] = useState();
  const [valuePointAccessJune, setValuePointAccessJune] = useState();
  const [valuePointAccessJuly, setValuePointAccessJuly] = useState();
  const [valuePointAccessAugust, setValuePointAccessAugust] = useState();
  const [valuePointAccessSeptember, setValuePointAccessSeptember] = useState();
  const [valuePointAccessOctober, setValuePointAccessOctober] = useState();
  const [valuePointAccessNovember, setValuePointAccessNovember] = useState();
  const [valuePointAccessDecember, setValuePointAccessDecember] = useState();

  // ARMAZENAR OS PONTOS DE QUIZZES DE CADA MÊS
  const [valuePointQuizzesApril, setValuePointQuizzesApril] = useState();
  const [valuePointQuizzesMay, setValuePointQuizzesMay] = useState();
  const [valuePointQuizzesJune, setValuePointQuizzesJune] = useState();
  const [valuePointQuizzesJuly, setValuePointQuizzesJuly] = useState();
  const [valuePointQuizzesAugust, setValuePointQuizzesAugust] = useState();
  const [
    valuePointQuizzesSeptember,
    setValuePointQuizzesSeptember,
  ] = useState();
  const [valuePointQuizzesOctober, setValuePointQuizzesOctober] = useState();
  const [valuePointQuizzesNovember, setValuePointQuizzesNovember] = useState();
  const [valuePointQuizzesDecember, setValuePointQuizzesDecember] = useState();

  // ARMAZENAR OS PONTOS DE SURVEYS DE CADA MÊS
  const [valuePointSurveysApril, setValuePointSurveysApril] = useState();
  const [valuePointSurveysMay, setValuePointSurveysMay] = useState();
  const [valuePointSurveysJune, setValuePointSurveysJune] = useState();
  const [valuePointSurveysJuly, setValuePointSurveysJuly] = useState();
  const [valuePointSurveysAugust, setValuePointSurveysAugust] = useState();
  const [
    valuePointSurveysSeptember,
    setValuePointSurveysSeptember,
  ] = useState();
  const [valuePointSurveysOctober, setValuePointSurveysOctober] = useState();
  const [valuePointSurveysNovember, setValuePointSurveysNovember] = useState();
  const [valuePointSurveysDecember, setValuePointSurveysDecember] = useState();

  // VALORES INTEGRAIS SINOS ENGAJAMENTO
  const [valueAccess, setValueAcess] = useState();
  const [valueQuizzes, setValueQuizzes] = useState();
  const [valueSurveys, setValueSurveys] = useState();

  // VALORES INTEGRAIS SINOS DE METAS DE ESTRATÉGIA
  const [valueTotalStrategies, setValueTotalStrategies] = useState();

  // VALORES INTEGRAIS SINOS DE METAS DE GESTÃO
  const [valueTotalEngagement, setValueTotalEngagement] = useState();

  // DADOS DOS SINOS DE METAS DE ESTRATÉGIA
  const [strategyVision, setStrategyVision] = useState<any>();
  const [productsDevelopment, setProductsDevelopment] = useState<any>();
  const [actionRel, setActionRel] = useState<any>();
  const [topTenFarmers, setTopTenFarmers] = useState<any>();

  // ACORDEÃO ESPECIFICO DE CADA MÊS
  const [chevrApr, setChevApr] = useState<boolean>(false);
  const [chevrMay, setChevMay] = useState<boolean>(false);
  const [chevrJun, setChevJun] = useState<boolean>(false);
  const [chevrJul, setChevJul] = useState<boolean>(false);
  const [chevrAug, setChevAug] = useState<boolean>(false);
  const [chevrSep, setChevSep] = useState<boolean>(false);
  const [chevrOct, setChevOct] = useState<boolean>(false);
  const [chevrNov, setChevNov] = useState<boolean>(false);
  const [chevrDec, setChevDec] = useState<boolean>(false);

  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    pluginApi
      .get('/extracts/get-rtcs-dm-extract')
      .then(res => {
        console.log(res.data);

        // Todos os dados
        setData(res.data[4]);

        // DADOS SINOS DE METAS DE ESTRATÉGIA
        setActionRel(
          parseInt(
            res?.data[4]?.strategies?.periods[202204]?.points[0]?.value,
          ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202205]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202206]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202207]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202208]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202209]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202210]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202211]?.points[0]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202212]?.points[0]?.value,
            ),
        );

        setProductsDevelopment(
          parseInt(
            res?.data[4]?.strategies?.periods[202204]?.points[1]?.value,
          ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202205]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202206]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202207]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202208]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202209]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202210]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202211]?.points[1]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202212]?.points[1]?.value,
            ),
        );

        setTopTenFarmers(
          parseInt(
            res?.data[4]?.strategies?.periods[202204]?.points[2]?.value,
          ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202205]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202206]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202207]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202208]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202209]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202210]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202211]?.points[2]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202212]?.points[2]?.value,
            ),
        );

        setStrategyVision(
          parseInt(
            res?.data[4]?.strategies?.periods[202204]?.points[3]?.value,
          ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202205]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202206]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202207]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202208]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202209]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202210]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202211]?.points[3]?.value,
            ) ||
            parseInt(
              res?.data[4]?.strategies?.periods[202212]?.points[3]?.value,
            ),
        );

        // SOMA TOTAL DOS VALORES ENGAGEMENT
        setValueAcess(res.data[4].engagement.access.total);
        setValueQuizzes(res.data[4].engagement.quizzes.total);
        setValueSurveys(res.data[4].engagement.surveys.total);

        // SOMA DOS VALORES STRATEGIES
        setValueTotalStrategies(res?.data[4]?.strategies?.total);

        // SOMA DOS VALORES MANAGEMENT
        setValueTotalEngagement(res?.data[4]?.management?.total);

        // DADOS DE ABRIL
        setApril(
          res.data[4].engagement?.quizzes?.periods[202104]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202104]?.periodBr ||
            res.data[4].engagement?.access?.periods[202104]?.periodBr,
        );

        setValuePointAccessApril(
          res?.data[4]?.engagement?.access?.periods[202104]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesApril(
          res?.data[4]?.engagement?.quizzes?.periods[202104]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysApril(
          res?.data[4]?.engagement?.surveys?.periods[202104]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE MAIO
        setMay(
          res.data[4].engagement?.quizzes?.periods[202105]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202105]?.periodBr ||
            res.data[4].engagement?.access?.periods[202105]?.periodBr,
        );

        setValuePointAccessMay(
          res?.data[4]?.engagement?.access?.periods[202105]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesMay(
          res?.data[4]?.engagement?.quizzes?.periods[202105]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysMay(
          res?.data[4]?.engagement?.surveys?.periods[202105]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE JUNHO
        setJune(
          res.data[4].engagement?.quizzes?.periods[202106]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202106]?.periodBr ||
            res.data[4].engagement?.access?.periods[202106]?.periodBr,
        );

        setValuePointAccessJune(
          res?.data[4]?.engagement?.access?.periods[202106]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesJune(
          res?.data[4]?.engagement?.quizzes?.periods[202106]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysJune(
          res?.data[4]?.engagement?.surveys?.periods[202106]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE JULHO
        setJuly(
          res.data[4].engagement?.quizzes?.periods[202107]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202107]?.periodBr ||
            res.data[4].engagement?.access?.periods[202107]?.periodBr,
        );

        setValuePointAccessJuly(
          res?.data[4]?.engagement?.access?.periods[202107]?.points
            ?.map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesJuly(
          res?.data[4]?.engagement?.quizzes?.periods[202107]?.points
            ?.map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysJuly(
          res?.data[4]?.engagement?.surveys?.periods[202107]?.points
            ?.map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE AGOSTO
        setAugust(
          res.data[4].engagement?.quizzes?.periods[202108]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202108]?.periodBr ||
            res.data[4].engagement?.access?.periods[202108]?.periodBr,
        );

        setValuePointAccessAugust(
          res?.data[4]?.engagement?.access?.periods[202108]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesAugust(
          res?.data[4]?.engagement?.quizzes?.periods[202108]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysAugust(
          res?.data[4]?.engagement?.surveys?.periods[202108]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE SETEMBRO
        setSeptember(
          res.data[4].engagement?.quizzes?.periods[202109]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202109]?.periodBr ||
            res.data[4].engagement?.access?.periods[202109]?.periodBr,
        );
        setValuePointAccessSeptember(
          res?.data[4]?.engagement?.access?.periods[202109]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesSeptember(
          res?.data[4]?.engagement?.quizzes?.periods[202109]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysSeptember(
          res?.data[4]?.engagement?.surveys?.periods[202109]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE OUTUBRO
        setOctober(
          res.data[4].engagement?.quizzes?.periods[202110]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202110]?.periodBr ||
            res.data[4].engagement?.access?.periods[202110]?.periodBr,
        );

        setValuePointAccessOctober(
          res?.data[4]?.engagement?.access?.periods[202110]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesOctober(
          res?.data[4]?.engagement?.quizzes?.periods[202110]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysOctober(
          res?.data[4]?.engagement?.surveys?.periods[202110]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE NOVEMBRO
        setNovember(
          res.data[4].engagement?.quizzes?.periods[202111]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202111]?.periodBr ||
            res.data[4].engagement?.access?.periods[202111]?.periodBr,
        );

        setValuePointAccessNovember(
          res?.data[4]?.engagement?.access?.periods[202111]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesNovember(
          res?.data[4]?.engagement?.quizzes?.periods[202111]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysNovember(
          res?.data[4]?.engagement?.surveys?.periods[202111]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        // DADOS DE DEZEMBRO
        setDecember(
          res.data[4].engagement?.quizzes?.periods[202112]?.periodBr ||
            res.data[4].engagement?.surveys?.periods[202112]?.periodBr ||
            res.data[4].engagement?.access?.periods[202112]?.periodBr,
        );

        setValuePointAccessDecember(
          res?.data[4]?.engagement?.access?.periods[202112]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointQuizzesDecember(
          res?.data[4]?.engagement?.quizzes?.periods[202112]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );

        setValuePointSurveysDecember(
          res?.data[4]?.engagement?.surveys?.periods[202112]?.points
            .map((items: { value: string }) => Number(items.value))
            .reduce((a: number, b: number) => a + b, 0),
        );
      })
      .catch(err => console.log(err));
  }, []);

  const defaultValue = 0;

  return (
    <ContainerAll>
      <h2 className="title">Extrato de Sinos</h2>
      <div className="pts-btn">
        <div className="points">
          <div style={{flexDirection: 'column'}}>
          Saldo disponível para resgate: <br />
          <p>
            {valueAccess + valueQuizzes + valueSurveys + valueTotalStrategies ||
              defaultValue}{' '}
            Sinos
          </p>
              </div>
          <div className="bells">
            <Bells />
          </div>
        </div>
        <button>RESGATAR</button>
      </div>
      <Container>
        <Header onClick={handleClick}>
          <div>
            Safra
            <strong>{data?.title || '0'}</strong>
          </div>
          <div className="divider" />
          <div>
            <div className="text-right">Total</div>
            <strong>
              &nbsp;
              {valueAccess +
                valueQuizzes +
                valueSurveys +
                valueTotalStrategies || defaultValue}{' '}
              Sinos
            </strong>
          </div>
          <div className={`chevron ${isOpen ? 'open' : ''}`} />
        </Header>
        {isOpen && (
          <Content>
            <br />
            <div className="types-points">
              <strong>Tipos de ponto</strong>
              <strong>Sinos</strong>
            </div>

            <div className="bells">
              <p className="waiting-for-release">
                Sinos de Metas de Gestão
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                <span>
                  {' '}
                  <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                </span>
              </p>
              <strong>
                {valueTotalEngagement !== undefined
                  ? valueTotalEngagement
                  : defaultValue}
              </strong>
            </div>

            <div className="bells-children">
              <p>Faturamento</p>
              <strong>0</strong>
            </div>

            <div className="bells-children">
              <p>Pog</p>
              <strong>0</strong>
            </div>

            <div className="bells">
              <p className="waiting-for-release">
                Sinos de Metas de Estrategia &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                <span>
                  {' '}
                  <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                </span>
              </p>
              <strong>{valueTotalStrategies || defaultValue}</strong>
            </div>

            <div className="bells-children">
              <p>Visão Estratégica</p>
              <strong>{strategyVision || defaultValue}</strong>
            </div>

            <div className="bells-children">
              <p>Desenvolvimento de produtos</p>
              <strong>{productsDevelopment || defaultValue}</strong>
            </div>

            <div className="bells-children">
              <p>Ações de relacionamento</p>
              <strong>{actionRel || defaultValue}</strong>
            </div>

            {participant.profile_value !== 'DM' && (
              <div className="bells-children">
                <p>Top 10 Farmers</p>
                <strong>{topTenFarmers || defaultValue}</strong>
              </div>
            )}

            <div className="bells">
              <p>Sinos de Engajamento</p>
              <strong>
                {valueAccess !== undefined &&
                valueQuizzes !== undefined &&
                valueSurveys !== undefined
                  ? valueQuizzes + valueSurveys + valueAccess
                  : defaultValue}
              </strong>
            </div>

            {valuePointSurveysApril > 0 ||
            valuePointQuizzesApril > 0 ||
            valuePointAccessApril > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevApr(!chevrApr)}
                >
                  &nbsp;&nbsp;Abril
                  <span className="chev-arrow">
                    {!chevrApr ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysApril !== undefined &&
                    april === 'Abril' ? (
                      <strong>{valuePointSurveysApril}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        &#8226; Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesApril !== undefined &&
                    april === 'Abril' ? (
                      <strong>{valuePointQuizzesApril}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessApril !== undefined &&
                    april === 'Abril' ? (
                      <strong>{valuePointAccessApril}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysMay > 0 ||
            valuePointQuizzesMay > 0 ||
            valuePointAccessMay > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevMay(!chevrMay)}
                >
                  &nbsp;&nbsp;Maio
                  <span className="chev-arrow">
                    {!chevrMay ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysMay !== undefined && may === 'Maio' ? (
                      <strong>{valuePointSurveysMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesMay !== undefined && may === 'Maio' ? (
                      <strong>{valuePointQuizzesMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessMay !== undefined && may === 'Maio' ? (
                      <strong>{valuePointAccessMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysJune > 0 ||
            valuePointQuizzesJune > 0 ||
            valuePointAccessJune > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevJun(!chevrJun)}
                >
                  &nbsp;&nbsp;Junho
                  <span className="chev-arrow">
                    {!chevrJun ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysJune !== undefined && june === 'Junho' ? (
                      <strong>{valuePointSurveysMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesJune !== undefined && june === 'Junho' ? (
                      <strong>{valuePointQuizzesJune}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessJune !== undefined && june === 'Junho' ? (
                      <strong>{valuePointAccessJune}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysJuly > 0 ||
            valuePointQuizzesJuly > 0 ||
            valuePointAccessJuly > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevJul(!chevrJul)}
                >
                  &nbsp;&nbsp;Julho
                  <span className="chev-arrow">
                    {!chevrJul ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysJuly !== undefined && july === 'Julho' ? (
                      <strong>{valuePointSurveysMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesJuly !== undefined && july === 'Julho' ? (
                      <strong>{valuePointQuizzesJuly}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessJuly !== undefined && july === 'Julho' ? (
                      <strong>{valuePointAccessJuly}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysAugust > 0 ||
            valuePointQuizzesAugust > 0 ||
            valuePointAccessAugust > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevAug(!chevrAug)}
                >
                  &nbsp;&nbsp;Agosto
                  <span className="chev-arrow">
                    {!chevrAug ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysAugust !== undefined &&
                    august === 'Agosto' ? (
                      <strong>{valuePointSurveysAugust}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesAugust !== undefined &&
                    august === 'Agosto' ? (
                      <strong>{valuePointQuizzesAugust}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessAugust !== undefined &&
                    august === 'Agosto' ? (
                      <strong>{valuePointAccessAugust}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysSeptember > 0 ||
            valuePointQuizzesSeptember > 0 ||
            valuePointAccessSeptember > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevSep(!chevrSep)}
                >
                  &nbsp;&nbsp;Setembro
                  <span className="chev-arrow">
                    {!chevrSep ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysSeptember !== undefined &&
                    september === 'Setembro' ? (
                      <strong>{valuePointSurveysSeptember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesSeptember !== undefined &&
                    september === 'Setembro' ? (
                      <strong>{valuePointQuizzesSeptember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessSeptember !== undefined &&
                    september === 'Setembro' ? (
                      <strong>{valuePointAccessSeptember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysOctober > 0 ||
            valuePointQuizzesOctober > 0 ||
            valuePointAccessOctober > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevOct(!chevrOct)}
                >
                  &nbsp;&nbsp;Outubro
                  <span className="chev-arrow">
                    {!chevrOct ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysOctober !== undefined &&
                    october === 'Outubro' ? (
                      <strong>{valuePointSurveysMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesOctober !== undefined &&
                    october === 'Outubro' ? (
                      <strong>{valuePointQuizzesOctober}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessOctober !== undefined &&
                    october === 'Outubro' ? (
                      <strong>{valuePointAccessOctober}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysNovember > 0 ||
            valuePointQuizzesNovember > 0 ||
            valuePointAccessNovember > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevNov(!chevrNov)}
                >
                  &nbsp;&nbsp;Novembro
                  <span className="chev-arrow">
                    {!chevrNov ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysNovember !== undefined &&
                    november === 'Novembro' ? (
                      <strong>{valuePointSurveysMay}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesNovember !== undefined &&
                    november === 'Novembro' ? (
                      <strong>{valuePointQuizzesNovember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                        <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessNovember !== undefined &&
                    november === 'Novembro' ? (
                      <strong>{valuePointAccessNovember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}

            {valuePointSurveysDecember > 0 ||
            valuePointQuizzesDecember > 0 ||
            valuePointAccessDecember > 0 ? (
              <details>
                <summary
                  className="months-of-the-year"
                  onClick={() => setChevDec(!chevrDec)}
                >
                  &nbsp;&nbsp;Dezembro
                  <span className="chev-arrow">
                    {!chevrDec ? (
                      <img src={chevronDown} alt="arrowDown" />
                    ) : (
                      <img src={chevronUp} alt="arrowUp" />
                    )}
                  </span>
                </summary>
                <div className="div-items">
                  <div className="flex">
                    <p>
                      Pesquisa de conduta de trabalho{' '}
                      <span className="span-quizzes">
                       <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointSurveysDecember !== undefined &&
                    december === 'Dezembro' ? (
                      <strong>{valuePointSurveysDecember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Treinamento de EPI{' '}
                      <span className="span-training-epi">
                       <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointQuizzesDecember !== undefined &&
                    december === 'Dezembro' ? (
                      <strong>{valuePointQuizzesDecember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>

                <div className="div-items">
                  <div className="flex">
                    <p>
                      Acessos{' '}
                      <span className="span-access">
                       <span className="bullet-red">&#8226;</span> &nbsp;Aguardando liberação
                      </span>
                    </p>
                    {valuePointAccessDecember !== undefined &&
                    december === 'Dezembro' ? (
                      <strong>{valuePointAccessDecember}</strong>
                    ) : (
                      <strong>{defaultValue}</strong>
                    )}
                  </div>
                </div>
              </details>
            ) : (
              <></>
            )}
          </Content>
        )}
        <br /> <br />
      </Container>
    </ContainerAll>
  );
};

export default ExtratRtc;
