import { pluginApi } from 'services/api';
import { handlerAxiosError } from 'util/handle-axios-error';

export type ApproveDistributionTeamPayload = {
  undistributed_point_id: number;
  point_type_id: number;
  value_total: number;
  campaign_id: number | any;
  establishment_id: number | any;
  participants: Array<{
    participant_id: number;
    value: number;
  }>;
};

type Response = {
  ok: boolean;
  message?: any;
  error?: string;
};

export async function sendTeamDistributionToApprovation(
  payload: ApproveDistributionTeamPayload,
): Promise<Response> {
  try {
    const response = await pluginApi.post(
      // 'undistributed-points/points-to-approve-team',
      'points-distribution/approve-team-points',
      payload,
    );

    return {
      ok: true,
      message: response.data.message,
    };
  } catch (error) {
    return {
      ok: false,
      error: handlerAxiosError(error),
    };
  }
}
