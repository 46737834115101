import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  color: #2c2b2b;
  border: solid 1px #821c0e;
  margin: 0 auto 10px;
  position: relative;
`;
