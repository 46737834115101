export default {
  campaign: '/campanhas',
  campaignManagement: {
    newCampaign: '/nova-campanha',
    acceleratedCampaigns: '/campanhas-aceleradoras',
  },
  campaignCounting: '/apuracao-de-campanhas',
  contact: '/fale-conosco',
  producerExtract: '/produtor-extrato',
  extract: {
    my: '/myextract',
    channel: '/pontos-canal',
  },
  rtc: '/extrato-sinos',
  faq: '/faq',
  gallery: '/bibliotecas-juntos',
  goal: '/performance',
  home: '/home',
  howParticipate: '/como-participar',
  news: '/novidades',
  participantsIndication: '/indicacao-de-participantes',
  participantSimulation: '/visao-do-participante',
  pointDistribution: '/distribuicao-de-pontos',
  pointTeamDistribution: '/distribuicao-de-pontos/equipe',
  pointApprovement: '/aprovacao-distribuidor-de-pontos',
  products: '/solucoes',
  producer: '/indicar-produtor',
  profile: '/editar-perfil',
  regulation: '/regulamentos-e-termos',
  showcase: '/catalogo-de-premios',
  catalogoPi: '/catalogo',
  training: '/treinamentos',
  cockpit: '/cockpit',
  report: '/relatorios',
  participantChannels: '/canais-participantes',
  pointsSimulator: {
    calculator: '/simulador',
    pdfGeneratorPage: '/pdfGeneratorPage',
    pdfGeneratorEmail: '/pdfGeneratorEmail',
  },
  fmcCoinsSimulator: {
    selector: '/simulador-coins',
    calculator: '/simulador-coins/calcular',
    result: '/simulador-coins/resultado',
    resultSaved: '/simulador-coins/resultado',
    calculatorSaved: '/simulador-coins/calcular',
  },
  qrCode: '/qr',
  receipts: '/extrato-notas',
  harvestTerm: '/termo-de-safra',
  flyingHigh: '/voandoalto',
  producerApproval: '/aprovacao-de-produtores',
  research: '/pesquisas',
  InternalPage: {
    questions: '/responder-pesquisa',
    answers: '/ver-respostas',
  },
  strategicPlans: '/planos-estrategicos',
  blockAccess: {
    tampaoSafra: '/indiretos',
  },
  completeIndirectRegistration: '/completar-cadastro-indireto',
  completeProducerRegistration: '/completar-cadastro-produtor',
  rtcExtract: '/extrato-sinos',
  rouletteExtract: '/extrato-roleta-premiada',
  reportIncome: '/informe-de-rendimentos',
  regulations: '/regulamentos',
  primeiroAcesso: '/primeiro-acesso',
  producersWhoBuy: '/produtores-que-compram',
  dataDictionary: {
    teamRegistration: '/cadastro-time',
    channelHierarchy: '/hierarquia-canais',
    branchInclusion: '/inclusao-filial',
    focalPointChange: '/alteracao-focal-point',
    importGoals: '/importacao-metas',
    importGoalsProducts: '/importacao-metas-produtos',
  },
};
