export const SET_FMC_PRODUCT_CHECK =
  '@fmc/fmc-coins-simulator/SET_FMC_PRODUCT_CHECK';

export const FETCH_FMC_PRODUCTS_ACTION =
  '@fmc/fmc-coins-simulator/FETCH_FMC_PRODUCTS_ACTION';
export const FETCH_FMC_PRODUCTS_SUCCESS =
  '@fmc/fmc-coins-simulator/FETCH_FMC_PRODUCTS_SUCCESS';
export const FETCH_FMC_PRODUCTS_FAILURE =
  '@fmc/fmc-coins-simulator/FETCH_FMC_PRODUCTS_FAILURE';

export const SET_FMC_PRODUCTS_VALUES =
  '@fmc/fmc-coins-simulator/SET_FMC_PRODUCTS_VALUES';

export const SET_FMC_PRODUCT_VALUE =
  '@fmc/fmc-coins-simulator/SET_FMC_PRODUCT_VALUE';

export const RESET_FMC = '@fmc/fmc-coins-simulator/RESET_FMC';

export const FETCH_FMC_LOAD_STATE =
  '@fmc/fmc-coins-simulator/FETCH_FMC_LOAD_STATE';
export const FETCH_FMC_LOAD_STATE_SUCCESS =
  '@fmc/fmc-coins-simulator/FETCH_FMC_LOAD_STATE_SUCCESS';

export const SET_VALUE_TO_CONVERSION =
  '@fmc/fmc-coins-simulator/SET_VALUE_TO_CONVERSION ';
