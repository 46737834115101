import styled from 'styled-components';
import chevron from 'assets/images/extract/chevron.svg';
// import { Button as DefaultButton } from 'components/shared';

export const ContainerAll = styled.div`
  .points {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;

    .bells {
      margin-right: 2rem;

      img {
        height: 32px;
        filter: invert(60%) sepia(123%) saturate(1207%) hue-rotate(2831deg)
          brightness(85%) contrast(177%) grayscale(40%);
        margin-left: auto;
      }
    }

    p {
      font-size: 1.3rem;
    }
  }

  .pts-btn {
    color: #000;
    display: flex;
    justify-content: space-between;
    border: 3px solid #e4001b;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    width: 92%;
    margin: 8px auto;
    padding: 20px;
    font-weight: bold;
    color: #821c0e;

    button {
      width: 25%;
      border: none;
      background-color: #e4001b;
      color: #f1f1f1;
      font-size: 1.2rem;
      border-radius: 10px;
      font-weight: bold;
      height: 3rem;
    }
  }

  .title {
    margin: 3%;
    color: #821c0e;
  }

  .types-points {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    strong {
      color: #821c0e;
      font-size: 1rem;
    }
  }

  .bells {
    background-color: #fff;
    color: 000;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    gap: 10px;
    height: 42px;
    line-height: 2.5rem;

    .waiting-for-release {
      display: flex;

      .bullet-red {
        color: red !important;
      }

      span {
        color: #313131;
        font-weight: 400;
      }
    }

    p {
      margin-left: 7px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 2.58rem;
      color: #821c0e;
    }

    strong {
      margin-right: 7px;
      color: #821c0e;
    }
  }

  .bells-children {
    background-color: #efefef;
    color: 000;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    gap: 10px;
    height: 35px;
    line-height: 2rem;

    p {
      margin-left: 7px;
    }

    strong {
      margin-right: 7px;
    }
  }

  .months-of-the-year {
    background-color: #fff;
    margin: 10px;
    gap: 10px;
    height: 47px;
    line-height: 2.85rem;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.3px;
    color: #821c0e;

    img {
      color: #821c0e;
    }

    .chev-arrow {
      position: relative;
      top: 6.7px;
      left: 3px;

      img {
        height: 16px;
      }
    }

    span {
      margin-right: 10px;
    }
  }

  .div-items {
    background-color: #fff;
    margin: 10px;
    gap: 10px;
    height: 45px;
    line-height: 2rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #444;

    .bullet-red {
      color: red !important;
    }

    .span-quizzes {
      margin-left: 2.8rem;
      font-weight: 400;
      /* color: #313131; */
    }

    .span-training-epi {
      margin-left: 8.4rem;
      font-weight: 400;
      /* color: #313131; */
    }

    .span-access {
      margin-left: 13.3rem;
      font-weight: 400;
      /* color: #313131; */
    }

    .flex {
      display: flex;
      width: 100%;
      justify-content: space-between;

      strong {
        margin-right: 0.71rem;
      }

      p {
        margin-left: 0.6rem;
        line-height: 1.6rem;
      }
    }
    @media screen and (min-width: 308px) and (max-width: 500px) {
      .months-of-the-year {
        height: 63px;
        width: 90%;
      }

      .div-items {
        height: 56px;
        width: 90%;
      }
    }
  }
`;

export const Container = styled.div`
  background: #efefef;
  padding: 10px;
  margin-bottom: 20px;
  width: 92%;
  margin: 20px auto;
  color: #000;

  button {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 16px;
    height: 42px;
    max-width: 156px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  color: ${({ theme }) => theme.font.color.primary};
  strong {
    display: block;
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }
  .divider {
    flex: 1;
    border-bottom: 1px solid #444;
  }
  .text-right {
    text-align: right;
  }
  .chevron {
    background: url(${chevron}) no-repeat center center;
    width: 40px;
    height: 40px;
    &.open {
      transform: rotate(180deg);
    }
  }
`;

export const Content = styled.div`
  margin-right: 40px;
  details > summary::marker {
    display: none;
  }
  margin-top: 20px;
  .content-row {
    margin-bottom: 10px;
  }
  .row-header {
    background: #fff;
    color: ${({ theme }) => theme.font.color.primary};
    display: flex;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    justify-content: space-between;
    padding: 10px;
  }
  .row-descripton {
    padding: 5px 10px;
  }
  .row-details {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    color: ${({ theme }) => theme.font.color.quartenary};
  }

  .special {
    opacity: 50%;
  }
`;
