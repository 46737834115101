import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MaterialButton from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, useSimpleToast } from 'components/shared';
import NegotiationModal from 'components/StrategicPlans/Modals/ReproveModal';
import { Comment } from 'services/strategic-plans/interfaces';
import requestPlanApproval from 'services/strategic-plans/requestPlanApproval';

import { useToast } from 'context/ToastContext';
import { useHistory } from 'react-router-dom';
import { Action } from 'services/strategic-plans/actions/interfaces';
import { toApiAction } from 'services/strategic-plans/actions/transformers';
import { Container } from './styles';

type Props = {
  canRequestApproval: boolean;
  canCreateActions: boolean;
  planUUID: string;
  planID: number;
  comments: Comment[];
};

export const CreatorActions: React.FC<Props> = ({
  canRequestApproval,
  canCreateActions,
  planUUID,
  planID,
  comments,
}) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [adjustsModal, setAdjustsModal] = useState(false);

  const { addToast } = useToast();
  const { showToast } = useSimpleToast();
  const history = useHistory();

  const handleRequestApproval = async () => {
    setShowAlert(false);
    setLoading(true);

    try {
      await requestPlanApproval(planUUID);

      showToast({
        message: 'Solicitação de aprovação realizada com sucesso!',
        type: 'success',
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      addToast({
        title:
          (error as any).response?.data?.message ||
          'Problemas ao Solicitar Aprovação!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const buildAction = (): Action => {
    return {
      title: '',
      id: 0,
      description: '',
      expectedDate: '',
      participantId: 0,
      strategyPlanId: planID,
      strategyActionTypeName: '',
      strategyActionTypeId: 0,
      created: '',
      strategyPlanStatus: 0,
      products: [],
      budget: 0,
      igd: 0,
      points: 0,
      attachments: [],
      crops: [],
      status: 1,
      strategyActionType: { title: '', value: '' } || '',
      beginDateAt: '',
      costMonthyAt: 0,
      endDateAt: '',
      exclusiveFmcAt: '',
      quantityAt: 0,
      typeHiringIdAt: 0,
      typeHiring: { title: '', value: '0' },
      strategyActionAt: {
        begin_date: '',
        cost_monthy: 0,
        end_date: '',
        exclusive_fmc: '',
        quantity: '',
        type_hiring_id: 0,
      },
      customFields: {
        attachments: [],
        budget: 0,
        points: 0,
        products: [],
        igd: 0
      },
    };
  };

  const createAction = () => {
    history.push(`/planos-estrategicos/${planUUID}/acao`, {
      action: toApiAction(buildAction()),
      uuid: planUUID,
    });
  };

  return (
    <Container>
      <Button
        type="button"
        buttonRole="primary"
        onClick={createAction}
        disabled={!canCreateActions || loading}
      >
        Criar ação
      </Button>

      <Button
        type="button"
        buttonRole="tertiary"
        onClick={() => setShowAlert(true)}
        loading={loading}
        disabled={!canRequestApproval || loading}
      >
        Solicitar aprovação
      </Button>

      <Button
        type="button"
        buttonRole="tertiary"
        disabled={loading}
        onClick={() => setAdjustsModal(true)}
      >
        Comentários
      </Button>

      <Dialog open={showAlert}>
        <DialogTitle>Deseja solicitar a aprovação deste plano?</DialogTitle>
        <DialogActions>
          <MaterialButton onClick={() => setShowAlert(false)}>
            Cancelar
          </MaterialButton>
          <MaterialButton onClick={handleRequestApproval}>
            Confirmar
          </MaterialButton>
        </DialogActions>
      </Dialog>

      <NegotiationModal
        isOpen={adjustsModal}
        comments={comments}
        onRequestClose={() => setAdjustsModal(false)}
        selectedUuids={[planUUID]}
        onlyShow
        onSuccess={() => window.location.reload()}
      />
    </Container>
  );
};
