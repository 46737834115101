import styled from 'styled-components';
import DefaultModal from 'components/shared/Modal';
import DefaultButton from 'components/shared/Button';
import DefaultTextArea from 'components/shared/TextArea';
import DefaultReasonsSelect from './ReasonsSelect';

export const Modal = styled(DefaultModal)`
  ._modalContainer {
    max-width: 600px;
    max-height: 530px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
  }
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.fontFamily.bold};
  color: ${({ theme }) => theme.font.color.primary};
  font-size: 16px;
  margin-bottom: 20px;
`;

export const Close = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: ${({ theme }) => theme.font.color.primary};
    }
  }
`;

export const FormOptOut = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ReasonsSelect = styled(DefaultReasonsSelect)`
  ._inputContainer {
    height: 40px;
  }
  > span {
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 5px;
  }
`;

export const TextArea = styled(DefaultTextArea)`
  margin-top: 20px;
  > span {
    color: ${({ theme }) => theme.font.color.primary};
    margin-bottom: 5px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const Button = styled(DefaultButton)`
  max-width: 200px;
  height: 40px;
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 8px;
  margin-top: 25px;
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 50px;
  height: 100%;

  > span {
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 16px;
    display: flex;
    margin-bottom: 20px;
  }
`;
