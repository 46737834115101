import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5em;
`;

interface NavigationItemProps {
  selected: boolean;
}
export const NavigationItem = styled.div<NavigationItemProps>`
  flex: 1;
  height: 87px;
  border: 2px solid ${({ theme }) => theme.font.color.quartenary};
  opacity: 0.15;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 15px;
  color: ${({ theme }) => theme.font.color.primary};
  text-transform: uppercase;

  & + div {
    border-left-color: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border: 2px solid ${({ theme }) => theme.font.color.quartenary};
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
    right: -9px;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 17px;
    border-right: 2px solid #fff;
    top: 50%;
    right: -2px;
    transform: translateY(-50%);
  }

  &:last-child {
    &::after,
    &::before {
      display: none;
    }
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      opacity: 1;
      border: 2px solid ${theme.font.color.primary};
      &::before {
        border-right-color: ${theme.font.color.primary};
        border-top-color: ${theme.font.color.primary};
      }
    `}

  @media (min-width: 1024px) {
    text-transform: none;
    height: 90px;
    padding: 0 0px 0 40px;
    margin-bottom: 20px;
  }
`;
