/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

type TAxios = AxiosError<{
  message: string;
  url: string;
  code: number;
}>;

export function handlerAxiosError(error: any) {
  if (error.response) {
    const err = error as AxiosError;

    return err.response?.data.message;
  }

  if (error && error.stack && error.message) {
    return error.message;
  }

  const errorMessage = 'Ocorreu um erro inesperado';

  return errorMessage;
}
