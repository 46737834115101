import { pluginApi } from 'services/api';
import { CreateNewCampaignDTO } from './dtos';

export default async (
  data: CreateNewCampaignDTO,
  id?: number,
): Promise<void> => {
  let url = 'fmc-campaigns/save-draft';

  if (id) {
    url += `/${id}`;
  }

  await pluginApi.post(url, data);
};
