/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import routeMap from 'routes/route-map';
import history from 'services/history';
import getData from 'services/campaigns-manager/getMechanics';
import {
  setCampaign,
  setMechanicID,
  updateStatus,
} from 'state/modules/campaigns-manager/actions';
import { Button } from 'components/shared';
import { useToast } from 'context/ToastContext';

import { Mechanic } from 'services/campaigns-manager/interfaces/Campaign';

import { getCampaign } from 'state/modules/campaigns-manager/selectors';
import { newEmptyCampaignObject } from 'services/campaigns-manager';
import { Card, Container, Content, CardImage, Alert, Header } from './styles';

const NewCampaign: React.FC = () => {
  const dispatch = useDispatch();
  const campaign = useSelector(getCampaign);
  const [mechanics, setMechanics] = useState<Mechanic[]>([]);
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();

  useEffect(() => {
    if (campaign.id !== 0) {
      dispatch(setCampaign(newEmptyCampaignObject()));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getData()
      .then(data => {
        setMechanics(data);
        setLoading(false);
      })
      .catch(_ => {
        addToast({
          title: 'Erro ao carregar campanhas, por favor contate o admnistrador',
        });
      });
  }, []);

  const backToList = () => {
    history.push(`${routeMap.campaignManagement.acceleratedCampaigns}`);
  };

  const handleCreateCampaign = async (id: number, name: string) => {
    try {
      const updatedCampaign = {
        ...campaign,
        mechanic: {
          id,
          name,
        },
      };

      await dispatch(setMechanicID(id));
      dispatch(updateStatus(updatedCampaign));

      history.replace(`${routeMap.campaignManagement.newCampaign}/${name}`);
    } catch (e) {
      addToast({
        title: 'Falha ao selecionar mecânica. Por favor contate o suporte.',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Container>
        <Header>
          <div className="contents-header">
            <button type="button" onClick={backToList}>
              Voltar para todas as campanhas
            </button>
            <h1>Nova Campanha</h1>
          </div>
        </Header>

        <Content>
          <h3>Escolha o tipo de mecânica da sua campanha</h3>

          {loading && (
            <Alert>
              <ReactLoading
                type="bars"
                color="#8d8d8d"
                height={24}
                width={24}
              />
            </Alert>
          )}

          {mechanics.map(mechanic => (
            <Card key={mechanic.id}>
              <CardImage>
                <img src={mechanic.internalImage} alt="logo-campaign" />
              </CardImage>
              <div>
                <h1>{mechanic.name}</h1>
                <p>{mechanic.description}</p>
              </div>
              <Button
                buttonRole="primary"
                type="button"
                onClick={() => handleCreateCampaign(mechanic.id, mechanic.name)}
              >
                SELECIONAR
              </Button>
            </Card>
          ))}
        </Content>
      </Container>
    </>
  );
};

export default NewCampaign;
