import styled from 'styled-components';
import { Button as DefaultButton } from 'components/shared';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .contents {
    display: flex;
    justify-content: space-between;
  }

  ._accelerator-campaign-title {
    margin-bottom: 0 !important;
  }

  ._accelerator-campaign-subtitle {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;

    h2 {
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      color: ${({ theme }) => theme.font.color.primary};
    }

    ._accelerator-campaign-btn {
      width: 100%;
      font-size: 12px;
    }
  }

  strong {
    text-transform: uppercase;
    color: ${({ theme }) => theme.font.color.primary};
    font-size: 12px;
  }

  .contents-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;

    button {
      background: none;
      border: none;
      color: ${({ theme }) => theme.font.color.primary};
      font-family: ${({ theme }) => theme.font.fontFamily.bold};
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 14px;
      margin-left: 30px;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: transparent;
    text-decoration: underline;
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const ApprovalBox = styled.div`
  display: flex;
  padding: 50px;
  margin-top: 5px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  background-color: white;
  background-image: repeating-linear-gradient(
    -79deg,
    transparent,
    transparent 10px,
    #f2f3f394 10px,
    #f2f3f394 20px
  );

  span {
    text-align: start;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.italic};
    font-weight: bold;
    margin-right: 290px;
  }

  > button {
    text-decoration: underline;
    background-color: transparent;
    border: none;
    margin-left: 30px;
    color: ${({ theme }) => theme.font.color.primary};
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
  }

  .approve {
    color: #008000;
  }

  .disapprove {
    color: #ff0000;
  }

  .icon {
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    margin-right: 5px;
  }

  .icon-check {
    border-radius: 50%;
    border: 2px solid #008000;
  }

  .icon-times {
    border-radius: 50%;
    border: 2px solid #ff0000;
  }
`;

export const CampaignStatus = styled.div`
  color: ${({ theme }) => theme.font.color.primary};
  p {
    color: ${({ theme }) => theme.font.color.quartenary};
    margin-top: 0.3rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;

  .content-modal {
    margin-left: 2rem;

    h3 {
      text-align: left;
      font-size: 1.1rem;
    }
  }

  h2 {
    margin: 2rem;
    text-align: left;
    font-size: 1.4rem;
    font-family: 'Helvetica-Neue-Medium';
    color: ${({ theme }) => theme.font.color.primary};
  }
`;

export const Content = styled.div`
  color: #000;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  margin-bottom: 0.5rem;

  > button {
    width: 166px;
    height: 30px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fontFamily.bold};
    font-size: 11px;
  }

  .register {
    background: ${({ theme }) => theme.font.color.primary};
    color: #fff;
    margin-left: 1rem;
  }

  .cancel {
    background: transparent;
    color: ${({ theme }) => theme.font.color.primary};
    border: 1px solid ${({ theme }) => theme.font.color.primary};
  }
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-4px) translateY(4px);
  > button {
    border: none;
    background: transparent;
    svg path {
      fill: #000;
    }
  }
`;
interface ButtonProps {
  toggle?: boolean;
}

export const HelpButton = styled.button<ButtonProps>`
  background: transparent;
  border: 2px solid ${({ theme }) => theme.font.color.primary};
  color: ${({ theme }) => theme.font.color.primary};
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 0;
`;

export const ContentsButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .simple-button {
    color: ${({ theme }) => theme.font.color.primary};
    background: none;
    text-transform: uppercase;
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }
`;

export const Button = styled(DefaultButton)`
  max-width: 250px;
  height: 50px;
  background-color: transparent;
  border: 2px solid;
  color: ${({ theme }) => theme.font.color.primary};
  text-transform: uppercase;
  margin-left: 20px;

  ._loading {
    svg {
      fill: ${({ theme, buttonRole }) => theme.button[buttonRole].fontColor};
      margin-top: 1px;
    }
  }

  &:hover {
    color: ${({ theme, buttonRole }) => theme.button[buttonRole].fontColor};
    ._loading {
      svg {
        fill: #fff;
        margin-top: 1px;
      }
    }
  }
`;
