/* eslint-disable @typescript-eslint/camelcase */
import { pluginApi } from 'services/api';

export interface ApiProfile {
  logged_in: boolean;
  participant_id: number;
  establishment_id: number;
  role_id: number;
  establishment: {
    name: string;
    cnpj: string;
    type: {
      name: string;
    };
  };
}

export interface ApiResponse {
  indireto: ApiProfile;
  produtor: ApiProfile;
}
export interface Profile {
  loggedIn: boolean;
  participantId: number;
  establishmentId: number;
  roleId: number;
  establishmentType: string;
}

export interface Response {
  indirect: Profile | null;
  producer: Profile | null;
}

const getProfile = (data: ApiProfile): Profile | null => {
  if (!data || !data.establishment_id) return null;

  const {
    logged_in,
    establishment_id,
    role_id,
    establishment,
    participant_id,
  } = data;

  return {
    loggedIn: logged_in,
    participantId: participant_id,
    establishmentId: establishment_id,
    roleId: role_id,
    establishmentType: establishment.type.name || 'Revenda',
  };
};

const buildResponse = (data: ApiResponse): Response => {
  const { indireto, produtor } = data;

  return {
    indirect: getProfile(indireto),
    producer: getProfile(produtor),
  };
};

export default async (): Promise<Response> => {
  const { data } = await pluginApi.get<ApiResponse>(
    '/participants/login/profiles',
  );

  return buildResponse(data);
};
