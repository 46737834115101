import React, { useState, useEffect } from 'react';

import { Option } from 'components/shared/Select';
import {
  PlanFilters,
  StrategicPlan,
} from 'services/strategic-plans/interfaces';
import { Container, Content, FilterItem } from './styles';
import CultivationsSelect from './Selects/Cultivations';
import ChannelsSelect from './Selects/Channel';
import ProductsSelect from './Selects/Products';
import PlanAuthorSelect from './Selects/PlanAuthor';
import ApproverSelect from './Selects/Approver';
import Status from './Selects/Status';

interface FiltersProps {
  changeFilterHandler: (filters: PlanFilters) => void;
  hide?: boolean;
  hideStatusFilter?: boolean;
  hideChannelFilter?: boolean;
  tabPlans?: StrategicPlan[];
}

const Filters: React.FC<FiltersProps> = ({
  changeFilterHandler,
  hide,
  hideStatusFilter = false,
  hideChannelFilter = false,
  tabPlans,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Option | null>(null);
  const [selectedApprover, setSelectedApprover] = useState<Option | null>(null);
  const [selectedAuthor, setSelectedAuthor] = useState<Option | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<Option | null>(null);
  const [selectedCrop, setSelectedCrop] = useState<Option | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<Option | null>(null);

  useEffect(() => {
    let filters = {} as PlanFilters;

    if (selectedStatus) {
      filters = {
        ...filters,
        status: parseInt(selectedStatus.value, 10),
      };
    }

    if (selectedChannel) {
      filters = {
        ...filters,
        channelId: parseInt(selectedChannel.value, 10),
      };
    }

    if (selectedProduct) {
      filters = {
        ...filters,
        productId: parseInt(selectedProduct.value, 10),
      };
    }
    if (selectedCrop) {
      filters = {
        ...filters,
        cropId: parseInt(selectedCrop.value, 10),
      };
    }
    if (selectedApprover) {
      filters = {
        ...filters,
        approverId: parseInt(selectedApprover.value, 10),
      };
    }
    if (selectedAuthor) {
      filters = {
        ...filters,
        authorId: parseInt(selectedAuthor.value, 10),
      };
    }

    changeFilterHandler(filters);
  }, [
    changeFilterHandler,
    selectedApprover,
    selectedAuthor,
    selectedChannel,
    selectedCrop,
    selectedProduct,
    selectedStatus,
  ]);

  const actions =
    tabPlans && Object.values(tabPlans.flatMap(plan => plan.strategyActions));

  return (
    <Container hide={hide}>
      <Content>
        {!hideStatusFilter && (
          <FilterItem>
            <Status value={selectedStatus} setValue={setSelectedStatus} />
          </FilterItem>
        )}
        {!hideChannelFilter && (
          <FilterItem>
            <ChannelsSelect
              value={selectedChannel}
              setValue={setSelectedChannel}
              plans={tabPlans || []}
            />
          </FilterItem>
        )}
        <FilterItem>
          <CultivationsSelect
            value={selectedCrop}
            setValue={setSelectedCrop}
            actions={actions || []}
          />
        </FilterItem>
        <FilterItem>
          <ProductsSelect
            value={selectedProduct}
            setValue={setSelectedProduct}
            actions={actions || []}
          />
        </FilterItem>
        <FilterItem>
          <PlanAuthorSelect
            value={selectedAuthor}
            setValue={setSelectedAuthor}
            plans={tabPlans || []}
          />
        </FilterItem>
        <FilterItem>
          <ApproverSelect
            value={selectedApprover}
            setValue={setSelectedApprover}
            plans={tabPlans || []}
          />
        </FilterItem>
      </Content>
    </Container>
  );
};

export default Filters;
