import { vendavallApi } from 'services/api';
import { TrainingApi } from './interfaces/TrainingApi';
export default async (trainingId: number) => {
  const { data } = await vendavallApi.get<TrainingApi>(
    `/trainings/get-training-by-id?training_id=${trainingId}`,
  );
    if(data?.max_tries <= data?.total_attempts){
        return true;
    }
  return false;
};
