import React, { useCallback, useState } from 'react';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/images/training/close-icon.svg';
import history from 'services/history';

import {
  Modal,
  Container,
  BtnContainer,
  Close,
  Button,
  ButtonConfirm,
} from './styles';

const CultivationArea: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const onRequestCloseHandler = useCallback(() => setIsOpen(false), []);

  const onConfirmHandler = useCallback(() => {
    history.push('/editar-perfil?pre_select=dados_de_cultivo');
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestCloseHandler}>
      <Close>
        <button type="button" onClick={onRequestCloseHandler}>
          <ReactSVG src={closeIcon} />
        </button>
      </Close>
      <Container>
        <h5>
          Caro(a) Produtor, <br />
          Revise agora os dados preenchidos de “Área de Cultivo”.
        </h5>
        <BtnContainer>
          <ButtonConfirm type="button" onClick={onConfirmHandler}>
            Preencher agora
          </ButtonConfirm>
          <Button
            type="button"
            buttonRole="primary"
            onClick={onRequestCloseHandler}
          >
            Preencher depois
          </Button>
        </BtnContainer>
      </Container>
    </Modal>
  );
};

export default CultivationArea;
