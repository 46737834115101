import { shade, rgba } from 'polished';

export default {
  primary: {
    borderColor: '#004E27',
    backgroundColor: '#fff',
    fontColor: '#808285',
    iconColor: '#808285',
    placeholderColor: '#808285',
    errorIconColor: '#e63027',
    errorBorderColor: '#e63027',
    focusedBorderColor: shade(0.3, '#004E27'),
    filledIconColor: '#004E27',
    filledBorderColor: '#004E27',
    labelFontColor: '#004E27',
    borderRadius: '0px',
    borderWidth: '1px',
    errorBorderWidth: '1px',
    focusedBorderWidth: '1px',
    filledBorderWidth: '1px',
    scrollBarColor: '#c6c6c6',
    scrollBarBackgroundColor: '#fff',
  },
  secondary: {
    borderColor: '#808285',
    backgroundColor: '#fff',
    fontColor: '#808285',
    iconColor: '#808285',
    placeholderColor: '#808285',
    errorIconColor: '#e63027',
    errorBorderColor: '#e63027',
    focusedBorderColor: shade(0.3, '#808285'),
    filledIconColor: '#808285',
    filledBorderColor: '#808285',
    labelFontColor: '#808285',
    borderRadius: '0px',
    borderWidth: '1px',
    errorBorderWidth: '1px',
    focusedBorderWidth: '1px',
    filledBorderWidth: '1px',
    scrollBarColor: '#c6c6c6',
    scrollBarBackgroundColor: '#fff',
  },
  terciary: {
    borderColor: '#ffffff',
    backgroundColor: rgba('#000000', 0.3),
    fontColor: '#ffffff',
    iconColor: '#ffffff',
    placeholderColor: rgba('#ffffff', 0.6),
    errorIconColor: '#e63027',
    errorBorderColor: '#e63027',
    focusedBorderColor: shade(0.3, '#ffffff'),
    filledIconColor: '#ffffff',
    filledBorderColor: '#ffffff',
    labelFontColor: '#ffffff',
    borderRadius: '5px',
    borderWidth: '1px',
    errorBorderWidth: '1px',
    focusedBorderWidth: '1px',
    filledBorderWidth: '1px',
    scrollBarColor: '#c6c6c6',
    scrollBarBackgroundColor: '#fff',
  },
};
