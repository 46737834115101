import styled, { css } from 'styled-components';
import ContactBallon from 'components/Contact';
import backDesktop from 'assets/images/fmcProdutor/NF/back-desktop.svg';
import StatusTable from './StatusTable';
import Upload from './Upload';

export const Container = styled.div`
  padding: 12px;
  position: relative;
  border-radius: 10px;
  background-size: 100% auto;
  background-position: center;

  .imgComputer {
    z-index: 2;
    position: absolute;
    margin-top: 65px;
    margin-left: 85px;
    max-width: 200px;
  }

  .coinsBanner1 {
    z-index: 1;
    position: absolute;
    max-width: 280px;
    top: 0.6;
    left: 0;
    margin-left: 65px;
  }

  .coinsBanner {
    z-index: 2;
    position: absolute;
    max-width: 740px;
    margin-left: 20%;
  }

  .coinsBanner2 {
    z-index: 0;
    position: absolute;
    max-width: 215px;
    top: 0;
    margin-left: 85%;
  }

  .backgroundComputer {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: rgba(220, 220, 220, 0.4);
    z-index: 0;
    position: absolute;
    margin-top: 28px;
    margin-left: 56px;
  }

  .background3 {
    margin-top: 60px;
    margin-left: 10%;
    background-color: rgba(220, 220, 220, 0.4);
    z-index: 0;
    position: absolute;
    width: 80%;
    height: 80px;
  }

  .background1 {
    margin-top: 140px;
    margin-left: 10%;
    background-color: rgba(214, 214, 214, 0.6);
    z-index: 0;
    position: absolute;
    width: 992px;
    height: 88px;
    border-radius: 40px;
  }

  .background2 {
    margin-top: 80px;
    margin-left: 10%;
    background-color: rgba(255, 255, 255, 1.8);
    z-index: 0;
    position: absolute;
    width: 980px;
    height: 100px;
    border-radius: 55px;
  }

  .background4 {
    margin-top: 240px;
    margin-left: 10%;
    background-color: rgba(220, 220, 220, 0.4);
    z-index: 0;
    position: absolute;
    width: 920px;
    height: 40px;
  }

  @media (min-width: 1024) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: 80%;
  }
`;

interface ContentProps {
  secondary?: boolean;
}

export const Content = styled.div<ContentProps>`
  position: relative;
  padding: 24px;

  @media (min-width: 768px) {
    padding: 50px 24px;
  }
  a {
    text-decoration: none;
    color: #181818;
  }

  @media (max-width: 767px) {
    > * {
      margin-top: 20px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 145px;
      display: block;
      margin: 0 auto 16px auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  @media (min-width: 768px) {
    background: url(${backDesktop});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media (min-width: 1024px) {
    display: flex;
    padding-left: 25%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    ${({ secondary }) =>
      secondary &&
      css`
        flex-flow: row wrap;
        h3 {
          max-width: none;
        }
      `}
  }

  ${({ secondary }) =>
    secondary &&
    css`
      h3 {
        margin: 0 0 20px 0;
      }
      > p {
        @media (min-width: 768px) {
          width: 50%;
          padding-right: 2em;
        }
      }
    `}
`;

export const Contact = styled(ContactBallon)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 36px;
  line-height: 1.2;
  z-index: 2;

  font-family: ${({ theme }) => theme.font.fontFamily.condensed};
  color: ${({ theme }) => theme.font.color.primary};

  @media (min-width: 1024px) {
    margin: auto 40px;
  }

  @media (max-width: 360px) {
    font-size: 26px;
    text-align: center;
    position: relative;
    margin-left: 55px;
    margin-right: 380px;
  }

  @media (max-width: 420px) {
    padding: 55px 0 0 0;
    font-size: 20px;
    text-align: center;
    position: relative;
    margin-right: 0;
  }
`;

export const RightSideBox = styled.div`
  margin-right: 100px;
  display: flex;

  > div {
    margin-top: 20px;
    max-width: 300px;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1024px) {
    flex-flow: row nowrap;
  }
`;

export const AdjustStatusTable = styled(StatusTable)`
  &&& {
    background-color: #ccc;
    color: #000;
  }
`;

export const ButtonDownload = styled(Upload)`
  margin-top: 20px;
`;
