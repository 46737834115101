import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/modules/points-simulator/actions';
import { getDollarBaseValue, getProducts } from 'state/modules/points-simulator/selectors';
 
import {
  Award,
  Configuration,
  Indicator,
  IndicatorType,
  Channel,
} from 'state/modules/points-simulator/interfaces';
import { formatPoints } from 'util/points';

import { EstablishmentTypes } from 'config/constants';
import MiniBox from '../MiniBox';
import CardComponent from '../Card';
import {
  Container,
  CustomSimulateContent,
  Title,
  CustomSimuteBox,
  CustomAcumulateBox,
  CustomAcumulateContent,
  CustomIndicatorContainer,
  Cards,
  IndirectBox,
  TitleBox,
} from './styles';

export interface Card {
  title: string;
  isBiological: boolean;
  isTerraBrasil: boolean;
  type: IndicatorType;
  isRegisteredProduct?: boolean;
  description: string;
  tableData: {
    title: string;
    value: string;
  }[];
  percentageCompleted: number;
  percentageSimulated: number;
}

interface Props {
  cards: Card[];
  award: Award;
  configuration: Configuration;
  indicators: Indicator[];
  channel: Channel;
}

const title = (item: Card) => (
  <>
    {item.title}
    {item.isRegisteredProduct && (
      <span
        style={{
          fontSize: 11,
          transform: 'translateY(-2px)',
          display: 'inline-block',
        }}
      >
        
      </span>
    )}
  </>
);

const Body: React.FC<Props> = ({ cards, award, indicators, channel }) => {
  const [revenuesValue, setRevenuesValue] = useState<number | undefined>(0);
  const [pogValue, setPogValue] = useState<number | undefined>(0);

  const products = useSelector(getProducts);
  const dollar = useSelector(getDollarBaseValue);
  const dispatch = useDispatch();

  const [biologicalCards, setBiologicalCards] = useState<Card[] | undefined>(
    [],
  );
  const [juntosCards, setJuntosCards] = useState<Card[] | undefined>([]);

  const isBiological = channel?.isBiological;
  const hasAdditionalMargin = channel?.hasAdditionalMargin;
  const isResale = channel?.type === 'Revenda';
  const isTerraBrasil = channel?.type === EstablishmentTypes.TerraBrasil;

  const shouldRenderMargemAdicionalCard = !isTerraBrasil;
  const shouldRenderPremicaoVendedorCard = ![
    EstablishmentTypes.Cooperative,
    EstablishmentTypes.TerraBrasil,
  ].includes(channel.type);

  useEffect(() => {
    /**
     * !! TODO: Mover toda lógica de cálculos para o state
     * geral da funcionalidade (redux)
     */
    const foundPog = indicators.find(item => item.type === IndicatorType.pog);
    const foundRevenues = indicators.find(
      item => item.type === IndicatorType.revenues,
    );

    const foundRevenuesBiological = indicators.find(
      item => item.type === IndicatorType.revenuesBiological,
    );
    const foundPogBiological = indicators.find(
      item => item.type === IndicatorType.pogBiological,
    );

    if (
      foundPog &&
      foundRevenues &&
      foundPogBiological &&
      foundRevenuesBiological
    ) {
      const sumFoundRevenue =
        foundRevenues.simulationData.totalSimulated +
        foundRevenuesBiological.simulationData.totalSimulated;
      const sumFoundPog =
        foundPog.simulationData.totalSimulated +
        foundPogBiological?.simulationData.totalSimulated;
      setPogValue(sumFoundPog);
      setRevenuesValue(sumFoundRevenue);
    }
  }, [indicators]);

  useEffect(() => {
    const defaultCards = [IndicatorType.pog, IndicatorType.revenues];

    const cardsBiological = cards.filter(item => item.isBiological);
    setBiologicalCards(cardsBiological);

    const cardsJuntos = cards.filter(
      item =>
        !item.isBiological &&
        (item.isTerraBrasil === isTerraBrasil ||
          defaultCards.includes(item.type)),
    );
    setJuntosCards(cardsJuntos);
  }, [cards, isTerraBrasil]);

  // Ordenação de juntosCards
  const juntosCardsSorted =
    juntosCards &&
    juntosCards
      .filter(item => item.title === 'Faturamento' || item.title === 'POG') // Extrai Faturamento e POG
      .concat(
        juntosCards
          .filter(item => item.title !== 'Faturamento' && item.title !== 'POG') // Extrai os outros itens
          .sort((a, b) => a.title.localeCompare(b.title)),
      );

  // Ordenação de biologicalCards
  const biologicalCardsSorted =
    biologicalCards &&
    biologicalCards
      .filter(item => item.title === 'Faturamento' || item.title === 'POG') // Extrai Faturamento e POG
      .concat(
        biologicalCards
          .filter(item => item.title !== 'Faturamento' && item.title !== 'POG') // Extrai os outros itens
          .sort((a, b) => a.title.localeCompare(b.title)),
      );

  // Refatoração Futura: Cálculo das margens adicionais de biológicos. INICIO
  const firstBiologicalCard = biologicalCardsSorted?.[0];
  const valueFromTable = firstBiologicalCard?.tableData[1].value || '';
  const valueTrimmed = valueFromTable.substring(4);
  const sanitizedValue = valueTrimmed.replace(/[^\w\s]/gi, '');
  const parsedValue = parseFloat(sanitizedValue);
  const completionPercentage = firstBiologicalCard?.percentageCompleted || 0;
  const simulatedPercentage = firstBiologicalCard?.percentageSimulated || 0;
  let simulationValues: number[] = [];
  let biologicalPercentageValues: number[] = [];
  products?.map(
    product => {
      if (product.isBiological && product.checked) {
        simulationValues.push(product.simulationData.revenuesInDollar);
      }
      if (product.isBiological){
        biologicalPercentageValues.push(product.awardsParamsToPay.additionalMarginPercentageBiological);
      }
      return product;
    }
  );
  const totalBiologicalPercentage =
    biologicalPercentageValues.length > 0
      ? biologicalPercentageValues.reduce(x => x)
      : 0;
  const totalSimulationValue = simulationValues.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
  const percentageForCalculation =
    completionPercentage !== simulatedPercentage
      ? simulatedPercentage
      : completionPercentage;
  let percentageOfTotalValue = 0;
  if (percentageForCalculation < 80) {
    percentageOfTotalValue = 0;
  }
  if (percentageForCalculation >= 80 && percentageForCalculation < 85) {
    percentageOfTotalValue = totalBiologicalPercentage * 0.5;
  }
  if (percentageForCalculation >= 85 && percentageForCalculation < 90) {
    percentageOfTotalValue = totalBiologicalPercentage * 0.6;
  }
  if (percentageForCalculation >= 90 && percentageForCalculation < 95) {
    percentageOfTotalValue = totalBiologicalPercentage * 0.7;
  }
  if (percentageForCalculation >= 95 && percentageForCalculation < 100) {
    percentageOfTotalValue = totalBiologicalPercentage * 0.8;
  }
  if (percentageForCalculation >= 100) {
    percentageOfTotalValue = totalBiologicalPercentage;
  }
  const biologicalSimulationValue =
    ((totalSimulationValue * totalBiologicalPercentage) / 100) * dollar;
  const finalCalculation = ((parsedValue * percentageOfTotalValue) / 100) * dollar;
  useEffect(
    () => {
      dispatch(actions.setBiologicalSimulationValue(biologicalSimulationValue));
      dispatch(actions.setFinalCalculation(finalCalculation));
    } , [biologicalSimulationValue, finalCalculation, dispatch]
  );
  // Refatoração Futura: Cálculo das margens adicionais de biológicos. FIM

  return (
    <Container>
      <CustomSimulateContent>
        <Title>
          <h3>Valor da venda</h3>
          <span>(Valores referentes a soma de Indiretos e Biológicos)</span>
        </Title>
        <CustomSimuteBox>
          <MiniBox
            title="Faturamento"
            text={`US$ ${formatPoints(revenuesValue || 0, 0, 0)}`}
          />
          <MiniBox
            title="POG"
            text={`US$ ${formatPoints(pogValue || 0, 0, 0)}`}
          />
        </CustomSimuteBox>
      </CustomSimulateContent>
      <TitleBox>
        <h3>Juntos FMC</h3>
      </TitleBox>
      <IndirectBox>
        <CustomSimulateContent>
          <Title>
            <h3>Pontos simulados</h3>
            <span>
              (Os pontos referentes a venda simulada assumem o atingimento de
              100% da meta)
            </span>
          </Title>
          <CustomSimuteBox>
            <MiniBox
              title="Pontos do rebate"
              text={`${formatPoints(award.simulatedRebate, 0, 0)} pontos`}
              simulated
            />
            {shouldRenderMargemAdicionalCard && hasAdditionalMargin && (
              <MiniBox
                title="Margem adicional"
                text={`R$ ${formatPoints(
                  award.simulatedAdditionalMargin,
                  0,
                  0,
                )}`}
              />
            )}

            {shouldRenderPremicaoVendedorCard && (
              <MiniBox
                title="Premiação de vendedor"
                text={`${formatPoints(award.simulatedSeller, 0, 0)} pontos`}
              />
            )}
          </CustomSimuteBox>
        </CustomSimulateContent>
        <CustomAcumulateContent>
          <Title>
            <h3>Total acumulado</h3>
          </Title>
          <CustomAcumulateBox>
            <MiniBox
              title="Pontos do rebate"
              text={`${formatPoints(award.totalRebate, 0, 0)} pontos`}
            />

            {shouldRenderMargemAdicionalCard && hasAdditionalMargin && (
              <MiniBox
                title="Margem adicional"
                text={`R$ ${formatPoints(award.totalAdditionalMargin, 0, 0)}`}
              />
            )}

            {shouldRenderPremicaoVendedorCard && (
              <MiniBox
                title="Premiação de vendedor"
                text={`${formatPoints(award.totalSeller, 0, 0)} pontos`}
              />
            )}
          </CustomAcumulateBox>
        </CustomAcumulateContent>
        <CustomIndicatorContainer>
          <Title>
            <h3>Indicadores</h3>
            <span>(Faturamento e POG)</span>
          </Title>
          <Cards className="_indicatorsContainer">
            {juntosCardsSorted &&
              juntosCardsSorted.map(item => (
                <CardComponent
                  key={item.title}
                  title={title(item)}
                  description={item.description}
                  tableData={item.tableData}
                  percentageCompleted={item.percentageCompleted}
                  percentageSimulated={item.percentageSimulated}
                />
              ))}
          </Cards>
        </CustomIndicatorContainer>
      </IndirectBox>
      {isBiological && (
        <>
          <TitleBox>
            <h3>Biológicos FMC</h3>
          </TitleBox>
          <IndirectBox>
            <CustomSimulateContent>
              <Title>
                <h3>Pontos simulados</h3>
                <span>
                  (Os pontos referentes a venda simulada assumem o atingimento
                  de 100% da meta)
                </span>
              </Title>
              <CustomSimuteBox>
                <MiniBox
                  title="Pontos do rebate"
                  text={`${formatPoints(
                    award.simulatedRebateBiological,
                    0,
                    0,
                  )} pontos`}
                />
                <MiniBox
                  title="Margem adicional"
                  text={`R$ ${formatPoints(biologicalSimulationValue, 0, 0)}`}
                />
                {isResale && (
                  <MiniBox
                    title="Premiação de vendedor"
                    text={`${formatPoints(
                      award.simulatedSellerBiological,
                      0,
                      0,
                    )} pontos`}
                  />
                )}
              </CustomSimuteBox>
            </CustomSimulateContent>
            <CustomAcumulateContent>
              <Title>
                <h3>Total acumulado</h3>
              </Title>
              <CustomAcumulateBox>
                <MiniBox
                  title="Pontos do rebate"
                  text={`${formatPoints(
                    award.totalRebateBiological,
                    0,
                    0,
                  )} pontos`}
                />
                <MiniBox
                  title="Margem adicional"
                  text={`R$ ${formatPoints(finalCalculation, 0, 0)}`}
                />
                {isResale && (
                  <MiniBox
                    title="Premiação de vendedor"
                    text={`${formatPoints(
                      award.totalSellerBiological,
                      0,
                      0,
                    )} pontos`}
                  />
                )}
              </CustomAcumulateBox>
            </CustomAcumulateContent>
            <CustomIndicatorContainer>
              <Title>
                <h3>Indicadores</h3>
                <span>
                  (Faturamento e POG: Valores referentes a soma de Indiretos e
                  Biológicos)
                </span>
              </Title>
              <Cards className="_indicatorsContainer">
                {biologicalCardsSorted &&
                  biologicalCardsSorted.map(item => (
                    <CardComponent
                      key={item.title}
                      title={item.title}
                      description={item.description}
                      tableData={item.tableData}
                      percentageCompleted={item.percentageCompleted}
                      percentageSimulated={item.percentageSimulated}
                    />
                  ))}
              </Cards>
            </CustomIndicatorContainer>
          </IndirectBox>
        </>
      )}
    </Container>
  );
};

export default Body;
