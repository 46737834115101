const getStatusText = (status: number): string => {
  switch (status) {
    case 0:
      return 'Cancelado';
    case 1:
      return 'Publicado';
    case 2:
      return 'Em análise/desenvolvimento';
    case 5:
      return 'Em ajustes';
    case 3:
      return 'Em aprovação';
    case 4:
      return 'Nova solicitação';
    case 7:
      return 'Aprovada para publicação';
    case 9:
      return 'Finalizada';
    default:
      return '';
  }
};

export default getStatusText;
