import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCampaign,
  getErrors,
} from 'state/modules/campaigns-manager/selectors';
import {
  setPointsValue,
  setPrizeDescription,
  setPrizeName,
} from 'state/modules/campaigns-manager/actions';
import InputValue from 'components/CampaignsManager/Inputs/NumberMaskInput';
import {
  formatPointsInput,
  formatRebatePointsInput,
  formatPoints,
} from 'util/points';

import { Container, InputText, TextArea, Box, Input } from './styles';

interface Props {
  actived: boolean;
}

const Awards: React.FC<Props> = ({ actived }) => {
  const dispatch = useDispatch();
  const campaign = useSelector(getCampaign);
  const errors = useSelector(getErrors);

  const audienceBalanceSum =
    campaign.audience.reduce((sum, item) => sum + item.balance, 0) || 0;

  return (
    <Container style={{ display: actived ? 'block' : 'none' }}>
      <h4>Prêmio</h4>
      <span>Descreva o nome do prêmio</span>
      <InputText
        type="text"
        placeholder="Nome da premiação"
        value={campaign.prize.name}
        error={errors['prize.name']}
        onChange={e => dispatch(setPrizeName(e.target.value))}
        inputRole="secondary"
      />
      <h4>Descrição da premiação</h4>
      <span>Descreva neste campo os detalhes da premiação de sua campanha</span>
      <TextArea
        value={campaign.prize.description}
        onChange={e => dispatch(setPrizeDescription(e.target.value))}
        inputRole="secondary"
        error={errors['prize.description']}
      />
      <span>
        Após a aprovação de sua campanha acesse o Personal Class no seu Catálogo
        de Prêmios e inicie o processo de orçamento da premiação escolhida
      </span>
      <h4>Pontos de Rebate (Estimados para uso)</h4>
      <span>
        Aqui você deve informar a origem da verba que deseja para realizar a sua
        campanha
      </span>

      <InputValue
        onChange={value => {
          dispatch(setPointsValue({ fieldName: 'affordPoints', value }));
        }}
        value={campaign.affordPoints}
        component={Input}
        formatValue={formatRebatePointsInput}
        placeholder="0 pontos"
        inputRole="secondary"
      />

      <strong>
        Saldo de Gestão e Desenvolvimento {' '}
        <span>(Todos os canais somados)</span>:{' '}
        {audienceBalanceSum > 0
          ? `R$ ${formatPoints(audienceBalanceSum)}`
          : campaign.audience[0]?.balance || 0}
      </strong>
      <br />
      <h4>Budget complementar</h4>
      <Box>
        <div />
        <h6>Budget Ações aceleradoras</h6>
        <InputValue
          onChange={value => {
            dispatch(
              setPointsValue({ fieldName: 'complementaryCrmBudget', value }),
            );
          }}
          value={campaign.complementaryCrmBudget}
          component={Input}
          formatValue={formatPointsInput}
          placeholder="R$ 0,00"
          inputRole="secondary"
        />
      </Box>
      <h4>Retorno esperado da campanha:</h4>
      <span>
        E por último indique o retorno que deseja atingir com sua campanha
      </span>
      <Box>
        <div />
        <h6>Sell-in</h6>
        <InputValue
          onChange={value => {
            dispatch(setPointsValue({ fieldName: 'expectedSellIn', value }));
          }}
          value={campaign.expectedSellIn}
          component={Input}
          formatValue={formatPointsInput}
          placeholder="R$ 0,00"
          inputRole="secondary"
          error={errors.expectedSellIn}
        />
      </Box>
      <Box>
        <div />
        <h6>Sell-out</h6>
        <InputValue
          onChange={value => {
            dispatch(setPointsValue({ fieldName: 'expectedSellOut', value }));
          }}
          value={campaign.expectedSellOut}
          component={Input}
          formatValue={formatPointsInput}
          placeholder="R$ 0,00"
          inputRole="secondary"
          error={errors.expectedSellOut}
        />
      </Box>
    </Container>
  );
};

export default Awards;
